import React, { useState } from "react";
import PropTypes from "prop-types";

import { Form, Segment, Button } from "semantic-ui-react";

import FocusBar from "components/FocusBar";
import naturalSort from "utils/naturalSort";
import AjaxFn from "services/AjaxFn";

const ChangeAgentsControls = props => {
  const [state, setState] = useState({
    template: null,
    loading: false
  });

  const options = props.options
    .map(option => ({
      text: option.name,
      value: option.id
    }))
    .sort((a, b) => naturalSort(a.text, b.text));

  const handleSubmit = e => {
    e.preventDefault();
    setState({ ...state, loading: true });
    AjaxFn({
      url: "/apis/portal/agreements",
      data: {
        action: "change_type",
        data: {
          agreement_id: props.ID,
          template_id: state.template
        }
      },
      success: () => {
        window.toastr.success();
        props.reload();
      },
      failure: error => {
        window.toastr["error"](error);
        setState({ ...state, loading: false });
      }
    });
  };

  return (
    <>
      <FocusBar.Content>
        <FocusBar.Generic>
          <Segment inverted>
            <Form inverted onSubmit={handleSubmit}>
              <Form.Dropdown
                label="Select a template"
                search
                selection
                placeholder="Select one..."
                selectOnBlur={false}
                options={options}
                onChange={(e, { value }) =>
                  setState({ ...state, template: value })
                }
              />
              <Button
                primary
                type="submit"
                basic
                content="Save"
                disabled={!state.template}
                loading={state.loading}
              />
            </Form>
          </Segment>
        </FocusBar.Generic>
      </FocusBar.Content>
    </>
  );
};

ChangeAgentsControls.propTypes = {
  options: PropTypes.array.isRequired,
  reload: PropTypes.func.isRequired,
  ID: PropTypes.number.isRequired
};

export default ChangeAgentsControls;
