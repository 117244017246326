import React from "react";
import AjaxFn from "services/AjaxFn";
import WithState from "components/WithState";

export default class AjaxComponent extends React.Component {
  state = {
    loading: true,
    error: false,
    data: false,
    cancel: false
  };

  componentDidMount() {
    const { autoload = true } = this.props;
    if (autoload) {
      this.reload();
    } else {
      this.setState({ loading: false });
    }
  }

  componentWillUnount() {
    this.cancel();
  }

  cancel = () => {
    if (typeof this.state.cancel === "function") {
      this.state.cancel();
    }
  };

  setSuccess = data => {
    this.setState({
      loading: false,
      error: false,
      cancel: false,
      data
    });
  };

  setFailure = error => {
    this.setState({
      loading: false,
      error,
      cancel: false,
      data: false
    });
  };

  reload = () => {
    const { ajaxConfig } = this.props;
    this.cancel();
    this.setState({
      loading: true,
      error: false,
      data: false,
      cancel: AjaxFn({
        ...ajaxConfig,
        success: message => {
          if (typeof ajaxConfig.success === "function") {
            ajaxConfig.success(message, this);
          } else {
            this.setSuccess(message);
          }
        },
        failure: error => {
          if (typeof ajaxConfig.failure === "function") {
            ajaxConfig.failure(error, this);
          } else {
            this.setFailure(error);
          }
        }
      })
    });
  };

  render() {
    const { children, stateful = true } = this.props;
    const { loading, error, data } = this.state;

    if (stateful) {
      return (
        <WithState loading={loading} error={error}>
          {data ? children({ data, loading, error }) : null}
        </WithState>
      );
    } else {
      return children({ data, loading, error });
    }
  }
}
