import React, { Component, Fragment } from "react";
import propTypes from "prop-types";
import { Table, Divider } from "semantic-ui-react";
import StartAudit from "./components/StartAudit";
import InvalidateAudit from "./components/InvalidateAudit";
import CompleteAudit from "./components/CompleteAudit";
import moment from "moment";

export default class Audits extends Component {
  static propTypes = {
    reload: propTypes.func,
    data: propTypes.object
  };

  render() {
    const { data, reload } = this.props;
    const { audits } = data;
    const {
      complete_audit,
      start_audit,
      invalidate_audit
    } = data._actions.actions;
    return (
      <div>
        {start_audit ? (
          <StartAudit data={data} reload={reload} />
        ) : (
          <Fragment>
            {complete_audit && <CompleteAudit data={data} reload={reload} />}
            {complete_audit && invalidate_audit && <Divider />}
            {invalidate_audit && (
              <InvalidateAudit data={data} reload={reload} />
            )}
          </Fragment>
        )}
        <Table celled padded selectable>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Status</Table.HeaderCell>
              <Table.HeaderCell>Started By</Table.HeaderCell>
              <Table.HeaderCell>Cancelled By</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {audits.length ? (
              audits
                .sort((a, b) => new Date(b.started_on) - new Date(a.started_on))
                .map((audit, index) => (
                  <Table.Row key={index}>
                    <Table.Cell>
                      {audit.invalidated_on ? (
                        <b>Cancelled</b>
                      ) : audit.completed_on ? (
                        <Fragment>
                          <b>Completed</b>
                          <br />
                          {moment(audit.completed_on).fromNow()}
                        </Fragment>
                      ) : (
                        <b>In Progress</b>
                      )}
                    </Table.Cell>
                    <Table.Cell>
                      <b>
                        {audit.started_by.firstname +
                          " " +
                          audit.started_by.lastname}
                      </b>
                      <br />
                      {moment(audit.started_on).fromNow()}
                    </Table.Cell>
                    <Table.Cell>
                      {audit.invalidated_on && (
                        <Fragment>
                          <b>
                            {audit.invalidated_by.firstname +
                              " " +
                              audit.invalidated_by.lastname}
                          </b>
                          <br />
                          {moment(audit.invalidated_on).fromNow()}
                        </Fragment>
                      )}
                    </Table.Cell>
                  </Table.Row>
                ))
            ) : (
              <Table.Row>
                <Table.Cell colSpan={3}>
                  <br />
                  There are no audits to show.
                  <br />
                  <br />
                </Table.Cell>
              </Table.Row>
            )}
          </Table.Body>
        </Table>
      </div>
    );
  }
}
