import React, { Component } from "react";
import { Form } from "semantic-ui-react";
import styled from "styled-components";

import AjaxFn from "services/AjaxFn";
import { Wrapper } from "components/Agreement/context";

class Submit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formIsValid: false
    };
  }

  componentDidMount() {
    this.validate();
  }

  componentDidUpdate = (prevProps, prevState) => {
    if (prevProps.context.data !== this.props.context.data) {
      this.validate();
    }
  };

  validate = () => {
    const {
      context: { data }
    } = this.props;

    let status;

    const validFields =
      data.traveler_name &&
      data.address &&
      data.city &&
      data.state &&
      data.zip &&
      data.home_phone &&
      data.account_number &&
      data.initial &&
      data.signature;

    if (validFields) {
      status = true;
    } else {
      status = false;
    }

    this.setState({ formIsValid: status });
  };

  render() {
    const { context } = this.props;
    const {
      functions: { handleSubmit }
    } = context;
    const { formIsValid } = this.state;

    const disabled = !formIsValid;

    return (
      <StyledButton
        type="submit"
        content="Submit"
        disabled={disabled}
        onClick={handleSubmit}
        primary
      />
    );
  }
}

export default Wrapper(Submit);

const StyledButton = styled(Form.Button)`
  position: fixed;
  right: 1rem;
  bottom: 1rem;
`;
