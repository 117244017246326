import React from "react";
import { Message, Icon, List, Grid } from "semantic-ui-react";
import { formatMJY } from "utils/dates";
export default class Cancellations extends React.Component {
  state = {
    open: false
  };
  toggleOpen = () => this.setState({ open: !this.state.open });
  handleClick = id => {
    if (window.confirm("Are you sure you want to uncancel the booking?")) {
      this.props.uncancel(id);
    }
  };
  render() {
    const fn = row => row.active;
    const cancellation = this.props.data.cancellations.find(fn);
    if (!cancellation) {
      return null;
    }
    return (
      <div>
        <Message warning>
          <Message.Header>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <span>This booking has been cancelled.</span>
              <a
                href={null}
                onClick={this.toggleOpen}
                style={{ fontWeight: "normal !important" }}
              >
                {this.state.open ? "Show Less" : "Show More"}
              </a>
            </div>
          </Message.Header>
          {this.state.open && (
            <div>
              <br />
              <Grid>
                <Grid.Column width={4}>
                  <List>
                    <List.Item>
                      <List.Icon name="user" />
                      <List.Content>
                        <List.Header>Cancelled By</List.Header>
                        <List.Description>
                          {cancellation.cancelled_by.firstname +
                            " " +
                            cancellation.cancelled_by.lastname}
                        </List.Description>
                      </List.Content>
                    </List.Item>
                    <List.Item>
                      <List.Icon name="calendar outline" />
                      <List.Content>
                        <List.Header>Cancelled On</List.Header>
                        <List.Description>
                          {formatMJY(cancellation.date)}
                        </List.Description>
                      </List.Content>
                    </List.Item>
                    <List.Item>
                      <List.Icon name="edit" />
                      <List.Content>
                        <List.Header>Reason</List.Header>
                        <List.Description>
                          {reasons[cancellation.reason] || "Unknown Reason"}
                        </List.Description>
                      </List.Content>
                    </List.Item>
                  </List>
                </Grid.Column>
                <Grid.Column width={6}>
                  <List>
                    <List.Item>
                      <List.Icon name="comment" />
                      <List.Content>
                        <List.Header>Comments</List.Header>
                        <List.Description>
                          {cancellation.comments || "No comment was left"}
                        </List.Description>
                      </List.Content>
                    </List.Item>
                  </List>
                </Grid.Column>
                <Grid.Column width={6} style={{ borderLeft: "1px solid #ddd" }}>
                  <p>
                    <Icon name={"info"} />A booking that has been cancelled can
                    not be changed/saved until it is uncancelled. To uncancel
                    it, go to actions and click uncancel.
                  </p>
                  <p>
                    <Icon name={"info"} />
                    If the name is "Temporary Temporary" or the date is "Jan 1,
                    1970" the booking was cancelled before that information was
                    tracked.
                  </p>
                </Grid.Column>
              </Grid>
              {/* <Divider />
	            <UncancelButton /> */}
            </div>
          )}
        </Message>
        <br />
      </div>
    );
  }
}

const reasons = {
  1: "The Owner Cancelled",
  2: "The Guest Cancelled",
  3: "Not Available/Calendar Issue",
  4: "The Guest Didn't Pay"
};
