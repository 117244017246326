import React from "react";
import { Divider, Button } from "semantic-ui-react";
import { validateUnit } from "../../validate";
import WithState from "components/WithState";
import SelectBaseUnit from "./components/SelectBaseUnit";
import SelectSubUnit from "./components/SelectSubUnit";
import { loadUnits } from "redux/BookingWizard/actions";
import { connect } from "react-redux";
class UnitStep extends React.Component {
  componentDidMount() {
    const { units, loadUnits } = this.props;
    if (!units.data.length) {
      loadUnits();
    }
  }
  render() {
    const {
      data,
      setData,
      resetData,
      setStep,
      units,
      setBaseUnit
    } = this.props;
    return (
      <div>
        <WithState loading={units.loading} error={units.error}>
          <SelectBaseUnit setBaseUnit={setBaseUnit} data={data} />
          {data.unit && <SelectSubUnit setData={setData} data={data} />}
          <p>Only units set to "active" will show up in the dropdowns above.</p>
        </WithState>
        <Divider />
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Button onClick={() => setStep("guest")} content={"Back"} />
          <div style={{ textAlign: "right" }}>
            <Button onClick={resetData} content={"Reset"} />
            <Button
              onClick={() => setStep("details")}
              disabled={!validateUnit(data, units)}
              content={"Next"}
            />
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = state => ({
  units: state.BookingWizard.units
});
const mapDispatchToProps = dispatch => ({
  loadUnits: () => dispatch(loadUnits())
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UnitStep);
