import React from "react";
import {
  Button,
  Modal,
  Icon,
  Header,
  Input,
  Dropdown,
  Form,
  Grid,
  TextArea
} from "semantic-ui-react";
import { connect } from "react-redux";
import { reloadBooking } from "redux/BookingPage/actions";
import AjaxFn from "services/AjaxFn";
import { timerestrict_booking } from "utils/user";
class CancelButton extends React.Component {
  state = {
    loading: false,
    open: false,
    data: {
      ...defaultStateData
    }
  };
  handleCancel = () => {
    const { booking, reload } = this.props;
    this.setState({ loading: true });
    AjaxFn({
      url: "/apis/portal/bookings",
      data: {
        action: "cancel",
        data: {
          ID: booking.id,
          ...this.state.data
        }
      },
      success: () => reload(),
      failure: error => {
        this.setState({ loading: false });
        window.toastr.error(error);
      }
    });
  };
  handleOpen = () => this.setState({ open: true });
  handleClose = () => this.setState({ open: false });
  reset = () => this.setState({ data: { ...defaultStateData } });
  isNumeric = n => {
    return !isNaN(parseFloat(n)) && isFinite(n);
  };
  /**
   *	Callback for onChange for createInput
   *  @param {ReactElement} element
   *  @param {String} field
   *  @param {Object} props
   *  @return {ReactElement}
   */
  createInput = (element, field, props) => {
    return React.cloneElement(element, {
      value: this.state.data[field] || "",
      onChange: (e, { value }) => this.standardOnChange(value, field),
      ...props
    });
  };
  /**
   *	Callback for onChange for createInput
   *  @param {String} field
   *	@param {Mixed} value
   */
  standardOnChange = (value, field) => {
    this.setState({
      data: {
        ...this.state.data,
        [field]: value
      }
    });
  };
  /**
   *	Callback for onChange for the amount field
   *	@param {Object} event
   */
  onRefundChange = (e, { value }) => {
    if (value === "" || value.match(/^([0-9]+)?\.?([0-9]{1,2})?$/)) {
      this.setState({
        data: {
          ...this.state.data,
          refund: value
        }
      });
    }
  };
  /**
   *	Decimal formats the refund on blur
   */
  onRefundBlur = () =>
    this.setState(state => {
      return {
        data: {
          ...state.data,
          refund: !state.data.refund
            ? "0.00"
            : parseFloat(state.data.refund).toFixed(2)
        }
      };
    });
  /**
   *	Reads the codes array and displays the relevant comment destinations
   *  @param {Object} code (the code)
   */
  getWhereCommentsPost = ({ owner, booking }) => {
    if (owner && booking) {
      return (
        <p>
          This comment will be posted <b>on the owner</b> and{" "}
          <b>on the booking</b>.
        </p>
      );
    } else if (owner) {
      return (
        <p>
          This comment will be posted <b>on the owner</b>.
        </p>
      );
    } else if (booking) {
      return (
        <p>
          This comment will be posted <b>on the booking</b>.
        </p>
      );
    } else {
      return <p>This comment will not be posted.</p>;
    }
  };
  /**
   *	Reads the codes array and displays the relevant comment topics
   *	based on this.state.reason
   *  @param {Object} code
   */
  getTopics = code =>
    code.comments.map((topic, key) => <li key={key}>{topic}</li>);
  render() {
    const { user, booking } = this.props;
    const can_cancel = timerestrict_booking(
      booking,
      user,
      ["BOOKINGS_CANCEL"],
      ["BOOKINGS_WIZARD"]
    );
    if (!can_cancel) {
      return <p>You do not have access to cancel bookings.</p>;
    }
    // const { loading } = this.state;
    const trigger = (
      <Button color={"red"} onClick={this.handleOpen}>
        Cancel Booking
      </Button>
    );
    const currentCode = codes
      .filter(code => code.id === this.state.data.reason)
      .pop();
    return (
      <Modal
        onClose={this.handleClose}
        trigger={trigger}
        open={this.state.open}
      >
        <Header as="h2" icon textAlign="center">
          <Icon name={"exclamation triangle"} color={"red"} />
          Cancel Booking
        </Header>
        <Modal.Content>
          <Form>
            <Grid columns={"2"}>
              <Grid.Column>
                <Form.Field required>
                  <label>Refunded Amount</label>
                  {this.createInput(<Input />, "refund", {
                    placeholder: "0.00",
                    label: "$",
                    onChange: this.onRefundChange,
                    onBlur: this.onRefundBlur
                  })}
                </Form.Field>
                <Form.Field required>
                  <label>Reason for Cancel</label>
                  {this.createInput(<Dropdown />, "reason", {
                    selection: true,
                    placeholder: "Select a reason",
                    options: codes.map(code => ({
                      value: code.id,
                      text: code.text
                    }))
                  })}
                </Form.Field>
              </Grid.Column>
              <Grid.Column>
                <Form.Field required>
                  <label>Comments</label>
                  {this.createInput(<TextArea />, "comments", {
                    placeholder: "Add some context"
                  })}
                </Form.Field>
                <ul>{this.getTopics(currentCode)}</ul>
                {this.getWhereCommentsPost(currentCode)}
              </Grid.Column>
            </Grid>
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button
            onClick={this.reset}
            disabled={this.state.loading}
            content={"Reset"}
            icon={"undo"}
          />
          <Button
            onClick={this.handleClose}
            content={"Nevermind"}
            icon={"close"}
            disabled={this.state.loading}
          />
          <Button
            color={"red"}
            onClick={this.handleCancel}
            content={"Cancel Booking"}
            icon={"checkmark"}
            loading={this.state.loading}
            disabled={this.state.loading || !this.state.data.comments.trim()}
          />
        </Modal.Actions>
      </Modal>
    );
  }
}
const mapStateToProps = state => ({
  booking: state.BookingPage.booking.data,
  user: state.User
});
const mapDispatchToProps = dispatch => ({
  reload: () => dispatch(reloadBooking())
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CancelButton);

const defaultStateData = {
  refund: "0.00",
  comments: "",
  reason: 1
};

const codes = [
  {
    id: 1,
    text: "Owner Cancelled",
    booking: true,
    owner: true,
    comments: [
      "Can you log into the owner?",
      "Why was the reservation cancelled?",
      "When was the reservation cancelled?",
      "When did we find out it was cancelled?",
      "Did you notify customer service of the issue with the owner?"
    ]
  },
  {
    id: 2,
    text: "Guest Cancelled",
    booking: true,
    owner: false,
    comments: [
      "Was our cancellation policy enforced?",
      "Why did the guest cancel?",
      "Is a refund required? If so, how much?"
    ]
  },
  {
    id: 3,
    text: "Calendar Issue/Not Available",
    booking: true,
    owner: false,
    comments: [
      "Did you check the calendar to make sure it correct and syncing?",
      "Is a refund required? If so how much?",
      "Did we incur channel cancellation fines? If so how much?",
      "Did you offer the guest alternative properties or dates?"
    ]
  },
  {
    id: 4,
    text: "Guest Didn't Pay",
    booking: true,
    owner: false,
    comments: [
      "How many emails did you send to the guest?",
      "How many times have you tried to call the guest?",
      "Did you cancel the booking in the owners account?",
      "If the booking was not cancelled in time for the points to be refunded, how many were lost?"
    ]
  }
];
