import React, { Component } from "react";
import PropTypes from "prop-types";
import { Modal, Icon, Button } from "semantic-ui-react";

export default class Skip extends Component {
  static propTypes = {
    open: PropTypes.bool.isRequired,
    skipTutorial: PropTypes.func.isRequired,
    closeSkipModal: PropTypes.func.isRequired
  };

  render() {
    return (
      <Modal open={this.props.open} basic size="mini">
        <Modal.Header>Skip tutorial?</Modal.Header>
        <Modal.Content>
          Click yes to skip the rest of the tutorial. It won't show up again
          unless you restart it.
        </Modal.Content>
        <Modal.Actions>
          <Button
            basic
            color="red"
            inverted
            onClick={this.props.closeSkipModal}
          >
            <Icon name="remove" /> No
          </Button>
          <Button color="green" inverted onClick={this.props.skipTutorial}>
            <Icon name="checkmark" /> Yes
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}
