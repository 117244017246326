import React, { Component, Fragment } from "react";
import { Modal, Header, Icon, Button, Table, Segment } from "semantic-ui-react";
import AjaxFn from "services/AjaxFn";
import { formatMJY } from "utils/dates";
/**
 *	@prop booking
 *	@prop reload
 */
export default class FinancesAudited extends Component {
  state = {
    loading: false,
    open: false
  };
  handleClick = value => {
    this.setState({ loading: true });
    AjaxFn({
      url: "/apis/portal/bookingfinances",
      data: {
        action: "create",
        data: {
          amount: value ? "1.00" : "0.00",
          type: "audited",
          reason: value ? "Audited Finances" : "De-Audited Finances",
          booking_id: this.props.booking.id
        }
      },
      success: this.props.reload,
      failure: e => {
        window.toastr.error(e);
        this.setState({ loading: false });
      }
    });
  };
  render() {
    const audits = this.props.booking.finances
      .filter(({ type }) => type === "audited")
      .sort((a, b) => new Date(b.created_on) - new Date(a.created_on));
    const trigger = (
      <div onClick={() => this.setState({ open: true })}>
        {this.props.children}
      </div>
    );
    const AuditDeAuditButtons = (
      <Fragment>
        <p>
          Use the button below to audit/de-audit the finances for the booking.
        </p>
        {this.props.audited ? (
          <Button color={"red"} onClick={() => this.handleClick(0)}>
            DeAudit
          </Button>
        ) : (
          <Button color={"blue"} onClick={() => this.handleClick(1)}>
            Audit
          </Button>
        )}
      </Fragment>
    );
    return (
      <Modal open={this.state.open} trigger={trigger} size={"small"}>
        <Modal.Header>Financial Audit</Modal.Header>
        {this.state.loading ? (
          <Modal.Content>
            <Segment
              loading
              style={{ paddingTop: "8rem", paddingBottom: "8rem" }}
            />
          </Modal.Content>
        ) : (
          <Modal.Content>
            {this.props.showIfEditable(AuditDeAuditButtons, "audit_finances")}
            <br />
            <br />
            {audits.length > 0 && (
              <div>
                <br />
                <Header>
                  <Icon name="history" />
                  <Header.Content>
                    History
                    <Header.Subheader>Current and past values</Header.Subheader>
                  </Header.Content>
                </Header>
                <div style={{ maxHeight: "300px", overflow: "auto" }}>
                  <Table striped celled>
                    <Table.Header>
                      <Table.Row>
                        <Table.HeaderCell>Type</Table.HeaderCell>
                        <Table.HeaderCell>Date</Table.HeaderCell>
                        <Table.HeaderCell>Updated By</Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      {audits.map((setting, key) => (
                        <Table.Row key={key}>
                          <Table.Cell>
                            {setting.amount === "1.00" ? "Audit" : "DeAudit"}
                          </Table.Cell>
                          <Table.Cell>
                            {formatMJY(setting.created_on)}
                          </Table.Cell>
                          <Table.Cell>
                            {setting.created_by.firstname +
                              " " +
                              setting.created_by.lastname[0]}
                          </Table.Cell>
                        </Table.Row>
                      ))}
                    </Table.Body>
                  </Table>
                </div>
                <br />
                <p>
                  <Icon name={"info"} />
                  The highest row is the current value for the booking.
                </p>
              </div>
            )}
          </Modal.Content>
        )}
        <Modal.Actions>
          <Button onClick={() => this.setState({ open: false })}>Close</Button>
        </Modal.Actions>
      </Modal>
    );
  }
}
