import React, { Component } from "react";
import PropTypes from "prop-types";

import { Container } from "semantic-ui-react";

import Layout from "components/Layout";

import Breadcrumb from "./components/Breadcrumb";
import MemberLoader from "./components/MemberLoader";
import MembershipLoader from "./components/MembershipLoader";
import Renderer from "./components/Renderer";

export default class MemberPage extends Component {
  static propTypes = {
    match: PropTypes.object.isRequired
  };

  reloadMember = () => this.mRef && this.mRef.reload();
  reloadMembership = () => this.msRef && this.msRef.reload();

  render() {
    const {
      memberID,
      membershipID,
      membershipTab,
      memberTab
    } = this.props.match.params;
    const mode = typeof membershipID !== "undefined" ? "membership" : "member";

    const membershipConfig = {
      membershipID: membershipID,
      ref: r => (this.msRef = r)
    };

    const memberConfig = {
      memberID: memberID,
      ref: r => (this.mRef = r)
    };

    return (
      <Layout>
        <Breadcrumb />
        <br />
        <Container>
          <MemberLoader {...memberConfig}>
            {memberData => (
              <MembershipLoader {...membershipConfig}>
                {membershipData => {
                  return (
                    <Renderer
                      memberData={memberData}
                      membershipData={membershipData}
                      memberTab={memberTab}
                      membershipTab={membershipTab}
                      reloadMember={this.reloadMember}
                      reloadMembership={this.reloadMembership}
                      memberID={memberID}
                      membershipID={membershipID}
                      mode={mode}
                    />
                  );
                }}
              </MembershipLoader>
            )}
          </MemberLoader>
        </Container>
      </Layout>
    );
  }
}
