import React, { Component } from "react";
import { Button, Form, Input, Grid, Header } from "semantic-ui-react";
import AjaxFn from "services/AjaxFn";

export default () => null;

/**
 *	@prop newIDs {Array}
 *	@prop addAnotherID {Function}
 *	@prop getNewIDField {Function}
 *  @prop cloneID {String/Integer}
 *  @prop setCloneID {Function}
 */
// export default class CloneForm extends Component {
//   /**
//    *	If the reset button is clicked, we need to clear this.state.search and
//    *  this.state.clone. We determine when by checking if cloneID changes to false
//    */
//   componentWillReceiveProps(newProps) {
//     if (newProps.cloneID === false) {
//       if (this.state.search !== "" || this.state.clone !== false) {
//         this.setState({
//           clone: false,
//           search: ""
//         });
//       }
//     }
//   }
//   state = {
//     search: "",
//     clone: false,
//     loading: false
//   };
//   /**
//    *	Callback for when target ciirusID changes.
//    *  @param {String} value
//    */
//   handleChange = value => {
//     this.setState({
//       search: value,
//       clone: false,
//       loading: true
//     });
//     AjaxFn({
//       url: "/apis/portal/units/searchByCiirusID",
//       data: { ciirus_id: value },
//       success: this.handleSuccess,
//       failure: this.handleFailure
//     });
//   };
//   handleSuccess = message => {
//     if (message.id) {
//       this.setState(
//         {
//           loading: false,
//           clone: message.ciirus_name
//         },
//         () => this.props.setCloneID(message.id)
//       );
//     } else {
//       this.setState({
//         loading: false,
//         clone: false
//       });
//     }
//   };
//   handleFailure = error => {
//     this.setState({ loading: false });
//     window.toastr.error(error);
//   };
//   /**
//    *	Render output
//    */
//   render() {
//     return (
//       <React.Fragment>
//         <Header as={"h3"} dividing>
//           Find a unit
//         </Header>
//         <Grid columns={2}>
//           <Grid.Column>
//             <p>
//               Search for a unit based on its Ciirus ID. This is done
//               automaticaly if you got here from the clone button. For every
//               extra Ciirus ID entered in the New Ciirus IDs section a unit will
//               be created with the corresponding ID, and the ciirus name will be
//               set automatically. The following information will be cloned to the
//               unit: The unit's resort, name, bedrooms, bathrooms, bed types,
//               sleeps, kitchen type, sq footage, features.
//             </p>
//             <p>
//               <b>
//                 NOTE: The unit will not be automatically added to a property
//                 group with the rest of them.
//               </b>
//             </p>
//             <p>
//               <b>
//                 NOTE: If you update a unit, clones are not automatically updated
//                 (yet).
//               </b>
//               For example if you clone a unit, then change its bed types, the
//               clone will have what was saved at the time it was cloned.
//             </p>
//           </Grid.Column>
//           <Grid.Column>
//             <Form>
//               <Form.Field>
//                 <Input
//                   label="Target CiiRUS ID #"
//                   placeholder="000000"
//                   value={this.state.search || ""}
//                   onChange={(e, { value }) => this.handleChange(value)}
//                 />
//               </Form.Field>
//               <Form.Field>
//                 <Input
//                   readOnly
//                   label={"Unit found"}
//                   icon={"lock"}
//                   loading={this.state.loading}
//                   value={this.state.clone || ""}
//                   placeholder={"Search for a unit above"}
//                 />
//               </Form.Field>
//               {this.state.clone && (
//                 <React.Fragment>
//                   {this.props.newIDs.map((value, index) => (
//                     <Form.Group key={index}>
//                       <Form.Field width={8}>
//                         {this.props.getNewIDField(value, index)}
//                       </Form.Field>
//                     </Form.Group>
//                   ))}
//                   <Button onClick={this.props.addAnotherID}>Add Another</Button>
//                 </React.Fragment>
//               )}
//             </Form>
//           </Grid.Column>
//         </Grid>
//       </React.Fragment>
//     );
//   }
// }
