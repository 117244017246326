import styled from "styled-components";
import { Table } from "semantic-ui-react";

export const ClickableRow = styled(Table.Row)`
  cursor: pointer;
  &:hover {
    &&& td {
      background: #fafafa !important;
    }
  }
`;

export const DeletedRow = styled(ClickableRow)`
  &&& td {
    text-decoration: line-through;
  }
`;
