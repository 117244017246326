import React from "react";
import PropTypes from "prop-types";

import { Form } from "semantic-ui-react";

import { setAuditedOnly } from "redux/EOQReport/actions";
import { connect } from "react-redux";

const AuditedOnly = ({ auditedOnly, setAuditedOnly }) => {
  return (
    <Form.Checkbox
      toggle
      style={{ marginTop: ".5rem" }}
      checked={auditedOnly}
      onChange={() => setAuditedOnly(!auditedOnly)}
    />
  );
};

const mapStateToProps = state => ({
  auditedOnly: state.EOQReport.auditedOnly
});

const mapDispatchToProps = {
  setAuditedOnly
};

AuditedOnly.propTypes = {
  auditedOnly: PropTypes.bool.isRequired,
  setAuditedOnly: PropTypes.func.isRequired
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AuditedOnly);
