import React from "react";
import { Button, Modal, Form, Input, Message } from "semantic-ui-react";
import styled from "styled-components";

export default class WizardClass extends React.PureComponent {
  render() {
    const { ContentManager, Wizard } = this.props;
    return (
      <Modal open={ContentManager.state.wizardActive} size={"small"}>
        <Modal.Header>Create Bank</Modal.Header>
        <Modal.Content>
          <Form>
            <Form.Group widths={"equal"}>
              <Form.Field>
                <label>Portal Name</label>
                {Wizard.functions.createInput(<Input fluid />, "portal_name")}
              </Form.Field>
            </Form.Group>
            <br />

            <Form.Group widths={"equal"}>
              <Form.Field>
                <label>Bank Name</label>
                {Wizard.functions.createInput(<Input fluid />, "bank_name")}
              </Form.Field>
              <Form.Field>
                <label>Company</label>
                {Wizard.functions.createInput(<Input fluid />, "company_name")}
              </Form.Field>
            </Form.Group>
            <Form.Group widths={"equal"}>
              <Form.Field>
                <label>Bank Address</label>
                {Wizard.functions.createInput(<Input fluid />, "bank_address")}
              </Form.Field>
              <Form.Field>
                <label>Company Address</label>
                {Wizard.functions.createInput(
                  <Input fluid />,
                  "company_address"
                )}
              </Form.Field>
            </Form.Group>
            <Form.Group widths={"equal"}>
              <Form.Field>
                <label>Bank Address 2</label>
                {Wizard.functions.createInput(
                  <Input fluid />,
                  "bank_address_2"
                )}
              </Form.Field>
              <Form.Field>
                <label>Company Address 2</label>
                {Wizard.functions.createInput(
                  <Input fluid />,
                  "company_address_2"
                )}
              </Form.Field>
            </Form.Group>
            <br />

            <Form.Group>
              <Form.Field width={4}>
                <label>Account</label>
                {Wizard.functions.createInput(
                  <Input fluid />,
                  "account_number",
                  {
                    label: "#",
                    type: "number"
                  }
                )}
              </Form.Field>
              <Form.Field width={4}>
                <label>Routing</label>
                {Wizard.functions.createInput(
                  <Input fluid />,
                  "routing_number",
                  {
                    label: "#",
                    type: "number"
                  }
                )}
              </Form.Field>
              <Form.Field width={8}>
                <label>Signature</label>
                {Wizard.functions.createInput(<Input fluid />, "signature")}
              </Form.Field>
            </Form.Group>
          </Form>
        </Modal.Content>
        <Modal.Content>
          <Message info>
            <Message.Header>Info</Message.Header>
            <p>
              The "Portal Name" is what the check appears as in dropdowns. It
              will not show on the check.
            </p>
            <p>
              Leave the signature field blank to display a "Signature not
              required" message on the check.
            </p>
          </Message>
        </Modal.Content>
        <ModalActions>
          <Button
            content="Close"
            onClick={ContentManager.functions.closeWizard}
            basic
          />
          {Object.keys(Wizard.data).length ? (
            <div>
              <Button
                type={"button"}
                content={"Discard"}
                icon="undo"
                onClick={Wizard.functions.reset}
                basic
              />
              <Button
                type={"button"}
                onClick={Wizard.functions.save}
                loading={ContentManager.state.wizardLoading}
                icon={"save"}
                content={"Save"}
                color={"blue"}
              />
            </div>
          ) : null}
        </ModalActions>
      </Modal>
    );
  }
}

const ModalActions = styled(Modal.Actions)`
  display: flex;
  justify-content: space-between;
  align-items: stretch;

  &&& {
    button {
      height: 100%;
      margin: 0 0.25em 0 0.75em;
      &:first-of-type {
        justify-self: flex-start;
      }
    }
  }
`;
