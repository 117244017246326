import React, { useRef } from "react";
import PropTypes from "prop-types";

import { Container } from "semantic-ui-react";

import Layout from "components/Layout";
import Breadcrumb from "./components/Breadcrumb";
import GuestLoader from "./components/GuestLoader";
import Renderer from "./components/Renderer";

const GuestPage = props => {
  const guestLoaderRef = useRef(null);

  const { guestID, guestTab } = props.match.params;

  const guestConfig = {
    guestID: guestID,
    ref: guestLoaderRef
  };

  const reload = () => {
    if (guestLoaderRef.current) {
      guestLoaderRef.current.reload();
    }
  };

  return (
    <Layout>
      <Breadcrumb />
      <br />
      <Container>
        <GuestLoader {...guestConfig}>
          {guestData => {
            return (
              <Renderer
                guestData={guestData}
                guestID={guestID}
                guestTab={guestTab}
                reloadGuest={reload}
              />
            );
          }}
        </GuestLoader>
      </Container>
    </Layout>
  );
};

GuestPage.propTypes = {
  match: PropTypes.object.isRequired
};

export default GuestPage;
