import React, { Component } from "react";

import { Container } from "semantic-ui-react";

import Layout from "components/Layout";
import ContextBar from "components/ContextBar";
import FocusBar from "components/FocusBar";

export default class FocusBarTest extends Component {
  renderDrawer() {
    return (
      <>
        <FocusBar.Content>
          <FocusBar.Button icon="far fa-file" content="Generic Agreement" />
          <FocusBar.Button icon="far fa-file" content="Generic Agreement" />
          <FocusBar.Button icon="far fa-file" content="Generic Agreement" />
          <FocusBar.Button
            icon="far fa-file"
            content="Generic Agreement"
            color="blue"
            basic={false}
            inverted={false}
            hovereffect={false}
          />
          <FocusBar.Button icon="far fa-file" content="Generic Agreement" />
        </FocusBar.Content>
        <FocusBar.Actions>
          <FocusBar.Button icon="fas fa-times" content="Cancel" />
          <FocusBar.Button
            icon="fas fa-check"
            content="Change Agreement Template"
          />
        </FocusBar.Actions>
      </>
    );
  }

  render() {
    return (
      <Layout>
        <ContextBar />
        <FocusBar>
          {/* <FocusBar.Content>
            <FocusBar.Dropdown content="Elements">
              <FocusBar.Button content="Item" icon="far fa-square" />
              <FocusBar.Button content="Item" icon="far fa-square" />
              <FocusBar.Button content="Item" icon="far fa-square" />
            </FocusBar.Dropdown>
            <FocusBar.Dropdown content="Groups">
              <FocusBar.Button content="Item" icon="far fa-square" />
              <FocusBar.Button content="Item" icon="far fa-square" />
              <FocusBar.Button content="Item" icon="far fa-square" />
            </FocusBar.Dropdown>
            <FocusBar.Dropdown content="Custom Shop">
              <FocusBar.Button content="Item" icon="far fa-square" />
              <FocusBar.Button content="Item" icon="far fa-square" />
              <FocusBar.Button content="Item" icon="far fa-square" />
            </FocusBar.Dropdown>
            <FocusBar.Dropdown content="Legal">
              <FocusBar.Button content="Item" icon="far fa-square" />
              <FocusBar.Button content="Item" icon="far fa-square" />
              <FocusBar.Button content="Item" icon="far fa-square" />
            </FocusBar.Dropdown>
          </FocusBar.Content> */}
          <FocusBar.Content>
            <FocusBar.Item
              color="blue"
              content="Agreement Started"
              icon="fas fa-calendar-day"
              description="January 16th 2019, 9:57 am"
            />
            <FocusBar.Item content="Agreement Sent" icon="fas fa-envelope" />
            <FocusBar.Item content="Agreement Submitted" icon="fas fa-inbox" />
            <FocusBar.Item content="Agreement Processed" icon="fas fa-fax" />
            <FocusBar.Item content="Agreement Sent" icon="fas fa-envelope" />
            <FocusBar.Item content="Agreement Submitted" icon="fas fa-inbox" />
            <FocusBar.Item content="Agreement Processed" icon="fas fa-fax" />
            <FocusBar.Item content="Agreement Sent" icon="fas fa-envelope" />
            <FocusBar.Item content="Agreement Submitted" icon="fas fa-inbox" />
            <FocusBar.Item content="Agreement Processed" icon="fas fa-fax" />
            <FocusBar.Item content="Agreement Sent" icon="fas fa-envelope" />
            <FocusBar.Item content="Agreement Submitted" icon="fas fa-inbox" />
            <FocusBar.Item content="Agreement Processed" icon="fas fa-fax" />
            <FocusBar.Item content="Agreement Sent" icon="fas fa-envelope" />
            <FocusBar.Item content="Agreement Submitted" icon="fas fa-inbox" />
            <FocusBar.Item content="Agreement Processed" icon="fas fa-fax" />
            <FocusBar.Item content="Agreement Sent" icon="fas fa-envelope" />
            <FocusBar.Item content="Agreement Submitted" icon="fas fa-inbox" />
            <FocusBar.Item content="Agreement Processed" icon="fas fa-fax" />
            <FocusBar.Item content="Agreement Sent" icon="fas fa-envelope" />
            <FocusBar.Item content="Agreement Submitted" icon="fas fa-inbox" />
            <FocusBar.Item content="Agreement Processed" icon="fas fa-fax" />
            <FocusBar.Item content="Agreement Sent" icon="fas fa-envelope" />
            <FocusBar.Item content="Agreement Submitted" icon="fas fa-inbox" />
            <FocusBar.Item content="Agreement Processed" icon="fas fa-fax" />
          </FocusBar.Content>
          <FocusBar.Actions>
            <FocusBar.Button
              icon="fas fa-exchange-alt"
              content="View Dealsheet"
            />
            <FocusBar.Button
              icon="fas fa-print"
              content="Print Agreement"
              disabled
            />
            <FocusBar.Drawer
              icon="fas fa-retweet"
              content="Change Agreement Template"
              title="Change Agreement Template"
              backbutton={false}
              children={this.renderDrawer()}
            />
            <FocusBar.Button icon="fas fa-save" content="Save Changes" />
            <FocusBar.Button
              icon="fas fa-paper-plane"
              content="Send Agreement"
              disabled
            />
          </FocusBar.Actions>
        </FocusBar>

        <Container>
          <p>test page</p>
        </Container>
      </Layout>
    );
  }
}
