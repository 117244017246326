import React from "react";
import { Divider, Button, Dropdown } from "semantic-ui-react";
import { validateGuest } from "../../validate";
import WithState from "components/WithState";
import naturalSort from "utils/naturalSort";
import { loadGuests } from "redux/BookingWizard/actions";
import { connect } from "react-redux";
class GuestStep extends React.Component {
  componentDidMount() {
    const { guests, loadGuests } = this.props;
    if (!guests.data.length) {
      loadGuests();
    }
  }
  gotoGuestList = e => {
    e.preventDefault();
    window.open("/browse/guests");
  };
  render() {
    const {
      data,
      setData,
      resetData,
      setStep,
      guests,
      loadGuests
    } = this.props;
    return (
      <div>
        <WithState loading={guests.loading} error={guests.error}>
          <p>Select a guest for the booking.</p>
          <Dropdown
            selection
            search
            options={guests.data
              .filter(row => row.active)
              .map(row => ({
                value: row.id,
                text: row.firstname + " " + row.lastname
              }))
              .sort((a, b) => naturalSort(a.text, b.text))}
            value={data.guest}
            onChange={(e, { value }) => setData("guest", value)}
            placeholder={"Select a guest"}
            style={{ marginBottom: "1rem", width: "400px" }}
          />
          <Button onClick={loadGuests} style={{ marginLeft: ".25rem" }}>
            Reload
          </Button>
          <p>Only guests set to "active" will show up in the dropdown above.</p>
          <p>
            {"If the guest does not exist, you can open the "}
            <a href={"/browse/guests"} onClick={this.gotoGuestList}>
              Guests List
            </a>
            {
              ' in a new tab and use the "New Guest" button to add them. Once they are set to active, click "Reload" and they should appear in the list. '
            }
          </p>
        </WithState>
        <Divider />
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div />
          <div style={{ textAlign: "right" }}>
            <Button onClick={resetData} content={"Reset"} />
            <Button
              onClick={() => setStep("unit")}
              disabled={!validateGuest(data)}
              content={"Next"}
            />
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = state => ({
  guests: state.BookingWizard.guests
});
const mapDispatchToProps = dispatch => ({
  loadGuests: () => dispatch(loadGuests())
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GuestStep);
