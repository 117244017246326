import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Input } from "semantic-ui-react";
import _, { debounce } from "lodash";

import { setSearch } from "redux/PortalTables/actions";

const timeout = 500;

export class Search extends Component {
  static propTypes = {
    namespace: PropTypes.string.isRequired,
    setSearch: PropTypes.func.isRequired
  };

  constructor(props) {
    super(props);
    this.state = {
      search: "",
      typing: false
    };

    this.handleRoleSearch = debounce(this.handleRoleSearch, timeout);
  }

  handleChange = ({ target: { value } }) => {
    this.setState({ search: value, typing: true }, () => {
      if (value.length > 0) return this.handleRoleSearch(value);
      return this.handleRoleSearch();
    });
  };

  handleRoleSearch = value => {
    const { setSearch, namespace } = this.props;

    this.setState({ typing: false }, setSearch(namespace, value));
  };

  render() {
    const { typing } = this.state;

    return (
      <Input
        icon="search"
        defaultValue={this.props.defaultValue || ""}
        iconPosition="left"
        placeholder="Search the results..."
        onChange={this.handleChange}
        loading={typing}
        fluid
      />
    );
  }
}

const mapDispatchToProps = {
  setSearch
};

export default connect(
  undefined,
  mapDispatchToProps
)(Search);
