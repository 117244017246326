import React, { useContext } from "react";
import styled from "styled-components";

import { Button as SemanticButton, Icon } from "semantic-ui-react";

import { Context } from "../../";

const ButtonComponent = props => {
  const context = useContext(Context);
  const { expanded } = context.state;
  const {
    basic = true,
    color = undefined,
    content = "",
    description = "",
    disabled = false,
    drawer = false,
    icon = "",
    ...rest
  } = props;

  const buttonProps = {
    basic,
    color,
    disabled,
    expanded: expanded ? "true" : undefined,
    fluid: true,
    hovereffect: "true",
    inverted: true,
    title: content,
    ...rest
  };

  return (
    <Button {...buttonProps}>
      {icon && <i className={icon} />}
      {expanded && content}
      {description && expanded && (
        <p style={{ marginTop: ".5rem" }}>{description}</p>
      )}
      {drawer && expanded && (
        <DrawerIconDiv>
          <div style={{ flexGrow: 1 }} />
          <Icon name="chevron right" />
          <div style={{ flexGrow: 1 }} />
        </DrawerIconDiv>
      )}
    </Button>
  );
};
export default ButtonComponent;

const DrawerIconDiv = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  & i {
    font-size: 0.65rem !important;
    margin-right: 0.5rem !important;
  }
`;

const Button = styled(SemanticButton)`
  &&& {
    &&& {
      position: relative;
      display: inline-block;
      align-items: center;

      margin: 0;
      padding: 1rem;

      text-align: left;
      font-weight: 600;

      border: 1px solid transparent;
      box-shadow: none !important;

      &:disabled {
        cursor: not-allowed;
      }

      ${props => {
        if (!props.expanded) {
          return `
            border-radius: 0;
            padding-left: 0;
            padding-right: 0;
            text-align: center;
            justify-content: center;
          `;
        }
      }}

      ${props => {
        if (props.hovereffect) {
          return `
            &:hover {
              background: rgba(255, 255, 255, 0.05) !important;
              border: 1px solid rgba(255, 255, 255, 0.055) !important;
            }
            &:focus {
              background: rgba(255, 255, 255, 0.075) !important;
              border: 1px solid #2185d0 !important;
            }
          `;
        }
      }};
    }
  }
  i {
    margin-right: ${props => (props.expanded ? "1rem" : "0px !important")};
  }
`;
