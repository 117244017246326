import React from "react";
import { connect } from "react-redux";
import injectSheet from "react-jss";
import AlignmentHeader from "./components/AlignmentHeader";
import ReactDataGrid from "react-data-grid";
import { StickyTable, Row, Cell } from "react-sticky-table";
import "react-sticky-table/dist/react-sticky-table.css";

class Spreadsheet extends React.Component {
  render() {
    const { data } = this.props;
    console.log("in spreadsheet", data);
    const style = {
      style: {
        whiteSpace: "nowrap",
        padding: ".25rem 1rem",
        border: "1px solid #eee"
      }
    };
    const containerStyle = {
      width: "100%",
      border: "1px solid #ccc",
      height: "800px"
    };
    return (
      <div>
        <AlignmentHeader data={data} />
        <div style={containerStyle}>
          <StickyTable>
            <Row>
              {data.datagrid.columns.map((col, ix) => (
                <Cell key={ix} {...style}>
                  {col.name}
                </Cell>
              ))}
            </Row>
            {data.datagrid.rows.map((row, ix) => (
              <Row key={ix}>
                {data.datagrid.columns.map((col, colix) => {
                  const src = row[col.key];
                  return (
                    <Cell
                      key={colix}
                      {...style}
                      className={
                        typeof col.class === "function" ? col.class(src) : ""
                      }
                    >
                      {typeof col.formatter === "function"
                        ? col.formatter(src)
                        : src}
                    </Cell>
                  );
                })}
              </Row>
            ))}
          </StickyTable>
          {/* <table style={{ width: "100%" }}>
            <thead>
              <tr>
                {data.datagrid.columns.map((col, ix) => (
                  <th key={ix} {...style}>
                    {col.name}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {data.datagrid.rows.map((row, ix) => (
                <tr key={ix}>
                  {data.datagrid.columns.map((col, colix) => {
                    return (
                      <td
                        key={colix}
                        {...style}
                        className={
                          typeof col.class === "function" ? col.class(src) : ""
                        }
                      >
                        {typeof col.formatter === "function"
                          ? col.formatter(src)
                          : src}
                      </td>
                    );
                  })}
                </tr>
              ))}
            </tbody>
          </table> */}
        </div>
      </div>
    );
  }
}

const styles = {
  // ...
};

export default injectSheet(styles)(Spreadsheet);
