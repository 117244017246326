import React, { Component } from "react";
import PropTypes from "prop-types";
import { debounce } from "lodash";

import { Icon, Form } from "semantic-ui-react";
import AjaxFn from "services/AjaxFn";

const scaryMessageThing =
  "An agreement with this name or email already exists. You must get approval from your supervisor to proceed. Failure to do so will result in disciplinary action up to termination of employment.";

export default class VerifiedInput extends Component {
  static propTypes = {
    value: PropTypes.string.isRequired,
    setValue: PropTypes.func.isRequired,
    field: PropTypes.string.isRequired
  };

  state = {
    loaded: false,
    loading: false,
    match: false,
    cancel: false
  };

  componentWillUnmount() {
    this.cancel();
  }

  cancel = () => {
    if (typeof this.state.cancel === "function") {
      this.state.cancel();
    }
  };

  checkForMatches = debounce(() => {
    this.cancel();
    this.setState({
      loaded: false,
      loading: true,
      match: false,
      cancel: AjaxFn({
        url: "/apis/portal/agreements",
        data: {
          action: "check_for_matches",
          data: {
            field: this.props.field,
            value: this.props.value
          }
        },
        success: match => {
          this.setState({ loading: false, loaded: true, match, cancel: false });
          if (match) {
            window.toastr.error(scaryMessageThing, "Warning");
          }
        },
        failure: error => {
          console.log("error fetching match data:", error);
          this.setState({
            loading: false,
            loaded: false,
            match: false,
            cancel: false
          });
        }
      })
    });
  }, 500);

  render() {
    const { value, setValue, ...rest } = this.props;
    const { loading, loaded, match } = this.state;

    return (
      <Form.Input
        value={value}
        onChange={(undefined, { value }) => {
          if (value === "") {
            // If the value is empty, we want to reset to our default empty state (no icon)
            this.setState({ loaded: false });
          } else {
            // Otherwise if we have a value being set, check for matches
            this.checkForMatches();
          }

          setValue(value);
        }}
        icon={
          /**
           * If not loaded, show nothing
           * If loaded with match, show exclamation triangle
           * If loaded with value and no match, show green checkmark
           * otherwise, show blank
           */
          !loaded ? (
            undefined
          ) : match ? (
            <Icon name="exclamation triangle" color="red" />
          ) : value ? (
            <Icon name="checkmark" color="green" />
          ) : null
        }
        loading={loading}
        {...rest}
      />
    );
  }
}
