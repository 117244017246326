import React from "react";
import { Button, Modal, Form, Input } from "semantic-ui-react";
import styled from "styled-components";

export default (ContentManager, Editor) => {
  return (
    <Modal open={ContentManager.state.editorActive} size={"small"}>
      <Modal.Header>Update Page Group</Modal.Header>
      <Modal.Content>
        <Form>
          <Form.Field>
            <label htmlFor="index">Index</label>
            {Editor.functions.createInput(<Input id="index" />, "index", {
              type: "number "
            })}
          </Form.Field>
          <Form.Field>
            <label htmlFor="name">Name</label>
            {Editor.functions.createInput(<Input id="name" />, "name")}
          </Form.Field>
        </Form>
      </Modal.Content>
      <ModalActions>
        <div>
          <Button
            content="Close"
            onClick={ContentManager.functions.closeEditor}
            basic
          />
          {ContentManager.functions.getDeleteButton()}
        </div>
        <div>
          {Object.keys(Editor.data).length ? (
            <React.Fragment>
              <Button
                type={"button"}
                content={"Discard"}
                icon="undo"
                onClick={Editor.functions.reset}
                basic
              />
              <Button
                type={"button"}
                onClick={Editor.functions.save}
                loading={ContentManager.state.editorLoading}
                icon={"save"}
                content={"Save"}
                color={"blue"}
              />
            </React.Fragment>
          ) : null}
        </div>
      </ModalActions>
    </Modal>
  );
};

const ModalActions = styled(Modal.Actions)`
  display: flex;
  justify-content: space-between;
  align-items: stretch;

  div {
    &:first-of-type {
      justify-self: flex-start;
    }
  }

  &&& {
    button {
      margin: 0 0.25em 0 0.75em;
      height: 100%;
    }
  }
`;
