import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";

import { Button, Divider, Header, Table } from "semantic-ui-react";
import { formatMJY } from "utils/dates";
import { addCommas } from "utils/numbers";

export default class ValidPointSets extends Component {
  static propTypes = {
    membership: PropTypes.object.isRequired,
    usePointSet: PropTypes.func.isRequired,
    unsetMembership: PropTypes.func
  };

  render() {
    const { membership, usePointSet, unsetMembership } = this.props;
    const { member } = membership;

    return (
      <Fragment>
        <div style={{ display: "flex" }}>
          <div style={{ flexGrow: 1 }}>
            <Header>
              {member.firstname} {member.lastname}
              <Header.Subheader>
                {membership.resort_company.name} {membership.level}
              </Header.Subheader>
            </Header>
          </div>
          {unsetMembership && (
            <Button
              basic
              content="Switch Membership"
              onClick={unsetMembership}
            />
          )}
        </div>
        <Divider />
        <p>
          Select which point set should be used. If you need to split points
          across multiple sets (example: credit pools) you will have to apply
          them one at a time.
        </p>
        <Table>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Point Set</Table.HeaderCell>
              <Table.HeaderCell>Points Left</Table.HeaderCell>
              <Table.HeaderCell />
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {membership.points_sets.map(point_set => {
              const points_left =
                point_set.allocated -
                point_set.point_usages.reduce((a, c) => a + c.amount, 0);
              return (
                <Table.Row key={point_set.id}>
                  <Table.Cell>
                    {point_set.name && (
                      <p>
                        <b>{point_set.name}</b>
                      </p>
                    )}
                    <b>{formatMJY(point_set.start)}</b>
                    {" to "}
                    <b>{formatMJY(point_set.end)}</b>
                  </Table.Cell>
                  <Table.Cell>
                    <b>{addCommas(points_left)}</b>
                  </Table.Cell>
                  <Table.Cell>
                    <a href={null} onClick={() => usePointSet(point_set)}>
                      Use Set
                    </a>
                  </Table.Cell>
                </Table.Row>
              );
            })}
          </Table.Body>
        </Table>
      </Fragment>
    );
  }
}
