import store from "redux/store.js";
import {
  createTable,
  setLoading,
  setError,
  setRequestData
} from "redux/PortalTables/actions";
import AjaxFn from "services/AjaxFn";
import calculateBooking, { isBookingCancelled } from "utils/bookings";

const namespace = "BookingList";

/**
 *	Initializes the portaltable instance for the bookings list
 *  @param {Object} config
 */
export const instantiate = config => (dispatch, getState) => {
  dispatch(createTable(namespace, config));
  dispatch(reload());
};
/**
 *	Set the start of the desired bookings range
 *  @param {DateTime} start
 */
export const setStart = start => (dispatch, getState) => {
  const { end } = getState().BookingList;
  dispatch({ type: "BOOKPAGE_SET_START", data: start });
};
/**
 *	Set the end of the desired bookings range
 *  @param {DateTime} end
 */
export const setEnd = end => (dispatch, getState) => {
  const { start } = getState().BookingList;
  dispatch({ type: "BOOKPAGE_SET_END", data: end });
};
/**
 * switch between date booked/check in query type
 * @param {String} type
 */
export const setType = type => ({ type: "BOOKPAGE_SET_TYPE", data: type });
/**
 *	Reload the booking list
 */
export const reload = () => (dispatch, getState) => {
  const { BookingList } = getState();
  dispatch(setLoading(namespace));
  AjaxFn({
    url: "/apis/portal/bookings",
    data: {
      action: "read",
      data: {
        start: BookingList.start,
        end: BookingList.end,
        type: BookingList.type
      }
    },
    success: message => {
      const processed = processResponse(message);
      dispatch(setRequestData(namespace, processed));
    },
    failure: error => dispatch(setError(namespace, error))
  });
};
/**
 *	Preprocess a response from the server
 *	@param {Array} bookings
 *  @return array
 */
export const processResponse = message => {
  const calculated = message.map(booking => ({
    ...booking,
    calculated: calculateBooking(booking),
    cancelled: isBookingCancelled(booking)
  }));
  const dateType = store.getState().BookingList.type;
  return calculated.map(booking => ({
    id: booking.id,
    date: booking[dateType],
    guest: booking.guest,
    cancelled: booking.cancelled,
    channel: booking.booking_source,
    rco: booking.rco,
    point_usages: booking.point_usages,
    balance_due: booking.calculated.guest_payment_due !== "0.00",
    issues: booking.issues,
    audits: booking.audits,
    financialAuditDone: booking.calculated.audited,
    audit_progress: getAuditProgress(booking),
    financial_progress: getFinancialProgress(booking),
    reservation_progress: getReservationProgress(booking),
    check: booking.check
  }));
};
/**
 *	Determine the audit completion percentage of a booking
 *	@param {Object} booking
 *  @return number
 */
export const getAuditProgress = booking => {
  if (!booking.audits.length) {
    return 0;
  }

  const valid_audit = booking.audits.find(audit => {
    return audit.completed_on && !audit.invalidated_on;
  });

  if (valid_audit) {
    return 100;
  }

  const in_progress_audit = booking.audits.find(audit => {
    return !audit.completed_on && !audit.invalidated_on;
  });

  if (in_progress_audit) {
    return 50;
  }

  return 0;
};
/**
 *	Determine the financial completion percentage of a booking
 *	@param {Object} booking
 *  @return number
 */
export const getFinancialProgress = ({ calculated }) => {
  const gri = Number(calculated.gross_rental_income);
  const cf = Number(calculated.channel_fee);
  const gt = Number(calculated.grand_total);
  const gpm = Number(calculated.guest_payment);
  const ref = Number(calculated.refund);
  if (ref === gpm && calculated.audited) {
    return 100;
  } else {
    let percentage = 0;
    if (gri > 1) percentage += 20;
    if (cf > 1) percentage += 20;
    if (Math.abs(gt - gpm) <= 5) percentage += 20;
    if (calculated.audited) percentage += 40;
    return percentage;
  }
};
/**
 *	Determine the reservation completion percentage of a booking
 *	@param {Object} booking
 *  @return number
 */
export const getReservationProgress = booking => {
  if (booking.cancelled) return 100;
  const notices = booking.sent_notices;
  const bConfSent = !!notices.filter(c => c.type === "booking").length;
  const aConfSent = !!notices.filter(c => c.type === "advance").length;
  const fConfSent = !!notices.filter(c => c.type === "final").length;
  let completed = 0;
  if (bConfSent) completed++;
  if (aConfSent) completed++;
  if (fConfSent) completed++;
  if (booking.point_usages.length) completed++;
  if (booking.ciirus_id !== "") completed++;
  if (booking.rco !== "") completed++;
  return Math.floor((completed / 6) * 100).toFixed(0);
};
