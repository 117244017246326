import React from "react";
import { Header, Icon } from "semantic-ui-react";
import Layout from "components/Layout";
import PortalTable from "components/PortalTable";
import Breadcrumb from "./components/Breadcrumb";
import { formatMJY } from "utils/dates";

import AgreementListFocusBar from "components/AgreementListFocusBar";

export default class AgreementsLegacy extends React.Component {
  getPortalTableConfig = () => ({
    tableName: "LegacyAgreementsList",
    url: "/apis/portal/legacyagreements",
    data: {
      action: "read"
    },
    tableData: {
      show: 10,
      order: [[1, "desc"]],
      paginationPosition: "bottom",
      showFooter: false,
      menu: () => ["[search]", "[show]", "[refresh]"],
      cols: [
        {
          name: "",
          src: "id",
          render: (data, row) => {
            return (
              <a
                title={"View Agreement"}
                href={`/browse/legacyagreements/${data}`}
                style={{ width: "50px", marginLeft: "5px" }}
                children={<Icon name={"external"} />}
              />
            );
          },
          orderable: false,
          searchable: false,
          props: {
            textAlign: "center",
            selectable: true,
            collapsing: true
          }
        },
        {
          name: "Started",
          src: "created",
          render: src => formatMJY(src)
        },
        {
          name: "Agent",
          src: row => row.agent.firstname + " " + row.agent.lastname
        },
        {
          name: "Type",
          src: "contract_type"
        },
        {
          name: "Recipient Email",
          src: "email"
        },
        {
          name: "Recipient Name",
          src: "name"
        },
        {
          name: "Last Sent",
          src: "sent",
          render: src => {
            if (src) {
              return (
                <React.Fragment>
                  <Icon name={"checkmark"} color={"green"} />
                  {formatMJY(src)}
                </React.Fragment>
              );
            } else {
              return (
                <React.Fragment>
                  <Icon name={"cancel"} color={"red"} />
                  {"Not sent"}
                </React.Fragment>
              );
            }
          },
          props: row => {
            const props = {};
            if (row.sent) {
              // ...
            } else {
              props.negative = true;
            }
            return props;
          }
        },
        {
          name: "Received",
          src: "received",
          render: src => {
            if (src) {
              return (
                <React.Fragment>
                  <Icon name={"checkmark"} color={"green"} />
                  {formatMJY(src)}
                </React.Fragment>
              );
            } else {
              return (
                <React.Fragment>
                  <Icon name={"cancel"} color={"red"} />
                  {"Not submitted"}
                </React.Fragment>
              );
            }
          },
          props: row => {
            const props = {};
            if (row.received) {
              // ...
            } else {
              props.negative = true;
            }
            return props;
          }
        }
      ]
    }
  });
  render() {
    return (
      <Layout>
        <AgreementListFocusBar page="legacy" />
        <Breadcrumb />
        <br />
        <div style={{ width: "100%" }}>
          <Header as={"h1"} dividing>
            <Header.Content>
              Legacy Agreements
              <Header.Subheader>
                Agreements created before December 8, 2018 will appear in this
                list.
              </Header.Subheader>
            </Header.Content>
          </Header>
          <PortalTable {...this.getPortalTableConfig()} />
        </div>
      </Layout>
    );
  }
}
