import React from "react";
import { Link } from "react-router-dom";

import { Breadcrumb } from "semantic-ui-react";

import ContextBar from "components/ContextBar";

export default () => (
  <ContextBar>
    <Breadcrumb>
      <Breadcrumb.Section>
        <Link to={"/"}>Portal</Link>
      </Breadcrumb.Section>
      <Breadcrumb.Divider>/</Breadcrumb.Divider>
      <Breadcrumb.Section>
        <Link to={"/browse/agreements"}>Agreements</Link>
      </Breadcrumb.Section>
      <Breadcrumb.Divider>/</Breadcrumb.Divider>
      <Breadcrumb.Section active>Agreement</Breadcrumb.Section>
    </Breadcrumb>
  </ContextBar>
);

export const LoadedBreadcrumb = ({ data }) => (
  <ContextBar>
    <Breadcrumb>
      <Breadcrumb.Section>
        <Link to={"/"}>Portal</Link>
      </Breadcrumb.Section>
      <Breadcrumb.Divider>/</Breadcrumb.Divider>
      <Breadcrumb.Section>
        <Link to={"/browse/agreements"}>Agreements</Link>
      </Breadcrumb.Section>
      <Breadcrumb.Divider>/</Breadcrumb.Divider>
      <Breadcrumb.Section active>
        {data.recipient} - {data.template.name}
      </Breadcrumb.Section>
    </Breadcrumb>
  </ContextBar>
);
