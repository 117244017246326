import { searchData, paginateData } from "../../selector";

// This function should be called after every data reload
export const processData = () => dispatch => {
  dispatch(setSearchedData());
  // ...
};

export const setSearch = data => dispatch => {
  dispatch({ type: "EOQ_SET_SEARCH", data });
  dispatch(setSearchedData());
};
export const setSearchedData = () => (dispatch, getState) => {
  const { bookings, search } = getState().EOQReport;
  const { rows_original } = bookings.data;
  dispatch({
    type: "EOQ_SET_SEARCHED",
    data: searchData(rows_original, search)
  });
  dispatch(setFilteredData());
};

export const setFilteredData = () => (dispatch, getState) => {
  const { bookings } = getState().EOQReport;
  const { rows_searched } = bookings.data;
  dispatch({
    type: "EOQ_SET_FILTERED",
    data: rows_searched
  });
  dispatch(setOrderedData());
};

export const setOrderedData = () => (dispatch, getState) => {
  const { bookings } = getState().EOQReport;
  const { rows_filtered } = bookings.data;
  dispatch({
    type: "EOQ_SET_ORDERED",
    data: rows_filtered
  });
  dispatch(setPaginatedData());
};

export const setPerPage = data => dispatch => {
  dispatch({ type: "EOQ_SET_PER_PAGE", data });
  dispatch(setPaginatedData());
};
export const setPage = data => dispatch => {
  dispatch({ type: "EOQ_SET_PAGE", data });
  dispatch(setPaginatedData());
};
export const setPaginatedData = () => (dispatch, getState) => {
  const { bookings, page, per_page } = getState().EOQReport;
  const { rows_ordered } = bookings.data;

  if (!rows_ordered.length) {
    if (page !== 1) {
      // make sure we aren't on a page that isn't the first if we have no data
      dispatch(setPage(1));
    }
  } else {
    let div = Math.floor(rows_ordered.length / per_page);
    let maxPage = rows_ordered.length % per_page ? div + 1 : div;
    if (page > maxPage) {
      // make sure we arent past the max page
      dispatch(setPage(maxPage));
    } else {
      dispatch({
        type: "EOQ_SET_PAGINATED",
        data: paginateData(rows_ordered, per_page, page)
      });
    }
  }
};
