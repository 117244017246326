import React from "react";
import { Table } from "semantic-ui-react";
import styles from "../../../../../../styles";
import injectSheet from "react-jss";
import { connect } from "react-redux";
import {
  handleMouseDown,
  handleMouseUp,
  handleMouseEnter
} from "redux/Calendar/actions";
import {
  isSelected,
  isRenderedAsBlackoutDate,
  isRenderedAsBookingDate
} from "redux/Calendar/selector";
class Day extends React.Component {
  isSelected = () => {
    const { day, rangeStart, rangeEnd } = this.props;
    const { date } = day;
    return isSelected(date, rangeStart, rangeEnd);
  };
  isBlackout = () => {
    const { day, unit, changes } = this.props;
    const { date } = day;
    return isRenderedAsBlackoutDate(date, unit, changes);
  };
  isBooking = () => {
    const { day, unit } = this.props;
    const { date } = day;
    return isRenderedAsBookingDate(date, unit);
  };
  getClass = () => {
    const { classes } = this.props;
    if (this.isSelected()) {
      return classes.day + " " + classes.dayInRange;
    } else {
      const isBooking = this.isBooking();
      const isBlackout = this.isBlackout();
      if (isBooking && isBlackout) {
        return classes.day + " " + classes.dayBookingBlackout;
      } else if (isBooking) {
        return classes.day + " " + classes.dayBooking;
      } else if (isBlackout) {
        return classes.day + " " + classes.dayBlackout;
      } else {
        return classes.day;
      }
    }
  };

  handleContext = (e) => e.preventDefault();
  handleMouseDown = () => {
    const { day, dispatch } = this.props;
    dispatch(handleMouseDown(day.date));
  };
  handleMouseUp = () => {
    const { day, dispatch } = this.props;
    dispatch(handleMouseUp(day.date));
  };
  handleMouseEnter = () => {
    const { day, dispatch, rangeStart } = this.props;
    if (rangeStart) {
      dispatch(handleMouseEnter(day.date));
    }
  };

  render() {
    const { day } = this.props;
    return (
      <Table.Cell
        className={this.getClass()}
        onContextMenu={this.handleContext}
        onMouseDown={this.handleMouseDown}
        onMouseUp={this.handleMouseUp}
        onMouseEnter={this.handleMouseEnter}
        children={day.day}
      />
    );
  }
}

const styled = injectSheet(styles)(Day);
export default connect((state) => ({
  rangeStart: state.Calendar.rangeStart,
  rangeEnd: state.Calendar.rangeEnd,
  unit: state.Calendar.unit,
  changes: state.Calendar.changes
  // ...
}))(styled);
