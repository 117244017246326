import React from "react";
import { Button, Icon } from "semantic-ui-react";

export default ({ ContentManager, viewResorts }) => ({
  tableName: "resortCompanies",
  url: "/apis/portal/resortcompanies",
  data: {
    action: "read"
  },
  tableData: {
    show: 250,
    order: [[1, "asc"]],
    paginationPosition: "none",
    showFooter: false,
    menu: () => [
      "[search]",
      "[refresh]",
      <Button
        icon
        color={"blue"}
        title={"New"}
        onClick={ContentManager.functions.openWizard}
        children={<Icon name={"plus"} />}
      />
    ],
    cols: [
      {
        name: "",
        src: "id",
        render: (src, row) => (
          <a onClick={() => ContentManager.functions.openEditor(row)}>
            <Icon name={"edit"} />
          </a>
        ),
        orderable: false,
        searchable: false,
        props: {
          textAlign: "center",
          selectable: true,
          width: 1
        }
      },
      {
        name: "Name",
        src: "name"
      },
      {
        name: "Resorts (click to view)",
        src: row => row.resorts.length,
        render: (src, row) => {
          if (!src) {
            return (
              <React.Fragment>
                <Icon name={"cancel"} color={"red"} />
                None
              </React.Fragment>
            );
          } else {
            return (
              <a onClick={() => viewResorts(row)}>
                <Icon name={"checkmark"} color={"green"} />
                {src} resort{src > 1 ? "s" : null}
              </a>
            );
          }
        },
        props: row => {
          const props = {
            width: 3
          };
          if (row.resorts.length) {
            props.selectable = true;
          }
          return props;
        }
      }
    ]
  }
});
