import React, { Component } from "react";
import { Container } from "semantic-ui-react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { setContent } from "redux/ContextBar/actions";

const styles = {
  padding: ".5rem 0 .75rem 0",
  background: "#eee"
  // marginBottom: "1rem"
};

export class ContextBar extends Component {
  static propTypes = {
    render: PropTypes.bool
  };

  componentDidMount() {
    this.props.set(this.props.children);
  }

  render() {
    if (!this.props.render) {
      return null;
    } else {
      return (
        <div id="breadcrumbs" style={styles}>
          <Container>{this.props.content}</Container>
        </div>
      );
    }
  }
}

const mapStateToProps = state => ({
  content: state.ContextBar.content
});

const mapDispatchToProps = dispatch => ({
  set: content => dispatch(setContent(content))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ContextBar);
