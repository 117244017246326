import React from "react";
import { Button, Icon, Modal } from "semantic-ui-react";
import { Link } from "react-router-dom";
/**
 *	@prop close {Function}
 *	@prop open {Boolean}
 *	@prop data {Object}
 */
export default class SuccessModal extends React.Component {
  content = () => {
    const { data } = this.props;
    let content = [];
    if (typeof data.created === "undefined") {
      return [];
    }
    if (data.created.resort) {
      content.push(
        <Link
          as={<p />}
          to={"/browse/resorts/" + data.created.resort.id}
          children={
            "Created resort " +
            data.created.resort.resort_company.name +
            " " +
            data.created.resort.name +
            " successfully."
          }
        />
      );
    }
    if (data.created.unit) {
      content.push(
        <Link
          as={<p />}
          to={"/browse/units/" + data.created.unit.id}
          children={
            "Created unit " +
            data.created.unit.resort.resort_company.name +
            " " +
            data.created.unit.resort.name +
            " " +
            data.created.unit.name +
            " successfully."
          }
        />
      );
    }
    return content;
  };
  render() {
    const { open, close } = this.props;
    return (
      <Modal open={open} onClose={close} size={"mini"}>
        <Modal.Header>
          <Icon name={"checkmark"} color={"green"} />
          Created properties
        </Modal.Header>
        <Modal.Content>
          <ul>{this.content().map((el, ix) => <li key={ix}>{el}</li>)}</ul>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={close}>
            <Icon name={"close"} />
            Close
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}
