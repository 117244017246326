import React from "react";
import { Icon, Button } from "semantic-ui-react";
import { Link } from "react-router-dom";
import Filters from "./components/Filters";
const namespace = "guests";
export default ({ ContentManager }) => ({
  tableName: namespace,
  url: "/apis/portal/guests/index",
  data: {
    action: "read"
  },
  preprocessor: data =>
    data.map(row => {
      if (!row.country) {
        row.country = "none";
      }
      if (row.country === "USA") {
        row.country = "us";
      }
      return row;
    }),
  tableData: {
    show: 25,
    order: [[1, "asc"]],
    paginationPosition: "bottom",
    showFooter: false,
    menu: () => [
      "[search]",
      "[show]",
      <Filters namespace={namespace} />,
      "[refresh]",
      <Button
        primary={true}
        onClick={ContentManager.functions.openWizard}
        content={"New Guest"}
      />
    ],
    cols: [
      {
        name: "",
        src: "id",
        render: (src, row) => (
          <Link
            to={"/browse/guests/" + src}
            children={<Icon name={"external"} />}
          />
        ),
        orderable: false,
        searchable: false,
        props: {
          textAlign: "center",
          selectable: true,
          width: 1
        }
      },
      {
        name: "Name",
        src: row => row.firstname + " " + row.lastname
      },
      {
        name: "Phone Number",
        src: "phone"
      },
      {
        name: "Email",
        src: "email"
      },
      {
        name: "Active",
        src: "active",
        render: active => {
          if (active) {
            return (
              <React.Fragment>
                <Icon name={"checkmark"} color={"green"} />
                Yes
              </React.Fragment>
            );
          } else {
            return (
              <React.Fragment>
                <Icon name={"cancel"} color={"red"} />
                No
              </React.Fragment>
            );
          }
        },
        props: row => {
          const props = {};
          if (row.active) {
            props.positive = true;
          } else {
            props.negative = true;
          }
          return props;
        }
      }
    ]
  }
});
