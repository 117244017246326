import React, { Component } from "react";
import { Form, Button, Label, Header } from "semantic-ui-react";
import injectSheet from "react-jss";
import PropTypes from "prop-types";
class BedTypes extends Component {
	static propTypes = {
		// value: ??
		onChange: PropTypes.func.isRequired
	};

	state = {
		value: {}
	};

	componentDidMount() {
		try {
			this.setState({
				value: JSON.parse(this.props.value)
			});
		} catch (e) {
			this.setState({
				value: {}
			});
		}
	}

	/**
	 *	Alter the bed types
	 *  @param {String} key - the bed type key
	 *  @param {Integer} amount - the amount to alter it by
	 */
	alter = (key, amount) => {
		const newState = {
			value: {
				...this.state.value,
				[key]: (this.state.value[key] || 0) + amount
			}
		};
		this.setState(newState, () => {
			const newValue = JSON.stringify(this.state.value);
			this.props.onChange({}, { value: newValue });
		});
	};

	/**
	 *	Create a controlled bed type field
	 *  @param {Integer} name - the text to show
	 *  @param {String} key - the bed type key
	 *  @return {ReactElement}
	 */
	getField = key => {
		const { classes } = this.props;
		return (
			<React.Fragment>
				<Button
					size={"mini"}
					icon={"minus"}
					disabled={!this.state.value[key]}
					onClick={() => this.alter(key, -1)}
				/>
				<Label
					color={this.state.value[key] > 0 ? "blue" : undefined}
					className={classes.bedLabel}
					size={"large"}
					content={this.state.value[key] || 0}
				/>
				<Button
					size={"mini"}
					icon={"plus"}
					disabled={this.state.value[key] === 10}
					onClick={() => this.alter(key, 1)}
				/>
			</React.Fragment>
		);
	};

	/**
	 *	Render fn
	 */
	render() {
		const { classes } = this.props;
		return (
			<React.Fragment>
				<Header dividing as={"h4"}>
					Bed Types
				</Header>
				{fields.map((rows, rowkey) => (
					<Form.Group key={rowkey} widths={"equal"}>
						{rows.map(({ name, key }, cellkey) => (
							<Form.Field key={cellkey}>
								<label className={classes.label}>{name}</label>
								<div className={classes.buttonContainer}>
									{this.getField(key)}
								</div>
							</Form.Field>
						))}
					</Form.Group>
				))}
			</React.Fragment>
		);
	}
}

const emptyState = {
	K: 0,
	KB: 0,
	QS: 0,
	Q: 0,
	QB: 0,
	DS: 0,
	QM: 0,
	DB: 0,
	SS: 0,
	D: 0,
	TB: 0,
	SB: 0,
	T: 0,
	BB: 0,
	VARIES: 0
};

const fields = [
	[
		{ name: "King", key: "K" },
		{ name: "King Bunk", key: "KB" },
		{ name: "Queen Sofa", key: "QS" }
	],
	[
		{ name: "Queen", key: "Q" },
		{ name: "Queen Bunk", key: "QB" },
		{ name: "Double Sofa", key: "DS" }
	],
	[
		{ name: "Queen Murphy", key: "QM" },
		{ name: "Double Bunk", key: "DB" },
		{ name: "Sleeper Sofa", key: "SS" }
	],
	[
		{ name: "Double", key: "D" },
		{ name: "Twin Bunk", key: "TB" },
		{ name: "Sofa Bed", key: "SB" }
	],
	[
		{ name: "Twin", key: "T" },
		{ name: "Bunk Bed", key: "BB" },
		{ name: "Other/Varies", key: "VARIES" }
	]
];

const styles = {
	label: {
		textAlign: "center",
		display: "block",
		marginBottom: "0 !important"
	},
	buttonContainer: {
		display: "block",
		textAlign: "center"
	},
	bedLabel: {
		marginLeft: "0 !important"
	}
};

export default injectSheet(styles)(BedTypes);
