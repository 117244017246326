import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import UnitSidebar from "./components/UnitSidebar";
import CiirusSidebar from "./components/CiirusSidebar";

export default class Sidebar extends Component {
  static propTypes = {
    unit: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    reload: PropTypes.func.isRequired
  };

  getListingSidebar = () => {
    switch (this.props.unit.unit_type) {
      case "ciirus":
        return <CiirusSidebar {...this.props} />;
      default:
        throw new Error("Invalid unit type");
    }
  };

  render() {
    return (
      <Fragment>
        <UnitSidebar {...this.props} />
        {this.getListingSidebar()}
      </Fragment>
    );
  }
}
