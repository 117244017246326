import propTypes from "prop-types";
import moment from "moment";

export const InstanceTypes = {
  config: propTypes.shape({
    // a unique namespace for the calendar
    name: propTypes.string.isRequired,
    // "date", "datetime", "time"
    type: propTypes.string.isRequired,
    // current/starting value
    value: propTypes.instanceOf(moment),
    // custom moment output mask
    outputMask: propTypes.string,
    // mininum + maximum date boundaries
    minDate: propTypes.instanceOf(moment),
    maxDate: propTypes.instanceOf(moment),
    // whether or not the calendar is open or not
    open: propTypes.bool,
    // A hint to help the user fill out the data, appears as the
    // header of the modal
    hint: propTypes.string,
    // open calendar
    onOpen: propTypes.func,
    // close calendar
    onClose: propTypes.func,
    // getInput() allows you to override the input a user interacts with
    // Expects a function to be passed, expects an element to be retuned
    // will be called with an object containing required props to pass
    getInput: propTypes.func,
    // if it should be read-only or not
    readOnly: propTypes.bool,
    disabled: propTypes.bool,
    fluid: propTypes.bool
  })
};

export const MyCalTypes = {
  // configuration object for the entire calendar wrapper
  config: propTypes.shape({
    // configuration for individual calendars goes here
    calendars: propTypes.arrayOf(propTypes.shape(InstanceTypes)).isRequired
  }).isRequired
};
