import React from "react";
import { connect } from "react-redux";
import moment from "moment";
import styled from "styled-components";

import { Icon, Button, Dropdown } from "semantic-ui-react";

import { ShowSelector } from "components/PortalTable/parts";
import { setStart, setEnd, reload, setType } from "redux/BookingList/actions";
import { MyCalWrapper } from "components/MyCal";

class Filters extends React.Component {
  render() {
    return (
      <Wrapper>
        <ShowSelector namespace={"BookingList"} semantic />

        <Calendar className="tutorial-booklist-dates">
          <CalendarItems>
            <MyCalWrapper
              value={this.props.start}
              onChange={(e, { value }) =>
                this.props.setStart(
                  moment(value)
                    .startOf("day")
                    .format()
                )
              }
              maxDate={moment(this.props.end)}
              type="date"
            />
            <span>
              <Icon name={"right arrow"} />
            </span>
            <MyCalWrapper
              value={this.props.end}
              onChange={(e, { value }) =>
                this.props.setEnd(
                  moment(value)
                    .endOf("day")
                    .format()
                )
              }
              minDate={moment(this.props.start)}
              type="date"
            />
          </CalendarItems>
          <Dropdown
            selection
            value={this.props.type}
            onChange={(e, { value }) => this.props.setType(value)}
            placeholder="Select one..."
            options={[
              { value: "check_in", text: "Check In" },
              { value: "date_booked", text: "Date Booked" }
            ]}
          />
          <Button
            basic
            onClick={this.props.reload}
            disabled={this.props.loading || this.props.start > this.props.end}
            loading={this.props.loading}
            primary
            content="Search"
          />
        </Calendar>
      </Wrapper>
    );
  }
}

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
`;

const Calendar = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-gap: 1rem;
  align-items: stretch;
`;

const CalendarItems = styled.div`
  display: grid;
  grid-template-columns: repeat(3, auto);
  grid-auto-flow: column;
  align-items: stretch;

  div {
    :first-of-type {
      div {
        input {
          border-right: none;
          border-radius: 0.28571429rem 0 0 0.28571429rem;
        }
      }
    }
    :last-of-type {
      div {
        input {
          border-left: none;
          border-radius: 0 0.28571429rem 0.28571429rem 0;
        }
      }
    }
  }

  span {
    border-top: 1px solid rgba(34, 36, 38, 0.15);
    border-bottom: 1px solid rgba(34, 36, 38, 0.15);
    border-radius: 0;
    height: 100%;
    padding: 0 0.5em;

    display: flex;
    align-items: center;

    i {
      height: 100%;
      margin: 0;
      display: flex;
      align-items: center;
    }
  }
`;

const mapStateToProps = state => ({
  start: state.BookingList.start,
  end: state.BookingList.end,
  type: state.BookingList.type,
  loading: state.PortalTables["BookingList"].loading
});

const mapDispatchToProps = {
  setStart,
  setEnd,
  setType,
  reload
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Filters);
