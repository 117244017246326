import React from "react";
import { Dropdown, Icon } from "semantic-ui-react";
import Ajax from "services/AjaxFn";
/**
 *	@prop ContentManager
 */
export default class Deactivate extends React.Component {
  state = {
    loading: false
  };
  handleClick = e => {
    e.stopPropagation();
    this.setState({ loading: true });
    if (window.confirm("Are you sure? This will lock the account.")) {
      this.setState({ loading: true });
      Ajax({
        url: "/apis/portal/accounts",
        data: {
          action: "update",
          data: {
            ID: this.props.ContentManager.state.editorSource.id,
            disabled: 1
          }
        },
        success: () => {
          this.props.ContentManager.functions.closeEditor();
          this.props.ContentManager.functions.refresh();
          window.toastr.success();
        },
        failure: e => {
          this.setState({ loading: false });
          window.toastr.error(e);
        }
      });
    }
  };
  render() {
    const { loading } = this.state;
    return (
      <Dropdown.Item disabled={loading} onClick={this.handleClick}>
        <Icon name={loading ? "notched circle" : "lock"} loading={loading} />
        Deactivate
      </Dropdown.Item>
    );
  }
}
