import React from "react";
import { Breadcrumb } from "semantic-ui-react";
import { Link } from "react-router-dom";

import ContextBar from "components/ContextBar";

export default () => (
  <ContextBar>
    <Breadcrumb>
      <Breadcrumb.Section as={Link} to="/" content="Portal" />
      <Breadcrumb.Divider />
      <Breadcrumb.Section content="Check Wizard" active />
    </Breadcrumb>
  </ContextBar>
);
