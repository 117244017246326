import React from "react";
import { Modal, Button, Icon } from "semantic-ui-react";
export default (ContentManager, Delete) => (
  <React.Fragment>
    <Button color={"red"} onClick={Delete.functions.openModal}>
      Delete
    </Button>
    <Modal
      open={Delete.state.isOpen}
      onClose={Delete.functions.cancel}
      size={"mini"}
      basic
    >
      <Modal.Header icon={"exclamation triangle"}>Are you sure?</Modal.Header>
      <Modal.Content>
        Are you sure you want to delete this page group? This action can not be
        undone. Note: The page group can not be deleted if any pages are
        assigned to it.
      </Modal.Content>
      <Modal.Actions>
        <Button
          color={"red"}
          inverted
          onClick={Delete.functions.confirm}
          loading={Delete.state.isLoading}
        >
          <Icon name={"checkmark"} />
          Yes, delete it
        </Button>
        <Button onClick={Delete.functions.cancel} inverted>
          <Icon name={"close"} />
          Nevermind
        </Button>
      </Modal.Actions>
    </Modal>
  </React.Fragment>
);
