import React from "react";
import { Dropdown, Icon } from "semantic-ui-react";
import AjaxFn from "services/AjaxFn";
/**
 *	@prop ContentManager
 */
export default class ResetPassword extends React.Component {
  state = {
    loading: false
  };
  handleClick = e => {
    e.stopPropagation();
    if (window.confirm("Are you sure? This will generate a random password.")) {
      this.setState({ loading: true });
      AjaxFn({
        url: "/apis/portal/accounts",
        data: {
          action: "reset_password",
          data: { ID: this.props.ContentManager.state.editorSource.id }
        },
        success: message => {
          window.toastr.success(
            "The account's password has been reset to: " + message
          );
        },
        failure: error => {
          window.toastr.error(error);
        },
        finally: () => {
          this.setState({ loading: false });
        }
      });
    }
  };
  render() {
    const { loading } = this.state;
    return (
      <Dropdown.Item disabled={loading} onClick={this.handleClick}>
        <Icon name={loading ? "notched circle" : "refresh"} loading={loading} />
        Reset Password
      </Dropdown.Item>
    );
  }
}
