import React, { Component } from "react";
import PropTypes from "prop-types";
import moment from "moment";

import { Header, Segment, Grid, Form, Button } from "semantic-ui-react";
import AjaxFn from "services/AjaxFn";

export default class NewSet extends Component {
  static propTypes = {
    reloadMembership: PropTypes.func.isRequired,
    membership: PropTypes.object.isRequired
  };

  state = {
    loading: false,
    cancel: false,
    data: {
      name: "",
      start: moment().format("YYYY-MM-DD"),
      end: moment()
        .add(1, "year")
        .format("YYYY-MM-DD"),
      earned: "0",
      allocated: "0"
    }
  };

  componentWillUnmount() {
    this.cancel();
  }

  cancel = () => {
    if (typeof this.state.cancel === "function") {
      this.state.cancel();
    }
  };

  submit = e => {
    e.preventDefault();
    this.cancel();
    this.setState({
      loading: true,
      cancel: AjaxFn({
        url: "/apis/portal/memberships",
        data: {
          action: "add_points",
          data: {
            membership_id: this.props.membership.id,
            ...this.state.data
          }
        },
        success: this.props.reloadMembership,
        failure: error => {
          this.setState({ loading: false, cancel: false });
          window.toastr.error(error);
        }
      })
    });
  };

  connect = field => ({
    value: this.state.data[field] || "",
    onChange: (e, { value }) =>
      this.setState({ data: { ...this.state.data, [field]: value } }),
    disabled: this.state.loading
  });

  render() {
    return (
      <Segment color="blue">
        <Header
          icon="cube"
          content="Create a Points Set"
          subheader="Fill out the form below"
        />
        <Form onSubmit={this.submit}>
          <Grid columns={3}>
            <Grid.Row>
              <Grid.Column>
                <Form.Input
                  {...this.connect("name")}
                  label="Set Name"
                  placeholder="2018 Credit Pool (example)"
                />
                <Form.Input
                  {...this.connect("start")}
                  label="Start Date"
                  type="date"
                  icon="calendar"
                />
                <Form.Input
                  {...this.connect("end")}
                  label="End Date"
                  type="date"
                  icon="calendar"
                />
              </Grid.Column>
              <Grid.Column>
                <Form.Input
                  {...this.connect("earned")}
                  label="Earned"
                  type="number"
                  placeholder="0"
                />
                <Form.Input
                  {...this.connect("allocated")}
                  label="Allocated"
                  type="number"
                  placeholder="0"
                />
              </Grid.Column>
              <Grid.Column />
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width="16">
                <Button basic primary content="Submit" type="submit" />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Form>
      </Segment>
    );
  }
}
