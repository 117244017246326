import React from "react";
import injectSheet from "react-jss";
import { formatMJY } from "utils/dates";
import { addCommas } from "utils/numbers";
import writeNumber from "written-number";
/**
 *	@prop From
 *	@prop FromAddress
 *	@prop FromAddress2
 *	@prop Bank
 *	@prop BankAddress
 *	@prop BankAddress2
 *	@prop CheckNumber
 *	@prop Date
 *	@prop Amount
 *	@prop Signature
 *	@prop PayTo
 *  @prop Memo
 *	@prop AccountNumber
 *  @prop RoutingNumber
 */
class Check extends React.Component {
  getWords = () => {
    const amount = this.props.Amount;
    const parts = amount.split(".");
    return writeNumber(parts[0], { noAnd: true }) + " AND " + parts[1] + "/100";
  };
  render() {
    const { classes } = this.props;
    return (
      <div className={classes.body}>
        <div className={classes.addressBlock1}>
          {this.props.From}
          <br />
          {this.props.FromAddress}
          <br />
          {this.props.FromAddress2}
        </div>
        <div className={classes.addressBlock2}>
          {this.props.Bank}
          <br />
          {this.props.BankAddress}
          <br />
          {this.props.BankAddress2}
        </div>
        <div className={classes.checkNumber}>#{this.props.CheckNumber}</div>
        <div className={classes.date}>
          <b style={{ marginRight: "1rem" }}>DATE</b>
          {formatMJY(this.props.Date)}
        </div>
        <div className={classes.payTo}>
          <b style={{ marginRight: "1rem" }}>PAY TO</b>
          <span>{this.props.PayTo}</span>
        </div>
        <div className={classes.amountAsWords}>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <span>{this.getWords()}</span>
            <b>DOLLARS</b>
          </div>
        </div>
        <div className={classes.amountAsNumber}>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <span>$</span>
            <span>{addCommas(this.props.Amount, 2)}</span>
          </div>
        </div>
        <div className={classes.memo}>
          <b style={{ marginRight: "1rem" }}>MEMO</b>
          {this.props.Memo}
        </div>
        {this.props.Signature ? (
          <div className={classes.signature}>{this.props.Signature}</div>
        ) : (
          <div className={classes.nosignature}>
            <h1>SIGNATURE NOT REQUIRED</h1>
            PAYMENT HAS BEEN AUTHORIZED BY THE DEPOSITOR.
            <br />
            PAYEE TO HOLD YOU HARMLESS FOR PAYMENT OF THIS DOCUMENT.
            <br />
            THIS DOCUMENT SHALL BE DEPOSITED ONLY TO CREDIT OF PAYEE.
            <br />
            ABSENCE OF ENDORSEMENT IS GUARENTEED BY PAYEE'S BANK.
          </div>
        )}
        <div className={classes.routingMICR}>a{this.props.RoutingNumber}a</div>
        <div className={classes.accountMICR}>{this.props.AccountNumber}c</div>
        <div className={classes.checkNumberMICR}>
          {this.props.CheckNumber.toString().padStart(7, "0")}
        </div>
      </div>
    );
  }
}
const styles = {
  body: {
    height: "3.5in !important",
    minHeight: "3.5in !important",
    maxHeight: "3.5in !important",
    width: "100%",
    display: "block",
    color: "#333",
    position: "relative"
  },
  addressBlock1: {
    position: "absolute",
    top: ".4in",
    left: ".4in",
    fontSize: "18px",
    textTransform: "uppercase"
  },
  addressBlock2: {
    position: "absolute",
    top: ".4in",
    left: "4.5in",
    textTransform: "uppercase"
  },
  date: {
    position: "absolute",
    top: "1in",
    left: "6.5in",
    fontSize: "16px",
    borderBottom: "1px solid #333",
    minWidth: "135px"
  },
  checkNumber: {
    position: "absolute",
    top: ".4in",
    left: "7.2in",
    fontSize: "18px"
  },
  payTo: {
    position: "absolute",
    top: "1.3in",
    left: ".4in",
    width: "6in",
    borderBottom: "1px solid #333",
    borderRight: "1px solid #333",
    height: "40px",
    "& span": {
      fontSize: "20px",
      lineHeight: "55px",
      textTransform: "capitalize"
    }
  },
  payToBefore: {
    maxWidth: "80px",
    float: "left"
  },
  amountAsWords: {
    position: "absolute",
    top: "1.8in",
    left: ".4in",
    width: "7.5in",
    borderBottom: "1px solid #333",
    textTransform: "uppercase"
  },
  amountAsNumber: {
    position: "absolute",
    top: "1.3in",
    left: "6.5in",
    padding: "5px",
    border: "1px solid #333",
    fontSize: "20px",
    minWidth: "135px",
    lineHeight: "2rem"
  },
  memo: {
    position: "absolute",
    top: "2.5in",
    left: ".4in",
    width: "3.2in",
    borderBottom: "1px solid #333"
  },
  nosignature: {
    position: "absolute",
    width: "3.5in",
    top: "2.1in",
    left: "4.6in",
    fontSize: ".7rem",
    lineHeight: ".8rem",
    "& h1": {
      fontSize: "1.5rem",
      marginBottom: "0 !important"
    }
  },
  signature: {
    position: "absolute",
    width: "2.9in",
    left: "5in",
    borderBottom: "1px solid #000",
    fontSize: "2rem",
    lineHeight: "2rem",
    top: "2.41in",
    fontFamily: "Dancing Script, cursive, sans-serif"
  },
  routingMICR: {
    position: "absolute",
    top: "3.15in",
    left: ".4in",
    fontSize: "1.85rem",
    fontFamily: "micr"
    // fontWeight: "600"
  },
  accountMICR: {
    position: "absolute",
    top: "3.15in",
    left: "2.6in",
    fontSize: "1.85rem",
    fontFamily: "micr"
    // fontWeight: "600"
  },
  checkNumberMICR: {
    position: "absolute",
    top: "3.15in",
    left: "5in",
    fontSize: "1.85rem",
    fontFamily: "micr"
    // fontWeight: "600"
  },
  "@font-face": [
    {
      fontFamily: "micr",
      src: "url(/fonts/micr.woff)"
    },
    {
      fontFamily: "Dancing Script",
      src: "url(/fonts/DancingScript-Regular.ttf)"
    }
  ]
};
export default injectSheet(styles)(Check);
