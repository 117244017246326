import React, { Component, Fragment } from "react";
import { Button, Header, Form, Segment } from "semantic-ui-react";
import AjaxFn from "services/AjaxFn";
import AceEditor from "react-ace";
import "brace/mode/json";
import "brace/snippets/json";
import "brace/theme/monokai";
import "brace/ext/language_tools";
import "brace/ext/searchbox";

export default class Emitter extends Component {
  state = {
    ...placeholder,
    loading: false,
    cancel: false
  };

  componentWillUnmount() {
    this.cancel();
  }

  cancel = () => {
    if (typeof this.state.cancel === "function") {
      this.state.cancel();
    }
  };

  handleSubmit = e => {
    const { event, room, data } = this.state;
    e.preventDefault();
    this.setState({
      loading: true,
      cancel: AjaxFn({
        url: "/apis/portal/_dev",
        data: {
          action: "websocket",
          key: process.env.REACT_APP_DEV_KEY,
          data: {
            event,
            room,
            data
          }
        },
        success: () => {
          window.toastr.success();
          this.setState({
            loading: false,
            cancel: false,
            ...placeholder
          });
        },
        failure: e => {
          window.toastr.error(e);
          this.setState({ loading: false, cancel: false });
        }
      })
    });
  };

  render() {
    const { room, event, data, loading } = this.state;
    return (
      <Fragment>
        <Header content="Emitter" />
        <Segment secondary>
          <Form onSubmit={this.handleSubmit}>
            <Form.Input
              label="Event"
              placeholder={placeholder.event}
              value={event}
              onChange={(e, { value }) => this.setState({ event: value })}
            />
            <Form.Input
              label="Room"
              placeholder={placeholder.room}
              value={room}
              onChange={(e, { value }) => this.setState({ room: value })}
            />
            <Form.Field>
              <label>Data</label>
              <AceEditor
                {...aceConfig}
                onChange={data => this.setState({ data })}
                value={data}
              />
            </Form.Field>
            <Button
              type="submit"
              primary
              content="Send"
              basic
              loading={loading}
              disabled={loading}
            />
          </Form>
        </Segment>
      </Fragment>
    );
  }
}

const aceConfig = {
  mode: "json",
  theme: "monokai",
  name: "blah2",
  fontSize: 16,
  showPrintMargin: true,
  showGutter: true,
  highlightActiveLine: true,
  setOptions: {
    enableBasicAutocompletion: false,
    enableLiveAutocompletion: true,
    enableSnippets: true,
    showLineNumbers: true,
    tabSize: 2
  },
  style: { width: "100%" }
};

const placeholder = {
  event: "accounts",
  room: "",
  data: `{
  "action": "timepunch",
  "data": {
    "id": "6",
    "clocked_in": false
  }
}`
};
