import React from "react";
import styled from "styled-components";
import { connect } from "react-redux";

import { portalBlue, breadcrumbDivider } from "theme/colors";
import { secondBackgroundColor, fontColor } from "theme";

const Breadcrumb = ({ content }) => {
  return <BreadcrumbWrapper id="breadcrumb" children={content} />;
};

const BreadcrumbWrapper = styled.div`
  grid-area: breadcrumb;
  display: flex;
  align-items: center;
  padding: 0 1.5rem;
  font-weight: 600;

  background-color: ${secondBackgroundColor};
  color: ${fontColor};

  &&& {
    .ui.breadcrumb {
      font-weight: 600;
      .section {
        color: ${portalBlue};
      }
      .divider {
        color: ${breadcrumbDivider};
      }
      .active {
        color: ${fontColor};
      }
    }
  }
`;

const mapStateToProps = state => ({
  content: state.ContextBar.content
});

export default connect(mapStateToProps)(Breadcrumb);
