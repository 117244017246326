import React from "react";
import { Button, Dropdown } from "semantic-ui-react";
import naturalSort from "utils/naturalSort";
import { loadUnits } from "redux/BookingWizard/actions";
import { connect } from "react-redux";
class UnitStep extends React.Component {
  render() {
    const { data, units, setBaseUnit, loadUnits } = this.props;
    return (
      <div>
        <p>Unit for the Booking</p>
        <Dropdown
          selection
          search
          options={units.data
            .filter((row) => row.active)
            .map((row) => ({
              value: row.id,
              text: `${row.resort.resort_company.name} ${row.resort.name} ${
                row.name
              }`
            }))
            .sort((a, b) => naturalSort(a.text, b.text))}
          value={data.unit}
          onChange={(e, { value }) => setBaseUnit(value)}
          placeholder={"Select a unit"}
          style={{ marginBottom: "1rem", width: "400px" }}
        />
        <Button onClick={loadUnits} style={{ marginLeft: ".25rem" }}>
          Reload
        </Button>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  units: state.BookingWizard.units
});
const mapDispatchToProps = (dispatch) => ({
  loadUnits: () => dispatch(loadUnits())
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UnitStep);
