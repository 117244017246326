import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { setPerPage } from "redux/EOQReport/actions";
import { Dropdown } from "semantic-ui-react";

export class PerPage extends Component {
  static propTypes = {
    setPerPage: PropTypes.func.isRequired,
    value: PropTypes.number.isRequired
  };

  render() {
    const { value, setPerPage } = this.props;
    return (
      <Dropdown
        selection
        selectOnBlur={false}
        placeholder="Select one..."
        onChange={(undefined, { value }) => setPerPage(value)}
        value={value}
        options={[
          { value: 5, text: "5 rows/page" },
          { value: 10, text: "10 rows/page" },
          { value: 25, text: "25 rows/page" },
          { value: 50, text: "50 rows/page" },
          { value: 100, text: "100 rows/page" },
          { value: 250, text: "250 rows/page" }
        ]}
      />
    );
  }
}

const mapStateToProps = state => ({
  value: state.EOQReport.per_page
});

const mapDispatchToProps = {
  setPerPage
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PerPage);
