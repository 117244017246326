import React, { Component } from "react";
import PropTypes from "prop-types";
import { Container } from "semantic-ui-react";
import injectSheet from "react-jss";

import AjaxComponent from "components/AjaxComponent";
import WithState from "components/WithState";
import Layout from "components/Layout";
import Header from "./components/Header";
import CoverPage from "./components/CoverPage";
import OtherPages from "./components/OtherPages";
import ContextBar from "components/ContextBar";
import styles from "./styles";

import getSocketHandler from "services/sockets";
const socket = getSocketHandler();

class CheckInvoicePage extends Component {
  static propTypes = {
    history: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    classes: PropTypes.object.isRequired
  };

  state = {
    deletedIDs: []
  };

  componentDidMount() {
    socket
      .connect()
      .joinRoom("checks")
      .on("client_check_deleted", data => {
        this.markAsDeleted(Number(data["ID"]));
      });
  }

  componentWillUnmount() {
    socket.off();
  }

  /**
   * Pushes the id into state.deletedIDs, updates the URL,
   * and shows an info toast if necessary.
   *
   * @param {Number} id a check ID
   */
  markAsDeleted = id => {
    const oldIDs = this.getIDs();
    /**
     * first, we verify that we're actually rendering a check with the passed id.
     * if we aren't, we don't actually have to do anything.
     */
    if (oldIDs.includes(id)) {
      /**
       * first we update deletedIDs in our state. this will cause the
       * check to no longer display as it will be filtered out in our render method.
       */
      this.setState({
        deletedIDs: this.state.deletedIDs.concat(id)
      });
      // if necessary, show a info toast to the user
      window.toastr.info(
        "A check in this queue has been deleted, so its invoice has been removed from this page."
      );
      // finally, we update the url.
      const newIDs = oldIDs.filter(oldID => oldID !== id);
      this.pushToURL(newIDs);
    }
  };

  /**
   * this function accepts a JSON-encoded URL-encoded array of check IDs,
   * and an id to filter out from that array. it will then update the url to
   * include the filtered array.
   *
   * This is necessary after deleting a check because the server will kick an
   * error if the user refreshes and a deleted check ID is still present.
   * @param {String} oldIDs
   * @param {String} id
   */
  pushToURL = (newIDs, id) => {
    const { history } = this.props;
    /**
     * If any IDs are left after the filter, we can change the URL
     * It's important that we don't redirect, we only visually update the URL
     *
     * If there are no IDs left after the filter, we just redirect the user
     * to the checks list & show an info toast.
     */
    if (newIDs.length) {
      const encodedIDs = encodeURIComponent(JSON.stringify(newIDs));
      history.push(`/browse/checks/${encodedIDs}/invoice`);
    } else {
      window.toastr.info(
        "You have been redirected away from the print invoices page because no invoices remain to be printed."
      );
      history.push("/browse/checks");
    }
  };

  /**
   * URL-decodes and JSON-decodes the check IDs from the url
   * Automatically converts a single ID to an array format if only one
   * check is being viewed
   *
   * @return {Array}
   */
  getIDs = () => {
    const { id } = this.props.match.params;
    const ids = JSON.parse(decodeURIComponent(id));

    if (Array.isArray(ids)) {
      return ids;
    } else {
      return [ids];
    }
  };

  /**
   * Callback to filter out any invoices for checks that have
   * since been deleted
   *
   * @param {Object} check
   * @return {Bool}
   */
  filterCallback = check => !this.state.deletedIDs.includes(check.id);

  /**
   * callback to sort bookings by checkin date
   */
  sortCallback = (a, b) => new Date(a.check_in) - new Date(b.check_in);

  /**
   * calculates the amount of points used for a single booking
   * @param {Object} booking
   * @return {Number}
   */
  bookingPtsUsed = booking =>
    booking.point_usages.reduce((a, c) => a + c.amount, 0);

  /**
   * calculates the amount of points used for multiple bookings
   * @param {Array} bookings
   * @return {Number}
   */
  bookingsPtsUsed = bookings =>
    bookings.reduce((a, c) => a + this.bookingPtsUsed(c), 0);

  getConfig = () => ({
    ajaxConfig: {
      url: "/apis/portal/checks",
      data: {
        action: "read_for_invoice",
        data: { IDs: this.getIDs() }
      }
    },
    stateful: false
  });

  render() {
    const { classes } = this.props;
    return (
      <AjaxComponent {...this.getConfig()}>
        {({ data, loading, error }) => {
          if (loading || error) {
            return (
              <Layout noBar>
                <ContextBar />
                <Container>
                  <br />
                  <WithState loading={loading} error={error} />
                </Container>
              </Layout>
            );
          }

          return (
            <div className={classes.background}>
              <Header classes={this.props.classes} />
              {data.filter(this.filterCallback).map((row, key) => {
                const bookings = row.bookings.sort(this.sortCallback);
                const totalPtsUsed = this.bookingsPtsUsed(bookings);
                return (
                  <React.Fragment key={key}>
                    <CoverPage
                      classes={this.props.classes}
                      check={row}
                      totalPtsUsed={totalPtsUsed}
                    />
                    <OtherPages
                      classes={this.props.classes}
                      check={row}
                      bookings={bookings}
                    />
                  </React.Fragment>
                );
              })}
            </div>
          );
        }}
      </AjaxComponent>
    );
  }
}

export default injectSheet(styles)(CheckInvoicePage);
