import React, { Fragment } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import { Button } from "semantic-ui-react";

import { formatMJY } from "utils/dates";

const Sets = props => {
  const { set, sets, activeSet } = props;
  const sorted = sets.sort((a, b) => new Date(b.start) - new Date(a.start));

  return (
    <OuterContainer>
      <Button
        primary={!activeSet}
        basic
        icon="plus"
        content="Add New Set"
        onClick={() => set(null)}
      />
      <Container>
        {sorted.map((ps, index) => (
          <Button
            key={index}
            basic
            content={ps.name || formatMJY(ps.start)}
            onClick={() => set(ps.id)}
            primary={activeSet === ps.id}
          />
        ))}
      </Container>
    </OuterContainer>
  );
};

Sets.propTypes = {
  activeSet: PropTypes.number,
  set: PropTypes.func.isRequired,
  sets: PropTypes.array.isRequired
};

export default Sets;

const OuterContainer = styled.div`
  display: flex;
`;

const Container = styled.div`
  max-width: 100%;
  overflow-x: scroll;
  flex-grow: 1;
  padding-bottom: 0.25rem;
`;
