import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";

import { Segment, Divider, Button } from "semantic-ui-react";

import MakeDealsheet from "../MakeDealsheet";

export default class LegacyAgreementHeader extends Component {
  static propTypes = {
    data: PropTypes.object.isRequired,
    showing: PropTypes.string.isRequired,
    handleChange: PropTypes.func.isRequired,
    reload: PropTypes.func.isRequired
  };

  render() {
    const { showing, handleChange, reload, data } = this.props;
    return (
      <Segment secondary>
        <p>
          This is a <b>legacy agreement</b> that was converted from our old
          system to our new system. It may not visually look the same, but the
          information the user entered is the same. You can still attach and
          edit a dealsheet for this agreement, but certain features are
          disabled.
        </p>
        <Divider />
        {showing === "agreement" ? (
          <Fragment>
            <p>
              You are currently viewing the agreement. Use the button below to
              view its dealsheet.
            </p>
            {data.deal_sheet.id ? (
              <Button
                basic
                secondary
                content="View Dealsheet"
                onClick={handleChange}
              />
            ) : (
              <MakeDealsheet data={data} reload={reload} />
            )}
          </Fragment>
        ) : (
          <Fragment>
            <p>
              You are currently viewing the dealsheet. Use the button below to
              view its agreement.
            </p>
            <Button
              basic
              primary
              content="View Agreement"
              onClick={handleChange}
            />
          </Fragment>
        )}
      </Segment>
    );
  }
}
