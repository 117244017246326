import React, { Component } from "react";
import { Header } from "semantic-ui-react";
import PropTypes from "prop-types";
import CommentsComponent from "components/Comments";

export default class Comments extends Component {
  static propTypes = {
    unit: PropTypes.object.isRequired
  };

  render() {
    return (
      <div>
        <Header dividing>
          <Header.Content>Comments</Header.Content>
        </Header>
        <CommentsComponent thread={this.props.unit.thread} />
      </div>
    );
  }
}
