import React, { Component, Fragment } from "react";
import { Button, Segment, Header, Form } from "semantic-ui-react";

import Amount from "./components/Amount";
import ClientInfo from "./components/ClientInfo";
import EmployeeInfo from "./components/EmployeeInfo";
import Membership from "./components/Membership";
import Maintenance from "./components/Maintenance";
import Timeshares from "./components/Timeshares";
import Notes from "./components/Notes";

class DealSheetsForm extends Component {
  render() {
    const {
      appointment_setter,
      client,
      closing_agent,
      handleChange,
      handleDealSheetSubmission,
      handleTimeshareAddition,
      handleTimeshareRemoval,
      handleTimeshareUpdate,
      maintenance,
      membership,
      notes,
      onboarding_agent,
      processed_amount,
      processing_agent,
      quoted_amount,
      timeshares,
      loading,
      received_on,
      legacy
    } = this.props;
    return (
      <Form onSubmit={handleDealSheetSubmission}>
        <Segment attached>
          {!legacy && (
            <EmployeeInfo
              appointment_setter={appointment_setter}
              closing_agent={closing_agent}
              onboarding_agent={onboarding_agent}
              processing_agent={processing_agent}
              received_on={received_on}
            />
          )}
          <Header as="header" dividing>
            Amount
          </Header>
          <Amount
            processed_amount={processed_amount}
            quoted_amount={quoted_amount}
            handleChange={handleChange}
          />
          <br />
          <Header as="header" dividing>
            Client Information
          </Header>
          <ClientInfo {...client} handleChange={handleChange} />
          <br />
          <Header as="header" dividing>
            Membership
          </Header>
          <Membership {...membership} handleChange={handleChange} />
          <br />
          <Header as="header" dividing>
            Maintenance
          </Header>
          <Maintenance {...maintenance} handleChange={handleChange} />
          <br />
          <Header as="header" dividing>
            Timeshares
          </Header>
          <Timeshares
            timeshares={timeshares}
            handleChange={handleTimeshareUpdate}
            handleTimeshareAddition={handleTimeshareAddition}
            handleTimeshareRemoval={handleTimeshareRemoval}
          />
          <br />
          <Header as="header" dividing>
            Notes
          </Header>
          <Notes notes={notes} handleChange={handleChange} />
          <br />
        </Segment>
        <Segment inverted attached="bottom">
          {legacy || received_on === null ? (
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <Button
                type="submit"
                loading={loading}
                disabled={loading}
                color="blue"
                inverted
                content="Save Dealsheet"
              />
            </div>
          ) : (
            <div>
              <br />
              <p>
                This dealsheet can not be saved anymore because the agreement
                has been submitted.
              </p>
            </div>
          )}
        </Segment>
      </Form>
    );
  }
}

export default DealSheetsForm;
