import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { Table } from "semantic-ui-react";

export class index extends Component {
  static propTypes = {
    namespace: PropTypes.string.isRequired,
    tables: PropTypes.object.isRequired
  };

  render() {
    const { cols } = this.props.tables[this.props.namespace];
    const cs = cols.filter(r => r.visible || typeof r.visible === "undefined");

    return (
      <Table.Row>
        <Table.Cell colSpan={cs.length}>
          <br />
          <p>There are no results to display.</p>
          <br />
        </Table.Cell>
      </Table.Row>
    );
  }
}

const mapStateToProps = state => ({
  tables: state.PortalTables
});

const mapDispatchToProps = {
  // ...
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(index);
