import React from "react";
import PropTypes from "prop-types";

const Content = ({ children = [], flex = false }) => {
  const styles = flex
    ? { overflowY: "auto", overflowX: "hidden", flexGrow: "1" }
    : { overflowY: "visible" };

  return <div style={styles}>{children}</div>;
};

Content.propTypes = {
  children: PropTypes.any,
  flex: PropTypes.bool
};

export default Content;
