import React from "react";
import { Table } from "semantic-ui-react";
/**
 *  @prop row
 *  @prop col
 */
export default class TableCell extends React.PureComponent {
  render() {
    const { row, col } = this.props;
    if (col.visible || col.visible === undefined) {
      const data = typeof col.src === "string" ? row[col.src] : col.src(row);
      const props =
        typeof col.props === "function" ? col.props(row) : col.props;
      return (
        <Table.Cell {...props}>
          {col.render ? col.render(data, row) : data}
        </Table.Cell>
      );
    } else {
      return null;
    }
  }
}
