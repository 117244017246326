import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import update from "immutability-helper";

import { Segment, Header, Icon } from "semantic-ui-react";

import DealSheetsForm from "./components/Form";
import AjaxFn from "services/AjaxFn";

class DealSheet extends Component {
  componentDidMount() {
    const { deal_sheet } = this.props.data;
    const { maintenance, timeshares, client, membership } = deal_sheet;
    // Maintenance & Timeshares must be JSON decoded (to preserve booleans)
    this.setState({
      ...deal_sheet,
      maintenance: maintenance
        ? JSON.parse(maintenance)
        : {
            fees: "",
            fee_withdrawl_occurrence: "",
            auto_draft: false,
            fees_up_to_date: true
          },
      timeshares: timeshares
        ? JSON.parse(timeshares)
        : [
            {
              contracts: "",
              developer_name: "",
              outstanding_balance: false,
              contract_numbers: ""
            }
          ],
      client: client || {
        name: "",
        email: "",
        phone: "",
        time_zone: ""
      },
      membership: membership || {
        vip_level: "",
        resort: "",
        points: "",
        years_owner: "",
        member_number: ""
      },
      notes: deal_sheet.notes || ""
    });
  }

  state = {
    loading: false
  };

  // change handler for data in the component state
  handleChange = (e, { name, value, nested }) => {
    let state = Object.assign({}, this.state);
    nested ? (state[nested][name] = value) : (state[name] = value);
    return this.setState({ ...state });
  };

  handleTimeshareAddition = () => {
    const { timeshares } = this.state;
    const initialTimeshares = timeshares;
    const timeshareObject = {
      contracts: "",
      developer_name: "",
      outstanding_balance: false,
      contract_numbers: ""
    };
    const addTimeshare = update(initialTimeshares, {
      $push: [timeshareObject]
    });
    this.setState({ timeshares: addTimeshare });
  };

  handleTimeshareRemoval = (e, { value }) => {
    const { timeshares } = this.state;
    const initialTimeshares = timeshares;
    const index = value;
    const removeTimeshare = update(initialTimeshares, {
      $splice: [[index, 1]]
    });
    this.setState({ timeshares: removeTimeshare });
  };

  handleTimeshareUpdate = (e, { name, value, index }) => {
    const { timeshares } = this.state;
    const initialTimeshares = timeshares;
    const updateTimeshare = update(initialTimeshares, {
      [index]: { [name]: { $set: value } }
    });

    this.setState({ timeshares: updateTimeshare });
  };

  handleDealSheetSubmission = e => {
    e.preventDefault();
    const { data, legacy } = this.props;
    this.setState({ loading: true });

    AjaxFn({
      url: legacy
        ? "/apis/portal/legacyagreementdealsheets"
        : "/apis/portal/dealsheets",
      data: {
        action: "update",
        data: {
          ID: data.id,
          ...this.state,
          // Maintenance & Timeshares must be JSON encoded (to preserve booleans)
          maintenance: JSON.stringify(this.state.maintenance),
          timeshares: JSON.stringify(this.state.timeshares)
        }
      },
      success: () => window.toastr.success(),
      failure: e => window.toastr.error(e),
      finally: () => this.setState({ loading: false })
    });
  };

  render() {
    return (
      <Fragment>
        <Segment inverted attached="top">
          <Header as="h1" inverted>
            <Header.Content>
              <Icon name="sticky note outline" /> Deal Sheet
              <Header.Subheader>
                The client can not see this form. This is for internal use only.
              </Header.Subheader>
            </Header.Content>
          </Header>
        </Segment>
        <DealSheetsForm
          {...this.state}
          handleChange={this.handleChange}
          handleDealSheetSubmission={this.handleDealSheetSubmission}
          handleTimeshareAddition={this.handleTimeshareAddition}
          handleTimeshareRemoval={this.handleTimeshareRemoval}
          handleTimeshareUpdate={this.handleTimeshareUpdate}
          loading={this.state.loading}
          received_on={this.props.data.received_on}
          legacy={this.props.legacy}
        />
      </Fragment>
    );
  }
}

DealSheet.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]).isRequired,
  legacy: PropTypes.bool
};

export default DealSheet;
