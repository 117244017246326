import React from "react";
import { Message, Button } from "semantic-ui-react";
import AjaxFn from "services/AjaxFn";
/**
 *	@prop context
 */
export default class Prefill extends React.Component {
  submit = () => {
    this.props.context.functions.startSubmit({ loading: true });
    AjaxFn({
      url: "/apis/portal/agreements",
      data: {
        action: "prefill",
        data: {
          ID: this.props.context.agreement.id,
          data: {
            ...this.props.context.data
          }
        }
      },
      success: () => {
        window.toastr.success();
        this.props.context.functions.endSubmit();
      },
      failure: error => {
        window.toastr.error(error);
        this.props.context.functions.endSubmit();
      }
    });
  };
  render() {
    const { context } = this.props;
    const { type, agreement } = context;
    if (type !== "internal" || agreement.original_data.received_on) {
      return null;
    }
    return (
      <Message
        color="black"
        header="Only you can see this"
        content={
          <div>
            <p>
              Use the button below to fill out the agreement before sending it.
              Any payment fields will be ignored.
            </p>
            <Button
              inverted
              disabled={this.props.context.submitting}
              loading={this.props.context.submitting}
              onClick={this.submit}
              content="Save"
              color="blue"
              type="button"
            />
          </div>
        }
      />
    );
  }
}
