import React from "react";
import { Segment, Header, Grid, Icon, Form } from "semantic-ui-react";
import { Wrapper } from "../../../../context";

const MemberForm = ({ context }) => (
  <Segment vertical>
    <Grid stackable>
      <Grid.Column width={5}>
        <Header as={"h3"}>
          <Icon name={"child"} />
          Personal
        </Header>
        <p>
          Your personal information is required so that we may keep in contact
          with you.
        </p>
      </Grid.Column>
      <Grid.Column width={11}>
        <Form.Group widths={"equal"}>
          {context.functions.createInput(<Form.Input />, "firstname", {
            name: "firstname",
            label: "First name",
            placeholder: "First name",
            required: true,
            autoComplete: "off"
          })}
          {context.functions.createInput(<Form.Input />, "lastname", {
            name: "lastname",
            label: "Last name",
            placeholder: "Last name",
            required: true,
            autoComplete: "off"
          })}
        </Form.Group>
        {context.functions.createInput(<Form.Input />, "pay_to", {
          name: "pay_to",
          label: "Pay checks out to",
          placeholder: "Full name",
          required: true,
          autoComplete: "off"
        })}
        <Form.Group widths={"equal"}>
          {context.functions.createInput(<Form.Input />, "email", {
            name: "email",
            label: "Email address",
            placeholder: "Email address",
            required: true,
            autoComplete: "off"
          })}
          {context.functions.createInput(<Form.Input />, "phone", {
            name: "phone",
            label: "Phone number",
            placeholder: "Phone number",
            required: true,
            autoComplete: "off"
          })}
        </Form.Group>
        <Form.Group widths={"equal"}>
          {context.functions.createInput(<Form.Input />, "address", {
            name: "address",
            label: "Address",
            placeholder: "Address",
            required: true,
            autoComplete: "off"
          })}
          {context.functions.createInput(<Form.Input />, "city", {
            name: "city",
            label: "City",
            placeholder: "City",
            required: true,
            autoComplete: "off"
          })}
        </Form.Group>
        <Form.Group>
          {context.functions.createInput(<Form.Input />, "address_2", {
            name: "address_2",
            label: "Address Line 2",
            placeholder: "Address Line 2",
            required: false,
            autoComplete: "off",
            width: 8
          })}
          {context.functions.createInput(<Form.Input />, "state", {
            name: "state",
            label: "State",
            placeholder: "State",
            required: false,
            autoComplete: "off",
            width: 4
          })}
          {context.functions.createInput(<Form.Input />, "zip", {
            name: "zip",
            label: "Zipcode",
            placeholder: "Zipcode",
            required: false,
            autoComplete: "off",
            width: 4
          })}
        </Form.Group>
      </Grid.Column>
    </Grid>
  </Segment>
);
export default Wrapper(MemberForm);
