import React from "react";
import { Icon, Button } from "semantic-ui-react";
import Filters from "./components/Filters";
const namespace = "banks";
export default ({ ContentManager }) => ({
  tableName: namespace,
  url: "/apis/portal/banks",
  data: {
    action: "read"
  },
  tableData: {
    show: 25,
    order: [[1, "asc"]],
    paginationPosition: "bottom",
    showFooter: false,
    menu: () => [
      "[search]",
      "[show]",
      <Filters />,
      "[refresh]",
      <Button
        primary={true}
        onClick={ContentManager.functions.openWizard}
        content={"New Bank"}
      />
    ],
    cols: [
      {
        name: "",
        src: "id",
        render: (src, row) => (
          <a
            href={null}
            onClick={() => ContentManager.functions.openEditor(row)}
          >
            <Icon name={"external"} />
          </a>
        ),
        orderable: false,
        searchable: false,
        props: {
          textAlign: "center",
          selectable: true,
          width: 1
        }
      },
      {
        name: "Name",
        src: "portal_name"
      },
      {
        name: "Company",
        src: "company_name"
      },
      {
        name: "Bank Name",
        src: "bank_name"
      },
      {
        name: "Checks",
        src: row => row.checks.length,
        searchable: false,
        props: {
          width: 2
        }
      },
      {
        name: "Signature",
        src: "signature",
        searchable: false,
        render: src => {
          if (src) {
            return (
              <React.Fragment>
                <Icon name={"checkmark"} color={"green"} />
                Yes
              </React.Fragment>
            );
          } else {
            return (
              <React.Fragment>
                <Icon name={"cancel"} color={"red"} />
                No
              </React.Fragment>
            );
          }
        },
        props: row => {
          const props = { width: 2 };
          if (row.signature) {
            // props.positive = true
          } else {
            props.negative = true;
          }
          return props;
        }
      },
      {
        name: "Active",
        src: "active",
        searchable: false,
        render: src => {
          if (src) {
            return (
              <React.Fragment>
                <Icon name={"checkmark"} color={"green"} />
                Yes
              </React.Fragment>
            );
          } else {
            return (
              <React.Fragment>
                <Icon name={"cancel"} color={"red"} />
                No
              </React.Fragment>
            );
          }
        },
        props: row => {
          const props = { width: 2 };
          if (row.active) {
            // props.positive = true
          } else {
            props.negative = true;
          }
          return props;
        }
      }
    ]
  }
});
