import React from "react";
import {
  Modal,
  Button,
  Icon,
  Header,
  Table,
  Segment,
  Form
} from "semantic-ui-react";
import { formatMJY } from "utils/dates";
import MoneyInput from "components/MoneyInput";
import AjaxFn from "services/AjaxFn";
/**
 *	@prop booking
 *	@prop reload
 */
export default class GrossRentalIncome extends React.Component {
  state = {
    open: false,
    loading: false,
    value: "0.00"
  };
  save = () => {
    this.setState({ loading: true });
    AjaxFn({
      url: "/apis/portal/bookingfinances",
      data: {
        action: "create",
        data: {
          booking_id: this.props.booking.id,
          reason: "Add gross rental income",
          amount: this.state.value,
          type: "gross_rental_income"
        }
      },
      success: message => {
        this.props.reload();
      },
      failure: error => {
        window.toastr.error(error);
        this.setState({ loading: false });
      }
    });
  };
  render() {
    const gross_rental_incomes = this.props.booking.finances
      .filter(({ type }) => type === "gross_rental_income")
      .sort((a, b) => new Date(b.created_on) - new Date(a.created_on));
    const trigger = (
      <div onClick={() => this.setState({ open: true })}>
        {this.props.children}
      </div>
    );
    return (
      <Modal open={this.state.open} trigger={trigger} size={"tiny"}>
        <Modal.Header>Gross Rental Income</Modal.Header>
        {this.state.loading ? (
          <Modal.Content>
            <Segment
              loading
              style={{ paddingTop: "8rem", paddingBottom: "8rem" }}
            />
          </Modal.Content>
        ) : (
          <Modal.Content>
            {this.props.showIfEditable(
              <Form>
                <Form.Field>
                  <label>New Gross Rental Income</label>
                  <MoneyInput
                    label={"$"}
                    placeholder={"0.00"}
                    value={this.state.value}
                    onChange={value => this.setState({ value })}
                  />
                </Form.Field>
                <Form.Field>
                  <Button
                    onClick={this.save}
                    color={"blue"}
                    loading={this.state.loading}
                    disabled={this.state.loading}
                    content={"Update"}
                    icon={"save"}
                  />
                </Form.Field>
              </Form>
            )}
            <Header>
              <Icon name="history" />
              <Header.Content>
                History
                <Header.Subheader>Current and past values</Header.Subheader>
              </Header.Content>
            </Header>
            <div style={{ maxHeight: "300px", overflow: "auto" }}>
              <Table striped celled>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell>Amount</Table.HeaderCell>
                    <Table.HeaderCell>Date</Table.HeaderCell>
                    <Table.HeaderCell>Updated By</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {gross_rental_incomes.map((income, key) => (
                    <Table.Row key={key}>
                      <React.Fragment>
                        <Table.Cell>${income.amount}</Table.Cell>
                        <Table.Cell>{formatMJY(income.created_on)}</Table.Cell>
                        <Table.Cell>
                          {income.created_by.firstname +
                            " " +
                            income.created_by.lastname[0]}
                        </Table.Cell>
                      </React.Fragment>
                    </Table.Row>
                  ))}
                </Table.Body>
              </Table>
            </div>
            <br />
            <p>
              <Icon name={"info"} />
              The highest row is the current value for the booking.
            </p>
          </Modal.Content>
        )}
        <Modal.Actions>
          <Button onClick={() => this.setState({ open: false })}>Close</Button>
        </Modal.Actions>
      </Modal>
    );
  }
}
