import moment from "moment";
import { chunk } from "utils/arrays";
import { getDates } from "utils/dates";
import monthsArray from "fixtures/months";
export const getMonths = (year) => {
  const months = monthsArray.map((row) => ({
    name: row.text,
    year,
    month: row.month,
    days: getDaysInMonth(row.month, year)
  }));
  return chunk(months, 3);
};
export const getDaysInMonth = (month, year) => {
  const firstDay = moment(year + "-" + month + "-01");
  // what day of the week the first day is on
  const dayOfWeek = firstDay.day(); // 0 = sun, 6 = sat
  // the total number of days in the month
  const daysInMonth = firstDay.daysInMonth();
  const days = [];
  // add null for days of previous month
  for (let i = 1; i <= dayOfWeek; i++) {
    days.push(null);
  }
  // add in the actual days
  for (let i = 1; i <= daysInMonth; i++) {
    const ymd = year + "-" + month + "-" + i.toString().padStart(2, "0");
    const date = new Date(ymd + " 00:00:00");
    days.push({ date, ymd, day: i });
  }
  // add padding to square off the final row
  const padding = Math.ceil(days.length / 7) * 7;
  for (let i = days.length; i < padding; i++) {
    days.push(null);
  }
  return chunk(days, 7);
};
export const isRenderedAsBlackoutDate = (date, unit, changes) => {
  const original = isOriginallyBlackedOut(date, unit);
  if (!changes.length) {
    return original;
  } else {
    return isBlackedOutNow(date, changes, original);
  }
};
export const isRenderedAsBookingDate = (date, unit) =>
  unit.availability
    .filter((row) => row.type === "booking")
    .filter((row) => date >= row.start && date <= row.end).length > 0;
export const isOriginallyBlackedOut = (date, unit) =>
  unit.availability
    .filter((row) => row.type === "blackout")
    .filter((row) => date >= row.start && date <= row.end).length > 0;
export const isBlackedOutNow = (date, changes, original) => {
  const filterByDate = (row) => date >= row.rangeStart && date <= row.rangeEnd;
  const filterByUndone = (row) => row.undone === false;
  const filtered = changes.filter(filterByDate).filter(filterByUndone);
  if (filtered.length) {
    return filtered.reverse()[0].blackout;
  } else {
    return original;
  }
};
export const isSelected = (date, rangeStart, rangeEnd) => {
  if (rangeStart) {
    if (rangeStart === date) {
      // in the case of a single square being selected
      return true;
    }
    if (rangeEnd) {
      // flip start/end date if user is selecting backwards
      const [from, to] =
        rangeStart < rangeEnd ? [rangeStart, rangeEnd] : [rangeEnd, rangeStart];
      return date >= from && date <= to;
    }
  }
  return false;
};
export const getFinalBlackout = (unit, changes) => {
  const { availability } = unit;
  const validBlackouts = availability.filter((row) => row.type === "blackout");
  const validChanges = changes.filter((row) => !row.undone);
  const dates = [];
  validBlackouts.forEach((range) => {
    getDates(range.start, range.end).forEach((date) => {
      if (dates.indexOf(date) === -1) {
        dates.push(date);
      }
    });
  });
  validChanges.forEach((range) => {
    getDates(range.rangeStart, range.rangeEnd).forEach((date) => {
      const index = dates.indexOf(date);
      if (range.blackout) {
        if (index === -1) {
          dates.push(date);
        }
      } else {
        dates.splice(index, 1);
      }
    });
  });
  return dates.sort((a, b) => new Date(a) - new Date(b));
};
