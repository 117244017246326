import React, { useState } from "react";
import PropTypes from "prop-types";

import { Modal, Button, Form } from "semantic-ui-react";

import AjaxFn from "services/AjaxFn";

const CloseIssue = props => {
  const [state, setState] = useState({
    text: "",
    open: false,
    loading: false
  });

  const handleOpen = () => setState({ ...state, open: true });
  const handleClose = () => {
    if (!state.loading) {
      setState({ ...state, open: false });
    }
  };
  const handleChange = (e, { value }) => setState({ ...state, text: value });

  const closeIssue = () => {
    setState({ ...state, loading: true });
    AjaxFn({
      url: "/apis/portal/memberissues",
      data: {
        action: "close",
        data: {
          ID: props.data.id,
          closed_reason: state.text
        }
      },
      success: () => {
        window.toastr.success();
        props.reload();
      },
      failure: error => {
        window.toastr.error(error);
        setState({ ...state, loading: false });
      }
    });
  };

  const modalProps = {
    open: state.open,
    onClose: handleClose,
    size: "mini",
    trigger: (
      <Button
        basic
        color="blue"
        content="Mark issue as closed"
        fluid
        onClick={handleOpen}
        disabled={!props.canClose}
      />
    )
  };

  return (
    <Modal {...modalProps}>
      <Modal.Header>Close Issue</Modal.Header>
      <Modal.Content>
        <Form>
          <Form.TextArea
            label="Reason/Description"
            value={state.text}
            onChange={handleChange}
            placeholder="Reason for closing..."
            maxLength="512"
            disabled={state.loading}
          />
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button
          onClick={handleClose}
          disabled={state.loading}
          icon="cancel"
          content="Close"
        />
        <Button
          disabled={!state.text || state.loading}
          loading={state.loading}
          onClick={closeIssue}
          content="Submit"
          primary
        />
      </Modal.Actions>
    </Modal>
  );
};

CloseIssue.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  reload: PropTypes.func,
  canClose: PropTypes.bool.isRequired
};

export default CloseIssue;
