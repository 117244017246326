import React from "react";
import { Form, Divider, Dropdown } from "semantic-ui-react";

export default class AlignmentHeader extends React.Component {
  render() {
    const { state, functions, request } = this.props.data;
    return (
      <React.Fragment>
        <Form>
          <Form.Group>
            <Form.Field width={4}>
              <label>Alignment</label>
              <Dropdown
                disabled={state.query !== ""}
                selection
                options={[
                  { value: "horiz", text: "Rights across top" },
                  { value: "vert", text: "Users across top" }
                ]}
                value={state.align}
                placeholder={"Select an alignment"}
                onChange={(e, { value }) => functions.setAlignment(value)}
              />
            </Form.Field>
            <Form.Field width={4}>
              <label>Filter By</label>
              <Dropdown
                selection
                options={[
                  { value: "user", text: "Specific user" },
                  { value: "right", text: "Specific right" }
                ]}
                value={state.mode}
                placeholder={"Select a mode"}
                onChange={(e, { value }) => functions.setMode(value)}
              />
            </Form.Field>
            <Form.Field width={4}>
              <label>Filter For</label>
              <Dropdown
                selection
                options={
                  state.mode === "right"
                    ? [{ value: "", text: "Nothing" }].concat(
                        functions.processRights(request).map(r => ({
                          value: r,
                          text: r
                        }))
                      )
                    : [{ value: "", text: "Nothing" }].concat(
                        functions.processAccounts(request).map(a => ({
                          value: a.id,
                          text: a.firstname + " " + a.lastname
                        }))
                      )
                }
                value={state.query}
                placeholder={
                  state.mode === "right"
                    ? "Select a right"
                    : "Select an account"
                }
                onChange={(e, { value }) => functions.setQuery(value)}
              />
            </Form.Field>
          </Form.Group>
        </Form>
        <Divider />
      </React.Fragment>
    );
  }
}
