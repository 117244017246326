import React from "react";
import { Comment } from "semantic-ui-react";
export default ({ comment }) => {
  if (comment.is_system) {
    return (
      <Comment.Author as={"a"} href={null}>
        {"System"}
      </Comment.Author>
    );
  } else {
    if (comment.account.firstname) {
      return (
        <Comment.Author as={"a"} href={null}>
          {comment.account.firstname + " " + comment.account.lastname[0]}
        </Comment.Author>
      );
    } else {
      return (
        <Comment.Author as={"a"} href={null}>
          Unknown Author
        </Comment.Author>
      );
    }
  }
};
