import React from "react";
import { Container } from "semantic-ui-react";
import AjaxComponent from "components/AjaxComponent";
import WithState from "components/WithState";
import Layout from "components/Layout";
import Content from "./components/Content";
import Breadcrumb, { LoadedBreadcrumb } from "./components/Breadcrumbs";

export default class CheckPage extends React.Component {
  reload = () => {
    if (this.ref) {
      this.ref.reload();
    }
  };
  render() {
    const config = {
      ajaxConfig: {
        url: "/apis/portal/checks",
        data: {
          action: "read_one",
          data: { ID: this.props.match.params.id }
        }
      },
      stateful: false,
      ref: ref => (this.ref = ref)
    };
    return (
      <AjaxComponent {...config}>
        {({ data, loading, error }) => {
          if (loading || error || !data) {
            return (
              <Layout noBar>
                <Breadcrumb />
                <Container>
                  <br />
                  <WithState loading={loading} error={error} />
                </Container>
              </Layout>
            );
          }
          return (
            <Layout noBar>
              <LoadedBreadcrumb check={data} />
              <Content data={data} reload={this.reload} />
            </Layout>
          );
        }}
      </AjaxComponent>
    );
  }
}
