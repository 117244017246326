import React, { Component } from "react";
import PropTypes from "prop-types";
import { debounce } from "lodash";
import moment from "moment";

import { Form, Message } from "semantic-ui-react";

import AjaxFn from "services/AjaxFn";
import NullableDropdown from "components/NullableDropdown";

export default class AsyncForm extends Component {
  static propTypes = {
    // The function to run on save success
    success: PropTypes.func,
    // The function to run on save failure
    failure: PropTypes.func,
    // The function that builds the data object to pass into AjaxFn
    // Should return { url: "", data: {} }
    dataFn: PropTypes.func.isRequired,
    // If the act of submitting should disable the form, allowing it to finish before the next is available
    multiSubmit: PropTypes.bool,
    // render function
    children: PropTypes.func.isRequired,
    // function that allows a data reload
    reload: PropTypes.func.isRequired
  };

  state = {
    submitting: false,
    lastSave: null
  };

  startSubmitting = () => this.setState({ submitting: true });
  endSubmitting = () => this.setState({ submitting: false });
  updateLastSave = () => this.setState({ lastSave: moment() });

  handleSubmit = e => e.preventDefault();

  render() {
    const { multiSubmit = true } = this.props;

    const sharedConfig = {
      success: this.props.success || (() => true),
      failure: this.props.failure || (() => true),
      dataFn: this.props.dataFn,
      reload: this.props.reload,
      startSubmitting: this.startSubmitting,
      endSubmitting: this.endSubmitting,
      updateLastSave: this.updateLastSave,
      canChange: multiSubmit || !this.state.submitting
    };

    return (
      <Form onSubmit={this.handleSubmit}>
        {this.props.children({
          functions: {
            // a hooked up Form.Input
            input: config => {
              return (
                <AsyncFormInput
                  config={{
                    debounce: 500,
                    element: <Form.Input />,
                    ...config
                  }}
                  {...sharedConfig}
                />
              );
            },
            // a hooked up Form.Dropdown (essentially)
            dropdown: config => {
              const {
                props: { label, ...restOfProps },
                ...restOfConfig
              } = config;
              return (
                <Form.Field>
                  {label && <label>{label}</label>}
                  <AsyncFormInput
                    config={{
                      element: <NullableDropdown />,
                      props: {
                        selection: true,
                        selectOnBlur: false,
                        ...restOfProps
                      },
                      ...restOfConfig
                    }}
                    {...sharedConfig}
                  />
                </Form.Field>
              );
            },
            // for making custom fields
            make: config => (
              <AsyncFormInput config={config} {...sharedConfig} />
            ),
            // for displaying a message that the form autosaves
            message: () => (
              <AsyncFormMessage
                lastSave={this.state.lastSave}
                submitting={this.state.submitting}
              />
            )
          }
        })}
      </Form>
    );
  }
}

export class AsyncFormInput extends Component {
  static propTypes = {
    canChange: PropTypes.bool.isRequired,
    dataFn: PropTypes.func.isRequired,
    success: PropTypes.func.isRequired,
    failure: PropTypes.func.isRequired,
    reload: PropTypes.func.isRequired,
    startSubmitting: PropTypes.func.isRequired,
    endSubmitting: PropTypes.func.isRequired,
    updateLastSave: PropTypes.func.isRequired,
    config: PropTypes.shape({
      reload: PropTypes.bool,
      element: PropTypes.element.isRequired,
      props: PropTypes.object.isRequired,
      debounce: PropTypes.number,
      field: PropTypes.string.isRequired,
      value: PropTypes.any
    }).isRequired
  };

  /**
   * Initializes a debounced save function
   */
  save = debounce(
    value => this.sendSaveRequest(value),
    typeof this.props.config.debounce === "undefined"
      ? 0
      : this.props.config.debounce
  );

  state = {
    loading: false,
    cancel: null,
    value: this.props.config.value || "",
    valueBeforeSave: null
  };

  /**
   * Allows updating this.state.value by updating the prop passed
   * This won't trigger the save function (this is intentional)
   */
  componentWillReceiveProps(props) {
    if (props.config.value !== this.props.config.value) {
      this.setState({
        value: props.config.value
      });
    }
  }

  /**
   * Just cancels any pending requests on unmount
   */
  componentWillUnmount() {
    this.cancel();
  }

  /**
   * Check if we have a cancel token, and if so, call it as a function
   */
  cancel = () => {
    if (typeof this.state.cancel === "function") {
      this.state.cancel();
    }
  };

  /**
   * Onchange handler
   * Checks if the input can change, then if it should change, then handles
   * saving what the value should reset to incase saving fails
   */
  handleChange = (e, { value }) => {
    // first, make sure we're allowed to alter this field
    if (this.props.canChange) {
      // next, make sure the value is actually different
      if (value !== this.state.value) {
        // If we already have a valueBeforeSave set up, we don't want to overwrite it.
        if (this.state.valueBeforeSave) {
          this.setState({ value });
        } else {
          this.setState({
            valueBeforeSave: this.state.value,
            value
          });
        }
        this.save(value);
      }
    }
  };

  sendSaveRequest = value => {
    this.props.startSubmitting();
    this.cancel();
    this.setState({
      loading: true,
      // create our AjaxFn request and save the cancel token
      // The call will fire immediately
      cancel: AjaxFn({
        // url and data are spread out from the result of dataFn
        ...this.props.dataFn(this.props.config.field, value),
        success: this.handleAjaxSuccess,
        failure: this.handleAjaxFailure
      })
    });
  };

  /**
   * Callback ran when ajax saving succeeds
   * @param {Mixed} response - response from server
   */
  handleAjaxSuccess = response => {
    const newState = {
      loading: false,
      valueBeforeSave: null
    };
    // set loading state complete, when done run a callback function
    this.setState(newState, () => {
      // register that we've stopped submitting in AsyncForm
      this.props.endSubmitting();
      // tell AsyncForm to update when its last save occurred
      this.props.updateLastSave();
      // run the success method for AsyncForm
      this.props.success(response);
      // reload if necessary
      if (this.props.config.reload) {
        this.props.reload();
      }
    });
  };

  /**
   * Callback ran when ajax saving fails
   * @param {Mixed} error - the error received
   * @param {Mixed} value - the value to reset state to
   */
  handleAjaxFailure = error => {
    // set loading state complete,
    // also, we set the value to what it was before the save attempted
    const newState = {
      loading: false,
      value: this.state.valueBeforeSave,
      valueBeforeSave: null
    };
    this.setState(newState, () => {
      // register that we've stopped submitting in AsyncForm
      this.props.endSubmitting();
      // run callback error function for AsyncForm
      this.props.failure(error);
    });
  };

  render() {
    const { element, props } = this.props.config;
    return React.cloneElement(element, {
      loading: this.state.loading,
      disabled: !this.props.canChange,
      ...props,
      onChange: this.handleChange,
      value: this.state.value
    });
  }
}

export class AsyncFormMessage extends Component {
  static propTypes = {
    lastSave: PropTypes.instanceOf(moment),
    submitting: PropTypes.bool.isRequired
  };

  componentDidMount() {
    setTimeout(() => {
      if (this.props.lastSave) {
        this.setState({
          ago: this.props.lastSave.fromNow()
        });
      }
    }, 30000);
  }

  componentWillReceiveProps(props) {
    if (props.lastSave !== this.props.lastSave) {
      this.setState({
        ago: props.lastSave.fromNow()
      });
    }
  }

  render() {
    return (
      <Message
        info
        icon="cloud upload"
        header="Changes saved automatically"
        content={
          <div>
            <p>{"Changes will be saved as you make them."}</p>
            {this.props.submitting ? (
              <p>{"Saving..."}</p>
            ) : (
              this.props.lastSave && (
                <p>
                  {"Last saved "}
                  {this.state.ago}
                </p>
              )
            )}
          </div>
        }
      />
    );
  }
}
