import React from "react";
import { Button, Form, Input, Segment, Header } from "semantic-ui-react";
import styled from "styled-components";

export default (ContentManager, Editor) => {
  return ContentManager.state.editorActive ? (
    <Segment.Group>
      <Segment raised color="blue">
        <Header as="h4" content="Company Info" />
      </Segment>
      <Segment loading={ContentManager.state.editorLoading}>
        <Form>
          <Form.Field>
            <a
              href="https://htmlcolorcodes.com/color-picker/"
              target="_blank"
              rel="nofollow noreferrer"
              style={{
                textAlign: "right",
                position: "absolute",
                right: 0,
                top: 0
              }}
            >
              Color Picker
            </a>
            <label htmlFor="color">Color</label>
            {Editor.functions.createInput(
              <Input disabled id="color" />,
              "color",
              {
                placeholder: "#EEEEEE"
              }
            )}
          </Form.Field>
          <Form.Field>
            <label htmlFor="logo">Logo</label>
            {Editor.functions.createInput(<Input disabled id="logo" />, "logo")}
          </Form.Field>
          <Form.Field>
            <label htmlFor="name">Name</label>
            {Editor.functions.createInput(<Input id="name" />, "name")}
          </Form.Field>
          <Form.Field>
            <label htmlFor="name_alt">Name Alt</label>
            {Editor.functions.createInput(
              <Input disabled id="name_alt" />,
              "name_alt"
            )}
          </Form.Field>
          <Form.Field>
            <label htmlFor="email">Email</label>
            {Editor.functions.createInput(
              <Input disabled id="email" />,
              "email"
            )}
          </Form.Field>
          <Form.Field>
            <label htmlFor="phone">Phone</label>
            {Editor.functions.createInput(
              <Input disabled id="phone" />,
              "phone"
            )}
          </Form.Field>
          <Form.Field>
            <label htmlFor="web">Web</label>
            {Editor.functions.createInput(<Input disabled id="web" />, "web")}
          </Form.Field>
          <Form.Field>
            <label htmlFor="web_alt">Web Alt</label>
            {Editor.functions.createInput(
              <Input disabled id="web_alt" />,
              "web_alt"
            )}
          </Form.Field>
        </Form>
      </Segment>
      <SegmentActions>
        {ContentManager.functions.getDeleteButton()}
        <ButtonContainer>
          {Object.keys(Editor.data).length ? (
            <React.Fragment>
              <Button
                type="button"
                content="Discard"
                icon="undo"
                onClick={Editor.functions.reset}
                basic
              />
              <Button
                type="button"
                onClick={Editor.functions.save}
                loading={ContentManager.state.editorLoading}
                icon="save"
                content="Save"
                color="blue"
              />
            </React.Fragment>
          ) : null}
        </ButtonContainer>
      </SegmentActions>
    </Segment.Group>
  ) : null;
};

const SegmentActions = styled(Segment)`
  display: flex;
  justify-content: space-between;
  align-items: stretch;
`;

const ButtonContainer = styled.div`
  &&& {
    button {
      margin: 0 0 0 0.75em;
    }
  }
`;
