import PropTypes from "prop-types";

export default {
  resortData: PropTypes.shape({
    data: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]).isRequired,
    loading: PropTypes.bool.isRequired,
    error: PropTypes.any.isRequired
  }).isRequired,
  reloadResort: PropTypes.func.isRequired,
  resortTab: PropTypes.string,
  resortID: PropTypes.string.isRequired
};
