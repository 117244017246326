import React from "react";
import { Breadcrumb, Container } from "semantic-ui-react";
import { Link } from "react-router-dom";

import ContextBar from "components/ContextBar";

export const InitialBar = () => (
  <ContextBar>
    <Breadcrumb>
      <Breadcrumb.Section>
        <Link to={"/"}>Portal</Link>
      </Breadcrumb.Section>
      <Breadcrumb.Divider>/</Breadcrumb.Divider>
      <Breadcrumb.Section>
        <Link to={"/browse/legacyagreements"}>Legacy Agreements</Link>
      </Breadcrumb.Section>
      <Breadcrumb.Divider>/</Breadcrumb.Divider>
      <Breadcrumb.Section active>Loading...</Breadcrumb.Section>
    </Breadcrumb>
  </ContextBar>
);

export const LoadedBar = ({ data }) => (
  <ContextBar>
    <Breadcrumb>
      <Breadcrumb.Section>
        <Link to={"/"}>Portal</Link>
      </Breadcrumb.Section>
      <Breadcrumb.Divider>/</Breadcrumb.Divider>
      <Breadcrumb.Section>
        <Link to={"/browse/legacyagreements"}>Legacy Agreements</Link>
      </Breadcrumb.Section>
      <Breadcrumb.Divider>/</Breadcrumb.Divider>
      <Breadcrumb.Section active>
        {data.name} ({data.email})
      </Breadcrumb.Section>
    </Breadcrumb>
  </ContextBar>
);
