import React, { useRef } from "react";
import PropTypes from "prop-types";

import {
  Placeholder,
  Button,
  Checkbox,
  Table,
  Segment
} from "semantic-ui-react";

import AjaxComponent from "components/AjaxComponent";

const Memberships = props => {
  const ajaxComponentRef = useRef(null);

  /**
   * Function to reload the ajaxcomponent
   */
  const reload = () => ajaxComponentRef.current.reload();

  /**
   * Function to render checkboxes
   */
  const showBox = (id, value) => {
    return (
      <Checkbox
        {...{
          checked: props.applied[id] === value,
          fitted: true,
          onClick: () => props.set(id, value)
        }}
      />
    );
  };

  /**
   * Configuration object for <AjaxComponent />
   */
  const ajaxConfig = {
    ajaxConfig: {
      url: "/apis/portal/resortcompanies?action=read_for_dropdown"
    },
    ref: ajaxComponentRef,
    stateful: false
  };

  return (
    <Segment style={{ marginTop: "0px !important" }}>
      <AjaxComponent {...ajaxConfig}>
        {({ data, loading, error }) => {
          if (error) {
            return (
              <>
                <p>
                  Something went wrong loading the resort companies. Use the
                  button below to retry.
                </p>
                <Button basic color="red" content="Reload" onClick={reload} />
              </>
            );
          } else {
            return (
              <Table basic={"very"} compact={"very"}>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell width="10">Name</Table.HeaderCell>
                    <Table.HeaderCell width="2">Require</Table.HeaderCell>
                    <Table.HeaderCell width="2">Any</Table.HeaderCell>
                    <Table.HeaderCell width="2">Hide</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                {loading ? (
                  Placeholders
                ) : (
                  <Table.Body>
                    {data.map((company, key) => (
                      <Table.Row key={key}>
                        <Table.Cell>{company.name}</Table.Cell>
                        <Table.Cell>{showBox(company.id, true)}</Table.Cell>
                        <Table.Cell>
                          {showBox(company.id, undefined)}
                        </Table.Cell>
                        <Table.Cell>{showBox(company.id, false)}</Table.Cell>
                      </Table.Row>
                    ))}
                  </Table.Body>
                )}
              </Table>
            );
          }
        }}
      </AjaxComponent>
    </Segment>
  );
};

Memberships.propTypes = {
  // function to update applied
  set: PropTypes.func.isRequired,
  // object of ids + states
  applied: PropTypes.object.isRequired
};

export default Memberships;

const PlaceholderRow = (
  <Table.Row>
    <Table.Cell>
      <Placeholder>
        <Placeholder.Line />
        <Placeholder.Line />
      </Placeholder>
    </Table.Cell>
    <Table.Cell>
      <Checkbox disabled />
    </Table.Cell>
    <Table.Cell>
      <Checkbox disabled />
    </Table.Cell>
    <Table.Cell>
      <Checkbox disabled />
    </Table.Cell>
  </Table.Row>
);

const Placeholders = (
  <Table.Body>
    {PlaceholderRow}
    {PlaceholderRow}
    {PlaceholderRow}
    {PlaceholderRow}
    {PlaceholderRow}
    {PlaceholderRow}
    {PlaceholderRow}
  </Table.Body>
);
