import { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { pushNews } from "redux/NewsFeed/actions";

import getSocketHandler from "services/sockets";
const socket = getSocketHandler();

export class Announcements extends Component {
  static propTypes = {
    user: PropTypes.object.isRequired
  };

  componentDidMount() {
    const { user } = this.props;

    if (typeof user.id !== "undefined") {
      const { account_company, account_role } = user;

      socket
        .connect()
        .joinRoom(`announcements/${account_company.id}/${account_role.id}`)
        .on("client_announcement_posted", this.handleSocketEvent);
    }
  }

  componentWillUnmount() {
    socket.off();
  }

  /**
   * Verifies that the socket request's token isn't present in state.Socket.ignore
   * and if it isn't, it notifies the user that an announcement was posted
   */
  handleSocketEvent = data => {
    const { user, pushNews } = this.props;
    const { announcement } = data;
    const { header, created_by } = announcement;

    if (user.id !== created_by.id) {
      window.toastr.info(header, "New announcement");
    }

    pushNews(announcement);
  };

  render() {
    return null;
  }
}

const mapStateToProps = state => ({
  user: state.User
});

const mapDispatchToProps = {
  pushNews
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Announcements);
