import React from "react";
import { connect } from "react-redux";
import { Table } from "semantic-ui-react";

import TableRow from "../TableRow";
import { NoResults } from "components/PortalTable/parts.js";

class TableBody extends React.Component {
  render() {
    return this.props.finalData.length ? (
      this.props.finalData.map((row, ix) => <TableRow row={row} key={ix} />)
    ) : (
      <NoResults namespace="BookingList" />
    );
  }
}

const mapStateToProps = state => ({
  finalData: state.PortalTables["BookingList"].finalData
  // ...
});

const mapDispatchToProps = dispatch => ({
  // ...
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TableBody);
