import React, { Fragment } from "react";
import {
  Button,
  Form,
  Segment,
  Header,
  Dropdown,
  Divider
} from "semantic-ui-react";
import styled from "styled-components";

import AjaxDropdown from "components/AjaxDropdown";
import Pages from "./components/Pages";
import Privileges from "./components/Privileges";
import LogMeIn from "./components/LogMeIn";
import Disabled from "./components/Disabled";
import ResetPassword from "./components/ResetPassword";
import Deactivate from "./components/Deactivate";
import Schedule from "./components/Schedule";

export default (ContentManager, Editor) => {
  const { editorActive, editorSource } = ContentManager.state;
  const { createInput, save, reset } = Editor.functions;
  const { disabled } = editorSource;

  if (disabled) {
    return <Disabled ContentManager={ContentManager} />;
  }

  if (!editorActive) {
    return null;
  }

  return (
    <Fragment>
      <Segment.Group>
        <Segment raised color="blue">
          <Header as="h4" content="Account" />
        </Segment>
        <Segment>
          <Form>
            <Form.Group widths="equal">
              {createInput(<Form.Input />, "firstname", {
                fluid: true,
                label: "First name"
              })}
              {createInput(<Form.Input />, "lastname", {
                fluid: true,
                label: "Last name"
              })}
            </Form.Group>
            {createInput(<Form.Input />, "username", {
              fluid: true,
              label: "Username"
            })}
            {createInput(<Form.Input />, "email", {
              fluid: true,
              label: "Email Address"
            })}
            <Divider />
            <Form.Field>
              <label>Role</label>
              {createInput(
                <AjaxDropdown
                  url={"/apis/portal/accountroles"}
                  data={{ action: "read_for_dropdowns" }}
                  dataRetreiver={({ message: m }) =>
                    m.map(({ id, name }) => ({
                      value: id,
                      text: name
                    }))
                  }
                />,
                ["account_role", "id"],
                { placeholder: "Account Role" }
              )}
            </Form.Field>
            <Form.Field>
              <label>Company</label>
              {createInput(
                <AjaxDropdown
                  url={"/apis/portal/accountcompanies"}
                  data={{ action: "read_for_dropdown" }}
                  dataRetreiver={({ message: m }) =>
                    m.map(({ id, name }) => ({
                      value: id,
                      text: name
                    }))
                  }
                />,
                ["account_company", "id"],
                { placeholder: "Account Company" }
              )}
            </Form.Field>
          </Form>
        </Segment>
        <ActionsWrapper>
          <Dropdown
            text="Options"
            icon="caret down"
            floating
            labeled
            button
            basic
            className="icon"
          >
            <Dropdown.Menu>
              <LogMeIn userID={editorSource.id} />
              <ResetPassword ContentManager={ContentManager} />
              <Schedule ContentManager={ContentManager} />
              <Dropdown.Divider />
              <Deactivate ContentManager={ContentManager} />
            </Dropdown.Menu>
          </Dropdown>
          <ModifiedActions>
            {!!Object.keys(Editor.data).length && (
              <Fragment>
                <Button
                  type="button"
                  content="Discard"
                  icon="undo"
                  onClick={reset}
                  basic
                />
                <Button
                  type="button"
                  onClick={save}
                  icon="save"
                  content="Save"
                  color="blue"
                />
              </Fragment>
            )}
          </ModifiedActions>
        </ActionsWrapper>
      </Segment.Group>
      <Segment.Group>
        <Segment raised color="blue">
          <Header as="h4" content="Account Permissions" />
        </Segment>
        <Segment>
          <Form>
            <FormGroupWrapper widths="equal">
              <Form.Field>
                <Pages ContentManager={ContentManager} />
              </Form.Field>
              <Form.Field>
                <Privileges ContentManager={ContentManager} />
              </Form.Field>
            </FormGroupWrapper>
          </Form>
        </Segment>
      </Segment.Group>
    </Fragment>
  );
};

const ActionsWrapper = styled(Segment)`
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  &:first-child {
    justify-self: flex-start;
  }
`;

const ModifiedActions = styled.div`
  &&& {
    button:last-of-type {
      margin-left: 0.75em;
    }
  }
`;

const FormGroupWrapper = styled(Form.Group)`
  &&& {
    margin-bottom: 0;
  }
`;
