import React, { useState } from "react";
import PropTypes from "prop-types";

import { Modal, Button, Form } from "semantic-ui-react";

import { OwnerIssues } from "fixtures/issues";
import AjaxFn from "services/AjaxFn";

const OpenIssue = props => {
  const [state, setState] = useState({
    open: false,
    loading: false,
    data: {
      text: "",
      name: "",
      category: "MISCLOW"
    }
  });

  const handleOpen = () => setState({ ...state, open: true });
  const handleClose = () => {
    if (!state.loading) {
      setState({ ...state, open: false });
    }
  };

  const canSave = () => {
    return (
      state.data.text &&
      state.data.name &&
      state.data.category &&
      !state.loading
    );
  };

  const submit = () => {
    setState({ ...state, loading: true });
    AjaxFn({
      url: "/apis/portal/memberissues",
      data: {
        action: "create",
        data: {
          member_id: props.data.id,
          opened_reason: state.data.text,
          category: state.data.category,
          name: state.data.name
        }
      },
      success: () => {
        window.toastr.success();
        props.reload();
      },
      failure: error => {
        window.toastr.error(error);
        setState({ ...state, loading: false });
      }
    });
  };

  const getFormProps = src => {
    return {
      value: state[src],
      onChange: (undefined, { value }) => {
        setState({
          ...state,
          data: {
            ...state.data,
            [src]: value
          }
        });
      },
      disabled: state.loading
    };
  };

  const modalProps = {
    open: state.open,
    onClose: handleClose,
    size: "mini",
    trigger: <Button onClick={handleOpen} content="Open Issue" basic primary />
  };

  return (
    <Modal {...modalProps}>
      <Modal.Header>Open Issue</Modal.Header>
      <Modal.Content>
        <Form>
          <Form.Dropdown
            {...getFormProps("category")}
            label="Category"
            placeholder="Select a category"
            options={OwnerIssues}
            selection
          />
          <Form.Input
            {...getFormProps("name")}
            label="Issue Name"
            placeholder="Name"
            maxLength="64"
          />
          <Form.TextArea
            {...getFormProps("text")}
            label="Reason"
            placeholder="Description"
            maxLength="1024"
          />
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button
          disabled={state.loading}
          onClick={handleClose}
          icon="cancel"
          content="Back"
        />
        <Button
          primary
          loading={state.loading}
          disabled={!canSave()}
          onClick={submit}
          content="Submit"
        />
      </Modal.Actions>
    </Modal>
  );
};

OpenIssue.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  reload: PropTypes.func
};

export default OpenIssue;
