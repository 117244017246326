import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { fetch } from "redux/PortalTables/actions";
import { Button } from "semantic-ui-react";
import styled from "styled-components";

export class RefreshButton extends Component {
  static propTypes = {
    namespace: PropTypes.string.isRequired,
    fetch: PropTypes.func.isRequired
  };

  render() {
    const { tables, namespace, fetch } = this.props;
    const { loading } = tables[namespace];
    return (
      <StyledButton
        basic
        primary
        loading={loading}
        disabled={loading}
        onClick={() => fetch(namespace)}
        icon="refresh"
      />
    );
  }
}

const StyledButton = styled(Button)`
  margin-right: 0;
  height: 100%;
`;

const mapStateToProps = state => ({
  tables: state.PortalTables
  // ...
});

const mapDispatchToProps = {
  fetch
  // ...
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RefreshButton);
