import React from "react";

import { Container, Header, Grid } from "semantic-ui-react";

import Layout from "components/Layout";
import ContentManager from "components/ContentManager";
import List from "./components/List";
import Editor from "./components/Editor";
import Wizard from "./components/Wizard";
import Delete from "./components/Delete";
import Breadcrumb from "./components/Breadcrumb";
import { Ajax } from "services/Ajax";

export default class AccountCompanies extends React.Component {
  getConfig = ContentManager => ({
    List: List(ContentManager),
    Wizard: {
      JSX: Wizard,
      AJAX: {
        instance: new Ajax({ url: "/apis/portal/accountcompanies" }),
        onSuccess: ({ message }) => {
          window.toastr.success();
        },
        data: data => ({
          action: "create",
          data
        })
      }
    },
    Editor: {
      JSX: Editor,
      AJAX: {
        instance: new Ajax({ url: "/apis/portal/accountcompanies" }),
        onSuccess: ({ message }) => {
          window.toastr.success();
        },
        data: ({ id: ID }, data) => ({
          action: "update",
          data: { ID, ...data }
        })
      }
    },
    Delete: {
      JSX: Delete,
      AJAX: {
        instance: new Ajax({ url: "/apis/portal/accountcompanies" }),
        onSuccess: ({ message }) => {
          window.toastr.success();
        },
        data: ({ id: ID }) => ({
          action: "delete",
          data: { ID }
        })
      }
    }
  });
  /**
   * 	Render fn
   */
  render() {
    return (
      <Layout noBar>
        <Breadcrumb />
        <Container style={{ paddingBottom: "4rem" }}>
          <Header as={"h1"} dividing style={{ marginTop: "2rem" }}>
            Account Companies
            <div className={"sub header"}>
              Below are all of the companies registered in the database.
            </div>
          </Header>
          <ContentManager config={this.getConfig}>
            {({ components: { List, Editor, Wizard } }) => (
              <React.Fragment>
                <Grid>
                  <Grid.Column width={10}>{List}</Grid.Column>
                  <Grid.Column width={6}>{Editor}</Grid.Column>
                </Grid>
                {Wizard}
              </React.Fragment>
            )}
          </ContentManager>
          <br />
        </Container>
      </Layout>
    );
  }
}
