import InitialState from "./initial.js";
export default (state = InitialState, action) => {
  switch (action.type) {
    case "CONTEXT_BAR_SET_CONTENT":
      return {
        ...state,
        content: action.data
      };
    default:
      return state;
  }
};
