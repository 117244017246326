import React from "react";
import styled from "styled-components";

import { footerBackgroundColor, fontColor } from "theme";

const Content = ({ children }) => (
  <ContentWrapper id="content">
    {children}
    <Footer id="footer" />
  </ContentWrapper>
);

const ContentWrapper = styled.div`
  grid-area: content;
  padding: 1.5rem;
  overflow-y: scroll;

  display: flex;
  flex-wrap: wrap;
`;

export default Content;

const Footer = () => {
  const year = new Date().getFullYear();
  const link = (
    <a href="https://vmgresorts.com" target="_blank" rel="nofollow noreferrer">
      VMG Resorts, LLC.
    </a>
  );

  return (
    <StyledFooter id="footer">
      <p>
        Copyright &copy; {year} {link}
      </p>
    </StyledFooter>
  );
};

const StyledFooter = styled.footer`
  display: flex;
  align-items: center;
  align-self: flex-end;

  background-color: ${footerBackgroundColor};
  color: ${fontColor};
  font-weight: 600;

  width: calc(100% + 3rem);
  height: 50px;

  margin: 1.5rem -1.5rem -1.5rem;
  padding: 0 1.5rem;
`;
