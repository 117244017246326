import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { Message, Dimmer, Loader } from "semantic-ui-react";
import FocusBar from "components/FocusBar";

import { user_can } from "utils/user";

const AgreementFocusBar = props => {
  const {
    user,
    page,
    loading = false,
    error = false,
    children = null,
    expandable = true,
    expanded = undefined
  } = props;

  return (
    <FocusBar expandable={expandable} expanded={expanded}>
      <FocusBar.Actions>
        <FocusBar.Nav
          active={page === "agreements"}
          icon="fas fa-list"
          content="Agreement List"
          href="/browse/agreements"
        />
        <FocusBar.Nav
          active={page === "wizard"}
          icon="fas fa-magic"
          content="Agreement Wizard"
          href="/wizards/agreements"
        />
        <FocusBar.Nav
          active={page === "legacy"}
          icon="fas fa-scroll"
          content="Legacy Agreements"
          href="/browse/legacyagreements"
        />
      </FocusBar.Actions>
      <FocusBar.Content>
        {loading ? (
          <Dimmer active>
            <Loader />
          </Dimmer>
        ) : error ? (
          <FocusBar.Item
            color="red"
            content={
              <>
                <b>Something went wrong</b>
                <br />
                <p>{error}</p>
              </>
            }
          />
        ) : (
          children
        )}
      </FocusBar.Content>
    </FocusBar>
  );
};

AgreementFocusBar.propTypes = {
  user: PropTypes.object.isRequired,
  page: PropTypes.string.isRequired
};

const mapStateToProps = state => ({
  user: state.User
});

const mapDispatchToProps = {
  // ...
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AgreementFocusBar);
