import React, { Component } from "react";
import propTypes from "prop-types";
import { Button } from "semantic-ui-react";
import AjaxFn from "services/AjaxFn";

export default class CompleteAudit extends Component {
  static propTypes = {
    reload: propTypes.func,
    data: propTypes.object
  };

  state = {
    loading: false
  };

  handleClick = () => {
    this.setState({ loading: true });
    AjaxFn({
      url: "/apis/portal/bookingaudits",
      data: {
        action: "complete",
        data: {
          ID: this.props.data.audits.find(
            row => row.completed_on === null && row.invalidated_on === null
          ).id
        }
      },
      success: () => {
        window.toastr.success();
        this.props.reload();
      },
      failure: error => {
        window.toastr.error(error);
        this.setState({ loading: false });
      }
    });
  };

  render() {
    return (
      <div>
        <p>
          There is currently an audit in progress. Click the button below to
          complete it.
        </p>
        <Button
          primary
          loading={this.state.loading}
          disabled={this.state.loading}
          onClick={this.handleClick}
          content="Complete Audit"
        />
        <br />
        <br />
        <p>
          You will not be able to complete the audit unless all issues on the
          booking have been closed.
        </p>
      </div>
    );
  }
}
