import React from "react";
import { Message, Icon } from "semantic-ui-react";

export default ({ data }) => {
  const owner = data.point_usages[0].point_set.membership.member;
  return (
    <Message>
      <Message.Header>
        <Icon name={"envelope outline"} /> Owner Email
      </Message.Header>
      <br />
      {"This will send to "}
      <b>{owner.firstname + " " + owner.lastname}</b>
      {" at "}
      <b>{owner.email}</b>
    </Message>
  );
};
