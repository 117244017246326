const InitialState = {
  ignore: []
};

export default (state = InitialState, action) => {
  switch (action.type) {
    case "SOCKETS_IGNORE":
      return {
        ...state,
        ignore: state.ignore.concat(action.data)
      };
    case "SOCKETS_EXPIRE":
      return {
        ...state,
        ignore: state.ignore.filter(token => token !== action.data)
      };
    default:
      return state;
  }
};
