import React from "react";
import { Segment, Header } from "semantic-ui-react";
import { Wrapper } from "../../context";

const Title = ({ header = "", subheader = "", context }) => (
  <Segment vertical inverted color={context.agreement.color}>
    <Header inverted as={"h1"} style={{ textAlign: "center" }}>
      {header}
      {subheader && <Header.Subheader>{subheader}</Header.Subheader>}
    </Header>
  </Segment>
);
export default Wrapper(Title);
