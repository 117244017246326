import React, { PureComponent } from "react";
import { Container, Table } from "semantic-ui-react";
import moment from "moment";

import { addCommas } from "utils/numbers";
import { isObjectEmpty } from "utils/objects";
import { Wrapper } from "components/Agreement/context";
import styled from "styled-components";

class Schedule extends PureComponent {
  render() {
    const { context } = this.props;
    const { data } = context;

    if (isObjectEmpty(data)) return null;
    return (
      <ScheduleWrapper>
        <Container>
          <LoanInformation data={data} />
        </Container>
        <LoanSchedule payments={data.payments} />
      </ScheduleWrapper>
    );
  }
}

const ScheduleWrapper = styled(Container)`
  @media print {
    margin: 0.25in 0;
  }
`;

export default Wrapper(Schedule);

const LoanInformation = ({ data }) => {
  const loanAmount = `$${addCommas(data.financed_amount, 2)}`;
  const annualInterestRate = `${data.interest_rate}%`;
  const loanPeriodInYears = Number(data.agreement_term) / 12;
  const startDateOfLoan = moment(data.loan_start_date).format("MM/DD/YYYY");
  const monthlyPayment = `$${addCommas(data.monthly_payment, 2)}`;
  const numberOfPayments = data.agreement_term;
  const totalInterest = `$${addCommas(data.finance_charge, 2)}`;
  const totalCost = `$${addCommas(data.total_sale, 2)}`;

  return (
    <Table celled>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell content="Loan Amount" />
          <Table.HeaderCell content="Annual Interest Rate" />
          <Table.HeaderCell content="Loan Period in Years" />
          <Table.HeaderCell content="Start Date of Loan" />
          <Table.HeaderCell content="Monthly Payment" />
          <Table.HeaderCell content="Number of Payments" />
          <Table.HeaderCell content="Total Interest" />
        </Table.Row>
      </Table.Header>
      <Table.Body>
        <Table.Row>
          <Table.Cell content={loanAmount} />
          <Table.Cell content={annualInterestRate} />
          <Table.Cell content={loanPeriodInYears} />
          <Table.Cell content={startDateOfLoan} />
          <Table.Cell content={monthlyPayment} />
          <Table.Cell content={numberOfPayments} />
          <Table.Cell content={totalInterest} />
        </Table.Row>
      </Table.Body>
      <Table.Footer>
        <Table.Row>
          <Table.HeaderCell colSpan="7" textAlign="right">
            <strong>Total Cost of Loan:</strong> {totalCost}
          </Table.HeaderCell>
        </Table.Row>
      </Table.Footer>
    </Table>
  );
};

const LoanSchedule = ({ payments }) => {
  return (
    <Table definition selectable celled>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell width="1" textAlign="center" content="#" />
          <Table.HeaderCell width="3" textAlign="left" content="Payment Date" />
          <Table.HeaderCell width="2" textAlign="right" content="Balance" />
          <Table.HeaderCell
            width="2"
            textAlign="right"
            content="Monthly Payment"
          />
          <Table.HeaderCell width="2" textAlign="right" content="Principle" />
          <Table.HeaderCell width="2" textAlign="right" content="Interest" />
          <Table.HeaderCell
            width="2"
            textAlign="right"
            content="Ending Balance"
          />
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {payments.map((payment, index) => {
          const number = payment.number;
          const paymentDate = moment(payment.payment_date).format("MM/DD/YYYY");
          const startingBalance = addCommas(payment.starting_balance, 2);
          const monthlyPayment = addCommas(payment.monthly_payment, 2);
          const principle = addCommas(payment.principle, 2);
          const interest = addCommas(payment.interest, 2);
          const endingBalance = addCommas(
            payment.ending_balance > 0 || payment.ending_balance < -0.1
              ? payment.ending_balance
              : 0,
            2
          );
          return (
            <Table.Row key={index}>
              <Table.Cell textAlign="center" content={number} />
              <Table.Cell textAlign="left" content={paymentDate} />
              <Table.Cell textAlign="right" content={`$${startingBalance}`} />
              <Table.Cell textAlign="right" content={`$${monthlyPayment}`} />
              <Table.Cell textAlign="right" content={`$${principle}`} />
              <Table.Cell textAlign="right" content={`$${interest}`} />
              <Table.Cell textAlign="right" content={`$${endingBalance}`} />
            </Table.Row>
          );
        })}
      </Table.Body>
    </Table>
  );
};
