import React from "react";
import { Divider } from "semantic-ui-react";
import { addCommas } from "utils/numbers";
/**
 *	@prop classes
 *	@prop check
 */
export default class CoverPage extends React.Component {
  render() {
    const { classes, check, totalPtsUsed } = this.props;
    const { membership } = check.bookings[0].point_usages[0].point_set;
    const { member } = membership;

    const company = () => {
      const membership =
        check.bookings[0].point_usages[0].point_set.membership.issuer_company;

      // images
      const vmgresorts =
        "https://vmg-uploads.s3.us-east-2.amazonaws.com/VMG+Resorts.png";
      const eliteMemberPerks =
        "https://vmg-uploads.s3.us-east-2.amazonaws.com/elite_member_perks.png";
      const eliteRewards =
        "https://vmg-uploads.s3.us-east-2.amazonaws.com/elite_rewards.png";
      const premierRewards =
        "https://vmg-uploads.s3.us-east-2.amazonaws.com/premier_rewards.png";
      const epc = "https://vmg-uploads.s3.us-east-2.amazonaws.com/epcpng.png";
      const timeshareSellingTeam =
        "https://vmg-uploads.s3.us-east-2.amazonaws.com/timeshare_selling_team.png";

      let data = {
        image: vmgresorts,
        address1: "15933 Clayton Rd Suite 135",
        address2: "Ballwin, MO 63011"
      };

      switch (membership) {
        case "Premier":
          data.image = premierRewards;
          return data;
        case "MPerks":
          data.image = eliteMemberPerks;
          return data;
        case "ERewards":
          data.image = eliteRewards;
          data.address1 = "7208 Sand Lake Rd, Suite 303";
          data.address2 = "Orlando, FL 32819";
          return data;
        case "EPC":
          data.image = epc;
          data.address1 = "6965 piazza grande ave 218";
          data.address2 = "Orlando, FL 32835";
          return data;
        case "TSTeam":
          data.image = timeshareSellingTeam;
          return data;
        case "Unknown":
        default:
          return data;
      }
    };

    return (
      <div className={classes.container}>
        <div style={{ textAlign: "center" }}>
          <img
            alt="VMG Resorts"
            className={classes.logo}
            src={company().image}
          />
          <Divider />
        </div>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <b>Booking Invoice</b>
          <b>Check #{check.number.toString().padStart(7, "0")}</b>
        </div>
        <div className={classes.ownerInfo}>
          <br />
          <p>
            <span>
              {
                "Below is a brief summary of the bookings we have made under your "
              }
            </span>
            <b>
              {membership.resort_company.name} {membership.level}
            </b>
            <span>
              {
                " membership. The following pages detail all of the bookings covered by this invoice."
              }
            </span>
          </p>
          Number of Bookings: <b>{check.bookings.length}</b>
          <br />
          Total Points Used: <b>{addCommas(totalPtsUsed)}</b>
          <br />
          Total Payout: <b>${addCommas(check.amount, 2)}</b>
          <br />
          <br />* Your payout for each booking depends on the terms of your
          agreement.
        </div>
        <br />
        <div className={classes.coverFooter}>
          <div className={classes.coverFooterFrom}>
            <img
              src={company().image}
              className={classes.coverFooterFromImage}
            />
            {company().address1}
            <br />
            {company().address2}
          </div>
          <div className={classes.coverFooterTo}>
            {member.firstname} {member.lastname}
            <br />
            {member.address}
            <br />
            {member.city}, {member.state} {member.zipcode}
          </div>
        </div>
      </div>
    );
  }
}
