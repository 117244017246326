import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { setSearch } from "redux/EOQReport/actions";
import { Input } from "semantic-ui-react";

export class SearchBox extends Component {
  static propTypes = {
    setSearch: PropTypes.func.isRequired,
    search: PropTypes.string.isRequired
  };

  // time in milliseconds to delay the actual search (for laggy reasons)
  TIMEOUT = 300;

  state = {
    search: "",
    typing: false,
    timeout: 0
  };

  handleChange = (undefined, { value }) => {
    if (this.state.timeout) {
      clearTimeout(this.state.timeout);
    }
    this.setState({
      search: value,
      typing: false,
      timeout: setTimeout(() => {
        this.props.setSearch(this.state.search);
      }, this.TIMEOUT)
    });
  };

  render() {
    return (
      <Input
        fluid
        onChange={this.handleChange}
        value={this.state.search}
        placeholder="Search..."
        icon="search"
      />
    );
  }
}

const mapStateToProps = state => ({
  search: state.EOQReport.search
});

const mapDispatchToProps = {
  setSearch
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SearchBox);
