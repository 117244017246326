import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { Header, Table, Button } from "semantic-ui-react";
import { formatMJY } from "utils/dates";
import { Link } from "react-router-dom";
export default class Bookings extends Component {
	static propTypes = {
		listing: PropTypes.object.isRequired
	};

	state = {
		collapsed: true
	};

	collapse = () => this.setState({ collapsed: true });
	expand = () => this.setState({ collapsed: false });

	render() {
		const { collapsed } = this.state;
		const bookings = this.props.listing.bookings
			.sort((a, b) => new Date(b.check_in) - new Date(a.check_in))
			.slice(0, collapsed ? 5 : this.props.listing.bookings.length);
		return (
			<div>
				<Header dividing>
					<Header.Content>Bookings</Header.Content>
				</Header>
				<p>
					All bookings assigned to this listing will appear in the table below.
				</p>
				{this.props.listing.bookings.length > 0 && (
					<p>
						There are <b>{this.props.listing.bookings.length}</b> booking(s) for
						this listing.
					</p>
				)}
				<Table compact={"very"}>
					<Table.Header>
						<Table.Row>
							<Table.HeaderCell>Booking/Check In</Table.HeaderCell>
							<Table.HeaderCell>Guest</Table.HeaderCell>
						</Table.Row>
					</Table.Header>
					<Table.Body>
						{bookings.length > 0 ? (
							bookings.map((booking, index) => (
								<Table.Row key={index}>
									<Table.Cell>
										<Link to={"/browse/bookings/" + booking.id}>
											{formatMJY(booking.check_in)}
										</Link>
									</Table.Cell>
									<Table.Cell>
										<Link to={"/browse/guests/" + booking.guest.id}>
											{booking.guest.firstname + " " + booking.guest.lastname}
										</Link>
									</Table.Cell>
								</Table.Row>
							))
						) : (
							<Table.Row>
								<Table.Cell colSpan="2">
									<br />
									<p>There are no bookings to show.</p>
									<br />
								</Table.Cell>
							</Table.Row>
						)}
					</Table.Body>
				</Table>
				{this.props.listing.bookings.length > 5 && (
					<Fragment>
						{this.state.collapsed ? (
							<Button
								onClick={this.expand}
								content={`Show ${this.props.listing.bookings.length - 5} more`}
							/>
						) : (
							<Button onClick={this.collapse} content="Show less" />
						)}
					</Fragment>
				)}
			</div>
		);
	}
}
