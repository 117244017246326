import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import theme from "styled-theming";

import { ThemeInput } from "theme/components";
import AjaxFn from "services/AjaxFn";

const CreatePost = ({ goBack }) => {
  return (
    <Wrapper>
      <ThemeInput placeholder="Placeholder Text" label="Label" />
    </Wrapper>
  );
};

export default CreatePost;

const Wrapper = styled.div`
  padding: 1.5rem;
`;
