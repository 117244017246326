import React from "react";
import styled from "styled-components";

import Navigation from "./components/Navigation";
import UserActions from "./components/UserActions";

import { backgroundColor } from "theme";

const Navbar = () => {
  return (
    <NavbarWrapper id="navbar">
      <Navigation />
      <UserActions />
    </NavbarWrapper>
  );
};

const NavbarWrapper = styled.div`
  grid-area: navbar;
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: repeat(2, auto);
  height: 100%;

  background-color: ${backgroundColor};
`;

export default Navbar;
