import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Header, Button } from "semantic-ui-react";
import { reloadBooking } from "redux/BookingPage/actions";

export class NoOwner extends Component {
	static propTypes = {
		assignOwner: PropTypes.func.isRequired,
		reloadBooking: PropTypes.func.isRequired,
		booking: PropTypes.object.isRequired
	};

	render() {
		const { assignOwner, booking } = this.props;
		const canEdit = booking._actions.actions.edit_points;
		return (
			<div>
				<Header as="h2">
					<Header.Content>
						Owner & Points
						<Header.Subheader>Assign an Owner</Header.Subheader>
					</Header.Content>
				</Header>
				<br />
				{canEdit ? (
					<Button onClick={assignOwner} primary content="Assign Owner" basic />
				) : (
					<p>You can not assign an owner right now.</p>
				)}
			</div>
		);
	}
}

const mapStateToProps = state => ({
	booking: state.BookingPage.booking.data
});

const mapDispatchToProps = {
	reloadBooking
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(NoOwner);
