import React from "react";
import { Modal, Button, Icon, Header } from "semantic-ui-react";
import { Redirect } from "react-router-dom";
/**
 * 	@prop to (href)
 *  @prop as (react element)
 */
export default class RedirectAfterConfirmation extends React.Component {
  state = {
    modal: false,
    redirecting: false
  };
  onClick = (e) => {
    e.preventDefault();
    this.setState({ modal: true });
  };
  onClose = () => this.setState({ modal: false });
  confirm = () => this.setState({ redirecting: true });
  getLink = () => {
    const { to, as, children, ...rest } = this.props;
    const props = { onClick: this.onClick, ...rest };
    const el = as || <a href={to} />;
    return React.cloneElement(el, props, children);
  };
  getModal = () => {
    const { modal } = this.state;
    return (
      <Modal onClose={this.onClose} open={modal} size={"mini"} basic>
        <Header as={"h1"} icon={"external"} content={"Redirecting"} />
        <Modal.Content style={{ textAlign: "center" }}>
          You're about to be redirected to another page.
          <br />
          If you have any unsaved changes, they may be lost.
        </Modal.Content>
        <Modal.Actions>
          <Button basic color={"red"} inverted onClick={this.onClose}>
            <Icon name={"remove"} /> Stay here
          </Button>
          <Button color={"green"} inverted onClick={this.confirm}>
            <Icon name={"checkmark"} /> Take me there
          </Button>
        </Modal.Actions>
      </Modal>
    );
  };
  render() {
    const { redirecting } = this.state;
    const { to } = this.props;
    if (redirecting) {
      // if no destination was specified,
      // just close the modal and send a warning to the console
      if (!to) {
        console.warn('No "to" prop was assigned');
        return null;
      }
      return <Redirect push to={to} />;
    }
    return (
      <React.Fragment>
        {this.getLink()}
        {this.getModal()}
      </React.Fragment>
    );
  }
}
