import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { Header, Icon, Step } from "semantic-ui-react";
import { Link } from "react-router-dom";
import { getAvailabilityUpdated } from "utils/units";
export default class Sidebar extends Component {
  static propTypes = {
    unit: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired
  };

  render() {
    const { unit, match } = this.props;
    const { unitTab, unitID, listingTab, listingID } = match.params;
    const availabilityStatus = getAvailabilityUpdated(unit);
    const pages = [
      {
        key: "summary",
        icon: "folder outline",
        description: "General unit info",
        title: "Summary",
        active: !unitTab && !listingID,
        as: Link,
        to: `/browse/units/${unitID}`
      },
      {
        key: "bookings",
        icon: "address book outline",
        description: "Bookings for this unit",
        title: "Bookings",
        active: unitTab === "bookings",
        as: Link,
        to: `/browse/units/${unitID}/bookings`
      },
      {
        key: "availability",
        icon: (() => {
          switch (availabilityStatus) {
            case "updated":
              return <Icon name={"check square outline"} color={"green"} />;
            case "outdated":
              return <Icon name={"square outline"} color={"red"} />;
            case "frozen":
              return <Icon name={"snowflake"} color={"blue"} />;
            case "unlisted":
              return <Icon name={"square outline"} color={"yellow"} />;
            default:
              throw new Error("Invalid availability status");
          }
        })(),
        description: (() => {
          switch (availabilityStatus) {
            case "updated":
              return "Up to date";
            case "outdated":
              return "Out of date";
            case "frozen":
              return "Frozen";
            case "unlisted":
              return "No listings";
            default:
              throw new Error("Invalid availability status");
          }
        })(),
        title: "Availability",
        active: unitTab === "availability",
        as: Link,
        to: `/browse/units/${unitID}/availability`
      },
      {
        key: "comments",
        icon: "comments outline",
        description: "Read or write comments",
        title: "Comments",
        active: unitTab === "comments",
        as: Link,
        to: `/browse/units/${unitID}/comments`
      },
      {
        key: "actions",
        icon: "content",
        description: "Miscellaneous actions",
        title: "Actions",
        active: unitTab === "actions",
        as: Link,
        to: `/browse/units/${unitID}/actions`
      }
    ];

    return (
      <Fragment>
        <Header dividing>Unit</Header>
        <Step.Group fluid vertical items={pages} />
      </Fragment>
    );
  }
}
