export default [
  {
    value: "vmg",
    text: "VMGResorts",
    source: "merchant"
  },
  {
    value: "tripadvisor",
    text: "TripAdvisor",
    source: "booking source"
  },
  {
    value: "airbnb",
    text: "AirBNB",
    source: "booking source"
  },
  {
    value: "vrbo",
    text: "VRBo",
    source: "merchant"
  },
  {
    value: "homeaway",
    text: "HomeAway",
    source: "merchant"
  },
  {
    value: "redawning",
    text: "RedAwning",
    source: "booking source"
  },
  {
    value: "phone",
    text: "Call-In (phone)",
    source: "merchant"
  },
  {
    value: "other",
    text: "other",
    source: "merchant"
  },
  {
    value: "rentalsunited",
    text: "RentalsUnited",
    source: "booking source",
    disabled: true
  },
  {
    value: "canadastays",
    text: "CanadaStays",
    source: "booking source",
    disabled: true
  },
  {
    value: "triping",
    text: "Triping.com",
    source: "booking source",
    disabled: true
  },
  {
    value: "booking",
    text: "Booking.com",
    source: "booking source",
    disabled: true
  },
  {
    value: "expedia",
    text: "Expedia.com",
    source: "booking source",
    disabled: true
  }
];
