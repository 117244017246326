import React from "react";
import { Message, Icon } from "semantic-ui-react";

export default ({ data }) => (
  <Message>
    <Message.Header>
      <Icon name={"envelope outline"} /> Guest Email
    </Message.Header>
    <br />
    This will send to <b>the guest</b> at <b>{data.guest.email}</b>.
  </Message>
);
