import React, { Component } from "react";
import { Message } from "semantic-ui-react";
import { connect } from "react-redux";

export class SyncMessage extends Component {
  render() {
    if (this.props.synced) {
      return null;
    }
    return (
      <Message>
        <Message.Header>Unit not re-synced</Message.Header>
        <p>
          To ensure consistency, the unit must be re-synced with CiiRUS before
          it can have its availability updated.
        </p>
      </Message>
    );
  }
}

export default connect(state => ({
  unit: state.Calendar.unit,
  synced: state.Calendar.synced
}))(SyncMessage);
