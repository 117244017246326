import React from "react";
import { Segment, Header, Grid, Icon } from "semantic-ui-react";
import { Wrapper } from "../../context";

const Legal = ({ context }) => {
  return (
    <Segment vertical>
      <Grid stackable>
        <Grid.Column width={5}>
          <Header as={"h3"}>
            <Icon name={"handshake"} />
            Legal Terms
          </Header>
          <p>Please review this section carefully.</p>
        </Grid.Column>
        <Grid.Column width={11}>
          <div dangerouslySetInnerHTML={{ __html: context.agreement.legal }} />
        </Grid.Column>
      </Grid>
    </Segment>
  );
};
export default Wrapper(Legal);
