import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { connect } from "react-redux";
import styled from "styled-components";

import { reduce } from "utils/timepunch";

const thisMorning = moment().startOf("day");

const Counter = ({
  clocked_in,
  timepunching,
  timepunches,
  loading,
  loaded
}) => {
  const [text, setCalculated] = useState("...");
  // filter out timepunches for only those applicable to today,
  // then calculate the hh:mm:ss to show and set it in redux
  const tryCalculate = () => {
    if (loaded && !loading) {
      const todaysPunches = timepunches.filter(punch => {
        return !punch.out || moment(punch.in) >= thisMorning;
      });

      setCalculated(reduce(todaysPunches));
    }
  };

  // sets up an interval, if the user is clocked in, to recalculate every second
  useEffect(() => {
    if (clocked_in) {
      const interval = setInterval(tryCalculate, 1000);
      return () => clearInterval(interval);
    } else {
      tryCalculate();
    }
  });

  return <StyledSpan>{timepunching ? "..." : text}</StyledSpan>;
};

const StyledSpan = styled.span`
  font-weight: 600;
  line-height: 1;
  cursor: default;

  @media screen and (max-width: 884px) {
    display: none;
  }
`;

const mapStateToProps = state => ({
  timepunching: state.User.timepunching,
  clocked_in: state.User.clocked_in,
  timepunches: state.User.timepunches_for_week.data,
  loaded: state.User.timepunches_for_week.loaded,
  loading: state.User.timepunches_for_week.loading
});

const mapDispatchToProps = {};

Counter.propTypes = {
  timepunching: PropTypes.bool.isRequired,
  clocked_in: PropTypes.bool.isRequired,
  timepunches: PropTypes.array.isRequired,

  loaded: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Counter);
