import React from "react";
// import { Divider } from "semantic-ui-react";
import { connect } from "react-redux";
import Loader from "components/WithState/components/Loader";
import Header from "./components/Header";
import Menu from "./components/Menu";
import Months from "./components/Months";
import SyncMessage from "./components/SyncMessage";
// import Changelog from "./components/Changelog";
class CalendarPage extends React.Component {
  render() {
    if (this.props.loading) {
      return <Loader />;
    }
    return (
      <div>
        <Header />
        <Menu />
        <SyncMessage />
        <br />
        <Months />
        {/* <Divider /> */}
        {/* <Changelog /> */}
      </div>
    );
  }
}
export default connect(state => ({
  unit: state.Calendar.unit,
  loading: state.Calendar.loading
}))(CalendarPage);
