import { Menu } from "semantic-ui-react";
export default {
  content: null,
  props: {
    animation: "push",
    direction: "top"
  },
  as: Menu,
  open: false,
  locked: false
};
