import React from "react";
import { Container, Header } from "semantic-ui-react";
import Layout from "components/Layout";
import PortalTable from "components/PortalTable";
import ListFn from "./ListFn";
import Breadcrumb from "./components/Breadcrumb";
import Filters from "./components/Filters";
export default class AgreementTemplateList extends React.Component {
  render() {
    return (
      <Layout noBar>
        <Breadcrumb />
        <br />
        <Container>
          <Header as={"h1"} dividing>
            <Header.Content>
              Agreement Templates
              <Header.Subheader>
                Below are all of the agreement templates registered in the
                database.
              </Header.Subheader>
            </Header.Content>
          </Header>
          <Filters namespace={"AgreementTemplatesList"} />
          <PortalTable {...ListFn()} />
          <br />
        </Container>
      </Layout>
    );
  }
}
