import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { setAccount } from "redux/TimepunchManager/actions";
import { Table, Icon } from "semantic-ui-react";
import naturalSort from "utils/naturalSort";
import moment from "moment";

import { ClickableRow } from "../StyledComponents";

export class TimepunchTable extends Component {
  static propTypes = {
    data: PropTypes.array.isRequired,
    setAccount: PropTypes.func.isRequired
  };

  renderHoursCell = data => {
    const cellProps = {
      warning: data.modified ? true : undefined,
      style: {
        fontFamily: "consolas"
      }
    };
    let duration = moment.duration(data.total);
    let hours = Math.floor(duration.asHours());
    return (
      <Table.Cell {...cellProps}>
        {data.modified && (
          <Icon name={"exclamation triangle"} style={{ marginRight: "1rem" }} />
        )}
        {`${hours}h ${duration.minutes()}m ${duration.seconds()}s`}
      </Table.Cell>
    );
  };

  renderStatusCell = data => {
    const cellProps = {};
    if (data.account.clocked_in) {
      cellProps.positive = true;
    }
    return (
      <Table.Cell {...cellProps}>
        {data.account.clocked_in ? (
          <Fragment>
            <Icon name={"checkmark"} color={"green"} />
            Yes
          </Fragment>
        ) : (
          <Fragment>
            <Icon name={"cancel"} color={"red"} />
            No
          </Fragment>
        )}
      </Table.Cell>
    );
  };

  getFilteredData = () => {
    const { data, filters } = this.props;
    let modifiedStatus = filters.modified === "false" ? false : true;
    let disabledStatus = filters.disabled === "false" ? false : true;
    return data.filter(row => {
      if (filters.companies.length) {
        if (filters.companies.indexOf(row.account.account_company.id) === -1) {
          return false;
        }
      }
      if (filters.modified !== "any") {
        if (row.modified !== modifiedStatus) {
          return false;
        }
      }
      if (filters.disabled !== "any") {
        if (row.account.disabled !== disabledStatus) {
          return false;
        }
      }
      return true;
    });
  };

  render() {
    const { setAccount } = this.props;

    const filtered = this.getFilteredData();

    return (
      <Table>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Name</Table.HeaderCell>
            <Table.HeaderCell>Hours this week</Table.HeaderCell>
            <Table.HeaderCell>Clocked in</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {filtered.length ? (
            filtered
              .sort((a, b) => {
                let aname = a.account.firstname + a.account.lastname;
                let bname = b.account.firstname + b.account.lastname;
                return naturalSort(aname, bname);
              })
              .map((row, key) => {
                return (
                  <ClickableRow
                    key={key}
                    onClick={() => setAccount(row.account.id)}
                  >
                    <Table.Cell>
                      {row.account.firstname} {row.account.lastname}
                    </Table.Cell>
                    {this.renderHoursCell(row)}
                    {this.renderStatusCell(row)}
                  </ClickableRow>
                );
              })
          ) : (
            <Table.Row>
              <Table.Cell colSpan={3}>
                <br />
                <p>There is nothing to show.</p>
                <br />
              </Table.Cell>
            </Table.Row>
          )}
        </Table.Body>
      </Table>
    );
  }
}

const mapStateToProps = state => ({
  data: state.TimepunchManager.processed,
  filters: state.TimepunchManager.filters
});

const mapDispatchToProps = {
  setAccount
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TimepunchTable);
