import React from "react";
import { Form, Dropdown, Header, Grid } from "semantic-ui-react";
import AjaxDropdown from "components/AjaxDropdown";
import { countries } from "fixtures/countries";
/**
 * @prop resortOnly {Bool}
 * @prop setData {Function}
 * @prop data {Object}
 */
export default class ResortForm extends React.PureComponent {
  render() {
    const { setData, data } = this.props;
    return (
      <React.Fragment>
        <Header dividing as={"h3"}>
          Resort Info
        </Header>
        <Grid columns={2}>
          <Grid.Column>
            <p>
              Fill in some basic information so the resort can easily be found
              after it is created. You will be able to edit more information
              once it is created, such as fees, features, etc.
            </p>
          </Grid.Column>
          <Grid.Column>
            <Form>
              <Form.Field required>
                <label>Company</label>
                <AjaxDropdown
                  url={"/apis/portal/resortcompanies"}
                  data={{ action: "read_for_dropdown" }}
                  placeholder={"Select a company"}
                  dataRetreiver={({ message: m }) =>
                    m.map(({ id, name }) => ({
                      value: id,
                      text: name
                    }))
                  }
                  value={data.company}
                  onChange={(e, { value }) =>
                    setData("resort_company_id", value)
                  }
                />
              </Form.Field>
              <Form.Group widths={"equal"}>
                <Form.Field required>
                  <label>Name</label>
                  <input
                    value={data.name}
                    placeholder="Bonnet Creek"
                    onChange={e => setData("name", e.target.value)}
                  />
                </Form.Field>
                <Form.Field required>
                  <label>Phone Number</label>
                  <input
                    placeholder="(123) 456-7890"
                    value={data.phone}
                    onChange={e => setData("phone", e.target.value)}
                  />
                </Form.Field>
              </Form.Group>
              <Form.Group widths={"equal"}>
                <Form.Field required>
                  <label>Address</label>
                  <input
                    placeholder="Address"
                    value={data.address}
                    onChange={e => setData("address", e.target.value)}
                  />
                </Form.Field>
                <Form.Field required>
                  <label>City</label>
                  <input
                    placeholder="City"
                    value={data.city}
                    onChange={e => setData("city", e.target.value)}
                  />
                </Form.Field>
              </Form.Group>
              <Form.Group widths={"equal"}>
                <Form.Field required>
                  <label>Country</label>
                  <Dropdown
                    selection
                    search
                    placeholder={"Country"}
                    value={data.country}
                    onChange={(e, { value }) => setData("country", value)}
                    options={countries.map(el => ({
                      key: el[0],
                      value: el[0],
                      flag: el[1],
                      text: el[2]
                    }))}
                  />
                </Form.Field>
                <Form.Field>
                  <label>State</label>
                  <input
                    placeholder="State"
                    value={data.state}
                    onChange={e => setData("state", e.target.value)}
                  />
                </Form.Field>
                <Form.Field>
                  <label>County</label>
                  <input
                    placeholder="County"
                    value={data.county}
                    onChange={e => setData("county", e.target.value)}
                  />
                </Form.Field>
              </Form.Group>
            </Form>
          </Grid.Column>
        </Grid>
      </React.Fragment>
    );
  }
}
