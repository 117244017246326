import moment from "moment";

export default {
  // quarter selection
  start: moment().format("YYYY-MM-DD"),
  end: moment()
    .add(3, "month")
    .format("YYYY-MM-DD"),
  auditedOnly: true,
  loadedAuditedOnly: false,
  // table data
  bookings: {
    loading: false,
    data: {
      rows_original: [],
      rows_searched: [],
      rows_filtered: [],
      rows_ordered: [],
      rows_paginated: [],
      bookings: []
    },
    error: false
  },
  // table display
  page: 1,
  per_page: 10,
  filters: [],
  search: "",
  // bank/check information
  banks: {
    loading: false,
    data: [],
    error: false
  },
  bank: "",
  startingCheckNumber: "",
  queue: [],
  // saving state
  saving: false,
  created: []
  // ...
};
