import React, { Component, Fragment } from "react";
import { Form } from "semantic-ui-react";
import { getDaysInMonth } from "redux/Calendar/selector";
import Month from "./components/Month";
import Year from "./components/Year";
import Days from "./components/Days";
import propTypes from "prop-types";
import { InstanceTypes } from "../../../../types";
import moment from "moment";
/**
 * @prop config
 * @prop value
 * @prop displayMonth
 * @prop displayYear
 * @prop setYear
 * @prop setMonth
 */
export default class DateForm extends Component {
  static propTypes = {
    config: InstanceTypes.config.isRequired,
    value: propTypes.instanceOf(moment).isRequired,
    displayMonth: propTypes.string,
    displayYear: propTypes.string,
    setYear: propTypes.func.isRequired,
    setMonth: propTypes.func.isRequired
  };
  render() {
    const {
      value,
      displayMonth,
      displayYear,
      setYear,
      setMonth,
      setDate,
      config
    } = this.props;
    const day = value.format("YYYY-MM-DD");
    const month = displayMonth || value.format("MM");
    const year = displayYear || value.format("YYYY");
    const days = getDaysInMonth(month, year);
    return (
      <Fragment>
        <Form>
          <Form.Group widths="equal">
            <Form.Field>
              <Year year={year} setYear={setYear} />
            </Form.Field>
            <Form.Field>
              <Month
                month={month}
                setMonth={setMonth}
                year={year}
                setYear={setYear}
              />
            </Form.Field>
          </Form.Group>
        </Form>
        <Days
          value={value}
          config={config}
          day={day}
          days={days}
          setDate={setDate}
          month={month}
          setMonth={setMonth}
          year={year}
          setYear={setYear}
        />
      </Fragment>
    );
  }
}
