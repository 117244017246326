import React from "react";
import { Button, Icon, Modal, Form, Input, Message } from "semantic-ui-react";

export default class EditorClass extends React.PureComponent {
  render() {
    const { Editor, ContentManager } = this.props;
    return (
      <Modal open={ContentManager.state.editorActive} size={"small"}>
        <Modal.Header onClick={ContentManager.functions.refresh}>
          Update Resort Company
        </Modal.Header>
        <Modal.Content>
          <Form>
            <Form.Field>
              <label>Name</label>
              {Editor.functions.createInput(<Input />, "name")}
            </Form.Field>
          </Form>
          <Message warning>
            <Message.Header>Important</Message.Header>
            <p>
              Changing anything here will cascade to all linked resorts (and
              subsequently units). This should almost never have to be done.
            </p>
          </Message>
        </Modal.Content>
        <Modal.Actions>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div>{ContentManager.functions.getDeleteButton()}</div>
            <div>
              {Object.keys(Editor.data).length ? (
                <React.Fragment>
                  <Button
                    type={"button"}
                    onClick={Editor.functions.save}
                    loading={ContentManager.state.editorLoading}
                    icon={"save"}
                    content={"Save"}
                    color={"blue"}
                  />
                  <Button
                    type={"button"}
                    content={"Discard"}
                    onClick={Editor.functions.reset}
                  />
                </React.Fragment>
              ) : null}
              <Button onClick={ContentManager.functions.closeEditor}>
                <Icon name={"close"} />
                Close
              </Button>
            </div>
          </div>
        </Modal.Actions>
      </Modal>
    );
  }
}
