import {
  getMonths,
  isRenderedAsBlackoutDate,
  getFinalBlackout
} from "./selector";
import AjaxFn from "services/AjaxFn";

// ------------------------------------------
// Unit loading related actions
// ------------------------------------------

/**
 * Set processing state
 */
export const setProcessing = () => ({ type: "CALENDAR_SET_PROCESSING" });
/**
 * End processing state
 */
export const doneProcessing = () => ({ type: "CALENDAR_DONE_PROCESSING" });
/**
 * Set unit loading state
 */
export const setUnitLoading = () => ({ type: "CALENDAR_SET_UNIT_LOADING" });
/**
 * Set unit error state
 */
export const setUnitError = message => {
  return {
    type: "CALENDAR_SET_UNIT_ERR",
    data: message
  };
};
/**
 * Register the resync button having been clicked
 */
export const setUnitSynced = () => ({ type: "CALENDAR_SET_UNIT_SYNCED" });
/**
 * Register the resync button having been clicked
 */
export const resetSynced = () => ({ type: "CALENDAR_RESET_UNIT_SYNCED" });
/**
 * Request data for a unit
 * @param {String} unitID
 * @param {Bool} resync
 */
export const getUnit = (unitID, type) => {
  return (dispatch, getState) => {
    dispatch(setUnitLoading());
    AjaxFn({
      url: "/apis/portal/unitavailability",
      data: {
        action: "download",
        data: { ID: unitID, type }
      },
      success: data => {
        const state = getState();
        dispatch(setUnit(data));
        dispatch(generateMonths(state.Calendar.year));
        if (type === "resync") {
          dispatch(setUnitSynced());
        }
      },
      failure: error => dispatch(setUnitError(error))
    });
  };
};
/**
 * Determine what type of interperetation to use for unit availability based on unit type
 * @param {Object} unit
 * @return {Array}
 */
export const processAvailability = unit => {
  switch (unit.unit_type) {
    case "ciirus":
      return processAvailabilityForCiirusUnit(unit);
    default:
      throw new Error("unsupported unit type: " + unit.unit_type);
  }
};
/**
 * Interperet unit availability for ciirus units
 * @param {Object} unit
 * @return {Array}
 */
export const processAvailabilityForCiirusUnit = unit => {
  return unit.ciirus_units
    .map(ciirus_unit => {
      return ciirus_unit.freeze_availability
        ? []
        : ciirus_unit.reservations.map(reservation => ({
            start: new Date(reservation.start_date),
            end: new Date(reservation.end_date),
            type: reservation.blackout ? "blackout" : "booking"
          }));
    })
    .reduce((a, c) => a.concat(c));
};
/**
 * Set loaded data for a unit
 * @param {Object} unit
 */
export const setUnit = unit => dispatch => {
  const availability = processAvailability(unit);
  dispatch({
    type: "CALENDAR_SET_UNIT",
    data: {
      ...unit,
      availability
    }
  });
};

// ------------------------------------------
// Calendar date related actions
// ------------------------------------------

/**
 * Generate the months/days to display for a given year
 * @param {Integer} year
 */
export const generateMonths = year => {
  return {
    type: "CALENDAR_GEN_MONTHS",
    data: getMonths(year)
  };
};
/**
 * Set the given year displayed to a specific value
 * @param {Integer} year
 */
export const setYear = year => dispatch => {
  dispatch({
    type: "CALENDAR_SET_YEAR",
    data: year
  });
  dispatch(generateMonths(year));
};
/**
 * When user being selecting dates
 */
export const handleMouseDown = rangeStart => (dispatch, getState) => {
  const state = getState();
  const { unit, changes } = state.Calendar;
  const rangeStartBlackedOut = isRenderedAsBlackoutDate(
    rangeStart,
    unit,
    changes
  );
  dispatch({
    type: "CALENDAR_SET_RANGE_START",
    data: { rangeStart, rangeStartBlackedOut }
  });
};
/**
 * When user is selecting and enters a new cell
 */
export const handleMouseEnter = rangeEnd => {
  return {
    type: "CALENDAR_SET_RANGE_END",
    data: { rangeEnd }
  };
};
/**
 * When user ends selection
 */
export const handleMouseUp = () => (dispatch, getState) => {
  const state = getState();
  let { rangeStart, rangeEnd, rangeStartBlackedOut } = state.Calendar;
  // invert rangeStartBlackedOut, becuase if it was blacked out, we aren't making a blackout date
  dispatch(addChangesToQueue(rangeStart, rangeEnd, !rangeStartBlackedOut));
  dispatch({ type: "CALENDAR_CLEAR_RANGE" });
};
/**
 * Push users changes to the queue
 * @param {Date} rangeStart
 * @param {Date} rangeEnd
 * @param {Bool} blackout
 */
export const addChangesToQueue = (rangeStart, rangeEnd, blackout) => {
  return (dispatch, getState) => {
    // First, make sure the calendar is in sync.
    if (!getState().Calendar.synced) {
      return false;
    }
    // Next, make sure we have a valid date range built
    if (!rangeEnd) {
      rangeEnd = rangeStart;
    }
    // Need to reverse these, if they're selected backwards...
    const [start, end] =
      rangeStart < rangeEnd ? [rangeStart, rangeEnd] : [rangeEnd, rangeStart];
    dispatch({
      type: "CALENDAR_ADD_CHANGES",
      data: {
        rangeStart: start,
        rangeEnd: end,
        blackout,
        undone: false
      }
    });
  };
};
/**
 * Undo one change
 */
export const undoOne = () => ({ type: "CALENDAR_UNDO_ONE" });
/**
 * Undo all changes
 */
export const undoAll = () => ({ type: "CALENDAR_UNDO_ALL" });
/**
 * Clear changes
 */
export const clearChanges = () => ({ type: "CALENDAR_CLEAR_CHANGES" });
/**
 * Save changes
 */
export const save = () => (dispatch, getState) => {
  const { unit, changes } = getState().Calendar;
  const dates = getFinalBlackout(unit, changes);
  dispatch(setProcessing());
  AjaxFn({
    url: "/apis/portal/unitavailability",
    data: {
      action: "update",
      data: {
        ID: unit.id,
        dates: JSON.stringify(dates)
      }
    },
    success(data) {
      window.toastr.success();
      dispatch(clearChanges());
      dispatch(doneProcessing());
      dispatch(getUnit(unit.id, "reload"));
    },
    failure(error) {
      window.toastr.error(error);
      dispatch(doneProcessing());
    }
  });
};
