import React from "react";
import Comments from "components/Comments";
import { Header } from "semantic-ui-react";
import props from "../props.js";

const ResortComments = props => {
  const { data } = props.resortData;

  return (
    <>
      <Header
        as="h1"
        dividing
        icon="comment outline"
        content={data.resort_company.name + " " + data.name + " / Comments"}
        subheader="Leave comments for the resort"
      />
      <Comments thread={data.thread} />
    </>
  );
};

ResortComments.propTypes = { ...props };

export default ResortComments;
