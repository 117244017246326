import React from "react";
import ButtonElement from "../ButtonElement";

const NavElement = props => {
  const childprops = {
    color: "blue",
    ...props
  };

  return <ButtonElement {...childprops} />;
};

export default NavElement;
