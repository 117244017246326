import React, { useRef } from "react";
import PropTypes from "prop-types";

import { Container } from "semantic-ui-react";

import Layout from "components/Layout";
import Breadcrumb from "./components/Breadcrumb";
import ResortLoader from "./components/ResortLoader";
import Renderer from "./components/Renderer";

const ResortPage = props => {
  const resortLoaderRef = useRef(null);

  const { resortID, resortTab } = props.match.params;

  const config = {
    resortID: resortID,
    ref: resortLoaderRef
  };

  const reload = () => {
    if (resortLoaderRef.current) {
      resortLoaderRef.current.reload();
    }
  };

  return (
    <Layout>
      <Breadcrumb />
      <br />
      <Container>
        <ResortLoader {...config}>
          {resortData => {
            return (
              <Renderer
                resortData={resortData}
                resortID={resortID}
                resortTab={resortTab}
                reloadResort={reload}
              />
            );
          }}
        </ResortLoader>
      </Container>
    </Layout>
  );
};

ResortPage.propTypes = {
  match: PropTypes.object.isRequired
};

export default ResortPage;
