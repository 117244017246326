import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";

import { Table, Header, Icon } from "semantic-ui-react";
import moment from "moment";

export default class History extends Component {
  static propTypes = {
    timepunch: PropTypes.object.isRequired
  };

  render() {
    const { timepunch } = this.props;
    const sorted_edits = timepunch.edits.sort(
      (a, b) => new Date(b.modified_on) - new Date(a.modified_on)
    );
    return (
      <Fragment>
        <p>
          The current and past values for the timepunch are listed below. The
          highest row is the most recent value.
        </p>
        <Table basic="very">
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell width="4">Punch in</Table.HeaderCell>
              <Table.HeaderCell width="4">Punch out</Table.HeaderCell>
              <Table.HeaderCell width="8">Notes</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {!timepunch.deleted && (
              // We don't need to show this if it's deleted - there will be a log entry
              // Otherwise we render this to represent the current value
              <Table.Row>
                <Table.Cell>
                  <b>
                    {timepunch.in &&
                      moment(timepunch.in).format("dddd, hh:mm:ss A")}
                  </b>
                </Table.Cell>
                <Table.Cell>
                  <b>
                    {timepunch.out &&
                      moment(timepunch.out).format("dddd, hh:mm:ss A")}
                  </b>
                </Table.Cell>
                <Table.Cell>
                  <i>This is the current value.</i>
                </Table.Cell>
              </Table.Row>
            )}
            {sorted_edits.map((edit, index) => {
              return (
                <Table.Row key={index}>
                  <Table.Cell>
                    {edit.old_in &&
                      "was " + moment(edit.old_in).format("ddd, hh:mm:ss A ")}
                  </Table.Cell>
                  <Table.Cell>
                    {edit.old_out &&
                      "was " + moment(edit.old_out).format("ddd, hh:mm:ss A ")}
                  </Table.Cell>
                  <Table.Cell>{edit.reason}</Table.Cell>
                </Table.Row>
              );
            })}
          </Table.Body>
        </Table>
      </Fragment>
    );
  }
}
