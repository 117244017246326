import React from "react";
import { Table } from "semantic-ui-react";
export default ({ data }) => {
  const visible = [...data].splice(0, 10);
  const hidden = [...data].splice(10);
  return (
    <div>
      <Table compact={"very"}>
        <Table.Body>
          {visible.map((row, key) => (
            <Table.Row key={key}>
              {row.map((cell, ckey) => (
                <Table.Cell key={ckey}>{cell}</Table.Cell>
              ))}
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
      {hidden.length > 0 && <p>+ {hidden.length} more row(s)</p>}
      <p>{data.length} total row(s)</p>
    </div>
  );
};
