import React from "react";
import {
  Modal,
  Button,
  Icon,
  Header,
  Table,
  Segment,
  Form,
  Dropdown
} from "semantic-ui-react";
import AjaxFn from "services/AjaxFn";
import { formatMJY } from "utils/dates";
/**
 *	@prop booking
 *	@prop reload
 */
export default class GuestPaymentDueMethod extends React.Component {
  state = {
    open: false,
    loading: false,
    value: "2.00"
  };

  save = () => {
    this.setState({ loading: true });
    AjaxFn({
      url: "/apis/portal/bookingfinances",
      data: {
        action: "create",
        data: {
          booking_id: this.props.booking.id,
          reason: "Updated guest payment required",
          amount: this.state.value,
          type: "require_payment"
        }
      },
      success: message => {
        this.props.reload();
      },
      failure: error => {
        window.toastr.error(error);
        this.setState({ loading: false });
      }
    });
  };

  render() {
    const require_payments = this.props.booking.finances
      .filter(({ type }) => type === "require_payment")
      .sort((a, b) => new Date(b.created_on) - new Date(a.created_on));
    const trigger = (
      <div onClick={() => this.setState({ open: true })}>
        {this.props.children}
      </div>
    );
    return (
      <Modal open={this.state.open} trigger={trigger} size={"small"}>
        <Modal.Header>Guest Payment Due Method</Modal.Header>
        {this.state.loading ? (
          <Modal.Content>
            <Segment
              loading
              style={{ paddingTop: "8rem", paddingBottom: "8rem" }}
            />
          </Modal.Content>
        ) : (
          <Modal.Content>
            {this.props.showIfEditable(
              <Form>
                <Form.Field>
                  <label>Required</label>
                  <Dropdown
                    selection
                    options={options}
                    value={this.state.value}
                    onChange={(e, { value }) => this.setState({ value })}
                    placeholder={"Setting"}
                  />
                </Form.Field>
                <Form.Field>
                  <Button
                    onClick={this.save}
                    color={"blue"}
                    loading={this.state.loading}
                    disabled={this.state.loading}
                    content={"Update"}
                    icon={"save"}
                  />
                  <Button
                    onClick={() => this.setState({ value: "2.00" })}
                    disabled={this.state.loading}
                    content={"Reset"}
                    icon={"undo"}
                  />
                </Form.Field>
              </Form>
            )}

            <Header>
              <Icon name="history" />
              <Header.Content>
                History
                <Header.Subheader>Current and past values</Header.Subheader>
              </Header.Content>
            </Header>
            <div style={{ maxHeight: "300px", overflow: "auto" }}>
              <Table striped celled>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell>Required</Table.HeaderCell>
                    <Table.HeaderCell>Date</Table.HeaderCell>
                    <Table.HeaderCell>Updated By</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {require_payments.map((setting, key) => (
                    <Table.Row key={key}>
                      <React.Fragment>
                        <Table.Cell>
                          {
                            options
                              .filter(opt => opt.value === setting.amount)
                              .pop().text
                          }
                        </Table.Cell>
                        <Table.Cell>{formatMJY(setting.created_on)}</Table.Cell>
                        <Table.Cell>
                          {setting.created_by.firstname +
                            " " +
                            setting.created_by.lastname[0]}
                        </Table.Cell>
                      </React.Fragment>
                    </Table.Row>
                  ))}
                </Table.Body>
              </Table>
            </div>
            <br />
            <p>
              <Icon name={"info"} />
              The highest row is the current value for the booking.
            </p>
          </Modal.Content>
        )}
        <Modal.Actions>
          <Button onClick={() => this.setState({ open: false })}>Close</Button>
        </Modal.Actions>
      </Modal>
    );
  }
}
const options = [
  { value: "0.00", text: "Not Required" },
  { value: "1.00", text: "Required" },
  { value: "2.00", text: "Calculated (default)" }
];
