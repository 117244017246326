/**
 * Dispatch an action that pushes the token into state.Socket.ignore[]
 * @param {String} token
 */
export const ignore = token => ({
  type: "SOCKETS_IGNORE",
  data: token
});

export const expire = token => ({
  type: "SOCKETS_EXPIRE",
  data: token
});
