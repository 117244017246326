import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import moment from "moment";

import { Modal, Button, Form } from "semantic-ui-react";

import { MyCalWrapper } from "components/MyCal";
import { reload } from "redux/TimepunchManager/actions";
import naturalSort from "utils/naturalSort";
import AjaxFn from "services/AjaxFn";

export class NewPunchButton extends Component {
  static propTypes = {
    loading: PropTypes.bool.isRequired,
    accounts: PropTypes.array.isRequired,
    restricted: PropTypes.bool.isRequired,
    userID: PropTypes.number.isRequired
  };

  state = {
    open: false,
    submitting: false,
    in: moment(),
    out: moment(),
    account: "",
    cancel: false
  };

  componentWillUnmount() {
    this.cancel();
  }

  cancel = () => {
    if (typeof this.state.cancel === "function") {
      this.state.cancel();
    }
  };

  handleOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    if (!this.state.submitting) {
      this.setState({ open: false });
    }
  };

  canSubmit = () => {
    if (!this.state.account) {
      return false;
    }
    if (!this.state.in || !this.state.out) {
      return false;
    }
    if (this.state.in > this.state.out) {
      return false;
    }
    return true;
  };

  submit = () => {
    this.cancel();
    this.setState({
      submitting: true,
      cancel: AjaxFn({
        url: "/apis/portal/timepunches",
        data: {
          action: "create",
          data: {
            account_id: this.state.account,
            in: this.state.in.format("YYYY-MM-DD HH:mm:ss"),
            out: this.state.out.format("YYYY-MM-DD HH:mm:ss")
          }
        },
        success: () => {
          this.setState({
            submitting: false,
            cancel: false,
            open: false,
            account: "",
            in: moment(),
            out: moment()
          });
          window.toastr.success();
          this.props.reload();
        },
        failure: error => {
          this.setState({ submitting: false, cancel: false });
          window.toastr.error(error);
        }
      })
    });
  };

  render() {
    const { loading, accounts, restricted, userID } = this.props;
    const { submitting, open, account } = this.state;

    const modalProps = {
      size: "mini",
      onClose: this.handleClose,
      open,
      trigger: (
        <Button
          primary
          content="New"
          disabled={loading}
          onClick={this.handleOpen}
        />
      )
    };

    return (
      <Modal {...modalProps}>
        <Modal.Header>New Timepunch</Modal.Header>
        <Modal.Content>
          <p>
            Only accounts that are not disabled can have timepunches created.
          </p>
          <Form>
            <Form.Dropdown
              label="Account"
              value={account}
              search
              selection
              selectOnBlur={false}
              placeholder="Select one..."
              options={accounts
                .filter(row => !row.disabled)
                .filter(row => (row.id === userID ? !restricted : true))
                .map((account, index) => ({
                  value: account.id,
                  text: account.firstname + " " + account.lastname,
                  key: index
                }))
                .sort((a, b) => naturalSort(a.text, b.text))}
              onChange={(e, { value }) => this.setState({ account: value })}
              required
            />
            <Form.Field required>
              <label>Punch In</label>
              <MyCalWrapper
                type="datetime"
                value={this.state.in}
                onChange={(e, { value }) => this.setState({ in: value })}
                outputMask={"MMM Do YYYY, h:mm:ss a"}
                format={false}
                disabled={submitting}
              />
            </Form.Field>
            <Form.Field required>
              <label>Punch Out</label>
              <MyCalWrapper
                type="datetime"
                value={this.state.out}
                onChange={(e, { value }) => this.setState({ out: value })}
                outputMask={"MMM Do YYYY, h:mm:ss a"}
                format={false}
                disabled={submitting}
              />
            </Form.Field>
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button
            content="Close"
            onClick={this.handleClose}
            disabled={submitting}
          />
          <Button
            content="Submit"
            color="blue"
            onClick={this.submit}
            disabled={submitting || !this.canSubmit()}
            loading={submitting}
          />
        </Modal.Actions>
      </Modal>
    );
  }
}

const mapStateToProps = state => ({
  loading: state.TimepunchManager.loading,
  accounts: state.TimepunchManager.accounts,
  restricted: state.User.account_role.restricted,
  userID: state.User.id
});

const mapDispatchToProps = {
  reload
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NewPunchButton);
