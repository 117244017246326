import naturalSort from "../../utils/naturalSort";
import smartSearch from "../../utils/smartSearch";
/**
 *	Natural sorts data and returns it
 *	Supports multi column sorting
 *	@param {Array} a (row A)
 *	@param {Array} b (row B)
 *	@param {Array} cols (column definitions)
 *	@param {Array} orderings (the orderings)
 *	@param {Integer} depth (what ordering to use, recursion purposes)
 *	@return {Integer}
 */
const sortData = (a, b, cols, orderings, depth = 0) => {
  let [colIndex, direction] = orderings[depth];
  let src = cols[colIndex].src;
  let [aData, bData] =
    typeof src === "function" ? [src(a), src(b)] : [a[src], b[src]];
  let result = naturalSort(aData, bData);
  if (result === undefined) {
    if (orderings.length > depth + 1) {
      result = sortData(a, b, cols, orderings, depth + 1);
    }
  }
  if (result !== undefined) {
    return direction === "asc" ? result : result * -1;
  }
};
/**
 *	Gets and returns ordered data
 *	@param {Array} data
 *	@param {Array} cols (column definitions)
 *	@param {Array} order (current ordering)
 *	@return {Array}
 */
export const getOrderedData = (data, cols, order) => {
  return [...data].sort((a, b) => sortData(a, b, cols, order));
};
/**
 *	Gets and returns searched data
 *	@param {Array} data
 *	@param {String} search
 *	@param {Array} cols (column definitions)
 *	@return {Array}
 */
export const getSearchedData = (data, search, cols) => {
  return [...data].filter(row => {
    try {
      cols.forEach(col => {
        if (col.searchable !== false) {
          const src =
            typeof col.src === "function" ? col.src(row) : row[col.src];
          const entry = [{ src }];
          const props = { src: true };
          const options = { maxInsertions: 4 };
          const result =
            typeof col.filterMethod === "function"
              ? col.filterMethod(src, row, search, smartSearch)
              : window.smartSearch(entry, search, props, options).length;
          // error in try/catch to break forEach
          if (result) throw new Error();
        }
      });
    } catch (e) {
      return true;
    }
    return false;
  });
};
/**
 *	Gets and returns filtered data
 *	@param {Array} data
 *	@param {Array} filters (functions to apply)
 *	@return {Array}
 */
export const getFilteredData = (data, filters) => {
  return [...data].filter(row => {
    try {
      filters.forEach(filter => {
        if (!filter(row)) {
          throw new Error("row did not pass filter");
        }
      });
    } catch (e) {
      return false;
    }
    return true;
  });
};
/**
 *	Gets and returns paginated data
 *	@param {Array} data
 *	@param {Integer} show (the max rows to show)
 *	@param {Integer} page (the current page #)
 *	@return {Array}
 */
export const getPaginatedData = (data, show, page) => {
  return [...data].splice(show * page, show);
};
/**
 *	Returns finalized data
 *	@param {Array} data
 *	@return {Array}
 */
export const getFinalData = data => [...data];
