import React from "react";
import { Button, Header, Icon, Modal } from "semantic-ui-react";
export default (ContentManager, Delete) => (
  <React.Fragment>
    <Button
      content="Delete"
      icon="trash alternate outline"
      color="red"
      onClick={Delete.functions.openModal}
    />
    <Modal
      open={Delete.state.isOpen}
      onClose={Delete.functions.cancel}
      size="tiny"
      basic
    >
      <Modal.Header>
        <Header inverted>
          <Icon name="exclamation triangle" />
          Are you sure?
        </Header>
      </Modal.Header>
      <Modal.Content>
        Are you sure you want to delete this role?{" "}
        <strong>
          <u>This action can not be undone.</u>
        </strong>
        <Modal.Description content="You can not delete a role that has users still assigned to it." />
      </Modal.Content>
      <Modal.Actions>
        <Button
          icon="close"
          content="Cancel"
          onClick={Delete.functions.cancel}
          inverted
        />
        <Button
          icon="checkmark"
          content="Yes, delete it"
          color="red"
          inverted
          onClick={Delete.functions.confirm}
          loading={Delete.state.isLoading}
        />
      </Modal.Actions>
    </Modal>
  </React.Fragment>
);
