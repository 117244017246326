import React, { Component } from "react";
import PropTypes from "prop-types";
import { Segment, Header, List, Button } from "semantic-ui-react";
import ChangeModal from "./components/ChangeModal";
import calculateBooking from "utils/bookings";
import { Link } from "react-router-dom";

export default class Guest extends Component {
  static propTypes = {
    data: PropTypes.object.isRequired
  };
  state = {
    open: false
  };
  handleOpen = () => this.setState({ open: true });
  handleClose = () => this.setState({ open: false });
  render() {
    const { data } = this.props;
    const { guest } = data;
    const calculated = calculateBooking(data);
    return (
      <Segment>
        <Header as="h2">
          <Header.Content>
            Guest
            <Header.Subheader>
              <Link to={"/browse/guests/" + guest.id}>
                {guest.firstname} {guest.lastname}
              </Link>
            </Header.Subheader>
          </Header.Content>
        </Header>
        <List>
          <List.Item>
            <List.Icon name="dollar" />
            <List.Content>
              <List.Header>Financials</List.Header>
              <List.Description>
                Grand Total: ${calculated.grand_total} <br />
                Amount Paid: ${calculated.guest_payment} <br />
                Payment Due: ${calculated.guest_payment_due}
              </List.Description>
            </List.Content>
          </List.Item>
          <List.Item>
            <List.Icon name="phone" />
            <List.Content>
              <List.Header>Phone</List.Header>
              <List.Description>{guest.phone}</List.Description>
            </List.Content>
          </List.Item>
          <List.Item>
            <List.Icon name="mail" />
            <List.Content>
              <List.Header>Email</List.Header>
              <List.Description>{guest.email}</List.Description>
            </List.Content>
          </List.Item>
          <List.Item>
            <List.Icon name="map" />
            <List.Content>
              <List.Header>Address</List.Header>
              <List.Description>
                {guest.address}
                <br />
                {guest.city}, {guest.state} {guest.country} {guest.zipcode}
              </List.Description>
            </List.Content>
          </List.Item>
        </List>
        <br />
        {data._actions.actions.edit_guest ? (
          <div>
            <Button basic onClick={this.handleOpen} content="Change Guest" />
            <ChangeModal
              handleClose={this.handleClose}
              open={this.state.open}
              booking={data}
            />
          </div>
        ) : (
          <p>You are not able to change the booking's guest right now.</p>
        )}
      </Segment>
    );
  }
}
