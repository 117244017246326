import React from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import { Popup } from "semantic-ui-react";

import { user_can } from "utils/user";
import {
  Admin,
  Accounting,
  Booking,
  CustomerService,
  Developer,
  Reception
} from "./departments";

import {
  backgroundColor,
  backgroundColorHover,
  borderColor,
  fontColor
} from "theme";

import {
  ThemePopup,
  ThemePopupContent,
  ThemeNavAction
} from "theme/components";

const Resources = ({ User }) => (
  <ThemePopup
    trigger={
      <ThemeNavAction>
        <i className="fas fa-globe" />
      </ThemeNavAction>
    }
    content={
      <ThemePopupContent>
        <Departments user={User} />
      </ThemePopupContent>
    }
    on="click"
    basic
  />
);

const Departments = ({ user }) => {
  if (user_can("EM_RES_ADMIN", user)) return <Admin />;
  if (user_can("EM_RES_ACCOUNTING", user)) return <Accounting />;
  if (user_can("EM_RES_BOOKDEP", user)) return <Booking />;
  if (user_can("EM_RES_CS", user)) return <CustomerService />;
  if (user_can("EM_RES_DEVELOPER", user)) return <Developer />;
  if (user_can("EM_RES_RECEPTION", user)) return <Reception />;
  return <p>No resources found.</p>;
};

const mapStateToProps = state => ({
  User: state.User
});

export default connect(mapStateToProps)(Resources);
