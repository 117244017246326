import React from "react";
import {
  Segment,
  Header,
  Grid,
  Icon,
  Form,
  Button,
  Message
} from "semantic-ui-react";
import MoneyInput from "components/MoneyInput";
import Card from "./components/Card";
import Check from "./components/Check";
import { Wrapper } from "../../context";

class PaymentForm extends React.Component {
  renderPaymentForm() {
    const { data, functions, config, agreement } = this.props.context;

    if (config.original_data.sensitive_data_cleared_on) {
      return (
        <Message
          icon="lock"
          header="Payment data cleared"
          content="Payment information for this agreement has been automatically removed from our system."
        />
      );
    }

    const paymentMethodReadOnly = functions.shouldFieldBeReadOnly(
      "payment_method"
    );

    const paymentMethodDisabled = functions.shouldFieldBeDisabled(
      "payment_method",
      paymentMethodReadOnly
    );

    return (
      <div>
        <Message attached={"top"}>
          <Message.Header>
            <Icon name={"lock"} />
            Payment
          </Message.Header>
          Your payment info is kept secure.
        </Message>
        <Segment attached={"bottom"}>
          <Form.Group widths={"equal"}>
            {functions.createInput(<Form.Input />, "full_name_on_account", {
              name: "full_name_on_account",
              label: "Full name on account",
              placeholder: "Full name",
              required: true,
              autoComplete: "off"
            })}
            <Form.Field required>
              <label>I authorize one payment of</label>
              {functions.createInput(<MoneyInput />, "authorize_payment", {
                label: "$",
                type: "number",
                onChange: value => {
                  functions.setData("authorize_payment", value);
                }
              })}
            </Form.Field>
          </Form.Group>
          <Form.Group widths={"equal"}>
            {functions.createInput(<Form.Input />, "billing_address", {
              name: "billing_address",
              label: "Address",
              placeholder: "Address",
              required: true,
              autoComplete: "off"
            })}
            {functions.createInput(<Form.Input />, "billing_city", {
              name: "billing_city",
              label: "City",
              placeholder: "City",
              required: true,
              autoComplete: "off"
            })}
          </Form.Group>
          <Form.Group>
            {functions.createInput(<Form.Input />, "billing_address_2", {
              name: "billing_address_2",
              label: "Address line 2",
              placeholder: "Address line 2",
              required: false,
              autoComplete: "off",
              width: 8
            })}
            {functions.createInput(<Form.Input />, "billing_state", {
              name: "billing_state",
              label: "State",
              placeholder: "State",
              required: false,
              autoComplete: "off",
              width: 4
            })}
            {functions.createInput(<Form.Input />, "billing_zip", {
              name: "billing_zip",
              label: "Zip",
              placeholder: "Zip",
              required: false,
              autoComplete: "off",
              width: 4
            })}
          </Form.Group>
          <Form.Field required>
            <label>Payment Method</label>
            <Button
              type="button"
              content="Card"
              color={
                data.payment_method === "card" ? agreement.color : undefined
              }
              onClick={() => functions.setData("payment_method", "card")}
              disabled={paymentMethodDisabled || paymentMethodReadOnly}
            />
            <Button
              type="button"
              content="Check"
              color={
                data.payment_method === "check" ? agreement.color : undefined
              }
              onClick={() => functions.setData("payment_method", "check")}
              disabled={paymentMethodDisabled || paymentMethodReadOnly}
            />
          </Form.Field>
          {data.payment_method === "card" ? <Card /> : <Check />}
        </Segment>
      </div>
    );
  }
  render() {
    return (
      <Segment vertical>
        <Grid stackable>
          <Grid.Column width={5}>
            <Header as={"h3"}>
              <Icon name={"money"} />
              Payment
            </Header>
            <p>
              Your payment information is required so that we may process any
              related fees.
            </p>
          </Grid.Column>
          <Grid.Column width={11}>{this.renderPaymentForm()}</Grid.Column>
        </Grid>
      </Segment>
    );
  }
}

export default Wrapper(PaymentForm);
