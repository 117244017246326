import React from "react";
import PropTypes from "prop-types";
import { Form } from "semantic-ui-react";

const Notes = ({ notes, handleChange }) => {
	return (
		<Form.Group widths="equal">
			<Form.TextArea
				label="Additional information"
				name="notes"
				id="notes"
				onChange={handleChange}
				value={notes}
				placeholder="Notes..."
			/>
		</Form.Group>
	);
};

Notes.propTypes = {
	notes: PropTypes.string
};

Notes.defaultProps = {
	notes: ""
};

export default Notes;
