import moment from "moment";

/**
 *	Check if the connected user has a permission enabled for them
 *	@param {String} permission
 *  @param {Sbject} user
 *  @return {Bool}
 */
export const user_can = (permission, user) => {
  if (typeof permission !== "string") {
    throw new Error("Permission passed should be a string");
  }

  if (user.account_role.restricted) {
    let user_rights = {};
    let role_rights = {};
    user.rights.forEach(right => {
      user_rights[right.right] = right.value;
    });
    user.account_role.rights.forEach(right => {
      role_rights[right.right] = right.value;
    });
    if (typeof user_rights[permission] !== "undefined") {
      return user_rights[permission];
    } else {
      return role_rights[permission] || false;
    }
  } else {
    return true;
  }
};
/**
 *	Check if the connected user has all passed permissions enabled for them
 *	@param {Array} permissions
 *  @param {Object} user
 *  @return {Bool}
 */
export const user_can_arr = (permissions, user) => {
  return (
    permissions
      .map(permission => user_can(permission, user))
      .filter(row => !row).length === 0
  );
};

/**
 *	Check if the connected user has all passed permissions enabled for them
 *	@param {Object} booking - the booking object
 *  @param {Object} user
 *  @param {Array} locked - permissions to require if older than 30 mins
 *  @param {Array} unlocked - permissions to require if newer than 30mins
 *  @return {Bool}
 */
export const timerestrict_booking = (booking, user, locked, unlocked) => {
  const created = moment(booking.created_on);
  const current = moment();
  const restrict = (current - created) / 1000 / 60 > 30;
  const allowed = restrict
    ? user_can_arr(locked, user)
    : user_can_arr(unlocked, user);
  return allowed;
};

/**
 * Make and return a sample user with rights specified
 * Rights should be an array ["right_name"(, true/false)]
 * Should ONLY be used for running tests
 * @param {Array} rights
 */
export const makeUser = (rights = []) => ({
  id: 1,
  rights: [],
  account_role: {
    restricted: true,
    rights: rights.map(right => ({
      right,
      value: true
    }))
  }
});
