import React from "react";
import { connect } from "react-redux";
import injectSheet from "react-jss";
import { addCommas } from "utils/numbers";

class Statistics extends React.Component {
  render() {
    const { queue, bookings } = this.props;
    const all_memberships = [];
    const all_members = [];
    const all_issues = [];
    let queued_memberships = [];
    let queued_members = [];
    let queued_issues = [];
    bookings.forEach(booking => {
      const { membership } = booking.point_usages[0].point_set;
      if (all_memberships.indexOf(membership.id) === -1) {
        all_memberships.push(membership.id);
      }
      if (all_members.indexOf(membership.member.id) === -1) {
        all_members.push(membership.member.id);
      }

      membership.member.issues.forEach(issue => {
        if (issue.closed_on === null) {
          if (all_issues.indexOf(issue.id === -1)) {
            all_issues.push(issue.id);
          }
        }
      });

      if (queue.indexOf(booking.id) !== -1) {
        if (queued_memberships.indexOf(membership.id) === -1) {
          queued_memberships.push(membership.id);
        }
        if (queued_members.indexOf(membership.member.id) === -1) {
          queued_members.push(membership.member.id);
        }

        membership.member.issues.forEach(issue => {
          if (issue.closed_on === null) {
            if (queued_issues.indexOf(issue.id) === -1) {
              queued_issues.push(issue.id);
            }
          }
        });
      }
    });
    let owner_pay = 0;
    if (bookings.length) {
      const mapped = queue.map(id => bookings.find(row => row.id === id));
      const reducer = (a, c) => a + Number(c.calculated.owner_pay);
      owner_pay = mapped.reduce(reducer, 0);
    }

    return (
      <div>
        {"Members queued: "}
        <b>
          {queued_members.length}
          {"/"}
          {all_members.length}
        </b>
        <br />
        {"Memberships queued: "}
        <b>
          {queued_memberships.length}
          {"/"}
          {all_memberships.length}
        </b>
        <br />
        <br />
        {"Bookings queued: "}
        <b>
          {queue.length}
          {"/"}
          {bookings.length}
        </b>
        <br />
        {"Owner Issues in queue: "}
        <b>
          {queued_issues.length}/{all_issues.length}
        </b>
        <br />
        <br />
        Total payout: <b>${addCommas(owner_pay, 2)}</b>
      </div>
    );
  }
}

const styles = {};

const mapStateToProps = state => ({
  queue: state.EOQReport.queue,
  bookings: state.EOQReport.bookings.data.bookings
  // ...
});
const mapDispatchToProps = dispatch => ({
  // ...
});

const connected = connect(
  mapStateToProps,
  mapDispatchToProps
)(Statistics);
export default injectSheet(styles)(connected);
