import storage from "local-storage-fallback";
import initial from "./initial";

export const set = (key, value) => {
  storage.setItem(
    "store",
    JSON.stringify({
      ...JSON.parse(storage.getItem("store") || "{}"),
      [key]: value
    })
  );

  return {
    type: "STORAGE_SET",
    key,
    value
  };
};

/**
 * reads localstorage and updates our current storage to match it, should be fired whenever
 * a change event is detected
 */
export const resync = event => ({
  type: "STORAGE_RESYNC",
  data: {
    ...initial,
    ...JSON.parse(storage.getItem("store") || "{}")
  }
});
