export const validateGuest = data => {
  return !!data.guest;
};
export const validateUnit = (data, units) => {
  if (!data || !units || !units.data) {
    return false;
  }
  const unit = units.data.find(row => row.id === data.unit);
  if (!unit || !unit.active) {
    return false;
  }
  switch (unit.unit_type) {
    case "ciirus":
      return !!data.subUnit;
    default:
      return true;
  }
};
// export const validateOwner = (data) => {
//   return data.amount > 0;
// };
export const validateDetails = data => {
  if (!data.check_in || !data.check_out) {
    return false;
  }
  if (new Date(data.check_in) > new Date(data.check_out)) {
    return false;
  }
  return true;
};
export const canSave = (data, units) => {
  return (
    validateGuest(data) &&
    validateUnit(data, units) &&
    // validateOwner(data) &&
    validateDetails(data)
  );
};
