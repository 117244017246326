import React from "react";
import { Table } from "semantic-ui-react";
import Day from "./components/Day";
import NoDay from "./components/NoDay";
import OldDay from "./components/OldDay";
import styles from "../../../../styles";
import injectSheet from "react-jss";
import { connect } from "react-redux";
import moment from "moment";
class Month extends React.Component {
  render() {
    const { month, classes, processing, year } = this.props;
    const today = new Date(moment().format("YYYY-MM-DD") + " 00:00:00");
    if (processing) {
      return <div style={{ height: "220px", background: "#eee" }} />;
    }
    return (
      <Table className={classes.month} columns={7} compact={"very"}>
        <Table.Body>
          <Table.Row className={classes.monthNameContainer}>
            <Table.Cell className={classes.monthName} colSpan={7}>
              <span>{month.name + " " + year}</span>
            </Table.Cell>
          </Table.Row>
          <Table.Row className={classes.monthDayHeader}>
            <Table.Cell className={classes.monthDayHeaderDay}>Su</Table.Cell>
            <Table.Cell className={classes.monthDayHeaderDay}>Mo</Table.Cell>
            <Table.Cell className={classes.monthDayHeaderDay}>Tu</Table.Cell>
            <Table.Cell className={classes.monthDayHeaderDay}>We</Table.Cell>
            <Table.Cell className={classes.monthDayHeaderDay}>Th</Table.Cell>
            <Table.Cell className={classes.monthDayHeaderDay}>Fr</Table.Cell>
            <Table.Cell className={classes.monthDayHeaderDay}>Sa</Table.Cell>
          </Table.Row>
          {month.days.map((row, key) => (
            <Table.Row key={key}>
              {row.map((day, key2) => (
                <React.Fragment key={key2}>
                  {!day ? (
                    <NoDay />
                  ) : day.date >= today ? (
                    <Day day={day} />
                  ) : (
                    <OldDay today={today} day={day} />
                  )}
                </React.Fragment>
              ))}
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    );
  }
}
const styled = injectSheet(styles)(Month);
export default connect((state) => ({
  processing: state.Calendar.processing,
  year: state.Calendar.year
  // ...
}))(styled);
