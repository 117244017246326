import React from "react";
import { Dimmer, Loader, Header, Icon } from "semantic-ui-react";
import FocusBar from "components/FocusBar";
import props from "../props.js";

const ResortFocusBar = props => {
  const {
    resortData: { data },
    resortID,
    resortTab
  } = props;

  const url = "/browse/resorts/" + resortID + "/";

  return (
    <FocusBar>
      {data ? (
        <FocusBar.Content>
          <br />
          <FocusBar.Generic>
            <Header as="h3" inverted>
              {data.resort_company.name} {data.name}
              <Header.Subheader>
                {data.active ? (
                  <>
                    <Icon name="checkmark" color="green" />
                    Resort is active
                  </>
                ) : (
                  <>
                    <Icon name="cancel" color="red" />
                    Resort is inactive
                  </>
                )}
              </Header.Subheader>
            </Header>
          </FocusBar.Generic>
          <FocusBar.Nav
            content="Summary"
            icon="far fa-folder"
            active={!resortTab}
            href={url}
          />
          <FocusBar.Nav
            content="Comments"
            icon="far fa-comments"
            active={resortTab === "comments"}
            href={url + "comments"}
          />
          <FocusBar.Dropdown content="Units">
            {data.units.map(unit => (
              <FocusBar.Nav
                icon="fas fa-link"
                key={unit.id}
                href={"/browse/units/" + unit.id}
                content={unit.name}
              />
            ))}
            <FocusBar.Nav
              content="New Unit"
              icon="fas fa-plus"
              href="/wizards/properties"
            />
          </FocusBar.Dropdown>
        </FocusBar.Content>
      ) : (
        <FocusBar.Content>
          <Dimmer active style={{ background: "none" }}>
            <Loader />
          </Dimmer>
        </FocusBar.Content>
      )}
    </FocusBar>
  );
};

ResortFocusBar.propTypes = { ...props };
export default ResortFocusBar;
