import React from "react";
import {
  Container,
  Header,
  Icon,
  Button,
  Segment,
  Input
} from "semantic-ui-react";
import Layout from "components/Layout";
import ContextBar from "components/ContextBar";
import AjaxFn from "services/AjaxFn";
import CloneForm from "./components/CloneForm";
import PropertyTypeSelector from "./components/PropertyTypeSelector";
import ResortForm from "./components/ResortForm";
import UnitForm from "./components/UnitForm";
import SuccessModal from "./components/SuccessModal";

// default form state
const defaultValues = {
  newResort: {
    resort_company_id: "",
    name: "",
    address: "",
    phone: "",
    city: "",
    state: "",
    country: "us",
    county: ""
  },
  newUnit: {
    resort_id: "",
    name: ""
    // unique_title: "",
    // ciirus_name: "",
    // ciirus_id: ""
  },
  newIDs: [""],
  cloneID: false
};
export default class PropertyWizard extends React.Component {
  state = {
    loading: false,
    resort: true,
    unit: false,
    clone: false,
    successModalOpen: false,
    createdProperties: {},
    ...defaultValues
  };
  closeSuccessModal = () => this.setState({ successModalOpen: false });
  reset = () => {
    this.setState({
      ...defaultValues
    });
  };
  /**
   *	Sets a value for the resort
   *  @param {String} field
   *  @param {Mixed} value
   */
  setResort = (field, data) => {
    this.setState({ newResort: { ...this.state.newResort, [field]: data } });
  };
  /**
   *	Sets a value for the unit
   *  @param {String} field
   *  @param {Mixed} value
   */
  setUnit = (field, data) => {
    this.setState({ newUnit: { ...this.state.newUnit, [field]: data } });
  };
  /**
   *	Changes an ID for the clones array
   *  @param {String} value
   *  @param {Integer} index
   */
  handleIDChange = (value, index) => {
    const current = this.state.newIDs;
    current[index] = value;
    this.setState({ newIDs: current });
  };
  /**
   *	Removes an ID from the clones array
   *  @param {Integer} index
   */
  removeID = index => {
    const newIDs = this.state.newIDs.filter((v, i) => index !== i);
    this.setState({
      newIDs: newIDs.length ? newIDs : [""]
    });
  };
  /**
   *	Creates and returns a new wired up clone ID field
   *  @param {String} text
   *  @param {Integer} index
   */
  getNewIDField = (text = "", index) => {
    return (
      <Input
        label={"#"}
        placeholder={"0000000"}
        labelPosition={"left"}
        value={text}
        onChange={(e, { value }) => this.handleIDChange(value, index)}
        action={{
          color: "red",
          icon: "cancel",
          onClick: () => this.removeID(index)
        }}
      />
    );
  };
  /**
   *	Adds a new blank ID to the clones array
   */
  addAnotherID = () => {
    this.setState({
      newIDs: this.state.newIDs.concat("")
    });
  };
  /**
   *	Set the target unit ID to clone properties for
   *  Called once a valid clone is found in the database
   *  @param {Integer} Id
   */
  setCloneID = id => this.setState({ cloneID: id });
  /**
   *	Submits the form in its current state
   *  Determines what data to send based on the current mode
   */
  submit = () => {
    const mode = this.getMode();
    const data = this.getData();
    this.setState({ loading: true });
    AjaxFn({
      url: "/apis/portal/properties/build",
      data: { data, mode },
      success: message => {
        this.setState({
          loading: false,
          createdProperties: message,
          successModalOpen: true
        });
        this.reset();
      },
      failure: e => {
        this.setState({ loading: false });
        window.toastr.error(e);
      }
    });
  };
  /**
   *	Sets the interface type
   *	@param {String} type
   */
  selectType = type => {
    if (this.state.loading) {
      return false;
    }
    switch (type) {
      case "resort":
        this.setState({ resort: true, unit: false, clone: false });
        break;
      case "unit":
        this.setState({ resort: false, unit: true, clone: false });
        break;
      case "resort-unit":
        this.setState({ resort: true, unit: true, clone: false });
        break;
      case "clone":
        this.setState({ resort: false, unit: false, clone: true });
        break;
      default:
        this.selectType("resort");
    }
  };
  /**
   *	Decides which form to show based on the current state
   */
  getForm = () => {
    return this.state.clone ? (
      <CloneForm
        newIDs={this.state.newIDs}
        addAnotherID={this.addAnotherID}
        getNewIDField={this.getNewIDField}
        cloneID={this.state.cloneID}
        setCloneID={this.setCloneID}
      />
    ) : (
      <React.Fragment>
        {this.state.resort && (
          <ResortForm
            resortOnly={this.state.resort && !this.state.unit}
            setData={this.setResort}
            data={this.state.newResort}
          />
        )}
        {this.state.unit && (
          <UnitForm
            unitOnly={!this.state.resort && this.state.unit}
            newResortAndUnit={() => this.selectType("resort-unit")}
            setData={this.setUnit}
            data={this.state.newUnit}
          />
        )}
      </React.Fragment>
    );
  };
  /**
   *  Determines the current mode
   */
  getMode = () => {
    const { resort, unit, clone } = this.state;
    if (clone) {
      return "clone";
    } else if (resort && unit) {
      return "resort-unit";
    } else if (unit) {
      return "unit";
    } else {
      return "resort";
    }
  };
  /**
   *	Get the payload for save requests
   */
  getData = () => {
    const { newResort, newUnit, newIDs } = this.state;
    let mode = this.getMode();
    switch (mode) {
      case "resort":
        return newResort;
      case "unit":
        return newUnit;
      case "resort-unit":
        return {
          newResort,
          newUnit
        };
      case "clone":
        return {
          target: this.state.cloneID,
          clones: newIDs
        };
      default:
        return {};
    }
  };
  /**
   *  Determines if the resort is valid
   */
  isResortValid = () => {
    let required = [
      "name",
      "resort_company_id",
      "address",
      "phone",
      "city",
      "country"
    ];
    let valid = true;
    required.forEach(field => {
      if (this.state.newResort[field] === "") {
        valid = false;
      }
    });
    return valid;
  };
  /**
   *  Determines if the unit is valid
   */
  isUnitValid = () => {
    let required = ["name" /*"title", "ciirusId", "ciirusName"*/];
    if (!this.state.resort) {
      required.push("resort_id");
    }
    let valid = true;
    required.forEach(field => {
      if (this.state.newUnit[field] === "") {
        valid = false;
      }
    });
    return valid;
  };
  /**
   *  Determines if the clone data is valid
   */
  isCloneValid = () => {
    let target = this.state.cloneID;
    let clones = this.state.newIDs.filter(id => id !== "").length;
    return clones && target;
  };
  /**
   *  Determines if the form can be submitted
   */
  canSubmit = () => {
    let mode = this.getMode();
    switch (mode) {
      case "resort":
        return this.isResortValid();
      case "unit":
        return this.isUnitValid();
      case "resort-unit":
        return this.isResortValid() && this.isUnitValid();
      case "clone":
        return this.isCloneValid();
      default:
        return false;
    }
  };
  /**
   *  Render output
   */
  render() {
    return (
      <Layout noBar>
        <ContextBar />
        <Container>
          <Header
            as="h1"
            dividing
            style={{ marginTop: "2rem", marginBottom: "3rem" }}
          >
            <Icon name="wizard" />
            <Header.Content>
              Create Properties
              <Header.Subheader>Create Units & Resorts</Header.Subheader>
            </Header.Content>
          </Header>
          <React.Fragment>
            <PropertyTypeSelector
              clone={this.state.clone}
              resort={this.state.resort}
              unit={this.state.unit}
              selectType={this.selectType}
              disabled={this.state.loading}
            />
            <Segment disabled={this.state.loading}>
              {this.getForm()}
              <div style={{ textAlign: "right" }}>
                <Button onClick={this.reset} content={"Clear"} />
                <Button
                  primary
                  onClick={this.submit}
                  disabled={!this.canSubmit()}
                  loading={this.state.loading}
                  content={"Submit"}
                />
              </div>
            </Segment>
          </React.Fragment>
        </Container>
        <br />
        <br />
        <SuccessModal
          open={this.state.successModalOpen}
          close={this.closeSuccessModal}
          data={this.state.createdProperties}
        />
      </Layout>
    );
  }
}
