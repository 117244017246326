import React from "react";
import naturalSort from "utils/naturalSort";
import LinkConf from "components/LinkWithConfirmation";
import { Icon, Popup } from "semantic-ui-react";

export default ({ data }) => {
  if (!data) {
    return null;
  }
  return (
    <Popup
      key={data.id}
      on={"focus"}
      trigger={
        <a href={null}>
          <Icon name={"checkmark"} color={"green"} />
          {data.units.length}
        </a>
      }
      header={"Units assigned:"}
      content={
        <ul style={{ paddingLeft: "1.5rem" }}>
          {data.units
            .sort((a, b) => naturalSort(a.name, b.name))
            .map((unit, key) => (
              <li key={key}>
                <a href={`/browse/units/${unit.id}`}>{unit.name}</a>
              </li>
            ))}
        </ul>
      }
    />
  );
};
