import React, { useState } from "react";
import PropTypes from "prop-types";

import { Modal, Button, Icon, Divider, Card, Feed } from "semantic-ui-react";

import CloseIssue from "../CloseIssue";
import IssueTableRow from "../IssueTableRow";
import SendMail from "../SendMail";
import { formatMJY } from "utils/dates";
import { OwnerIssues, getIssueName } from "fixtures/issues";
import useOnMountEffect from "effects/useOnMountEffect";

const Issue = props => {
  const [state, setState] = useState({
    open: false,
    canClose: false,
    requireMail: false
  });

  useOnMountEffect(() => {
    const requireMail = shouldRequireMail(props.data);

    if (requireMail) {
      setState({
        ...state,
        requireMail,
        canClose: data.sent_mail.length > 0
      });
    } else {
      setState({
        ...state,
        requireMail,
        canClose: true
      });
    }
  });

  const { data } = props;

  const modalProps = {
    size: "tiny",
    onClose: () => setState({ ...state, open: false }),
    open: state.open,
    trigger: (
      <IssueTableRow
        data={data}
        setOpen={() => setState({ ...state, open: true })}
      />
    )
  };

  return (
    <Modal {...modalProps}>
      <Modal.Header>
        ({getIssueName(data.category, OwnerIssues)}) {data.name}
      </Modal.Header>
      <Modal.Content style={{ background: "#eee" }}>
        <Card fluid style={{ pointerEvents: "auto" }}>
          <Card.Content>
            <Card.Header>
              <div style={{ display: "flex" }}>
                <div style={{ flexGrow: "1" }}>
                  {data.closed_on !== null ? (
                    <span>
                      <Icon
                        style={{ marginRight: ".5rem" }}
                        name="checkmark"
                        color="green"
                      />
                      Resolved Issue
                    </span>
                  ) : (
                    <span>
                      <Icon
                        style={{ marginRight: ".5rem" }}
                        name="exclamation triangle"
                        color="red"
                      />
                      Open Issue
                    </span>
                  )}
                </div>
                <div>
                  {state.requireMail && (
                    <SendMail
                      data={data}
                      enableClosing={() =>
                        setState({ ...state, canClose: true })
                      }
                    />
                  )}
                </div>
              </div>
            </Card.Header>
            <Card.Meta
              style={{ marginTop: state.requireMail ? "-1rem" : undefined }}
            >
              Details below
            </Card.Meta>
            <Divider />
            <Feed>
              <Feed.Event>
                <Feed.Label>
                  <Icon name="calendar outline" />
                </Feed.Label>
                <Feed.Content>
                  <Feed.Date content={formatMJY(data.opened_on)} />
                  <Feed.Summary>
                    {!data.opened_by.id ? (
                      <>
                        <Icon name="shield" />
                        {" Opened by "}
                        <b>System</b>
                      </>
                    ) : (
                      <>
                        {"Opened by "}
                        <b>
                          {data.opened_by.firstname} {data.opened_by.lastname}
                        </b>
                      </>
                    )}
                  </Feed.Summary>
                  <Feed.Extra style={{ whiteSpace: "pre-line" }}>
                    <b>Reason: </b>
                    {data.opened_reason}
                  </Feed.Extra>
                </Feed.Content>
              </Feed.Event>
              {data.closed_on !== null && (
                <React.Fragment>
                  <Divider />
                  <Feed.Event>
                    <Feed.Label>
                      <Icon name="calendar check outline" />
                    </Feed.Label>
                    <Feed.Content>
                      <Feed.Date content={formatMJY(data.closed_on)} />
                      <Feed.Summary>
                        {!data.closed_by.id ? (
                          <>
                            <Icon name="shield" />
                            {" Closed by "}
                            <b>System</b>
                          </>
                        ) : (
                          <>
                            {"Closed by "}
                            <b>
                              {data.closed_by.firstname}{" "}
                              {data.closed_by.lastname}
                            </b>
                          </>
                        )}
                      </Feed.Summary>
                      <Feed.Extra style={{ whiteSpace: "pre-line" }}>
                        <b>Reason: </b>
                        {data.closed_reason}
                      </Feed.Extra>
                    </Feed.Content>
                  </Feed.Event>
                </React.Fragment>
              )}
            </Feed>
          </Card.Content>
          <Card.Content extra>
            {data.closed_on === null && (
              <CloseIssue
                data={data}
                reload={props.reload}
                canClose={state.canClose}
              />
            )}
          </Card.Content>
        </Card>
      </Modal.Content>
      <Modal.Actions>
        <Button
          onClick={() => setState({ ...state, open: false })}
          content="Back"
        />
      </Modal.Actions>
    </Modal>
  );
};

Issue.propTypes = {
  reload: PropTypes.func,
  data: PropTypes.oneOfType([PropTypes.object, PropTypes.bool])
};

export default Issue;

export function shouldRequireMail(issue) {
  // if there's no booking link, it can't have mail sent.
  if (!issue.booking.id) {
    return false;
  }

  switch (issue.category) {
    case "NEWBOOK":
      return true;
    default:
      return false;
  }
}
