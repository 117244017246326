import React from "react";
import { Grid, Icon, Button, Header } from "semantic-ui-react";
import { connect } from "react-redux";
import { undoOne, undoAll, setYear, save } from "redux/Calendar/actions";
import Resync from "./components/Resync";

const margin = {
  style: {
    marginRight: ".25rem"
  }
};

const Menu = props => {
  const { processing, changes, year } = props;
  const { undoAll, undoOne, save, setYear } = props;
  return (
    <Grid columns={3}>
      <Grid.Column>
        <div style={{ textAlign: "left" }}>
          <Resync margin={margin} />
        </div>
      </Grid.Column>
      <Grid.Column>
        <div style={{ textAlign: "center" }}>
          <Button
            basic
            onClick={() => setYear(year - 1)}
            style={{ margin: "0" }}
            icon="step backward"
          />
          <Header
            as="h1"
            style={{
              display: "inline",
              padding: ".5rem",
              marginBottom: "0",
              verticalAlign: "middle"
            }}
            content={year}
          />
          <Button basic onClick={() => setYear(year + 1)} icon="step forward" />
        </div>
      </Grid.Column>
      <Grid.Column>
        <div style={{ textAlign: "right" }}>
          <Button
            disabled={
              processing ||
              changes.filter(change => !change.undone).length === 0
            }
            {...margin}
            onClick={() => undoAll()}
            content="Undo"
            basic
          />
          <Button
            disabled={
              processing ||
              changes.filter(change => !change.undone).length === 0
            }
            {...margin}
            onClick={() => undoOne()}
            content="Undo"
            basic
          />

          <Button
            disabled={
              processing ||
              changes.filter(change => !change.undone).length === 0
            }
            primary
            basic
            onClick={() => save()}
            loading={processing}
            content="Save"
          />
        </div>
      </Grid.Column>
    </Grid>
  );
};

const mapStateToProps = state => ({
  processing: state.Calendar.processing,
  changes: state.Calendar.changes,
  year: state.Calendar.year
});

const mapDispatchToProps = {
  save,
  undoOne,
  undoAll,
  setYear
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Menu);
