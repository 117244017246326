import React, { Component } from "react";
import PropTypes from "prop-types";
import { formatMJY } from "utils/dates";
import options from "fixtures/bookingNotices";
import { Table } from "semantic-ui-react";

export default class History extends Component {
  static propTypes = {
    data: PropTypes.object.isRequired
  };

  state = {
    expanded: false
  };

  toggle = () => this.setState({ expanded: !this.state.expanded });

  render() {
    const { data } = this.props;
    const fn = (a, b) => new Date(b.sent_on) - new Date(a.sent_on);
    const sorted = data.sent_notices.sort(fn);
    if (!sorted.length) {
      return (
        <p>
          <br />
          No mail has been sent.
        </p>
      );
    }
    const visible = [...sorted].splice(0, 5);
    const hidden = [...sorted].splice(5);
    const output = this.state.expanded ? sorted : visible;
    return (
      <React.Fragment>
        <br />
        <br />
        <div style={{ maxHeight: "300px", overflow: "auto" }}>
          <Table celled padded>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell width={4}>Type</Table.HeaderCell>
                <Table.HeaderCell>Email</Table.HeaderCell>
                <Table.HeaderCell width={3}>Sent By</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {output.map((row, key) => (
                <Table.Row key={key}>
                  <Table.Cell>
                    {options.find(o => o.value === row.type).text}
                  </Table.Cell>
                  <Table.Cell>{row.sent_to}</Table.Cell>
                  <Table.Cell>
                    <b>
                      {row.sent_by.firstname} {row.sent_by.lastname[0]}
                    </b>
                    <br />
                    {formatMJY(row.sent_on)}
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        </div>
        <br />
        {hidden.length > 0 && (
          <a href={null} onClick={this.toggle}>
            {this.state.expanded
              ? "Show Less"
              : "Show " + hidden.length + " more"}
          </a>
        )}
        <br />
      </React.Fragment>
    );
  }
}
