export default {
  // initial loading of a unit
  unitLoading: true,
  unitError: false,
  unit: false,

  // what "page" of months to show
  year: new Date().getFullYear(),
  months: [],
  rangeStart: false,
  rangeStartBlackedOut: false,
  rangeEnd: false,
  changes: [],

  // if the calendar is saving/resyncing/etc
  processing: false,

  // if the calendar has been sync'd at least once
  synced: false
};
