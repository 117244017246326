import moment from "moment";

const formatMJY = (date, pad = 1) => {
  if (date) {
    let [y, m, d] = date.split(" ")[0].split("-");
    let months = {
      "01": "Jan",
      "02": "Feb",
      "03": "Mar",
      "04": "Apr",
      "05": "May",
      "06": "Jun",
      "07": "Jul",
      "08": "Aug",
      "09": "Sep",
      "10": "Oct",
      "11": "Nov",
      "12": "Dec"
    };
    m = months[m] || false;
    d = Number(d)
      .toFixed(0)
      .toString()
      .padStart(pad, "0");
    return m ? `${m} ${d}, ${y}` : "unknown";
  } else return date;
};

const dateToYMD = (date) => {
  let d = new Date(date),
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear();
  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;
  return [year, month, day].join("-");
};

/**
 *	Converts milliseconds to an array [hours, minutes, seconds]
 *	@param {Integer} ms
 *  @return {Array}
 */
const msToHMS = (ms) => {
  let s = parseInt((ms / 1000) % 60, 10);
  let m = parseInt((ms / (1000 * 60)) % 60, 10);
  let h = parseInt(ms / (1000 * 60 * 60), 10);
  h = h < 10 ? "0" + h : h;
  m = m < 10 ? "0" + m : m;
  s = s < 10 ? "0" + s : s;
  return [h, m, s];
};

const getDates = (startDate, stopDate) => {
  let dateArray = [];
  let currentDate = moment(startDate);
  stopDate = moment(stopDate);
  while (currentDate <= stopDate) {
    dateArray.push(moment(currentDate).format("YYYY-MM-DD"));
    currentDate = moment(currentDate).add(1, "days");
  }
  return dateArray;
};

export { formatMJY, dateToYMD, msToHMS, getDates };
