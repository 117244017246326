import React, { Component } from "react";
import PropTypes from "prop-types";

import { Header, Grid } from "semantic-ui-react";

import AsyncForm from "components/AsyncForm";
import membershipLevels from "fixtures/membershipLevels";
import payrates from "fixtures/payrates";
import issuerCompanies from "fixtures/issuerCompanies";

export default class SummaryForm extends Component {
  static propTypes = {
    membership: PropTypes.object.isRequired,
    reloadMembership: PropTypes.func.isRequired
  };

  render() {
    const { membership, reloadMembership } = this.props;

    const config = {
      reload: reloadMembership,
      failure: window.toastr.error,
      dataFn: (source, value) => ({
        url: "/apis/portal/memberships",
        data: {
          action: "update",
          data: {
            ID: membership.id,
            [source]: typeof value === "boolean" ? (value ? 1 : 0) : value
          }
        }
      })
    };

    return (
      <AsyncForm {...config}>
        {({ functions }) => {
          return (
            <Grid columns="3" stackable>
              <Grid.Row>
                <Grid.Column>
                  <Header icon="id badge outline" content="Account Details" />
                  {functions.input({
                    field: "account_number",
                    value: membership.account_number,
                    props: {
                      label: "Account Number",
                      placeholder: "000000"
                    }
                  })}
                  {functions.input({
                    field: "guest_passes",
                    value: membership.guest_passes || "0",
                    props: {
                      label: "Guest Passes",
                      placeholder: "Guest Passes",
                      type: "number"
                    }
                  })}
                  {functions.input({
                    field: "reservation_passes",
                    value: membership.reservation_passes || "0",
                    props: {
                      label: "Reservation Passes",
                      placeholder: "Reservation Passes",
                      type: "number"
                    }
                  })}
                  {functions.dropdown({
                    field: "shared",
                    value: membership.shared,
                    props: {
                      label: "Shared Account",
                      placeholder: "Select one...",
                      options: [
                        { value: 0, text: "No" },
                        { value: 1, text: "Yes" },
                        { value: 2, text: "Unknown" }
                      ]
                    }
                  })}
                </Grid.Column>
                <Grid.Column>
                  <Header icon="building outline" content="Resort Info" />
                  {functions.input({
                    field: "resort_company",
                    value: membership.resort_company.name,
                    props: {
                      label: "Company",
                      placeholder: "Select one...",
                      icon: "lock",
                      readOnly: true
                    }
                  })}
                  {functions.dropdown({
                    field: "level",
                    value: membership.level,
                    props: {
                      label: "VIP Level",
                      placeholder: "Select one...",
                      options: membershipLevels
                    }
                  })}
                  {functions.input({
                    field: "home_resort_name",
                    value: membership.home_resort_name,
                    props: {
                      label: "Home Resort",
                      placeholder: "Home Resort",
                      icon: "building outline"
                    }
                  })}
                </Grid.Column>
                <Grid.Column>
                  <Header icon="cubes" content="Miscellaneous" />
                  {functions.dropdown({
                    field: "issuer_company",
                    value: membership.issuer_company,
                    props: {
                      label: "Issued By",
                      placeholder: "Select one...",
                      options: issuerCompanies
                    }
                  })}
                  {functions.dropdown({
                    field: "has_resigned",
                    value: membership.has_resigned,
                    props: {
                      label: "Contract Renewed",
                      placeholder: "Select one...",
                      options: [
                        { value: false, text: "No" },
                        { value: true, text: "Yes" }
                      ]
                    }
                  })}
                  {functions.dropdown({
                    field: "payrate",
                    value: membership.payrate,
                    props: {
                      label: "Payrate",
                      placeholder: "Select one...",
                      options: [...payrates].splice(1)
                    }
                  })}
                </Grid.Column>
              </Grid.Row>
            </Grid>
          );
        }}
      </AsyncForm>
    );
  }
}
