import React, { Component } from "react";
import { Input, Button } from "semantic-ui-react";
import { InstanceTypes } from "../../../../types";
import propTypes from "prop-types";
import moment from "moment";
const inputStyle = {
  width: "50px"
};
/**
 * @prop config
 * @prop value
 * @prop setTime
 */
export default class TimeForm extends Component {
  static propTypes = {
    value: propTypes.instanceOf(moment).isRequired,
    setTime: propTypes.func.isRequired,
    config: InstanceTypes.config
  };
  handleHourScroll = e => this.scroll(e, "hour");
  handleMinuteScroll = e => this.scroll(e, "minute");
  handleSecondScroll = e => this.scroll(e, "second");
  scroll = (e, type) => {
    const { value, setTime } = this.props;
    // We'll let moment do all the hard work for incrementing/decrementing hours/minutes/seconds
    const newDateTime = moment(value);
    if (e.deltaY <= 0) {
      newDateTime.add(1, type);
    } else {
      newDateTime.subtract(1, type);
    }
    // But we don't want to modify the date portion of the datetime, so we only take the time data
    const newTime = moment(value)
      .hours(newDateTime.hour())
      .minutes(newDateTime.minutes())
      .seconds(newDateTime.seconds());
    setTime(newTime);
  };
  handleHourChange = (e, { value }) => {
    const { value: currentvalue, setTime } = this.props;
    const numericvalue = Number(value);
    if (numericvalue >= 1 && numericvalue <= 12) {
      const newvalue = moment(currentvalue).hour(numericvalue);
      setTime(newvalue);
    }
  };
  handleMinuteChange = (e, { value }) => {
    const { value: currentvalue, setTime } = this.props;
    const numericvalue = Number(value);
    if (numericvalue >= 0 && numericvalue <= 59) {
      const newvalue = moment(currentvalue).minute(numericvalue);
      setTime(newvalue);
    }
  };
  handleSecondChange = (e, { value }) => {
    const { value: currentvalue, setTime } = this.props;
    const numericvalue = Number(value);
    if (numericvalue >= 0 && numericvalue <= 59) {
      const newvalue = moment(currentvalue).second(numericvalue);
      setTime(newvalue);
    }
  };
  handleFocus = e => e.target.select();

  handleAMClick = () => {
    const { value, setTime } = this.props;
    if (value.hour() > 12) {
      value.subtract(12, "hour");
    }
    setTime(value);
  };
  handlePMClick = () => {
    const { value, setTime } = this.props;
    if (value.hour() <= 12) {
      value.add(12, "hour");
    }
    setTime(value);
  };

  render() {
    const { value } = this.props;
    const showingAM = value.format("a") === "am" ? true : false;
    return (
      <div style={{ textAlign: "center" }}>
        <span>
          <Input
            style={inputStyle}
            onWheel={this.handleHourScroll}
            value={value.format("hh")}
            onChange={this.handleHourChange}
            onFocus={this.handleFocus}
          />
          <b>{" : "}</b>
        </span>
        <span>
          <Input
            style={inputStyle}
            onWheel={this.handleMinuteScroll}
            value={value.format("mm")}
            onChange={this.handleMinuteChange}
            onFocus={this.handleFocus}
          />
          <b>{" : "}</b>
        </span>
        <span>
          <Input
            style={inputStyle}
            onWheel={this.handleSecondScroll}
            value={value.format("ss")}
            onChange={this.handleSecondChange}
            onFocus={this.handleFocus}
          />
        </span>{" "}
        <Button primary={showingAM} onClick={this.handleAMClick} content="AM" />
        <Button
          primary={!showingAM}
          onClick={this.handlePMClick}
          content="PM"
        />
      </div>
    );
  }
}
