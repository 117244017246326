import moment from "moment";

/**
 * Calculate the Monthly payment, Total Interest and the Total Cost
 * @param {String} amount the amount to base interest off of3
 * @param {String} term the term length in months
 * @param {String} rate the interest rate
 * @example interest("4795", "30", "18.5");
 */
export const calculate_interest = (amount, term, rate) => {
  // Convert interest from a percentage to a decimal, and convert from
  // an annual rate to a monthly rate. Convert payment period in years
  // to the number of monthly payments
  const priciple = Number(amount);
  const interest = Number(rate) / 100 / 12;
  const payments = Number(term);

  // compute monthly payment
  const x = Math.pow(1 + interest, payments);
  const monthly = (priciple * x * interest) / (x - 1);

  if (isFinite(monthly)) {
    return {
      monthly_payment: monthly,
      finance_charge: monthly * payments - priciple,
      total_payment: monthly * payments
    };
  }
  return Error;
};

/**
 * Throw-away function to quickly calculate the amount financned after a down payment
 * @param {String} base
 * @param {String} downpayment
 */
export const calculate_financed = (base, downpayment) => {
  // Subtracting the downpayment from the base amount
  return Number(base) - Number(downpayment);
};

/**
 *
 * @param {String} balance
 * @param {String} months
 * @param {String} rate interest rate
 * @param {String} date should be a ISO format moment string
 * @example payments("4795", "30", "20.9", "2014-09-08T08:02:17-05:00");
 */
export const calculate_payments = (balance, months, rate, date) => {
  months = Number(months);
  let payments = [];

  const monthly = calculate_interest(balance, months, rate).monthly_payment;

  // function to get interest per payment
  const intr = (balance, rate, term) => {
    // balance = loan amount - downpayment
    // rate = interest percentage i.e 20.9
    // term = months of payments
    return (balance * (rate / 100) * (term / 12)) / term;
  };

  for (let i = 0; i < months; i++) {
    let starting_balance = starting_balance || Number(balance);
    let monthly_payment = monthly;
    let principle = 0;
    let interest = 0;
    let ending_balance = 0;

    interest = intr(starting_balance, rate, months);
    principle = monthly_payment - interest;
    ending_balance = starting_balance - principle;

    payments.push({
      number: i + 1,
      payment_date: moment(date)
        .clone()
        .add(i, "M")
        .format(),
      starting_balance,
      monthly_payment,
      principle,
      interest,
      ending_balance
    });

    starting_balance = ending_balance;
  }

  return payments;
};
