import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";

import { Container } from "semantic-ui-react";

import Agreement from "components/Agreement";
import DealSheet from "components/DealSheet";
import Layout from "components/Layout";
import AgreementLoader from "./components/AgreementLoader";
import { InitialBar, LoadedBar } from "./components/Breadcrumbs";
import Header from "./components/Header";
import remap from "./remap.js";

export default class AgreementLegacyPage extends Component {
  static propTypes = {
    match: PropTypes.object.isRequired
  };

  state = {
    showing: "agreement"
  };

  /**
   * Toggle between viewing dealsheet or agreement
   */
  handleVisibilityChange = () => {
    this.setState({
      showing: this.state.showing === "agreement" ? "dealsheet" : "agreement"
    });
  };

  /**
   * Triggers a reload
   */
  handleReload = () => {
    if (this.loader && this.loader.ajaxComponent) {
      this.loader.ajaxComponent.reload();
    }
  };

  render() {
    const { id } = this.props.match.params;
    const { showing } = this.state;
    const { handleVisibilityChange, handleReload } = this;

    return (
      <Layout noBar>
        <InitialBar />
        <br />
        <Container>
          <AgreementLoader ID={id} ref={r => (this.loader = r)}>
            {({ data }) => {
              // the agreement + dealsheet components must be remapped
              const remapped = remap(data);

              return (
                <Fragment>
                  <LoadedBar data={data} />
                  <Header
                    data={data}
                    showing={showing}
                    handleChange={handleVisibilityChange}
                    reload={handleReload}
                  />
                  {showing === "agreement" ? (
                    <Agreement data={remapped} type="internal" />
                  ) : (
                    <DealSheet data={remapped} legacy />
                  )}
                </Fragment>
              );
            }}
          </AgreementLoader>
        </Container>
      </Layout>
    );
  }
}
