import React, { Component } from "react";
import { Container, Grid } from "semantic-ui-react";

import Layout from "components/Layout";
import ContextBar from "components/ContextBar";
import Emitter from "./components/Emitter";
import Subscriptions from "./components/Subscriptions";

export default class SocketryTest extends Component {
  render() {
    return (
      <Layout noBar>
        <ContextBar />
        <Container>
          <br />
          <Grid columns="2">
            <Grid.Column>
              <Emitter />
            </Grid.Column>
            <Grid.Column>
              <Subscriptions />
            </Grid.Column>
          </Grid>
        </Container>
      </Layout>
    );
  }
}
