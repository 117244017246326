import React from "react";
import { Comment, Icon } from "semantic-ui-react";
import AjaxFn from "services/AjaxFn";
import { connect } from "react-redux";
class Heart extends React.Component {
  state = {
    loading: false,
    vote: false
  };
  componentDidMount() {
    const { user, comment } = this.props;
    const vote = comment.votes[user.id] || false;
    this.setState({ vote });
  }
  getVotes = () => {
    const { comment, user } = this.props;
    let likes = 0;
    const votes = { ...comment.votes };
    votes[user.id] = this.state.vote;
    for (var k in votes) {
      if (votes[k]) {
        likes++;
      }
    }
    switch (likes) {
      case 1:
        return "1 like";
      case 0:
        return "0 likes";
      default:
        return likes + " likes";
    }
  };
  handleClick = () => {
    this.setState({ loading: true });
    AjaxFn({
      url: "/apis/portal/comments",
      data: {
        action: "vote",
        data: {
          ID: this.props.comment.id,
          vote: this.state.vote ? 0 : 1
        }
      },
      success: message => {
        this.setState({ vote: !this.state.vote, loading: false });
      },
      failure: error => {
        window.toastr.error(error);
        this.setState({ loading: false });
      }
    });
  };
  render() {
    // const { comment, user } = this.props;
    if (this.state.loading) {
      return <Comment.Action>Processing...</Comment.Action>;
    }
    return (
      <Comment.Action as={"a"} href={null} onClick={this.handleClick}>
        <Icon name={"heart"} color={this.state.vote ? "red" : undefined} />
        {this.getVotes()}
      </Comment.Action>
    );
  }
}
const mapStateToProps = state => ({
  user: state.User
});
export default connect(mapStateToProps)(Heart);
