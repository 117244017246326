import React from "react";
import PropTypes from "prop-types";
import moment from "moment";

import { Segment, Dropdown, Form } from "semantic-ui-react";
import { MyCalWrapper } from "components/MyCal";

const dateTypeOptions = [
  { value: "any", text: "(unfiltered)" },
  { value: "created_on", text: "created" },
  { value: "sent_on", text: "sent" },
  { value: "received_on", text: "submitted" },
  { value: "processed_on", text: "processed" }
];

const Date = props => {
  const { update, applied } = props;
  return (
    <Segment secondary>
      <Form.Field>
        <label>
          <span style={{ marginRight: ".5rem" }}>Filter by date</span>
          <Dropdown
            options={dateTypeOptions}
            value={applied.dateType}
            onChange={(undefined, { value }) => update("dateType", value)}
          />
        </label>
      </Form.Field>
      {applied.dateType !== "any" && (
        <Form.Group widths="equal">
          <Form.Field>
            <label>From</label>
            <MyCalWrapper
              value={applied.dateStart}
              onChange={(undefined, { value }) => update("dateStart", value)}
              type="date"
              format={null}
              maxDate={applied.dateEnd}
              fluid
            />
          </Form.Field>
          <Form.Field>
            <label>To</label>
            <MyCalWrapper
              value={applied.dateEnd}
              onChange={(undefined, { value }) => update("dateEnd", value)}
              type="date"
              format={null}
              minDate={applied.dateStart}
              maxDate={moment()}
              fluid
            />
          </Form.Field>
        </Form.Group>
      )}
    </Segment>
  );
};

Date.propTypes = {
  update: PropTypes.func.isRequired,
  applied: PropTypes.object.isRequired
};

export default Date;
