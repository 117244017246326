import React from "react";
import { Message, Icon } from "semantic-ui-react";

export default () => (
  <Message icon>
    <Icon name={"lock"} />
    <Message.Content>
      <Message.Header>Your information is kept private</Message.Header>
      <p>
        All of it, including any potential payment or contact information, is
        transmitted securely via{" "}
        <a href="https://www.websecurity.symantec.com/security-topics/what-is-ssl-tls-https">
          SSL
        </a>.
      </p>
    </Message.Content>
  </Message>
);
