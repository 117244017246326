import React, { Component } from "react";
import propTypes from "prop-types";
import { Button } from "semantic-ui-react";
import AjaxFn from "services/AjaxFn";

export default class InvalidateAudit extends Component {
  static propTypes = {
    reload: propTypes.func,
    data: propTypes.object
  };

  state = {
    loading: false
  };

  handleClick = () => {
    this.setState({ loading: true });
    AjaxFn({
      url: "/apis/portal/bookingaudits",
      data: {
        action: "invalidate",
        data: {
          ID: this.props.data.audits.find(row => row.invalidated_on === null).id
        }
      },
      success: () => {
        window.toastr.success();
        this.props.reload();
      },
      failure: error => {
        window.toastr.error(error);
        this.setState({ loading: false });
      }
    });
  };

  render() {
    return (
      <div>
        <p>
          There is currently an active audit for this booking. Click the button
          below to cancel it.
        </p>
        <Button
          loading={this.state.loading}
          disabled={this.state.loading}
          onClick={this.handleClick}
          content="Cancel Audit"
          basic
          color="red"
        />
        <br />
        <br />
        <p>
          Cancelling the audit will close all of the booking-related audit
          issues that the system opened, if they have not yet been resolved. You
          will be able to start a new audit after cancelling it.
        </p>
      </div>
    );
  }
}
