import React from "react";
import { Message, Dropdown } from "semantic-ui-react";
import { Ajax } from "services/Ajax";
// disable eslint since data/method in normalProps will always throw
/* eslint no-unused-vars: 0 */
/**
 * @prop url
 * @prop data (optional, ajax payload, function/object)
 * @prop method (optional, ajax method, "get" or "post")
 * @prop validator (optional, ajax response validator, function)
 * @prop dataRetreiver (optional, ajax payload retreiver, function)
 * @prop errorRetreiver (optional, ajax error msg retreiver, function)
 */
export default class AjaxDropdown extends React.Component {
  componentWillUnmount() {
    if (this.AJAX && this.AJAX.requestPending()) {
      this.AJAX.cancelPendingRequest();
    }
  }
  state = {
    loading: false,
    error: false,
    data: []
  };
  // validates ajax payload is okay
  validator = d => d.status;
  // retreives payload from ajax call
  dataRetreiver = d => d.data;
  // retreives the error message from ajax call
  errorRetreiver = d => d.error || "Connection error";
  // handles the ajax success state
  onSuccess = data =>
    this.setState({
      loading: false,
      error: false,
      data: this.dataRetreiver(data)
    });
  // handles the ajax error state
  onError = data =>
    this.setState({
      loading: false,
      error: this.errorRetreiver(data),
      data: []
    });
  // initializes the AJAX class and loads it
  componentWillMount() {
    const {
      url,
      data = {},
      method = "post",
      validator,
      dataRetreiver,
      errorRetreiver,
      ...rest
    } = this.props;
    this.AJAX = new Ajax({ url, data, method });
    this.rest = rest;
    // we already have defaults if these are undefined
    if (validator) this.validator = validator;
    if (dataRetreiver) this.dataRetreiver = dataRetreiver;
    if (errorRetreiver) this.errorRetreiver = errorRetreiver;
    this.reload();
  }
  // return loading state props for the dropdown
  loadingProps = () => ({
    icon: "spinner",
    className: "loading icon disabled",
    floating: true,
    button: true,
    children: <Dropdown.Menu />
  });
  // return error state props to pass to the dropdown
  errorProps = () => ({
    icon: "exclamation triangle",
    className: "red icon",
    floating: true,
    button: true,
    children: (
      <Dropdown.Menu>
        <Message error>
          <Message.Header>Error</Message.Header>
          <p>{this.state.error}</p>
          <b onClick={this.reload} style={{ cursor: "Pointer" }}>
            <u>Click here to retry</u>
          </b>
        </Message>
      </Dropdown.Menu>
    )
  });
  // return success state props to pass to the dropdown
  normalProps = () => {
    const {
      url,
      data = {},
      method = "post",
      validator,
      dataRetreiver,
      errorRetreiver,
      ...rest
    } = this.props;
    return {
      options: [...this.state.data],
      search: true,
      selection: true,
      selectOnBlur: false,
      ...rest
    };
  };
  // forces an ajax refresh
  reload = () => {
    this.setState(
      {
        loading: true,
        error: false,
        data: []
      },
      () => this.AJAX.reload(this.validator, this.onSuccess, this.onError)
    );
  };
  render() {
    const { loading, error } = this.state;
    if (loading) {
      return <Dropdown fluid {...this.loadingProps()} />;
    } else if (error) {
      return <Dropdown fluid {...this.errorProps()} />;
    } else {
      return <Dropdown fluid {...this.normalProps()} />;
    }
  }
}
