import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { Form, Icon } from "semantic-ui-react";

import trueFalse from "fixtures/trueFalse";

class Timeshares extends Component {
	constructor(props) {
		super(props);
		this.state = {
			disableAddButton: false,
			disableRemoveButton: false
		};
	}

	componentDidUpdate(prevProps) {
		if (this.props.timeshares !== prevProps.timeshares) {
			this.disableRemoveButton();
			this.disableAddButton();
		}
	}

	disableAddButton = () => {
		const { timeshares } = this.props;
		if (Array.isArray(timeshares) && timeshares.length >= 5) {
			return this.setState({ disableAddButton: true });
		}
		return this.setState({ disableAddButton: false });
	};

	disableRemoveButton = () => {
		const { timeshares } = this.props;
		if (Array.isArray(timeshares) && timeshares.length <= 1) {
			return this.setState({ disableRemoveButton: true });
		}
		return this.setState({ disableRemoveButton: false });
	};

	render() {
		const {
			handleChange,
			handleTimeshareAddition,
			handleTimeshareRemoval,
			timeshares
		} = this.props;

		const { disableAddButton, disableRemoveButton } = this.state;

		if (timeshares) {
			return (
				<Fragment>
					{timeshares.map((ts, i) => {
						return (
							<Form.Group key={i} widths="equal">
								<Form.Input
									label="Number of Contracts"
									name="contracts"
									id="contracts"
									index={i}
									onChange={handleChange}
									value={ts.contracts}
									autoComplete="off"
									placeholder="Contracts"
									// required
								/>
								<Form.Input
									label={`Developer ${i + 1} Name`}
									name="developer_name"
									id="developer_name"
									index={i}
									onChange={handleChange}
									value={ts.developer_name}
									autoComplete="off"
									placeholder="Developers"
									// required
								/>
								<Form.Select
									label="Outstanding Balance?"
									name="outstanding_balance"
									id="outstanding_balance"
									index={i}
									onChange={handleChange}
									options={trueFalse}
									value={ts.outstanding_balance}
									placeholder="Select one..."
									// required
								/>
								<Form.Input
									label="Contract Number(s)"
									name="contract_numbers"
									id="contract_numbers"
									index={i}
									onChange={handleChange}
									value={ts.contract_numbers}
									autoComplete="off"
									placeholder="Contract Numbers"
									// required
								/>
								<Form.Button
									type="button"
									label="&nbsp;"
									value={i}
									color="red"
									basic
									onClick={handleTimeshareRemoval}
									disabled={disableRemoveButton}
									fluid
								>
									<Icon name="close" /> Remove
								</Form.Button>
							</Form.Group>
						);
					})}
					<Form.Button
						type="button"
						primary
						basic
						onClick={handleTimeshareAddition}
						disabled={disableAddButton}
					>
						Add Additional Timeshare
					</Form.Button>
				</Fragment>
			);
		}
		return (
			<Form.Button
				type="button"
				onClick={handleTimeshareAddition}
				disabled={disableAddButton}
			>
				Add Additional Timeshare
			</Form.Button>
		);
	}
}

Timeshares.propTypes = {
	timeshares: PropTypes.array,
	handleChange: PropTypes.func.isRequired,
	handleTimeshareAddition: PropTypes.func.isRequired,
	handleTimeshareRemoval: PropTypes.func.isRequired
};

Timeshares.defaultProps = {
	timeshares: [
		{
			contracts: "",
			developer_name: "",
			outstanding_balance: false,
			contract_numbers: ""
		}
	]
};

export default Timeshares;
