import React, { Fragment } from "react";
import { Form } from "semantic-ui-react";
export default ({ context }) => {
  const {
    functions: { createInput }
  } = context;
  return (
    <Fragment>
      <Form.Group widths="equal">
        {createInput(<Form.Input />, "email", {
          name: "email",
          placeholder: "Email",
          label: "Email",
          autoComplete: "off",
          required: true
        })}
        {createInput(<Form.Input />, "primary_phone", {
          name: "primary_phone",
          placeholder: "Primary phone",
          label: "Primary phone",
          autoComplete: "off",
          required: true
        })}
      </Form.Group>
      <Form.Group widths="equal">
        {createInput(<Form.Input />, "work_phone", {
          name: "work_phone",
          placeholder: "Work phone",
          label: "Work phone",
          autoComplete: "off",
          required: true
        })}
        {createInput(<Form.Input />, "cell_phone", {
          name: "cell_phone",
          placeholder: "Cell phone",
          label: "Cell phone",
          autoComplete: "off",
          required: true
        })}
      </Form.Group>
      <Form.Group widths={"equal"}>
        {createInput(<Form.Input />, "address", {
          name: "address",
          label: "Address",
          placeholder: "Address",
          required: true,
          autoComplete: "off"
        })}
        {createInput(<Form.Input />, "city", {
          name: "city",
          label: "City",
          placeholder: "City",
          required: true,
          autoComplete: "off"
        })}
      </Form.Group>
      <Form.Group>
        {createInput(<Form.Input />, "address_2", {
          name: "address_2",
          label: "Address Line 2",
          placeholder: "Address Line 2",
          required: false,
          autoComplete: "off",
          width: 8
        })}
        {createInput(<Form.Input />, "state", {
          name: "state",
          label: "State",
          placeholder: "State",
          required: false,
          autoComplete: "off",
          width: 4
        })}
        {createInput(<Form.Input />, "zip", {
          name: "zip",
          label: "Zipcode",
          placeholder: "Zipcode",
          required: false,
          autoComplete: "off",
          width: 4
        })}
      </Form.Group>
    </Fragment>
  );
};
