import React, { Component } from "react";
import { Input } from "semantic-ui-react";
import propTypes from "prop-types";
import { InstanceTypes } from "../../../../../../types";
/**
 * @prop year
 * @prop setYear
//  * @prop config
 */
export default class Year extends Component {
	static propTypes = {
		year: propTypes.string.isRequired,
		setYear: propTypes.func.isRequired
		// config: InstanceTypes.config
	};
	handleScroll = e => {
		const { year, setYear } = this.props;
		const modifier = e.deltaY <= 0 ? 1 : -1;
		let newyear = Number(year) + modifier;
		setYear(newyear.toString());
	};
	render() {
		const { year, setYear } = this.props;
		return (
			<Input
				fluid
				value={year}
				type="number"
				onChange={(e, { value }) => setYear(value)}
				onWheel={this.handleScroll}
			/>
		);
	}
}
