import { Component } from "react";
import { connect } from "react-redux";
import { resync } from "redux/Storage/actions";

class SubscribeToStorage extends Component {
  componentDidMount() {
    window.addEventListener("storage", this.localStorageUpdated, false);
  }
  componentWillUnmount() {
    window.removeEventListener("storage", this.localStorageUpdated);
  }

  localStorageUpdated = e => this.props.resync(e);

  render() {
    return null;
  }
}

const mapDispatchToProps = {
  resync
};

export default connect(
  undefined,
  mapDispatchToProps
)(SubscribeToStorage);
