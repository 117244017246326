import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { Button, Modal, Form } from "semantic-ui-react";
import AjaxFn from "services/AjaxFn";
// import AniModal from "components/AniModal";

export default class CreateListing extends Component {
  static propTypes = {
    unit: PropTypes.object.isRequired,
    reload: PropTypes.func.isRequired
  };

  state = {
    open: false,
    loading: false,
    name: ""
  };

  openModal = () => {
    this.setState({
      open: true,
      name: ""
    });
  };

  closeModal = () => {
    if (!this.state.loading) {
      this.setState({
        open: false
      });
    }
  };

  /**
   * Handle updating the value of this.state.name
   */
  handleChange = (e, { value }) => this.setState({ name: value });

  /**
   * Return whether or not the user can perform the save action
   * @return {Bool}
   */
  canSave = () => {
    return !this.state.loading && this.state.name.trim() !== "";
  };

  /**
   * Handle the submission of the form (or click on the submit btn)
   * @param {Event}
   */
  handleSubmit = e => {
    e.preventDefault();
    if (this.canSave()) {
      this.setState({ loading: true });
      AjaxFn({
        url: "/apis/portal/unittypeciirus",
        data: {
          action: "create",
          data: {
            name: this.state.name,
            unit: this.props.unit.id
          }
        },
        success: () => {
          window.toastr.success();
          this.setState({ loading: false }, this.closeModal);
          setTimeout(this.props.reload, 200);
        },
        failure: error => {
          window.toastr.error(error);
          this.setState({ loading: false });
        }
      });
    }
  };

  render() {
    return (
      <Fragment>
        <Button
          fluid
          primary
          basic
          onClick={this.openModal}
          content="New CiiRUS Listing"
        />
        <Modal
          onClose={this.closeModal}
          open={this.state.open}
          size="tiny"
          transition="scale"
        >
          <Modal.Header>New CiiRUS Listing</Modal.Header>
          <Modal.Content>
            <p>
              Enter the name that the listing will have in CiiRUS. After you
              submit it, you will be able to find the listing on the unit page
              and add additional info to the listing.
            </p>
            <Form onSubmit={this.handleSubmit}>
              <Form.Input
                label="CiiRUS Name"
                placeholder="CiiRUS Name"
                value={this.state.name}
                onChange={this.handleChange}
                autoComplete="off"
                disabled={this.state.loading}
                required
              />
            </Form>
          </Modal.Content>
          <Modal.Actions>
            <Button
              onClick={this.closeModal}
              disabled={this.state.loading}
              content="Close"
            />
            <Button
              primary
              onClick={this.handleSubmit}
              loading={this.state.loading}
              disabled={!this.canSave()}
              content="Submit"
            />
          </Modal.Actions>
        </Modal>
      </Fragment>
    );
  }
}
