import React, { Fragment, useState, useContext } from "react";
import ButtonElement from "../ButtonElement";
import DrawerPortalComponent from "../DrawerPortalComponent";
import { useOnMountEffect } from "effects";
import { Context } from "../../";

const DrawerElement = props => {
  // setup all required hooks
  const [open, setOpen] = useState(false);
  const [node, setNode] = useState(false);
  const context = useContext(Context);
  useOnMountEffect(() => {
    const foundNode = document.getElementById("drawer-display");
    setNode(foundNode);
  });

  const {
    autoclose = true,
    backbutton = true,
    children,
    content = "Open Drawer",
    icon = "fas fa-bars",
    overlay = true,
    title = "Open Drawer",
    ...restOfProps
  } = props;

  const drawerProps = {
    basic: open ? false : true,
    color: open ? "blue" : undefined,
    content,
    drawer: true,
    icon,
    onClick: () => setOpen(!open),
    title,
    ...restOfProps
  };

  const portalProps = {
    autoclose,
    backbutton,
    children,
    expanded: context.state.expanded,
    handleClose: () => setOpen(false),
    node,
    open,
    overlay
  };

  return (
    <Fragment>
      <ButtonElement {...drawerProps} />
      <DrawerPortalComponent {...portalProps} />
    </Fragment>
  );
};

export default DrawerElement;
