import React from "react";
import { connect } from "react-redux";
import injectSheet from "react-jss";
import Layout from "components/Layout";
import { Container, Header, Icon, Grid } from "semantic-ui-react";
import Breadcrumb from "./components/Breadcrumb";
import EOQMenu from "./components/EOQMenu";
import EOQTable from "./components/EOQTable";
import EOQSummary from "./components/EOQSummary";
import EOQSuccess from "./components/EOQSuccess";

class EndofQuarterPage extends React.Component {
  render() {
    return (
      <Layout noBar>
        <Breadcrumb />
        <Container style={{ paddingBottom: "4rem" }}>
          <Header as={"h1"} dividing style={{ marginTop: "2rem" }}>
            <Icon name={"chart bar"} />
            <Header.Content>
              EOQ Report
              <Header.Subheader>End of Quarter financials</Header.Subheader>
            </Header.Content>
          </Header>
          <Grid>
            <Grid.Column width={11}>
              <EOQMenu />
              <EOQTable />
            </Grid.Column>
            <Grid.Column width={5}>
              <EOQSummary />
            </Grid.Column>
          </Grid>
        </Container>
        <EOQSuccess />
      </Layout>
    );
  }
}

const styles = {
  // ...
};

const mapStateToProps = state => ({
  // ...
});
const mapDispatchToProps = dispatch => ({
  // ...
});

const connected = connect(
  mapStateToProps,
  mapDispatchToProps
)(EndofQuarterPage);
export default injectSheet(styles)(connected);
