import React from "react";
import { Form } from "semantic-ui-react";

import { FiltersModal } from "components/PortalTable/parts";
import AjaxDropdown from "components/AjaxDropdown";
import naturalSort from "utils/naturalSort";

export default ({ namespace }) => (
  <FiltersModal
    namespace={namespace}
    filters={{
      visibility: "any",
      processed: "any",
      bank: "any"
    }}
    buildFilters={applied => {
      const functions = [];

      functions.push(check => handleVisibility(check, applied.visibility));
      functions.push(check => handleProcessed(check, applied.processed));
      functions.push(check => handleBank(check, applied.bank));

      return functions;
    }}
    children={(applied, update) => (
      <Form>
        <Form.Dropdown
          label="Visibility"
          selection
          placeholder="Select one..."
          onChange={(e, { value }) => update("visibility", value)}
          value={applied.visibility}
          options={[
            { value: "admin", text: "Admin Only" },
            { value: "accounting", text: "Accounting or Admin Only" },
            { value: "eoq", text: "EOQ Checks Only" },
            { value: "any", text: "Standard (unfiltered)" }
          ]}
        />
        <Form.Dropdown
          label="Processed"
          selection
          placeholder="Select one..."
          onChange={(e, { value }) => update("processed", value)}
          value={applied.processed}
          options={[
            { value: true, text: "Processed Only" },
            { value: false, text: "Unprocessed Only" },
            { value: "any", text: "Any" }
          ]}
        />
        <Form.Field>
          <label>Bank</label>
          <AjaxDropdown
            url={"/apis/portal/banks"}
            data={{ action: "read_for_dropdown" }}
            dataRetreiver={({ message }) =>
              [{ value: "any", text: "Any Bank" }].concat(
                message
                  .sort((a, b) => naturalSort(a.portal_name, b.portal_name))
                  .map(row => ({
                    value: row.id,
                    text: row.portal_name
                  }))
              )
            }
            selection
            placeholder="Bank"
            onChange={(e, { value }) => update("bank", value)}
            value={applied.bank}
          />
        </Form.Field>
      </Form>
    )}
  />
);

/**
 * @param {Object} check
 * @param {String|null} option
 */
function handleVisibility(check, option) {
  switch (option) {
    case "admin":
      return check.visibility === "admin";
    case "accounting":
      return check.visibility === "admin" || check.visibility === "accounting";
    case "eoq":
      return !!check.bookings.length;
    default:
      return true;
  }
}

/**
 * @param {Object} check
 * @param {String} option
 */
function handleProcessed(check, option) {
  return option === "any" ? true : check.processed === option;
}

/**
 * @param {Object} check
 * @param {String} option
 */
function handleBank(check, option) {
  return option === "any" ? true : check.bank.id === option;
}
