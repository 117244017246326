import smartSearch from "../../utils/smartSearch";

export const getHighestCheckNumber = (bankID, banks) => {
  const bank = banks.find(row => row.id === bankID);
  if (bank.checks.length) {
    const asArray = bank.checks.map(check => {
      return check.number;
    });

    return Math.max.apply(Math, asArray) + 1;
  }

  return 1;
};

/**
 *	Gets and returns searched data
 *	@param {Array} rows
 *	@param {String} string
 *	@return {Array}
 */
export const searchData = (rows, string) => {
  const searchable = [1, 1, 0, 0];
  return rows.filter(row => {
    try {
      row.forEach((col, index) => {
        if (searchable[index]) {
          const entry = [{ col }];
          const props = { col: true };
          const options = { maxInsertions: 4 };
          const result = window.smartSearch(entry, string, props, options)
            .length;
          // error in try/catch to break forEach
          if (result) throw new Error();
        }
      });
    } catch (e) {
      return true;
    }
    return false;
  });
};

/**
 *	Gets and returns paginated rows
 *	@param {Array} rows
 *	@param {Integer} per_page (the max rows to per_page)
 *	@param {Integer} page_num (the current page #)
 *	@return {Array}
 */
export const paginateData = (rows, per_page, page_num) => {
  return [...rows].splice(per_page * (page_num - 1), per_page);
};
