import React from "react";
// import PropTypes from "prop-types";
import { Link } from "react-router-dom";

import { Breadcrumb } from "semantic-ui-react";

import ContextBar from "components/ContextBar";

const BreadcrumbComponent = props => {
  return (
    <ContextBar>
      <Breadcrumb>
        <Breadcrumb.Section>
          <Link to={"/"}>Portal</Link>
        </Breadcrumb.Section>
        <Breadcrumb.Divider>/</Breadcrumb.Divider>
        <Breadcrumb.Section>
          <Link to={"/browse/agreements"}>Agreements</Link>
        </Breadcrumb.Section>
        <Breadcrumb.Divider>/</Breadcrumb.Divider>
        <Breadcrumb.Section active>Wizard</Breadcrumb.Section>
      </Breadcrumb>
    </ContextBar>
  );
};

// BreadcrumbComponent.propTypes = {};

export default BreadcrumbComponent;
