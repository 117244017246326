import AjaxFn from "services/AjaxFn";
import { timerestrict_booking } from "utils/user";
export const setBookingLoading = () => ({
  type: "BOOKPAGE_LOADING"
});
export const setBookingError = data => ({
  type: "BOOKPAGE_ERROR",
  data
});
export const setBookingData = data => ({
  type: "BOOKPAGE_DATA",
  data
});
export const reloadBooking = ID => (dispatch, getState) => {
  if (typeof ID !== "string" && typeof ID !== "number") {
    ID = getState().BookingPage.booking.data.id;
  }
  dispatch(setBookingLoading());
  AjaxFn({
    url: "/apis/portal/bookings",
    data: {
      action: "read_one",
      data: {
        ID
      }
    },
    success(message) {
      dispatch(setBookingData(message));
    },
    failure(error) {
      dispatch(setBookingError(error));
    }
  });
};

export const storeChange = (source, value) => (dispatch, getState) => {
  const { User, BookingPage } = getState();
  const booking = BookingPage.booking.data;
  const can_edit = timerestrict_booking(
    booking,
    User,
    ["BOOKINGS_EDIT"],
    ["BOOKINGS_WIZARD"]
  );
  if (can_edit) {
    dispatch({
      type: "BOOKPAGE_STORE_CHANGE",
      source,
      value
    });
  } else {
    window.toastr["info"]("Your account is not allowed to edit bookings.");
  }
};
export const removeChange = source => ({
  type: "BOOKPAGE_REMOVE_CHANGE",
  source
});
export const discardChanges = () => ({
  type: "BOOKPAGE_DISCARD_CHANGES"
});
export const savingChanges = () => ({
  type: "BOOKPAGE_SAVING_CHANGES"
});
export const savedChanges = () => ({
  type: "BOOKPAGE_SAVED_CHANGES"
});
export const saveChanges = () => (dispatch, getState) => {
  dispatch(savingChanges());
  const { changes, booking } = getState().BookingPage;
  AjaxFn({
    url: "/apis/portal/bookings",
    data: {
      action: "update",
      data: {
        ID: booking.data.id,
        ...changes
      }
    },
    success: message => {
      window.toastr.success();
      dispatch(savedChanges());
      dispatch(discardChanges());
      dispatch(reloadBooking(booking.data.id));
    },
    failure: error => {
      dispatch(savedChanges());
      window.toastr.error(error);
    }
  });
};
export const uncancel = ID => dispatch => {
  dispatch(savingChanges());
  AjaxFn({
    url: "/apis/portal/bookings",
    data: {
      action: "uncancel",
      data: {
        ID
      }
    },
    success: message => {
      window.toastr.success();
      dispatch(savedChanges());
      dispatch(reloadBooking());
    },
    failure: error => {
      dispatch(savedChanges());
      window.toastr.error(error);
    }
  });
};
