import React from "react";
import { connect } from "react-redux";
import { Container, Segment, Form } from "semantic-ui-react";
import { user_can } from "utils/user";
import AjaxFn from "services/AjaxFn";

// context
import { Provider } from "./context";

// components
import Title from "./components/Title";
import SSL from "./components/SSL";
import CompanyHeader from "./components/CompanyHeader";
import Legal from "./components/Legal";
import Signature from "./components/Signature";
import CompletionModal from "./components/CompletionModal";
import SubmittedMessage from "./components/SubmittedMessage";
import PointsRelease from "./components/PointsRelease";
import DeedTransfer from "./components/DeedTransfer";
import Generic from "./components/Templates/Generic";
import Finance from "./components/Templates/Finance";

import { Print, PageBreak } from "components/Printing";

// fixtures
import {
  pointsrelease,
  deedtransfer,
  generic,
  finance
} from "./fixtures/defaults";

import {
  cardFields,
  checkFields,
  billingFields,
  externalOnlyFields
} from "./fixtures/fields";

const paymentFields = [...cardFields, ...checkFields, ...billingFields];

/**
 *	@prop data  (object, agreement data)
 *	@prop type  (string "internal", "external", "preview")
 *  @prop token (only required for external, can't submit without it)
 */
class Agreement extends React.Component {
  state = {
    config: getCurrentData(this.props.data),
    type: this.props.type || "external",
    data: {},
    submitting: false,
    completionModalOpen: false
  };

  componentDidMount() {
    const { data } = this.props;

    const formData = JSON.parse(data.data);

    // initial data set
    this.setState({
      data: {
        ...this.getInitialData(),
        ...formData
      }
    });
  }

  /**
   * Get the context to pass to children
   * @return {Object}
   */
  getContext = () => ({
    agreement: this.state.config,
    key: this.props.token || "",
    ...this.state,
    functions: {
      createInput: this.createInput,
      createMultipleInputs: this.createMultipleInputs,
      setData: this.setData,
      startSubmit: this.startSubmit,
      endSubmit: this.endSubmit,
      shouldFieldBeDisabled: this.shouldFieldBeDisabled,
      shouldFieldBeReadOnly: this.shouldFieldBeReadOnly,
      openCompletionModal: this.openCompletionModal,
      closeCompletionModal: this.closeCompletionModal,
      handleSubmit: this.handleSubmit
    }
  });

  /**
   * Get initial data based on agreement type
   * @return {Object}
   */
  getInitialData = () => {
    const {
      config: { template }
    } = this.state;

    switch (template.type) {
      case "deedtransfer":
        return deedtransfer;
      case "pointsrelease":
        return pointsrelease;
      case "generic":
        return generic;
      case "finance":
        return finance;
      default:
        return {};
    }
  };

  /**
   * Get form to display based on agreement type
   * @return {ReactElement}
   */
  getShownForm = () => {
    const {
      config: { template }
    } = this.state;

    switch (template.type) {
      case "deedtransfer":
        return <DeedTransfer />;
      case "pointsrelease":
        return <PointsRelease />;
      case "generic":
        return <Generic />;
      case "finance":
        return <Finance />;
      default:
        throw new Error("Unsupported agreement type");
    }
  };

  /**
   * Get data to submit based on agreement type
   * Returns this.state.data with payment info filtered/removed
   * If they selected card, check fields are omitted
   * If they selected check, card fields are omited
   * If payment isn't required, all payment info is omitted
   * @param {Boolean} required
   * @return {Object}
   */
  getDataToSubmit = type => {
    const { template, payment_required } = this.state.config;
    switch (template.type) {
      case "deedtransfer":
        return this.getFilteredPaymentData(true);
      default:
        return this.getFilteredPaymentData(payment_required);
    }
  };

  /**
   * Returns this.state.data with payment info filtered/removed
   * If they selected card, check fields are omitted
   * If they selected check, card fields are omited
   * If payment isn't required, all payment info is omitted
   * @param {Boolean} required
   * @return {Object}
   */
  getFilteredPaymentData = (required = true) => {
    const { data } = this.state;
    const unset = k => k.forEach(v => delete data[v]);
    if (required === false) {
      unset(cardFields);
      unset(checkFields);
      unset(billingFields);
    } else {
      if (data.payment_method === "card") {
        unset(checkFields);
      } else {
        unset(cardFields);
      }
    }
    return data;
  };

  /**
   *  Determine if an input field should be disabled
   *  @param {String} source
   *	@return {Boolean}
   */
  shouldFieldBeDisabled = (source, isReadOnly) => {
    const { type, user } = this.props;
    const { submitting } = this.state;

    // const isExternalOnly = externalOnlyFields.indexOf(source) !== -1;
    if (submitting) return true;
    if (isReadOnly) return false;
    switch (type) {
      case "preview":
        return source === "signature" || source === "initial";
      case "internal":
        if (paymentFields.includes(source)) {
          return !user_can("AGREEMENTS_EDIT_PAY", user);
        } else {
          return externalOnlyFields.includes(source);
        }
      case "external":
      default:
        return false;
    }
    // ...
  };

  /**
   *  Determine if an input field should be read only
   *  @param {String} source
   *	@return {Boolean}
   */
  shouldFieldBeReadOnly = () => {
    const { type, data } = this.props;
    switch (type) {
      case "internal":
      // fall through
      case "external":
        return !!data.received_on;
      default:
        return false;
    }
    // ...
  };

  /**
   *  creates a handled input element for the form
   *  @param {ReactElement} element
   *  @param {String} source
   *  @param {Object} props
   *	@return {ReactElement}
   */
  createInput = (element, source, props = {}) => {
    const readOnly = this.shouldFieldBeReadOnly(source);
    const disabled = this.shouldFieldBeDisabled(source, readOnly);
    return React.cloneElement(element, {
      disabled,
      readOnly,
      value: this.state.data[source] || "",
      onChange: (e, { value }) => {
        if (!readOnly && !disabled) {
          this.setData(source, value);
        }
      },
      fluid: true,
      name: source,
      id: source,
      ...props
    });
  };

  /**
   * Update a key/value pair in the state
   * @param {String} source
   * @param {Mixed} value
   */
  setData = (source, value) => {
    this.setState({
      data: {
        ...this.state.data,
        [source]: value
      }
    });
  };

  /**
   * Start/End submitting state
   */
  startSubmit = () => this.setState({ submitting: true });
  endSubmit = () => this.setState({ submitting: false });

  /**
   * Open/Close completion modal
   */
  openCompletionModal = () => this.setState({ completionModalOpen: true });
  closeCompletionModal = () => this.setState({ completionModalOpen: false });

  /**
   * Handle submission of form
   * @param {Event} e
   */
  handleSubmit = e => {
    e.preventDefault();
    this.startSubmit();
    AjaxFn({
      url: "/apis/portal/agreements",
      data: {
        action: "submit",
        data: {
          ID: this.props.data.id,
          key: this.props.token,
          data: this.getDataToSubmit()
        }
      },
      success: () => this.openCompletionModal(),
      failure: e => window.toastr.error(e),
      finally: () => this.endSubmit()
    });
  };

  render() {
    const context = this.getContext();

    if (this.state.config.template.type === "finance") {
      return (
        <Provider value={context}>
          <Finance />
        </Provider>
      );
    }

    return (
      <Provider value={context}>
        <Print />
        <Container>
          {context.type === "external" &&
            context.agreement.original_data.received_on && (
              <SubmittedMessage context={context} />
            )}
          <Form onSubmit={this.handleSubmit}>
            <Segment raised>
              <CompanyHeader />
            </Segment>
            <Segment raised>
              <Title
                header={context.agreement.title}
                subheader={"Please review carefully"}
              />
              <Segment vertical>
                <br />
                <div
                  dangerouslySetInnerHTML={{ __html: context.agreement.html }}
                  style={{ padding: "0 1rem" }}
                />
              </Segment>
            </Segment>
            <Segment raised>
              <Title
                header={"Owner/Trustee Info"}
                subheader={"Fields marked with a * are required"}
              />
              <SSL />
              <div style={{ padding: "0 1rem" }}>{this.getShownForm()}</div>
              <PageBreak />
              <Title header={"Legal"} subheader={"Please review carefully"} />
              <div style={{ padding: "0 1rem" }}>
                <Legal />
                <Signature />
              </div>
            </Segment>
          </Form>
          {this.props.type === "external" && <CompletionModal />}
        </Container>
      </Provider>
    );
  }
}

const mapStateToProps = state => ({
  user: state.User
});

export default connect(mapStateToProps)(Agreement);

/**
 *	Gets and returns an object that represents the contract
 *	Uses only the newest version information
 *	@return {Object}
 */
export const getCurrentData = data => {
  const { id, template } = data;
  const { versions } = template;
  const agreement = {
    id,
    original_data: data,
    template: {}
  };
  for (var k in template) {
    if (k !== "versions") {
      agreement.template[k] = template[k];
    }
  }
  const maximumDate = new Date(data.created_on);
  versions
    .sort((a, b) => new Date(a.created_on) - new Date(b.created_on))
    .forEach(version => {
      for (var k in version) {
        if (new Date(version.created_on) > maximumDate) {
          return agreement;
        }
        const fields = [
          "color",
          "payment_required",
          "year",
          "title",
          "html",
          "legal",
          "account_company_logo",
          "account_company_web",
          "account_company_phone",
          "account_company_email",
          "account_company_legal_name"
        ];
        fields.forEach(field => {
          if (version[field] !== null) {
            agreement[field] = version[field];
          }
        });
        agreement.template_version_date = version.created_on;
        agreement.template_version = version.id;
      }
    });
  return agreement;
};
