import React, { Component } from "react";
import { Message } from "semantic-ui-react";

export default class Audited extends Component {
  render() {
    return (
      <Message success>
        <Message.Header>Booking Audited</Message.Header>
        <p>
          This booking has been audited. Nothing except finances can be modified
          until it is de-audited.
        </p>
      </Message>
    );
  }
}
