import React from "react";
import { connect } from "react-redux";
import injectSheet from "react-jss";
import { addCommas } from "utils/numbers";

const Summary = ({ final, ordered, original }) => {
  const page = final.length;
  const pages = ordered.length;
  const request = original.length;
  return request > 0 ? (
    <p>
      {"Showing "}
      <b>{addCommas(page)}</b>
      {" out of "}
      <b>{addCommas(pages)}</b>
      {` visible ${pages > 1 ? "results" : "result"}, `}
      <b>{addCommas(request)}</b>
      {` total ${request > 1 ? "results" : "result"} found`}
    </p>
  ) : (
    <p>No results found</p>
  );
};

const styles = {
  // ...
};

const mapStateToProps = state => ({
  final: state.EOQReport.bookings.data.rows_paginated,
  ordered: state.EOQReport.bookings.data.rows_ordered,
  original: state.EOQReport.bookings.data.rows_original
  // ...
});
const mapDispatchToProps = dispatch => ({
  // ...
});

const connected = connect(
  mapStateToProps,
  mapDispatchToProps
)(Summary);
export default injectSheet(styles)(connected);
