import React from "react";
import { Button, Icon, Modal, Form, Input, Message } from "semantic-ui-react";

export default class WizardClass extends React.PureComponent {
  render() {
    const { ContentManager, Wizard } = this.props;
    return (
      <Modal open={ContentManager.state.wizardActive} size={"small"}>
        <Modal.Header onClick={ContentManager.functions.refresh}>
          Create Resort Company
        </Modal.Header>
        <Modal.Content>
          <Form>
            <Form.Field>
              <label>Name</label>
              {Wizard.functions.createInput(<Input />, "name")}
            </Form.Field>
          </Form>
          <Message warning>
            <Message.Header>Important</Message.Header>
            <p>You can not delete a resort company once it is made.</p>
          </Message>
        </Modal.Content>
        <Modal.Actions>
          {Object.keys(Wizard.data).length ? (
            <React.Fragment>
              <Button
                type={"button"}
                onClick={Wizard.functions.save}
                loading={ContentManager.state.wizardLoading}
                icon={"save"}
                content={"Save"}
                color={"blue"}
              />
              <Button
                type={"button"}
                content={"Discard"}
                onClick={Wizard.functions.reset}
              />
            </React.Fragment>
          ) : null}
          <Button onClick={ContentManager.functions.closeWizard}>
            <Icon name={"close"} />
            Close
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}
