import React from "react";
import { Grid } from "semantic-ui-react";
import { connect } from "react-redux";
import Month from "./components/Month";
class Months extends React.Component {
  render() {
    const { months: rows } = this.props;
    return (
      <React.Fragment>
        {rows.map((months, key) => (
          <Grid columns={3} key={key}>
            {months.map((month, key2) => (
              <Grid.Column key={key2}>
                <Month month={month} />
              </Grid.Column>
            ))}
          </Grid>
        ))}
      </React.Fragment>
    );
  }
}
export default connect((state) => ({
  months: state.Calendar.months
}))(Months);
