import InitialState from "./initial.js";
export default (state = InitialState, action) => {
  switch (action.type) {
    // banks related
    case "EOQ_BANKS_LOADING":
      return {
        ...state,
        banks: {
          loading: true,
          error: false,
          data: []
        },
        bank: "",
        startingCheckNumber: ""
      };
    case "EOQ_BANKS_ERROR":
      return {
        ...state,
        banks: {
          loading: false,
          error: action.data,
          data: []
        }
      };
    case "EOQ_BANKS_DATA":
      return {
        ...state,
        banks: {
          loading: false,
          error: false,
          data: action.data
        }
      };
    case "EOQ_SET_BANK":
      return {
        ...state,
        bank: action.data
      };
    case "EOQ_SET_CNUM":
      return {
        ...state,
        startingCheckNumber: action.data
      };

    // queue related
    case "EOQ_QUEUE_CLEAR":
      return {
        ...state,
        queue: []
      };
    case "EOQ_QUEUE_ADD":
      const add_ids = Array.isArray(action.data) ? action.data : [action.data];
      return {
        ...state,
        queue: state.queue
          .concat(add_ids)
          // only unique values
          .filter((value, index, self) => self.indexOf(value) === index)
      };
    case "EOQ_QUEUE_REMOVE":
      const rem_ids = Array.isArray(action.data) ? action.data : [action.data];
      return {
        ...state,
        queue: state.queue.filter(row => rem_ids.indexOf(row) === -1)
      };

    // regarding booking data
    case "EOQ_BOOK_LOADING":
      return {
        ...state,
        loadedAuditedOnly: state.auditedOnly,
        bookings: {
          loading: true,
          error: false,
          data: {
            rows_original: [],
            rows_searched: [],
            rows_filtered: [],
            rows_ordered: [],
            rows_paginated: [],
            bookings: []
          }
        }
      };
    case "EOQ_BOOK_ERROR":
      return {
        ...state,
        bookings: {
          loading: false,
          error: action.data,
          data: {
            rows_original: [],
            rows_searched: [],
            rows_filtered: [],
            rows_ordered: [],
            rows_paginated: [],
            bookings: []
          }
        }
      };
    case "EOQ_BOOK_DATA":
      return {
        ...state,
        bookings: {
          loading: false,
          error: false,
          data: {
            rows_original: action.data.rows,
            rows_searched: [],
            rows_filtered: [],
            rows_ordered: [],
            rows_paginated: [],
            bookings: action.data.bookings
          }
        }
      };
    case "EOQ_SET_SEARCHED":
      return {
        ...state,
        bookings: {
          ...state.bookings,
          data: {
            ...state.bookings.data,
            rows_searched: action.data,
            rows_filtered: [],
            rows_ordered: [],
            rows_paginated: []
          }
        }
      };
    case "EOQ_SET_FILTERED":
      return {
        ...state,
        bookings: {
          ...state.bookings,
          data: {
            ...state.bookings.data,
            rows_filtered: action.data,
            rows_ordered: [],
            rows_paginated: []
          }
        }
      };
    case "EOQ_SET_ORDERED":
      return {
        ...state,
        bookings: {
          ...state.bookings,
          data: {
            ...state.bookings.data,
            rows_ordered: action.data,
            rows_paginated: []
          }
        }
      };
    case "EOQ_SET_PAGINATED":
      return {
        ...state,
        bookings: {
          ...state.bookings,
          data: {
            ...state.bookings.data,
            rows_paginated: action.data
          }
        }
      };

    // saving related
    case "EOQ_SAVING":
      return {
        ...state,
        saving: true
      };
    case "EOQ_DONE_SAVING":
      return {
        ...state,
        saving: false
      };
    case "EOQ_SAVE_SUCCESS":
      return {
        ...state,
        created: action.data
      };
    case "EOQ_CLEAR_CREATED":
      return {
        ...state,
        created: []
      };

    // quarter selection
    case "EOQ_SET_START":
      return {
        ...state,
        start: action.data
      };
    case "EOQ_SET_END":
      return {
        ...state,
        end: action.data
      };
    case "EOQ_SET_AUDITED":
      return {
        ...state,
        auditedOnly: action.data
      };

    // regarding filtering booking data
    case "EOQ_SET_SEARCH":
      return {
        ...state,
        search: action.data
      };
    case "EOQ_SET_PER_PAGE":
      return {
        ...state,
        per_page: action.data
      };
    case "EOQ_SET_PAGE":
      return {
        ...state,
        page: action.data
      };

    default:
      return state;
  }
};
