import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import { Form, Header, Button } from "semantic-ui-react";

import AsyncForm from "components/AsyncForm";
import { user_can } from "utils/user";

export class Availability extends Component {
  static propTypes = {
    unit: PropTypes.object.isRequired,
    reload: PropTypes.func.isRequired,
    user: PropTypes.object.isRequired
  };

  render() {
    const config = {
      multiSubmit: true,
      reload: this.props.reload,
      failure: window.toastr.error,
      dataFn: (source, value) => ({
        url: "/apis/portal/units",
        data: {
          action: "update",
          data: {
            ID: this.props.unit.id,
            [source]: value
          }
        }
      })
    };

    const canEdit = user_can("UNITS_AVAIL_INTERVAL", this.props.user);

    return (
      <div>
        <Header dividing>
          <Header.Content>Availability</Header.Content>
        </Header>
        <Button
          primary
          basic
          size="big"
          as={Link}
          icon="calendar alternate outline"
          labelPosition="left"
          to={`/browse/units/${this.props.unit.id}/calendar`}
          content="View Unit Calendar"
        />
        <br />
        <br />
        <p>
          Units must have their calendars updated regularly to ensure there
          aren't availability issues. Use the dropdown below to adjust when this
          unit's calendar should be considered outdated.
        </p>
        <AsyncForm {...config}>
          {({ functions }) => {
            return (
              <Fragment>
                {canEdit ? (
                  <Fragment>
                    {functions.make({
                      element: <Form.Dropdown />,
                      field: "availability_interval",
                      value: this.props.unit.availability_interval,
                      props: {
                        label: "Calendars reset",
                        placeholder: "Select one...",
                        required: true,
                        selection: true,
                        options
                      }
                    })}
                  </Fragment>
                ) : (
                  <Form.Input
                    icon="lock"
                    label="Calendars reset"
                    readOnly
                    value={
                      options.find(
                        row =>
                          row.value === this.props.unit.availability_interval
                      ).text
                    }
                  />
                )}

                <p>
                  Units with <b>frozen</b> availability will not have calendar
                  changes pushed. They do not count towards calendars that need
                  to be updated.
                </p>
                {functions.message()}
              </Fragment>
            );
          }}
        </AsyncForm>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  user: state.User
});

const mapDispatchToProps = {
  // ...
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Availability);

const options = [
  { value: "never", text: "Never" },
  { value: "every", text: "Midnight" },
  { value: "even", text: "Midnight every even day" },
  { value: "odd", text: "Midnight every odd day" },
  { value: "fri", text: "Midnight on Friday" }
];
