import React, { Fragment } from "react";
import { Link } from "react-router-dom";

import { Table, Header } from "semantic-ui-react";

import { addCommas } from "utils/numbers";
import { formatMJY } from "utils/dates";

/**
 *	Get all bookings in a membership
 *	Accounts for the fact that bookings may have multiple usages, and only
 *  displays them once, summing the point usages
 *  @return {Array}
 */
const getBookings = usages => {
  const byBooking = [];
  // Define a function to check if a booking already exists within byBooking,
  // if so we need only to push the amount of pts
  const filterFn = (row, usage) => row && row.booking.id === usage.booking.id;
  usages.forEach(usage => {
    // check if the booking for this usage already exists in byBooking
    const exists = !!byBooking.find(r => filterFn(r, usage));
    if (!exists) {
      // make an entry for this booking only once
      byBooking[usage.booking.id] = {
        booking: usage.booking,
        // where we'll store all the used amounts
        amounts: []
      };
    }
    // for this and every next iteration, only push the amount into that entry
    byBooking[usage.booking.id].amounts.push(usage.amount);
  });
  return (
    byBooking
      // .filter(Boolean) removes the empty values
      .filter(Boolean)
      // .map() is to reduce the points_used
      .map(booking => ({
        ...booking.booking,
        points_used: booking.amounts.reduce((a, c) => a + c, 0)
      }))
      // order them by check-in, newest first
      .sort((a, b) => new Date(b.check_in) - new Date(a.check_in))
  );
};

export default ({ usages }) => {
  const bookings = getBookings(usages);
  return (
    <Fragment>
      <Header
        as="h3"
        icon="address book outline"
        content="Bookings in this set"
        subheader="All bookings using these points are listed below"
      />
      <p>
        {usages.length === 1
          ? "There is 1 booking using these points."
          : `There are ${usages.length} bookings using these points.`}
      </p>
      {bookings.length > 0 && (
        <Table basic="very" compact="very">
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Guest</Table.HeaderCell>
              <Table.HeaderCell>Booking/Check-In</Table.HeaderCell>
              <Table.HeaderCell>Amount Used</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {bookings.map((b, key) => (
              <Table.Row key={key}>
                <Table.Cell>
                  <Link to={`/browse/guests/${b.guest.id}`}>
                    {`${b.guest.firstname} ${b.guest.lastname}`}
                  </Link>
                </Table.Cell>
                <Table.Cell>
                  <Link to={`/browse/bookings/${b.id}`}>
                    {formatMJY(b.check_in)}
                  </Link>
                </Table.Cell>
                <Table.Cell>{addCommas(b.points_used)}</Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      )}
    </Fragment>
  );
};
