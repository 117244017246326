import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { debounce } from "lodash";

import { Form } from "semantic-ui-react";

export default class AmountSelector extends Component {
  static propTypes = {
    amount: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
      .isRequired,
    disabled: PropTypes.bool.isRequired,
    setAmount: PropTypes.func.isRequired,
    message: PropTypes.bool
  };

  search = (e, { value }) => {
    const amount = Number(value);
    if (value === "") {
      // don't show a warning if they just blank it out
      return false;
    } else if (amount <= 0) {
      window.toastr.info("You must enter a valid amount of points.");
      return false;
    }

    this.props.setAmount(amount);
  };

  render() {
    const { message, disabled } = this.props;
    return (
      <Fragment>
        {message && (
          <p>
            The server will attempt to find the owners that fit this reservation
            the best. It will look at the booking date and the resort company of
            the unit and compare it against all active owners.
          </p>
        )}
        <Form>
          <Form.Input
            type="number"
            label="Amount of Points"
            onChange={debounce(this.search, 500)}
            placeholder="Enter amount..."
            disabled={disabled}
          />
        </Form>
      </Fragment>
    );
  }
}
