import React from "react";
import { Icon } from "semantic-ui-react";
export default ({ comment }) => {
  if (comment.is_system) {
    return (
      <div className={"avatar"} style={{ marginRight: "5px" }}>
        <Icon name={"shield"} size={"big"} />
      </div>
    );
  }
  return null;
};
