import React, { useState } from "react";

import { Header, Table, Checkbox, Icon } from "semantic-ui-react";

import Issue from "./components/Issue";
import OpenIssue from "./components/OpenIssue";
import props from "../propTypes.js";

const MemberIssues = props => {
  const [state, setState] = useState({
    open: true,
    closed: false
  });

  const toggleOpen = () => setState({ ...state, open: !state.open });
  const toggleClosed = () => setState({ ...state, closed: !state.closed });

  const { member } = props;
  const numberOpen = countOpenIssues(member);
  const numberClosed = member.issues.length - numberOpen;
  const issues = getIssuesToShow(member, state);

  return (
    <>
      <Header
        as="h1"
        dividing
        icon={
          numberOpen > 0 ? (
            <Icon name="dot circle outline" color="red" />
          ) : (
            <Icon name="check circle outline" />
          )
        }
        content={member.firstname + " " + member.lastname + " / Issues"}
        subheader="Open, close, or view member issues"
      />
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div style={{ paddingTop: ".5rem" }}>
          <Checkbox
            label={`Show open (${numberOpen})`}
            style={{ marginRight: "1rem" }}
            onChange={toggleOpen}
            checked={state.open}
          />
          <Checkbox
            label={`Show closed (${numberClosed})`}
            onChange={toggleClosed}
            checked={state.closed}
          />
        </div>
        <OpenIssue data={member} reload={props.reloadMember} />
      </div>
      <Table celled padded selectable>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell singleLine width={2} />
            <Table.HeaderCell width={6}>Type of Issue</Table.HeaderCell>
            <Table.HeaderCell singleLine width={4}>
              Opened By
            </Table.HeaderCell>
            <Table.HeaderCell singleLine width={4}>
              Closed By
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {issues.length ? (
            issues.map((issue, key) => (
              <Issue key={key} data={issue} reload={props.reloadMember} />
            ))
          ) : (
            <Table.Row>
              <Table.Cell colSpan={4}>
                <br />
                There are no issues to show.
                <br />
                <br />
              </Table.Cell>
            </Table.Row>
          )}
        </Table.Body>
      </Table>
      <p>Newer issues will always show up at the top.</p>
    </>
  );
};

MemberIssues.propTypes = { ...props };

export default MemberIssues;

/**
 * count the total # of issues open for member
 */
export const countOpenIssues = member =>
  member.issues.filter(issue => issue.closed_on === null).length;

/**
 * get all of the visible issues given the current filters
 */
export const getIssuesToShow = (member, state) => {
  return member.issues
    .filter(issue => {
      if (issue.closed_on === null) {
        return state.open;
      } else {
        return state.closed;
      }
    })
    .sort((a, b) => new Date(b.opened_on) - new Date(a.opened_on));
};
