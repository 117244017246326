import React from "react";
import { Comment as SComment, Message } from "semantic-ui-react";
import Comment from "./components/Comment";
import AjaxComponent from "components/AjaxComponent";
import Reply from "./components/Reply";
import injectSheet from "react-jss";
import { connect } from "react-redux";
import { user_can } from "utils/user";

class Comments extends React.Component {
  state = {
    thread: this.props.thread,
    replying: false
  };
  setReplying = id => this.setState({ replying: id });
  // Force the thread to update and reload the <AjaxComponent />
  componentWillReceiveProps(props) {
    if (props.thread !== this.state.thread) {
      this.setState({ thread: props.thread }, this.ref.reload);
    }
  }
  render() {
    const { classes, maxHeight = "400px" } = this.props;
    // Configuration object to pass into <AjaxComponent />, allows us to load comments
    const ajaxConfig = {
      url: "/apis/portal/comments",
      data: {
        action: "read",
        data: {
          thread: this.state.thread
        }
      }
    };
    // Check if the user has the right to use comments first
    if (!user_can("COMMENTS_ENABLED", this.props.user)) {
      return (
        <Message warning>
          <Message.Header>Comments not enabled</Message.Header>
          <p>Your account is not allowed to leave or view comments.</p>
        </Message>
      );
    }
    return (
      <AjaxComponent ajaxConfig={ajaxConfig} ref={a => (this.ref = a)}>
        {({ data }) => {
          return (
            <div>
              <div style={{ maxHeight: maxHeight, overflow: "auto" }}>
                <SComment.Group>
                  {data
                    // Older towards the top
                    // Chose to do it like this becuase it seems to do it oldest at the bottom when using reply chains
                    .sort((a, b) => new Date(a.date) - new Date(b.date))
                    .map((comment, key) => (
                      <Comment
                        thread={this.state.thread}
                        comment={comment}
                        key={key}
                        classes={classes}
                        setReplying={this.setReplying}
                        replying={this.state.replying}
                        reload={this.ref.reload}
                      />
                    ))}
                </SComment.Group>
              </div>
              {!this.state.replying && (
                // Check that we aren't replying becuase we don't want two boxes
                // for the user to type in to render, this one is for the entire thread
                <Reply
                  comment={{ id: null }}
                  setReplying={this.setReplying}
                  thread={this.state.thread}
                  reload={this.ref.reload}
                  divider={false}
                />
              )}
            </div>
          );
        }}
      </AjaxComponent>
    );
  }
}

const styles = {
  comments: {
    paddingBottom: "0px !important",
    borderLeft: "1px solid #eee",
    marginTop: ".5rem !important"
  },
  systemComment: {
    padding: "5px !important",
    margin: "5px !important",
    borderRadius: "5px !important",
    border: "1px solid #ccccff !important",
    background: "#eff5ff !important"
  }
};

const mapStateToProps = state => ({
  user: state.User
  // ...
});
const mapDispatchToProps = dispatch => ({
  // ...
});

const connected = connect(
  mapStateToProps,
  mapDispatchToProps
)(Comments);
export default injectSheet(styles)(connected);
