import React, { useContext } from "react";
import PropTypes from "prop-types";
import { Context } from "../../";
import styled from "styled-components";

const Element = props => {
  const context = useContext(Context);

  const {
    children = [],
    hideOnCollapse = true,
    indicator = false
    // ...
  } = props;
  const { expanded } = context.state;

  if (!expanded && hideOnCollapse) {
    return null;
  }

  const containerProps = {
    expanded: expanded ? "true" : undefined,
    indicator: indicator ? "true" : undefined
  };

  return <StyledElement {...containerProps}>{children}</StyledElement>;
};

Element.propTypes = {
  children: PropTypes.any,
  hideOnCollapse: PropTypes.bool,
  indicator: PropTypes.bool
};

export default Element;

const StyledElement = styled.div`
  margin-bottom: 0.5rem;
  padding: ${({ expanded }) => (expanded ? "0 1rem" : "0px")};
  position: relative;
  ${({ indicator }) => {
    if (indicator) {
      return `
        &&& {
          a > .ui.button {
            color: #54c8ff !important;
          }
          &:before {
            background: #2185d0;
            border-radius: 0px 5px 5px 0px;
            bottom: 0;
            box-shadow: 0px 0px 15px 2px #2185d0;
            content: "";
            left: 0;
            position: absolute;
            top: 0;
            width: .125rem;
          }
        }
      `;
    }
  }};
`;
