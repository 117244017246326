import React from "react";
import { Form, Input } from "semantic-ui-react";
import AjaxDropdown from "components/AjaxDropdown";
export default class Step2 extends React.Component {
  /**
   *	Update the state from form changes
   *  @param {ReactElement} element
   *  @param {String} index
   *  @param {Object} props
   *  @return {ReactElement}
   */
  createInput = (element, index, props = {}) => {
    const { set, data } = this.props;
    return React.cloneElement(element, {
      value: data[index] || "",
      placeholder: index,
      onChange: (e, { value }) => set(index, value),
      ...props
    });
  };
  render() {
    return (
      <Form>
        <Form.Group widths={"equal"}>
          <Form.Field required>
            <label>First name</label>
            {this.createInput(<Input />, "firstname")}
          </Form.Field>
          <Form.Field required>
            <label>Last name</label>
            {this.createInput(<Input />, "lastname")}
          </Form.Field>
        </Form.Group>
        <Form.Group widths={"equal"}>
          <Form.Field required>
            <label>Username</label>
            {this.createInput(<Input />, "username")}
          </Form.Field>
          <Form.Field required>
            <label>Email Address</label>
            {this.createInput(<Input />, "email")}
          </Form.Field>
        </Form.Group>
        <Form.Group widths={"equal"}>
          <Form.Field required>
            <label>Company</label>
            {this.createInput(
              <AjaxDropdown
                url={"/apis/portal/accountcompanies"}
                data={{ action: "read_for_dropdown" }}
                dataRetreiver={({ message: m }) =>
                  m.map(({ id, name }) => ({
                    value: id,
                    text: name
                  }))
                }
              />,
              "account_company_id",
              { placeholder: "Account Company" }
            )}
          </Form.Field>
          <Form.Field />
        </Form.Group>
        <p>
          * Permissions for pages / account privileges can be edited after the
          account has been created.
        </p>
      </Form>
    );
  }
}
