// helpers
export const isBookingCancelled = ({ cancellations }) =>
  !!cancellations.filter(({ active }) => active).length;
export const setGuestPayOverride = (calculated, { finances }) => {
  // check if a 'require payment' setting is set.
  const mostRecent = finances
    // filter for the require_payment entries
    .filter(({ type }) => type === "require_payment")
    // sort newest to oldest
    .sort((a, b) => new Date(a.created_on) - new Date(b.created_on));
  // check that the most recent one does not equal 0.00
  if (mostRecent.length) {
    const setting = mostRecent.pop().amount;
    // 2.00 means it was set back to default
    if (setting === "2.00") {
      calculated.guestpay_override_exists = false;
      calculated.guestpay_override = false;
    } else {
      // if it is "1.00" it is required, else, not required
      calculated.guestpay_override = setting === "1.00";
      calculated.guestpay_override_exists = true;
    }
  } else {
    calculated.guestpay_override = false;
    calculated.guestpay_override_exists = false;
  }
};
export const isPaymentRequired = (calculated, booking) => {
  // first, check if the booking is cancelled.
  if (isBookingCancelled(booking)) {
    return false;
  }
  // second, check if refunds exceed guest payments made.
  if (calculated.refund !== "0.00") {
    if (Number(calculated.refund) >= Number(calculated.guest_payment)) {
      return false;
    }
  }
  if (calculated.guestpay_override_exists) {
    return calculated.guestpay_override;
  } else {
    return true;
  }
};
export const getSharedExpense = ({ channel_fee, booking_cost }) => {
  return (Number(channel_fee) + Number(booking_cost)).toFixed(2);
};
export const setPayrate = (calculated, { finances }) => {
  const payrateSetting = finances
    .filter(({ type }) => type === "booking_payrate")
    .sort((a, b) => new Date(a.created_on) - new Date(b.created_on));
  if (payrateSetting.length) {
    const setting = payrateSetting.pop().amount;
    calculated.payrate_setting = setting;
  } else {
    calculated.payrate_setting = "-1.00";
  }
};
export const getGrossOwnerPay = (calculated, booking, sharedExpense) => {
  if (booking.point_usages.length === 0) {
    return 0;
  }
  const setting =
    calculated.payrate_setting === "-1.00"
      ? Number([...booking.point_usages].pop().point_set.membership.payrate)
      : Number(calculated.payrate_setting);
  let grossPay;
  if (setting < 11 && setting > 0) {
    // $/1,000 points
    grossPay = booking.point_usages.reduce((a, c) => {
      return a + (c.amount / 1000) * setting;
    }, 0);
    grossPay -= Number(sharedExpense) + Number(calculated.booking_fee);
  } else {
    // % GRI based
    grossPay = Number(calculated.gross_rental_income) - Number(sharedExpense);
    grossPay *= setting / 100;
  }
  return grossPay;
};
export const setAuditStatus = (calculated, { finances }) => {
  const auditSetting = finances
    .filter(({ type }) => type === "audited")
    .sort((a, b) => new Date(a.created_on) - new Date(b.created_on));
  if (auditSetting.length) {
    calculated.audited = auditSetting.pop().amount === "1.00";
  } else {
    calculated.audited = false;
  }
};
// main calculators
export const determineGrossRentalIncome = (calculated, { finances }) => {
  const GRIs = finances
    .filter(({ type }) => type === "gross_rental_income")
    .sort((a, b) => new Date(a.created_on) - new Date(b.created_on));
  calculated.gross_rental_income = GRIs.length ? GRIs.pop().amount : "0.00";
};
export const sumFinancialFields = (calculated, { finances }) => {
  let fields = {
    owner_cost: "0.00",
    booking_cost: "0.00",
    management_cost: "0.00",
    channel_fee: "0.00",
    refund: "0.00",
    booking_fee: "0.00",
    guest_payment: "0.00",
    tax: "0.00"
  };
  for (var field in fields) {
    calculated[field] = finances
      .filter(({ type, deleted }) => type === field && !deleted)
      .reduce((accumulator, { amount }) => accumulator + parseFloat(amount), 0)
      .toFixed(2);
  }
};
export const calculateGrandTotal = calculated => {
  calculated.grand_total = (
    Number(calculated.gross_rental_income) +
    Number(calculated.tax) +
    Number(calculated.booking_fee)
  ).toFixed(2);
};
export const calculateGuestPaymentDue = (calculated, booking) => {
  calculated.payment_required = isPaymentRequired(calculated, booking);
  calculated.guest_payment_due = calculated.payment_required
    ? (
        Number(calculated.grand_total) - Number(calculated.guest_payment)
      ).toFixed(2)
    : "0.00";
};
export const calculateOwnerPay = (calculated, grossOwnerPay) => {
  calculated.owner_pay = (
    grossOwnerPay - Number(calculated.owner_cost)
  ).toFixed(2);
};
export const calculateProfit = (calculated, sharedExpense, grossOwnerPay) => {
  const gains =
    Number(calculated.guest_payment_due) + Number(calculated.guest_payment);
  const losses =
    Number(calculated.refund) +
    Number(sharedExpense) +
    Number(grossOwnerPay) +
    Number(calculated.management_cost);
  calculated.profit = (gains - losses).toFixed(2);
};
const calculateBooking = booking => {
  const calculated = {};
  setGuestPayOverride(calculated, booking);
  // console.log("set guest pay override", calculated);
  determineGrossRentalIncome(calculated, booking);
  // console.log("determined GRI", calculated);
  sumFinancialFields(calculated, booking);
  // console.log("summed", calculated);
  calculateGrandTotal(calculated);
  // console.log("grand total", calculated);
  calculateGuestPaymentDue(calculated, booking);
  // console.log("guest payment due", calculated);
  setPayrate(calculated, booking);
  const sharedExpense = getSharedExpense(calculated);
  const grossOwnerPay = getGrossOwnerPay(calculated, booking, sharedExpense);
  calculateOwnerPay(calculated, grossOwnerPay);
  // console.log("owner pay", calculated);
  calculateProfit(calculated, sharedExpense, grossOwnerPay);
  // console.log("profit", calculated);
  setAuditStatus(calculated, booking);
  // console.log("audit status", calculated);
  // console.log("final", calculated);
  return calculated;
};
export default calculateBooking;
