import React from "react";
import { Card, Icon, Grid } from "semantic-ui-react";
import injectSheet from "react-jss";
const styles = {
  column: {
    textAlign: "center"
  },
  image: {
    padding: "20px",
    background: "#f6f6f6"
  },
  active: {
    boxShadow: "0px 0px 10px 0px blue !important"
  }
};
const cards = [
  {
    icon: "building",
    name: "Resort",
    sub: "Just a resort",
    value: "resort"
  },
  {
    icon: "hotel",
    name: "Unit",
    sub: "Just a unit",
    value: "unit"
  },
  {
    icon: "cubes",
    name: "Resort + Unit",
    sub: "Resort and a Unit",
    value: "resort-unit"
  }
  // {
  //   icon: "clone",
  //   name: "Clone",
  //   sub: "Copy/Copies of a Unit",
  //   value: "clone"
  // }
];
/**
 *	@prop clone {Boolean}
 *	@prop unit {Boolean}
 *	@prop resort {Boolean}
 *  @prop selectType {function}
 */
class PropertyTypeSelector extends React.PureComponent {
  getClass = (type) => {
    const { resort, unit, clone, classes } = this.props;
    switch (type) {
      case "resort":
        return resort && !unit ? classes.active : undefined;
      case "unit":
        return !resort && unit ? classes.active : undefined;
      case "resort-unit":
        return resort && unit ? classes.active : undefined;
      case "clone":
        return clone ? classes.active : undefined;
      default:
        return undefined;
    }
  };
  render() {
    const { classes, selectType } = this.props;
    return (
      <Grid columns={4}>
        {cards.map((el, ix) => (
          <Grid.Column className={classes.column} key={ix}>
            <Card
              raised
              onClick={() => selectType(el.value)}
              className={this.getClass(el.value)}
            >
              <div className={classes.image}>
                <Icon name={el.icon} size={"huge"} color={"black"} />
              </div>
              <Card.Content>
                <Card.Header>{el.name}</Card.Header>
                <Card.Meta>{el.sub}</Card.Meta>
              </Card.Content>
            </Card>
          </Grid.Column>
        ))}
      </Grid>
    );
  }
}

export default injectSheet(styles)(PropertyTypeSelector);
