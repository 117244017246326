import React, { Component, Fragment } from "react";

import { Header } from "semantic-ui-react";

import CommentsComponent from "components/Comments";
import props from "../propTypes.js";

export default class MemberComments extends Component {
  static propTypes = { ...props };

  render() {
    const { member } = this.props;

    return (
      <Fragment>
        <Header
          as="h1"
          dividing
          icon="comment outline"
          content={member.firstname + " " + member.lastname + " / Comments"}
          subheader="Leave comments for the member"
        />
        <CommentsComponent thread={member.thread} maxHeight="500px" />
      </Fragment>
    );
  }
}
