/**
 * Creates an alert
 */
export const createAlert = (
  type,
  content,
  identifier = "",
  color = "#eee",
  icon = null
) => ({
  type: "ALERTBAR_CREATE",
  data: {
    type,
    content,
    identifier,
    props: {
      color,
      icon
    }
  }
});

/**
 * Remove the visible alert
 */
export const removeAlert = () => ({
  type: "ALERTBAR_REMOVE"
});

export const removeAlertByIdentifier = identifier => ({
  type: "ALERTBAR_REMOVE_BY_IDENTIFIER",
  data: identifier
});
