import React from "react";
import {
  Button,
  // Form,
  Segment,
  Icon,
  Header
} from "semantic-ui-react";
import AjaxFn from "services/AjaxFn";
/**
 *	@prop ContentManager
 */
export default class Disabled extends React.Component {
  state = {
    loading: false
  };
  handleClick = () => {
    this.setState({ loading: true });
    AjaxFn({
      url: "/apis/portal/accounts",
      data: {
        action: "update",
        data: {
          ID: this.props.ContentManager.state.editorSource.id,
          disabled: 0
        }
      },
      success: () => {
        this.props.ContentManager.functions.closeEditor();
        this.props.ContentManager.functions.refresh();
        window.toastr.success();
      },
      failure: e => {
        this.setState({ loading: false });
        window.toastr.error(e);
      }
    });
  };
  render() {
    return (
      <Segment
        raised
        color={"blue"}
        style={{ marginTop: "1.5rem" }}
        textAlign={"center"}
      >
        <Header as="h2" icon textAlign="center">
          <Icon name="lock" circular />
          <Header.Content>Account Disabled</Header.Content>
        </Header>
        <p>
          This account is disabled. It does not have access to anything that
          requires being logged in.
        </p>
        <Button
          onClick={this.handleClick}
          loading={this.state.loading}
          disabled={this.state.loading}
        >
          <Icon name={"unlock"} />
          Enable it
        </Button>
      </Segment>
    );
  }
}
