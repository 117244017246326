import React from "react";
import { Button } from "semantic-ui-react";
import { connect } from "react-redux";
import { uncancel } from "redux/BookingPage/actions";
import { timerestrict_booking } from "utils/user";

const UncancelButton = ({ cancellation, uncancel, user, booking }) => {
  const canDelete = timerestrict_booking(
    booking,
    user,
    ["BOOKINGS_UNCANCEL"],
    ["BOOKINGS_WIZARD"]
  );
  if (canDelete) {
    return (
      <Button onClick={() => uncancel(cancellation.id)}>
        Uncancel Booking
      </Button>
    );
  } else {
    return <p>Your account does not have access to uncancel bookings.</p>;
  }
};
const mapStateToProps = state => ({
  cancellation: state.BookingPage.booking.data.cancellations.filter(
    row => row.active
  )[0],
  booking: state.BookingPage.booking.data,
  user: state.User
  // ...
});
const mapDispatchToProps = dispatch => ({
  uncancel: ID => {
    if (window.confirm("Are you sure?")) {
      dispatch(uncancel(ID));
    }
  }
  // ...
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UncancelButton);
