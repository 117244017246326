export default {
  // header message
  warning: {
    padding: "2rem",
    background: "#fff",
    width: "8.5in",
    margin: "0 auto"
  },
  // cover letter
  coverFooter: {
    height: "3.5in",
    width: "8.5in",
    position: "absolute",
    bottom: 0,
    left: 0,
    borderTop: "1px dashed #ccc"
  },
  coverFooterFrom: {
    position: "absolute",
    bottom: "1.5in",
    left: "0.35in",
    height: "1.25in",
    width: "3.5in",
    height: "1.25in",
    padding: "0.125in"
  },
  coverFooterFromImage: {
    display: "block",
    maxWidth: "100%",
    maxHeight: "0.6in",
    paddingBottom: "0.0625in"
  },
  coverFooterTo: {
    textAlign: "left",
    position: "absolute",
    bottom: "0in",
    left: "0.35in",
    height: "1in",
    width: "4in",
    display: "flex",
    alignItems: "center",
    padding: "0.125in"
  },
  // print layout
  background: {
    background: "#eee",
    padding: "2rem"
  },
  container: {
    position: "relative",
    width: "8.5in",
    height: "11in",
    padding: ".4in",
    "page-break-after": "always",
    margin: "0 auto",
    marginTop: "2rem",
    background: "#fff",
    display: "flex",
    flexDirection: "column"
  },
  logo: {
    width: "auto",
    maxWidth: "60%",
    maxHeight: "1.5in",
    paddingTop: "1rem"
  },
  content: { flexGrow: "1" },
  contact: {
    display: "flex",
    justifyContent: "space-between",
    "& li": {
      listStyle: "none"
    }
  },
  address: {
    paddingLeft: "40px"
  },
  // hide stuff on print
  "@media print": {
    background: {
      background: "none",
      padding: "0"
    },
    warning: {
      display: "none"
    },
    container: {
      margin: "0"
    }
  }
};
