import React, { Component } from "react";
import BookingPageRouter from "./BookingPageRouter";
export default class BookingPage extends Component {
  getParams = () => ({
    ...this.props.match.params
  });
  render() {
    return <BookingPageRouter {...this.getParams()} />;
  }
}
