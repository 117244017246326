import React, { Component } from "react";
import PropTypes from "prop-types";
import { Segment, Header, Divider } from "semantic-ui-react";
import DeleteButton from "./components/DeleteButton";

export default class Actions extends Component {
  static propTypes = {
    unit: PropTypes.object.isRequired,
    reload: PropTypes.func.isRequired
  };

  render() {
    return (
      <div>
        <Header content="Actions" dividing />
        <Segment color="red">
          <Header content="Danger Zone" />
          <Divider />
          <p>
            Use the button below to delete this unit. A unit with listings or
            bookings may not be deleted.
            <b> This is irreversible.</b>
          </p>
          <DeleteButton unit={this.props.unit} reload={this.props.reload} />
        </Segment>
      </div>
    );
  }
}
