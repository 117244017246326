import React, { Fragment } from "react";
import {
  Button,
  Form,
  Input,
  Segment,
  Header,
  TextArea,
  Checkbox
} from "semantic-ui-react";
import styled from "styled-components";

import Pages from "./components/Pages";
import Privileges from "./components/Privileges";

export default (ContentManager, Editor) => {
  return ContentManager.state.editorActive ? (
    <Fragment>
      <Segment.Group>
        <Segment raised color="blue">
          <Header as="h4" content="Account Role Info" />
        </Segment>
        <Segment loading={ContentManager.state.editorLoading}>
          <Form>
            <Form.Field>
              <label>Name</label>
              {Editor.functions.createInput(<Input />, "name")}
            </Form.Field>
            <Form.Field>
              <label>Description</label>
              {Editor.functions.createInput(<TextArea />, "description")}
            </Form.Field>
            <Form.Group widths={"equal"}>
              <Form.Field>
                <label>Restricted</label>
                {Editor.functions.createCheckbox(<Checkbox />, "restricted", {
                  toggle: true
                })}
              </Form.Field>
            </Form.Group>
          </Form>
        </Segment>
        <Segment>
          <ActionsWrapper>
            <div>{ContentManager.functions.getDeleteButton()}</div>
            <div>
              {Object.keys(Editor.data).length ? (
                <React.Fragment>
                  <Button
                    type="button"
                    content="Discard"
                    icon="undo"
                    onClick={Editor.functions.reset}
                    basic
                  />
                  <Button
                    type="button"
                    onClick={Editor.functions.save}
                    loading={ContentManager.state.editorLoading}
                    icon="save"
                    content="Save"
                    color="blue"
                  />
                </React.Fragment>
              ) : null}
            </div>
          </ActionsWrapper>
        </Segment>
      </Segment.Group>
      <Segment.Group>
        <Segment raised color="blue">
          <Header as="h4" content="Permissions" />
        </Segment>
        <Segment>
          <Form>
            <FormGroupWrapper widths="equal">
              <Form.Field>
                <Pages ContentManager={ContentManager} />
              </Form.Field>
              <Form.Field>
                <Privileges ContentManager={ContentManager} />
              </Form.Field>
            </FormGroupWrapper>
          </Form>
        </Segment>
      </Segment.Group>
    </Fragment>
  ) : null;
};

const ActionsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: strech;

  div {
    button {
      height: 100%;
    }
    &:first-of-type {
      justify-self: flex-start;
    }
    &:last-of-type {
      button {
        margin: 0 0.25em 0 0.75em !important;
        &:last-of-type {
          margin: 0 0 0 0.75em !important;
        }
      }
    }
  }
`;

const FormGroupWrapper = styled(Form.Group)`
  &&& {
    margin-bottom: 0;
  }
`;
