import React from "react";
import { Segment, Dimmer, Loader } from "semantic-ui-react";

export default () => {
  return (
    <Segment style={{ paddingTop: "16rem", paddingBotom: "16rem" }}>
      <Dimmer active inverted>
        <Loader inverted>Loading</Loader>
      </Dimmer>
    </Segment>
  );
};
