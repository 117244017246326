import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { Button } from "semantic-ui-react";

import { save, clearQueue } from "redux/EOQReport/actions";

export const Controls = props => {
  const { bank, queue, cnum, save, clearQueue, loadedAuditedOnly } = props;
  return (
    <div style={{ display: "flex", justifyContent: "space-between" }}>
      <Button
        basic
        disabled={!queue.length}
        onClick={clearQueue}
        content={"Clear Queue"}
      />
      {loadedAuditedOnly ? (
        <Button
          primary
          disabled={!bank || !queue.length || !cnum}
          onClick={save}
          content={"Print"}
        />
      ) : (
        <p>Printing is disabled for queries with unaudited bookings.</p>
      )}
    </div>
  );
};

Controls.propTypes = {
  bank: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  cnum: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  queue: PropTypes.array.isRequired,
  save: PropTypes.func.isRequired,
  clearQueue: PropTypes.func.isRequired,
  loadedAuditedOnly: PropTypes.bool.isRequired
};

const mapStateToProps = state => ({
  bank: state.EOQReport.bank,
  queue: state.EOQReport.queue,
  cnum: state.EOQReport.startingCheckNumber,
  loadedAuditedOnly: state.EOQReport.loadedAuditedOnly
});

const mapDispatchToProps = {
  save,
  clearQueue
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Controls);
