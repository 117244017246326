import React from "react";
import { Icon, Button } from "semantic-ui-react";
import { Link } from "react-router-dom";
import ProgressBar from "components/ProgressBar";
import preprocessor from "./Preprocessor.js";
import ViewUnits from "./components/ViewUnits";
import Filters from "./components/Filters";
export default ({ functions }) => ({
  tableName: "resorts",
  url: "/apis/portal/resorts",
  data: {
    action: "read"
  },
  preprocessor,
  tableData: {
    show: 10,
    order: [[0, "asc"], [1, "asc"]],
    paginationPosition: "bottom",
    showFooter: false,
    menu: () => [
      "[search]",
      "[show]",
      <Filters />,
      "[refresh]",
      <Link to={"/wizards/properties"}>
        <Button primary>New</Button>
      </Link>
    ],
    cols: [
      {
        name: "",
        src: "id",
        render: (src, row) => (
          <Link to={"/browse/resorts/" + row.id}>
            <Icon name={"external"} />
          </Link>
        ),
        orderable: false,
        searchable: false,
        props: {
          textAlign: "center",
          selectable: true,
          width: 1
        }
      },
      {
        name: "Company",
        src: row => row.resort_company.name,
        // Resort company page does not exist yet.
        // render: (src, row) => (
        //   <a href={`/browse/resortCompanies/${row.id}`}>{src}</a>
        // ),
        props: {
          width: 2
        }
      },
      {
        name: "Name",
        src: "name",
        props: {
          width: 6
        }
      },
      {
        name: "Agent",
        src: ({ assigned_account }) =>
          assigned_account.id
            ? `${assigned_account.firstname} ${assigned_account.lastname[0]}.`
            : "Nobody",
        props: {
          width: 2
        }
      },
      {
        name: "Units",
        src: row => row.units.length,
        render: (src, row) => {
          if (src) {
            return <ViewUnits data={row} />;
          } else {
            return (
              <React.Fragment>
                <Icon name={"cancel"} color={"red"} />
                None
              </React.Fragment>
            );
          }
        },
        props: row => {
          const props = {
            width: 2
          };
          if (row.units.length) {
            props.selectable = true;
            props.positive = true;
          } else {
            props.negative = true;
          }
          return props;
        }
      },
      {
        name: "Completion",
        src: "completion",
        render: src => (
          <a onClick={functions.viewCompletion}>
            <ProgressBar value={src} />
          </a>
        ),
        props: {
          width: 3,
          selectable: true
        }
      }
    ]
  }
});
