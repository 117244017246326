import React from "react";

import { Breadcrumb } from "semantic-ui-react";

import ContextBar from "components/ContextBar";

export default () => (
  <ContextBar render>
    <Breadcrumb>
      <Breadcrumb.Section active>E-Agreement</Breadcrumb.Section>
    </Breadcrumb>
  </ContextBar>
);
