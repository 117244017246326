import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import AsyncForm from "components/AsyncForm";
import NullableDropdown from "components/NullableDropdown";
import {
  Form,
  Header,
  Input,
  Segment,
  Divider,
  Button
} from "semantic-ui-react";
import { chunk } from "utils/arrays";
import Bookings from "./components/Bookings";
import DeleteButton from "./components/DeleteButton";

export default class CiirusListing extends Component {
  static propTypes = {
    unit: PropTypes.object.isRequired,
    listing: PropTypes.object.isRequired,
    listingTab: PropTypes.string,
    reload: PropTypes.func.isRequired
  };

  render() {
    const { listing } = this.props;
    const config = {
      multiSubmit: true,
      reload: this.props.reload,
      failure: e => {
        window.toastr.error(e);
      },
      dataFn: (source, value) => ({
        url: "/apis/portal/unittypeciirus",
        data: {
          action: "update",
          data: {
            ID: listing.id,
            [source]: typeof value === "boolean" ? (value ? 1 : 0) : value
          }
        }
      })
    };

    return (
      <div>
        <Header dividing>
          <Header.Content>{listing.name}</Header.Content>
        </Header>
        <AsyncForm {...config}>
          {({ functions }) => {
            return (
              <Fragment>
                <Form.Group>
                  {functions.make({
                    element: <Form.Input />,
                    field: "name",
                    value: listing.name,
                    debounce: 500,
                    props: {
                      width: 12,
                      placeholder: "CiiRUS name",
                      label: "CiiRUS name"
                    }
                  })}
                  <Form.Field width={4}>
                    <label>CiiRUS ID</label>
                    {functions.make({
                      element: <Input />,
                      field: "ciirus_id",
                      value: listing.ciirus_id,
                      debounce: 500,
                      props: {
                        width: 12,
                        placeholder: "00000",
                        label: "#",
                        type: "number"
                      }
                    })}
                  </Form.Field>
                </Form.Group>
                {functions.make({
                  element: <Form.Input />,
                  field: "title",
                  value: listing.title,
                  debounce: 500,
                  props: {
                    placeholder: "CiiRUS title",
                    label: "CiiRUS title"
                  }
                })}
                <br />
                <Header dividing>
                  <Header.Content>Listing Channel IDs</Header.Content>
                </Header>
                <p>
                  These are only for reference. They will not affect anything.
                  To mark this listing as "unlisted" for a channel, just leave
                  the ID field blank for that channel.
                </p>
                {chunk(sources, 4).map((row, rowkey) => (
                  <Form.Group key={rowkey}>
                    {row.map((col, colkey) => (
                      <Form.Field key={colkey} width={4}>
                        {col && (
                          <React.Fragment>
                            <label>{col.show}</label>
                            {functions.make({
                              element: <Input />,
                              field: "listed_" + col.src,
                              value: listing["listed_" + col.src],
                              debounce: 500,
                              props: {
                                placeholder: "00000",
                                label: "#"
                              }
                            })}
                          </React.Fragment>
                        )}
                      </Form.Field>
                    ))}
                  </Form.Group>
                ))}
                <br />
                <br />
                <Bookings listing={listing} />
                <br />
                <br />
                <Segment color="red">
                  <Header content="Danger Zone" />
                  <Divider />
                  <p>
                    Use the "Freeze Availability" toggle below to prevent this
                    listing from being affected by the calendars. It will not
                    count towards unit availability being outdated, and will not
                    have its availability updated.
                  </p>
                  <Form.Field width={4}>
                    <label>Freeze Availability</label>
                    {functions.make({
                      element: <NullableDropdown />,
                      field: "freeze_availability",
                      value: listing.freeze_availability,
                      props: {
                        options: [
                          { value: true, text: "Yes" },
                          { value: false, text: "No" }
                        ],
                        placeholder: "Select one...",
                        selection: true,
                        selectOnBlur: false
                      }
                    })}
                  </Form.Field>
                  <Divider />
                  <p>
                    Use the button below to delete this listing. A listing with
                    bookings attached to it may not be deleted.
                    <b> This is irreversible.</b>
                  </p>
                  <DeleteButton listing={listing} reload={this.props.reload} />
                </Segment>
                <br />
                {functions.message()}
              </Fragment>
            );
          }}
        </AsyncForm>
      </div>
    );
  }
}

const sources = [
  { src: "airbnb", show: "AirBNB" },
  { src: "booking", show: "Booking.com" },
  { src: "canadastays", show: "CanadaStays" },
  { src: "expedia", show: "Expedia.com" },
  { src: "homeaway", show: "HomeAway" },
  { src: "redawning", show: "RedAwning" },
  { src: "rentalsunited", show: "RentalsUnited" },
  { src: "roomorama", show: "Room-O-Rama" },
  { src: "tripadvisor", show: "TripAdvisor" },
  { src: "triping", show: "Triping.com" },
  { src: "VMG", show: "VMGResorts.com" }
];
