import uuidv4 from "uuid/v4";
import AjaxFn from "services/AjaxFn";
import { ignore } from "redux/Sockets/actions";
import { removeAlertByIdentifier } from "redux/Alertbar/actions";

/**
 *  @param {Object} data (user data from the server)
 */
export const setConnectedUser = data => ({
  type: "USER_SET_CONNECTED_USER",
  data
});

export const timepunch = () => (dispatch, getState) => {
  // prevent the socket from triggering on this event for this window
  const token = uuidv4();
  dispatch(ignore(token));
  // begin the request
  dispatch({ type: "USER_TIMEPUNCH_REQUEST" });
  AjaxFn({
    url: "/apis/portal/timepunches",
    data: {
      action: "punch",
      data: {
        token
      }
    },
    success: ({ state: { clocked_in } }) => {
      // update our clocked_in status
      dispatch(setStatus(clocked_in));
      // remove any clocked out alerts, if necessary
      if (clocked_in) {
        dispatch(removeAlertByIdentifier("clockedOut"));
      }
    },
    failure: window.toastr.error,
    finally: () => {
      // update our timepunching loader state
      dispatch({
        type: "USER_TIMEPUNCH_REQUEST_DONE"
      });
    }
  });
};

/**
 *  @param {Boolean} data
 */
export const setStatus = data => dispatch => {
  dispatch(loadPunches());
  dispatch({
    type: "USER_TIMEPUNCH_SET_STATUS",
    data
  });
};

/**
 *  @param {Object} data
 */
export const updateTutorial = data => ({
  type: "USER_UPDATE_TUTORIAL",
  data
});

export const setPunches = data => ({
  type: "USER_SET_PUNCHES",
  data
});

export const loadPunches = () => dispatch => {
  console.log("loading punches");
  dispatch({ type: "USER_LOAD_PUNCHES" });
  AjaxFn({
    url: "/apis/portal/timepunches?action=read_for_week",
    success: data => dispatch(setPunches(data)),
    failure: window.toastr.error
  });
};

export const setCalculated = data => ({
  type: "USER_SET_CALCULATED",
  data
});
