import React from "react";
import { Button } from "semantic-ui-react";
import { Link } from "react-router-dom";

export default ({ created, back }) => {
  let batch = created.map((check, key) => check.id);
  batch = encodeURIComponent(batch);

  return (
    <div>
      <p>The following checks have been created:</p>
      <ul>
        {created.map((check, key) => (
          <li key={key}>
            <Link to={`/browse/checks/${check.id}`}>
              Check # {check.number.toString().padStart(7, "0")}
            </Link>
          </li>
        ))}
      </ul>
      <Link to={`/browse/checks/%5B${batch}%5D/print`}>Batch Print Checks</Link>
      <p>
        Remember to mark each check as processed or it will be deleted
        automatically at midnight.
      </p>
      <Button onClick={back}>Go Back</Button>
    </div>
  );
};
