import React from "react";
import { Divider, Icon } from "semantic-ui-react";
/**
 * 	@prop classes
 *  @prop header
 *  @prop children
 */
export default class Container extends React.Component {
  render() {
    const { check, classes, header = false, children } = this.props;
    const membership =
      check.bookings[0].point_usages[0].point_set.membership.issuer_company;

    const company = () => {
      let data = {
        name: "Vacation Management Group",
        website: "www.vmgresorts.com",
        email: "cs@vmgresorts.com",
        phone: "(877) 411-2883",
        address1: "15933 Clayton Rd Suite 135",
        address2: "Ballwin, MO 63011"
      };

      switch (membership) {
        case "Premier":
          data.name = "Premier Rewards";
          data.website = "www.pr-rewards.com";
          data.email = "cs@pr-rewards.com";
          data.phone = "(877) 230-8322";
          return data;
        case "MPerks":
          data.name = "Member Perks";
          data.website = "www.elitememberperks.com";
          data.email = "cs@elitememberperks.com";
          data.phone = "(877) 600-5711";
          return data;
        case "ERewards":
          data.name = "Elite Rewards";
          data.website = "www.yourclubrewards.com";
          data.email = "cs@yourclubrewards.com";
          data.phone = "(833) 596-1348";
          data.address1 = "6965 piazza grande ave 218";
          data.address2 = "Orlando, FL 32835";
          return data;
        case "EPC":
          data.name = "Exchange Points Club";
          data.website = "www.exchangepointsclub.com";
          data.email = "customerservice@exchangepointsclub.com";
          data.phone = "(844) 246-0224";
          data.address1 = "6965 piazza grande ave 218";
          data.address2 = "Orlando, FL 32835";
          return data;
        case "TSTeam":
          data.name = "Timeshare Selling Team";
          data.website = "www.timesharesellingteam.com";
          data.email = "info@timesharesellingteam.com";
          data.phone = "(877) 935-1102";
          return data;
        case "Unknown":
        default:
          return {
            name: "Vacation Management Group",
            website: "vmgresorts.com",
            email: "info@vmgresorts.com",
            phone: "(877) 411-2883",
            address1: "15933 Clayton Rd Suite 135",
            address2: "Ballwin, MO 63011"
          };
      }
    };

    return (
      <div className={classes.container}>
        {header && (
          <div style={{ textAlign: "center" }}>
            <img
              className={classes.logo}
              alt="VMG Resorts"
              src="/img/logo/dark.png"
            />
            <Divider />
          </div>
        )}
        <div className={classes.content}>{children}</div>
        <div>
          <Divider />
          <ul className={classes.contact}>
            <li>
              <b>
                <Icon name={"phone"} /> Phone
              </b>
              <br />
              {company().phone}
            </li>
            <li>
              <b>
                <Icon name={"globe"} /> Web
              </b>
              <br />
              {company().website}
            </li>
            <li>
              <b>
                <Icon name={"mail"} /> Email
              </b>
              <br />
              {company().email}
            </li>
          </ul>
          <div className={classes.address}>
            {company().name}
            <br />
            {company().address1}
            <br />
            {company().address2}
          </div>
        </div>
      </div>
    );
  }
}
