import React from "react";
import { Modal, Button, Icon, TextArea, Form } from "semantic-ui-react";
import AjaxFn from "services/AjaxFn";
export default class CloseIssue extends React.Component {
  state = {
    text: "",
    open: false,
    loading: false
  };
  handleOpen = () => this.setState({ open: true });
  handleClose = () => {
    if (!this.state.loading) {
      this.setState({ open: false });
    }
  };
  closeIssue = () => {
    this.setState({ loading: true });
    AjaxFn({
      url: "/apis/portal/bookingissues",
      data: {
        action: "close",
        data: {
          ID: this.props.data.id,
          closed_reason: this.state.text
        }
      },
      success: () => {
        window.toastr.success();
        this.props.reload();
      },
      failure: error => {
        window.toastr.error(error);
        this.setState({ loading: false });
      }
    });
  };
  render() {
    return (
      <Modal
        {...{
          open: this.state.open,
          onClose: this.handleClose,
          size: "mini",
          trigger: (
            <Button basic color={"blue"} fluid onClick={this.handleOpen}>
              Mark Issue as Closed
            </Button>
          )
        }}
      >
        <Modal.Header>Close Issue</Modal.Header>
        <Modal.Content>
          <Form>
            <Form.Field>
              <label>Reason/Description</label>
              <TextArea
                value={this.state.text}
                onChange={(e, { value }) => this.setState({ text: value })}
                placeholder={"Reason for closing..."}
                maxLength={512}
                fluid
                disabled={this.state.loading}
              />
            </Form.Field>
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={this.handleClose} disabled={this.state.loading}>
            <Icon name={"cancel"} />
            Close
          </Button>
          <Button
            color={"blue"}
            disabled={!this.state.text || this.state.loading}
            loading={this.state.loading}
            onClick={this.closeIssue}
          >
            Submit
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}
