import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { setShown } from "redux/PortalTables/actions";
import { Dropdown } from "semantic-ui-react";

export class ShowSelector extends Component {
  static propTypes = {
    setShown: PropTypes.func.isRequired,
    tables: PropTypes.object.isRequired
  };

  render() {
    const { namespace, tables, setShown } = this.props;
    const { show } = tables[namespace];
    return (
      <Dropdown
        selection
        placeholder="Pages to show"
        onChange={(e, { value }) => setShown(namespace, value)}
        value={show}
        options={options.map(o => ({
          ...o,
          value: o.key
        }))}
      />
    );
  }
}

const mapStateToProps = state => ({
  tables: state.PortalTables
});

const mapDispatchToProps = {
  setShown
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ShowSelector);

const options = [
  { key: 10, text: "Show 10 rows" },
  { key: 25, text: "Show 25 rows" },
  { key: 50, text: "Show 50 rows" },
  { key: 100, text: "Show 100 rows" },
  { key: 250, text: "Show 250 rows" }
];
