import React from "react";
import { Message } from "semantic-ui-react";
/**
 *	@prop title optional - the title of the error message
 *	@prop message optional - the message to show
 */
export default ({ title, message }) => {
  // if it's an object, try to treat it as an error object
  if (typeof message === "object") {
    message = message.message;
  }
  return (
    <Message negative>
      <Message.Header>{title || "An error has occurred"}</Message.Header>
      <p>{message || "An unknown error has occurred"}</p>
    </Message>
  );
};
