import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { Dimmer, Loader } from "semantic-ui-react";

import FocusBar from "components/FocusBar";
import Steps from "./Steps";
import ChangeAgents from "./components/ChangeAgents";
import ChangeTemplate from "./components/ChangeTemplate";
import SendAgreement from "./components/SendAgreement";
import ProcessAgreement from "./components/ProcessAgreement";
import PayAgreement from "./components/PayAgreement";
import { user_can } from "utils/user";

const AgreementFocusBar = props => {
  const { data, loading, error, reload, viewing, user } = props;

  if (loading) {
    return (
      <FocusBar>
        <FocusBar.Content>
          <Dimmer active style={{ background: "none" }}>
            <Loader />
          </Dimmer>
        </FocusBar.Content>
      </FocusBar>
    );
  }

  if (error) {
    return (
      <FocusBar>
        <FocusBar.Content>
          <FocusBar.Button
            icon="fas fa-retweet"
            content="Reload"
            onClick={reload}
          />
        </FocusBar.Content>
      </FocusBar>
    );
  }

  // The first actions block is the navigation
  // The content block will contain the completion tracker
  // The second actions block are general actions (process, mark paid)
  // The final actions block is contextual actions (change agents for dealsheet, send agreement, change template)

  const actions = [];

  const isCloser = user_can("AGREEMENTS_IS_CLOSER", user);
  const canProcess = user_can("AGREEMENTS_CAN_PROCESS", user);
  const canChangeAgent = user_can("AGREEMENTS_CHANGE_AGENT", user);

  if (!!data.received_on && !data.processed_on && canProcess) {
    actions.push(<ProcessAgreement data={data} reload={reload} />);
  }

  if (!data.paid_on && !!data.received_on && canProcess) {
    actions.push(<PayAgreement data={data} reload={reload} />);
  }

  if (viewing === "dealsheet") {
    if (!!data.received_on && !data.paid_on && canChangeAgent) {
      actions.push(
        <FocusBar.Drawer
          icon="fas fa-retweet"
          content="Change Agents..."
          children={<ChangeAgents data={data} reload={reload} />}
        />
      );
    }
  } else {
    actions.push(
      <FocusBar.Button
        icon="fas fa-print"
        content="Print"
        onClick={() => window.print()}
      />
    );
    if (!data.sent_on) {
      actions.push(
        <FocusBar.Drawer
          icon="fas fa-retweet"
          content="Change Type..."
          children={<ChangeTemplate data={data} reload={reload} />}
        />
      );
    }
    if (!data.received_on && isCloser) {
      actions.push(<SendAgreement data={data} reload={reload} />);
    }
  }

  return (
    <FocusBar>
      <FocusBar.Actions>
        <FocusBar.Nav
          content="Agreement"
          icon="fas fa-scroll"
          active={viewing === "agreement"}
          href={`/browse/agreements/${data.id}/agreement`}
        />
        <FocusBar.Nav
          content="Dealsheet"
          icon="far fa-sticky-note"
          active={viewing === "dealsheet"}
          href={`/browse/agreements/${data.id}/dealsheet`}
        />
      </FocusBar.Actions>
      <FocusBar.Content>
        <Steps data={data} />
      </FocusBar.Content>
      {actions.length > 0 && (
        <FocusBar.Actions>
          {actions.map((action, key) => React.cloneElement(action, { key }))}
        </FocusBar.Actions>
      )}
    </FocusBar>
  );
};

AgreementFocusBar.propTypes = {
  data: PropTypes.any.isRequired,
  loading: PropTypes.bool.isRequired,
  error: PropTypes.any.isRequired,
  viewing: PropTypes.string.isRequired,
  reload: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  user: state.User
});

export default connect(mapStateToProps)(AgreementFocusBar);

// unused actions/not implemented yet

// actions.push(
//   <FocusBar.Button
//     icon="fas fa-save"
//     content="Save Changes"
//   />
// )
