import React from "react";

import { formatMJY } from "utils/dates";
import ProgressBar from "components/ProgressBar";

export default {
  show: 25,
  order: [[1, "asc"]],
  tableProps: {
    selectable: true
  },
  // menu: [],
  cols: [
    {
      name: "Guest",
      src: ({ guest }) => guest.firstname + " " + guest.lastname
    },
    {
      name: "Date",
      src: "date",
      render: formatMJY,
      props: {
        width: 2
      }
    },
    {
      name: "Reservation",
      src: "reservation_progress",
      render: src => <ProgressBar value={src} />,
      props: {
        width: 3
      }
    },
    {
      name: "Audit",
      src: "audit_progress",
      render: src => <ProgressBar value={src} />,
      props: {
        width: 3
      }
    },
    {
      name: "Payment",
      src: "financial_progress",
      render: src => <ProgressBar value={src} />,
      props: {
        width: 3
      }
    },
    // hidden search cols
    // RCO
    {
      visible: false,
      src: "rco"
    },
    // member name
    {
      visible: false,
      src: booking => {
        const { point_usages } = booking;
        if (point_usages.length) {
          const {
            firstname,
            lastname
          } = point_usages[0].point_set.membership.member;
          return `${firstname} ${lastname}`;
        }

        return "";
      }
    }
  ]
};
