export default {
  loading: false,
  error: false,
  requestData: [],
  searchedData: [],
  filteredData: [],
  orderedData: [],
  paginatedData: [],
  finalData: [],
  search: "",
  show: 10,
  order: [],
  paginationPosition: "bottom",
  filters: [],
  menu: ["[search]", "[show]", "[settings]", "[refresh]"],
  page: 0,
  tableProps: {
    color: "blue",
    striped: true,
    celled: true
  },
  cols: [],
  rows: [],
  showFooter: true
};
