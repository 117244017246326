import React from "react";
import { Button, Icon } from "semantic-ui-react";
import Filters from "./components/Filters";
import { Link } from "react-router-dom";
// import { addCommas } from "utils/numbers";
// const date = new Date();
// const currentYear = date.getYear() + 1900;
// const nextYear = currentYear + 1;
export default namespace => ({
  tableName: namespace,
  url: "/apis/portal/memberships",
  data: {
    action: "read"
  },
  tableData: {
    show: 25,
    order: [[1, "asc"]],
    paginationPosition: "bottom",
    showFooter: false,
    menu: () => [
      "[search]",
      "[show]",
      <Filters namespace={namespace} />,
      "[refresh]",
      <Link to={"/wizards/memberships"}>
        <Button
          icon
          color={"blue"}
          title={"New"}
          children={<Icon name={"plus"} />}
        />
      </Link>
    ],
    cols: [
      {
        name: "",
        src: "id",
        render: (src, row) => {
          return (
            <Link
              to={"/browse/memberships/" + src}
              children={<Icon name={"external"} />}
            />
          );
        },
        orderable: false,
        searchable: false,
        props: {
          textAlign: "center",
          selectable: true,
          width: 1
        }
      },
      {
        name: "Active",
        src: "is_active",
        render: src =>
          src ? (
            <React.Fragment>
              <Icon name={"checkmark"} />
              Active
            </React.Fragment>
          ) : (
            <React.Fragment>
              <Icon name={"cancel"} />
              Inactive
            </React.Fragment>
          ),
        props: src => {
          const props = {};
          if (src.is_active) {
            props.positive = true;
          } else {
            props.negative = true;
          }
          return props;
        }
      },
      {
        name: "Owner",
        src: row => `${row.member.firstname} ${row.member.lastname}`
      },
      {
        name: "Level",
        src: "level",
        render: src => src || "Unknown",
        props: {
          width: 2
        }
      },
      {
        name: "Resort",
        src: ({ resort_company }) => resort_company.name,
        props: {
          width: 2
        }
      },
      // {
      //   name: "Home Resort",
      //   src: "home_resort_name",
      //   render: (src) => src || "None"
      // },
      {
        name: "Guest Passes",
        src: "guest_passes",
        searchable: false,
        props: {
          width: 2
        }
      },
      {
        name: "Res Passes",
        src: "reservation_passes",
        searchable: false,
        props: {
          width: 2
        }
      }
      // hidden columns for sorting/searching
      // {
      //   name: "Login Works",
      //   src: "login_works",
      //   visible: false,
      //   searchable: false
      // },
      // {
      //
      // }
    ]
  }
});
