import React, { Component } from "react";
import { createPortal } from "react-dom";
import { connect } from "react-redux";
import styled, { createGlobalStyle, keyframes } from "styled-components";

import { removeAlert } from "../../redux/Alertbar/actions";

const duration = 600;
const alertsRoot = document.getElementById("alerts");

class Alertbar extends Component {
  constructor(props) {
    super(props);
    this.state = { height: 0, background: "", border: "", color: "" };
    this.bar = React.createRef();

    // Create a div that we'll render the alertbar into.
    this.el = document.createElement("div");
  }

  componentDidMount() {
    // Append the element into the DOM on mount
    alertsRoot.appendChild(this.el);
    // Update component height and background color
    this.updateAlertbar();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.alerts !== this.props.alerts) {
      this.updateAlertbar();
    }
  }

  componentWillUnmount() {
    // remove the element from the DOM when we unmount
    alertsRoot.removeChild(this.el);
  }

  // handles updates associated to the alertbar
  updateAlertbar = () => {
    // the alerts are sorted in order of priority when a new one is added, so zero always shows first
    const { type } = this.props.alerts[0];
    const height = this.bar.current.clientHeight;

    // update state height with current height
    this.setState({ height });

    // set background and text color based on message type
    switch (type) {
      case "message":
        return this.setState({
          background: "#eee",
          border: "1px solid #dedede",
          color: "initial"
        });
      case "alert":
        return this.setState({
          background: "#db2828",
          border: "1px solid #A71414",
          color: "white"
        });
      case "warning":
        return this.setState({
          background: "#fbbd08",
          border: "1px solid #DBA402",
          color: "white"
        });
      case "disconnected":
        return this.setState({
          background: "#eee",
          border: "1px solid #dedede",
          color: "initial"
        });
      default:
        return this.setState({
          background: "#eee",
          border: "1px solid #dedede",
          color: "initial"
        });
    }
  };

  render() {
    const { alerts, removeAlert } = this.props;
    const { background, border, color, height } = this.state;
    // the alerts are sorted in order of priority when a new one is added, so zero always shows first
    const {
      content,
      type,
      props: { icon }
    } = alerts[0];

    const renderComponent = (
      <StyledBar
        ref={this.bar}
        height={height}
        background={background}
        border={border}
        color={color}
        // is this needed? app.js prevents the render of this component if alerts invisible
        isVisible={!!alerts.length}
      >
        <div className="content">
          {this.props.children || content || "\u00A0"}
        </div>
        {type === "disconnected" ? (
          <Reconnecting />
        ) : (
          <CloseButton onClick={removeAlert} type={type}>
            <i aria-hidden="true" />
          </CloseButton>
        )}
        <GlobalStyle isVisible={!!alerts.length} height={height} />
      </StyledBar>
    );

    return createPortal(renderComponent, this.el);
  }
}

const SlideDown = keyframes`
  0% { transform: translateY(-100%) }
  100% { transform: translateY(0) }
`;

const Rotate = keyframes`
  0% { transform: rotate(0deg) }
  100% { transform: rotate(360deg) }
`;

const StyledBar = styled.div`
  position: fixed;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  background: ${props => props.background};
  border-bottom: ${props => props.border};
  color: ${props => props.color};
  font-size: 1.125rem;
  padding: 0.875rem 2.5rem 0.875rem 0.875rem;
  top: 0;
  animation: ${SlideDown} ${duration}ms cubic-bezier(0.645, 0.045, 0.355, 1)
    forwards;
  z-index: 1002;

  i {
    font-family: Icons;
    font-style: normal;
    font-weight: 400;
    text-decoration: inherit;
    text-align: center;
    color: ${props => props.color};
    &:before {
      content: ${props => props.icon};
      background: 0 0 !important;
    }
  }
`;

const CloseButton = styled.button`
  display: "flex";
  position: absolute;
  right: 0;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: auto;
  padding: 0.875rem;
  background: none;
  border: none;
  cursor: pointer;

  i:before {
    content: "\f00d";
  }
`;

const Reconnecting = styled.i`
  animation: ${Rotate} 1500ms linear infinite;
  margin-left: 1rem;
  &:before {
    content: "\f110";
  }
`;

const GlobalStyle = createGlobalStyle`
  body {
    margin-top: ${props => (props.isVisible ? `${props.height}px` : 0)};
    transition: margin-top ${duration}ms cubic-bezier(0.645, 0.045, 0.355, 1);
  }
`;

const mapStateToProps = state => ({
  alerts: state.Alertbar.alerts
});

const mapDispatchToProps = { removeAlert };

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Alertbar);
