import React, { Fragment } from "react";
import { Segment, Header, Icon, Grid } from "semantic-ui-react";

// import context wrapper
import { Wrapper } from "../../../context";

// import sections
import Personal from "../../DeedTransfer/components/Personal";
import Contact from "../../DeedTransfer/components/Contact";
import PaymentForm from "../../PaymentForm";

const Section = (header, icon, subheader, content) => (
  <Segment vertical>
    <Grid stackable>
      <Grid.Column width="5">
        <Header as="h3">
          <Icon name={icon} />
          {header}
        </Header>
        <p>{subheader}</p>
      </Grid.Column>
      <Grid.Column width="11">{content}</Grid.Column>
    </Grid>
  </Segment>
);

const FormComponent = ({ context }) => (
  <Fragment>
    {Section(
      "Personal Info",
      "user",
      "Fill in some required personal information",
      <Personal context={context} />
    )}
    {Section(
      "Contact Info",
      "phone",
      "Let us know how to keep in touch",
      <Contact context={context} />
    )}
    <PaymentForm />
  </Fragment>
);

export default Wrapper(FormComponent);
