import React, { useState } from "react";
import PropTypes from "prop-types";

import { Dimmer, Loader } from "semantic-ui-react";

import FocusBar from "components/FocusBar";
import Controls from "./Controls";
import AjaxFn from "services/AjaxFn";
import { useOnMountEffect } from "effects";

const ChangeTemplate = props => {
  const [state, setState] = useState({ loading: false, data: false });

  const load = () => {
    setState({ loading: true, data: false });
    AjaxFn({
      url: "/apis/portal/agreements?action=get_types",
      success: data => setState({ data, loading: false }),
      failure: error => {
        window.toastr["error"](error);
        setState({ data: false, loading: false });
      }
    });
  };

  useOnMountEffect(load);

  return state.loading ? (
    <FocusBar.Content>
      <Dimmer active style={{ background: "none" }}>
        <Loader />
      </Dimmer>
    </FocusBar.Content>
  ) : state.data ? (
    <Controls reload={props.reload} options={state.data} ID={props.data.id} />
  ) : (
    <FocusBar.Content>
      <FocusBar.Button icon="fas fa-retweet" content="Reload" onClick={load} />
    </FocusBar.Content>
  );
};

ChangeTemplate.propTypes = {
  data: PropTypes.object.isRequired,
  reload: PropTypes.func.isRequired
  // ...
};

export default ChangeTemplate;
