import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { Segment, Header, List, Button } from "semantic-ui-react";
import { Link } from "react-router-dom";
import ChangeUnit from "./components/ChangeUnit";

export default class Unit extends Component {
  static propTypes = {
    reload: PropTypes.func.isRequired,
    data: PropTypes.object.isRequired
  };

  state = {
    open: false
  };

  handleOpen = () => this.setState({ open: true });
  handleClose = () => this.setState({ open: false });

  renderUnitTypeCiirus = () => {
    const { data } = this.props;
    const { unit, unit_type_ciirus: utc } = data;
    return (
      <Segment>
        <Header as="h2">
          <Header.Content>
            Unit
            <Header.Subheader>
              <Link to={`/browse/units/${unit.id}`}>
                {unit.resort.resort_company.name} {unit.resort.name} {unit.name}
              </Link>
            </Header.Subheader>
          </Header.Content>
        </Header>
        <List>
          <List.Item>
            <List.Icon name="phone" />
            <List.Content>
              <List.Header>Phone</List.Header>
              <List.Description>{unit.resort.phone}</List.Description>
            </List.Content>
          </List.Item>
          <List.Item>
            <List.Icon name="map" />
            <List.Content>
              <List.Header>Address</List.Header>
              <List.Description>
                {unit.resort.address}
                <br />
                {unit.resort.city}, {unit.resort.state} {unit.resort.country}
              </List.Description>
            </List.Content>
          </List.Item>
          {unit.unit_type === "ciirus" && (
            <Fragment>
              {utc && (
                <List.Item>
                  <List.Icon name="hotel" />
                  <List.Content>
                    <List.Header>Ciirus Listing</List.Header>
                    <List.Description>
                      <Link to={`/browse/units/${unit.id}/listing/{utc.id}`}>
                        {utc.name}
                      </Link>
                    </List.Description>
                  </List.Content>
                </List.Item>
              )}
            </Fragment>
          )}
        </List>
        <br />
        {data._actions.actions.edit_unit ? (
          <Fragment>
            <Button basic content="Change Unit" onClick={this.handleOpen} />
            <ChangeUnit
              data={data}
              open={this.state.open}
              handleClose={this.handleClose}
            />
          </Fragment>
        ) : (
          <p>You are not able to change the booking's unit right now.</p>
        )}
      </Segment>
    );
  };

  render() {
    switch (this.props.data.unit.unit_type) {
      case "ciirus":
        return this.renderUnitTypeCiirus();
      default:
        throw new Error("Unknown unit type");
    }
  }
}
