import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { Header, Icon, Step, Button } from "semantic-ui-react";
import { Link } from "react-router-dom";
import { isLastPushUpToDate } from "utils/units";
import naturalSort from "utils/naturalSort";
import CreateListing from "./components/CreateListing";
export default class Sidebar extends Component {
  static propTypes = {
    unit: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired
  };

  state = {
    collapsed: true
  };

  collapse = () => this.setState({ collapsed: true });
  expand = () => this.setState({ collapsed: false });

  render() {
    const { unit, match } = this.props;
    const { unitTab, unitID, listingTab, listingID } = match.params;
    const pages = unit.ciirus_units
      // naturalsort by name first
      .sort((a, b) => naturalSort(a.name, b.name))
      // only trim it if this.state.collapsed = true
      .slice(0, this.state.collapsed ? 3 : unit.ciirus_units.length)
      // map each listing into a Step.Group.pages compatible object
      .map(listing => {
        const availabilityUpdated = isLastPushUpToDate(
          listing.availability_pushed,
          unit.availability_interval
        );

        return {
          key: listing.id,
          icon: (() => {
            if (listing.freeze_availability) {
              return <Icon color="blue" name="snowflake outline" />;
            } else if (availabilityUpdated === "updated") {
              return <Icon color="green" name="check square outline" />;
            } else {
              return <Icon color="red" name="square outline" />;
            }
          })(),
          description: (() => {
            let status = "Out of date";
            if (listing.freeze_availability) {
              status = "Frozen";
            } else if (availabilityUpdated === "updated") {
              status = "Updated";
            }
            return (
              <div className="description">
                <b>Availability:</b> {status}
                <br />
                <b>Bookings:</b> {listing.bookings.length}
                <br />
                <b>CiiRUS ID:</b> {listing.ciirus_id || "none"}
              </div>
            );
          })(),
          title: (
            <div className="title" title={listing.name}>
              {listing.name.length > 14
                ? listing.name.substring(0, 14) + "..."
                : listing.name}
            </div>
          ),
          active: Number(listingID) === listing.id,
          as: Link,
          to: `/browse/units/${unitID}/listing/${listing.id}`
        };
      });

    return (
      <Fragment>
        <Header dividing>CiiRUS Listings</Header>
        <CreateListing unit={this.props.unit} reload={this.props.reload} />
        <Step.Group fluid vertical items={pages} />
        {unit.ciirus_units.length > 3 && (
          <Fragment>
            {this.state.collapsed ? (
              <Button
                basic
                content={`Show ${unit.ciirus_units.length - 3} more`}
                onClick={this.expand}
                fluid
              />
            ) : (
              <Button basic content="Show less" onClick={this.collapse} fluid />
            )}
          </Fragment>
        )}
      </Fragment>
    );
  }
}
