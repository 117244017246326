import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { loadBookings } from "redux/EOQReport/actions";
import { Button } from "semantic-ui-react";

export class Refresh extends Component {
  static propTypes = {
    loadBookings: PropTypes.func.isRequired
  };

  render() {
    return (
      <Button
        basic
        primary
        content={"Load"}
        onClick={this.props.loadBookings}
      />
    );
  }
}

const mapDispatchToProps = {
  loadBookings
};

export default connect(
  undefined,
  mapDispatchToProps
)(Refresh);
