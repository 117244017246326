import React, { useRef, useState } from "react";
import { useOutsideClick } from "react-use";
import PropTypes from "prop-types";
import { ThemeNavAction } from "theme/components";
import { danger } from "theme/colors";

const DeleteButton = props => {
  const { remove } = props;
  const ref = useRef(null);
  const [confirming, setConfirming] = useState(false);
  useOutsideClick(ref, () => {
    if (confirming) {
      setConfirming(false);
    }
  });

  if (confirming) {
    return (
      <ThemeNavAction title="Remove" onClick={remove} ref={ref}>
        <i className="far fa-trash-alt" style={{ color: danger }} />
      </ThemeNavAction>
    );
  } else {
    return (
      <ThemeNavAction title="Remove" onClick={() => setConfirming(true)}>
        <i className="far fa-trash-alt" />
      </ThemeNavAction>
    );
  }
};

DeleteButton.propTypes = {
  remove: PropTypes.func.isRequired
};

export default DeleteButton;
