import AjaxFn from "services/AjaxFn";

export const pushNews = announcement => ({
  type: "NEWSFEED_PUSH_NEWS",
  data: announcement
});

export const getNews = () => dispatch => {
  dispatch({ type: "NEWSFEED_REQUEST" });
  AjaxFn({
    url: "/apis/portal/announcements",
    data: {
      action: "READ",
      data: {
        limit: 15
      }
    },
    success: response => {
      dispatch({
        type: "NEWSFEED_REQUEST_SUCCESS",
        data: response
      });
    },
    failure: error => {
      dispatch({ type: "NEWSFEED_REQUEST_ERROR" });
      window.toastr.error(error);
    }
  });
};
