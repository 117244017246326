import {
  getOrderedData,
  getFilteredData,
  getSearchedData,
  getPaginatedData,
  getFinalData
} from "./selector.js";
/**
 *	Instantiates a table and stores it in the array of tables within the store
 *	@param {String} tableName (must be unique)
 *  @param {Object} tableData (mostly optional configs - see reducer.js)
 *  @param {Ajax} AJAX (an instance of the AJAX helper class)
 */
export const createTable = (tableName, tableData = {}, AJAX = {}) => ({
  type: "PORTAL_TABLES_CREATE_TABLE",
  tableName,
  tableData,
  AJAX
});
/**
 *	Set the loading state for the table
 *	@param {String} tableName
 */
export const setLoading = tableName => ({
  type: "PORTAL_TABLES_SET_LOADING",
  tableName
});
/**
 *	Set the error state for the table
 *	@param {String} tableName
 *  @param {String} value (the error message)
 */
export const setError = (tableName, value) => ({
  type: "PORTAL_TABLES_SET_ERROR",
  tableName,
  value
});
/**
 *	Set the ordering of data for the table
 *	@param {String} tableName
 *  @param {Array} order (array like so: [[1, 'asc'], [2, 'desc']])
 */
export const setOrder = (tableName, order) => dispatch => {
  dispatch({
    type: "PORTAL_TABLES_SET_ORDER",
    tableName,
    order
  });
  dispatch(setOrderedData(tableName));
};
/**
 *	Set the text-based search filter for the table
 *	@param {String} tableName
 *  @param {String} text
 */
export const setSearch = (tableName, search) => dispatch => {
  dispatch({
    type: "PORTAL_TABLES_SET_SEARCH",
    tableName,
    search
  });
  dispatch(setSearchedData(tableName));
};
/**
 *	Set the max # of rows to show
 *	@param {String} tableName
 *  @param {Integer} amount
 */
export const setShown = (tableName, amount) => dispatch => {
  dispatch({
    type: "PORTAL_TABLES_SET_SHOWN",
    tableName,
    amount
  });
  dispatch(setPaginatedData(tableName));
};
/**
 *	Set the page number
 *	@param {String} tableName
 *  @param {Integer} page
 */
export const setPage = (tableName, page) => dispatch => {
  dispatch({
    type: "PORTAL_TABLES_SET_PAGE",
    tableName,
    page
  });
  dispatch(setPaginatedData(tableName));
};
/**
 *	Set the filters
 *	@param {String} tableName
 *  @param {Array} filters
 */
export const setFilters = (tableName, filters) => dispatch => {
  dispatch({
    type: "PORTAL_TABLES_SET_FILTERS",
    tableName,
    filters
  });
  dispatch(setFilteredData(tableName));
};
/**
 *	Forces a reload
 *	@param {String} tableName
 *	@param {Function} fn (preprocessor)
 */
export const fetch = (tableName, fn = m => m) => (dispatch, getState) => {
  dispatch(setLoading(tableName));
  getState().PortalTables[tableName].AJAX.reload(
    // validation function
    data => data.status,
    // success callback
    ({ message }) => dispatch(setRequestData(tableName, fn(message))),
    // error callback
    ({ message }) => dispatch(setError(tableName, message))
  );
};
/**
 *	Updates the table with the data
 *	@param {String} tableName
 *  @param {Array} data
 */
export const setRequestData = (tableName, data) => dispatch => {
  dispatch({
    type: "PORTAL_TABLES_SET_DATA",
    tableName,
    dataType: "requestData",
    data
  });
  dispatch(setFilteredData(tableName));
};
/**
 *	Updates the table with filtered data
 *	@param {String} tableName
 */
export const setFilteredData = tableName => (dispatch, getState) => {
  const { filters, requestData } = getState().PortalTables[tableName];
  dispatch({
    type: "PORTAL_TABLES_SET_DATA",
    dataType: "filteredData",
    tableName,
    data: getFilteredData(requestData, filters)
  });
  dispatch(setSearchedData(tableName));
};
/**
 *	Updates the table with searched data
 *	@param {String} tableName
 */
export const setSearchedData = tableName => (dispatch, getState) => {
  const { filteredData, search, cols } = getState().PortalTables[tableName];
  dispatch({
    type: "PORTAL_TABLES_SET_DATA",
    dataType: "searchedData",
    tableName,
    data: !search ? filteredData : getSearchedData(filteredData, search, cols)
  });
  dispatch(setOrderedData(tableName));
};
/**
 *	Updates the table with ordered data
 *	@param {String} tableName
 */
export const setOrderedData = tableName => (dispatch, getState) => {
  const { searchedData, cols, order } = getState().PortalTables[tableName];
  dispatch({
    type: "PORTAL_TABLES_SET_DATA",
    dataType: "orderedData",
    tableName,
    data: !order.length
      ? searchedData
      : getOrderedData(searchedData, cols, order)
  });
  dispatch(setPaginatedData(tableName));
};
/**
 *	Updates the table with paginated data
 *	@param {String} tableName
 */
export const setPaginatedData = tableName => (dispatch, getState) => {
  const { orderedData, show, page } = getState().PortalTables[tableName];
  if (orderedData.length) {
    // make sure we arent past the max page
    let div = Math.floor(orderedData.length / show);
    let maxPage = (orderedData.length % show ? div + 1 : div) - 1;
    if (page > maxPage) {
      dispatch(setPage(tableName, maxPage));
      return false;
    }
  } else if (page !== 0) {
    // make sure we aren't on a page that isn't the first if we have no data
    dispatch(setPage(tableName, 0));
    return false;
  }
  // if we're still ok, then get the paginated data for this page
  dispatch({
    type: "PORTAL_TABLES_SET_DATA",
    dataType: "paginatedData",
    tableName,
    data: getPaginatedData(orderedData, show, page)
  });
  dispatch(setFinalData(tableName));
};
/**
 *	Updates the table with finalized data
 *	@param {String} tableName
 */
export const setFinalData = tableName => (dispatch, getState) => {
  const { paginatedData } = getState().PortalTables[tableName];
  dispatch({
    type: "PORTAL_TABLES_SET_DATA",
    dataType: "finalData",
    tableName,
    data: getFinalData(paginatedData)
  });
};
