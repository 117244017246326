import React from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";

// all authorized-only scenes
import AuthorizedScenes from "./scenes-authed.js";

// external routes
import AgreementExternal from "./AgreementExternal";
import LoginPage from "./Login";

// tests
import SocketryTest from "./_tests/SocketryTest";
import AlertsTest from "./_tests/AlertsTest";
import FocusBarTest from "./_tests/FocusBarTest";

export default () => (
  <BrowserRouter>
    <Switch>
      {process.env.NODE_ENV === "development" && (
        <Route exact path="/tests/socketry" component={SocketryTest} />
      )}
      {process.env.NODE_ENV === "development" && (
        <Route exact path="/tests/alerts" component={AlertsTest} />
      )}
      {process.env.NODE_ENV === "development" && (
        <Route exact path="/tests/bar" component={FocusBarTest} />
      )}
      <Route exact path="/login" component={LoginPage} />
      <Route exact path="/agreements/:id/:key" component={AgreementExternal} />
      <AuthorizedScenes />
    </Switch>
  </BrowserRouter>
);
