import React, { Component } from "react";
import PropTypes from "prop-types";
import { Form } from "semantic-ui-react";

import { isValidFinance } from "utils/numbers";

class Amount extends Component {
	constructor(props) {
		super(props);
		this.state = {
			quoted_amount: props.quoted_amount,
			processed_amount: props.processed_amount
		};
	}

	componentWillReceiveProps(nextProps) {
		if (nextProps.quoted_amount !== this.props.quoted_amount) {
			this.setState({ quoted_amount: nextProps.quoted_amount });
		}
		if (nextProps.quoted_amount !== this.props.processed_amount) {
			this.setState({ processed_amount: nextProps.processed_amount });
		}
	}

	handleChange = (e, { name, value }) => {
		const { handleChange } = this.props;
		if (isValidFinance(value)) {
			this.setState({ [name]: value }, handleChange(e, { name, value }));
		}
	};

	triggerChange = (e, { name }) => {
		const { onChange } = this.props;
		if (typeof onChange === "function") {
			onChange(this.state[name]);
		}
	};

	handleBlur = e => {
		const { name, value } = e.target;
		const float = parseFloat(value);
		const floatValue = isNaN(float) ? "0.00" : float.toFixed(2);

		this.setState(
			{ [name]: floatValue },
			this.triggerChange(e, { name, value })
		);
	};

	render() {
		const { processed_amount, quoted_amount } = this.state;

		return (
			<Form.Group widths="equal">
				<Form.Input
					label="Quoted Amount"
					name="quoted_amount"
					id="quoted_amount"
					onChange={this.handleChange}
					onBlur={this.handleBlur}
					value={quoted_amount}
					autoComplete="off"
					placeholder="0.00"
					// required
				/>
				<Form.Input
					label="Processed Amount"
					name="processed_amount"
					id="processed_amount"
					onChange={this.handleChange}
					onBlur={this.handleBlur}
					value={processed_amount}
					autoComplete="off"
					placeholder="0.00"
					// required
				/>
			</Form.Group>
		);
	}
}

Amount.propTypes = {
	handleChange: PropTypes.func.isRequired,
	processed_amount: PropTypes.string,
	quoted_amount: PropTypes.string
};

Amount.defaultProps = {
	processed_amount: "0.00",
	quoted_amount: "0.00"
};

export default Amount;
