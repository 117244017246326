import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";

import { Header, Icon } from "semantic-ui-react";

import FocusBar from "components/FocusBar";

export default class Sidebar extends Component {
  static propTypes = {
    memberID: PropTypes.string.isRequired,
    membershipID: PropTypes.string,
    memberTab: PropTypes.string,
    membershipTab: PropTypes.string,
    memberData: PropTypes.shape({
      data: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]).isRequired,
      loading: PropTypes.bool.isRequired,
      error: PropTypes.any.isRequired
    }).isRequired,
    membershipData: PropTypes.shape({
      data: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]).isRequired,
      loading: PropTypes.bool.isRequired,
      error: PropTypes.any.isRequired
    }).isRequired
  };

  render() {
    const { memberData, memberTab, membershipTab, membershipID } = this.props;
    const member = memberData.data;
    const { memberships } = member;

    return (
      <FocusBar>
        <FocusBar.Content>
          <br />
          <FocusBar.Generic>
            <Header as="h3" inverted>
              {member.firstname} {member.lastname}
              <Header.Subheader>
                {!member.deleted ? (
                  <Fragment>
                    <Icon name="checkmark" color="green" />
                    Member is active
                  </Fragment>
                ) : (
                  <Fragment>
                    <Icon name="cancel" color="red" />
                    Member is inactive
                  </Fragment>
                )}
              </Header.Subheader>
            </Header>
          </FocusBar.Generic>
          <div className="tutorial-memberpage-sidebar">
            <div className="tutorial-memberpage-sidebar-member">
              <FocusBar.Nav
                active={!membershipID && !memberTab}
                href={`/browse/members/${member.id}`}
                content="Summary"
                icon="far fa-folder"
                className="tutorial-memberpage-sidebar-summary"
              />
              <FocusBar.Nav
                active={memberTab === "bookings"}
                href={`/browse/members/${member.id}/bookings`}
                content="Bookings"
                icon="far fa-address-book"
              />
              <IssuesNav member={member} memberTab={memberTab} />
              <FocusBar.Nav
                active={memberTab === "comments"}
                href={`/browse/members/${member.id}/comments`}
                content="Comments"
                icon="far fa-comment"
              />
            </div>
            <FocusBar.Divider />
            <br />
            <FocusBar.Generic>
              <Header as="h3" inverted>
                Memberships
                <Header.Subheader>
                  {memberships.filter(r => r.is_active).length} active
                  membership(s)
                </Header.Subheader>
              </Header>
            </FocusBar.Generic>
            <div className="tutorial-memberpage-sidebar-memberships">
              {memberships.map((membership, key) => (
                <MembershipNav
                  membership={membership}
                  membershipID={membershipID}
                  member={member}
                  tab={membershipTab}
                  key={key}
                />
              ))}
            </div>
          </div>
        </FocusBar.Content>
        <FocusBar.Actions>
          <FocusBar.Nav
            content="Add Membership"
            icon="fas fa-plus"
            className="tutorial-memberpage-sidebar-add-memberships"
            href={`/wizards/members/${member.id}`}
          />
        </FocusBar.Actions>
      </FocusBar>
    );
  }
}

const MembershipNav = ({ membership, membershipID, member, tab }) => {
  const active = membership.id.toString() === membershipID;
  // const props = {
  //   active,
  //   content: membership.resort_company.name + " " + membership.level,
  //   icon: membership.is_active ? "far fa-check-square" : "far fa-square"
  // };
  // const href = `/browse/members/${member.id}/membership/${membership.id}`;

  return (
    <FocusBar.Nav
      {...{
        active,
        content: membership.resort_company.name + " " + membership.level,
        icon: membership.is_active ? "far fa-check-square" : "far fa-square",
        href: `/browse/members/${member.id}/membership/${membership.id}`
      }}
    />
  );
  // return (
  //   <FocusBar.NavDrawer {...props}>
  //     <br />
  //     <FocusBar.Generic>
  //       <Header as="h3" inverted>
  //         {membership.resort_company.name} {membership.level}
  //         <Header.Subheader>
  //           {member.firstname} {member.lastname}
  //         </Header.Subheader>
  //       </Header>
  //     </FocusBar.Generic>
  //     <FocusBar.Nav
  //       content="Summary"
  //       icon="far fa-folder"
  //       href={`${href}`}
  //       active={active && !tab}
  //     />
  //     <FocusBar.Nav
  //       content="Bookings"
  //       icon="far fa-address-book"
  //       href={`${href}/bookings`}
  //       active={active && tab === "bookings"}
  //     />
  //     <FocusBar.Nav
  //       content="Points"
  //       icon="fas fa-cubes"
  //       href={`${href}/points`}
  //       active={active && tab === "points"}
  //     />
  //   </FocusBar.NavDrawer>
  // );
};

const IssuesNav = ({ memberTab, member }) => {
  const openIssues = member.issues.filter(row => row.closed_on === null).length;

  const props = {
    active: memberTab === "issues",
    href: `/browse/members/${member.id}/issues`,
    content: "Issues",
    className: "tutorial-memberpage-sidebar-issues"
  };

  if (openIssues === 1) {
    props.icon = "far fa-dot-circle";
    props.description = "1 unresolved issue";
    props.color = "red";
    // props.inverted = false;
    props.basic = false;
    // props.overrideHoverBackground = false;
  } else if (openIssues > 1) {
    props.icon = "far fa-dot-circle";
    props.description = `${openIssues} unresolved issues`;
    props.color = "red";
    // props.inverted = false;
    props.basic = false;
    // props.overrideHoverBackground = false;
  } else {
    props.icon = "far fa-check-circle";
  }

  return <FocusBar.Nav {...props} />;
};
