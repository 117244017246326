import React from "react";
import { Container, Header, Grid } from "semantic-ui-react";
import Layout from "components/Layout";
import ContentManager from "components/ContentManager";
import { Ajax } from "services/Ajax";
import List from "./components/List";
import Editor from "./components/Editor";
import Wizard from "./components/Wizard";
import Delete from "./components/Delete";
import Breadcrumb from "./components/Breadcrumb";
export default class Accounts extends React.Component {
  getConfig = ContentManager => ({
    List: List(ContentManager),
    Wizard: {
      JSX: Wizard,
      Defaults: {
        restricted: true,
        mutable: true
      },
      AJAX: {
        instance: new Ajax({ url: "/apis/portal/accountroles" }),
        onSuccess: () => {
          window.toastr.success();
        },
        data: data => {
          data.restricted = data.restricted ? 1 : 0;
          data.mutable = 1;
          return { action: "create", data };
        }
      }
    },
    Editor: {
      JSX: Editor,
      AJAX: {
        instance: new Ajax({ url: "/apis/portal/accountroles" }),
        data: ({ id: ID }, data) => {
          if (typeof data.restricted !== "undefined") {
            data.restricted = data.restricted ? 1 : 0;
          }
          return { action: "update", data: { ID, ...data } };
        },
        onSuccess: () => {
          window.toastr.success();
        }
      }
    },
    Delete: {
      JSX: Delete,
      AJAX: {
        instance: new Ajax({ url: "/apis/portal/accountroles" }),
        onSuccess: () => {
          window.toastr.success();
        },
        data: ({ id: ID }) => ({ action: "delete", data: { ID } })
      }
    }
  });
  /**
   * 	Render fn
   */
  render() {
    return (
      <Layout noBar>
        <Breadcrumb />
        <Container style={{ paddingBottom: "4rem" }}>
          <Header as={"h1"} dividing style={{ marginTop: "2rem" }}>
            Account Roles
            <div className={"sub header"}>
              Below are all of the pages registered in the database.
            </div>
          </Header>
          <ContentManager config={this.getConfig}>
            {({ components: { List, Editor, Wizard } }) => (
              <React.Fragment>
                <Grid>
                  <Grid.Column width={10}>{List}</Grid.Column>
                  <Grid.Column width={6}>{Editor}</Grid.Column>
                </Grid>
                {Wizard}
              </React.Fragment>
            )}
          </ContentManager>
          <br />
        </Container>
      </Layout>
    );
  }
}
