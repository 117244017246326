import React from "react";
import { connect } from "react-redux";

import Breadcrumb, { LoadedBreadcrumb } from "./components/Breadcrumb";
import Sidebar from "./components/Sidebar";
import Summary from "./components/Summary";
import Finances from "./components/Finances";
import Mail from "./components/Mail";
import Actions from "./components/Actions";
import Comments from "./components/Comments";
import ChangesWarning from "./components/ChangesWarning";
import Issues from "./components/Issues";
import Audits from "./components/Audits";
import BookingStatusMessages from "./components/BookingStatusMessages";

import Layout from "components/Layout";
import WithTour from "components/WithTour";
import WithState from "components/WithState";
import { bookingPageTour } from "fixtures/tutorials";

import { Container, Grid } from "semantic-ui-react";
import { reloadBooking } from "redux/BookingPage/actions";

class BookingPageRouter extends React.Component {
  componentDidMount() {
    this.props.reload(this.props.bookingID);
  }
  getComponent = () => {
    switch (this.props.bookingTab) {
      case "finances":
        return <Finances />;
      case "mail":
        return <Mail />;
      case "actions":
        return <Actions />;
      case "audits":
        return <Audits />;
      case "issues":
        return <Issues />;
      // case "comments":
      //   return <Comments />;
      default:
        return <Summary />;
    }
  };
  getRenderedComponent = () => {
    const { data } = this.props.booking;
    const component = this.getComponent();
    return React.cloneElement(component, {
      data,
      reload: this.props.reload
    });
  };
  render() {
    const { bookingTab, bookingID, booking, saving } = this.props;
    const { loading, error, data } = booking;
    return (
      <Layout noBar>
        <Breadcrumb />
        <Container>
          <br />
          <WithState loading={saving || loading} error={error}>
            {data && (
              <WithTour {...bookingPageTour}>
                <div
                  style={{ background: "#fff" }}
                  className="tutorial-bookpage-main"
                >
                  <LoadedBreadcrumb data={data} />
                  <Grid>
                    <Grid.Column width={4}>
                      <Sidebar bookingTab={bookingTab} data={data} />
                      <ChangesWarning />
                    </Grid.Column>
                    <Grid.Column stretched width={12}>
                      <BookingStatusMessages
                        data={data}
                        reload={this.props.reload}
                      />
                      <div className="tutorial-bookpage-content">
                        {this.getRenderedComponent()}
                      </div>
                      <div className="tutorial-bookpage-comments">
                        <Comments data={data} />
                      </div>
                    </Grid.Column>
                  </Grid>
                </div>
              </WithTour>
            )}
          </WithState>
        </Container>
      </Layout>
    );
  }
}
const mapStateToProps = state => ({
  booking: state.BookingPage.booking,
  saving: state.BookingPage.savingChanges
  // ...
});
const mapDispatchToProps = dispatch => ({
  reload: ID => dispatch(reloadBooking(ID))
  // ...
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BookingPageRouter);
