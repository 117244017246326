import React from "react";
import AjaxComponent from "components/AjaxComponent";
import Layout from "components/Layout";
import Breadcrumb from "./components/Breadcrumb";
import Spreadsheet from "./components/Spreadsheet";
import naturalSort from "utils/naturalSort";
import injectSheet from "react-jss";
const formatter = value => {
  switch (value[0]) {
    case null:
      return "Unrestricted";
    case false:
      switch (value[1]) {
        case null:
          return "Not allowed";
        case false:
          return "Not allowed (and forced)";
        case true:
          return "Allowed (forced)";
        default:
          return "Unknown";
      }
    case true:
      switch (value[1]) {
        case null:
          return "Allowed";
        case false:
          return "Not Allowed (forced)";
        case true:
          return "Allowed (and forced)";
        default:
          return "Unknown";
      }
    default:
      return <p>Unknown</p>;
  }
};
const classFormatter = (value, classes) => {
  switch (value[0]) {
    case null:
      return classes.unrestricted;
    case false:
      switch (value[1]) {
        case null:
          return classes.negative;
        case false:
          return classes.warning;
        case true:
          return classes.warning;
        default:
          return "";
      }
    case true:
      switch (value[1]) {
        case null:
          return classes.positive;
        case false:
          return classes.warning;
        case true:
          return classes.warning;
        default:
          return "";
      }
    default:
      return "";
  }
};
class AccountManagerSpreadsheet extends React.Component {
  state = {
    // blank str || a userid || a right name
    query: "",
    // 'right' || 'user'
    mode: "right",
    // 'horiz' || 'vert'
    align: "vert",
    // 'include', 'exclude', 'exclusive'
    disabled_mode: "include",
    // 'include', 'exclude', 'exclusive'
    restricted_mode: "include"
  };
  /**
   *	Returns true if a row passes the disabled filter, false if not
   *	@param {Object} row (account)
   *	@return {Bool}
   */
  passesDisabledMode = row => {
    switch (this.state.disabled_mode) {
      case "include":
        return true;
      case "exclude":
        return !row.disabled;
      case "exclusive":
        return !!row.disabled;
      default:
        return false;
    }
  };
  /**
   *	Returns true if a row passes the restricted filter, false if not
   *	@param {Object} row (account)
   *	@return {Bool}
   */
  passesRestrictedMode = row => {
    switch (this.state.restricted_mode) {
      case "include":
        return true;
      case "exclude":
        return !row.account_role.restricted;
      case "exclusive":
        return !!row.account_role.restricted;
      default:
        return false;
    }
  };
  /**
   *	Map over accounts, replace account company IDs with account company references
   *	@param {Object} account
   *	@param {Object} data (from ajax)
   *	@return {Object}
   */
  mapAccounts = (account, data) => {
    const fn = row => row.id === account.account_role.id;
    return {
      ...account,
      account_role: data.account_roles.find(fn)
    };
  };
  /**
   *	Callback for filter that removes accounts that dont pass the queries
   *	@param {Object} row (account)
   *	@return {bool}
   */
  filterAccounts = row => {
    return (
      !row.disabled &&
      this.passesDisabledMode(row) &&
      this.passesRestrictedMode(row)
    );
  };
  /**
   *	Naturalsort accounts by firstname, lastname
   *	@param {Object} a (account)
   *	@param {Object} b (account)
   */
  sortAccounts = (a, b) => {
    let aname = a.firstname + " " + a.lastname;
    let bname = b.firstname + " " + b.lastname;
    return naturalSort(aname, bname);
  };
  /**
   *	Run various methods against dataset to prepare accounts list
   *	@param {Array} data (from ajax)
   *	@return {Array} (accounts)
   */
  processAccounts = data => {
    return data.accounts
      .map(account => this.mapAccounts(account, data))
      .filter(this.filterAccounts)
      .sort(this.sortAccounts);
  };
  /**
   *	Run various methods against dataset to prepare rights list
   *	@param {Array} data (from ajax)
   *	@return {Array} (rights)
   */
  processRights = data => {
    return data.rights.sort((a, b) => naturalSort(a, b));
  };
  /**
   *	Create the columns for the spreadsheet based on state
   *  @param {Object} data (from ajax)
   *  @return {Array}
   */
  createColumns = data => {
    const columns = [];
    if (this.state.query === "") {
      if (this.state.align === "horiz") {
        // rights across top, unfiltered
        columns.push({ key: "name", name: "Name" });
        this.processRights(data).forEach(right => {
          columns.push({
            key: right,
            name: right,
            formatter,
            class: value => classFormatter(value, this.props.classes)
          });
        });
      } else {
        // users across top, unfiltered
        columns.push({ key: "right", width: "200", name: "Right" });
        this.processAccounts(data).forEach(account => {
          columns.push({
            key: account.id,
            name: `${account.firstname} ${account.lastname}`,
            formatter,
            class: value => classFormatter(value, this.props.classes)
          });
        });
      }
    } else {
      if (this.state.mode === "right") {
        // users along left, filtered by a right
        columns.push({ key: "user", name: "User" });
      } else {
        // rights along left, filtered by a user
        columns.push({ key: "right", name: "Right" });
      }
      columns.push({
        key: "role",
        name: "Role Allows",
        formatter: value => {
          switch (value) {
            case false:
              return "No";
            case null:
              return "Unrestricted";
            case true:
              return "Yes";
            default:
              return "Unknown";
          }
        },
        class: value => {
          switch (value) {
            case false:
              return this.props.classes.negative;
            case null:
              return this.props.classes.unrestricted;
            case true:
              return this.props.classes.positive;
            default:
              return "";
          }
        }
      });
      columns.push({
        key: "override",
        name: "User Override",
        formatter: value => {
          switch (value) {
            case false:
              return "No";
            case null:
              return "";
            case true:
              return "Yes";
            default:
              return "Unknown";
          }
        },
        class: value => {
          switch (value) {
            case false:
              return this.props.classes.warning;
            case null:
              return "";
            case true:
              return this.props.classes.warning;
            default:
              return "";
          }
        }
      });
      columns.push({
        key: "final",
        name: "Final",
        formatter: value => {
          switch (value) {
            case false:
              return "No";
            case true:
              return "Yes";
            default:
              return "Unknown";
          }
        },
        class: value => {
          switch (value) {
            case false:
              return this.props.classes.negative;
            case true:
              return this.props.classes.positive;
            default:
              return "";
          }
        }
      });
    }
    return columns;
  };
  /**
   *	Check if an role can do something
   *  @param {Object} account
   *  @param {String} right
   *  @return {Bool/Null} null if unrestricted
   */
  canRoleDo = (account, right) => {
    if (account.account_role.restricted) {
      const found = account.account_role.rights.find(r => r.right === right);
      if (typeof found === "undefined") {
        return false;
      } else {
        return found.value;
      }
    } else {
      return null;
    }
  };
  /**
   *	Check if an account can do something
   *  @param {Object} account
   *  @param {String} right
   *  @return {Bool/Null} null if no override is set
   */
  canAccountDo = (account, right) => {
    const found = account.rights.find(r => r.right === right);
    if (typeof found === "undefined") {
      return null;
    } else {
      return found.value;
    }
  };
  /**
   *	Check if an account can do something
   *  @param {Bool/Null} roleCanDo
   *  @param {Bool/Null} accountCanDo
   *  @return {Bool}
   */
  canFinalDo = (roleCanDo, accountCanDo) => {
    if (roleCanDo === null) {
      return true;
    } else {
      if (accountCanDo === null) {
        return roleCanDo;
      } else {
        return accountCanDo;
      }
    }
  };
  /**
   *	Create the columns for the spreadsheet based on state
   *  @param {Object} data (from ajax)
   *  @return {Array}
   */
  createRows = data => {
    const accounts = this.processAccounts(data);
    const rights = this.processRights(data);
    /**
     *	For roles:
     *	TRUE = allowed, FALSE = disallowed, NULL = UNRESTRICTED
     *	For accounts:
     *  TRUE = override yes, FALSE = override no, NULL = no override
     *  For final:
     *	TRUE = allowed, FALSE = disallowed
     */
    if (this.state.query === "") {
      if (this.state.align === "horiz") {
        // rights across top, unfiltered
        return accounts.map(account => {
          let row = {
            name: `${account.firstname} ${account.lastname}`
          };
          rights.forEach(right => {
            row[right] = [
              this.canRoleDo(account, right),
              this.canAccountDo(account, right)
            ];
          });
          return row;
        });
      } else {
        // users across top, unfiltered
        return rights.map(right => {
          let row = { right };
          accounts.forEach(account => {
            row[account.id] = [
              this.canRoleDo(account, right),
              this.canAccountDo(account, right)
            ];
          });
          return row;
        });
      }
    } else {
      let row = {};
      if (this.state.mode === "right") {
        return accounts.map(account => {
          const rcd = this.canRoleDo(account, this.state.query);
          const acd = this.canAccountDo(account, this.state.query);
          let row = {
            user: `${account.firstname} ${account.lastname}`,
            role: rcd,
            override: acd,
            final: this.canFinalDo(rcd, acd)
          };
          return row;
        });
      } else {
        return rights.map(right => {
          const fn = row => row.id === this.state.query;
          const account = accounts.find(fn);
          const rcd = this.canRoleDo(account, right);
          const acd = this.canAccountDo(account, right);
          let row = {
            right,
            role: rcd,
            override: acd,
            final: this.canFinalDo(rcd, acd)
          };
          return row;
        });
      }
    }
  };
  /**
   *	Create the configuration for the spreadsheet
   *  @param {Object} data (from ajax)
   *  @return {Object}}
   */
  createData = data => {
    return {
      datagrid: {
        columns: this.createColumns(data),
        rows: this.createRows(data)
      },
      request: data,
      state: {
        ...this.state
      },
      functions: {
        setAlignment: value => this.setState({ align: value }),
        setMode: value => this.setState({ mode: value, query: "" }),
        setQuery: value => this.setState({ query: value }),
        processAccounts: this.processAccounts,
        processRights: this.processRights
        // ...
      }
    };
  };
  render() {
    const ajaxConfig = {
      ajaxConfig: {
        url: "/apis/portal/accounts",
        data: {
          action: "generate_spreadsheet"
        }
      }
    };
    return (
      <Layout noBar>
        <Breadcrumb />
        <AjaxComponent {...ajaxConfig}>
          {({ data }) => {
            return <Spreadsheet data={this.createData(data)} />;
          }}
        </AjaxComponent>
      </Layout>
    );
  }
}

const styles = {
  negative: {
    background: "rgba(255,0,0,.15)"
  },
  positive: {
    background: "rgba(0,255,0,.15)"
  },
  warning: {
    background: "rgba(255,255,0,.15)"
  },
  unrestricted: {
    background: "#2a2b2b",
    color: "#fff"
  }
};

export default injectSheet(styles)(AccountManagerSpreadsheet);
