import { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { setStatus } from "redux/User/actions";
import { createAlert, removeAlertByIdentifier } from "redux/Alertbar/actions";

import getSocketHandler from "services/sockets";
const socket = getSocketHandler();

export class TimepunchStatus extends Component {
  static propTypes = {
    user: PropTypes.object.isRequired,
    setStatus: PropTypes.func.isRequired,
    createAlert: PropTypes.func.isRequired,
    alerts: PropTypes.array.isRequired
  };

  componentDidMount() {
    const { user } = this.props;

    if (typeof user.id !== "undefined") {
      socket
        .connect()
        .joinRoom(`accounts/${user.id}`)
        .on("client_timepunched", this.handleSocketEvent);
    }
  }

  componentWillUnmount() {
    socket.off();
  }

  handleSocketEvent = data => {
    const {
      setStatus,
      createAlert,
      alerts,
      removeAlertByIdentifier
    } = this.props;

    setStatus(data.clocked_in);

    // This shows a warning to the user when they've been clocked out
    if (!data.clocked_in) {
      // we don't want to make multiple, so let's check for one being rendered
      let notify = !alerts.filter(a => a.identifier === "clockedOut").length;

      if (notify) {
        createAlert("warning", "You have been clocked out.", "clockedOut");
      }
    } else {
      removeAlertByIdentifier("clockedOut");
    }
  };

  render() {
    return null;
  }
}

const mapStateToProps = state => ({
  user: state.User,
  alerts: state.Alertbar.alerts
});

const mapDispatchToProps = {
  setStatus,
  createAlert,
  removeAlertByIdentifier
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TimepunchStatus);
