export default {
  background: {
    background: "#eee",
    padding: "2rem"
  },
  checks: {
    pageBreakAfter: "always !important",
    height: "11in",
    width: "8.5in",
    background: "#fff",
    margin: "0 auto",
    marginBottom: "2rem"
  },
  checkContainer: {
    position: "relative"
  },
  checkBackground: {
    // backgroundImage: "url(/img/backgrounds/check-background.png)",
    // backgroundRepeat: "no-repeat",
    // backgroundSize: "cover",
    // opacity: ".4",
    position: "absolute",
    width: "8.5in",
    height: "3.5in",
    borderBottom: "1px solid #ccc"
  },
  warningContainer: {
    background: "#eee",
    padding: "2rem",
    paddingBottom: "0 !important"
  },
  warning: {
    width: "8.5in",
    margin: "0 auto",
    background: "#fff",
    padding: "2rem"
  },
  "@media print": {
    background: {
      background: "#fff",
      paddingTop: "0",
      margin: "0",
      padding: "0"
    },
    checks: {
      margin: "0"
    },
    checkBackground: {
      display: "none"
    },
    warningContainer: {
      display: "none"
    }
  }
};
