import React, { Component } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { Button } from "semantic-ui-react";
import { MyCalWrapper } from "components/MyCal";

export default class ScheduleControls extends Component {
  static propTypes = {
    handleChange: PropTypes.func.isRequired,
    value: PropTypes.string // string or null
  };

  render() {
    const { handleChange, value } = this.props;

    const setLocked = (
      <Button
        basic
        content="Lock"
        icon="lock"
        onClick={() => handleChange(null)}
      />
    );

    const setUnlocked = (
      <Button
        secondary
        content="Unlock"
        icon="lock"
        onClick={() => handleChange("10:00:00")}
      />
    );

    const setRestricted = (
      <Button
        basic
        content="Restrict"
        onClick={() => handleChange("10:00:00")}
      />
    );

    const setRestrictionValue = (
      <MyCalWrapper
        value={moment("0001-01-01 " + value)}
        format="HH:mm:ss"
        type="time"
        style={{ flexGrow: 1 }}
        onChange={(e, { value }) => handleChange(value)}
      />
    );

    const setUnrestricted = (
      <Button
        basic
        content="Unrestrict"
        style={{ marginLeft: ".5rem" }}
        onClick={() => handleChange("00:00:00")}
      />
    );

    switch (value) {
      case null:
        return (
          <div style={{ display: "flex" }}>
            <div style={{ flexGrow: 1 }}>
              <p>Locked</p>
            </div>
            {setUnlocked}
          </div>
        );

      case "00:00:00":
        return (
          <div style={{ display: "flex" }}>
            <div style={{ flexGrow: 1 }}>
              <p>Unrestricted</p>
            </div>
            {setRestricted}
            {setLocked}
          </div>
        );

      default:
        return (
          <div style={{ display: "flex" }}>
            {setRestrictionValue}
            {setUnrestricted}
            {setLocked}
          </div>
        );
    }
  }
}
