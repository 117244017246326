import React, { Component, Fragment } from "react";
import { Segment, Form, Header, Button, Divider } from "semantic-ui-react";

import Subscription from "./components/Subscription";

export default class Subscriptions extends Component {
  state = {
    instances: [
      {
        room: "announcements/2/2",
        event: "client_announcement_posted"
      }
    ],
    room: "",
    event: ""
  };

  /**
   * Creates a new subscription instance
   */
  createInstance = e => {
    e.preventDefault();
    this.setState(({ instances, room, event }) => ({
      instances: instances.concat({
        event,
        room
      }),
      event: "",
      room: ""
    }));
  };

  /**
   * Deletes a subscription instance
   * @param {Integer} index
   */
  deleteInstance = index => {
    this.setState(({ instances }) => ({
      instances: instances.filter((el, ix) => ix !== index)
    }));
  };

  render() {
    const { instances, event, room } = this.state;
    return (
      <Fragment>
        <Header content="Subscriptions" />
        <Segment secondary>
          {instances.length === 0 && <p>No active subscriptions.</p>}
          {instances.map((instance, index) => (
            <Fragment key={index}>
              {index !== 0 && <Divider />}
              <Subscription
                room={instance.room}
                event={instance.event}
                destroy={() => this.deleteInstance(index)}
                update={(src, value) => this.updateInstance(index, src, value)}
              />
            </Fragment>
          ))}
          <Divider />
          <Form onSubmit={this.createInstance}>
            <Form.Input
              label="Room"
              placeholder="accounts/501"
              value={room}
              onChange={(e, { value }) => this.setState({ room: value })}
            />
            <Form.Input
              label="Event"
              placeholder="timepunched"
              value={event}
              onChange={(e, { value }) => this.setState({ event: value })}
            />
            <Button primary content="New Subscription" icon="plus" basic />
          </Form>
        </Segment>
      </Fragment>
    );
  }
}
