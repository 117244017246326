import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";

import { Header } from "semantic-ui-react";

import Sets from "./components/Sets";
import Set from "./components/Set";
import NewSet from "./components/NewSet";

export default class Points extends Component {
  static propTypes = {
    membership: PropTypes.object.isRequired,
    member: PropTypes.object.isRequired,
    reloadMembership: PropTypes.func.isRequired
  };

  state = {
    set: null
  };

  componentDidMount() {
    const { membership } = this.props;

    if (membership.points_sets.length) {
      this.setState({
        set: membership.points_sets[0].id
      });
    }
  }

  /**
   * Sets the current point set
   * @param {Number} set - the set id to set
   */
  setSet = set => this.setState({ set });

  getVisibleSet = () =>
    this.state.set
      ? this.props.membership.points_sets.find(row => row.id === this.state.set)
      : null;

  render() {
    const { member, membership, reloadMembership } = this.props;
    const set = this.getVisibleSet();

    return (
      <Fragment>
        <Sets
          activeSet={this.state.set}
          set={this.setSet}
          sets={membership.points_sets}
        />
        <br />
        {set ? (
          <Set
            set={set}
            membership={membership}
            reloadMembership={reloadMembership}
          />
        ) : (
          <NewSet membership={membership} reloadMembership={reloadMembership} />
        )}
      </Fragment>
    );
  }
}
