export default [
  // default
  { value: "-1.00", text: "Owner's Payrate (Default)" },
  // percentages
  { value: "0.00", text: "0% Rental Income" },
  { value: "15.00", text: "15% Rental Income" },
  { value: "20.00", text: "20% Rental Income" },
  { value: "25.00", text: "25% Rental Income" },
  { value: "30.00", text: "30% Rental Income" },
  { value: "35.00", text: "35% Rental Income" },
  { value: "40.00", text: "40% Rental Income" },
  { value: "45.00", text: "45% Rental Income" },
  { value: "50.00", text: "50% Rental Income" },
  { value: "55.00", text: "55% Rental Income" },
  { value: "60.00", text: "60% Rental Income" },
  { value: "65.00", text: "65% Rental Income" },
  { value: "70.00", text: "70% Rental Income" },
  { value: "75.00", text: "75% Rental Income" },
  { value: "80.00", text: "80% Rental Income" },
  { value: "85.00", text: "85% Rental Income" },
  { value: "90.00", text: "90% Rental Income" },
  { value: "95.00", text: "95% Rental Income" },
  { value: "100.00", text: "100% Rental Income" },
  // $x.00/thousand's
  { value: "1.00", text: "$1.00/1,000 points" },
  { value: "1.50", text: "$1.50/1,000 points" },
  { value: "2.00", text: "$2.00/1,000 points" },
  { value: "2.50", text: "$2.50/1,000 points" },
  { value: "3.00", text: "$3.00/1,000 points" },
  { value: "3.50", text: "$3.50/1,000 points" },
  { value: "4.00", text: "$4.00/1,000 points" },
  { value: "4.50", text: "$4.50/1,000 points" },
  { value: "5.00", text: "$5.00/1,000 points" },
  { value: "5.50", text: "$5.50/1,000 points" },
  { value: "6.00", text: "$6.00/1,000 points" },
  { value: "6.50", text: "$6.50/1,000 points" },
  { value: "7.00", text: "$7.00/1,000 points" },
  { value: "7.50", text: "$7.50/1,000 points" },
  { value: "8.00", text: "$8.00/1,000 points" },
  { value: "8.50", text: "$8.50/1,000 points" },
  { value: "9.00", text: "$9.00/1,000 points" },
  { value: "9.50", text: "$9.50/1,000 points" },
  { value: "10.00", text: "$10.00/1,000 points" }
];
