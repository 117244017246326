const countries = [
  ["af", "af", "Afghanistan"],
  ["ax", "ax", "Aland Islands"],
  ["al", "al", "Albania"],
  ["dz", "dz", "Algeria"],
  ["as", "as", "American Samoa"],
  ["ad", "ad", "Andorra"],
  ["ao", "ao", "Angola"],
  ["ai", "ai", "Anguilla"],
  ["ag", "ag", "Antigua"],
  ["ar", "ar", "Argentina"],
  ["am", "am", "Armenia"],
  ["aw", "aw", "Aruba"],
  ["au", "au", "Australia"],
  ["at", "at", "Austria"],
  ["az", "az", "Azerbaijan"],
  ["bs", "bs", "Bahamas"],
  ["bh", "bh", "Bahrain"],
  ["bd", "bd", "Bangladesh"],
  ["bb", "bb", "Barbados"],
  ["by", "by", "Belarus"],
  ["be", "be", "Belgium"],
  ["bz", "bz", "Belize"],
  ["bj", "bj", "Benin"],
  ["bm", "bm", "Bermuda"],
  ["bt", "bt", "Bhutan"],
  ["bo", "bo", "Bolivia"],
  ["ba", "ba", "Bosnia"],
  ["bw", "bw", "Botswana"],
  ["bv", "bv", "Bouvet Island"],
  ["br", "br", "Brazil"],
  ["vg", "vg", "British Virgin Islands"],
  ["bn", "bn", "Brunei"],
  ["bg", "bg", "Bulgaria"],
  ["bf", "bf", "Burkina Faso"],
  ["mm", "mm", "Burma"],
  ["bi", "bi", "Burundi"],
  ["tc", "tc", "Caicos Islands"],
  ["kh", "kh", "Cambodia"],
  ["cm", "cm", "Cameroon"],
  ["ca", "ca", "Canada"],
  ["cv", "cv", "Cape Verde"],
  ["ky", "ky", "Cayman Islands"],
  ["cf", "cf", "Central African Republic"],
  ["td", "td", "Chad"],
  ["cl", "cl", "Chile"],
  ["cn", "cn", "China"],
  ["cx", "cx", "Christmas Island"],
  ["cc", "cc", "Cocos Islands"],
  ["co", "co", "Colombia"],
  ["km", "km", "Comoros"],
  ["cg", "cg", "Congo Brazzaville"],
  ["cd", "cd", "Congo"],
  ["ck", "ck", "Cook Islands"],
  ["cr", "cr", "Costa Rica"],
  ["ci", "ci", "Cote Divoire"],
  ["hr", "hr", "Croatia"],
  ["cu", "cu", "Cuba"],
  ["cy", "cy", "Cyprus"],
  ["cz", "cz", "Czech Republic"],
  ["dk", "dk", "Denmark"],
  ["dj", "dj", "Djibouti"],
  ["dm", "dm", "Dominica"],
  ["do", "do", "Dominican Republic"],
  ["ec", "ec", "Ecuador"],
  ["eg", "eg", "Egypt"],
  ["sv", "sv", "El Salvador"],
  ["gb", "gb", "England"],
  ["gq", "gq", "Equatorial Guinea"],
  ["er", "er", "Eritrea"],
  ["ee", "ee", "Estonia"],
  ["et", "et", "Ethiopia"],
  ["eu", "eu", "European Union"],
  ["fk", "fk", "Falkland Islands"],
  ["fo", "fo", "Faroe Islands"],
  ["fj", "fj", "Fiji"],
  ["fi", "fi", "Finland"],
  ["fr", "fr", "France"],
  ["gf", "gf", "French Guiana"],
  ["pf", "pf", "French Polynesia"],
  ["tf", "tf", "French Territories"],
  ["ga", "ga", "Gabon"],
  ["gm", "gm", "Gambia"],
  ["ge", "ge", "Georgia"],
  ["de", "de", "Germany"],
  ["gh", "gh", "Ghana"],
  ["gi", "gi", "Gibraltar"],
  ["gr", "gr", "Greece"],
  ["gl", "gl", "Greenland"],
  ["gd", "gd", "Grenada"],
  ["gp", "gp", "Guadeloupe"],
  ["gu", "gu", "Guam"],
  ["gt", "gt", "Guatemala"],
  ["gw", "gw", "Guinea-Bissau"],
  ["gn", "gn", "Guinea"],
  ["gy", "gy", "Guyana"],
  ["ht", "ht", "Haiti"],
  ["hm", "hm", "Heard Island"],
  ["hn", "hn", "Honduras"],
  ["hk", "hk", "Hong Kong"],
  ["hu", "hu", "Hungary"],
  ["is", "is", "Iceland"],
  ["in", "in", "India"],
  ["io", "io", "Indian Ocean Territory"],
  ["id", "id", "Indonesia"],
  ["ir", "ir", "Iran"],
  ["iq", "iq", "Iraq"],
  ["ie", "ie", "Ireland"],
  ["il", "il", "Israel"],
  ["it", "it", "Italy"],
  ["jm", "jm", "Jamaica"],
  ["jp", "jp", "Japan"],
  ["jo", "jo", "Jordan"],
  ["kz", "kz", "Kazakhstan"],
  ["ke", "ke", "Kenya"],
  ["ki", "ki", "Kiribati"],
  ["kw", "kw", "Kuwait"],
  ["kg", "kg", "Kyrgyzstan"],
  ["la", "la", "Laos"],
  ["lv", "lv", "Latvia"],
  ["lb", "lb", "Lebanon"],
  ["ls", "ls", "Lesotho"],
  ["lr", "lr", "Liberia"],
  ["ly", "ly", "Libya"],
  ["li", "li", "Liechtenstein"],
  ["lt", "lt", "Lithuania"],
  ["lu", "lu", "Luxembourg"],
  ["mo", "mo", "Macau"],
  ["mk", "mk", "Macedonia"],
  ["mg", "mg", "Madagascar"],
  ["mw", "mw", "Malawi"],
  ["my", "my", "Malaysia"],
  ["mv", "mv", "Maldives"],
  ["ml", "ml", "Mali"],
  ["mt", "mt", "Malta"],
  ["mh", "mh", "Marshall Islands"],
  ["mq", "mq", "Martinique"],
  ["mr", "mr", "Mauritania"],
  ["mu", "mu", "Mauritius"],
  ["yt", "yt", "Mayotte"],
  ["mx", "mx", "Mexico"],
  ["fm", "fm", "Micronesia"],
  ["md", "md", "Moldova"],
  ["mc", "mc", "Monaco"],
  ["mn", "mn", "Mongolia"],
  ["me", "me", "Montenegro"],
  ["ms", "ms", "Montserrat"],
  ["ma", "ma", "Morocco"],
  ["mz", "mz", "Mozambique"],
  ["na", "na", "Namibia"],
  ["nr", "nr", "Nauru"],
  ["np", "np", "Nepal"],
  ["an", "an", "Netherlands Antilles"],
  ["nl", "nl", "Netherlands"],
  ["nc", "nc", "New Caledonia"],
  ["pg", "pg", "New Guinea"],
  ["nz", "nz", "New Zealand"],
  ["ni", "ni", "Nicaragua"],
  ["ne", "ne", "Niger"],
  ["ng", "ng", "Nigeria"],
  ["nu", "nu", "Niue"],
  ["nf", "nf", "Norfolk Island"],
  ["kp", "kp", "North Korea"],
  ["mp", "mp", "Northern Mariana Islands"],
  ["no", "no", "Norway"],
  ["om", "om", "Oman"],
  ["pk", "pk", "Pakistan"],
  ["pw", "pw", "Palau"],
  ["ps", "ps", "Palestine"],
  ["pa", "pa", "Panama"],
  ["py", "py", "Paraguay"],
  ["pe", "pe", "Peru"],
  ["ph", "ph", "Philippines"],
  ["pn", "pn", "Pitcairn Islands"],
  ["pl", "pl", "Poland"],
  ["pt", "pt", "Portugal"],
  ["pr", "pr", "Puerto Rico"],
  ["qa", "qa", "Qatar"],
  ["re", "re", "Reunion"],
  ["ro", "ro", "Romania"],
  ["ru", "ru", "Russia"],
  ["rw", "rw", "Rwanda"],
  ["sh", "sh", "Saint Helena"],
  ["kn", "kn", "Saint Kitts and Nevis"],
  ["lc", "lc", "Saint Lucia"],
  ["pm", "pm", "Saint Pierre"],
  ["vc", "vc", "Saint Vincent"],
  ["ws", "ws", "Samoa"],
  ["sm", "sm", "San Marino"],
  ["gs", "gs", "Sandwich Islands"],
  ["st", "st", "Sao Tome"],
  ["sa", "sa", "Saudi Arabia"],
  ["sn", "sn", "Senegal"],
  ["cs", "cs", "Serbia"],
  ["rs", "rs", "Serbia"],
  ["sc", "sc", "Seychelles"],
  ["sl", "sl", "Sierra Leone"],
  ["sg", "sg", "Singapore"],
  ["sk", "sk", "Slovakia"],
  ["si", "si", "Slovenia"],
  ["sb", "sb", "Solomon Islands"],
  ["so", "so", "Somalia"],
  ["za", "za", "South Africa"],
  ["kr", "kr", "South Korea"],
  ["es", "es", "Spain"],
  ["lk", "lk", "Sri Lanka"],
  ["sd", "sd", "Sudan"],
  ["sr", "sr", "Suriname"],
  ["sj", "sj", "Svalbard"],
  ["sz", "sz", "Swaziland"],
  ["se", "se", "Sweden"],
  ["ch", "ch", "Switzerland"],
  ["sy", "sy", "Syria"],
  ["tw", "tw", "Taiwan"],
  ["tj", "tj", "Tajikistan"],
  ["tz", "tz", "Tanzania"],
  ["th", "th", "Thailand"],
  ["tl", "tl", "Timorleste"],
  ["tg", "tg", "Togo"],
  ["tk", "tk", "Tokelau"],
  ["to", "to", "Tonga"],
  ["tt", "tt", "Trinidad"],
  ["tn", "tn", "Tunisia"],
  ["tr", "tr", "Turkey"],
  ["tm", "tm", "Turkmenistan"],
  ["tv", "tv", "Tuvalu"],
  ["ug", "ug", "Uganda"],
  ["ua", "ua", "Ukraine"],
  ["ae", "ae", "United Arab Emirates"],
  ["uk", "gb", "United Kingdom"],
  ["us", "us", "United States"],
  ["uy", "uy", "Uruguay"],
  ["um", "um", "Us Minor Islands"],
  ["vi", "vi", "Us Virgin Islands"],
  ["uz", "uz", "Uzbekistan"],
  ["vu", "vu", "Vanuatu"],
  ["va", "va", "Vatican City"],
  ["ve", "ve", "Venezuela"],
  ["vn", "vn", "Vietnam"],
  ["wf", "wf", "Wallis and Futuna"],
  ["eh", "eh", "Western Sahara"],
  ["ye", "ye", "Yemen"],
  ["zm", "zm", "Zambia"],
  ["zw", "zw", "Zimbabwe"]
];

export { countries };
