import React from "react";
import { Table, Checkbox } from "semantic-ui-react";
import Issue from "./components/Issue";
import OpenIssue from "./components/OpenIssue";
export default class Issues extends React.Component {
  state = {
    open: true,
    closed: false
  };
  toggleOpen = () => this.setState({ open: !this.state.open });
  toggleClosed = () => this.setState({ closed: !this.state.closed });
  getIssuesToShow = () => {
    return this.props.data.issues
      .filter(issue => {
        if (issue.closed_on === null) {
          return this.state.open;
        } else {
          return this.state.closed;
        }
      })
      .sort((a, b) => new Date(b.opened_on) - new Date(a.opened_on));
  };
  render() {
    const numberOpen = this.props.data.issues.filter(
      issue => issue.closed_on === null
    ).length;
    const numberClosed = this.props.data.issues.length - numberOpen;
    const issues = this.getIssuesToShow();
    return (
      <div>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div style={{ paddingTop: ".5rem" }}>
            <Checkbox
              label={`Show open (${numberOpen})`}
              style={{ marginRight: "1rem" }}
              onChange={this.toggleOpen}
              checked={this.state.open}
            />
            <Checkbox
              label={`Show closed (${numberClosed})`}
              onChange={this.toggleClosed}
              checked={this.state.closed}
            />
          </div>
          {this.props.data._actions.actions.open_issues ? (
            <OpenIssue data={this.props.data} reload={this.props.reload} />
          ) : (
            <p>You are not able to open issues for this right now.</p>
          )}
        </div>
        <Table celled padded selectable>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell singleLine width={2} />
              <Table.HeaderCell width={6}>Type of Issue</Table.HeaderCell>
              <Table.HeaderCell singleLine width={4}>
                Opened By
              </Table.HeaderCell>
              <Table.HeaderCell singleLine width={4}>
                Closed By
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {issues.length ? (
              issues.map((issue, key) => (
                <Issue
                  key={key}
                  data={issue}
                  booking={this.props.data}
                  reload={this.props.reload}
                />
              ))
            ) : (
              <Table.Row>
                <Table.Cell colSpan={4}>
                  <br />
                  There are no issues to show.
                  <br />
                  <br />
                </Table.Cell>
              </Table.Row>
            )}
          </Table.Body>
        </Table>
        <p>Newer issues will always show up at the top.</p>
      </div>
    );
  }
}
