import React, { Fragment } from "react";
import PropTypes from "prop-types";

import { Button, Form, Grid, Header, Segment } from "semantic-ui-react";

import AsyncForm from "components/AsyncForm";
import { countPointsUsedInPointsSet } from "utils/memberships";
import Bookings from "./Bookings.js";
import Delete from "./Delete.js";
import { formatMJY } from "utils/dates";

const Set = ({ set, reloadMembership, membership }) => {
  const config = {
    reload: reloadMembership,
    failure: window.toastr.error,
    dataFn: (source, value) => ({
      url: "/apis/portal/memberships",
      data: {
        action: "update_points",
        data: {
          ID: set.id,
          [source]: typeof value === "boolean" ? (value ? 1 : 0) : value
        }
      }
    })
  };

  const used = countPointsUsedInPointsSet(set);
  const remaining = set.allocated - used;

  return (
    <Fragment>
      <Segment attached="top">
        <Header
          as="h3"
          icon="cube"
          content={set.name || formatMJY(set.start)}
          subheader="Use the form below to modify this points set."
        />
        <AsyncForm {...config}>
          {({ functions }) => {
            return (
              <Fragment>
                <Grid columns="3" stackable>
                  <Grid.Row>
                    <Grid.Column>
                      {functions.make({
                        element: <Form.Input />,
                        field: "name",
                        value: set.name || "",
                        debounce: 500,
                        props: {
                          label: "Set Name",
                          placeholder: "2018 Credit Pool (example)"
                        }
                      })}
                      {functions.make({
                        element: <Form.Input />,
                        field: "start",
                        value: set.start.split(" ")[0],
                        debounce: 500,
                        props: {
                          label: "Start Date",
                          placeholder: "Start Date",
                          type: "date",
                          icon: "calendar"
                        }
                      })}
                      {functions.make({
                        element: <Form.Input />,
                        field: "end",
                        value: set.end.split(" ")[0],
                        debounce: 500,
                        props: {
                          label: "End Date",
                          placeholder: "End Date",
                          type: "date",
                          icon: "calendar"
                        }
                      })}
                    </Grid.Column>
                    <Grid.Column>
                      {functions.make({
                        element: <Form.Input />,
                        field: "earned",
                        value: set.earned,
                        debounce: 500,
                        props: {
                          type: "number",
                          label: "Points Earned",
                          placeholder: "0"
                        }
                      })}
                      {functions.make({
                        element: <Form.Input />,
                        field: "allocated",
                        value: set.allocated,
                        debounce: 500,
                        props: {
                          type: "number",
                          label: "Points Allocated",
                          placeholder: "0"
                        }
                      })}
                    </Grid.Column>
                    <Grid.Column>
                      {functions.make({
                        element: <Form.Input />,
                        field: "used",
                        value: used,
                        debounce: 500,
                        props: {
                          type: "number",
                          icon: "lock",
                          readOnly: true,
                          label: "Points Used",
                          placeholder: "0"
                        }
                      })}
                      {functions.make({
                        element: <Form.Input />,
                        field: "remaining",
                        value: remaining,
                        debounce: 500,
                        props: {
                          type: "number",
                          icon: "lock",
                          readOnly: true,
                          label: "Points Remaining",
                          placeholder: "0"
                        }
                      })}
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Fragment>
            );
          }}
        </AsyncForm>
      </Segment>
      <Segment attached>
        <Bookings usages={set.point_usages} />
      </Segment>
      <Segment attached="bottom">
        <Header
          as="h3"
          icon="settings"
          content="Actions"
          subheader="Perform actions for this points set"
        />
        <p>
          Use the button below to delete this points set. You can not delete the
          set if there are any bookings attached to the points set. They must
          first be moved off of the set.
        </p>
        <Delete
          membership={membership}
          reloadMembership={reloadMembership}
          set={set}
        />
      </Segment>
      <br />
      <br />
    </Fragment>
  );
};

Set.propTypes = {
  set: PropTypes.object.isRequired,
  membership: PropTypes.object.isRequired,
  reloadMembership: PropTypes.func.isRequired
};

export default Set;
