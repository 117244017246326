import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Detector } from "react-detect-offline";

import { createAlert, removeAlert } from "redux/Alertbar/actions";

export class CheckOffline extends Component {
  static propTypes = {
    alerts: PropTypes.array.isRequired,
    createAlert: PropTypes.func.isRequired,
    removeAlert: PropTypes.func.isRequired
  };

  state = {
    mounted: false
  };

  render() {
    const { createAlert, removeAlert, alerts } = this.props;

    // the index of any current "offline" alerts
    const index = alerts.findIndex(a => a.identifier === "disconnected");

    return (
      <Detector
        polling={{
          url: "/ping.php",
          interval: 5000,
          timeout: 5000,
          enabled: false
        }}
        render={({ online }) => {
          // if an alert for being offline doesn't exist and we're offline, make one
          if (!online && index === -1) {
            createAlert(
              "disconnected",
              "No internet connection",
              "disconnected"
            );
          }

          // otherwise if we're online and an alert for being offline exists, remove it
          if (online && index !== -1) {
            removeAlert(alert);
          }

          return null;
        }}
      />
    );
  }
}

const mapStateToProps = state => ({
  alerts: state.Alertbar.alerts
});

const mapDispatchToProps = {
  createAlert,
  removeAlert
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CheckOffline);
