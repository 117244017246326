import React from "react";
import { Step, Icon } from "semantic-ui-react";
import { Link } from "react-router-dom";

export default ({ data, bookingTab }) => {
  const openIssues = data.issues.filter(row => row.closed_on === null).length;
  const items = [
    {
      key: "summary",
      icon: "folder outline",
      description: "General information",
      title: "Summary",
      active: !bookingTab,
      as: Link,
      to: "/browse/bookings/" + data.id,
      className: "tutorial-bookpage-sidebar-summary"
    },
    {
      key: "mail",
      icon: "envelope outline",
      description: "Send Notice Emails",
      title: "Send Mail",
      active: bookingTab === "mail",
      as: Link,
      to: "/browse/bookings/" + data.id + "/mail",
      className: "tutorial-bookpage-sidebar-mail"
    },
    {
      key: "finances",
      icon: (() => {
        if (data._actions.state.finances_audited) {
          return <Icon color="green" name="check square outline" />;
        } else {
          return <Icon name="credit card outline" />;
        }
      })(),
      description: (() => {
        if (data._actions.state.finances_audited) {
          return "Finances Audited";
        } else {
          return "Booking Financials";
        }
      })(),
      title: "Finances",
      active: bookingTab === "finances",
      as: Link,
      to: "/browse/bookings/" + data.id + "/finances",
      className: "tutorial-bookpage-sidebar-finances"
    },
    {
      key: "issues",
      icon: (() => {
        if (!!openIssues) {
          return <Icon name="dot circle outline" color="red" />;
        } else {
          return <Icon name="check circle outline" color="green" />;
        }
      })(),
      description: (() => {
        if (openIssues === 1) {
          return "1 unresolved issue";
        } else {
          return `${openIssues} unresolved issues`;
        }
      })(),
      title: "Booking Issues",
      active: bookingTab === "issues",
      as: Link,
      to: "/browse/bookings/" + data.id + "/issues",
      className: "tutorial-bookpage-sidebar-issues"
    },
    {
      key: "audits",
      icon: (() => {
        if (data._actions.state.booking_audited) {
          return <Icon color="green" name="check square outline" />;
        } else if (data._actions.state.booking_auditing) {
          return <Icon color="yellow" name="square outline" />;
        } else {
          return <Icon color="red" name="square outline" />;
        }
      })(),
      description: (() => {
        if (data._actions.state.booking_audited) {
          return "Audited";
        } else if (data._actions.state.booking_auditing) {
          return "Audit in progress";
        } else {
          return "Not audited";
        }
      })(),
      title: "Booking Audit",
      active: bookingTab === "audits",
      as: Link,
      to: "/browse/bookings/" + data.id + "/audits",
      className: "tutorial-bookpage-sidebar-audits"
    },
    // {
    //   key: "comments",
    //   icon: "comment",
    //   description: "Read or write comments",
    //   title: "Comments",
    //   active: bookingTab === "comments",
    //   as: Link,
    //   to: "/browse/bookings/" + data.id + "/comments"
    // },
    {
      key: "actions",
      icon: "content",
      description: "Misc Actions",
      title: "Actions",
      active: bookingTab === "actions",
      as: Link,
      to: "/browse/bookings/" + data.id + "/actions",
      className: "tutorial-bookpage-sidebar-actions"
    }
  ];
  return (
    <div className="tutorial-bookpage-sidebar">
      <Step.Group fluid vertical items={items} />
    </div>
  );
};
