import styled from "styled-components";
import { Button, Input, Popup } from "semantic-ui-react";

import {
  backgroundColor,
  backgroundColorHover,
  borderColor,
  fontColor,
  defaultButtonBackground,
  defaultButtonBackgroundHover,
  defaultButtonBorderColor,
  defaultButtonFontColor,
  secondaryButtonBackground,
  secondaryButtonBackgroundHover,
  secondaryButtonBorderColor,
  primaryButtonBackground,
  primaryButtonFontColor,
  primaryButtonBackgroundHover,
  primaryButtonBorderColor,
  inputBackgroundColor,
  inputBorderColor,
  inputFontColor
} from "./";

export const ThemeNavAction = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;

  font-size: ${({ large }) => (large ? "1.5rem" : "1rem")};
  padding: 0;

  height: 2.5rem;
  width: 2.5rem;
  border-radius: 50%;

  border: none;
  background-color: transparent;
  cursor: pointer;

  transition: background-color ease-in-out 150ms;

  &:hover,
  &:focus {
    background-color: ${backgroundColorHover};
  }
`;

export const ThemePopup = styled(Popup)`
  &&& {
    background-color: ${backgroundColor};
    color: ${fontColor};
    border-color: ${borderColor};
    padding: 1rem;
    &:before {
      background-color: ${backgroundColor};
      box-shadow: none;
    }
  }
`;

export const ThemePopupContent = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-grow: 1;

  h1,
  h2,
  h3 {
    width: 100%;
    font-size: 1rem;
    font-weight: 600;
    padding-bottom: 0.5rem;
    margin-bottom: 0.5rem;
    border-bottom: 1px solid ${borderColor};
  }

  a,
  button,
  div {
    display: flex;
    width: 100%;
    align-items: center;
    color: ${fontColor};
    background-color: ${backgroundColor};
  }

  p {
    color: ${fontColor};
    background-color: ${backgroundColor};
  }

  a {
    padding: 0.5rem 1rem;
    margin: 0 -1rem;
    width: calc(100% + 2rem);

    &:hover,
    &:focus {
      background-color: ${backgroundColorHover};
    }

    i {
      margin-right: 0.5rem;
    }
  }
`;

export const ThemeButton = styled(Button)`
  &&& {
    text-align: ${props => props.align || "center"};
    background-color: ${({ primary, secondary, danger, success }) => {
      if (primary) return primaryButtonBackground;
      if (secondary) return secondaryButtonBackground;
      return defaultButtonBackground;
    }} !important;
    color: ${({ primary, secondary, danger, success }) => {
      if (primary) return primaryButtonFontColor;
      if (secondary) return defaultButtonFontColor;
      return defaultButtonFontColor;
    }} !important;
    border-color: ${({ primary, secondary, danger, success }) => {
      if (primary) return primaryButtonBorderColor;
      if (secondary) return secondaryButtonBorderColor;
      return defaultButtonBorderColor;
    }} !important;
    border: 1px solid;

    &:disabled {
      cursor: not-allowed !important;
    }

    &:hover,
    &:focus {
      background-color: ${({ primary, secondary, danger, success }) => {
        if (primary) return primaryButtonBackgroundHover;
        if (secondary) return secondaryButtonBackgroundHover;
        return defaultButtonBackgroundHover;
      }} !important;
      color: ${({ primary, secondary, danger, success }) => {
        if (primary) return primaryButtonFontColor;
        if (secondary) return defaultButtonFontColor;
        return defaultButtonFontColor;
      }} !important;
      border-color: ${({ primary, secondary, danger, success }) => {
        if (primary) return primaryButtonBorderColor;
        if (secondary) return secondaryButtonBorderColor;
        return defaultButtonBorderColor;
      }} !important;
    }
  }
`;

export const ThemeInput = styled(Input)`
  &&& {
    input {
      background-color: ${inputBackgroundColor};
      color: ${inputFontColor};
      border: 1px solid ${inputBorderColor};
    }
  }
`;
