import React from "react";
import PropTypes from "prop-types";

import { Icon, Popup } from "semantic-ui-react";

const Steps = props => {
  const steps = [
    {
      icon: "calendar outline alternate",
      name: "Created"
    },
    {
      icon: "phone",
      name: "Sent"
    },
    {
      icon: "inbox",
      name: "Submitted"
    },
    {
      icon: "fax",
      name: "Processed"
    },
    {
      icon: "sign out",
      name: "Onboarded"
    }
  ];

  return steps.map((step, key) => (
    <Popup
      key={key}
      inverted
      trigger={
        <Icon
          link
          // color={key + 1 <= props.applied.stepsCompleted ? "blue" : "black"}
          inverted={key + 1 <= props.applied.stepsCompleted}
          circular
          name={step.icon}
          size="large"
          onClick={() => props.update("stepsCompleted", key + 1)}
        />
      }
      content={step.name}
    />
  ));
};

Steps.propTypes = {
  update: PropTypes.func.isRequired,
  applied: PropTypes.object.isRequired
};

export default Steps;
