import React from "react";
import { Container, Header } from "semantic-ui-react";
import Layout from "components/Layout";
import PortalTable from "components/PortalTable";
import ListFn from "./components/List";
import Breadcrumb from "./components/Breadcrumb";
export default class MembershipList extends React.Component {
  render() {
    return (
      <Layout noBar>
        <Breadcrumb />
        <Container style={{ paddingBottom: "4rem" }}>
          <Header as={"h1"} dividing style={{ marginTop: "2rem" }}>
            Memberships
            <div className={"sub header"}>
              Below are all of the memberships registered in the database.
            </div>
          </Header>
          <PortalTable {...ListFn("memberships")} />
          <br />
        </Container>
      </Layout>
    );
  }
}
