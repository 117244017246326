import React from "react";
import moment from "moment";
import { Message } from "semantic-ui-react";

const getMessage = expires => {
  if (expires > 1) {
    return `This will end in approximately ${expires} minutes.`;
  } else if (expires === 1) {
    return "This will end in one minute.";
  } else {
    return "This will end in less than one minute.";
  }
};

export default ({ data }) => {
  const created = moment(data.created_on);
  const current = moment();
  // current time - created time converted to minutes
  const minutes = (current - created) / 1000 / 60;
  if (minutes > 30) {
    // don't render anything if it is past that 30 minute mark
    return null;
  } else {
    return (
      <Message info>
        <Message.Header>{"New Booking"}</Message.Header>
        <p>
          {
            "This is a new booking. Fields that are normally restricted will be available to you while it is fresh."
          }
        </p>
        <p>
          <b>
            {/* max 30mins to expire - how long its been */}
            {getMessage(30 - minutes.toFixed(0))}
            {" ("}
            {created.add(30, "minute").format("h:mm a")}
            {")"}
          </b>
        </p>
      </Message>
    );
  }
};
