import React, { Component } from "react";
import PropTypes from "prop-types";
import { Modal, Button, Icon, List } from "semantic-ui-react";
import { Redirect } from "react-router-dom";
import AjaxFn from "services/AjaxFn";
import getSocketHandler from "services/sockets";

const socket = getSocketHandler();

export default class DeleteButton extends Component {
  static propTypes = {
    check: PropTypes.object.isRequired
  };

  state = {
    loading: false,
    open: false,
    redirecting: false
  };

  handleOpen = () => this.setState({ open: true });
  handleClose = () => this.setState({ open: false });

  handleClick = () => {
    this.setState({ loading: true });
    const token = AjaxFn({
      url: "/apis/portal/checks",
      data: {
        action: "delete",
        data: { ID: this.props.check.id, token: socket.ignore() }
      },
      success: () => this.setState({ redirecting: true }),
      failure: error => {
        this.setState({ loading: false });
        window.toastr.error(error);
      }
    });
  };
  render() {
    if (this.state.redirecting) {
      return <Redirect push to={"/browse/checks"} />;
    }
    const trigger = (
      <Button
        color="red"
        basic
        onClick={this.handleOpen}
        content="Delete Check"
        fluid
        style={{
          margin: ".78571429rem 1.14285714rem",
          maxWidth: "calc(100% - 2.28571428rem)"
        }}
      />
    );
    return (
      <Modal
        open={this.state.open}
        onClose={this.handleClose}
        size={"mini"}
        trigger={trigger}
      >
        <Modal.Header icon={"exclamation triangle"}>Are you sure?</Modal.Header>
        <Modal.Content>
          <List>
            <List.Item>
              <List.Icon name="info" />
              <List.Content>
                <List.Description>
                  Any bookings the check has been printed for will no longer be
                  marked as "paid for".
                </List.Description>
              </List.Content>
            </List.Item>
            <List.Item>
              <List.Icon name="info" />
              <List.Content>
                <List.Description>
                  All financial info for this check will be lost.
                </List.Description>
              </List.Content>
            </List.Item>
          </List>
        </Modal.Content>
        <Modal.Actions>
          <Button
            color={"red"}
            onClick={this.handleClick}
            loading={this.state.loading}
          >
            <Icon name={"checkmark"} />
            Yes, delete it
          </Button>
          <Button onClick={this.handleClose}>
            <Icon name={"close"} />
            Nevermind
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}
