import React from "react";
import { connect } from "react-redux";
import { createTable, fetch } from "redux/PortalTables/actions";
import WithState from "components/WithState";
import { Ajax } from "services/Ajax";
import { Table, Button } from "semantic-ui-react";
import RefreshButton from "./components/RefreshButton";
import Info from "./components/Info";
import JumpToPage from "./components/JumpToPage";
import Pagination from "./components/Pagination";
import SearchBar from "./components/SearchBar";
import ShowSelector from "./components/ShowSelector";
import TableBody from "./components/TableBody";
import TableHeader from "./components/TableHeader";

import styled from "styled-components";
/**
 *	@prop namespace
 *	@prop tableName (remaps to namespace for legacy support)
 *  @prop config
 */
class PortalTable extends React.Component {
  namespace = null;
  /**
   *	Instantiates the table into its own namespace and then forces the first load
   */
  componentWillMount() {
    const { tableData = {}, url, data, method } = this.props;
    // for legacy support
    this.namespace = this.props.namespace || this.props.tableName;
    if (!this.namespace) throw new Error("namespace must be specified");
    if (!url) throw new Error("url must be specified");
    this.props.instantiate(
      this.namespace,
      tableData,
      new Ajax({ url, data, method })
    );
    this.props.fetch(this.namespace, this.props.preprocessor);
  }
  /**
   *	Instantiates the table into its own namespace and then forces the first load
   *  @param {Array/Function} Menu
   *  @return {Array}
   */
  buildMenu = menu => {
    const menuItems = typeof menu === "function" ? menu() : menu;
    return menuItems.map((el, ix) => {
      switch (el) {
        case "[search]":
          return (
            <div key={ix}>
              <SearchBar namespace={this.namespace} />
            </div>
          );
        case "[show]":
          return (
            <div key={ix}>
              <ShowSelector namespace={this.namespace} />
            </div>
          );
        case "[settings]":
          return (
            <div key={ix}>
              <Button disabled>Settings</Button>
            </div>
          );
        case "[refresh]":
          return (
            <div key={ix}>
              <RefreshButton namespace={this.namespace} />
            </div>
          );
        default:
          return <div key={ix}>{el}</div>;
      }
    });
  };

  render() {
    const { tables } = this.props;
    const table = tables[this.namespace];

    if (typeof table !== "object") {
      return null;
    } else {
      const {
        error,
        loading,
        paginationPosition,
        showFooter,
        tableProps,
        menu
      } = table;

      // need this to define amount of grid items for styled component
      // see BuildMenuContainer below for prop usage
      const items = table.menu().length;

      return (
        <React.Fragment>
          <BuildMenuContainer items={items} children={this.buildMenu(menu)} />
          <WithState loading={loading} error={error}>
            {["top", "both"].indexOf(paginationPosition) !== -1 ? (
              <React.Fragment>
                <br />
                <Pagination namespace={this.namespace} />
              </React.Fragment>
            ) : null}
            <Table {...tableProps}>
              <TableHeader namespace={this.namespace} />
              <TableBody namespace={this.namespace} />
            </Table>
            {showFooter && <TableHeader namespace={this.namespace} />}
            {["bottom", "both"].indexOf(paginationPosition) !== -1 ? (
              <React.Fragment>
                <Pagination namespace={this.namespace} />
                <br />
              </React.Fragment>
            ) : null}
            <InfoContainer>
              <Info namespace={this.namespace} />
              <JumpToPage namespace={this.namespace} />
            </InfoContainer>
          </WithState>
        </React.Fragment>
      );
    }
  }
}

const BuildMenuContainer = styled.div`
  display: grid;
  grid-template-columns: 3fr repeat(${props => props.items - 1}, auto);
  grid-column-gap: 1rem;
  grid-auto-flow: row;
  align-content: stretch;

  &&& {
    div {
      button,
      input {
        height: 100%;
        margin: 0;
      }
    }
  }
`;

const InfoContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const mapStateToProps = state => ({
  tables: state.PortalTables
  // ...
});
const mapDispatchToProps = dispatch => ({
  instantiate: (ns, data, ajax) => dispatch(createTable(ns, data, ajax)),
  fetch: (ns, fn) => dispatch(fetch(ns, fn))
  // ...
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PortalTable);
