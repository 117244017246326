import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { setTimepunch, setAccount } from "redux/TimepunchManager/actions";

import { Modal, Label } from "semantic-ui-react";
import moment from "moment";
import "moment-duration-format";

import RowView from "./components/RowView";
import TimepunchView from "./components/TimepunchView";

export class TimepunchRow extends Component {
  static propTypes = {
    account: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]).isRequired,
    timepunch: PropTypes.oneOfType([PropTypes.bool, PropTypes.number])
      .isRequired,
    processed: PropTypes.array.isRequired
  };

  state = {
    locked: false
  };

  setLocked = (status = true) => this.setState({ locked: status });

  handleClose = () => {
    if (!this.state.locked) {
      if (this.props.timepunch) {
        this.props.setTimepunch(false);
      } else {
        this.props.setAccount(false);
      }
    }
  };

  render() {
    if (!this.props.account) {
      return null;
    }
    const { locked } = this.state;
    // find the row by its account id in redux
    const row = this.props.processed.find(
      row => row.account.id === this.props.account
    );
    const { account, timepunches, total } = row;
    // calculate total hours, minutes, seconds spent clocked in
    const weekTime = moment
      .duration(total)
      .format("h[h] m[m] s[s]", { trim: false });
    return (
      <Modal open={true} onClose={this.handleClose} size="small">
        <Modal.Header>
          {account.firstname} {account.lastname} <br />
          {account.clocked_in ? (
            <Label color="blue" detail="Clocked in" content="status" />
          ) : (
            <Label detail="Clocked out" content="status" />
          )}
          <Label content="week" detail={weekTime} />
        </Modal.Header>
        {this.props.timepunch ? (
          <TimepunchView locked={locked} setLocked={this.setLocked} />
        ) : (
          // Timepunches is being passed in as a prop here because it doesn't make sense to
          // query redux to find the processed entry twice. We needed to do it for this render
          // method, but we don't need to do it again for RowView.
          <RowView
            timepunches={timepunches}
            locked={locked}
            setLocked={this.setLocked}
            account={account}
          />
        )}
      </Modal>
    );
  }
}

const mapStateToProps = state => ({
  processed: state.TimepunchManager.processed,
  account: state.TimepunchManager.selected_account,
  timepunch: state.TimepunchManager.selected_timepunch
});

const mapDispatchToProps = {
  setTimepunch,
  setAccount
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TimepunchRow);
