import React from "react";
import { FiltersModal } from "components/PortalTable/parts";
import { Grid, Form, Dropdown } from "semantic-ui-react";
import AjaxDropdown from "components/AjaxDropdown";
import ResortCompanyFilter from "components/ResortCompanyFilter";
import naturalSort from "utils/naturalSort";

const checkCompanies = (row, companies) => {
  if (companies[row.resort_company.id] === true) {
    return true;
  } else if (companies[row.resort_company.id] === false) {
    return false;
  } else {
    return true;
  }
};

export default () => (
  <FiltersModal
    namespace={"resorts"}
    modalProps={{}}
    filters={{
      companies: {},
      agent: "any",
      // TODO: implement "Audited"
      audited: "any"
    }}
    buildFilters={applied => {
      const functions = [];
      functions.push(row => checkCompanies(row, applied.companies));
      if (applied.agent !== "any") {
        functions.push(row => row.agent.id === applied.agent);
      }
      return functions;
    }}
    children={(applied, update) => (
      <Form>
        <Grid columns={2}>
          <Grid.Column>
            <Form.Field>
              <label>Agent</label>
              <AjaxDropdown
                url={"/apis/portal/accounts"}
                data={{ action: "read_for_dropdown" }}
                dataRetreiver={({ message }) =>
                  [
                    {
                      value: "any",
                      text: "Anybody"
                    }
                  ].concat(
                    message
                      .map(row => ({
                        value: row.id,
                        text: row.firstname + " " + row.lastname,
                        disabled: row.disabled
                      }))
                      .sort((a, b) => naturalSort(a.text, b.text))
                      .filter(row => !row.disabled)
                  )
                }
                search
                selection
                value={applied.agent}
                onChange={(e, { value }) => update("agent", value)}
              />
            </Form.Field>
            <Form.Field>
              <label>Audited (Not implemented)</label>
              <Dropdown
                search
                selection
                options={[{ value: "any", text: "Any" }]}
                value={applied.audited}
                onChange={(e, { value }) => update("audited", value)}
              />
            </Form.Field>
          </Grid.Column>
          <Grid.Column>
            <Form.Field>
              <label>Resort Company Visibility</label>
              <ResortCompanyFilter
                applied={applied.companies}
                set={(id, val) =>
                  update("companies", {
                    ...applied.companies,
                    [id]: val
                  })
                }
              />
            </Form.Field>
          </Grid.Column>
        </Grid>
      </Form>
    )}
  />
);
