import React, { Component } from "react";
import PropTypes from "prop-types";

import AjaxComponent from "components/AjaxComponent";

export default class AgreementLoader extends Component {
  static propTypes = {
    ID: PropTypes.string.isRequired
  };

  render() {
    const { ID, children } = this.props;

    return (
      <AjaxComponent
        {...{
          children,
          ref: r => (this.ajaxComponent = r),
          ajaxConfig: {
            url: "/apis/portal/legacyagreements",
            data: {
              action: "read_one",
              data: { ID }
            }
          }
          // stateful: false
        }}
      />
    );
  }
}
