import React from "react";
import { Comment, Icon } from "semantic-ui-react";
import { connect } from "react-redux";
import AjaxFn from "services/AjaxFn";
class Delete extends React.Component {
  state = {
    loading: false
  };
  delete = () => {
    this.setState({ loading: true });
    AjaxFn({
      url: "/apis/portal/comments",
      data: {
        action: "delete",
        data: {
          ID: this.props.comment.id
        }
      },
      success: () => {
        window.toastr.success();
        this.props.reload();
      },
      failure: error => {
        window.toastr.error(error);
        this.setState({ loading: false });
      }
    });
  };
  render() {
    const { comment, user } = this.props;
    if (comment.deleted) {
      return null;
    }
    if (comment.account.id === user.id) {
      if (this.state.loading) {
        return <Comment.Action>Deleting...</Comment.Action>;
      }
      return (
        <Comment.Action as={"a"} onClick={this.delete}>
          <Icon name={"cancel"} />
          Delete
        </Comment.Action>
      );
    }
    return null;
  }
}
const mapStateToProps = state => ({
  user: state.User
});
export default connect(mapStateToProps)(Delete);
