import React, { Component } from "react";
import PropTypes from "prop-types";

import { Container, Message } from "semantic-ui-react";

import Agreement from "components/Agreement";
import DealSheet from "components/DealSheet";
import Layout from "components/Layout";
import WithState from "components/WithState";
import AgreementFocusBar from "./components/AgreementFocusBar";
import Breadcrumb, { LoadedBreadcrumb } from "./components/Breadcrumb";
import AjaxFn from "services/AjaxFn";

export default class AgreementInternal extends Component {
  static propTypes = {
    match: PropTypes.object.isRequired
  };

  state = {
    loading: true,
    error: false,
    data: false,
    cancel: false
  };

  componentDidMount() {
    this.reload();
  }

  componentWillUnmount() {
    this.cancel();
  }

  cancel = () => {
    if (typeof this.state.cancel === "function") {
      this.state.cancel();
    }
  };

  reload = () => {
    this.cancel();
    this.setState({
      loading: true,
      data: false,
      error: false,
      cancel: AjaxFn({
        url: "/apis/portal/agreements",
        data: {
          action: "read_one",
          data: {
            ID: this.props.match.params.id
          }
        },
        success: data =>
          this.setState({ data, error: false, loading: false, cancel: false }),
        failure: error =>
          this.setState({ data: false, error, loading: false, cancel: false })
      })
    });
  };

  render() {
    const { data, loading, error } = this.state;
    const viewing = this.props.match.params.viewing || "agreement";
    return (
      <Layout>
        {data ? (
          <LoadedBreadcrumb data={data} reload={this.reload} />
        ) : (
          <Breadcrumb />
        )}
        <Breadcrumb />
        <AgreementFocusBar
          loading={loading}
          error={error}
          data={data}
          reload={this.reload}
          viewing={viewing}
        />
        <Container>
          <WithState loading={loading} error={error}>
            {(() => {
              if (!data) return null;

              switch (viewing) {
                case "dealsheet":
                  return Object.keys(data.deal_sheet).length > 1 ? (
                    // Dealsheet is visible. > 1 check because if it isn't visible, all that's present is the id.
                    <DealSheet data={data} />
                  ) : (
                    <Message>
                      <Message.Header>Dealsheet hidden</Message.Header>
                      <p>This dealsheet is no longer visible to you.</p>
                    </Message>
                  );
                case "agreement":
                default:
                  return Object.keys(data).length > 2 ? (
                    // Agreement is visible. >2 check because if it's hidden, all that's present is id and deal_sheet.
                    <Agreement data={data} type={"internal"} />
                  ) : (
                    <Message>
                      <Message.Header>Agreement hidden</Message.Header>
                      <p>This agreement is no longer visible to you.</p>
                    </Message>
                  );
              }
            })()}
          </WithState>
        </Container>
      </Layout>
    );
  }
}
