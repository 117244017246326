import React, { useState } from "react";
import PropTypes from "prop-types";

import { Form, Segment, Button } from "semantic-ui-react";

import FocusBar from "components/FocusBar";
import naturalSort from "utils/naturalSort";
import AjaxFn from "services/AjaxFn";

const ChangeAgentsControls = props => {
  const [state, setState] = useState({
    type: "appointment_setter",
    loading: false,
    agent: null
  });

  const options = props.options
    .map(option => ({
      text: option.firstname + " " + option.lastname,
      value: option.id
    }))
    .sort((a, b) => naturalSort(a.text, b.text));

  const handleSubmit = e => {
    e.preventDefault();
    setState({ ...state, loading: true });
    AjaxFn({
      url: "/apis/portal/dealsheets",
      data: {
        action: "change_agent",
        data: {
          ID: props.ID,
          type: state.type,
          agentID: state.agent
        }
      },
      success: () => {
        window.toastr.success();
        props.reload();
      },
      failure: error => {
        window.toastr["error"](error);
        setState({ ...state, loading: false });
      }
    });
  };

  return (
    <>
      <FocusBar.Content>
        <FocusBar.Generic>
          <Segment inverted>
            <p>Select agent type</p>
            <Form inverted onSubmit={handleSubmit}>
              <Form.Radio
                label="Appointment Setter"
                checked={state.type === "appointment_setter"}
                onClick={() =>
                  setState({ ...state, type: "appointment_setter" })
                }
              />
              <Form.Radio
                label="Closer"
                checked={state.type === "closing_agent"}
                onClick={() => setState({ ...state, type: "closing_agent" })}
              />
              <Form.Dropdown
                label="Select an agent"
                search
                selection
                placeholder="Select one..."
                selectOnBlur={false}
                options={options}
                onChange={(e, { value }) =>
                  setState({ ...state, agent: value })
                }
              />
              <Button
                primary
                type="submit"
                basic
                content="Save"
                disabled={!state.agent}
                loading={state.loading}
              />
            </Form>
          </Segment>
        </FocusBar.Generic>
      </FocusBar.Content>
    </>
  );
};

ChangeAgentsControls.propTypes = {
  options: PropTypes.array.isRequired,
  reload: PropTypes.func.isRequired,
  ID: PropTypes.number.isRequired
};

export default ChangeAgentsControls;
