import React from "react";
import { Icon, Button } from "semantic-ui-react";
import { Link } from "react-router-dom";
import { addCommas } from "utils/numbers";
import { formatMJY } from "utils/dates";
import Filters from "./components/Filters";

export default namespace => ({
  tableName: namespace,
  url: "/apis/portal/checks",
  data: {
    action: "read"
  },
  tableData: {
    show: 25,
    order: [[1, "desc"]],
    paginationPosition: "bottom",
    showFooter: false,
    menu: () => [
      "[search]",
      "[show]",
      <Filters namespace={namespace} />,
      "[refresh]",
      <Link to={"/wizards/checks"}>
        <Button primary={true} title={"New Check"} content={"New Check"} />
      </Link>
    ],
    cols: [
      {
        name: "",
        src: "id",
        render: (src, row) => (
          <Link to={"/browse/checks/" + src}>
            <Icon name={"external"} />
          </Link>
        ),
        orderable: false,
        searchable: false,
        props: {
          textAlign: "center",
          selectable: true,
          width: 1
        }
      },
      {
        name: "Printed",
        src: "printed_on",
        render: src => formatMJY(src)
      },
      {
        name: "Check #",
        src: "number",
        render: (src = 0) => "#" + src.toString().padStart(7, "0"),
        props: {
          width: 2
        }
      },
      {
        name: "Amount",
        src: "amount",
        render: (src = 0) => {
          return "$" + addCommas(src, 2);
        },
        props: {
          width: 2
        }
      },
      {
        name: "Paid To",
        src: "pay_to"
      },
      {
        name: "Printed By",
        src: row => row.created_by.firstname + " " + row.created_by.lastname
      },
      {
        name: "Processed",
        src: "processed",
        searchable: false,
        render: src => {
          if (src) {
            return (
              <React.Fragment>
                <Icon name={"checkmark"} color={"green"} />
                Yes
              </React.Fragment>
            );
          } else {
            return (
              <React.Fragment>
                <Icon name={"cancel"} color={"red"} />
                No
              </React.Fragment>
            );
          }
        },
        props: row => {
          const props = { width: 2 };
          if (row.processed) {
            props.positive = true;
          } else {
            props.negative = true;
          }
          return props;
        }
      }
    ]
  }
});

// // An example of what a real row looks like:
// const exampleRow = {
//   id: 21,
//   pay_to: "Pinkie Baker",
//   amount: "776.75",
//   sent_by: "Vacation Management Group LLC",
//   number: 2151,
//   processed: true,
//   bank: { id: 6, portal_name: "Commerce Bank (VMG)" },
//   created_by: { id: 13788, firstname: "Trenton", lastname: "Brown" },
//   bookings: [
//     {
//       id: 20916,
//       check_in: "2017-07-04 00:00:00",
//       guest: { id: 42206, firstname: "Amanda", lastname: "Phillips" },
//       unit: {
//         id: 29284,
//         name: "3 Bedroom",
//         resort: { name: "Emerald Grande", resort_company: { name: "Wyndham" } }
//       },
//       point_usages: [
//         {
//           id: 10603,
//           amount: 81000,
//           point_set: {
//             membership: {
//               id: 21908,
//               level: "Platinum",
//               resort_company: { id: 395, name: "Wyndham" },
//               member: { id: 19142, firstname: "Pinkie", lastname: "Baker" }
//             }
//           }
//         }
//       ]
//     },
//     {
//       id: 20936,
//       check_in: "2017-07-17 00:00:00",
//       guest: { id: 42226, firstname: "Jorge", lastname: "Martinez" },
//       unit: {
//         id: 29278,
//         name: "Studio",
//         resort: { name: "El Cid Marina", resort_company: { name: "Wyndham" } }
//       },
//       point_usages: [
//         {
//           id: 10604,
//           amount: 38500,
//           point_set: {
//             membership: {
//               id: 21908,
//               level: "Platinum",
//               resort_company: { id: 395, name: "Wyndham" },
//               member: { id: 19142, firstname: "Pinkie", lastname: "Baker" }
//             }
//           }
//         }
//       ]
//     }
//   ]
// };
