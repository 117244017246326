import React, { useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import { Button, Form } from "semantic-ui-react";

import { set } from "redux/Storage/actions";

const SavePage = props => {
  const [name, setName] = useState("");

  const handleClick = () => {
    props.set("favoritePages", [
      ...props.favoritePages,
      {
        url: window.location.href.replace(window.location.origin, ""),
        name,
        icon: "fas fa-link"
      }
    ]);
    setName("");
  };

  return (
    <div style={{ display: "block" }}>
      <Form>
        <Form.Input
          value={name}
          onChange={(e, { value }) => setName(value)}
          placeholder="Page name..."
          style={{ marginBottom: ".25rem" }}
        />
      </Form>
      <Button
        basic
        primary
        content="Save"
        icon="plus"
        disabled={!name}
        onClick={handleClick}
      />
    </div>
  );
};

SavePage.propTypes = {
  favoritePages: PropTypes.array.isRequired,
  set: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  favoritePages: state.Storage.favoritePages
  // ...
});

const mapDispatchToProps = {
  set
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SavePage);
