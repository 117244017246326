import React from "react";
import { Progress } from "semantic-ui-react";
import injectSheet from "react-jss";
const styles = {
  progress: {
    marginBottom: "0 !important",
    "& div.bar": {
      minWidth: "2.75em !important",
      transition: "none !important"
    }
  }
};
/**
 *	@prop classes
 *	@prop value
 */
const ProgressBar = ({ value, classes }) => {
  let color = null;
  if (value === 100) {
    color = "blue";
  } else if (value > 70) {
    color = "green";
  } else if (value > 50) {
    color = "olive";
  } else if (value > 30) {
    color = "yellow";
  } else if (value > 10) {
    color = "orange";
  } else {
    color = "red";
  }
  return (
    <Progress
      className={classes.progress}
      percent={value}
      // anything shorter will cut off text
      progress={value >= 20 && value !== 100}
      color={color}
      // add a title on hover
      title={value + "% completed"}
    />
  );
};
export default injectSheet(styles)(ProgressBar);
