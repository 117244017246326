import React from "react";
import { Icon, Button } from "semantic-ui-react";
import { Link } from "react-router-dom";
import Filters from "./components/Filters";
export default namespace => ({
  tableName: namespace,
  url: "/apis/portal/members",
  data: {
    action: "read"
  },
  tableData: {
    show: 25,
    order: [[1, "asc"]],
    paginationPosition: "bottom",
    showFooter: false,
    menu: () => [
      "[search]",
      "[show]",
      <Filters namespace={namespace} />,
      "[refresh]",
      <Link to={"/wizards/members"}>
        <Button primary content="New Member" />
      </Link>
    ],
    cols: [
      {
        name: "",
        src: "id",
        render: (src, row) => (
          <Link
            to={"/browse/members/" + src}
            children={<Icon name={"external"} />}
          />
        ),
        orderable: false,
        searchable: false,
        props: {
          textAlign: "center",
          selectable: true,
          width: 1
        }
      },
      {
        name: "Name",
        src: row => row.firstname + " " + row.lastname
      },
      {
        name: "Phone Number",
        src: "phone"
      },
      {
        name: "Email",
        src: "email"
      },
      {
        name: "Memberships",
        src: "memberships",
        render: (src, row) => {
          return (
            <div>
              {src.map(({ id, resort_company: { name } }, index) => {
                return (
                  <Link
                    to={`/browse/members/${row.id}/membership/${id}`}
                    key={index}
                  >
                    {(index ? ", " : "") + name}
                  </Link>
                );
              })}
            </div>
          );
        }
      },
      {
        name: "Active",
        src: ({ deleted }) => !deleted,
        render: active => {
          if (active) {
            return (
              <React.Fragment>
                <Icon name={"checkmark"} color={"green"} />
                Yes
              </React.Fragment>
            );
          } else {
            return (
              <React.Fragment>
                <Icon name={"cancel"} color={"red"} />
                No
              </React.Fragment>
            );
          }
        },
        props: row => {
          const props = {};
          if (!row.deleted) {
            props.positive = true;
          } else {
            props.negative = true;
          }
          return props;
        }
      },
      // searchable fields
      {
        name: "home_resort",
        src: row =>
          row.memberships.reduce((a, c) => a + " " + c.home_resort_name),
        visible: false
      }
    ]
  }
});
