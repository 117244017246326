import React, { Component } from "react";
import PropTypes from "prop-types";

import { Button } from "semantic-ui-react";

import AjaxFn from "services/AjaxFn";

export default class Activate extends Component {
  static propTypes = {
    member: PropTypes.object.isRequired,
    membership: PropTypes.object.isRequired,
    reloadMembership: PropTypes.func.isRequired
  };

  state = {
    loading: false,
    cancel: false
  };

  componentWillUnmount() {
    this.cancel();
  }

  cancel = () => {
    if (typeof this.state.cancel === "function") {
      this.state.cancel();
    }
  };

  handleClick = () => {
    const data = {
      ID: this.props.membership.id,
      is_active: this.props.membership.is_active ? 0 : 1
    };

    this.setState({
      loading: true,
      cancel: AjaxFn({
        url: "/apis/portal/memberships",
        data: {
          action: "update",
          data: data
        },
        success: this.props.reloadMembership,
        failure: error => {
          this.setState({ loading: false, cancel: false });
          window.toastr.error(error);
        }
      })
    });
  };

  render() {
    return (
      <Button
        basic
        onClick={this.handleClick}
        loading={this.state.loading}
        disabled={this.state.disabled}
        color={this.props.membership.is_active ? "red" : "blue"}
        content={this.props.membership.is_active ? "Deactivate" : "Activate"}
      />
    );
  }
}
