import React from "react";
import { Link } from "react-router-dom";
import Element from "../Element";
import ButtonComponent from "../ButtonComponent";

const ButtonElement = props => {
  const {
    active = false,
    disabled = false,
    href = false,
    hideOnCollapse = false,
    ...restOfProps
  } = props;

  const childprops = {
    disabled,
    ...restOfProps
  };

  const elementprops = {
    indicator: active ? true : undefined,
    hideOnCollapse
  };

  const RenderedButton = <ButtonComponent {...childprops} />;

  return (
    <Element {...elementprops}>
      {!href || disabled ? (
        RenderedButton
      ) : (
        <Link to={href} children={RenderedButton} />
      )}
    </Element>
  );
};

export default ButtonElement;
