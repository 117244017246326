import React, { useState } from "react";
import PropTypes from "prop-types";

import FocusBar from "components/FocusBar";
import AjaxFn from "services/AjaxFn";

const PayAgreement = props => {
  const [loading, setLoading] = useState(false);

  const handleClick = () => {
    setLoading(true);
    AjaxFn({
      url: "/apis/portal/agreements",
      data: {
        action: "mark_paid",
        data: {
          ID: props.data.id
        }
      },
      success: () => {
        window.toastr.success();
        props.reload();
      },
      failure: error => {
        setLoading(false);
        window.toastr.error(error);
      }
    });
  };

  return (
    <FocusBar.Button
      icon="fas fa-money-check-alt"
      content="Mark as Paid"
      loading={loading}
      disabled={loading}
      onClick={handleClick}
    />
  );
};

PayAgreement.propTypes = {
  data: PropTypes.object.isRequired,
  reload: PropTypes.func.isRequired
};

export default PayAgreement;
