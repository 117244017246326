const genericProps = {
  scrollDuration: 400,
  rounded: 10,
  disableInteraction: true
};

// Each entry should have an index, a title (this will be visible), and config (object representing reactour config)

export const dashboardTour = {
  index: "dashboard",
  title: "Dashboard Introduction",
  config: {
    ...genericProps,
    steps: [
      {
        selector: ".tutorial-dash-navbar",
        content: `When you come to certain pages for the first time, you will get a short walkthrough like this. 
        You can use the arrow keys to go to the next showcase. Clicking outside of the window will allow you to skip the rest
        of the tutorial, or if you are at the end of it, complete it. Here we will demonstrate what your dashboard is. 
        This is your menu. Use it to access different sections of the portal.`,
        position: "bottom"
      },
      {
        selector: ".tutorial-dash-user-actions",
        content:
          "You can find commonly used actions here, such as logging out or clocking out.",
        position: "bottom"
      },
      {
        selector: ".tutorial-dash-clockin-clockout",
        content:
          "You can also clock in and out using this button. Don't forget to clock out!",
        position: "bottom"
      },
      {
        selector: ".tutorial-dash-resources",
        content: "Company resources can be located here.",
        position: "right"
      },
      {
        selector: ".tutorial-dash-newsfeed",
        content:
          "Company-wide or department-wide announcements will show up here.",
        position: "left"
      },
      {
        selector: ".tutorial-dash-preferences",
        content: "This is where you will be able to set account preferences.",
        position: "top"
      },
      {
        selector: ".tutorial-dash-tutorials",
        content: `You can see tutorial progress here. A grey bar means it has not been started. A colored bar shows 
        how close you are to being finished. A blue bar is a completed tutorial, and a black bar is a skipped one. 
        You can click out of this tutorial now that it has been completed. It won't show up again unless you reset it.`,
        position: "top"
      }
    ]
  }
};

export const bookingListTour = {
  index: "booking-list",
  title: "Booking List",
  config: {
    ...genericProps,
    steps: [
      {
        selector: ".tutorial-booklist-dates",
        content: `Bookings will only be loaded between the start and end dates entered here. The bookings list can take a significant
        amount of time to load depending on the timeframe, so try to get it as small as possible.`,
        position: "bottom"
      },
      {
        selector: ".tutorial-booklist-filters",
        content: `Filters are only applied after the bookings have been loaded.`,
        position: "bottom"
      }
    ]
  }
};

export const bookingPageTour = {
  index: "booking-page",
  title: "Booking Page",
  config: {
    ...genericProps,
    steps: [
      {
        selector: ".tutorial-bookpage-main",
        content: `This is where you view each individual booking. It is divided into different sections to make it easier
        to find and digest information you need.`,
        position: "right"
      },
      {
        selector: ".tutorial-bookpage-sidebar",
        content: `This is the sidebar. It allows you to access different sections of the booking. Some of the items will
        even give you information about the booking.`,
        position: "right"
      },
      {
        selector: ".tutorial-bookpage-content",
        content: `The active section will show up here. It will change depending on which tab you click in the sidebar.`,
        position: "left"
      },
      {
        selector: ".tutorial-bookpage-comments",
        content: `At the bottom of the page you can view or leave comments on the booking. It doesn't matter what section
        you are viewing, the comments will all be the same.`,
        position: "left"
      },
      {
        selector: ".tutorial-bookpage-sidebar-summary",
        content: `The summary tab allows you to view general information about the booking, such as the timeframe, unit, owner, etc.`,
        position: "right"
      },
      {
        selector: ".tutorial-bookpage-sidebar-mail",
        content: `The mail tab lets you send email to the guests or the owners, as well as view mail history.`,
        position: "right"
      },
      {
        selector: ".tutorial-bookpage-sidebar-finances",
        content: `The finances tab contains current and past financial information entered for the booking.`,
        position: "right"
      },
      {
        selector: ".tutorial-bookpage-sidebar-issues",
        content: `The booking issues tab allows you to view, open, and close issues for the booking. 
        A red circle with a dot means the booking has unresolved issues that need to be addressed. 
        A green circle with a checkmark means that the booking has no open issues.`,
        position: "right"
      },
      {
        selector: ".tutorial-bookpage-sidebar-audits",
        content: `The audits tab allows you start, cancel, complete, and view the audits for the booking. A red square icon 
        means the booking is not audited. A yellow square means an audit is in progress. A green square with a checkmark means
        the booking is audited.`,
        position: "right"
      },
      {
        selector: ".tutorial-bookpage-sidebar-actions",
        content: `The actions tab contains miscellaneous actions like cancelling or deleting the booking.`,
        position: "right"
      }
    ]
  }
};

export const memberListTour = {
  index: "member-list",
  title: "Member List",
  config: {
    ...genericProps,
    steps: [
      {
        selector: ".tutorial-memberlist-main",
        content: `This is the members list. All of the owners ("members") and their timeshares ("memberships")
        in our system will show up here.`,
        position: "right"
      },
      {
        selector: ".tutorial-memberlist-main",
        content: `Each member should have only one row. Unless two people just have the same name, you shouldn't 
        see any duplicates in this list.`,
        position: "right"
      },
      {
        selector: ".tutorial-memberlist-main",
        content: `The buttons here on the left side of each row will take you to the member's page.`,
        position: "left"
      },
      {
        selector: ".tutorial-memberlist-main",
        content: `You can also click on the blue links in the memberships column on this side to 
        go to the respective membership for that owner.`,
        position: "right"
      },
      {
        selector: ".tutorial-memberlist-main",
        content: `By default the list will only show members set to active. You can change that in the filters section.`,
        position: "right"
      }
    ]
  }
};

export const memberPageTour = {
  index: "member-page",
  title: "Member Page",
  config: {
    ...genericProps,
    steps: [
      {
        selector: ".tutorial-memberpage-main",
        content: `This is where you view each member and their memberships. It is divided into different 
        sections to make it easier to find and digest information you need.`,
        position: "right"
      },
      {
        selector: ".tutorial-memberpage-sidebar",
        content: `This is the sidebar. It allows you to access different sections of the member page, such as their memberships. 
        Some of the items will even give you information about the booking.`,
        position: "right"
      },
      {
        selector: ".tutorial-memberpage-content",
        content: `The active section will show up here. It will change depending on which tab you click in the sidebar.`,
        position: "left"
      },
      {
        selector: ".tutorial-memberpage-comments",
        content: `At the bottom of the page you can view or leave comments on the member. It doesn't matter what section or
        membership you are viewing, the comments will all be the same.`,
        position: "left"
      },
      {
        selector: ".tutorial-memberpage-sidebar-member",
        content: `This part of the sidebar only contains sections for the member, not their memberships.`,
        position: "right"
      },
      {
        selector: ".tutorial-memberpage-sidebar-summary",
        content: `The summary tab allows you to view general information about the member, such as contact info.`,
        position: "right"
      },
      {
        selector: ".tutorial-memberpage-sidebar-issues",
        content: `The issues tab allows you to view, open, and close issues for the member. 
        A red circle with a dot means the member has unresolved issues that need to be addressed. 
        A green circle with a checkmark means that the member has no open issues.`,
        position: "right"
      },
      {
        selector: ".tutorial-memberpage-sidebar-memberships",
        content: `The memberships belonging to this member will show up here. If a membership has a red square icon, it 
        is set to inactive. A green square with a checkmark means it it active in our system.`,
        position: "right"
      },
      {
        selector: ".tutorial-memberpage-sidebar-add-memberships",
        content: `This button allows you to add new memberships. It will take you to the owner wizard and automatically 
        select the current member.`,
        position: "right"
      }
    ]
  }
};

export const agreementWizardTour = {
  index: "agreement-wizard",
  title: "Agreements Wizard",
  config: {
    ...genericProps,
    steps: [
      {
        selector: ".tutorial-agreement-wizard-main",
        content: `This page will allow you to begin new agreements. You will be required to enter the recipients name and email 
        address. This is what will show up in the agreements list. When it's time to mail the agreement, you can enter something
        different.`,
        position: "right"
      },
      {
        selector: ".tutorial-agreement-wizard-dropdown",
        content: `Use this dropdown to select what agreement type you want to send. Depending on your account, different
        agreements may show up in this list (or none at all). When you select one a preview will show below. DO NOT fill out 
        the agreement in the preview - none of it will be saved on this page.`,
        position: "right"
      },
      {
        selector: ".tutorial-agreement-wizard-next",
        content: `After filling out the name, email and agreement type, click "Continue" to generate the agreement. You will 
        be redirected to it where you can begin filling out both the clients information and the dealsheet.`,
        position: "left"
      }
    ]
  }
};

export const agreementListTour = {
  index: "agreement-list",
  title: "Agreements List",
  config: {
    ...genericProps,
    steps: [
      {
        selector: ".tutorial-agreement-list-main",
        content: `This page will allow you to view all of the agreements you have access to.`,
        position: "right"
      },
      {
        selector: ".tutorial-agreement-list-main",
        content: `The agreements that show up in this list may change. If you're a fronter, they will be removed after a closer
        the agreement from you. If you're a closer, they will be removed when they are submitted, etc.`,
        position: "right"
      },
      {
        selector: ".tutorial-agreement-list-stale",
        content: `Note that agreements will automatically be removed from this list after they have become "stale"`,
        position: "right"
      }
    ]
  }
};

export const agreementPageTour = {
  index: "agreement-page",
  title: "Agreements Page",
  config: {
    ...genericProps,
    steps: [
      {
        selector: ".tutorial-agreement-page-header",
        content: `This is what an agreement's page looks like. This section at the top isn't visible to the client. 
        Important information regarding the agreement. is shown here, and depending on the state of the agreement, you can perform
        certain actions from this section. This includes sending the agreement, marking it as processed, etc.`,
        position: "right"
      },
      {
        selector: ".tutorial-agreement-page-visibility-toggle",
        content: `Dealsheets are built into the agreements. Use the controls here to switch between viewing the dealsheet and the
        agreement. Again, only you can see this - the client only sees the agreement. Make sure you fill out the dealsheet in its
        entirety before mailing it - once the client submits it, the dealsheet and the agreement can't be changed.`,
        position: "right"
      },
      {
        selector: ".tutorial-agreement-page-header",
        content: `Assuming you haven't mailed the agreement yet, at the very bottom there will be another black box with a "Save" button.
        You can use that to pre-fill the agreement before the client submits it.`,
        position: "right"
      }
    ]
  }
};

export default {
  dashboardTour,
  bookingListTour,
  bookingPageTour,
  memberListTour,
  memberPageTour,
  agreementWizardTour,
  agreementListTour,
  agreementPageTour
};
