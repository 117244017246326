import React, { Fragment } from "react";
import { Table, Icon } from "semantic-ui-react";

export default ({ row_bookings, row }) => {
  const member = row_bookings[0].point_usages[0].point_set.membership.member;
  const issues = member.issues.filter(row => row.closed_on === null);

  const url = "/browse/members/" + member.id;
  const handleClick = e => {
    e.preventDefault();
    if (window.confirm("Open member in new tab?")) {
      window.open(url);
    }
  };
  return (
    <Table.Cell selectable warning={issues.length ? true : undefined}>
      <a href={url} onClick={handleClick}>
        {issues.length > 0 && (
          <Fragment>
            <Icon color="yellow" name="exclamation triangle" />{" "}
          </Fragment>
        )}
        {row[0]}
      </a>
    </Table.Cell>
  );
};
