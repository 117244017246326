import InitialState from "./initial";
export default (state = InitialState, action) => {
  switch (action.type) {
    case "BOOKWIZ_GUESTS_LOADING":
      return {
        ...state,
        guests: {
          data: [],
          loading: true,
          error: false
        }
      };
    case "BOOKWIZ_GUESTS_ERROR":
      return {
        ...state,
        guests: {
          data: [],
          loading: false,
          error: action.data
        }
      };
    case "BOOKWIZ_GUESTS_DATA":
      return {
        ...state,
        guests: {
          data: action.data,
          loading: false,
          error: false
        }
      };
    // case "BOOKWIZ_MEMBERS_LOADING":
    //   return {
    //     ...state,
    //     members: {
    //       data: [],
    //       loading: true,
    //       error: false
    //     }
    //   };
    // case "BOOKWIZ_MEMBERS_ERROR":
    //   return {
    //     ...state,
    //     members: {
    //       data: [],
    //       loading: false,
    //       error: action.data
    //     }
    //   };
    // case "BOOKWIZ_MEMBERS_DATA":
    //   return {
    //     ...state,
    //     members: {
    //       data: action.data,
    //       loading: false,
    //       error: false
    //     }
    //   };
    case "BOOKWIZ_UNITS_LOADING":
      return {
        ...state,
        units: {
          data: [],
          loading: true,
          error: false
        }
      };
    case "BOOKWIZ_UNITS_ERROR":
      return {
        ...state,
        units: {
          data: [],
          loading: false,
          error: action.data
        }
      };
    case "BOOKWIZ_UNITS_DATA":
      return {
        ...state,
        units: {
          data: action.data,
          loading: false,
          error: false
        }
      };
    default:
      return state;
  }
};
