import React, { Fragment } from "react";
import { Link } from "react-router-dom";

import { Breadcrumb } from "semantic-ui-react";

import ContextBar from "components/ContextBar";

export default () => (
  <ContextBar>
    <Breadcrumb>
      <Breadcrumb.Section>
        <Link to={"/"}>Portal</Link>
      </Breadcrumb.Section>
      <Breadcrumb.Divider>/</Breadcrumb.Divider>
      <Link to={"/browse/members"}>Members</Link>
      <Breadcrumb.Divider>/</Breadcrumb.Divider>
      <Breadcrumb.Section active>Membership</Breadcrumb.Section>
    </Breadcrumb>
  </ContextBar>
);

export const MemberBreadcrumb = ({ member, tab = false }) => (
  <ContextBar>
    <Breadcrumb>
      <Breadcrumb.Section>
        <Link to={"/"}>Portal</Link>
      </Breadcrumb.Section>
      <Breadcrumb.Divider>/</Breadcrumb.Divider>
      <Link to={"/browse/members"}>Members</Link>
      <Breadcrumb.Divider>/</Breadcrumb.Divider>
      {tab ? (
        <Fragment>
          <Breadcrumb.Section>
            <Link to={`/browse/members/${member.id}`}>
              {member.firstname} {member.lastname}
            </Link>
          </Breadcrumb.Section>
          <Breadcrumb.Divider>/</Breadcrumb.Divider>
          <Breadcrumb.Section active>{tab}</Breadcrumb.Section>
        </Fragment>
      ) : (
        <Breadcrumb.Section active>
          {member.firstname} {member.lastname}
        </Breadcrumb.Section>
      )}
    </Breadcrumb>
  </ContextBar>
);

export const MembershipBreadcrumb = ({ member, membership, tab = false }) => (
  <ContextBar>
    <Breadcrumb>
      <Breadcrumb.Section>
        <Link to={"/"}>Portal</Link>
      </Breadcrumb.Section>
      <Breadcrumb.Divider>/</Breadcrumb.Divider>
      <Link to={"/browse/members"}>Members</Link>
      <Breadcrumb.Divider>/</Breadcrumb.Divider>
      <Breadcrumb.Section>
        <Link to={`/browse/members/${member.id}`}>
          {member.firstname} {member.lastname}
        </Link>
      </Breadcrumb.Section>
      <Breadcrumb.Divider>/</Breadcrumb.Divider>
      {tab ? (
        <Fragment>
          <Breadcrumb.Section>
            <Link
              to={`/browse/members/${member.id}/membership/${membership.id}`}
            >
              {membership.resort_company.name} {membership.level}
            </Link>
          </Breadcrumb.Section>
          <Breadcrumb.Divider>/</Breadcrumb.Divider>
          <Breadcrumb.Section active>{tab}</Breadcrumb.Section>
        </Fragment>
      ) : (
        <Breadcrumb.Section active>
          {membership.resort_company.name} {membership.level}
        </Breadcrumb.Section>
      )}
    </Breadcrumb>
  </ContextBar>
);

export const LoadingBreadcrumb = () => (
  <ContextBar>
    <Breadcrumb>
      <Breadcrumb.Section>
        <Link to={"/"}>Portal</Link>
      </Breadcrumb.Section>
      <Breadcrumb.Divider>/</Breadcrumb.Divider>
      <Link to={"/browse/members"}>Members</Link>
      <Breadcrumb.Divider>/</Breadcrumb.Divider>
      <Breadcrumb.Section active>Loading...</Breadcrumb.Section>
    </Breadcrumb>
  </ContextBar>
);
