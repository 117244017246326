import React from "react";
import { Segment, Grid, Icon, Form, Message } from "semantic-ui-react";
import moment from "moment";
import { Wrapper } from "../../context";
import Submit from "./components/Submit";
import Prefill from "./components/Prefill";

const Signature = ({ context }) => {
  return (
    <Segment vertical>
      <Grid stackable>
        <Grid.Column width={5} />
        <Grid.Column width={11}>
          <Message>
            <Message.Header>
              <Icon name={"checkmark"} />
              Agreement
            </Message.Header>
            <p>
              By typing ("digitally signing") your name below you agree to the
              terms and conditions herein of this contract, and will be deemed a
              valid signature.
            </p>
            <Grid columns={2} stackable>
              <Grid.Column>
                {context.functions.createInput(<Form.Input />, "signature", {
                  name: "signature",
                  label: "Your name",
                  placeholder: "Your name",
                  required: true,
                  autoComplete: "off",
                  fluid: true
                })}
                <p style={{ textAlign: "right" }}>
                  {context.agreement.original_data.received_on ? (
                    <span>
                      {"signed at "}
                      {context.agreement.original_data.received_on}
                    </span>
                  ) : (
                    <span>
                      {"signing at "}
                      {moment().format("MMM Do, YYYY")}
                    </span>
                  )}
                </p>
              </Grid.Column>
              <Grid.Column>
                {context.type === "external" &&
                  !context.agreement.original_data.received_on && (
                    <Form.Field>
                      <label>&nbsp;</label>
                      <Submit />
                    </Form.Field>
                  )}
              </Grid.Column>
            </Grid>
          </Message>
          <Prefill context={context} />
        </Grid.Column>
      </Grid>
    </Segment>
  );
};
export default Wrapper(Signature);
