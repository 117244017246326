import React from "react";
import { FiltersModal } from "components/PortalTable/parts";
import { Dropdown, Form } from "semantic-ui-react";
import { connect } from "react-redux";
import ResortCompanyFilter from "components/ResortCompanyFilter";

import { OwnerIssues } from "fixtures/issues";
import membershipLevels from "fixtures/membershipLevels";
import issuerCompanies from "fixtures/issuerCompanies";

const issueFilter = (applied, row) => {
  switch (applied.issues) {
    case "any":
      return true;
    case "none":
      return row.issues.filter(i => i.closed_on === null).length === 0;
    case "only":
      return row.issues.filter(i => i.closed_on === null).length > 0;
    default:
      return !!row.issues.find(
        i => i.closed_on === null && i.category === applied.issues
      );
  }
};
const checkCompanies = (applied, row) => {
  const ms = row.memberships;
  let valid = true;
  const filterFn = (ms, id) =>
    !!ms.filter(ms => ms.resort_company.id.toString() === id).length;
  for (var id in applied.companies) {
    const a = applied.companies[id];
    const b = filterFn(ms, id);
    if (!a === b) {
      valid = false;
    }
  }
  return valid;
};

const membershipFilter = (applied, row) => {
  const memberships = row.memberships;
  switch (applied.membership) {
    case "Plus":
    case "Silver":
    case "Gold":
    case "Platinum":
    case "Presidential":
    case "Unspecified":
      return memberships
        .map(membership => membership.level)
        .includes(applied.membership);
    default:
      return true;
  }
};

const signupCompany = (applied, row) => {
  const memberships = row.memberships;
  switch (applied.signUpCompany) {
    case "Unknown":
    case "Premier":
    case "MPerks":
    case "ERewards":
    case "EPC":
      return memberships
        .map(membership => membership.issuer_company)
        .includes(applied.signUpCompany);
    default:
      return true;
  }
};

const memberStatus = [
  { value: true, text: "Inactive Only" },
  { value: false, text: "Active Only" },
  { value: "any", text: "Show All" }
];

const memberLevel = [{ value: "any", text: "Any" }, ...membershipLevels];
const signUpCompany = [{ value: "any", text: "Any" }, ...issuerCompanies];

const issues = [
  { value: "any", text: "Any (not filtered)" },
  { value: "only", text: "Only members with open issues" },
  { value: "none", text: "Only members without open issues" },
  ...OwnerIssues.map(i => ({
    ...i,
    text: `If has issue: ${i.text}`,
    disabled: false
  }))
];

export default ({ namespace }) => {
  return (
    <FiltersModal
      namespace={namespace}
      filters={{
        deleted: false,
        companies: {},
        issues: "any",
        membership: "any",
        signUpCompany: "any"
      }}
      buildFilters={applied => {
        const fns = [];
        if (applied.deleted !== "any") {
          fns.push(row => row.deleted === applied.deleted);
        }
        fns.push(row => checkCompanies(applied, row));
        fns.push(row => issueFilter(applied, row));
        fns.push(row => membershipFilter(applied, row));
        fns.push(row => signupCompany(applied, row));
        return fns;
      }}
      modalProps={{
        size: "mini"
      }}
      children={(applied, update) => (
        <Form>
          <Form.Field>
            <label>Member Status</label>
            <Dropdown
              style={{ marginRight: ".25rem" }}
              selection
              onChange={(e, { value }) => update("deleted", value)}
              value={applied.deleted}
              options={memberStatus}
              selectOnBlur={false}
            />
          </Form.Field>
          <Form.Field>
            <label>Issues</label>
            <Dropdown
              selection
              search
              onChange={(e, { value }) => update("issues", value)}
              value={applied.issues}
              options={issues}
              selectOnBlur={false}
            />
          </Form.Field>
          <Form.Dropdown
            label="Membership Level"
            onChange={(e, { value }) => update("membership", value)}
            value={applied.membership}
            options={memberLevel}
            selection
            selectOnBlur={false}
          />
          <Form.Dropdown
            label="Sign Up Company"
            onChange={(e, { value }) => update("signUpCompany", value)}
            value={applied.signUpCompany}
            options={signUpCompany}
            selection
            selectOnBlur={false}
          />
          <br />
          <Form.Field>
            <ResortCompanyFilter
              applied={applied.companies}
              set={(id, val) =>
                update("companies", {
                  ...applied.companies,
                  [id]: val
                })
              }
            />
          </Form.Field>
        </Form>
      )}
    />
  );
};
