import React, { Component } from "react";
import { Message } from "semantic-ui-react";

export default class Check extends Component {
  render() {
    return (
      <Message color="black">
        <Message.Header>Check printed for booking</Message.Header>
        <p>
          This booking's owner has been paid for this booking. This booking can
          no longer be modified.
        </p>
        <br />
        <a href={`/browse/checks/${this.props.id}`} style={{ color: "#fff" }}>
          Click to view check
        </a>
      </Message>
    );
  }
}
