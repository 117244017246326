import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import { Wrapper } from "components/Agreement/context";

const Header = ({ context, children, hideBranding }) => {
  const company = context.agreement.account_company_legal_name;
  const branding = context.agreement.account_company_logo;

  return (
    <StyledHeader>
      {!hideBranding && branding && (
        <img src={branding} alt={`${company} branding`} />
      )}
      <h2>{company}</h2>
      {children}
    </StyledHeader>
  );
};

Header.propTypes = {
  context: PropTypes.object,
  children: PropTypes.any,
  hideBranding: PropTypes.bool
};

Header.defaultProps = {
  hideBranding: false
};

export default Wrapper(Header);

const StyledHeader = styled.header`
  text-align: center;
  margin: 3rem 0 2rem;
  img {
    max-width: 50%;
  }
  h2 {
    font-size: 2rem;
    margin-bottom: 0.5rem;
  }
  p {
    font-size: 1.25rem;
    text-transform: uppercase;
    text-align: center !important;
    margin-bottom: 0.5rem;
  }
`;
