import React from "react";
import { Comment as SComment, Icon } from "semantic-ui-react";
import { formatMJY } from "utils/dates";
import Reply from "../Reply";
import Avatar from "./components/Avatar";
import Author from "./components/Author";
import Delete from "./components/Delete";
import Message from "./components/Message";
import Heart from "./components/Heart";
export default class Comment extends React.Component {
  reply = () => {
    const { comment, setReplying } = this.props;
    setReplying(comment.id);
  };
  render() {
    const {
      comment,
      classes,
      replying,
      setReplying,
      thread,
      reload
    } = this.props;
    const commentClass = comment.is_system ? classes.systemComment : undefined;
    // if comment is deleted and no children exist, hide the comment?
    // if comment is deleted, do not show a reply button, do not show hearts
    return (
      <SComment className={commentClass}>
        <Avatar comment={comment} />
        <SComment.Content>
          <Author comment={comment} />
          <SComment.Metadata>
            - <span className="date">{formatMJY(comment.date)}</span>
            - <span className="seen">Seen by {comment.seen.length}</span>
          </SComment.Metadata>
          <Message comment={comment} />
          <SComment.Actions>
            <SComment.Action onClick={this.reply}>
              <Icon name={"reply"} />Reply
            </SComment.Action>
            <Heart comment={comment} reload={reload} />
            <Delete comment={comment} reload={reload} />
          </SComment.Actions>
          {replying === comment.id && (
            <Reply
              comment={comment}
              setReplying={setReplying}
              thread={thread}
              reload={reload}
            />
          )}
          {comment.children.length > 0 && (
            <SComment.Group className={classes.comments}>
              {comment.children
                .sort((a, b) => new Date(a.date) - new Date(b.date))
                .map((child, key) => (
                  <Comment {...this.props} comment={child} key={key} />
                ))}
            </SComment.Group>
          )}
        </SComment.Content>
      </SComment>
    );
  }
}
