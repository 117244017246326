import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import { Container, Header } from "semantic-ui-react";

import AjaxComponent from "components/AjaxComponent";
import Agreement from "components/Agreement";
import { usePrevious } from "effects";

const Preview = props => {
  const ajaxRef = useRef(null);
  const prevProps = usePrevious(props);

  useEffect(() => {
    // triggers a reload
    if (props.templateID) {
      if (props.templateID !== prevProps.templateID) {
        if (ajaxRef.current && typeof ajaxRef.current.reload === "function") {
          ajaxRef.current.reload();
        }
      }
    }
  });

  const ajaxConfig = {
    ajaxConfig: {
      url: "/apis/portal/agreementtemplates",
      data: {
        action: "read_one",
        data: {
          ID: props.templateID
        }
      }
    },
    ref: ajaxRef
  };

  return !!props.templateID ? (
    <PreviewContainer>
      <AjaxComponent {...ajaxConfig}>
        {({ data }) =>
          !!data.id && (
            <>
              <PreviewMask />
              <Agreement
                type={"preview"}
                data={{
                  id: null,
                  template: data,
                  data: JSON.stringify({
                    // ...
                  })
                }}
              />
            </>
          )
        }
      </AjaxComponent>
    </PreviewContainer>
  ) : (
    <Container>
      <br />
      <Header as="h3" content="Select an agreement to get started." />
    </Container>
  );
};

Preview.propTypes = {
  templateID: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired
};

export default Preview;

const PreviewContainer = styled.div`
  position: relative;
  padding: 2rem;
  width: calc(100% + 3rem);
  min-height: 100%;
  margin: -1.5rem;
  background-image: url("/img/logo/preview.png");
  /* box-shadow: 0px 0px 5px 0px #888; */
`;

const PreviewMask = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  cursor: not-allowed;
`;
