import React, { Fragment } from "react";
import { Form, Header, Button } from "semantic-ui-react";

export default class Personal extends React.Component {
  /**
   * Create a handled input for the owner names
   * @param {Integer} index - index of the owner's entry in .data
   * @param {ReactElement} el - element to clone and assign value/onChange props to
   * @param {String} source - property name in .data
   * @param {Object} props - addtl. props to assign
   * @return {ReactElement}
   */

  state = {
    removeDisabled: false
  };

  componentDidUpdate(nextProps) {
    const { data } = this.props.context;
    if (nextProps.context.data.owner_names != data.owner_names) {
      this.handleRemoveButtonDisabledState();
    }
  }

  handleRemoveButtonDisabledState = () => {
    const { data } = this.props.context;

    if (data.owner_names.length > 1) {
      return this.setState({ removeDisabled: false });
    }
    return this.setState({ removeDisabled: true });
  };

  createFormInput = (index, el, source, props = {}) => {
    const {
      data,
      functions: { setData }
    } = this.props.context;
    return React.cloneElement(el, {
      value: data.owner_names[index][source] || "",
      onChange: (e, { value }) => {
        if (!props.readOnly) {
          const newData = data.owner_names.map((r, i) => {
            return i === index ? { ...r, [source]: value } : r;
          });
          setData("owner_names", newData);
        }
      },
      ...props
    });
  };
  /**
   * Add another owner name form
   */
  handleAddOne = () => {
    const {
      data,
      functions: { setData }
    } = this.props.context;
    const newData = data.owner_names.concat({
      fullname: "",
      status: "",
      married: ""
    });
    setData("owner_names", newData);
  };
  /**
   * Remove last owner
   */
  handleRemoveOne = () => {
    const {
      data,
      functions: { setData }
    } = this.props.context;
    const newData = data.owner_names.slice(0, -1);
    setData("owner_names", newData);
  };
  /**
   * render
   */
  render() {
    const { removeDisabled } = this.state;
    const {
      data,
      config,
      functions: { createInput, shouldFieldBeDisabled, shouldFieldBeReadOnly }
    } = this.props.context;
    const readOnly = shouldFieldBeReadOnly("owner_names");
    const disabled = shouldFieldBeDisabled("owner_names", readOnly);
    return (
      <Fragment>
        {createInput(<Form.Input />, "name_on_deed", {
          name: "name_on_deed",
          placeholder: "Name on deed",
          label: "Name on deed",
          autoComplete: "off",
          required: true
        })}
        <Form.Group widths="equal">
          {createInput(<Form.Dropdown />, "fees_up_to_date", {
            name: "fees_up_to_date",
            placeholder: "Select one...",
            label: "Maintenance Fees Up To Date?",
            selection: true,
            autoComplete: "off",
            fluid: true,
            options: [
              { value: "", text: "Select one..." },
              { value: "yes", text: "Yes" },
              { value: "no", text: "No" }
            ]
          })}
          {createInput(<Form.Input />, "mortgage", {
            name: "mortgage",
            placeholder: "Mortgage balance",
            label: "Mortgage Balance",
            autoComplete: "off",
            required: true
          })}
        </Form.Group>
        <Header as="h3" dividing>
          Owners
        </Header>
        {data.owner_names &&
          data.owner_names.map((owner, i) => (
            <Form.Group widths="equal" key={i}>
              {this.createFormInput(i, <Form.Input />, "fullname", {
                name: "fullname",
                label: "Full name",
                placeholder: "Full name",
                required: true,
                autoComplete: "off",
                width: 8,
                disabled,
                readOnly
              })}
              {this.createFormInput(i, <Form.Dropdown />, "married", {
                name: "married",
                label: "Married",
                placeholder: "Select one...",
                required: true,
                autoComplete: "off",
                width: 4,
                selection: true,
                fluid: true,
                options: [
                  { value: "", text: "Select one..." },
                  { value: "yes", text: "Yes" },
                  { value: "no", text: "No" }
                ],
                disabled,
                readOnly
              })}
              {this.createFormInput(i, <Form.Dropdown />, "status", {
                name: "status",
                label: "Status (if applicable)",
                placeholder: "Select one...",
                required: false,
                autoComplete: "off",
                width: 4,
                selection: true,
                fluid: true,
                options: [
                  { value: "", text: "Select one..." },
                  { value: "divorced", text: "Divorced" },
                  { value: "deceased", text: "Deceased" }
                ],
                disabled,
                readOnly
              })}
            </Form.Group>
          ))}
        <Form.Group>
          <Form.Button
            onClick={this.handleAddOne}
            color={config.color}
            disabled={readOnly || disabled}
            type="button"
            content="Add another"
          />
          <Form.Button
            onClick={this.handleRemoveOne}
            disabled={readOnly || disabled || removeDisabled}
            type="button"
            content="Remove one"
          />
        </Form.Group>
      </Fragment>
    );
  }
}
