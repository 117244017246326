import React, { Component } from "react";
import { Form } from "semantic-ui-react";
import { connect } from "react-redux";

// import redux action(s)
import { createAlert } from "redux/Alertbar/actions";

const options = [
  { key: "message", value: "message", text: "message" },
  { key: "alert", value: "alert", text: "alert" },
  { key: "warning", value: "warning", text: "warning" },
  { key: "disconnected", value: "disconnected", text: "disconnected" }
];

// this component is for testing purposes only
// should not be used in production
class SendAlert extends Component {
  state = {
    message: "",
    alertType: "message"
  };

  handleChange = (e, { value, name }) => {
    this.setState({ [name]: value });
  };

  render() {
    const { createAlert } = this.props;
    const { alertType, message } = this.state;
    return (
      <Form>
        <Form.Group>
          <Form.Dropdown
            name="alertType"
            options={options}
            value={alertType}
            onChange={this.handleChange}
            selection
          />
          <Form.Input
            name="message"
            onChange={this.handleChange}
            value={message}
          />
          <Form.Button
            onClick={() => createAlert(alertType, message)}
            content="Send Alert"
          />
        </Form.Group>
      </Form>
    );
  }
}

const mapDispatchToProps = {
  createAlert
};

export default connect(
  undefined,
  mapDispatchToProps
)(SendAlert);
