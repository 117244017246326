import React from "react";
import { Checkbox } from "semantic-ui-react";
import { connect } from "react-redux";
import { addToQueue, removeFromQueue } from "redux/EOQReport/actions";
import injectSheet from "react-jss";

class HeaderCell extends React.Component {
  handleClick = (e, { checked }) => {
    const { IDs, addToQueue, removeFromQueue } = this.props;
    if (checked) {
      addToQueue(IDs);
    } else {
      removeFromQueue(IDs);
    }
  };
  isChecked = () => {
    const { IDs, queue } = this.props;
    return (
      IDs.map(id => queue.indexOf(id) !== -1).filter(row => row === false)
        .length === 0
    );
  };
  render() {
    return (
      <Checkbox checked={this.isChecked()} onClick={this.handleClick} fitted />
    );
  }
}

const styles = {
  // ...
};

const mapStateToProps = state => ({
  queue: state.EOQReport.queue
  // ...
});
const mapDispatchToProps = dispatch => ({
  addToQueue: ids => dispatch(addToQueue(ids)),
  removeFromQueue: ids => dispatch(removeFromQueue(ids))
  // ...
});

const connected = connect(
  mapStateToProps,
  mapDispatchToProps
)(HeaderCell);
export default injectSheet(styles)(connected);
