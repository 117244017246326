export const addCommas = (number, fixed = 0) => {
  let asNumber = Number(number);
  if (!asNumber) {
    return fixed ? (0).toFixed(fixed) : "0";
  } else {
    if (fixed) {
      return asNumber.toFixed(fixed).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    } else {
      return asNumber.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
  }
};
export const isValidFinance = (value) =>
  value === "" || value.match(/^([0-9]+)?\.?([0-9]{1,2})?$/);
