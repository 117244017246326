import React, { Component } from "react";
import PropTypes from "prop-types";
import { Container, Form } from "semantic-ui-react";
import moment from "moment";
import styled from "styled-components";

import { Wrapper } from "components/Agreement/context";
import { isObjectEmpty } from "utils/objects";
import { addCommas } from "utils/numbers";
import states from "fixtures/states";
import { specificCompany } from "../../helpers";

import Header from "../Header";
import Highlight from "../Highlight";

const highlight = (text) => <Highlight content={text} />;

class Contact extends Component {
  static propTypes = {
    context: PropTypes.object.isRequired,
  };

  render() {
    const { context } = this.props;
    const { functions } = context;
    const { createInput } = functions;
    const { account_company } = context.agreement.original_data.template;

    const company = context.agreement.account_company_legal_name;
    const address = specificCompany(
      account_company.name,
      context.agreement.original_data.created_on
    );
    const agreementDate = moment(context.data.agreement_date).format(
      "DD-MMM-YY"
    );
    const baseAmount = context.data.base_amount;
    const baseAmountFormat = `$${addCommas(baseAmount, 2)}`;
    const downPayment = context.data.down_payment;
    const downPaymentFormat = `$${addCommas(downPayment, 2)}`;
    const financedAmount = context.data.financed_amount;
    const financedAmountFormat = `$${addCommas(financedAmount, 2)}`;
    const interestRate = context.data.interest_rate;
    const interestRateFormat = `${Number(interestRate).toFixed(2)}%`;
    const agreementTerm = context.data.agreement_term;
    const loanStartDate = context.data.loan_start_date;
    const loanStartDateFormat = moment(loanStartDate).format("DD-MMM-YY");
    const monthlyPayment = context.data.monthly_payment;
    const monthlyPaymentFormat = `$${addCommas(monthlyPayment, 2)}`;

    if (isObjectEmpty(context.data)) return null;

    let dropdownStates = [...states];
    if (context.data.state) {
      if (!states.find((state) => state.key === context.data.state)) {
        dropdownStates.push({
          key: context.data.state,
          text: context.data.state,
          value: context.data.state,
        });
      }
    }

    return (
      <Container text>
        <Header>
          <p>Deed transfer agreement and consumer note</p>
        </Header>
        <p>
          This Agreement and Consumer Note, as applicable, (hereinafter
          "Agreement") is made and entered on {highlight(agreementDate)} by and
          between {highlight(company)}, a {address.state.full} Corporation
          (hereinafter "CLUB") whose address is {highlight(address.full)} and{" "}
        </p>

        <Form.Group widths="equal" autoComplete="off">
          {createInput(<Form.Input />, "traveler_name", {
            type: "text",
            label: "Traveler Name",
            placeholder: "Gordon Norman",
            required: true,
            autoComplete: "off",
          })}
          {createInput(<Form.Input />, "cotraveler_name", {
            label: "Co-Traveler Name",
            placeholder: "Dianne Ameter",
          })}
        </Form.Group>

        {createInput(<Form.Input />, "address", {
          label: "Street Address",
          placeholder: address.street,
          required: true,
          autoComplete: "off",
        })}

        <Form.Group widths="equal">
          {createInput(<Form.Input />, "city", {
            label: "City",
            placeholder: address.city,
            required: true,
            autoComplete: "off",
          })}
          {createInput(<Form.Dropdown />, "state", {
            label: "State",
            options: dropdownStates,
            selection: true,
            selectOnBlur: false,
            search: true,
            placeholder: address.state.full,
            required: true,
            allowAdditions: true,
          })}
          {createInput(<Form.Input />, "zip", {
            label: "Zip",
            placeholder: address.zipcode,
            required: true,
            autoComplete: "off",
          })}
        </Form.Group>

        <Form.Group widths="equal">
          {createInput(<Form.Input />, "home_phone", {
            label: "Home Phone",
            required: true,
            autoComplete: "off",
          })}
          {createInput(<Form.Input />, "work_phone", {
            label: "Work Phone",
            autoComplete: "off",
          })}
          {createInput(<Form.Input />, "account_number", {
            label: "Account Number",
            required: true,
            autoComplete: "off",
          })}
        </Form.Group>

        <p>
          Client and/or Co-Client hereinafter referred to as "The client", "I",
          "me" or "my")
        </p>
        <ol>
          <li>
            <Terms
              dateCreated={context.agreement.original_data.created_on}
              baseAmount={baseAmount}
              company={company}
            />
            {/* <p>
              Purchase of Services. Client and CLUB acknowledge that Client has
              obtained the Services set forth in Paragraph 4 of this Agreement.
              Traveler has the option of paying cash for the Services or paying
              for the Services on credit as set forth herein. Traveler
              understands this cost is a one time membership fee that is non
              refundable and is earned upon receipt. {highlight(baseAmount)} fee
              is "Pre Paid Rent".
            </p> */}
            <ul className="empty">
              <li>
                <p>
                  To pay the Cash Sales Price of {highlight(baseAmount)} for the
                  Services as provided in Paragraph 2 below (“Cash Option”).
                </p>
              </li>
              <li>
                <p>
                  To pay for the Services on credit as provided in Paragraph 3
                  below (“Installment Option”)
                </p>
              </li>
            </ul>
          </li>
          <li>
            <p>
              Cash Option. If I chose the Cash Option above, I promise to pay{" "}
              {highlight(company)}, the Cash Price of {highlight(baseAmount)}.
              This amount is due when this Agreement is Signed
            </p>
          </li>
          <li>
            <p>
              Installment Option. If I chose the Installment Option above, I
              promise to make monthly payments to {highlight(company)} in
              accordance with the following payment terms:
            </p>
            <ol className="lettered">
              <li>
                <p>
                  Cash Sales Price
                  <Highlight floatRight content={baseAmountFormat} />
                </p>
              </li>
              <li>
                <p>
                  Cash Down Payment{" "}
                  <Highlight floatRight content={downPaymentFormat} />
                </p>
              </li>
              <li>
                <p>
                  Amount Financed (unpaid balance)[(a) minus (b)]{" "}
                  <Highlight floatRight content={financedAmountFormat} />
                </p>
              </li>
              <li>
                <p>
                  Rate of Interest{" "}
                  <Highlight floatRight content={interestRateFormat} />
                </p>
              </li>
              <li>
                <p>
                  Terms of Agreement (Months){" "}
                  <Highlight floatRight content={agreementTerm} />
                </p>
              </li>
              <li>
                <p>
                  Loan Start Date{" "}
                  <Highlight floatRight content={loanStartDateFormat} />
                </p>
              </li>
              <li>
                <p>
                  Monthly Payment{" "}
                  <Highlight floatRight content={monthlyPaymentFormat} />
                </p>
              </li>
            </ol>
          </li>
        </ol>
        <p>
          The payment schedule and other terms are set forth in detail in
          Exhibit "A" which is attached hereto and incorporated herein by this
          reference. All payments will be applied first to any late charge due,
          then to finance charge due with the remainder being applied to the
          Amount Financed. Payment shall be made to {highlight(company)} at such{" "}
          {highlight(address.full)}
        </p>

        <SignInitial context={context} />
      </Container>
    );
  }
}

export default Wrapper(Contact);

const SignInitial = ({ context }) => {
  const { createInput } = context.functions;

  return (
    <Wrap>
      {createInput(<Form.Input />, "initial", {
        label: "initial",
        autoComplete: "off",
        fluid: false,
        required: true,
      })}
    </Wrap>
  );
};

SignInitial.propTypes = {
  context: PropTypes.object.isRequired,
};

const Wrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;

  &&& {
    .field {
      display: flex;
      justify-content: space-between;
      align-items: center;

      label {
        display: inherit;
        margin-right: 1rem;
      }
      .input {
      }
    }
  }
`;

const Terms = (props) => {
  const { dateCreated, company, baseAmount } = props;

  if (dateCreated >= "2019-11-20 00:00:00") {
    return (
      <p>
        Purchase of Exit Services. Client and {highlight(company)} acknowledge
        that Client has obtained the Services set forth in Paragraph 4 of this
        Agreement. Traveler has the option of paying cash for the Services or
        paying for the Services on credit as set forth herein. Traveler
        understands this cost is a one-time fee. {highlight(baseAmount)} fee is
        "cost of exit".
      </p>
    );
  }

  return (
    <p>
      Purchase of Services.Client and CLUB acknowledge that Client has obtained
      the Services set forth in Paragraph 4 of this Agreement. Traveler has the
      option of paying cash for the Services or paying for the Services on
      credit as set forth herein. Traveler understands this cost is a one time
      membership fee that is non refundable and is earned upon receipt.{" "}
      {highlight(baseAmount)} fee is "Pre Paid Rent".
    </p>
  );
};
