import React from "react";
import PortalTable from "components/PortalTable";
export default class List extends React.Component {
  render() {
    const {
      config: { List }
    } = this.props;
    if (List) {
      return <PortalTable {...List} />;
    }
    return null;
  }
}
