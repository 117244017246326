import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import styled from "styled-components";
import { NavLink, withRouter } from "react-router-dom";
import { Dropdown } from "semantic-ui-react";

import {
  backgroundColor,
  backgroundColorHover,
  borderColor,
  fontColor
} from "theme";

const Navigation = ({ User, history, location, match }) => {
  const { allowed_pages: pageGroups = [] } = User;

  return (
    <StyledNavigation>
      {pageGroups
        .sort((a, b) => (a.index < b.index ? 1 : -1))
        .filter(group => group.name !== "General")
        // .slice(1)
        .map((group, groupKey) => {
          if (group.pages.length) {
            const { pathname } = location;
            const paths = group.pages.map(page => page.uri);

            const isActive = paths
              .map(path => pathname.indexOf(path) > -1)
              .includes(true);

            return (
              <StyledDropdown
                item
                key={groupKey}
                text={group.name}
                icon={null}
                className={isActive ? "active" : null}
              >
                <Dropdown.Menu>
                  {group.pages.map((page, pageKey) => {
                    return (
                      <Dropdown.Item
                        as={NavLink}
                        to={page.uri}
                        text={page.name}
                        icon={page.icon}
                        key={pageKey}
                      />
                    );
                  })}
                </Dropdown.Menu>
              </StyledDropdown>
            );
          }
        })}
    </StyledNavigation>
  );
};

Navigation.propTypes = {
  User: PropTypes.object.isRequired
};

const StyledNavigation = styled.nav`
  display: inline-flex;
  height: 50px;

  .dropdown {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.5rem 1.5rem;

    background-color: ${backgroundColor};
    border: none;

    font-weight: 600;

    cursor: pointer;

    &:hover,
    &:focus,
    &.active {
      background-color: ${backgroundColorHover};
      color: ${fontColor};
    }

    @media screen and (max-width: 884px) {
      padding: 0.5rem 1rem;
    }

    @media screen and (max-width: 744px) {
      padding: 0.5rem;
    }

    @media screen and (max-width: 674px) {
      display: none;
    }
  }
`;

const StyledDropdown = styled(Dropdown)`
  padding: 0.5rem 1.5rem;
  padding-top: 1rem;

  &:hover,
  &:focus,
  &.active {
    background-color: ${backgroundColorHover};
  }

  &&& {
    .menu {
      background-color: ${backgroundColor};
      border: 1px solid ${borderColor};
      color: ${fontColor};

      .item {
        &:hover,
        &:focus {
          background-color: ${backgroundColorHover};
        }
      }

      i {
        color: ${fontColor};
      }
    }
  }
`;

const mapStateToProps = state => ({
  User: state.User
});

export default connect(mapStateToProps)(withRouter(Navigation));
