import styled, { createGlobalStyle } from "styled-components";

export const Print = createGlobalStyle`
  @media print {
    html {
      font-size: 8pt;
      margin: 0.15in;

      &:last-child {
        page-break-after: avoid;
      }
    }

    body {
      max-width: 8.5in;
      margin: 0.25in;
    }

    input {
      page-break-inside: avoid;
    }

    .ui.text.container {
      width: 100% !important;
      max-width: none !important;
    }

    button,
    #alerts,
    #breadcrumbs,
    #config,
    #navbar,
    .reactour-portal,
    .ui.black.message,
    .dropdown.icon {
      display: none !important;
    }

    .ui.disabled.fluid.selection.dropdown .text {
      display: none !important;
    }

    .ui.raised {
      box-shadow: none !important;
      border: none !important;
      margin: 0 !important;
    }

    .segment {
      page-break-before: auto;
      page-break-inside: avoid;
      page-break-after: avoid;
    }

    table { page-break-inside:auto }
    tr    { page-break-inside:avoid; page-break-after:auto }
    thead { display:table-header-group }
    tfoot { display:table-footer-group }

    &&& {
      .disabled {
        opacity: 1 !important;
      }
      ::-webkit-input-placeholder { /* WebKit browsers */
          color: transparent !important;
      }
      :-moz-placeholder { /* Mozilla Firefox 4 to 18 */
          color: transparent !important;
      }
      ::-moz-placeholder { /* Mozilla Firefox 19+ */
          color: transparent !important;
      }
      :-ms-input-placeholder { /* Internet Explorer 10+ */
          color: transparent !important;
      }
      .default.text {
        color: transparent !important;
      }
    }

    /**
      "KEEP THESE"
     */
    .ui.text.container {
      max-width: none !important;
    }

    h1, h2, h3, h4, h5 {
      page-break-before: avoid !important;
      page-break-inside: avoid !important;
      page-break-after: avoid !important;
    }

    p, input {
      page-break-inside: avoid !important;
    }

    @page {
      margin: 0.25in;
    }
    /**
      "KEEP THESE"
    */
  }
`;

export const PageBreak = styled.div`
  @media print {
    display: block;
    height: 1px;
    margin: 0 0 0.25in;
    page-break-before: always;
    page-break-inside: never;
  }
`;
