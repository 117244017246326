import React, { Component } from "react";
import PropTypes from "prop-types";
import AjaxFn from "services/AjaxFn";
import { Button } from "semantic-ui-react";

export default class ProcessButton extends Component {
  static propTypes = {
    IDs: PropTypes.array.isRequired
  };

  state = {
    loading: false,
    processed: false
  };
  handleClick = () => {
    const { IDs } = this.props;
    this.setState({ loading: true });
    AjaxFn({
      url: "/apis/portal/checks",
      data: {
        action: "batch_process",
        data: { IDs: JSON.stringify(IDs) }
      },
      success: () => {
        this.setState({ processed: true });
        window.toastr.success();
      },
      failure: e => window.toastr.error(e),
      finally: () => this.setState({ loading: false })
    });
  };

  render() {
    const { IDs = [] } = this.props;
    if (!IDs.length || this.state.processed) {
      return null;
    } else {
      return (
        <Button
          primary
          basic
          loading={this.state.loading}
          disabled={this.state.loading}
          onClick={this.handleClick}
          content={"Process All"}
        />
      );
    }
  }
}
