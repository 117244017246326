import React, { Component } from "react";

import { Container } from "semantic-ui-react";

import AjaxComponent from "components/AjaxComponent";
import WithState from "components/WithState";
import Layout from "components/Layout";
import ContextBar from "components/ContextBar";
import Checks from "./components/Checks";

export default class CheckPrintPage extends Component {
  /**
   * URL-decodes and JSON-decodes the check IDs from the url
   * Automatically converts a single ID to an array format if only one
   * check is being viewed
   *
   * @return {Array}
   */
  getIDs = () => {
    const { id } = this.props.match.params;
    const ids = JSON.parse(decodeURIComponent(id));
    return Array.isArray(ids) ? ids : [ids];
  };

  getConfig = () => ({
    ajaxConfig: {
      url: "/apis/portal/checks",
      data: {
        action: "read_for_print",
        data: { IDs: this.getIDs() }
      }
    },
    stateful: false
  });

  render() {
    return (
      <AjaxComponent {...this.getConfig()}>
        {({ data, loading, error }) => {
          if (loading || error) {
            return (
              <Layout noBar>
                <ContextBar />
                <Container>
                  <br />
                  <WithState loading={loading} error={error} />
                </Container>
              </Layout>
            );
          }

          const checks = data.map(check => ({
            From: check.from_name,
            FromAddress: check.from_address,
            FromAddress2: check.from_address_2,
            Bank: check.bank_name,
            BankAddress: check.bank_address,
            BankAddress2: check.bank_address_2,
            CheckNumber: check.number,
            Date: check.printed_on,
            Amount: check.amount,
            Signature: check.signature,
            PayTo: check.pay_to,
            Memo: check.memo,
            AccountNumber: check.account_number,
            RoutingNumber: check.routing_number,
            processed: check.processed,
            id: check.id
          }));

          return <Checks checks={checks} />;
        }}
      </AjaxComponent>
    );
  }
}
