import React, { Component, Fragment } from "react";
import { Table } from "semantic-ui-react";
import { formatMJY } from "utils/dates";
import { addCommas } from "utils/numbers";
import calculateBooking from "utils/bookings";
import payrates from "fixtures/payrates";

const consolasRight = {
  textAlign: "right",
  fontFamily: "Consolas"
};

/**
 *	@prop bookings
 */
export default class BookingTable extends Component {
  pointsUsed = booking =>
    booking.point_usages.reduce((a, c) => a + c.amount, 0);

  getPayrateSetting = (calculated, booking) => {
    const payrate =
      calculated.payrate_setting === "-1.00"
        ? Number([...booking.point_usages].pop().point_set.membership.payrate)
        : Number(calculated.payrate_setting);
    const row = payrates.find(row => {
      return row.value === payrate.toFixed(2);
    });
    if (row) {
      return { ...row, setting: payrate };
    } else {
      return {};
    }
  };

  getUsedValues = (calculated, payrate) => {
    if (payrate < 11 && payrate > 0) {
      // $/1,000 points
      return {
        usedBookingFee: calculated.booking_fee,
        usedBookingCost: calculated.booking_cost,
        usedChannelFee: calculated.channel_fee
        // ...
      };
    } else {
      // % GRI based
      const rate = payrate / 100;
      return {
        usedBookingFee: "0.00",
        usedBookingCost: (Number(calculated.booking_cost) * rate).toFixed(2),
        usedChannelFee: (Number(calculated.channel_fee) * rate).toFixed(2)
      };
    }
  };

  render() {
    return (
      <Table basic={"very"} compact={"very"} celled>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Booking</Table.HeaderCell>
            <Table.HeaderCell>Confirmation Number(s)</Table.HeaderCell>
            <Table.HeaderCell>Points</Table.HeaderCell>
            <Table.HeaderCell>Payout</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {this.props.bookings.map((booking, key) => {
            const calculated = calculateBooking({
              ...booking,
              audits: [],
              cancellations: []
            });
            const payrate = this.getPayrateSetting(calculated, booking);
            const {
              usedBookingCost,
              usedChannelFee,
              usedBookingFee
            } = this.getUsedValues(calculated, payrate.setting);
            return (
              <Fragment key={key}>
                <Table.Row>
                  <Table.Cell>
                    <div>
                      <b>
                        {booking.guest.firstname} {booking.guest.lastname}
                      </b>
                    </div>
                    <p>
                      {formatMJY(booking.check_in, 2)} through{" "}
                      {formatMJY(booking.check_out, 2)}
                    </p>
                  </Table.Cell>
                  <Table.Cell>{booking.rco}</Table.Cell>
                  <Table.Cell style={consolasRight}>
                    {addCommas(this.pointsUsed(booking))}
                  </Table.Cell>
                  <Table.Cell style={consolasRight}>
                    {calculated.owner_pay}
                  </Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell colSpan="4" style={consolasRight}>
                    <div>
                      Gross Rental Income --- {calculated.gross_rental_income}
                    </div>
                    <div>Payrate --- {payrate ? payrate.text : ""}</div>
                    {usedChannelFee !== "0.00" && (
                      <div>Marketing Expense --- {usedChannelFee}</div>
                    )}
                    {usedBookingCost !== "0.00" && (
                      <div>Booking Costs --- {usedBookingCost}</div>
                    )}
                    {usedBookingFee !== "0.00" && (
                      <div>Booking Fees --- {usedBookingFee}</div>
                    )}
                    {calculated.owner_cost !== "0.00" && (
                      <div>Owner Costs --- {calculated.owner_cost}</div>
                    )}
                    <div>Final Income --- {calculated.owner_pay}</div>
                  </Table.Cell>
                </Table.Row>
              </Fragment>
            );
          })}
        </Table.Body>
      </Table>
    );
  }
}
