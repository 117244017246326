import React, { useState } from "react";
import { connect } from "react-redux";
import { sortableContainer, sortableElement } from "react-sortable-hoc";
import arrayMove from "array-move";
import PropTypes from "prop-types";
import styled from "styled-components";
import theme from "styled-theming";

import { portalBlack, offWhite } from "theme/colors";
import { set } from "redux/Storage/actions";
import Bookmark from "./Bookmark";

const Bookmarks = props => {
  const { bookmarks, setBookmarks } = props;
  const [state, setState] = useState({
    editing: false
  });
  // function to cease all editing
  const stopEditing = () => setState({ ...state, editing: false });
  // function to reorganize on drag and drop
  const handleSort = ({ oldIndex, newIndex }) => {
    const newBookmarks = arrayMove(bookmarks, oldIndex, newIndex);
    // we have to clear them and then re-add them otherwise
    // for some weird reason, the inputs get screwy
    setBookmarks([]);
    setBookmarks(newBookmarks);
  };

  if (bookmarks.length) {
    return (
      <SortableWrapper
        onSortEnd={handleSort}
        useDragHandle
        lockToContainerEdges
        lockAxis="y"
        lockOffset="25%"
        children={bookmarks.map((bookmark, index) => {
          // define a few item-specific props to pass down
          const setEditing = () => setState({ ...state, editing: index });
          const setName = name => {
            // update only the one that's currently being edited
            const newBookmarks = bookmarks.map((el, i) => ({
              ...el,
              name: index === i ? name : el.name
            }));
            // push the change, close the editor
            setBookmarks(newBookmarks);
            stopEditing();
          };
          const remove = () => {
            // filter all bookmarks out, except this one
            const newBookmarks = bookmarks.filter((el, i) => index !== i);
            // push the change, close the editor
            setBookmarks(newBookmarks);
          };

          return (
            <SortableBookmark
              key={`item-${index}`}
              index={index}
              bookmark={bookmark}
              editing={index === state.editing}
              setEditing={setEditing}
              stopEditing={stopEditing}
              remove={remove}
              setName={setName}
            />
          );
        })}
      />
    );
  }

  return (
    <Wrapper empty>
      <div className="content">
        <i className="fas fa-link" />
        <p>
          <strong>You haven't saved any pages yet.</strong>
        </p>
      </div>
    </Wrapper>
  );
};

Bookmarks.propTypes = {
  bookmarks: PropTypes.array.isRequired,
  setBookmarks: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  bookmarks: state.Storage.favoritePages
});

const mapDispatchToProps = dispatch => ({
  setBookmarks: bookmarks => dispatch(set("favoritePages", bookmarks))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Bookmarks);

/**
 * setup theme/styled components
 */
const iconColor = theme("mode", {
  light: portalBlack,
  dark: offWhite
});
const Wrapper = styled.div`
  display: grid;
  grid-auto-flow: row;
  grid-auto-rows: auto;
  grid-gap: 0.5rem;

  align-items: flex-start;

  i {
    color: ${iconColor};
  }

  ${({ empty }) => {
    if (empty)
      return `
        align-items: center;
        justify-content: center;
        height: 100%;
        text-align: center;

        i {
          font-size: 3rem;
          margin-bottom: 1rem;
        }
      `;
  }}
  a {
    button {
      text-align: left !important;
    }
  }
`;
/**
 * Setup sortable components
 */
const SortableWrapper = sortableContainer(({ children }) => (
  <Wrapper>{children}</Wrapper>
));
const SortableBookmark = sortableElement(props => <Bookmark {...props} />);
