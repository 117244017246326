import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";

import { Header, Table, Divider, Icon } from "semantic-ui-react";

import AjaxComponent from "components/AjaxComponent";
import { formatMJY } from "utils/dates";
import { addCommas } from "utils/numbers";
import { MemberBreadcrumb } from "../Breadcrumb";
import props from "../propTypes.js";

export default class MemberBookings extends Component {
  static propTypes = { ...props };
  /**
   * Take an array of all bookings and group them by membership (as an object)
   */
  getCategorizedBookings = data => {
    const memberships = {};
    data.forEach(booking => {
      const { membership } = booking.point_usages[0].point_set;

      if (typeof memberships[membership.id] === "undefined") {
        memberships[membership.id] = {
          ...membership,
          bookings: []
        };
      }

      memberships[membership.id].bookings.push(booking);
    });

    return Object.values(memberships);
  };

  renderTable = membership => {
    const bookings = membership.bookings.sort(
      (a, b) => new Date(b.check_in) - new Date(a.check_in)
    );

    return (
      <Table>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell content="Check-in" />
            <Table.HeaderCell content="Unit" />
            <Table.HeaderCell content="Guest" />
            <Table.HeaderCell content="Points" />
            <Table.HeaderCell content="Cancelled" />
            <Table.HeaderCell content="Paid" />
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {bookings.length ? (
            bookings.map(booking => {
              const cancelled = !!booking.cancellations.find(row => row.active);
              const check = !!booking.check.id;

              return (
                <Table.Row key={booking.id}>
                  <Table.Cell selectable>
                    <Link to={`/browse/bookings/${booking.id}`}>
                      {formatMJY(booking.check_in)}
                    </Link>
                  </Table.Cell>
                  <Table.Cell selectable>
                    <Link to={`/browse/units/${booking.unit.id}`}>
                      {booking.unit.resort.resort_company.name +
                        " " +
                        booking.unit.resort.name +
                        " " +
                        booking.unit.name}
                    </Link>
                  </Table.Cell>
                  <Table.Cell selectable>
                    <Link to={`/browse/guests/${booking.guest.id}`}>
                      {booking.guest.firstname + " " + booking.guest.lastname}
                    </Link>
                  </Table.Cell>
                  <Table.Cell>
                    {addCommas(
                      booking.point_usages.reduce((a, c) => a + c.amount, 0)
                    )}
                  </Table.Cell>
                  {cancelled ? (
                    <Table.Cell negative>
                      <Icon name="cancel" />
                      Yes
                    </Table.Cell>
                  ) : (
                    <Table.Cell>No</Table.Cell>
                  )}
                  {check ? (
                    <Table.Cell positive selectable>
                      <Link to={`/browse/checks/${booking.check.id}`}>
                        <Icon name="checkmark" />
                        Yes
                      </Link>
                    </Table.Cell>
                  ) : (
                    <Table.Cell negative>
                      <Icon name="cancel" />
                      No
                    </Table.Cell>
                  )}
                </Table.Row>
              );
            })
          ) : (
            <Table.Row>
              <Table.Cell colSpan="6">
                <div style={{ padding: "1rem" }}>
                  <p>There are no results to show.</p>
                </div>
              </Table.Cell>
            </Table.Row>
          )}
        </Table.Body>
      </Table>
    );
  };

  render() {
    const { member } = this.props;

    const config = {
      ajaxConfig: {
        url: "/apis/portal/members",
        data: {
          action: "get_all_bookings",
          data: {
            ID: member.id
          }
        }
      }
    };

    return (
      <div>
        <MemberBreadcrumb member={member} tab="Bookings" />
        <Header
          as="h1"
          dividing
          icon="address book outline"
          content={member.firstname + " " + member.lastname + " / Bookings"}
          subheader="View all member bookings"
        />
        <AjaxComponent {...config}>
          {({ data }) => {
            const categorized = this.getCategorizedBookings(data);

            if (!categorized.length) {
              return <p>There are no bookings for this member.</p>;
            }

            return (
              <Fragment>
                {categorized.map(membership => (
                  <Fragment key={membership.id}>
                    <Header
                      icon="cube"
                      content={
                        membership.resort_company.name + " " + membership.level
                      }
                    />
                    {this.renderTable(membership)}
                  </Fragment>
                ))}
                <Divider />
                <p>Hints:</p>
                <ul>
                  <li>Bookings are sorted by check-in</li>
                  <li>
                    The "check in" column will navigate you to that booking.
                  </li>
                  <li>The "guest" column will navigate you to the guest.</li>
                  <li>The "unit" column will navigate you to the unit.</li>
                  <li>
                    The "paid" column will navigate you to the booking's check,
                    if one has been printed.
                  </li>
                </ul>
              </Fragment>
            );
          }}
        </AjaxComponent>
      </div>
    );
  }
}
