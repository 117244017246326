import React from "react";
import { FiltersModal } from "components/PortalTable/parts";
import { Form } from "semantic-ui-react";

export default ({ namespace }) => (
  <FiltersModal
    {...{
      namespace,
      filters: {
        active: true
      },
      buildFilters: applied => {
        const fns = [];
        if (applied.active !== "any") {
          fns.push(b => b.active === applied.active);
        }
        return fns;
      },
      modalProps: {
        size: "small"
      },
      children: (applied, update) => (
        <Form>
          <Form.Dropdown
            {...{
              label: "Active Guests",
              selectOnBlur: false,
              selection: true,
              options: [
                { value: true, text: "Active Only" },
                { value: false, text: "Hide Active" },
                { value: "any", text: "Any" }
              ],
              value: applied.active,
              onChange: (e, { value }) => update("active", value)
            }}
          />
        </Form>
      )
    }}
  />
);
