import React, { Component } from "react";
import PropTypes from "prop-types";
import {
  Container,
  Header,
  Divider,
  Button,
  Grid,
  Dropdown
} from "semantic-ui-react";
import styled from "styled-components";
import { Link } from "react-router-dom";

// project components
import Check from "components/Check";
import Comments from "components/Comments";
import AjaxFn from "services/AjaxFn";

// scene specific components
import Bookings from "./components/Bookings";
import Processed from "./components/Processed";
import DeleteButton from "./components/DeleteButton";

export default class Content extends Component {
  static propTypes = {
    data: PropTypes.object.isRequired
  };

  convertCheckVisibility = value => {
    const {
      data: { id }
    } = this.props;
    AjaxFn({
      url: "/apis/portal/checks",
      data: {
        action: "set_visibility",
        data: {
          ID: id,
          value
        }
      },
      success: window.toastr["success"],
      failure: window.toastr["error"]
    });
  };

  processCheck = () => {
    const { data, reload } = this.props;
    AjaxFn({
      url: "/apis/portal/checks",
      data: {
        action: "batch_process",
        data: { IDs: JSON.stringify([data.id]) }
      },
      success: () => {
        window.toastr.success("Check Processed");
        reload();
      },
      failure: window.toastr["error"]
    });
  };

  render() {
    const { data, reload } = this.props;
    return (
      <Container>
        <Processed data={data} reload={reload} />
        <Header as={"h1"} dividing>
          Check Preview
          <Header.Subheader
            content={`Paid to ${
              data.pay_to
            }, Check #${data.number.toString().padStart(6, "0")}`}
          />
        </Header>
        <div style={{ margin: "0 auto", width: "8.5in" }}>
          <Check
            {...{
              From: data.from_name,
              FromAddress: data.from_address,
              FromAddress2: data.from_address_2,
              Bank: data.bank_name,
              BankAddress: data.bank_address,
              BankAddress2: data.bank_address_2,
              CheckNumber: data.number,
              Date: data.printed_on,
              Amount: data.amount,
              Signature: data.signature,
              PayTo: data.pay_to,
              Memo: data.memo,
              AccountNumber: data.account_number,
              RoutingNumber: data.routing_number,
              processed: data.processed,
              id: data.id
            }}
          />
        </div>
        <Divider />
        <div style={{ textAlign: "right" }}>
          <Dropdown text="Actions" button basic floating>
            <Dropdown.Menu direction="left">
              <Dropdown.Item
                icon="thumbs up"
                text="Process Check"
                onClick={this.processCheck}
              />
              <Dropdown.Item
                as={Link}
                to={`/browse/checks/${data.id}/print`}
                text="Print Check"
                icon="print"
              />
              {data.bookings.length > 0 && (
                <Dropdown.Item
                  as={Link}
                  to={`/browse/checks/${data.id}/invoice`}
                  text="Generate Invoice"
                  icon="file alternate"
                />
              )}
              <Dropdown
                text="Convert Check"
                pointing="right"
                className="item"
                icon=""
                fluid
              >
                <Dropdown.Menu>
                  <Dropdown.Item
                    active={data.visibility === null}
                    icon="users"
                    text="Default"
                    onClick={() => this.convertCheckVisibility("null")}
                  />
                  <Dropdown.Item
                    active={data.visibility === "accounting"}
                    icon="user"
                    text="Accounting Only"
                    onClick={() => this.convertCheckVisibility("accounting")}
                  />
                  <Dropdown.Item
                    active={data.visibility === "admin"}
                    icon="user secret"
                    text="Admin Only"
                    onClick={() => this.convertCheckVisibility("admin")}
                  />
                </Dropdown.Menu>
              </Dropdown>
              <Dropdown.Divider />
              <Dropdown.Item as={DeleteButton} check={data} />
            </Dropdown.Menu>
          </Dropdown>
        </div>
        <br />
        <br />
        <Grid columns={2}>
          <Grid.Column>
            <Header as={"h3"} dividing>
              Bookings
            </Header>
            <Bookings data={data} />
          </Grid.Column>
          <Grid.Column>
            <Header as={"h3"} dividing>
              Comments
            </Header>
            <Comments thread={data.thread} />
          </Grid.Column>
        </Grid>
      </Container>
    );
  }
}
