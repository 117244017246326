import React from "react";
import { Form } from "semantic-ui-react";

import { Wrapper } from "components/Agreement/context";

const options = [
  { key: "12", text: "12 months (1 year)", value: "12" },
  { key: "18", text: "18 months (1.5 years)", value: "18" },
  { key: "24", text: "24 months (2 years)", value: "24" },
  { key: "30", text: "30 months (2.5 years)", value: "30" },
  { key: "36", text: "36 months (3 years)", value: "36" },
  { key: "48", text: "48 months (4 years)", value: "48" }
];

const CalculateFinanceAgreement = ({
  formIsValid,
  hasAdminPriv,
  loading,
  handleCalculation,
  context
}) => {
  const { createInput } = context.functions;
  return (
    <Form.Group widths="equal">
      {createInput(<Form.Input />, "base_amount", {
        icon: "dollar",
        iconPosition: "left",
        placeholder: "Base Amount",
        autoComplete: "off"
      })}
      {createInput(<Form.Input />, "down_payment", {
        icon: "dollar",
        iconPosition: "left",
        placeholder: "Down Payment",
        autoComplete: "off"
      })}
      {createInput(<Form.Input />, "interest_rate", {
        icon: "percent",
        placeholder: "Interest Rate",
        disabled: !hasAdminPriv,
        autoComplete: "off"
      })}
      {createInput(<Form.Dropdown />, "agreement_term", {
        placeholder: "Select Term Length",
        options,
        selectOnBlur: false,
        selection: true
      })}
      <Form.Button
        type="button"
        content="Calculate"
        disabled={!formIsValid || loading}
        onClick={handleCalculation}
        loading={loading}
        primary
        fluid
      />
    </Form.Group>
  );
};

export default Wrapper(CalculateFinanceAgreement);
