import React from "react";
import { Form, Divider, Icon, Grid, Message } from "semantic-ui-react";
import calculateBooking, { isBookingCancelled } from "utils/bookings";
import GrossRentalIncome from "./components/GrossRentalIncome";
import Taxes from "./components/Taxes";
import BookingFees from "./components/BookingFees";
import ChannelFees from "./components/ChannelFees";
import OwnerCost from "./components/OwnerCost";
import ManagementCosts from "./components/ManagementCosts";
import BookingCosts from "./components/BookingCosts";
import GuestPaymentsMade from "./components/GuestPaymentsMade";
import Refunds from "./components/Refunds";
import BookingPayrate from "./components/BookingPayrate";
import GuestPaymentDueMethod from "./components/GuestPaymentDueMethod";
import FinancesAudited from "./components/FinancesAudited";
import payrates from "fixtures/payrates";
import { connect } from "react-redux";
import { reloadBooking } from "redux/BookingPage/actions";
import Field from "./components/Field";
class Finances extends React.Component {
  render() {
    const { data, reload, user } = this.props;
    const calculated = calculateBooking(data);
    const cancelled = isBookingCancelled(data);
    const payrate = payrates.find(
      row => row.value === calculated.payrate_setting
    );
    const fieldData = {
      booking: data,
      reload,
      showIfEditable: (content, field = "edit_finances") => {
        if (cancelled) {
          return (
            <Message warning>
              <Message.Header>Cancelled</Message.Header>
              <p>
                This can not be edited right now becuase the booking is
                cancelled.
              </p>
            </Message>
          );
        } else if (calculated.audited && field !== "audit_finances") {
          return (
            <Message warning>
              <Message.Header>Finances Audited</Message.Header>
              <p>
                This can not be edited right now becuase the finances are
                audited.
              </p>
            </Message>
          );
        } else if (!data._actions.actions[field]) {
          return (
            <Message warning>
              <Message.Header>Locked</Message.Header>
              <p>You are not able to edit this right now.</p>
            </Message>
          );
        } else {
          return content;
        }
      }
    };
    return (
      <div>
        <Form>
          <Grid columns={3}>
            <Grid.Column>
              <GrossRentalIncome {...fieldData}>
                <Field clickable={true}>
                  <Form.Field>
                    <label>Gross Rental Income</label>$
                    {calculated.gross_rental_income}
                  </Form.Field>
                </Field>
              </GrossRentalIncome>
              <Taxes {...fieldData}>
                <Field clickable={true}>
                  <Form.Field>
                    <label>Taxes</label>${calculated.tax}
                  </Form.Field>
                </Field>
              </Taxes>
              <BookingFees {...fieldData}>
                <Field clickable={true}>
                  <Form.Field>
                    <label>Booking Fees</label>${calculated.booking_fee}
                  </Form.Field>
                </Field>
              </BookingFees>
              <ChannelFees {...fieldData}>
                <Field clickable={true}>
                  <Form.Field>
                    <label>Channel Fees</label>${calculated.channel_fee}
                  </Form.Field>
                </Field>
              </ChannelFees>
            </Grid.Column>
            <Grid.Column>
              <OwnerCost {...fieldData}>
                <Field clickable={true}>
                  <Form.Field>
                    <label>Owner Cost</label>${calculated.owner_cost}
                  </Form.Field>
                </Field>
              </OwnerCost>
              <ManagementCosts {...fieldData}>
                <Field clickable={true}>
                  <Form.Field>
                    <label>Management Costs</label>${calculated.management_cost}
                  </Form.Field>
                </Field>
              </ManagementCosts>
              <BookingCosts {...fieldData}>
                <Field clickable={true}>
                  <Form.Field>
                    <label>Booking Costs</label>${calculated.booking_cost}
                  </Form.Field>
                </Field>
              </BookingCosts>
            </Grid.Column>
            <Grid.Column>
              <GuestPaymentsMade {...fieldData}>
                <Field clickable={true}>
                  <Form.Field>
                    <label>Guest Payments Made</label>$
                    {calculated.guest_payment}
                  </Form.Field>
                </Field>
              </GuestPaymentsMade>
              <Field clickable={false}>
                <Form.Field>
                  <label>Guest Payment Due</label>$
                  {calculated.guest_payment_due}
                </Form.Field>
              </Field>
              <Refunds {...fieldData}>
                <Field clickable={true}>
                  <Form.Field>
                    <label>Refund</label>${calculated.refund}
                  </Form.Field>
                </Field>
              </Refunds>
            </Grid.Column>
          </Grid>
          <Divider horizontal>Totals</Divider>
          <Grid columns={3}>
            <Grid.Column>
              <Field clickable={false}>
                <Form.Field>
                  <label>Grand Total</label>${calculated.grand_total}
                </Form.Field>
              </Field>
              <Field clickable={false}>
                <Form.Field>
                  <label>Channel Fee Percent</label>
                  {"% "}
                  {calculated.channel_fee !== "0.00" &&
                  calculated.grand_total !== "0.00"
                    ? (
                        (Number(calculated.channel_fee) /
                          Number(calculated.grand_total)) *
                        100
                      ).toFixed(2)
                    : "0"}
                </Form.Field>
              </Field>
            </Grid.Column>
            <Grid.Column>
              <Field clickable={false}>
                <Form.Field>
                  <label>Profit</label>${calculated.profit}
                </Form.Field>
              </Field>
            </Grid.Column>
            <Grid.Column>
              <Field clickable={false}>
                <Form.Field>
                  <label>Owner Payout</label>${calculated.owner_pay}
                </Form.Field>
              </Field>
            </Grid.Column>
          </Grid>
          <br />
          <Divider horizontal>Settings</Divider>
          <Grid columns={3}>
            <Grid.Column>
              <BookingPayrate {...fieldData}>
                <Field clickable={true}>
                  <Form.Field>
                    <label>Booking Payrate</label>
                    <React.Fragment>
                      {payrate.value !== "-1.00" && (
                        <Icon name={"exclamation triangle"} color={"red"} />
                      )}
                      {payrate.text}
                    </React.Fragment>
                  </Form.Field>
                </Field>
              </BookingPayrate>
            </Grid.Column>
            <Grid.Column>
              <GuestPaymentDueMethod {...fieldData}>
                <Field clickable={true}>
                  <Form.Field>
                    <label>Guest Payment Due Method</label>
                    {calculated.guestpay_override_exists ? (
                      <React.Fragment>
                        <Icon name={"exclamation triangle"} color={"red"} />
                        {`Forced (${
                          calculated.payment_required
                            ? "Required"
                            : "Not Required"
                        })`}
                      </React.Fragment>
                    ) : (
                      <React.Fragment>
                        {`Default (${
                          calculated.payment_required
                            ? "Required"
                            : "Not Required"
                        })`}
                      </React.Fragment>
                    )}
                  </Form.Field>
                </Field>
              </GuestPaymentDueMethod>
            </Grid.Column>
            <Grid.Column>
              <FinancesAudited {...fieldData} audited={calculated.audited}>
                <Field clickable={true}>
                  <Form.Field>
                    <label>Finances Audited</label>
                    {calculated.audited ? (
                      <React.Fragment>
                        <Icon name={"checkmark"} color={"green"} />
                        Yes
                      </React.Fragment>
                    ) : (
                      <React.Fragment>
                        <Icon name={"cancel"} color={"red"} />
                        No
                      </React.Fragment>
                    )}
                  </Form.Field>
                </Field>
              </FinancesAudited>
            </Grid.Column>
          </Grid>
          <br />
          <Icon name={"info"} /> Finances can not be updated while the finances
          are audited.
          <br />
          <Icon name={"info"} /> No finances can be changed while the booking is
          cancelled.
          <br />
        </Form>
      </div>
    );
  }
}
const mapStateToProps = state => ({
  user: state.User
});
const mapDispatchToProps = dispatch => ({
  reload: () => dispatch(reloadBooking())
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Finances);
