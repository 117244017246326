import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { setPage } from "redux/PortalTables/actions";
import { Input } from "semantic-ui-react";

export class JumpToPage extends Component {
  static propTypes = {
    setPage: PropTypes.func.isRequired
  };

  handleChange = (page = 1) => {
    if (!/^\d+$/.test(page)) {
      return false;
    }

    if (page < 1) {
      return false;
    }

    this.props.setPage(this.props.namespace, page - 1);
  };

  render() {
    return (
      <Input
        label="Go to Page"
        defaultValue={1}
        min={1}
        onChange={(e, { value }) => this.handleChange(value)}
        type="number"
      />
    );
  }
}

const mapDispatchToProps = {
  setPage
};

export default connect(
  undefined,
  mapDispatchToProps
)(JumpToPage);
