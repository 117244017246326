import React, { Component } from "react";
import PropTypes from "prop-types";
import CiirusListing from "./components/CiirusListing";
export default class UnitRouter extends Component {
  static propTypes = {
    unit: PropTypes.object.isRequired,
    listing: PropTypes.object.isRequired,
    listingTab: PropTypes.string,
    reload: PropTypes.func.isRequired
  };

  render() {
    switch (this.props.unit.unit_type) {
      case "ciirus":
        return <CiirusListing {...this.props} />;
      default:
        throw new Error("invalid unit type");
    }
  }
}
