import React from "react";
import { connect } from "react-redux";
import { setEnd } from "redux/EOQReport/actions";

import { MyCalWrapper } from "components/MyCal";
import moment from "moment";

const EndSelect = ({ end, setEnd }) => (
  <MyCalWrapper
    format="YYYY-MM-DD"
    value={moment(end + " 00:00:01")}
    onChange={(undefined, { value }) => setEnd(value)}
    type="date"
  />
);

const mapStateToProps = state => ({
  end: state.EOQReport.end
});

const mapDispatchToProps = {
  setEnd
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EndSelect);
