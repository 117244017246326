import React from "react";
import { Input } from "semantic-ui-react";
import { isValidFinance } from "utils/numbers";
/**
 *	@prop onChange
 *	@prop value
 */
export default class MoneyInput extends React.Component {
  state = {
    value: "0.00"
  };
  componentWillReceiveProps({ value }) {
    this.setState({ value });
  }
  handleChange = (e, { value }) => {
    if (isValidFinance(value)) {
      this.setState({ value }, this.triggerChange);
    }
  };
  triggerChange = () => {
    if (typeof this.props.onChange === "function") {
      this.props.onChange(this.state.value);
    }
  };
  handleBlur = () => {
    this.setState(({ value }) => {
      const float = parseFloat(value);
      return {
        value: isNaN(float) ? "0.00" : float.toFixed(2)
      };
    }, this.triggerChange);
  };
  render() {
    const { onChange, value, ...rest } = this.props;
    return (
      <Input
        onChange={this.handleChange}
        onBlur={this.handleBlur}
        value={this.state.value}
        {...rest}
      />
    );
  }
}
