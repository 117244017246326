import React from "react";
import { Link } from "react-router-dom";

import { Header, Table, Divider, Icon } from "semantic-ui-react";

import AjaxComponent from "components/AjaxComponent";
import { formatMJY } from "utils/dates";
import { addCommas } from "utils/numbers";
import props from "../props.js";

const GuestBookings = props => {
  const { data } = props.guestData;

  const config = {
    ajaxConfig: {
      url: "/apis/portal/guests",
      data: {
        action: "bookings",
        data: {
          ID: data.id
        }
      }
    }
  };

  return (
    <>
      <Header
        as="h1"
        dividing
        icon="address book outline"
        content={data.firstname + " " + data.lastname + " / Bookings"}
        subheader="View bookings for the guest"
      />
      You can view all of the guest's bookings below.
      <ul>
        <li>Click the "check in" column to go to the booking's page.</li>
        <li>Click the "unit" column to go to the booking's unit.</li>
      </ul>
      <AjaxComponent {...config}>
        {({ data }) => {
          const bookings = data.bookings.sort(
            (a, b) => new Date(b.check_in) - new Date(a.check_in)
          );

          return (
            <Table>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell content="Check-in" />
                  <Table.HeaderCell content="Unit" />
                  <Table.HeaderCell content="Points" />
                  <Table.HeaderCell content="Cancelled" />
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {bookings.length ? (
                  bookings.map(booking => {
                    const cancelled = !!booking.cancellations.find(
                      row => row.active
                    );

                    return (
                      <Table.Row key={booking.id}>
                        <Table.Cell selectable>
                          <Link to={`/browse/bookings/${booking.id}`}>
                            {formatMJY(booking.check_in)}
                          </Link>
                        </Table.Cell>
                        <Table.Cell selectable>
                          <Link to={`/browse/units/${booking.unit.id}`}>
                            {booking.unit.resort.resort_company.name +
                              " " +
                              booking.unit.resort.name +
                              " " +
                              booking.unit.name}
                          </Link>
                        </Table.Cell>
                        <Table.Cell>
                          {addCommas(
                            booking.point_usages.reduce(
                              (a, c) => a + c.amount,
                              0
                            )
                          )}
                        </Table.Cell>
                        {cancelled ? (
                          <Table.Cell negative>
                            <Icon name="cancel" />
                            Yes
                          </Table.Cell>
                        ) : (
                          <Table.Cell>No</Table.Cell>
                        )}
                      </Table.Row>
                    );
                  })
                ) : (
                  <Table.Row>
                    <Table.Cell colSpan="6">
                      <div style={{ padding: "1rem" }}>
                        <p>There are no results to show.</p>
                      </div>
                    </Table.Cell>
                  </Table.Row>
                )}
              </Table.Body>
            </Table>
          );
        }}
      </AjaxComponent>
    </>
  );
};

GuestBookings.propTypes = { ...props };

export default GuestBookings;
