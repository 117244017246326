import React, { Component } from "react";
import { Dropdown } from "semantic-ui-react";
import months from "fixtures/months";
import propTypes from "prop-types";
import { InstanceTypes } from "../../../../../../types";
/**
 * @prop month
 * @prop setMonth
 * @prop year 
 * @prop setYear
//  * @prop config
 */
export default class Month extends Component {
	static propTypes = {
		month: propTypes.string.isRequired,
		setMonth: propTypes.func.isRequired,
		year: propTypes.string.isRequired,
		setYear: propTypes.func.isRequired
		// config: InstanceTypes.config
	};
	getMonthOptions = () => {
		return months.map(month => {
			return {
				...month,
				value: month.month
			};
		});
	};
	render() {
		const { month, setMonth } = this.props;
		return (
			<Dropdown
				fluid
				selection
				value={month}
				options={this.getMonthOptions()}
				onChange={(e, { value }) => setMonth(value)}
				// onWheel={this.handleScroll}
			/>
		);
	}
}
