import AjaxFn from "services/AjaxFn";
import { loadBookings } from "./Bookings";
import { loadBanks } from "./Banks";

export const setStart = newStart => (dispatch, getState) => {
  const { end } = getState().EOQReport;
  if (newStart > end) {
    dispatch({ type: "EOQ_SET_END", data: newStart });
    dispatch({ type: "EOQ_SET_START", data: newStart });
  } else {
    dispatch({ type: "EOQ_SET_START", data: newStart });
  }
};

export const setEnd = newEnd => (dispatch, getState) => {
  const { start } = getState().EOQReport;
  if (newEnd < start) {
    dispatch({ type: "EOQ_SET_START", data: newEnd });
    dispatch({ type: "EOQ_SET_END", data: newEnd });
  } else {
    dispatch({ type: "EOQ_SET_END", data: newEnd });
  }
};

export const setAuditedOnly = data => ({ type: "EOQ_SET_AUDITED", data });

export const clearQueue = () => ({ type: "EOQ_QUEUE_CLEAR" });

export const addToQueue = data => ({ type: "EOQ_QUEUE_ADD", data });

export const removeFromQueue = data => ({ type: "EOQ_QUEUE_REMOVE", data });

export const setSaving = () => ({ type: "EOQ_SAVING" });

export const doneSaving = () => ({ type: "EOQ_DONE_SAVING" });

export const clearCreated = () => dispatch => {
  dispatch({ type: "EOQ_CLEAR_CREATED" });
  dispatch(loadBookings());
  dispatch(loadBanks());
};

export const saveSuccess = data => ({ type: "EOQ_SAVE_SUCCESS", data });

export const save = () => (dispatch, getState) => {
  const {
    queue,
    bookings: {
      data: { bookings }
    },
    bank,
    startingCheckNumber: cnum
  } = getState().EOQReport;

  dispatch(setSaving());

  const checks = queue.map(id => {
    const original = bookings.find(row => row.id === id);
    return { id, amount: original.calculated.owner_pay };
  });

  AjaxFn({
    url: "/apis/portal/checks",
    data: {
      action: "eoq_save",
      data: {
        checks: JSON.stringify(checks),
        cnum,
        bank
      }
    },
    success: message => dispatch(saveSuccess(message)),
    failure: e => window.toastr.error(e),
    finally: () => dispatch(doneSaving())
  });
};

export {
  setBanksLoading,
  setBanksError,
  setBanksData,
  loadBanks,
  setBank,
  setStartingCheckNumber
} from "./Banks";

export {
  setBookingsLoading,
  setBookingsError,
  setBookingsData,
  loadBookings
} from "./Bookings";

export {
  processData,
  setSearch,
  setSearchedData,
  setFilteredData,
  setOrderedData,
  setPerPage,
  setPage,
  setPaginatedData
} from "./Table";
