import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

const Highlight = ({ content, children, floatRight }) => {
  return <Wrapper floatRight={floatRight}>{content || children}</Wrapper>;
};

Highlight.propTypes = {
  floatRight: PropTypes.bool
};
Highlight.defaultProps = {
  floatRight: false
};

const Wrapper = styled.span`
  font-weight: bold;
  background: #eee;
  padding: 0.125rem;
  margin: -0.125rem 0;
  ${props => (props.floatRight ? "float: right;" : null)}
  ${props => (props.floatRight ? "clear: both;" : null)}
`;

export default Highlight;
