import React from "react";

import {
  calculate_financed,
  calculate_interest,
  calculate_payments
} from "utils/finance";
import { wait } from "utils/async";

const calculate = (data = {}) => {
  const {
    agreement_term,
    base_amount,
    down_payment,
    interest_rate,
    loan_start_date
  } = data;

  let results = {};

  // Determin the clients financed amount
  const financed_amount = calculate_financed(base_amount, down_payment);
  results.financed_amount = financed_amount;

  // ...
  const interest = calculate_interest(
    financed_amount,
    agreement_term,
    interest_rate
  );

  const monthly_payment = interest.monthly_payment;
  const total_payment = interest.total_payment;
  const finance_charge = interest.finance_charge;
  const total_sale = Number(total_payment) + Number(down_payment);

  results.monthly_payment = monthly_payment;
  results.total_payment = total_payment;
  results.finance_charge = finance_charge;
  results.total_sale = total_sale;

  // ...
  const payments = calculate_payments(
    financed_amount,
    agreement_term,
    interest_rate,
    loan_start_date
  );
  results.payments = payments;

  return results;
};

export default calculate;
