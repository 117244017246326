import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import styled from "styled-components";
import { set } from "redux/Storage/actions";

import { danger } from "theme/colors";

const DeleteButton = styled.div`
  position: absolute;
  display: inline-block !important;
  right: 0;
  text-align: center;
  width: 20px !important;
  height: 20px !important;
  background: ${danger} !important;
  color: #fff !important;
  border-radius: 50%;
  opacity: 0.8;
  &:hover {
    cursor: pointer;
    opacity: 1;
  }
`;

const Resources = props => {
  const { index, set, favoritePages } = props;

  const handleClick = () => {
    set(
      "favoritePages",
      favoritePages.filter((undefined, key) => key !== index)
    );
  };

  return (
    <DeleteButton title="Remove" onClick={handleClick}>
      <i className="fas fa-times" />
    </DeleteButton>
  );
};

Resources.propTypes = {
  favoritePages: PropTypes.array.isRequired,
  set: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  favoritePages: state.Storage.favoritePages
  // ...
});

const mapDispatchToProps = {
  set
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Resources);
