import React from "react";
import { Message, Icon } from "semantic-ui-react";

export default ({ data }) => (
  <Message warning>
    <Message.Header>
      <Icon name={"exclamation triangle"} /> Warning
    </Message.Header>
    <br />
    The <b>"Guest Advance Confirmation"</b> can only be sent through CiiRUS.
    Clicking send only marks it as sent.
  </Message>
);
