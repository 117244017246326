import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { Container, Header, Icon } from "semantic-ui-react";

import Layout from "components/Layout";
import ContextBar from "components/ContextBar";

const NotFound = ({ crabmode }) => (
  <Layout noBar>
    <ContextBar />
    <Container>
      {crabmode ? (
        <p
          style={{
            lineHeight: "7rem",
            fontSize: "5rem",
            textAlign: "center",
            marginTop: "20%"
          }}
          children="🦀PAGE IS GONE🦀"
        />
      ) : (
        <>
          <br />
          <Header dividing as={"h1"}>
            <Icon name={"search"} />
            Page not found
          </Header>
          <p>The page you are looking for does not exist.</p>
        </>
      )}
    </Container>
  </Layout>
);

NotFound.propTypes = {
  crabmode: PropTypes.bool.isRequired
};

const mapStateToProps = state => ({
  crabmode: state.Storage.crabmode
});

export default connect(mapStateToProps)(NotFound);
