export const cardFields = [
  "card_number",
  "card_cvc",
  "card_exp_date",
  "card_exp_year"
];
export const checkFields = [
  "check_bank_addr",
  "check_account_num",
  "check_routing_num",
  "check_num"
];
export const billingFields = [
  "full_name_on_account",
  "authorize_payment",
  "payment_method",
  "billing_address",
  "billing_city",
  "billing_address_2",
  "billing_state",
  "billing_zip"
];
export const externalOnlyFields = [
  ...cardFields,
  ...checkFields,
  ...billingFields,
  "initial",
  "signature",
  "signatures"
];
