import React from "react";
import styled from "styled-components";

import Timepunch from "./Timepunch";
import UserProfile from "./UserProfile";
import AdditionalOptions from "./AdditionalOptions";
import Resources from "./Resources";
import Favorites from "./Favorites";

const UserActions = () => {
  return (
    <StyledUserActions>
      <Favorites />
      <Resources />
      <Timepunch />
      <UserProfile />
      <AdditionalOptions />
    </StyledUserActions>
  );
};

const StyledUserActions = styled.div`
  justify-self: flex-end;

  display: grid;
  grid-auto-flow: column;
  grid-column-gap: 0.25rem;
  align-items: center;

  margin-right: 0.5rem;
`;

export default UserActions;
