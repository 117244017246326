export default {
  // ..
  columns: {
    paddingBottom: "0px !important"
  },
  month: {
    cursor: "default !important",
    width: "100%",
    border: "1px solid #ccc",
    marginBottom: ".5rem"
  },
  monthNameContainer: {
    borderBottom: "none"
  },
  monthName: {
    marginBottom: "0px",
    padding: ".5rem",
    fontWeight: "bold"
  },
  monthDayHeader: {
    borderBottom: "1px solid #ccc",
    borderTop: "none"
  },
  monthDayHeaderDay: {
    textAlign: "center",
    paddingBottom: ".25rem !important",
    paddingTop: ".25rem !important",
    borderTop: "none !important"
  },
  week: {},
  noDay: {
    background: "#eee",
    userSelect: "none !important"
  },
  day: {
    cursor: "pointer !important",
    paddingTop: ".5rem !important",
    paddingBottom: ".5rem !important",
    borderRight: "1px solid #eee",
    userSelect: "none !important",
    "&:last-of-type": {
      borderRight: "none"
    },
    "&:hover": {
      fontWeight: "bold"
    }
  },
  oldDay: {
    background: "#f2f2f2",
    userSelect: "none !important",
    cursor: "default !important",
    "&:hover": {
      fontWeight: "normal !important"
    }
  },
  dayInRange: {
    background: "#ccc"
  },
  dayBlackout: {
    background: "#888",
    color: "#fff"
  },
  dayBooking: {
    background: "#2185d0",
    color: "#fff"
  },
  dayBookingBlackout: {
    background: "linear-gradient(to bottom right, #2185d0 50%, #888 50%)",
    color: "#fff"
  },
  pendingChange: {
    // ...
  },
  undoneChange: {
    textDecoration: "line-through"
  }
};
