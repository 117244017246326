import React, { useState } from "react";
import { Redirect } from "react-router-dom";

import { Button, Form, Segment } from "semantic-ui-react";

import AjaxComponent from "components/AjaxComponent";
import Layout from "components/Layout";
import FocusBar from "components/FocusBar";
import AgreementListFocusBar from "components/AgreementListFocusBar";
import Breadcrumb from "./components/Breadcrumb";
import Preview from "./components/Preview";
import VerifiedInput from "./components/VerifiedInput";
import naturalSort from "utils/naturalSort";
import AjaxFn from "services/AjaxFn";

/**
 * callback to process options(data) received from the server
 * @param {Array} options
 */
function processOptions(options) {
  return options
    .filter(row => row.active)
    .map(row => ({
      value: row.id,
      text: row.name
    }))
    .sort((a, b) => naturalSort(a.text, b.text));
}

const ajaxConfig = {
  ajaxConfig: {
    url: "/apis/portal/agreements?action=get_types"
  },
  stateful: false
};

const AgreementWizard = props => {
  const [state, setState] = useState({
    template: "",
    recipient: "",
    email: "",
    loading: false,
    redirect: false
  });

  const handleSubmit = e => {
    e.preventDefault();
    setState({ ...state, loading: true });
    AjaxFn({
      url: "/apis/portal/agreements",
      data: {
        action: "start_by_email",
        data: {
          email: state.email,
          recipient: state.recipient,
          template_id: state.template
        }
      },
      success: message => {
        window.toastr.success();
        setTimeout(() => {
          setState({ ...state, redirect: message.ID });
        }, 500);
      },
      failure: error => {
        window.toastr.error(error);
        setState({ ...state, loading: false });
      }
    });
  };

  /**
   * Whether or not you can click save rn
   */
  const canSave = () => {
    if (state.loading) return false;
    if (!state.recipient) return false;
    if (!state.email) return false;
    if (!state.template) return false;
    return true;
  };

  if (state.redirect) {
    return <Redirect push to={"/browse/agreements/" + state.redirect} />;
  }

  return (
    <AjaxComponent {...ajaxConfig}>
      {({ data, loading, error }) => (
        <Layout>
          <AgreementListFocusBar
            page="wizard"
            loading={loading}
            error={error}
            expandable={false}
            expanded={true}
          >
            <FocusBar.Generic>
              <Segment inverted>
                <Form inverted onSubmit={handleSubmit}>
                  <VerifiedInput
                    placeholder="Bob Smith"
                    label="Client Name"
                    field="recipient"
                    value={state.recipient}
                    setValue={recipient => setState({ ...state, recipient })}
                    disabled={state.loading}
                  />
                  <VerifiedInput
                    placeholder="bob@gmail.com"
                    label="Client Email"
                    field="email"
                    value={state.email}
                    setValue={email => setState({ ...state, email })}
                    disabled={state.loading}
                  />
                  {data && (
                    <Form.Dropdown
                      label="Agreement"
                      value={state.template}
                      onChange={(undefined, { value: template }) =>
                        setState({ ...state, template })
                      }
                      disabled={state.loading}
                      options={processOptions(data)}
                      placeholder="Choose one..."
                      selectOnBlur={false}
                      clearable
                      selection
                      search
                    />
                  )}
                  <div style={{ textAlign: "right" }}>
                    <Button
                      primary
                      type="submit"
                      content="Continue"
                      disabled={!canSave()}
                      loading={state.loading}
                    />
                  </div>
                </Form>
              </Segment>
            </FocusBar.Generic>
          </AgreementListFocusBar>
          <Breadcrumb />
          <Preview templateID={state.template} />
        </Layout>
      )}
    </AjaxComponent>
  );
};

export default AgreementWizard;
