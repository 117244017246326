import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { setAccount, setTimepunch } from "redux/TimepunchManager/actions";

import { Icon, Table, Button, Modal, Segment } from "semantic-ui-react";
import moment from "moment";
import "moment-duration-format";

import { ClickableRow, DeletedRow } from "../../../StyledComponents";
import ClockOutButton from "./components/ClockOutButton";
import ShowDeletedToggle from "./components/ShowDeletedToggle";

export class RowView extends Component {
  static propTypes = {
    timepunches: PropTypes.array.isRequired,
    setAccount: PropTypes.func.isRequired,
    locked: PropTypes.bool.isRequired,
    setLocked: PropTypes.func.isRequired,
    account: PropTypes.object.isRequired,
    show_deleted: PropTypes.bool.isRequired
  };

  /**
   *	Used to calculate the elapsed time for clocked in users
   */
  currentTime = moment();

  /**
   * 	Generates the 'elapsed time' cell
   *	@param {Moment} start
   *	@param {Moment|null} end
   *  @return {ReactElement}
   */
  calculateDifference = (start, end) => {
    return moment
      .duration(end.diff(start))
      .format("h[h] m[m] s[s]", { trim: false });
  };

  render() {
    const {
      timepunches,
      setTimepunch,
      setAccount,
      locked,
      setLocked,
      account,
      show_deleted
    } = this.props;

    const deletedPunches = timepunches.filter(row => row.deleted);

    return (
      <Fragment>
        <Modal.Content>
          <p>
            Timepunches for the selected account are displayed below. You can
            click on each row for more details. If a punch has been striked out,
            it is deleted and will not count towards a users time for the week.
          </p>
          <Table basic="very">
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell width="4">Punch in</Table.HeaderCell>
                <Table.HeaderCell width="4">Punch out</Table.HeaderCell>
                <Table.HeaderCell width="4">Total</Table.HeaderCell>
                <Table.HeaderCell width="4">Modified</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {timepunches
                .filter(row => (show_deleted ? true : !row.deleted))
                .sort((a, b) => new Date(a.in) - new Date(b.in))
                .map((punch, index) => {
                  let inMoment = moment(punch.in);
                  let outMoment = punch.out
                    ? moment(punch.out)
                    : this.currentTime;
                  const tableCells = (
                    <Fragment>
                      <Table.Cell>
                        {inMoment.format("ddd, h:mm:ss A ")}
                      </Table.Cell>
                      {punch.out ? (
                        <Table.Cell>
                          {outMoment.format("ddd, h:mm:ss A ")}
                        </Table.Cell>
                      ) : (
                        <Table.Cell warning>Clocked in</Table.Cell>
                      )}
                      <Table.Cell>
                        {this.calculateDifference(inMoment, outMoment)}
                      </Table.Cell>
                      {punch.edits.length ? (
                        <Table.Cell warning>
                          <Icon name="exclamation triangle" />
                          Yes
                        </Table.Cell>
                      ) : (
                        <Table.Cell />
                      )}
                    </Fragment>
                  );
                  return punch.deleted ? (
                    <DeletedRow
                      key={index}
                      onClick={() => setTimepunch(punch.id)}
                      children={tableCells}
                    />
                  ) : (
                    <ClickableRow
                      key={index}
                      onClick={() => setTimepunch(punch.id)}
                      children={tableCells}
                    />
                  );
                })}
            </Table.Body>
          </Table>
          {deletedPunches.length > 0 && (
            <Segment secondary>
              <p>
                {deletedPunches.length > 1
                  ? `There are ${deletedPunches.length} deleted punches.`
                  : "There is 1 deleted punch."}
              </p>
              <ShowDeletedToggle />
            </Segment>
          )}
        </Modal.Content>
        <Modal.Actions>
          <Button
            content="Close"
            onClick={() => setAccount(false)}
            disabled={locked}
          />
          {account.clocked_in && (
            <ClockOutButton
              locked={locked}
              setLocked={setLocked}
              account={account}
            />
          )}
        </Modal.Actions>
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  show_deleted: state.TimepunchManager.show_deleted
  // ...
});

const mapDispatchToProps = {
  setAccount,
  setTimepunch
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RowView);
