import React from "react";
import { Container, Header } from "semantic-ui-react";
import Layout from "components/Layout";
import PortalTable from "components/PortalTable";
import CompletionModal from "./components/CompletionModal";
import List from "./components/List";
import injectSheet from "react-jss";
import { connect } from "react-redux";
import Breadcrumb from "./components/Breadcrumb";
const styles = {
  container: {
    paddingBottom: "4rem !important"
    // width: "1600px !important"
  },
  header: {
    marginTop: "2rem !important"
  }
};
class Units extends React.Component {
  state = {
    viewingCompletionHelp: false
  };
  viewCompletionHelp = state => this.setState({ viewingCompletionHelp: state });
  config = () => {
    const namespace = "units";
    return List({
      functions: {
        viewCompletion: () => this.viewCompletionHelp(true)
      },
      namespace
    });
  };
  /**
   * 	Render fn
   */
  render() {
    const { classes } = this.props;
    return (
      <Layout noBar>
        <Breadcrumb />
        <Container className={classes.container}>
          <Header as={"h1"} dividing className={classes.header}>
            Units
            <div className={"sub header"}>
              Below are all of the units registered in the database.
            </div>
          </Header>
          <PortalTable {...this.config()} />
          <br />
          <CompletionModal
            open={this.state.viewingCompletionHelp}
            close={() => this.viewCompletionHelp(false)}
          />
        </Container>
      </Layout>
    );
  }
}
const styled = injectSheet(styles)(Units);
const mapStateToProps = () => ({});
export default connect(mapStateToProps)(styled);
