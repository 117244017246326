import React, { Component, Fragment } from "react";
import propTypes from "prop-types";
import Cancellations from "./components/Cancellations";
import Timer from "./components/Timer";
import Check from "./components/Check";
import Audited from "./components/Audited";

export default class BookingStatusMessages extends Component {
  static propTypes = {
    data: propTypes.object,
    reload: propTypes.func
  };
  render() {
    const { data, reload } = this.props;
    return (
      <div style={{ marginBottom: "1rem" }}>
        {data.check.id ? (
          <Check id={data.check.id} />
        ) : data._actions.state.booking_audited ? (
          <Audited />
        ) : (
          <Fragment>
            <Timer data={data} />
            <Cancellations data={data} reload={reload} />
          </Fragment>
        )}
      </div>
    );
  }
}
