import React from "react";
import { toast, Slide, Bounce } from "react-toastify";
import { Header, Divider } from "semantic-ui-react";

const successToast = (message, header = "Done") => {
  toast.success(
    <Message message={message} header={header} icon="check circle" />,
    defaultProps
  );
};

const errorToast = (message, header = "Something's wrong") => {
  toast.error(
    <Message 
      message={typeof message === 'object' ? JSON.stringify(message) : message} 
      header={header} 
      icon="shield alternate" 
    />,
    errorProps
  );
};

const warningToast = (message, header = "Warning") => {
  toast.warn(
    <Message message={message} header={header} icon="exclamation triangle" />,
    defaultProps
  );
};

const infoToast = (message, header = "Heads up") => {
  toast.info(
    <Message message={message} header={header} icon="info circle" />,
    defaultProps
  );
};

// legacy support
window.toastr = {
  success: successToast,
  error: errorToast,
  warning: warningToast,
  info: infoToast
};

const Message = ({ message, header, icon = undefined }) => (
  <div style={{ padding: ".5rem" }}>
    {header && (
      <Header
        inverted
        content={header}
        style={{ marginBottom: ".25rem" }}
        icon={icon}
      />
    )}
    <p>{message}</p>
  </div>
);

const defaultProps = {
  position: "top-right",
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  transition: Slide
};

const errorProps = {
  position: "bottom-center",
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  transition: Bounce
};
