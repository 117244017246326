import React, { Fragment } from "react";
import { Header, Segment, Icon } from "semantic-ui-react";
import Comments from "components/Comments";

export default ({ data }) => (
  <Fragment>
    <br />
    <Segment>
      <Header as="h2">
        <Header.Content>
          <Icon name="comment" circular />
          Booking Comments
          <Header.Subheader>
            Leave comments attached to this booking
          </Header.Subheader>
        </Header.Content>
      </Header>
      <Comments thread={data.thread} maxHeight={"9999px"} />
    </Segment>
  </Fragment>
);
