export default {
  guests: {
    data: [],
    loading: false,
    error: false
  },
  // members: {
  //   data: [],
  //   loading: false,
  //   error: false
  // },
  units: {
    data: [],
    loading: false,
    error: false
  }
};
