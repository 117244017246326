import React from "react";
import {
  Modal,
  Button,
  Table,
  Segment,
  Form,
  Divider,
  Input
} from "semantic-ui-react";
import AjaxFn from "services/AjaxFn";
import { formatMJY } from "utils/dates";
import MoneyInput from "components/MoneyInput";
/**
 *	@prop booking
 *	@prop reload
 */
export default class Refunds extends React.Component {
  state = {
    open: false,
    loading: false,
    value: "0.00",
    reason: ""
  };
  delete = id => {
    if (window.confirm("Are you sure you want to delete this guest refund?")) {
      this.setState({ loading: true });
      AjaxFn({
        url: "/apis/portal/bookingfinances",
        data: {
          action: "delete",
          data: {
            ID: id,
            reason: "Deleted guest refund"
          }
        },
        success: message => {
          this.props.reload();
        },
        failure: error => {
          window.toastr.error(error);
          this.setState({ loading: false });
        }
      });
    }
  };
  save = () => {
    this.setState({ loading: true });
    AjaxFn({
      url: "/apis/portal/bookingfinances",
      data: {
        action: "create",
        data: {
          booking_id: this.props.booking.id,
          reason: this.state.reason,
          amount: this.state.value,
          type: "refund"
        }
      },
      success: message => {
        this.props.reload();
      },
      failure: error => {
        window.toastr.error(error);
        this.setState({ loading: false });
      }
    });
  };
  canSave = () => {
    if (this.state.loading) {
      return true;
    }
    if (this.state.value === "0.00" || this.state.reason.trim() === "") {
      return true;
    }
    return false;
  };

  render() {
    const refunds = this.props.booking.finances
      .filter(({ type }) => type === "refund")
      .sort((a, b) => new Date(b.created_on) - new Date(a.created_on));
    const trigger = (
      <div onClick={() => this.setState({ open: true })}>
        {this.props.children}
      </div>
    );
    return (
      <Modal open={this.state.open} trigger={trigger}>
        <Modal.Header>Refunds</Modal.Header>
        {this.state.loading ? (
          <Modal.Content>
            <Segment
              loading
              style={{ paddingTop: "8rem", paddingBottom: "8rem" }}
            />
          </Modal.Content>
        ) : (
          <Modal.Content>
            {refunds.length ? (
              <div style={{ maxHeight: "300px", overflow: "auto" }}>
                <Table striped celled>
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell>Amount</Table.HeaderCell>
                      <Table.HeaderCell>Created By</Table.HeaderCell>
                      <Table.HeaderCell>Reason Created</Table.HeaderCell>
                      <Table.HeaderCell />
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    {refunds.map((refund, key) => (
                      <Table.Row
                        key={key}
                        style={
                          refund.deleted
                            ? { textDecoration: "line-through" }
                            : undefined
                        }
                      >
                        <React.Fragment>
                          <Table.Cell>${refund.amount}</Table.Cell>
                          <Table.Cell>
                            <strong>{`${refund.created_by.firstname} ${
                              refund.created_by.lastname[0]
                            }.`}</strong>
                            <br />
                            {formatMJY(refund.created_on)}
                          </Table.Cell>
                          <Table.Cell>{refund.reason}</Table.Cell>
                          <Table.Cell>
                            {!refund.deleted && (
                              <span
                                style={{ color: "red", cursor: "pointer" }}
                                onClick={() => this.delete(refund.id)}
                                children={"Delete"}
                              />
                            )}
                          </Table.Cell>
                        </React.Fragment>
                      </Table.Row>
                    ))}
                  </Table.Body>
                </Table>
              </div>
            ) : (
              <p>There are no guest refunds associated with this booking.</p>
            )}
            {this.props.showIfEditable(
              <React.Fragment>
                <br />
                <Divider />
                <Form>
                  <Form.Group>
                    <Form.Field width={4}>
                      <label>New Guest Refund</label>
                      <MoneyInput
                        label={"$"}
                        placeholder={"0.00"}
                        value={this.state.value}
                        onChange={value => this.setState({ value })}
                      />
                    </Form.Field>
                    <Form.Field width={12}>
                      <label>Details</label>
                      <Input
                        placeholder={"Where was it refunded?"}
                        value={this.state.reason}
                        onChange={(e, { value }) =>
                          this.setState({ reason: value })
                        }
                      />
                    </Form.Field>
                  </Form.Group>
                  <Form.Field>
                    <Button
                      onClick={this.save}
                      color={"blue"}
                      loading={this.state.loading}
                      disabled={this.canSave()}
                      content={"Add Refund"}
                      icon={"plus"}
                    />
                  </Form.Field>
                </Form>
              </React.Fragment>
            )}
          </Modal.Content>
        )}
        <Modal.Actions>
          <Button onClick={() => this.setState({ open: false })}>Close</Button>
        </Modal.Actions>
      </Modal>
    );
  }
}
