import React from "react";
import { Button } from "semantic-ui-react";
import { Link } from "react-router-dom";

import "./style.css";

/**
 *	@prop classes
 */
export default class Header extends React.Component {
  print() {
    const msg =
      "Be sure you set 'margins: default', 'scale: 95' before " +
      "printing the invoices, or they may not appear correctly.";
    if (window.confirm(msg)) {
      window.print();
    }
  }
  render() {
    const { classes } = this.props;
    return (
      <div className={classes.warning}>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div>
            {window.history.length > 1 && (
              <span style={{ marginRight: ".25rem" }}>
                <Button basic onClick={() => window.history.back()}>
                  Back
                </Button>
              </span>
            )}
            <span style={{ marginRight: ".25rem" }}>
              <Button
                basic
                as={Link}
                to="/browse/checks"
                content="Checks List"
              />
            </span>
          </div>
          <Button
            color="blue"
            onClick={this.print}
            icon="print"
            content="Print"
            primary
            basic
          />
        </div>
      </div>
    );
  }
}
