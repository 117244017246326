import React from "react";
import { Container, Header, Icon, Segment } from "semantic-ui-react";
import { Redirect } from "react-router-dom";
import Layout from "components/Layout";
import Breadcrumb from "./components/Breadcrumb";
import WithState from "components/WithState";
import AjaxFn from "services/AjaxFn";
import Steps from "./components/Steps";
import GuestStep from "./components/GuestStep";
import UnitStep from "./components/UnitStep";
// import OwnerStep from "./components/OwnerStep";
import DetailsStep from "./components/DetailsStep";
import bookingSources from "fixtures/booking_sources";
import moment from "moment";
const defaultData = {
  step: "guest",
  data: {
    guest: "",
    unit: "",
    subUnit: "",
    check_in: moment(),
    check_out: moment().add(7, "day"),
    rco: "",
    booking_source: "airbnb",
    payment_source: "booking source"
    // -- test data --------------
    // guest: 47856,
    // unit: 8995,
    // subUnit: 20939,
    // check_in: "2018-08-26",
    // check_out: "2018-08-31",
    // rco: "",
    // booking_source: "airbnb",
    // payment_source: "booking source"
  }
};
export default class BookingWizard extends React.Component {
  state = {
    redirecting: false,
    loading: false,
    ...defaultData
  };
  setStep = step => this.setState({ step });
  getStep = () => {
    let element = null;
    switch (this.state.step) {
      case "guest":
        element = <GuestStep />;
        break;
      case "unit":
        element = <UnitStep />;
        break;
      case "details":
        element = <DetailsStep />;
        break;
      default:
        element = <GuestStep />;
    }
    return React.cloneElement(element, {
      data: this.state.data,
      setData: this.setData,
      resetData: this.resetData,
      setStep: this.setStep,
      setBaseUnit: this.setBaseUnit,
      save: this.save,
      setBookingSource: this.setBookingSource
    });
  };
  setData = (key, value) => {
    this.setState({
      data: {
        ...this.state.data,
        [key]: value
      }
    });
  };
  setBaseUnit = unitID => {
    this.setState({
      data: {
        ...this.state.data,
        unit: unitID,
        subUnit: false
      }
    });
  };
  setBookingSource = source => {
    this.setState({
      data: {
        ...this.state.data,
        booking_source: source,
        payment_source: bookingSources.find(row => row.value === source).source
      }
    });
  };
  resetData = () => {
    if (window.confirm("Are you sure? All changes will be undone.")) {
      this.setState({
        ...defaultData
      });
    }
  };
  save = () => {
    this.setState({ loading: true });
    AjaxFn({
      url: "/apis/portal/bookings",
      data: {
        action: "wizard",
        data: {
          guest: this.state.data.guest,
          // point_set: this.state.data.point_set,
          // amount: this.state.data.amount,
          unit: this.state.data.unit,
          subUnit: this.state.data.subUnit,
          check_in: this.state.data.check_in.format("YYYY-MM-DD"),
          check_out: this.state.data.check_out.format("YYYY-MM-DD"),
          booking_source: this.state.data.booking_source,
          payment_source: this.state.data.payment_source,
          rco: this.state.data.rco
        }
      },
      success: ({ id }) => {
        this.setState({ redirecting: id });
      },
      failure: error => {
        window.toastr.error(error);
        this.setState({ loading: false });
      }
    });
  };
  render() {
    if (this.state.redirecting) {
      return (
        <Redirect push to={`/browse/bookings/${this.state.redirecting}`} />
      );
    }
    return (
      <Layout noBar>
        <Breadcrumb />
        <br />
        <br />
        <Container>
          <Header as="h1" dividing>
            <Icon name="wizard" />
            <Header.Content>Booking Wizard</Header.Content>
          </Header>
          <WithState loading={this.state.loading} error={false}>
            <div style={{ display: "flex" }}>
              <Steps
                step={this.state.step}
                setStep={this.setStep}
                data={this.state.data}
              />
              <div style={{ marginLeft: "1.5rem", flexGrow: "1" }}>
                <Segment>{this.getStep()}</Segment>
              </div>
            </div>
          </WithState>
        </Container>
        <br />
        <br />
      </Layout>
    );
  }
}
