import React from "react";
import { Form, Input, Button } from "semantic-ui-react";
/**
 *	@prop data
 *	@prop setData
 *  @prop switchMode
 */
export default class MemberForm extends React.Component {
  createInput = (element, field, props) => {
    return React.cloneElement(element, {
      onChange: (e, { value }) => this.props.setData(field, value),
      value: this.props.data[field],
      ...props
    });
  };
  render() {
    return (
      <React.Fragment>
        <Form>
          <Form.Group widths={"equal"}>
            <Form.Field>
              <label>First name</label>
              {this.createInput(<Input fluid />, "firstname", {
                placeholder: "First Name"
              })}
            </Form.Field>
            <Form.Field>
              <label>Last Name</label>
              {this.createInput(<Input fluid />, "lastname", {
                placeholder: "Last Name"
              })}
            </Form.Field>
          </Form.Group>
          <Form.Group widths={"equal"}>
            <Form.Field>
              <label>Email</label>
              {this.createInput(<Input fluid />, "email", {
                placeholder: "Email Address"
              })}
            </Form.Field>
            <Form.Field>
              <label>Phone</label>
              {this.createInput(<Input fluid />, "phone", {
                placeholder: "Phone Number"
              })}
            </Form.Field>
          </Form.Group>
        </Form>
        <Button
          icon={"user"}
          content={"Use Existing Member"}
          onClick={this.props.switchMode}
        />
      </React.Fragment>
    );
  }
}
