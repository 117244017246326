import React, { Component } from "react";
import { Container } from "semantic-ui-react";
import AjaxComponent from "components/AjaxComponent";
import WithState from "components/WithState";
import Layout from "components/Layout";
import Breadcrumb from "./components/Breadcrumb";
import Editor from "./components/Editor";
export default class AgreementTemplatePage extends Component {
  reload = () => {
    if (this.Ref) {
      this.Ref.reload();
    }
  };
  render() {
    const config = {
      ajaxConfig: {
        url: "/apis/portal/agreementtemplates",
        data: {
          action: "read_one",
          data: {
            ID: this.props.match.params.id
          }
        }
      },
      stateful: false,
      ref: ref => (this.Ref = ref)
    };
    return (
      <AjaxComponent {...config}>
        {({ data, loading, error }) => {
          return (
            <Layout noBar>
              <Breadcrumb />
              <Container>
                <br />
                <WithState loading={loading} error={error}>
                  <Editor data={data} reload={this.reload} />
                </WithState>
              </Container>
            </Layout>
          );
        }}
      </AjaxComponent>
    );
  }
}
