import React, { Component } from "react";
import PropTypes from "prop-types";

import AjaxComponent from "components/AjaxComponent";

export default class ResortLoader extends Component {
  static propTypes = {
    children: PropTypes.func.isRequired,
    resortID: PropTypes.string.isRequired
  };

  componentDidUpdate(prevProps) {
    if (prevProps.resortID !== this.props.resortID) {
      if (this.props.resortID) {
        this.reload();
      }
    }
  }

  reload = () => {
    if (this.ref) {
      this.ref.cancel();
      this.ref.reload();
    }
  };

  render() {
    const { resortID, children } = this.props;

    const config = {
      ajaxConfig: {
        url: "/apis/portal/resorts",
        data: {
          action: "read",
          data: {
            ID: resortID
          }
        }
      },
      autoload: !!resortID,
      stateful: false,
      ref: r => (this.ref = r)
    };

    return (
      <AjaxComponent {...config}>
        {({ data, loading, error }) => children({ data, loading, error })}
      </AjaxComponent>
    );
  }
}
