import React from "react";
import { Icon } from "semantic-ui-react";
import injectSheet from "react-jss";
const styles = {
  div: {
    padding: ".5rem",
    background: "#eee",
    display: "flex",
    marginBottom: ".5rem",
    border: "1px solid #ddd"
  }
};

const Unclickable = ({ classes, children }) => (
  <div className={classes.div}>
    <div style={{ flexGrow: 1 }}>{children}</div>
    <div>
      <Icon name={"lock"} size={"large"} style={{ marginTop: ".8rem" }} />
    </div>
  </div>
);

export default injectSheet(styles)(Unclickable);
