import storage from "local-storage-fallback";

export default {
  theme: "light",
  crabmode: false,
  focusBarOpen: true,
  favoritePages: [],
  betaCode: "",
  ...JSON.parse(storage.getItem("store") || "{}")
};
