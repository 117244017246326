import React, { PureComponent } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

class Info extends PureComponent {
  render() {
    return <Wrapper {...this.props}>{this.props.children}</Wrapper>;
  }
}

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: ${props => "repeat(" + props.columns + ", 1fr)"};
  grid-template-rows: ${props => "repeat(" + props.rows + ", auto)"};
  grid-gap: 2rem;
  grid-auto-flow: ${props => props.autoFlow};
  margin: 3rem 0;
`;

Info.propTypes = {
  autoFlow: PropTypes.string,
  columns: PropTypes.string,
  rows: PropTypes.string
};

Info.defaultProps = {
  autoFlow: "column",
  columns: "2",
  rows: "3"
};

export default Info;

export const Item = ({ header, text, data, icon }) => {
  return (
    <StyledItem>
      {icon && <i aria-hidden="true" className={`fas ${icon}`} />}
      {header && <h3>{header}</h3>}
      {text && <p>{text}</p>}
      {data && <h4>{data}</h4>}
    </StyledItem>
  );
};

const StyledItem = styled.div`
  position: relative;
  overflow: hidden;

  display: flex;
  flex: 0 1 auto;
  flex-wrap: wrap;
  align-content: baseline;

  &:not(:last-child) {
    margin-bottom: 0;
  }

  i {
    position: absolute;
    right: -0.5rem;
    top: 0;
    font-size: 3.5rem;
    opacity: 0.25;
    color: #2185d0;
  }

  h3,
  span,
  p,
  h4 {
    margin: 0 0 0.5rem;
    width: 100%;
  }
  h3 {
    font-size: 1.25rem;
    font-weight: bold;
    text-transform: uppercase;
    white-space: pre-line;
  }
  h4 {
    font-size: 2rem;
    font-weight: bold;
    color: #2185d0;
    align-self: end;
  }
  p {
    text-align: left !important;
    white-space: pre-line;
    margin-bottom: 0 !important;
  }
`;
