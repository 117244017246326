import React, { Component } from "react";
import PropTypes from "prop-types";

import { Segment } from "semantic-ui-react";

import HasOwner from "./components/HasOwner";
import NoOwner from "./components/NoOwner";
import PointsWizard from "./components/PointsWizard";

export default class Owner extends Component {
  static propTypes = {
    data: PropTypes.object.isRequired,
    reload: PropTypes.func.isRequired,
    classes: PropTypes.object.isRequired
  };

  state = {
    open: false,
    membership: false
  };

  handleClose = () => this.setState({ open: false, membership: false });
  addPoints = membership => this.setState({ open: true, membership });
  assignOwner = () => this.setState({ open: true });

  render() {
    const { data, reload } = this.props;
    const { open, membership } = this.state;
    const { point_usages } = data;

    return (
      <Segment>
        {point_usages.length ? (
          <HasOwner {...this.props} addPoints={this.addPoints} />
        ) : (
          <NoOwner {...this.props} assignOwner={this.assignOwner} />
        )}
        <PointsWizard
          open={open}
          membership={membership}
          handleClose={this.handleClose}
          booking={data}
          reload={reload}
        />
      </Segment>
    );
  }
}
