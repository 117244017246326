import React from "react";
import { Modal, Button } from "semantic-ui-react";
import { connect } from "react-redux";
import { clearCreated } from "redux/EOQReport/actions";
import injectSheet from "react-jss";

class EOQSuccess extends React.Component {
  getProps = type => {
    const { created } = this.props;

    const IDs = encodeURIComponent(JSON.stringify(created));
    const URL = `/browse/checks/${IDs}/${type}`;

    return {
      // allows open in new tab on m2/m3
      href: URL,
      // prevents redirecting current tab on m1
      onClick: e => {
        e.preventDefault();
        window.open(URL);
      }
    };
  };

  render() {
    const { created, clearCreated } = this.props;
    return (
      <Modal open={created.length > 0} size={"tiny"}>
        <Modal.Header>Success</Modal.Header>
        <Modal.Content>
          <p>
            The checks have been created. Review them and remember to mark them
            as processed when they are printed. If a check is not correct,
            delete it and the booking will show up in this list again.
          </p>
          <div style={{ textAlign: "center" }}>
            <a
              {...this.getProps("invoice")}
              children={<Button>View Invoices</Button>}
            />
            <a
              {...this.getProps("print")}
              children={<Button>View Checks</Button>}
            />
          </div>
        </Modal.Content>
        <Modal.Actions>
          <Button secondary onClick={clearCreated}>
            Start Over
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

const styles = {
  // ...
};

const mapStateToProps = state => ({
  created: state.EOQReport.created
  // ...
});
const mapDispatchToProps = dispatch => ({
  clearCreated: () => dispatch(clearCreated())
  // ...
});

const connected = connect(
  mapStateToProps,
  mapDispatchToProps
)(EOQSuccess);
export default injectSheet(styles)(connected);
