import React from "react";
import {
  Container,
  Header,
  Icon,
  Divider,
  Button,
  Form,
  Table
} from "semantic-ui-react";
import styled from "styled-components";

import Layout from "components/Layout";
import WithState from "components/WithState";
import AjaxComponent from "components/AjaxComponent";
import AjaxFn from "services/AjaxFn";
import Breadcrumb from "./components/Breadcrumb";
import CheckForm from "./components/CheckForm";
import BankSelect from "./components/BankSelect";
import CreatedChecks from "./components/CreatedChecks";

const blankCheckState = {
  Amount: "0.00",
  PayTo: "",
  Memo: "",
  visibility: "null"
};

const defaultData = {
  data: [{ ...blankCheckState }]
};

export default class CheckWizard extends React.Component {
  state = {
    loading: false,
    saving: false,
    bank: undefined,
    startingCheckNumber: false,
    created: false,
    ...defaultData
  };

  // add another check state
  addAnother = () => {
    this.setState({
      data: [...this.state.data, { ...blankCheckState }]
    });
  };

  removeOne = index => {
    const { data } = this.state;

    // if data length is equal to one, reset data
    if (data.length === 1) return this.resetData();
    // otherwise, remove object from array
    const newData = data.filter((d, i) => i !== index);
    // return updated state
    return this.setState({ data: newData });
  };

  updateOne = (data, index) => {
    this.setState({
      data: this.state.data.map((el, ix) => {
        if (ix === index) {
          return data;
        } else {
          return el;
        }
      })
    });
  };

  // reset data back to an initial state
  // in this case it's defaultData
  resetData = () => this.setState({ ...defaultData });

  save = () => {
    this.setState({ loading: true });
    AjaxFn({
      url: "/apis/portal/checks",
      data: {
        action: "print",
        data: {
          checks: this.state.data,
          bankID: this.state.bank,
          checkNumber: this.state.startingCheckNumber
        }
      },
      success: ({ created }) => this.setState({ created, loading: false }),
      failure: error => {
        window.toastr.error(error);
        this.setState({ loading: false });
      }
    });
  };

  render() {
    const { bank, created, loading, startingCheckNumber } = this.state;

    // config for AjaxComponent
    const ajaxConfig = {
      url: "/apis/portal/banks",
      data: {
        action: "read_for_checks"
      }
    };

    return (
      <Layout noBar>
        <Breadcrumb />
        <CheckWizardWrapper>
          <Container>
            <Header as="h1">
              <Icon name="wizard" />
              <Header.Content content="Check Wizard" />
            </Header>
            <Divider />
            {created ? (
              <CreatedChecks
                created={created}
                back={() => this.setState({ created: false }, this.resetData)}
              />
            ) : (
              <AjaxComponent ajaxConfig={ajaxConfig}>
                {({ data }) => (
                  <WithState loading={loading} error={false}>
                    <BankSelect
                      banks={data}
                      bank={bank}
                      startingCheckNumber={startingCheckNumber}
                      setBank={bank => this.setState({ bank })}
                      setStartingCheckNumber={num =>
                        this.setState({ startingCheckNumber: num })
                      }
                    />
                    <p>
                      Add the information for the checks you want to print. Bank
                      and recipient information will be automatically added. You
                      can change the check number, but the next highest one is
                      automatically selected. Check numbers will increment
                      automatically.
                    </p>
                    <Divider />

                    <Form>
                      <Table singleLine>
                        <Table.Header>
                          <Table.Row>
                            <Table.HeaderCell content="Pay To" />
                            <Table.HeaderCell content="Amount" />
                            <Table.HeaderCell content="Memo" />
                            <Table.HeaderCell content="Visibility" />
                            <Table.HeaderCell content="" />
                          </Table.Row>
                        </Table.Header>
                        <Table.Body>
                          {this.state.data.map((check, key) => (
                            <CheckForm
                              check={check}
                              update={data => this.updateOne(data, key)}
                              remove={() => this.removeOne(key)}
                              key={key}
                              length={this.state.data.length}
                            />
                          ))}
                        </Table.Body>
                      </Table>
                    </Form>

                    <CheckActions>
                      <Button
                        onClick={this.resetData}
                        content="Reset"
                        icon="undo"
                        basic
                      />
                      <Button
                        onClick={this.addAnother}
                        content="Add Check"
                        icon="add"
                        color="green"
                        basic
                      />
                      <Button
                        primary
                        onClick={this.save}
                        disabled={!this.state.bank}
                        content={"Print Checks"}
                      />
                    </CheckActions>
                  </WithState>
                )}
              </AjaxComponent>
            )}
          </Container>
        </CheckWizardWrapper>
      </Layout>
    );
  }
}

const CheckWizardWrapper = styled.div`
  width: 100%;
  margin-top: 2rem;
`;

const CheckActions = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: stretch;
  margin-top: 1rem;

  &&& {
    button {
      margin-right: 1rem;

      &:last-of-type {
        margin-right: 0;
      }
    }
  }
`;
