import React from "react";
import { Comment } from "semantic-ui-react";
const style = {
  opacity: ".6",
  fontWeight: "bold",
  userSelect: "none"
};
export default ({ comment }) => {
  const getMessage = () => {
    if (comment.deleted) {
      return <span style={style}>{"(comment removed)"}</span>;
    }
    return (
      <span
        dangerouslySetInnerHTML={{ __html: comment.content }}
        style={{ whiteSpace: "pre-line" }}
      />
    );
  };
  return <Comment.Text>{getMessage()}</Comment.Text>;
};
