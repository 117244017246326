import React from "react";
import PropTypes from "prop-types";

import WithState from "components/WithState";
import ResortFocusBar from "../ResortFocusBar";
import Comments from "../Comments";
import Summary from "../Summary";

const Renderer = props => {
  const { resortData, resortTab } = props;

  const router = () => {
    switch (resortTab) {
      case "comments":
        return <Comments {...props} />;
      default:
        return <Summary {...props} />;
    }
  };

  return (
    <>
      <ResortFocusBar {...props} />
      <WithState loading={resortData.loading} error={resortData.error}>
        {router()}
      </WithState>
    </>
  );
};

Renderer.propTypes = {
  resortID: PropTypes.string.isRequired,
  resortData: PropTypes.object.isRequired,
  resortTab: PropTypes.string,
  reloadResort: PropTypes.func.isRequired
};

export default Renderer;
