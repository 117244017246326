import React from "react";
import { Message, Button, Icon } from "semantic-ui-react";
import { connect } from "react-redux";
import { discardChanges, saveChanges } from "redux/BookingPage/actions";
const ChangesWarning = ({ changes, discard, save }) => {
  if (!Object.keys(changes).length) {
    return null;
  }
  return (
    <Message>
      <Message.Header>
        <Icon name={"exclamation triangle"} />Unsaved changes
      </Message.Header>
      <p>
        You have unsaved changes in the "summary" tab. They will be lost if you
        leave this page.
      </p>
      <div>
        <Button onClick={discard}>Discard</Button>
        <Button primary onClick={save}>
          Save
        </Button>
      </div>
    </Message>
  );
};
const mapStateToProps = (state) => ({
  changes: state.BookingPage.changes
});
const mapDispatchToProps = (dispatch) => ({
  discard: () => dispatch(discardChanges()),
  save: () => dispatch(saveChanges())
  // ...
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ChangesWarning);
