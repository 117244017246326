import React, { Fragment } from "react";
import { Form } from "semantic-ui-react";
import ScheduleControls from "components/ScheduleControls";

export default ({ set, data }) => (
  <Fragment>
    <p>
      Specify what the users schedule will look like. If the user does not have
      the permission to clock in outside of hours, they will need manager
      permission to clock in before the times below.
    </p>
    <Form>
      {days.map((day, index) => (
        <Form.Field key={index}>
          <label>{day[1]}</label>
          <ScheduleControls
            value={data[day[0]]}
            handleChange={value => set(day[0], value)}
          />
        </Form.Field>
      ))}
    </Form>
  </Fragment>
);

const days = [
  ["mon", "Monday"],
  ["tue", "Tuesday"],
  ["wed", "Wednesday"],
  ["thu", "Thursday"],
  ["fri", "Friday"],
  ["sat", "Saturday"],
  ["sun", "Sunday"]
];
