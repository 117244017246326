import AjaxFn from "services/AjaxFn";
import preprocess from "./preprocessor.js";

export const reload = (unselect = true) => dispatch => {
  dispatch({ type: "TIMEPUNCH_MANAGER_RELOAD", unselect });
  dispatch(loadAccounts());
};

export const setError = data => ({
  type: "TIMEPUNCH_MANAGER_ERROR",
  data
});

export const setAccounts = data => ({
  type: "TIMEPUNCH_MANAGER_SET_ACCOUNTS",
  data
});

export const setTimepunches = data => ({
  type: "TIMEPUNCH_MANAGER_SET_TIMEPUNCHES",
  data
});

export const loadAccounts = () => dispatch => {
  AjaxFn({
    url: "/apis/portal/accounts?action=read_for_timepunches",
    success: accounts => {
      dispatch(setAccounts(accounts));
      dispatch(loadTimepunches());
    },
    failure: error => dispatch(setError(error))
  });
};

export const loadTimepunches = () => (dispatch, getState) => {
  const { date } = getState().TimepunchManager;
  AjaxFn({
    url: "/apis/portal/timepunches",
    data: {
      action: "read_all_for_week",
      data: {
        date: date.format("YYYY-MM-DD")
      }
    },
    success: timepunches => {
      dispatch(setTimepunches(timepunches));
      dispatch(processData());
    },
    failure: error => dispatch(setError(error))
  });
};

export const processData = () => (dispatch, getState) => {
  const { timepunches, accounts } = getState().TimepunchManager;
  dispatch({
    type: "TIMEPUNCH_MANAGER_SET_PROCESSED",
    data: preprocess(timepunches, accounts)
  });
};

export const setDate = date => dispatch => {
  dispatch({
    type: "TIMEPUNCH_MANAGER_SET_DATE",
    data: date
  });
  dispatch(reload());
};

export const setAccount = data => ({
  type: "TIMEPUNCH_MANAGER_SET_ACCOUNT",
  data
});

export const setTimepunch = data => ({
  type: "TIMEPUNCH_MANAGER_SET_PUNCH",
  data
});

export const setFilters = data => ({
  type: "TIMEPUNCH_MANAGER_SET_FILTERS",
  data
});

export const toggleDeleted = () => ({
  type: "TIMEPUNCH_MANAGER_TOGGLE_DELETED"
});
