import React from "react";
import { Form, Button } from "semantic-ui-react";
import AjaxDropdown from "components/AjaxDropdown";
import naturalSort from "utils/naturalSort";
/**
 *	@prop data
 *	@prop setData
 *  @prop switchMode
 */
export default class MemberSelect extends React.Component {
  render() {
    return (
      <React.Fragment>
        <Form>
          <Form.Field>
            <label>Member from database</label>
            <AjaxDropdown
              placeholder={"Select a member"}
              selection
              value={this.props.data}
              onChange={(e, { value }) => this.props.setData(value)}
              url={"/apis/portal/members"}
              data={{ action: "read_for_owner_wizard" }}
              dataRetreiver={processMembers}
            />
          </Form.Field>
        </Form>
        <br />
        <Button
          icon={"user plus"}
          content={"New Member"}
          onClick={this.props.switchMode}
        />
      </React.Fragment>
    );
  }
}

const processMembers = ({ message: m }) => {
  return m
    .filter(row => !row.deleted)
    .map(({ id, firstname, lastname }) => ({
      value: id,
      text: firstname + " " + lastname
    }))
    .sort((a, b) => naturalSort(a.text, b.text));
};
