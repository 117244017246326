import React from "react";
import PropTypes from "prop-types";

import WithState from "components/WithState";
import { LoadedBreadcrumb } from "../Breadcrumb";
import GuestFocusBar from "../GuestFocusBar";
import Bookings from "../Bookings";
import Comments from "../Comments";
import Summary from "../Summary";

const Renderer = props => {
  const { guestData, guestTab } = props;

  const router = () => {
    switch (guestTab) {
      case "bookings":
        return <Bookings {...props} />;
      case "comments":
        return <Comments {...props} />;
      default:
        return <Summary {...props} />;
    }
  };

  return (
    <>
      <GuestFocusBar {...props} />
      <WithState loading={guestData.loading} error={guestData.error}>
        <LoadedBreadcrumb guest={guestData.data} />
        {router()}
      </WithState>
    </>
  );
};

Renderer.propTypes = {
  guestID: PropTypes.string.isRequired,
  guestData: PropTypes.object.isRequired,
  guestTab: PropTypes.string,
  reloadGuest: PropTypes.func.isRequired
};

export default Renderer;
