import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";

import { Message, Header, Segment, Icon } from "semantic-ui-react";

import { MembershipBreadcrumb } from "../Breadcrumb";
import SummaryForm from "./components/SummaryForm";
import Activate from "./components/Activate";
import Points from "./components/Points";
import Logins from "./components/Logins";
import props from "../propTypes.js";

export default class MembershipSummary extends Component {
  static propTypes = { ...props };

  render() {
    const { member, membership, reloadMembership } = this.props;

    return (
      <Fragment>
        <MembershipBreadcrumb membership={membership} member={member} />
        <Header dividing as="h1">
          <Icon
            name={
              membership.is_active ? "check square outline" : "square outline"
            }
            color={membership.is_active ? undefined : "red"}
          />
          <Header.Content>
            <Link to={"/browse/members/" + member.id}>
              {member.firstname} {member.lastname}
            </Link>
            {" / "}
            {membership.resort_company.name} {membership.level}
            <Header.Subheader>Membership Summary</Header.Subheader>
          </Header.Content>
        </Header>
        {!membership.is_active && (
          <Message error>
            <Message.Header>Membership Inactive</Message.Header>
            This membership is marked as inactive. It will not be available for
            use for bookings. If this is a mistake, you can set them to active
            in the "actions" section below.
          </Message>
        )}
        <p>
          You can edit membership info from this page. Certain changes won't
          appear in the sidebar until after you refresh the page, such as the
          level or active/inactive status. Changes you make will be saved
          automatically.
        </p>
        <Segment attached="top">
          <SummaryForm
            membership={membership}
            reloadMembership={reloadMembership}
          />
        </Segment>
        <Segment attached>
          <Header
            icon="user circle outline"
            content="Login Info"
            subheader="Account login information"
          />
          <Logins membership={membership} reloadMembership={reloadMembership} />
        </Segment>
        <Segment attached="bottom">
          <Header
            icon="settings"
            content="Actions"
            subheader="Perform actions for this membership"
          />
          <p>Use the button below to activate or deactivate this membership.</p>
          <Activate
            member={member}
            membership={membership}
            reloadMembership={reloadMembership}
          />
        </Segment>
        <br />
        <Header
          as="h1"
          icon="cubes"
          content="Points"
          subheader="View or edit points for this membership"
          dividing
        />
        <p>
          Below you can view all of the points for this membership that have
          been entered in the portal, as well as view any bookings that are
          assigned to each set of points. The set you are currently viewing is
          outlined in <b>blue</b>. Any changes you make will be saved
          automatically.
        </p>
        <Points
          member={member}
          membership={membership}
          reloadMembership={reloadMembership}
        />
      </Fragment>
    );
  }
}
