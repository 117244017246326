import React, { Fragment } from "react";
import { Form } from "semantic-ui-react";
import { Wrapper } from "../../../../context";
import styled from "styled-components";

const Card = ({ context: { functions } }) => (
  <Fragment>
    <p>Your card information is required to process your payment.</p>
    <Form.Group widths={"equal"}>
      {functions.createInput(<Form.Input />, "card_number", {
        name: "card_number",
        label: "Card number",
        placeholder: "xxxx-xxxx-xxxx-xxxx",
        required: true,
        autoComplete: "off",
        icon: "lock",
      })}
      {functions.createInput(<Form.Input />, "card_cvc", {
        name: "card_cvc",
        label: "Card CVC",
        placeholder: "xxx",
        required: true,
        autoComplete: "off",
        type: "number",
        icon: "lock",
      })}
    </Form.Group>
    <Form.Group widths={"equal"}>
      {functions.createInput(<Form.Dropdown />, "card_exp_date", {
        name: "card_exp_date",
        label: "Expiration date",
        placeholder: "Select one...",
        required: true,
        autoComplete: "off",
        options: [
          { value: "1", text: "January" },
          { value: "2", text: "February" },
          { value: "3", text: "March" },
          { value: "4", text: "April" },
          { value: "5", text: "May" },
          { value: "6", text: "June" },
          { value: "7", text: "July" },
          { value: "8", text: "August" },
          { value: "9", text: "September" },
          { value: "10", text: "October" },
          { value: "11", text: "November" },
          { value: "12", text: "December" },
        ],
        selection: true,
      })}
      {functions.createInput(<Form.Input />, "card_exp_year", {
        name: "card_exp_year",
        label: "Expiration year",
        placeholder: "Expiration year",
        required: true,
        autoComplete: "off",
        type: "number",
      })}
    </Form.Group>
    <p>We accept the following:</p>
    <img src="/img/creditcards.png" alt="Visa, Mastercard, Amex, Discover" style={{ maxHeight: '3rem'}} />
    <img src="/img/america.png" alt="United States of America Flag" style={{ maxHeight: '3rem', padding: '.5rem', display: 'inline-block' }}/>
  </Fragment>
);

export default Wrapper(Card);

const StyledIcon = styled.i`
  display: inline-block;
  margin-right: 0.5rem;
  font-size: 2.5rem;
`;
