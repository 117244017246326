import React from "react";
import styled from "styled-components";
import { Form, Button } from "semantic-ui-react";

import LinkConf from "components/LinkWithConfirmation";
import AjaxDropdown from "components/AjaxDropdown";

export default ({ filters, updateFilters }) => (
  <Form>
    <FormGroup widths={"equal"}>
      <Form.Dropdown
        label="Disabled Accounts"
        selection
        fluid
        value={filters.disabled}
        options={[
          { text: "Show all accounts", value: "any" },
          { text: "Show disabled only", value: true },
          { text: "Do not show", value: false }
        ]}
        onChange={(e, { value }) => updateFilters("disabled", value)}
      />
      <Form.Field>
        <label>Account Companies</label>
        <AjaxDropdown
          selection
          fluid
          value={filters.company}
          url={"/apis/portal/accountcompanies"}
          data={{ action: "read_for_dropdown" }}
          dataRetreiver={({ message }) =>
            message
              .map(({ id, name }) => ({
                value: id,
                text: name
              }))
              .concat({ value: "any", text: "Any company" })
          }
          onChange={(e, { value }) => updateFilters("company", value)}
        />
      </Form.Field>
      <Form.Dropdown
        label="Unrestricted Accounts"
        selection
        fluid
        value={filters.unrestricted}
        options={[
          { text: "Any", value: "any" },
          { text: "Show restricted only", value: false },
          { text: "Show unrestricted only", value: true }
        ]}
        onChange={(e, { value }) => updateFilters("unrestricted", value)}
      />
      <Form.Field>
        <label>Alternate View</label>
        <LinkConf to={"/browse/accounts/spreadsheet"}>
          <Button icon="external" content="View Rights" fluid />
        </LinkConf>
      </Form.Field>
    </FormGroup>
  </Form>
);

const FormGroup = styled(Form.Group)`
  &&& {
    position: relative;
    display: grid;
    grid-template-columns: repeat(4, auto);
    grid-column-gap: 1rem;
    grid-auto-flow: row;
    align-items: stretch;
    width: 100%;
    margin: 0 auto 1rem;

    .field {
      padding: 0;
      flex: 0 !important;

      a {
        button {
          height: 38px;
        }
      }
    }
  }
`;
