import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Button } from "semantic-ui-react";
import { setPage } from "redux/PortalTables/actions";
import injectSheet from "react-jss";

export class Pagination extends Component {
  static propTypes = {
    namespace: PropTypes.string.isRequired,
    setPage: PropTypes.func.isRequired,
    tables: PropTypes.object.isRequired
  };
  render() {
    const { namespace, tables, classes, setPage } = this.props;
    const { orderedData, show, page } = tables[namespace];
    let div = Math.floor(orderedData.length / show);
    let pages = orderedData.length % show ? div + 1 : div;
    const buttons = [];
    let start_offset = 3;
    let end_offset = 4;
    let start = page - start_offset > 0 ? page - start_offset : 0;
    let end = page + end_offset <= pages ? page + end_offset : pages;
    for (let i = start; i < end; i++) {
      buttons.push(i);
    }
    // Do not show the paginator if there is one page
    if (buttons.length === 1) {
      return null;
    }
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginRight: "-.25rem"
        }}
      >
        <div className={classes.buttons}>
          {page > start_offset && (
            <Button
              basic
              content={"First"}
              onClick={() => setPage(namespace, 0)}
              className={classes.bordered}
            />
          )}
          <Button
            basic
            disabled={page === 0}
            content={"Back"}
            onClick={() => setPage(namespace, page - 1)}
            className={classes.bordered}
          />
        </div>
        <div className={classes.buttons}>
          {buttons.map((el, i) => (
            <Button
              basic
              key={i}
              content={el + 1}
              primary={el === page}
              onClick={() => setPage(namespace, el)}
              className={classes.bordered}
            />
          ))}
        </div>
        <div className={classes.buttons}>
          <Button
            basic
            disabled={page >= pages - 1}
            content={"Next"}
            onClick={() => setPage(namespace, page + 1)}
            className={classes.bordered}
          />
          {page < pages - end_offset && (
            <Button
              basic
              onClick={() => setPage(namespace, pages - 1)}
              content={`Last (${pages})`}
              className={classes.bordered}
            />
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  tables: state.PortalTables
});

const mapDispatchToProps = {
  setPage
};

const styles = {
  bordered: {
    border: "1px solid rgba(0,0,0,.10) !important"
  },
  buttons: {
    "& button": {
      marginRight: ".25rem"
    }
  }
};

const connected = connect(
  mapStateToProps,
  mapDispatchToProps
)(Pagination);
export default injectSheet(styles)(connected);
