import React from "react";
import { Dropdown, Modal, Button, Form } from "semantic-ui-react";
import { connect } from "react-redux";
import { setFilters } from "redux/PortalTables/actions.js";
import payrates from "fixtures/payrates";
import issuerCompanies from "fixtures/issuerCompanies";
import NullableDropdown from "components/NullableDropdown";
/**
 *  @prop namespace
 */
class Filters extends React.Component {
  state = {
    open: false,
    applied: {
      login_works: "any",
      is_active: true,
      has_resigned: "any",
      shared: "any",
      assigned_quarter: "any",
      issuer_company: "any",
      // guest_passes: "any",
      // reservation_passes: "any",
      payrate: "any",
      percentage_payrate: "any"
    }
  };
  componentDidMount() {
    this.updateFilters();
  }
  updateFilterState = (key, value) => {
    let newState = {
      applied: {
        ...this.state.applied,
        [key]: value
      }
    };
    this.setState(newState, this.updateFilters);
  };
  buildFilters = () => {
    const { applied } = this.state;
    const functions = [];
    const standard = [
      "is_active",
      "login_works",
      "shared",
      "has_resigned",
      "percentage_payrate",
      "payrate",
      "assigned_quarter",
      "issuer_company"
    ];
    standard.forEach((field) => {
      if (applied[field] !== "any") {
        functions.push((row) => row[field] === applied[field]);
      }
    });
    return functions;
  };
  updateFilters = () => {
    const action = setFilters(this.props.namespace, this.buildFilters());
    this.props.dispatch(action);
  };
  render() {
    return (
      <Modal
        trigger={<Button icon={"filter"} />}
        header={"Filters"}
        size={"tiny"}
        actions={["Close"]}
        content={
          <Modal.Content>
            <Form>
              <Form.Group widths={"equal"}>
                <Form.Field>
                  <label>Membership Status</label>
                  <Dropdown
                    style={{ marginRight: ".25rem" }}
                    selection
                    onChange={(e, { value }) =>
                      this.updateFilterState("is_active", value)
                    }
                    value={this.state.applied.is_active}
                    options={[
                      { value: false, text: "Inactive Only" },
                      { value: true, text: "Active Only" },
                      { value: "any", text: "Show All" }
                    ]}
                  />
                </Form.Field>
                <Form.Field>
                  <label>Login Works</label>
                  <Dropdown
                    style={{ marginRight: ".25rem" }}
                    selection
                    onChange={(e, { value }) =>
                      this.updateFilterState("login_works", value)
                    }
                    value={this.state.applied.login_works}
                    options={[
                      { value: false, text: "Broken Only" },
                      { value: true, text: "Working Only" },
                      { value: "any", text: "Show All" }
                    ]}
                  />
                </Form.Field>
              </Form.Group>
              <Form.Group widths={"equal"}>
                <Form.Field>
                  <label>Shared Account</label>
                  <Dropdown
                    style={{ marginRight: ".25rem" }}
                    selection
                    onChange={(e, { value }) =>
                      this.updateFilterState("shared", value)
                    }
                    value={this.state.applied.shared}
                    options={[
                      { value: false, text: "Not Shared Only" },
                      { value: true, text: "Shared Only" },
                      { value: "any", text: "Show All" }
                    ]}
                  />
                </Form.Field>
                <Form.Field>
                  <label>Has Re-Signed Up</label>
                  <Dropdown
                    style={{ marginRight: ".25rem" }}
                    selection
                    onChange={(e, { value }) =>
                      this.updateFilterState("has_resigned", value)
                    }
                    value={this.state.applied.has_resigned}
                    options={[
                      { value: false, text: "Do Not Show If" },
                      { value: true, text: "Only Show If" },
                      { value: "any", text: "Show All" }
                    ]}
                  />
                </Form.Field>
              </Form.Group>
              <Form.Group widths={"equal"}>
                <Form.Field>
                  <label>Payrate Is Percentage</label>
                  <Dropdown
                    style={{ marginRight: ".25rem" }}
                    selection
                    onChange={(e, { value }) =>
                      this.updateFilterState("percentage_payrate", value)
                    }
                    value={this.state.applied.percentage_payrate}
                    options={[
                      { value: false, text: "Do Not Show If" },
                      { value: true, text: "Only Show If" },
                      { value: "any", text: "Show All" }
                    ]}
                  />
                </Form.Field>
                <Form.Field>
                  <label>Payrate</label>
                  <Dropdown
                    style={{ marginRight: ".25rem" }}
                    selection
                    onChange={(e, { value }) =>
                      this.updateFilterState("payrate", value)
                    }
                    value={this.state.applied.payrate}
                    options={[{ value: "any", text: "Show All" }].concat(
                      [...payrates].splice(1)
                    )}
                  />
                </Form.Field>
              </Form.Group>
              <Form.Group widths={"equal"}>
                <Form.Field>
                  <label>Assigned Quarter</label>
                  <NullableDropdown
                    shallowRemap={false}
                    style={{ marginRight: ".25rem" }}
                    selection
                    onChange={(e, { value }) =>
                      this.updateFilterState("assigned_quarter", value)
                    }
                    value={this.state.applied.assigned_quarter}
                    options={[
                      { value: 1, text: "First" },
                      { value: 2, text: "Second" },
                      { value: 3, text: "Third" },
                      { value: 4, text: "Fourth" },
                      { value: null, text: "None" },
                      { value: "any", text: "Show All" }
                    ]}
                  />
                </Form.Field>
                <Form.Field>
                  <label>Issuer Company</label>
                  <Dropdown
                    style={{ marginRight: ".25rem" }}
                    selection
                    onChange={(e, { value }) =>
                      this.updateFilterState("issuer_company", value)
                    }
                    value={this.state.applied.issuer_company}
                    options={[{ value: "any", text: "Show All" }].concat([
                      ...issuerCompanies
                    ])}
                  />
                </Form.Field>
              </Form.Group>
            </Form>
          </Modal.Content>
        }
      />
    );
  }
}
const mapStateToProps = () => ({});
export default connect(mapStateToProps)(Filters);
