/**
 * Get those pesky URL paramaters returned to you as
 * an easy to use object
 * @returns object
 */
export const getUrlParams = (search) => {
  let hashes = search.slice(search.indexOf("?") + 1).split("&");
  let params = {};

  hashes.map((hash) => {
    let [key, val] = hash.split("=");
    params[key] = decodeURIComponent(val);
  });

  return params;
};

export const getAPIURL = (url) => {
  if (url[0] !== "/") {
    return url;
  }

  return `${process.env.REACT_APP_BUILD_API_URL}${url}`;
};
