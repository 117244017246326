import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import moment from "moment";

import { Button, Modal, Message, Divider, Segment } from "semantic-ui-react";

import EditForm from "./components/EditForm";
import DeleteButton from "./components/DeleteButton";
import History from "./components/History";

import { setTimepunch } from "redux/TimepunchManager/actions";
import { user_can } from "utils/user";

export class TimepunchView extends Component {
  static propTypes = {
    ID: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]).isRequired,
    timepunches: PropTypes.array.isRequired,
    locked: PropTypes.bool.isRequired,
    setLocked: PropTypes.func.isRequired,
    user: PropTypes.object.isRequired
  };

  render() {
    const {
      timepunches,
      ID,
      setTimepunch,
      locked,
      setLocked,
      user
    } = this.props;

    const timepunch = timepunches.find(punch => punch.id === ID);

    const sorted_edits = timepunch.edits.sort(
      (a, b) => new Date(b.modified_on) - new Date(a.modified_on)
    );

    // quick and ez way to check if this user is unrestricted
    // in hindsight, probably coulda just used this.props.user.account_role.restricted
    const restricted = !user_can("yeet", this.props.user);

    // if the user is the same, only unrestricted accounts should be able
    // to edit this timepunch
    const canEdit = timepunch.account.id !== user.id ? true : !restricted;

    return (
      <Fragment>
        <Modal.Content style={{ marginTop: "1px" }}>
          <History timepunch={timepunch} />
          {timepunch.deleted ? (
            <Message
              warning
              header="Timepunch deleted"
              content="This timepunch can not be edited becuase it has been deleted."
            />
          ) : canEdit ? (
            <Segment secondary>
              <p>
                Use the form below to edit this timepunch. It will show up in
                the timepunch history.
              </p>
              <EditForm
                timepunch={timepunch}
                locked={locked}
                setLocked={setLocked}
              />
            </Segment>
          ) : (
            <Segment secondary>
              <p>Your account is not allowed to modify your own timepunches.</p>
            </Segment>
          )}
        </Modal.Content>
        <Modal.Actions>
          <Button
            content="Back"
            onClick={() => setTimepunch(false)}
            disabled={locked}
          />
          {canEdit && (
            <DeleteButton
              timepunch={timepunch}
              locked={locked}
              setLocked={setLocked}
            />
          )}
        </Modal.Actions>
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  timepunches: state.TimepunchManager.timepunches,
  ID: state.TimepunchManager.selected_timepunch,
  user: state.User
});

const mapDispatchToProps = {
  setTimepunch
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TimepunchView);
