import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import withStyles from "react-jss";

import { Button, Form } from "semantic-ui-react";

import AsyncForm from "components/AsyncForm";
import Delete from "./Delete";
import AddNew from "./Add";

class Logins extends Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    membership: PropTypes.object.isRequired,
    reloadMembership: PropTypes.func.isRequired
  };

  getConfig = membershipLoginID => {
    const { membership, reloadMembership } = this.props;

    return {
      reload: reloadMembership,
      failure: window.toastr.error,
      dataFn: (source, value) => ({
        url: membershipLoginID
          ? "/apis/portal/membershiplogins"
          : "/apis/portal/memberships",
        data: {
          action: "update",
          data: {
            ID: membershipLoginID || membership.id,
            [source]: typeof value === "boolean" ? (value ? 1 : 0) : value
          }
        }
      })
    };
  };

  getFields = (functions, source) => (
    <Fragment>
      {functions.input({
        field: "username",
        value: source.username,
        props: {
          placeholder: "example",
          icon: "user circle outline"
        }
      })}
      {functions.input({
        field: "password",
        value: source.password,
        props: {
          placeholder: "secret"
        }
      })}
      {functions.dropdown({
        field: "login_works",
        value: source.login_works,
        props: {
          placeholder: "Select one...",
          options: [{ value: false, text: "No" }, { value: true, text: "Yes" }]
        }
      })}
    </Fragment>
  );

  render() {
    // Form.Group doesn't support style={} so we're using withStyles to add a custom scoped class
    const { classes, membership, reloadMembership } = this.props;

    return (
      <Fragment>
        <p>
          Use the form below to add or remove additonal usernames and passwords
          for this membership. The first one is required and can not be removed,
          any extras are optional.
        </p>
        <Form>
          <Form.Group widths="equal" className={classes.noMargin}>
            <Form.Field>
              <label>Account</label>
            </Form.Field>
            <Form.Field>
              <label>Secret</label>
            </Form.Field>
            <Form.Field>
              <label>Login works</label>
            </Form.Field>
            <Form.Field />
          </Form.Group>
        </Form>

        <AsyncForm {...this.getConfig()}>
          {({ functions }) => (
            <Form.Group widths="equal">
              {this.getFields(functions, membership)}
              <Form.Field>
                <Button basic icon="lock" />
              </Form.Field>
            </Form.Group>
          )}
        </AsyncForm>
        {membership.logins.map(login => (
          <AsyncForm key={login.id} {...this.getConfig(login.id)}>
            {({ functions }) => (
              <Form.Group widths="equal">
                {this.getFields(functions, login)}
                <Form.Field>
                  <Delete login={login} reloadMembership={reloadMembership} />
                </Form.Field>
              </Form.Group>
            )}
          </AsyncForm>
        ))}
        <AddNew membership={membership} reloadMembership={reloadMembership} />
      </Fragment>
    );
  }
}

const styles = {
  noMargin: {
    marginBottom: "0px !important"
  }
};

export default withStyles(styles)(Logins);
