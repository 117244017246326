import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import styled from "styled-components";

import SavePage from "./save";
import Remove from "./remove";

import {
  ThemePopup,
  ThemePopupContent,
  ThemeNavAction
} from "theme/components";

const Container = styled.div`
  position: relative;
`;

const Resources = ({ favoritePages }) => (
  <ThemePopup
    trigger={
      <ThemeNavAction>
        <i className="fas fa-link" />
      </ThemeNavAction>
    }
    content={
      <ThemePopupContent>
        <h1>
          Saved Pages
          <ThemePopup
            trigger={
              <i
                className="fas fa-question-circle"
                style={{ marginLeft: "0.5rem" }}
              />
            }
            content={
              <p>
                Pages you save using this menu will be saved in your browser,
                not to your account. They will not persist between computers
                yet.
              </p>
            }
            on="hover"
            size="small"
            position="bottom center"
            basic
          />
        </h1>
        {favoritePages.length
          ? favoritePages.map((page, key) => (
              <Container key={key}>
                <Link to={page.url}>
                  {page.icon && <i className={page.icon} />}
                  {page.name}
                </Link>
                {/* <Remove index={key} /> */}
              </Container>
            ))
          : "You haven't saved any pages yet."}

        <h1>Add this page</h1>
        <SavePage />
      </ThemePopupContent>
    }
    on="click"
    basic
  />
);

Resources.propTypes = {
  favoritePages: PropTypes.array.isRequired
};

const mapStateToProps = state => ({
  favoritePages: state.Storage.favoritePages
  // ...
});

const mapDispatchToProps = {
  // ...
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Resources);
