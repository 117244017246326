import React from "react";
import { Header, Divider, Table, Item, Button } from "semantic-ui-react";
import LinkConf from "components/LinkWithConfirmation";
import { addCommas } from "utils/numbers";
import EditPoints from "./components/EditPoints";
import WithState from "components/WithState";
import AjaxFn from "services/AjaxFn";
import { connect } from "react-redux";
import { reloadBooking } from "redux/BookingPage/actions";
import { formatMJY } from "utils/dates";
class HasOwner extends React.Component {
  state = {
    loading: false
  };
  handleClick = ID => {
    const message =
      "Are you sure you want to remove these points from the booking?";
    if (window.confirm(message)) {
      this.setState({ loading: true });
      AjaxFn({
        url: "/apis/portal/bookingpointusages",
        data: {
          action: "delete",
          data: { ID }
        },
        success: () => {
          window.toastr.success();
          this.setState({ loading: false });
          this.props.reload();
        },
        failure: error => {
          this.setState({ loading: false });
          window.toastr.error(error);
        }
      });
    }
  };
  render() {
    const { classes, data, addPoints, user } = this.props;
    const { point_usages } = data;
    const { membership } = point_usages[0].point_set;
    const { member } = membership;
    const canEdit = data._actions.actions.edit_points;

    const url = `/browse/members/${member.id}/membership/${membership.id}`;
    return (
      <WithState loading={this.state.loading} error={false}>
        <Header as="h2">
          <Header.Content className={classes.headerContent}>
            Owner
            <Header.Subheader>
              <LinkConf to={url}>
                {member.firstname +
                  " " +
                  member.lastname +
                  " (" +
                  membership.resort_company.name +
                  " " +
                  membership.level +
                  ")"}
              </LinkConf>
            </Header.Subheader>
          </Header.Content>
        </Header>
        <Item.Group divided>
          {point_usages.map((usage, key) => (
            <Item key={key}>
              <Item.Content>
                {usage.point_set.name && (
                  <Item.Header>{usage.point_set.name}</Item.Header>
                )}
                <Item.Meta>
                  {formatMJY(usage.point_set.start)}
                  {" to "}
                  {formatMJY(usage.point_set.end)}
                </Item.Meta>
                <Item.Description>
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    {addCommas(usage.amount) + " points used"}
                    {canEdit && (
                      <span>
                        <EditPoints usage={usage} />
                        {" - "}
                        <a
                          href={null}
                          style={{ color: "red" }}
                          onClick={() => this.handleClick(usage.id)}
                        >
                          remove
                        </a>
                      </span>
                    )}
                  </div>
                </Item.Description>
              </Item.Content>
            </Item>
          ))}
        </Item.Group>
        <br />
        {canEdit ? (
          <Button
            basic
            content="Add Points"
            onClick={() => addPoints(membership)}
          />
        ) : (
          <p>You are not able to edit points/owners right now.</p>
        )}
      </WithState>
    );
  }
}
const mapStateToProps = state => ({
  user: state.User
});
const mapDispatchToProps = dispatch => ({
  reload: () => dispatch(reloadBooking())
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(HasOwner);
