import React, { Fragment } from "react";
import { Header, Grid, List, Divider } from "semantic-ui-react";
import { Wrapper } from "../../context";

const ListItem = ({ text, subtext, icon }) => (
  <List size={"large"} horizontal style={{ width: "100%" }}>
    <List.Item style={{ textAlign: "center", width: "100%" }}>
      <List.Icon name={icon} size={"big"} />
      <List.Content>
        <List.Header>{text}</List.Header>
        <List.Description>{subtext}</List.Description>
      </List.Content>
    </List.Item>
  </List>
);
const CompanyHeader = ({ context }) => (
  <Fragment>
    {context.agreement.account_company_logo ? (
      <div style={{ display: "block", textAlign: "center" }}>
        <img
          src={context.agreement.account_company_logo}
          alt={context.agreement.account_company_legal_name}
          style={{ maxWidth: "50%" }}
        />
      </div>
    ) : (
      <Header
        as={"h1"}
        color={context.agreement.color}
        style={{ textAlign: "center", fontSize: "4rem" }}
        content={context.agreement.account_company_legal_name}
      />
    )}
    <Divider />
    <Grid columns={3} stackable>
      <Grid.Column>
        <ListItem
          text={context.agreement.account_company_phone}
          subtext={"Phone"}
          icon={"phone"}
        />
      </Grid.Column>
      <Grid.Column>
        <ListItem
          text={context.agreement.account_company_email}
          subtext={"Email"}
          icon={"mail"}
        />
      </Grid.Column>
      <Grid.Column>
        <ListItem
          text={context.agreement.account_company_web}
          subtext={"Web"}
          icon={"globe"}
        />
      </Grid.Column>
    </Grid>
  </Fragment>
);
export default Wrapper(CompanyHeader);
