import React from "react";
import { Form, Dropdown, Input } from "semantic-ui-react";
import naturalSort from "utils/naturalSort";

export default props => {
  const {
    banks,
    bank,
    startingCheckNumber,
    setBank,
    setStartingCheckNumber
  } = props;
  const sortFn = (a, b) => naturalSort(a.portal_name, b.portal_name);

  const options = banks
    .filter(row => row.active)
    .sort((a, b) => sortFn(a, b))
    .map(row => ({
      value: row.id,
      text: row.portal_name
    }));

  const handleChange = value => {
    const bank = banks.find(row => row.id === value);
    let startingCheckNumber = 1;

    if (bank.checks.length) {
      const asArray = bank.checks.map(check => {
        return check.number;
      });

      startingCheckNumber = Math.max.apply(Math, asArray) + 1;
    }

    setBank(value);
    setStartingCheckNumber(startingCheckNumber);
  };

  return (
    <Form>
      <Form.Group widths="equal">
        <Form.Field>
          <label>Bank</label>
          <Dropdown
            selection
            search
            options={options}
            placeholder={"Select a bank"}
            onChange={(e, { value }) => handleChange(value)}
            value={bank}
            selectOnBlur={false}
            fluid
          />
        </Form.Field>
        <Form.Field>
          <label>Starting Check Number</label>
          <Input
            type={"number"}
            label={"#"}
            placeholder={"000000"}
            value={startingCheckNumber}
            onChange={(e, { value }) => setStartingCheckNumber(value)}
            fluid
          />
        </Form.Field>
      </Form.Group>
    </Form>
  );
};
