import React, { Component } from "react";
import PropTypes from "prop-types";

import AjaxComponent from "components/AjaxComponent";

export default class MembershipLoader extends Component {
  static propTypes = {
    children: PropTypes.func.isRequired,
    membershipID: PropTypes.string
  };

  componentDidUpdate(prevProps) {
    if (prevProps.membershipID !== this.props.membershipID) {
      if (this.props.membershipID) {
        this.reload();
      }
    }
  }

  reload = () => {
    if (this.ref) {
      this.ref.cancel();
      this.ref.reload();
    }
  };

  render() {
    const { membershipID, children } = this.props;

    const config = {
      ajaxConfig: {
        url: "/apis/portal/memberships",
        data: {
          action: "read_one",
          data: {
            ID: membershipID
          }
        }
      },
      autoload: !!membershipID,
      ref: r => (this.ref = r),
      stateful: false
    };

    return (
      <AjaxComponent {...config}>
        {({ data, loading, error }) => {
          if (!data) {
            return children({
              data,
              // this is required to prevent a bug causing this to render before ajaxcomponent has a chance
              // to rerender with a loading state
              loading: !!membershipID,
              error
            });
          } else {
            return children({ data, loading, error });
          }
        }}
      </AjaxComponent>
    );
  }
}
