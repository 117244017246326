import React, { Fragment, useState } from "react";
import ReactDOM from "react-dom";
import PropTypes from "prop-types";
import styled from "styled-components";
import { connect } from "react-redux";
import { set } from "redux/Storage/actions";

import { Divider } from "semantic-ui-react";

import BackgroundComponent from "./components/BackgroundComponent";
import ButtonElement from "./components/ButtonElement";
import Content from "./components/Content";
import DrawerElement from "./components/DrawerElement";
import DropdownElement from "./components/DropdownElement";
import Element from "./components/Element";
import HeaderComponent from "./components/HeaderComponent";
import ItemElement from "./components/ItemElement";
import NavElement from "./components/NavElement";
import ToggleComponent from "./components/ToggleComponent";
import useOnMountEffect from "../../effects/useOnMountEffect";

export const Context = React.createContext();

const FocusBar = props => {
  const [state, setState] = useState({
    node: false,
    // if an expanded prop isn't present, use the defaul from localstorage
    expanded:
      typeof props.expanded === "undefined"
        ? props.focusBarOpen
        : props.expanded,
    expandable:
      typeof props.expandable === "undefined" ? true : props.expandable
  });

  /**
   * Updates mounted to true once the component mounts so the children never render
   * before the <div id="focus-bar" /> does
   */
  useOnMountEffect(() => {
    if (!state.node) {
      const node = document.getElementById("focus-bar");
      if (!node) throw '<div id="focus-bar" /> must be mounted';

      setState({ ...state, node });
    }
  });

  /**
   * toggles the focusbar opened/closed
   */
  const toggleExpanded = () => {
    // update in localstorage so it persists
    props.set("focusBarOpen", !state.expanded);
    setState({ ...state, expanded: !state.expanded });
  };

  if (state.node) {
    const { children } = props;
    const context = {
      state: { ...state },
      functions: {
        toggleExpanded: toggleExpanded
      }
    };

    return ReactDOM.createPortal(
      <Context.Provider value={context}>
        <StyledWrapper>
          <div id="drawer-display" />
          <BackgroundComponent>
            <HeaderComponent />
            {React.Children.map(children, (child, key) => {
              if (child) {
                return (
                  <Fragment key={key}>
                    {key !== 0 && <Divider />}
                    {React.cloneElement(child)}
                  </Fragment>
                );
              }

              return null;
            })}
            <ToggleComponent />
          </BackgroundComponent>
        </StyledWrapper>
      </Context.Provider>,
      state.node
    );
  }

  return null;
};

FocusBar.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  expandable: PropTypes.bool, // default true
  expanded: PropTypes.bool, // default true
  focusBarOpen: PropTypes.bool.isRequired,
  set: PropTypes.func.isRequired
};

// containers
FocusBar.Actions = Content;
FocusBar.Button = ButtonElement;
FocusBar.Content = props => <Content flex {...props} />;
FocusBar.Divider = Divider;
FocusBar.Drawer = DrawerElement;
FocusBar.Dropdown = DropdownElement;
FocusBar.Generic = Element;
FocusBar.Item = ItemElement;
FocusBar.Nav = NavElement;

const mapStateToProps = state => ({
  focusBarOpen: state.Storage.focusBarOpen
});

const mapDispatchToProps = {
  set
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FocusBar);

const StyledWrapper = styled.div`
  height: 100%;
  position: relative;
  background-color: var(--portalBlack);

  a,
  button,
  h1,
  h2,
  h3,
  p {
    color: var(--offWhite) !important;
    font-family: "Open Sans", Arial, Helvetica, sans-serif;
  }
`;
