const initialState = {
  bank: undefined,
  created: false,
  data: [{ AdminOnly: false, Amount: "0.00", Memo: "", PayTo: "" }],
  loading: false,
  saving: false,
  startingCheckNumber: undefined
};

export default (state = initialState, action) => {
  switch (action.type) {
    case "CW_ADD_CHECK":
      return {
        ...state
      };
    default:
      return state;
  }
};
