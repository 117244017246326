import React from "react";

import { Segment, Header, Grid, Form, Message } from "semantic-ui-react";

import AsyncForm from "components/AsyncForm";
import { countries } from "fixtures/countries";
import Activate from "./components/Activate";
import props from "../props.js";

const Summary = props => {
  const {
    guestData: { data },
    reloadGuest
  } = props;

  const config = {
    reload: reloadGuest,
    failure: window.toastr.error,
    dataFn: (source, value) => {
      return {
        url: "/apis/portal/guests",
        data: {
          action: "update",
          data: {
            ID: data.id,
            [source]: typeof value === "boolean" ? (value ? 1 : 0) : value
          }
        }
      };
    }
  };

  if (data.country === "") {
    data.country = "none";
  }

  if (data.country === "USA") {
    data.country = "us";
  }

  return (
    <>
      <Header
        as="h1"
        dividing
        icon="folder outline"
        content={data.firstname + " " + data.lastname}
        subheader="View or edit general guest info"
      />
      {!data.active && (
        <Message error>
          <Message.Header>Guest Inactive</Message.Header>
          This guest is marked as inactive. They will not be available for use
          for bookings. If this is a mistake, you can set them to active in the
          "actions" section below.
        </Message>
      )}
      <Segment attached="top">
        <AsyncForm {...config}>
          {({ functions }) => {
            return (
              <Grid columns="3" stackable>
                <Grid.Row>
                  <Grid.Column>
                    <Header icon="user" content="Personal" />
                    {functions.make({
                      element: <Form.Input />,
                      field: "firstname",
                      value: data.firstname,
                      debounce: 500,
                      props: {
                        label: "First name",
                        placeholder: "First name"
                      }
                    })}
                    {functions.make({
                      element: <Form.Input />,
                      field: "lastname",
                      value: data.lastname,
                      debounce: 500,
                      props: {
                        label: "Last name",
                        placeholder: "Last name"
                      }
                    })}
                  </Grid.Column>
                  <Grid.Column>
                    <Header icon="map" content="Address" />
                    {functions.make({
                      element: <Form.Input />,
                      field: "address",
                      value: data.address,
                      debounce: 500,
                      props: {
                        label: "Street Address",
                        placeholder: "1234 Example St."
                      }
                    })}
                    {functions.make({
                      element: <Form.Input />,
                      field: "city",
                      value: data.city,
                      debounce: 500,
                      props: {
                        label: "City",
                        placeholder: "City"
                      }
                    })}
                    {functions.make({
                      element: <Form.Input />,
                      field: "state",
                      value: data.state,
                      debounce: 500,
                      props: {
                        label: "State",
                        placeholder: "State"
                      }
                    })}
                    {functions.make({
                      element: <Form.Input />,
                      field: "zipcode",
                      value: data.zipcode,
                      debounce: 500,
                      props: {
                        label: "Zipcode",
                        placeholder: "Zipcode"
                      }
                    })}
                    {functions.make({
                      element: <Form.Dropdown />,
                      field: "country",
                      value: data.country,
                      props: {
                        label: "Country",
                        name: "not-a-country-chrome",
                        search: true,
                        selection: true,
                        lazyLoad: true,
                        selectOnBlur: false,
                        options: allCountries
                      }
                    })}
                  </Grid.Column>
                  <Grid.Column>
                    <Header icon="mail" content="Contact" />
                    {functions.make({
                      element: <Form.Input />,
                      field: "email",
                      value: data.email,
                      debounce: 500,
                      props: {
                        label: "Email Address",
                        placeholder: "email@address.com"
                      }
                    })}
                    {functions.make({
                      element: <Form.Input />,
                      field: "phone",
                      value: data.phone,
                      debounce: 500,
                      props: {
                        label: "Primary Phone",
                        placeholder: "(xxx) xxx-xxxx"
                      }
                    })}
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column width={16}>{functions.message()}</Grid.Column>
                </Grid.Row>
              </Grid>
            );
          }}
        </AsyncForm>
      </Segment>
      <Segment attached="bottom">
        <Header
          icon="settings"
          content="Actions"
          subheader="Perform actions for this guest"
          as="h3"
        />
        <p>Use the button below to activate or deactivate this guest.</p>
        <Activate guest={data} reloadGuest={reloadGuest} />
      </Segment>
    </>
  );
};

Summary.propTypes = { ...props };

export default Summary;

const none = {
  key: "none",
  value: "none",
  text: "None"
};

const allCountries = [none].concat(
  countries.map(c => ({
    key: c[0],
    value: c[0],
    flag: c[1],
    text: c[2]
  }))
);
