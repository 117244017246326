import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { Form, Header, Input, Grid } from "semantic-ui-react";

import AsyncForm from "components/AsyncForm";
import AjaxDropdown from "components/AjaxDropdown";
import NullableDropdown from "components/NullableDropdown";
import BedTypes from "./components/BedTypes";
import { user_can } from "utils/user";

export class Summary extends Component {
  static propTypes = {
    user: PropTypes.object.isRequired,
    unit: PropTypes.object.isRequired,
    reload: PropTypes.func.isRequired
  };

  render() {
    const config = {
      reload: this.props.reload,
      failure: window.toastr.error,
      dataFn: (source, value) => ({
        url: "/apis/portal/units",
        data: {
          action: "update",
          data: {
            ID: this.props.unit.id,
            [source]: typeof value === "boolean" ? (value ? 1 : 0) : value
          }
        }
      })
    };

    const canEdit = user_can("UNITS_ACTIVE", this.props.user);

    return (
      <div>
        <Header dividing>
          <Header.Content>Summary</Header.Content>
        </Header>
        <AsyncForm {...config}>
          {({ functions }) => {
            return (
              <Fragment>
                <Form.Group widths="equal">
                  <Form.Field>
                    <label>Resort</label>
                    {functions.make({
                      element: <AjaxDropdown />,
                      field: "resort_id",
                      value: this.props.unit.resort.id,
                      props: {
                        // AjaxDropdown specific props
                        url: "/apis/portal/resorts",
                        data: { action: "read_for_dropdown" },
                        dataRetreiver: ({ message: resorts }) => {
                          return resorts.map(resort => ({
                            value: resort.id,
                            text: resort.resort_company.name + " " + resort.name
                          }));
                        },
                        // Dropdown specific props
                        placeholder: "Select one..."
                      }
                    })}
                  </Form.Field>
                  {functions.make({
                    element: <Form.Input />,
                    field: "name",
                    value: this.props.unit.name,
                    debounce: 500,
                    props: {
                      label: "Unit name"
                    }
                  })}
                </Form.Group>
                <Form.Group widths="equal">
                  <Form.Field>
                    <label>Unit Status</label>
                    {canEdit ? (
                      functions.make({
                        element: <NullableDropdown />,
                        field: "active",
                        value: this.props.unit.active,
                        props: {
                          label: "Active",
                          placeholder: "Select one...",
                          width: 6,
                          selection: true,
                          selectOnBlur: false,
                          options: [
                            { value: true, text: "Active" },
                            { value: false, text: "Inactive" }
                          ]
                        }
                      })
                    ) : (
                      <Input
                        readOnly
                        icon="lock"
                        value={this.props.unit.active ? "Active" : "Inactive"}
                      />
                    )}
                  </Form.Field>
                  {functions.make({
                    element: <Form.Dropdown />,
                    field: "unit_type",
                    value: this.props.unit.unit_type,
                    props: {
                      label: "Listing type",
                      placeholder: "Select one...",
                      selection: true,
                      selectOnBlur: false,
                      options: [{ value: "ciirus", text: "CiiRUS" }]
                    }
                  })}
                </Form.Group>
                <Grid columns={2}>
                  <Grid.Column>
                    <Form.Group widths="equal">
                      {functions.make({
                        element: <Form.Dropdown />,
                        field: "bedrooms",
                        value: this.props.unit.bedrooms,
                        props: {
                          label: "Bedrooms",
                          placeholder: "Select one...",
                          selection: true,
                          selectOnBlur: false,
                          options: [
                            { value: "0.0", text: "None" },
                            { value: "1.0", text: "1" },
                            { value: "2.0", text: "2" },
                            { value: "3.0", text: "3" },
                            { value: "4.0", text: "4" },
                            { value: "5.0", text: "5" },
                            { value: "6.0", text: "6" },
                            { value: "7.0", text: "7" },
                            { value: "8.0", text: "8" }
                          ]
                        }
                      })}
                      <Form.Field>
                        <label>Occupancy</label>
                        {functions.make({
                          element: <NullableDropdown />,
                          field: "max_occupancy",
                          value: this.props.unit.max_occupancy,
                          props: {
                            label: "Occupancy",
                            placeholder: "Select one...",
                            selection: true,
                            selectOnBlur: false,
                            width: 4,
                            options: [
                              { value: 0, text: "0" },
                              { value: 2, text: "2" },
                              { value: 4, text: "4" },
                              { value: 6, text: "6" },
                              { value: 8, text: "8" },
                              { value: 10, text: "10" },
                              { value: 12, text: "12" },
                              { value: 14, text: "14" },
                              { value: 16, text: "16" },
                              { value: 18, text: "18" }
                            ]
                          }
                        })}
                      </Form.Field>
                    </Form.Group>
                    <Form.Group widths="equal">
                      {functions.make({
                        element: <Form.Dropdown />,
                        field: "bathrooms",
                        value: this.props.unit.bathrooms,
                        props: {
                          label: "Bathrooms",
                          placeholder: "Select one...",
                          selection: true,
                          selectOnBlur: false,
                          options: [
                            { value: "0.0", text: "None" },
                            { value: "1.0", text: "1" },
                            { value: "2.0", text: "2" },
                            { value: "3.0", text: "3" },
                            { value: "4.0", text: "4" },
                            { value: "5.0", text: "5" }
                          ]
                        }
                      })}
                      <Form.Field>
                        <label>Kitchen Type</label>
                        {functions.make({
                          element: <NullableDropdown />,
                          field: "kitchen_type",
                          value: this.props.unit.kitchen_type,
                          props: {
                            placeholder: "Select one...",
                            selection: true,
                            selectOnBlur: false,
                            options: [
                              {
                                value: 0,
                                text: "Mini"
                              },
                              {
                                value: 1,
                                text: "Partial"
                              },
                              {
                                value: 2,
                                text: "Full"
                              }
                            ]
                          }
                        })}
                      </Form.Field>
                    </Form.Group>
                    <Form.Field>
                      <label>Area (sq. feet)</label>
                      {functions.make({
                        element: <Input />,
                        field: "sq_footage",
                        value: this.props.unit.sq_footage,
                        debounce: 500,
                        props: {
                          placeholder: "Square Footage"
                        }
                      })}
                    </Form.Field>
                    {functions.make({
                      element: <BedTypes />,
                      field: "bed_types",
                      value: this.props.unit.bed_types,
                      debounce: 500,
                      props: {
                        // ...
                      }
                    })}
                  </Grid.Column>
                  <Grid.Column>
                    {functions.make({
                      element: <Form.TextArea />,
                      field: "features",
                      value: this.props.unit.features,
                      debounce: 500,
                      props: {
                        label: "Unit description",
                        placeholder: "Enter unit description...",
                        rows: 27,
                        loading: undefined
                      }
                    })}
                  </Grid.Column>
                </Grid>
                {functions.message()}
              </Fragment>
            );
          }}
        </AsyncForm>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  user: state.User
});

const mapDispatchToProps = {
  // ...
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Summary);
