import React, { Component } from "react";
import styled from "styled-components";
import enhanceWithClickOutside from "react-click-outside";
import DropdownComponent from "../DropdownComponent";

class DropdownElement extends Component {
  state = {
    open: false
  };

  handleClickOutside() {
    const { autoclose = true } = this.props;

    if (autoclose) {
      this.setState({ open: false });
    }
  }

  render() {
    const {
      children,
      content = "More...",
      autoclose = true,
      ...restOfProps
    } = this.props;
    const { open } = this.state;

    const buttonProps = {
      basic: true,
      content,
      fluid: true,
      inverted: true,
      open,
      toggle: () => this.setState({ open: !open }),
      ...restOfProps
    };

    return (
      <div>
        <DropdownComponent {...buttonProps} />
        {open && <DropdownContents>{children}</DropdownContents>}
      </div>
    );
  }
}

export default enhanceWithClickOutside(DropdownElement);

const DropdownContents = styled.div`
  width: 100%;
  background-color: rgba(0, 0, 0, 0.25);
  padding: 0.5rem 0rem;
`;
