import React from "react";
import ContextBar from "components/ContextBar";
import { Breadcrumb } from "semantic-ui-react";
import { Link } from "react-router-dom";

export default () => (
  <ContextBar>
    <Breadcrumb>
      <Breadcrumb.Section>
        <Link to={"/"}>Portal</Link>
      </Breadcrumb.Section>
      <Breadcrumb.Divider>/</Breadcrumb.Divider>
      <Breadcrumb.Section>
        <Link to={"/browse/checks"}>Checks</Link>
      </Breadcrumb.Section>
      <Breadcrumb.Divider>/</Breadcrumb.Divider>
      <Breadcrumb.Section active>View Check</Breadcrumb.Section>
    </Breadcrumb>
  </ContextBar>
);

export const LoadedBreadcrumb = ({ check }) => (
  <ContextBar>
    <Breadcrumb>
      <Breadcrumb.Section>
        <Link to={"/"}>Portal</Link>
      </Breadcrumb.Section>
      <Breadcrumb.Divider>/</Breadcrumb.Divider>
      <Breadcrumb.Section>
        <Link to={"/browse/checks"}>Checks</Link>
      </Breadcrumb.Section>
      <Breadcrumb.Divider>/</Breadcrumb.Divider>
      <Breadcrumb.Section active>
        View Check for {check.pay_to}
      </Breadcrumb.Section>
    </Breadcrumb>
  </ContextBar>
);
