import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import io from "socket.io-client";
import { Button, Label } from "semantic-ui-react";

export default class Subscription extends Component {
  static propTypes = {
    room: PropTypes.string.isRequired,
    event: PropTypes.string.isRequired,
    destroy: PropTypes.func.isRequired,
    update: PropTypes.func.isRequired
  };

  state = {
    lastMessage: "",
    socket: false
  };

  componentDidMount() {
    const { event, room, namespace } = this.props;
    const socket = io.connect(":1337");
    socket.emit("rooms", {
      action: "join",
      data: {
        room
      }
    });
    socket.on(event, data => {
      this.setState({ lastMessage: JSON.stringify(data) });
    });
    this.setState({ socket });
  }

  componentWillUnmount() {
    this.state.socket.off();
  }

  render() {
    const { destroy, room, event } = this.props;
    const { lastMessage } = this.state;
    return (
      <Fragment>
        <div style={{ display: "flex" }}>
          <div style={{ flexGrow: 1 }}>
            {room && (
              <Label image color="blue">
                {room}
                <Label.Detail>Room</Label.Detail>
              </Label>
            )}
            {event && (
              <Label image color="teal">
                {event}
                <Label.Detail>Event</Label.Detail>
              </Label>
            )}
          </div>
          <div>
            <Button
              size="mini"
              basic
              content="Destroy"
              color="red"
              onClick={destroy}
            />
          </div>
        </div>
        <p>{lastMessage || "No response"}</p>
        {/* <Button
          basic
          content="Destroy"
          color="red"
          icon="minus"
          onClick={destroy}
        /> */}
      </Fragment>
    );
  }
}
