import React from "react";
import { connect } from "react-redux";
import injectSheet from "react-jss";
import { Table, Icon } from "semantic-ui-react";
import { setOrder } from "redux/PortalTables/actions";
/**
 *	@prop namespace
 */
class HeaderCell extends React.Component {
  /**
   *	Dispatch a reorder
   *  @param {Integer} index
   */
  reorder = () => {
    const { index, tables, col, namespace } = this.props;
    const { order } = tables[namespace];
    // Assuming that we're only allowed to use one order for now.
    const curr = order.length ? order[0] : [[false, false]];
    let opposite = curr[1] === "asc" ? "desc" : "asc";
    let newOrder = curr[0] === index ? [[index, opposite]] : [[index, "asc"]];
    this.props.setOrder(namespace, newOrder);
  };

  render() {
    const { classes, col, tables, index, namespace } = this.props;
    const { order } = tables[namespace];
    let [upClass, upColor, downClass, downColor] = [
      classes.inactive,
      "black",
      classes.inactive,
      "black"
    ];

    if (order) {
      order.forEach(ord => {
        if (ord[0] === index) {
          if (ord[1] === "asc") {
            upClass = "";
            upColor = "blue";
          } else if (ord[1] === "desc") {
            downClass = "";
            downColor = "blue";
          }
        }
      });
    }

    return (
      <Table.HeaderCell className={classes.header}>
        {col.orderable !== false ? (
          <a href={null} onClick={this.reorder}>
            <div className={classes.left}>{col.name}</div>
            {/* <div className={classes.right}>
              <div className={classes.container}>
                <div className={classes.up}>
                  <Icon name={"caret up"} className={upClass} color={upColor} />
                </div>
                <div className={classes.down}>
                  <Icon
                    name={"caret down"}
                    className={downClass}
                    color={downColor}
                  />
                </div>
              </div>
            </div> */}
          </a>
        ) : (
          col.name
        )}
      </Table.HeaderCell>
    );
  }
}

const styles = {
  left: {
    width: "calc(100% - 10px)",
    float: "left"
  },
  right: {
    width: "10px",
    float: "right"
  },
  header: {
    "& a": {
      color: "#2a2b2b"
    }
  },

  container: {
    position: "absolute"
  },
  up: {
    position: "relative",
    top: "-10px",
    right: "0"
  },
  down: {
    position: "relative",
    bottom: "8px",
    right: "0"
  },
  active: {
    opacity: "1 !important"
  },
  inactive: {
    opacity: ".25 !important"
  }
};

const mapStateToProps = state => ({
  tables: state.PortalTables
  // ...
});
const mapDispatchToProps = dispatch => ({
  setOrder: (namespace, newOrder) => dispatch(setOrder(namespace, newOrder))
  // ...
});

const connected = connect(
  mapStateToProps,
  mapDispatchToProps
)(HeaderCell);
export default injectSheet(styles)(connected);
