import React, { useState } from "react";

import { Button, Dropdown, Modal } from "semantic-ui-react";

import props from "../../../props.js";
import AjaxComponent from "components/AjaxComponent";
import naturalSort from "utils/naturalSort";
import AjaxFn from "services/AjaxFn";

const Assign = props => {
  const [state, setState] = useState({
    open: false,
    loading: false,
    value: props.resortData.data.assigned_account.id || "nobody"
  });

  /**
   * form change handler
   */
  const handleChange = (undefined, { value }) => setState({ ...state, value });

  /**
   * save handler
   */
  const handleSave = () => {
    setState({ ...state, loading: true });
    AjaxFn({
      url: "/apis/portal/resorts",
      data: {
        action: "update",
        data: {
          ID: props.resortData.data.id,
          assigned_account_id: state.value === "nobody" ? null : state.value
        }
      },
      success: () => {
        window.toastr.success();
        props.reloadResort();
      },
      failure: error => {
        window.toastr.error(error);
        setState({ ...state, loading: false });
      }
    });
  };

  /**
   * open handler
   */
  const handleOpen = () => setState({ ...state, open: true });

  /**
   * close handler, prevents modal closing when open
   */
  const handleClose = () =>
    !state.loading && setState({ ...state, open: false });

  /**
   * return whether or not you can currently click save
   */
  const canSave = () => {
    if (state.loading) return false;

    if (props.resortData.data.assigned_account.id) {
      return state.value !== props.resortData.data.assigned_account.id;
    } else {
      return state.value !== "nobody";
    }
  };

  /**
   * all modal properties
   */
  const modalProps = {
    size: "mini",
    open: state.open,
    onClose: handleClose,
    trigger: (
      <Button
        primary
        basic
        content={
          props.resortData.data.assigned_account.id ? "Change" : "Assign"
        }
        onClick={handleOpen}
      />
    )
  };

  /**
   * ajax configuration to load accounts
   */
  const ajaxConfig = {
    ajaxConfig: {
      url: "/apis/portal/accounts?action=read_for_dropdown"
    }
  };

  return (
    <Modal {...modalProps}>
      <Modal.Header>Assign to User</Modal.Header>
      <Modal.Content>
        <AjaxComponent {...ajaxConfig}>
          {({ data: accountData }) => {
            const accounts = accountData
              .filter(row => !row.disabled)
              .map(row => ({
                value: row.id,
                text: row.firstname + " " + row.lastname
              }))
              .sort((a, b) => naturalSort(a.text, b.text));

            return (
              <>
                <p>Select an account to assign this resort to.</p>
                <Dropdown
                  disabled={state.loading}
                  fluid
                  onChange={handleChange}
                  options={[{ value: "nobody", text: "Nobody" }, ...accounts]}
                  placeholder="Select one..."
                  search
                  selection
                  selectOnBlur={false}
                  value={state.value}
                />
              </>
            );
          }}
        </AjaxComponent>
      </Modal.Content>
      <Modal.Actions>
        <Button
          icon="cancel"
          content="Close"
          onClick={handleClose}
          disabled={state.loading}
        />
        <Button
          icon="save"
          primary
          content="Save"
          onClick={handleSave}
          disabled={!canSave()}
          loading={state.loading}
        />
      </Modal.Actions>
    </Modal>
  );
};

Assign.propTypes = { ...props };

export default Assign;
