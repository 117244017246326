import React, { useState } from "react";
import PropTypes from "prop-types";

import { Button } from "semantic-ui-react";

import AjaxFn from "services/AjaxFn";

const SendMail = props => {
  const [state, setState] = useState({
    loading: false,
    sent: props.data.sent_mail.length
  });

  const submit = () => {
    setState({ ...state, loading: true });
    AjaxFn({
      url: "/apis/portal/memberissues",
      data: {
        action: "sendmail",
        data: {
          ID: props.data.id
        }
      },
      success: () => {
        window.toastr.success();
        setState({ ...state, sent: state.sent + 1, loading: false });
        props.enableClosing();
      },
      failure: error => {
        window.toastr.error(error);
        setState({ ...state, loading: false });
      }
    });
  };

  return (
    <Button
      onClick={submit}
      primary
      basic
      icon={state.sent ? "checkmark" : "mail"}
      content={state.sent + " sent"}
      loading={state.loading}
      disabled={state.loading || !!props.data.closed_on}
    />
  );
};

SendMail.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  enableClosing: PropTypes.func
};

export default SendMail;
