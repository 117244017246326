export const trimObject = obj => {
  for (var k in obj) {
    if (typeof obj[k] === "object" && obj[k] !== null) {
      trimObject(obj[k]);
      if (!Object.keys(obj[k]).length) {
        delete obj[k];
      }
    } else {
      if (obj[k] === undefined) {
        delete obj[k];
      }
    }
  }
};

export const setInObject = (obj, paths, value) => {
  let schema = obj; // a moving reference to internal objects within obj
  let len = paths.length;
  for (let i = 0; i < len - 1; i++) {
    let elem = paths[i];
    if (!schema[elem]) schema[elem] = {};
    schema = schema[elem];
  }
  schema[paths[len - 1]] = value;
};

export const findInObject = (datastore, paths) => {
  let current = datastore;
  paths.forEach(path => {
    if (typeof current === "undefined") {
      current = undefined;
    } else {
      if (typeof current[path] === "undefined") {
        current = undefined;
      } else {
        current = current[path];
      }
    }
  });
  return current;
};

export const isObjectEmpty = obj => {
  for (let key in obj) {
    if (obj.hasOwnProperty(key)) return false;
  }
  return true;
};
