import React, { Component } from "react";
import PropTypes from "prop-types";
import { Form } from "semantic-ui-react";
import styled from "styled-components";

import Config from "./components/Config";
import calculate from "./components/Config/components/Calculate";
import Contract from "./components/Contract";
import Legal from "./components/Legal";
import Schedule from "./components/Schedule";
import Submit from "./components/Submit";

import { Wrapper } from "components/Agreement/context";
import { finance } from "components/Agreement/fixtures/defaults";
import CompletionModal from "components/Agreement/components/CompletionModal";
import SubmittedMessage from "components/Agreement/components/SubmittedMessage";

import { isObjectEmpty } from "utils/objects";
import { Print, PageBreak } from "components/Printing";

class FinanceAgreement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: { ...finance }
    };
  }

  static propTypes = {
    context: PropTypes.shape({
      agreement: PropTypes.object,
      completionModalOpen: PropTypes.bool,
      config: PropTypes.object,
      data: PropTypes.object.isRequired,
      functions: PropTypes.object.isRequired,
      key: PropTypes.string,
      submitting: PropTypes.bool,
      type: PropTypes.string.isRequired
    }).isRequired
  };
  static defaultProps = {
    context: {
      completionModalOpen: false,
      config: {},
      key: "",
      submitting: false
    }
  };

  componentDidMount = () => {};
  componentDidUpdate = (prevProps, prevState) => {
    const { context } = this.props;

    const prevData = prevProps.context.data;
    const currData = this.props.context.data;

    if (isObjectEmpty(prevData) && prevData !== currData) {
      this.setState({ data: { ...currData } }, () => {
        if (context.type === "preview") this.handlePreviewData();
      });
    }
  };
  componentWillUnmount = () => {};

  /**
   * Handles setting context data when type is preview
   */
  handlePreviewData = async () => {
    const { context } = this.props;
    const {
      functions: { setData }
    } = context;

    const data = {
      base_amount: "4995",
      down_payment: "200",
      interest_rate: "20.99",
      agreement_term: "30",
      loan_start_date: context.data.loan_start_date
    };

    await setData("base_amount", data.base_amount);
    await setData("down_payment", data.down_payment);
    await setData("interest_rate", data.interest_rate);
    await setData("agreement_term", data.agreement_term);
    await setData("loan_start_date", data.loan_start_date);

    const calc = calculate(data);

    await setData("financed_amount", calc.financed_amount);
    await setData("monthly_payment", calc.monthly_payment);
    await setData("total_payment", calc.total_payment);
    await setData("finance_charge", calc.finance_charge);
    await setData("total_sale", calc.total_sale);
    await setData("payments", calc.payments);
  };

  render() {
    const { context } = this.props;
    const {
      agreement: { original_data }
    } = context;

    const received_on = original_data.received_on;

    return (
      <StyledAgreement>
        <Print />
        {context.type === "internal" && !received_on && <Config />}
        {context.type === "external" && received_on && <SubmittedMessage />}
        <Contract />
        <PageBreak />
        <Legal />
        <PageBreak />
        <Schedule />
        {context.type === "external" && !received_on && <Submit />}
        {context.type === "external" && <CompletionModal />}
      </StyledAgreement>
    );
  }
}

export default Wrapper(FinanceAgreement);

const StyledAgreement = styled(Form)`
  p {
    text-align: justify;
    line-height: 1.5;
    margin-bottom: 1rem;
  }

  ul,
  ol {
    margin-bottom: 1rem;
    &.lettered {
      list-style-type: lower-alpha;
    }
    &.empty {
      list-style-type: none;
      padding-left: 20px;
    }
    li {
      margin-bottom: 1rem;
    }
  }
`;
