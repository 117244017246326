import React from "react";
import { Grid, Form, Dropdown } from "semantic-ui-react";
import AjaxDropdown from "components/AjaxDropdown";
import { FiltersModal } from "components/PortalTable/parts";
import ResortCompanyFilter from "components/ResortCompanyFilter";
import naturalSort from "utils/naturalSort";
import { getAvailabilityUpdated } from "utils/units";

const checkCompanies = (row, companies) => {
  if (companies[row.resort.resort_company.id] === true) {
    return true;
  } else if (companies[row.resort.resort_company.id] === false) {
    return false;
  } else {
    return true;
  }
};

export default () => (
  <FiltersModal
    namespace={"units"}
    filters={{
      active: "any",
      companies: {},
      availability_updated: "any",
      availability_frozen: "any",
      agent: "any"
    }}
    buildFilters={applied => {
      const functions = [];
      functions.push(row => checkCompanies(row, applied.companies));
      if (applied.active !== "any") {
        functions.push(row => row.active === applied.active);
      }
      if (applied.availability_updated !== "any") {
        functions.push(
          row => applied.availability_updated === getAvailabilityUpdated(row)
        );
      }
      if (applied.availability_frozen !== "any") {
        functions.push(row => {
          const frozen = !!row.ciirus_units.filter(cu => cu.freeze_availability)
            .length;
          return frozen === applied.availability_frozen;
        });
      }
      if (applied.agent !== "any") {
        functions.push(row => row.resort.assigned_account.id === applied.agent);
      }
      return functions;
    }}
    children={(applied, update) => (
      <Form>
        <Grid columns={2}>
          <Grid.Column>
            <Form.Field>
              <label>Resort Agent</label>
              <AjaxDropdown
                url={"/apis/portal/accounts"}
                data={{ action: "read_for_dropdown" }}
                dataRetreiver={({ message }) =>
                  [
                    {
                      value: "any",
                      text: "Anybody"
                    }
                  ].concat(
                    message
                      .map(row => ({
                        value: row.id,
                        text: row.firstname + " " + row.lastname,
                        disabled: row.disabled
                      }))
                      .sort((a, b) => naturalSort(a.text, b.text))
                      .filter(row => !row.disabled)
                  )
                }
                search
                selection
                value={applied.agent}
                onChange={(e, { value }) => update("agent", value)}
              />
            </Form.Field>
            <Form.Field>
              <label>Active</label>
              <Dropdown
                selection
                onChange={(e, { value }) => update("active", value)}
                value={applied.active}
                options={[
                  { value: true, text: "Active" },
                  { value: false, text: "Inactive" },
                  { value: "any", text: "Any" }
                ]}
              />
            </Form.Field>
            <Form.Field>
              <label>Availability Status</label>
              <Dropdown
                selection
                onChange={(e, { value }) =>
                  update("availability_updated", value)
                }
                value={applied.availability_updated}
                options={[
                  { value: "any", text: "Any" },
                  { value: "outdated", text: "Outdated" },
                  { value: "updated", text: "Updated" },
                  { value: "unlisted", text: "No listings" },
                  { value: "frozen", text: "Frozen" }
                ]}
              />
            </Form.Field>
            <Form.Field>
              <label>Units w/ Frozen Availability</label>
              <Dropdown
                selection
                onChange={(e, { value }) =>
                  update("availability_frozen", value)
                }
                value={applied.availability_frozen}
                options={[
                  { value: true, text: "Show Only With Frozen" },
                  { value: false, text: "Hide All With Frozen" },
                  { value: "any", text: "Any" }
                ]}
              />
            </Form.Field>
          </Grid.Column>
          <Grid.Column>
            <Form.Field>
              <label>Resort Company Visibility</label>
              <ResortCompanyFilter
                applied={applied.companies}
                set={(id, val) =>
                  update("companies", {
                    ...applied.companies,
                    [id]: val
                  })
                }
              />
            </Form.Field>
          </Grid.Column>
        </Grid>
      </Form>
    )}
  />
);
