import React, { useState, useEffect } from "react";
import styled, {
  __DO_NOT_USE_OR_YOU_WILL_BE_HAUNTED_BY_SPOOKY_GHOSTS
} from "styled-components";
import theme from "styled-theming";
import moment from "moment";
import { useSpring, animated, config } from "react-spring";

import { portalBlack, portalBlue, offWhite } from "theme/colors";

const Header = ({ name }) => {
  const [greeting, setGreeting] = useState();
  const getGreetingTime = () => {
    const afternoon = 12;
    const evening = 17;
    const current = moment().format("HH");

    if (current >= afternoon && current <= evening) return "Afternoon";
    if (current >= evening) return "Evening";
    return "Morning";
  };

  useEffect(() => {
    setGreeting(getGreetingTime());
  });

  const props = useSpring({
    to: async (next, cancel) => {
      await next({ transform: "translateY(0)", opacity: 1 });
    },
    from: { transform: "translateY(-100%)", opacity: 0 },
    config: config.gentle
  });

  return (
    <StyledHeader>
      <animated.h1 style={props}>
        Good {greeting}, {name}
      </animated.h1>
    </StyledHeader>
  );
};

const backgroundColor = theme("mode", {
  light: portalBlue,
  dark: portalBlack
});

const StyledHeader = styled.header`
  display: flex;
  align-items: center;
  justify-content: center;

  width: calc(100% + 3rem);
  height: 100%;
  max-height: 200px;

  margin: -1.5rem -1.5rem 1.5rem;
  padding: 0 1.5rem;

  background-color: ${backgroundColor};

  h1 {
    color: ${offWhite};
    font-weight: 600;
    font-size: 2rem;
    text-align: center;
  }
`;

export default Header;
