import React from "react";
import { Container, Header } from "semantic-ui-react";
import Layout from "components/Layout";
import ContentManager from "components/ContentManager";
import { Ajax } from "services/Ajax";
import Wizard from "./components/Wizard";
import List from "./components/List";
import Editor from "./components/Editor";
import Delete from "./components/Delete";
import Breadcrumb from "./components/Breadcrumb";

export default class Pages extends React.Component {
  shouldComponentUpdate() {
    return false;
  }
  getConfig = ContentManager => ({
    List: List(ContentManager),
    Wizard: {
      JSX: Wizard,
      AJAX: {
        instance: new Ajax({ url: "/apis/portal/pagegroups" }),
        onSuccess: ({ message }) => {
          window.toastr.success();
        },
        data: data => ({
          action: "create",
          data
        })
      }
    },
    Editor: {
      JSX: Editor,
      AJAX: {
        instance: new Ajax({ url: "/apis/portal/pagegroups" }),
        onSuccess: ({ message }) => {
          window.toastr.success();
        },
        data: ({ id: ID }, data) => ({
          action: "update",
          data: { ID, ...data }
        })
      }
    },
    Delete: {
      JSX: Delete,
      AJAX: {
        instance: new Ajax({ url: "/apis/portal/pagegroups" }),
        onSuccess: ({ message }) => {
          window.toastr.success();
        },
        data: ({ id: ID }) => ({
          action: "delete",
          data: { ID }
        })
      }
    }
  });
  /**
   * 	Render fn
   */
  render() {
    return (
      <Layout noBar>
        <Breadcrumb />
        <Container style={{ paddingBottom: "4rem" }}>
          <Header as={"h1"} dividing style={{ marginTop: "2rem" }}>
            Page Groups
            <div className={"sub header"}>
              Below are all of the page groups registered in the database.
            </div>
          </Header>
          <ContentManager config={this.getConfig}>
            {({ components: { List, Editor, Wizard } }) => (
              <React.Fragment>
                {List}
                {Editor}
                {Wizard}
              </React.Fragment>
            )}
          </ContentManager>
          <br />
        </Container>
      </Layout>
    );
  }
}
