import React, { Component } from "react";
import PropTypes from "prop-types";
import WithState from "components/WithState";
import AjaxFn from "services/AjaxFn";

export default class UnitLoader extends Component {
  static propTypes = {
    unitID: PropTypes.string.isRequired,
    children: PropTypes.func.isRequired
  };

  componentDidMount() {
    this.get();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.unitID !== this.props.unitID) {
      this.get();
    }
  }

  componentWillUnmount() {
    this.cancel();
  }

  state = {
    loading: false,
    error: false,
    cancel: false,
    data: {}
  };

  cancel = () => {
    if (typeof this.state.cancel === "function") {
      this.state.cancel();
      this.setState({ cancel: false });
    }
  };

  get = () => {
    this.cancel();
    this.setState({
      loading: true,
      cancel: AjaxFn({
        url: `/apis/portal/units?action=read_one&data[ID]=${this.props.unitID}`,
        success: data => this.setState({ data, error: false, loading: false }),
        failure: error => this.setState({ data: {}, error, loading: false })
      })
    });
  };

  reload = () => {
    this.cancel();
    this.get();
  };

  /**
   * Render
   */
  render() {
    return (
      <WithState loading={this.state.loading} error={this.state.error}>
        {this.state.data.id && this.props.children({ ...this.state })}
      </WithState>
    );
  }
}
