import React from "react";
import { Divider, Button, Form, Input, Dropdown } from "semantic-ui-react";
import { canSave } from "../../validate";
import { connect } from "react-redux";
import bookingSources from "fixtures/booking_sources";
import paymentSources from "fixtures/payment_sources";
import MyCal from "../../../../components/MyCal";
import moment from "moment";
class DetailsStep extends React.Component {
  render() {
    const {
      data,
      setData,
      resetData,
      setStep,
      save,
      units,
      setBookingSource
    } = this.props;
    return (
      <div>
        <p>
          The following information is the minimum required to create a booking.
          You may edit anything on the booking for up to one hour after it has
          been created. After that period, certain fields (such as financials)
          will be locked. You will be redirected to the booking's page after
          saving.
        </p>
        <Form style={{ maxWidth: "400px" }}>
          <Form.Field>
            <label>Check In</label>
            <MyCal
              config={{
                calendars: [
                  {
                    name: "check_in",
                    type: "date",
                    value: data.check_in,
                    onChange: (_, value) => setData("check_in", value)
                  }
                ]
              }}
            />
          </Form.Field>
          <Form.Field>
            <label>Check Out</label>
            <MyCal
              config={{
                calendars: [
                  {
                    name: "check_out",
                    type: "date",
                    value: data.check_out,
                    onChange: (_, value) => setData("check_out", value)
                  }
                ]
              }}
            />
          </Form.Field>
          <Form.Field>
            <label>Booking Source</label>
            <Dropdown
              selection
              options={bookingSources}
              value={data.booking_source}
              onChange={(e, { value }) => setBookingSource(value)}
            />
          </Form.Field>
          <Form.Field>
            <label>Payment Source</label>
            <Dropdown
              selection
              options={paymentSources}
              value={data.payment_source}
              onChange={(e, { value }) => setData("payment_source", value)}
            />
          </Form.Field>
          <Form.Field>
            <label>RCO</label>
            <Input
              value={data.rco}
              onChange={(e, { value }) => setData("rco", value)}
              label={"#"}
              placeholder={"RCO123456789"}
            />
          </Form.Field>
        </Form>
        <Divider />
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Button onClick={() => setStep("unit")} content={"Back"} />
          <div style={{ textAlign: "right" }}>
            <Button onClick={resetData} content={"Reset"} />
            <Button
              primary
              onClick={save}
              disabled={!canSave(data, units)}
              icon={"save"}
              content={"Save"}
            />
          </div>
        </div>
      </div>
    );
  }
}
export default connect(state => ({
  units: state.BookingWizard.units
}))(DetailsStep);
