import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { Modal, Input, Button, Table, Form } from "semantic-ui-react";

import { formatMJY } from "utils/dates";
import { addCommas } from "utils/numbers";
import { countPointsLeftInPointsSet } from "utils/memberships";
import { outOfRange } from "utils/points";
import AjaxFn from "services/AjaxFn";
import { reloadBooking as reload } from "redux/BookingPage/actions";

import AjaxComponent from "components/AjaxComponent";

export class EditPoints extends Component {
  static propTypes = {
    reload: PropTypes.func.isRequired,
    usage: PropTypes.object.isRequired
  };

  state = {
    open: false,
    amount: this.props.usage.amount,
    loading: false
  };

  open = () => this.setState({ open: true });

  close = () => {
    if (!this.state.loading) {
      this.setState({ open: false });
    }
  };

  save = e => {
    e.preventDefault();
    if (this.state.amount < 1) {
      window.toastr.error(
        "More than one point must be allocated. " +
          "If you want to delete this, close the window " +
          "and use the delete button instead."
      );
      return false;
    }

    this.setState({ loading: true });
    AjaxFn({
      url: "/apis/portal/bookingpointusages",
      data: {
        action: "update",
        data: {
          ID: this.props.usage.id,
          amount: this.state.amount
        }
      },
      success: message => {
        window.toastr.success();
        this.setState({ loading: false, open: false });
        this.props.reload();
      },
      failure: error => {
        this.setState({ loading: false });
        window.toastr.error(error);
      }
    });
  };

  render() {
    const { usage } = this.props;
    const { open, amount, loading } = this.state;
    if (!open) {
      return (
        <a href={null} onClick={this.open}>
          edit
        </a>
      );
    }

    const ajaxConfig = {
      url: "/apis/portal/memberships",
      data: {
        action: "read_points_for_booking_page",
        data: {
          ID: usage.point_set.membership.id
        }
      }
    };

    return (
      <Modal open={open} size={"tiny"} onClose={this.close}>
        <Modal.Content>
          <AjaxComponent ajaxConfig={ajaxConfig}>
            {({ data }) => {
              const { points_sets } = data;
              const fn = ({ id }) => id === usage.point_set.id;
              const set = points_sets.find(fn);
              set.name = null;
              return (
                <Fragment>
                  <p>
                    Use the form below to edit this point usage. If you want to
                    change the membership or point set, you have to remove the
                    usage first.
                  </p>
                  <Table>
                    <Table.Header>
                      <Table.Row>
                        <Table.HeaderCell>Point Set</Table.HeaderCell>
                        <Table.HeaderCell>Points Left</Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      <Table.Row>
                        <Table.Cell>
                          {set.name && (
                            <p>
                              <b>{set.name}</b>
                            </p>
                          )}
                          <b>{formatMJY(set.start)}</b>
                          {" to "}
                          <b>{formatMJY(set.end)}</b>
                        </Table.Cell>
                        <Table.Cell>
                          <b>{addCommas(countPointsLeftInPointsSet(set))}</b>
                        </Table.Cell>
                      </Table.Row>
                    </Table.Body>
                  </Table>
                  <br />
                  <Form onSubmit={this.save}>
                    <Form.Input
                      label="New amount of points used"
                      onChange={(e, { value }) =>
                        this.setState({ amount: value })
                      }
                      disabled={loading}
                      value={amount}
                      type={"number"}
                      placeholder={0}
                      min={0}
                      fluid
                    />
                  </Form>
                </Fragment>
              );
            }}
          </AjaxComponent>
        </Modal.Content>
        <Modal.Actions>
          <Button disabled={loading} onClick={this.close} content={"Close"} />
          <Button
            style={{ marginLeft: ".25rem" }}
            disabled={loading}
            loading={loading}
            onClick={this.save}
            content={"Save"}
            color={"blue"}
            icon={"save"}
          />
        </Modal.Actions>
      </Modal>
    );
  }
}

const mapStateToProps = state => ({
  // ...
});

const mapDispatchToProps = {
  reload
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditPoints);
