import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link, withRouter } from "react-router-dom";
import injectSheet from "react-jss";

import { Button, Dimmer, Loader } from "semantic-ui-react";

import Check from "components/Check";
import ProcessButton from "./components/ProcessButton";
import styles from "./styles";
import { chunk } from "utils/arrays";

import AjaxFn from "services/AjaxFn";
import getSocketHandler from "services/sockets";
const socket = getSocketHandler();

class Checks extends Component {
  static propTypes = {
    checks: PropTypes.array.isRequired,
    classes: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired
  };

  state = {
    deletedIDs: [],
    deleting: []
  };

  componentDidMount() {
    socket
      .connect()
      .joinRoom("checks")
      .on("client_check_deleted", data => {
        this.markAsDeleted(Number(data["ID"]), true);
      });
  }

  componentWillUnmount() {
    socket.off();
  }

  /**
   * Pushes the id into state.deletedIDs, updates the URL,
   * and shows an info toast if necessary.
   *
   * @param {Number} id a check ID
   * @param {Bool} showMessage
   */
  markAsDeleted = (id, showMessage = false) => {
    const oldIDs = this.getIDs();
    /**
     * first, we verify that we're actually rendering a check with the passed id.
     * if we aren't, we don't actually have to do anything.
     */
    if (oldIDs.includes(id)) {
      /**
       * first we update deletedIDs in our state. this will cause the
       * check to no longer display as it will be filtered out in our render method.
       */
      this.setState({
        deletedIDs: this.state.deletedIDs.concat(id)
      });
      // if necessary, show a info toast to the user
      if (showMessage) {
        window.toastr.info(
          "A check in this queue has been deleted, so it has been removed from this page."
        );
      }
      // finally, we update the url.
      const newIDs = oldIDs.filter(oldID => oldID !== id);
      this.pushToURL(newIDs);
    }
  };

  /**
   * this function accepts a JSON-encoded URL-encoded array of check IDs,
   * and an id to filter out from that array. it will then update the url to
   * include the filtered array.
   *
   * This is necessary after deleting a check because the server will kick an
   * error if the user refreshes and a deleted check ID is still present.
   * @param {String} oldIDs
   * @param {String} id
   */
  pushToURL = (newIDs, id) => {
    const { history } = this.props;
    /**
     * If any IDs are left after the filter, we can change the URL
     * It's important that we don't redirect, we only visually update the URL
     *
     * If there are no IDs left after the filter, we just redirect the user
     * to the checks list & show an info toast.
     */
    if (newIDs.length) {
      const encodedIDs = encodeURIComponent(JSON.stringify(newIDs));
      history.push(`/browse/checks/${encodedIDs}/print`);
    } else {
      window.toastr.info(
        "You have been redirected away from the print checks page because no checks remain to be printed."
      );
      history.push("/browse/checks");
    }
  };

  /**
   * URL-decodes and JSON-decodes the check IDs from the url
   * Automatically converts a single ID to an array format if only one
   * check is being viewed
   *
   * @return {Array}
   */
  getIDs = () => {
    const { id } = this.props.match.params;
    const ids = JSON.parse(decodeURIComponent(id));

    if (Array.isArray(ids)) {
      return ids;
    } else {
      return [ids];
    }
  };

  handleDeleteClick = id => {
    if (!window.confirm("Are you sure? This can not be undone.")) {
      return false;
    }

    this.setState({
      deleting: this.state.deleting.concat(id)
    });

    AjaxFn({
      url: "/apis/portal/checks",
      data: {
        action: "delete",
        data: {
          token: socket.ignore(),
          ID: id
        }
      },
      success: () => {
        this.setState({
          deleting: this.state.deleting.filter(deletingID => deletingID !== id)
        });
        this.markAsDeleted(id);
      },
      failure: window.toastr.error
    });
  };

  /**
   * Informs the user of print settings to apply and then
   * opens the browsers print modal for them.
   */
  print() {
    const msg =
      "Be sure you set 'margins: none', 'scale: 100' before " +
      "printing the checks, or they may not align properly.";
    if (window.confirm(msg)) {
      window.print();
    }
  }

  /**
   * Returns all check IDs that haven't been processed
   *
   * @return {Array}
   */
  getUnprocessedIDs = () => {
    const { checks = [] } = this.props;

    return checks
      .filter(check => !check.processed) // ..
      .map(check => check.id); // ..
  };

  /**
   * Returns all checks that were passed in through props that haven't
   * since been marked as deleted.
   *
   * @return {Array}
   */
  getRenderedChecks = () => {
    const { checks = [] } = this.props;
    const { deletedIDs } = this.state;

    return checks.filter(check => !deletedIDs.includes(check.id));
  };

  render() {
    const { classes } = this.props;
    const { deleting } = this.state;
    const checks = this.getRenderedChecks();

    return (
      <React.Fragment>
        <div className={classes.warningContainer}>
          <div className={classes.warning}>
            <b>Important</b>
            <br />
            Don't forget to mark the checks as processed after printing.
            Otherwise, they will be automatically deleted from the system. If
            you forget, you can always find the check in the checks list and
            manually do it.
            <br />
            <br />
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div>
                {window.history.length > 1 && (
                  <span style={{ marginRight: ".25rem" }}>
                    <Button
                      basic
                      onClick={() => window.history.back()}
                      content="Go Back"
                    />
                  </span>
                )}
                <span style={{ marginRight: ".25rem" }}>
                  <Link to={"/browse/checks"}>
                    <Button basic content="Checks List" />
                  </Link>
                </span>
              </div>
              <div>
                <span style={{ marginRight: ".25rem" }}>
                  <ProcessButton IDs={this.getUnprocessedIDs()} />
                </span>
                <Button
                  basic
                  primary
                  onClick={this.print}
                  content="Print All"
                />
              </div>
            </div>
          </div>
        </div>
        <div className={classes.background}>
          {chunk(checks, 3).map((chunk, chunkkey) => (
            <div className={classes.checks} key={chunkkey}>
              {chunk.map((check, checkkey) => (
                <div className={classes.checkContainer} key={checkkey}>
                  <div className={classes.checkBackground} />
                  {deleting.indexOf(check.id) !== -1 ? (
                    <Dimmer active inverted>
                      <Loader />
                    </Dimmer>
                  ) : (
                    <Button
                      onClick={() => this.handleDeleteClick(check.id)}
                      basic
                      color="red"
                      content="Delete"
                      style={{ position: "absolute", left: "-1in" }}
                    />
                  )}
                  <Check {...check} />
                </div>
              ))}
            </div>
          ))}
        </div>
      </React.Fragment>
    );
  }
}

const styled = injectSheet(styles)(Checks);
export default withRouter(styled);
