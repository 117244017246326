import React from "react";
import { Dropdown } from "semantic-ui-react";
import naturalSort from "utils/naturalSort";
import { connect } from "react-redux";
class UnitStep extends React.Component {
  render() {
    const { data, setData, units } = this.props;
    const fn = (row) => row.id === data.unit;
    const baseUnit = units.data.find(fn);
    if (baseUnit) {
      switch (baseUnit.unit_type) {
        case "ciirus":
          return (
            <div>
              <p>Choose a Ciirus Listing</p>
              <Dropdown
                selection
                search
                options={baseUnit.ciirus_units
                  .map((row) => ({
                    value: row.id,
                    text: row.name
                  }))
                  .sort((a, b) => naturalSort(a.text, b.text))}
                value={data.subUnit}
                onChange={(e, { value }) => setData("subUnit", value)}
                placeholder={"Select a ciirus listing"}
                style={{ marginBottom: "1rem", width: "400px" }}
              />
            </div>
          );
        default:
          return null;
      }
    } else {
      return null;
    }
  }
}
const mapStateToProps = (state) => ({
  units: state.BookingWizard.units
});
export default connect(mapStateToProps)(UnitStep);
