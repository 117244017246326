import React from "react";
import { Button } from "semantic-ui-react";
import { Wrapper } from "../../../../context";
import AjaxFn from "services/AjaxFn";

class Submit extends React.Component {
  render() {
    const { context } = this.props;
    return (
      <Button
        color={context.agreement.color}
        type="submit"
        content="Submit"
        loading={context.submitting}
        disabled={
          // if submitting, viewing internally, or no id (null when previewing)
          context.submitting || context.internal || !context.agreement.id
        }
        // onClick={this.submit}
      />
    );
  }
}
export default Wrapper(Submit);
