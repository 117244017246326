export default [
  { text: "Jan", month: "01" },
  { text: "Feb", month: "02" },
  { text: "Mar", month: "03" },
  { text: "Apr", month: "04" },
  { text: "May", month: "05" },
  { text: "Jun", month: "06" },
  { text: "Jul", month: "07" },
  { text: "Aug", month: "08" },
  { text: "Sep", month: "09" },
  { text: "Oct", month: "10" },
  { text: "Nov", month: "11" },
  { text: "Dec", month: "12" }
];
