import React from "react";
import {
  Container,
  Header,
  Icon,
  Segment,
  Form,
  Label,
  Dropdown,
  Input,
  Button,
  Divider
} from "semantic-ui-react";
import { Redirect } from "react-router-dom";
import AjaxDropdown from "components/AjaxDropdown";
import Layout from "components/Layout";
import WithState from "components/WithState";
import AjaxFn from "services/AjaxFn";
import Breadcrumb from "./components/Breadcrumb";
import brace from "brace";
import AceEditor from "react-ace";
import NullableDropdown from "components/NullableDropdown";
import "brace/mode/html";
import "brace/snippets/html";
import "brace/theme/monokai";
import "brace/ext/language_tools";
import "brace/ext/searchbox";
import moment from "moment";
import agreementTypes from "fixtures/agreementTypes";

const ace_config = {
  mode: "html",
  theme: "monokai",
  name: "blah2",
  fontSize: 16,
  showPrintMargin: true,
  showGutter: true,
  highlightActiveLine: true,
  setOptions: {
    enableBasicAutocompletion: false,
    enableLiveAutocompletion: true,
    enableSnippets: true,
    showLineNumbers: true,
    tabSize: 2
  },
  style: { width: "100%" }
};

const defaultData = {
  data: {
    color: "black",
    payment_required: false,
    year: moment().format("YYYY"),
    account_company_id: "",
    resort_company_id: "",
    html: "",
    legal: "",
    title: ""
    // ...
  }
};

export default class BookingWizard extends React.Component {
  state = {
    redirecting: false,
    loading: false,
    ...defaultData
  };
  createInput = (element, source, props) => {
    return (
      <Form.Field>
        <label>{source}</label>
        {React.cloneElement(element, {
          value: this.state.data[source] || "",
          onChange: (e, { value }) =>
            this.setState({
              data: {
                ...this.state.data,
                [source]: value
              }
            }),
          placeholder: source,
          fluid: true,
          ...props
        })}
      </Form.Field>
    );
  };
  setData = (key, value) => {
    this.setState({
      data: {
        ...this.state.data,
        [key]: value
      }
    });
  };
  resetData = () => {
    if (window.confirm("Are you sure? All changes will be undone.")) {
      this.setState({
        ...defaultData
      });
    }
  };
  save = () => {
    this.setState({ loading: true });
    const data = this.state.data;
    data.payment_required = data.payment_required ? 1 : 0;
    AjaxFn({
      url: "/apis/portal/agreementtemplates",
      data: {
        action: "wizard",
        data
      },
      success: ({ id }) => {
        this.setState({ redirecting: id });
      },
      failure: error => {
        window.toastr.error(error);
        this.setState({ loading: false });
      }
    });
  };
  render() {
    if (this.state.redirecting) {
      return (
        <Redirect
          push
          to={`/browse/agreementtemplates/${this.state.redirecting}`}
        />
      );
    }
    return (
      <Layout noBar>
        <Breadcrumb />
        <br />
        <br />
        <Container>
          <Header as="h1" dividing>
            <Icon name="wizard" />
            <Header.Content>Agreement Template Wizard</Header.Content>
          </Header>
          <WithState loading={this.state.loading} error={false}>
            <Form>
              <Form.Group widths={"equal"}>
                {this.createInput(<Dropdown />, "color", {
                  options: [
                    { value: "black", text: "Black" },
                    { value: "red", text: "Red" },
                    { value: "orange", text: "Orange" },
                    { value: "yellow", text: "Yellow" },
                    { value: "olive", text: "Olive" },
                    { value: "green", text: "Green" },
                    { value: "teal", text: "Teal" },
                    { value: "blue", text: "Blue" },
                    { value: "purple", text: "Purple" },
                    { value: "violet", text: "Violet" },
                    { value: "pink", text: "Pink" },
                    { value: "brown", text: "Brown" },
                    { value: "grey", text: "Grey" }
                  ],
                  selection: true
                })}
                {this.createInput(<NullableDropdown />, "payment_required", {
                  options: [
                    { value: true, text: "Yes" },
                    { value: false, text: "No" }
                  ],
                  selection: true
                })}
                {this.createInput(<Input />, "year", {
                  type: "number"
                })}
                {this.createInput(<Dropdown />, "type", {
                  options: agreementTypes,
                  selection: true,
                  placeholder: "Select type..."
                })}
              </Form.Group>
              <Form.Group widths={"equal"}>
                {this.createInput(<Input />, "title", {})}
                {this.createInput(
                  <AjaxDropdown
                    {...{
                      url: "/apis/portal/accountcompanies",
                      data: { action: "read_for_dropdown" },
                      dataRetreiver: ({ message: m }) =>
                        m.map(({ id, name }) => ({
                          value: id,
                          text: name
                        }))
                    }}
                  />,
                  "account_company_id",
                  {}
                )}
                {this.createInput(
                  <AjaxDropdown
                    {...{
                      url: "/apis/portal/resortcompanies",
                      data: {
                        action: "read_for_dropdown"
                      },
                      dataRetreiver: ({ message: m }) =>
                        m.map(({ id, name }) => ({
                          value: id,
                          text: name
                        }))
                    }}
                  />,
                  "resort_company_id",
                  {}
                )}
              </Form.Group>
              <Form.Field>
                <label>Flavor Text</label>
                <AceEditor
                  {...ace_config}
                  onChange={value =>
                    this.setState({
                      data: { ...this.state.data, html: value }
                    })
                  }
                  value={this.state.data.html}
                />
              </Form.Field>
              <Form.Field>
                <label>Legal Text</label>
                <AceEditor
                  {...ace_config}
                  onChange={value =>
                    this.setState({
                      data: { ...this.state.data, legal: value }
                    })
                  }
                  value={this.state.data.legal}
                />
              </Form.Field>
            </Form>
            <Divider />
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Button onClick={this.resetData}>Reset</Button>
              <Button primary onClick={this.save}>
                Save
              </Button>
            </div>
          </WithState>
        </Container>
        <br />
        <br />
      </Layout>
    );
  }
}
