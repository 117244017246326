import React from "react";
import {
  Modal,
  Button,
  Icon,
  Table,
  Header,
  Divider,
  Segment,
  Grid,
  Card,
  Feed
} from "semantic-ui-react";
import Comments from "components/Comments";
import CloseIssue from "../CloseIssue";
import { formatMJY } from "utils/dates";
import { BookingIssues, getIssueName } from "fixtures/issues";
export default class Issue extends React.Component {
  state = {
    open: false
  };
  handleOpen = () => this.setState({ open: true });
  handleClose = () => this.setState({ open: false });
  render() {
    const { data, booking } = this.props;
    const trigger = (
      <Table.Row style={{ cursor: "pointer" }} onClick={this.handleOpen}>
        {data.closed_on === null ? (
          <Table.Cell singleLine negative>
            <Header as="h3" textAlign="center">
              Open
            </Header>
          </Table.Cell>
        ) : (
          <Table.Cell singleLine>
            <Header as="h3" textAlign="center">
              Closed
            </Header>
          </Table.Cell>
        )}
        <Table.Cell>
          <b>{getIssueName(data.category, BookingIssues)}</b>
          <br />
          {data.name}
        </Table.Cell>
        <Table.Cell singleLine>
          <b>
            {data.opened_by.firstname} {data.opened_by.lastname}
          </b>
          <br />
          {formatMJY(data.opened_on)}
        </Table.Cell>
        <Table.Cell>
          {data.closed_on !== null && (
            <React.Fragment>
              <b>
                {data.closed_by.firstname} {data.closed_by.lastname}
              </b>
              <br />
              {formatMJY(data.closed_on)}
            </React.Fragment>
          )}
        </Table.Cell>
      </Table.Row>
    );
    return (
      <Modal
        {...{
          open: this.state.open,
          onClose: this.handleClose,
          size: "tiny",
          // size: "large",
          trigger
        }}
      >
        <Modal.Header>
          ({getIssueName(data.category, BookingIssues)}) {data.name}
        </Modal.Header>
        <Modal.Content style={{ background: "#eee" }}>
          {/* <Grid>
            <Grid.Column width={9}>
              <Segment style={{ pointerEvents: "auto" }}>
                <Header as="h2" dividing>
                  <Icon name="comment" />
                  <Header.Content>
                    Issue Comments
                    <Header.Subheader>
                      Leave comments for this issue
                    </Header.Subheader>
                  </Header.Content>
                </Header>
                <Comments thread={data.thread} maxHeight={"300px"} />
              </Segment>
            </Grid.Column>
            <Grid.Column width={7}> */}
          <Card fluid style={{ pointerEvents: "auto" }}>
            <Card.Content>
              <Card.Header>
                {data.closed_on !== null ? (
                  <span>
                    <Icon
                      style={{ marginRight: ".5rem" }}
                      name={"checkmark"}
                      color={"green"}
                    />
                    Resolved Issue
                  </span>
                ) : (
                  <span>
                    <Icon
                      style={{ marginRight: ".5rem" }}
                      name={"exclamation triangle"}
                      color={"red"}
                    />
                    Open Issue
                  </span>
                )}
              </Card.Header>
              <Card.Meta>Details below</Card.Meta>
              <Divider />
              <Feed>
                <Feed.Event>
                  <Feed.Label>
                    <Icon name={"calendar outline"} />
                  </Feed.Label>
                  <Feed.Content>
                    <Feed.Date content={formatMJY(data.opened_on)} />
                    <Feed.Summary>
                      Opened by {data.opened_by.firstname}{" "}
                      {data.opened_by.lastname}
                    </Feed.Summary>
                    <Feed.Extra style={{ whiteSpace: "pre-line" }}>
                      <b>Reason: </b>
                      {data.opened_reason}
                    </Feed.Extra>
                  </Feed.Content>
                </Feed.Event>
                {data.closed_on !== null && (
                  <React.Fragment>
                    <Divider />
                    <Feed.Event>
                      <Feed.Label>
                        <Icon name={"calendar check outline"} />
                      </Feed.Label>
                      <Feed.Content>
                        <Feed.Date content={formatMJY(data.closed_on)} />
                        <Feed.Summary>
                          Closed by {data.closed_by.firstname}{" "}
                          {data.closed_by.lastname}
                        </Feed.Summary>
                        <Feed.Extra style={{ whiteSpace: "pre-line" }}>
                          <b>Reason: </b>
                          {data.closed_reason}
                        </Feed.Extra>
                      </Feed.Content>
                    </Feed.Event>
                  </React.Fragment>
                )}
              </Feed>
            </Card.Content>
            <Card.Content extra>
              {data.closed_on === null &&
                booking._actions.actions.close_issues && (
                  <CloseIssue data={data} reload={this.props.reload} />
                )}
            </Card.Content>
          </Card>
          {/* </Grid.Column>
          </Grid> */}
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={this.handleClose}>Back to Booking</Button>
        </Modal.Actions>
      </Modal>
    );
  }
}
