import React from "react";
import { Step } from "semantic-ui-react";
import {
  validateGuest,
  validateUnit,
  // validateOwner,
  validateDetails
} from "../../validate.js";
import { connect } from "react-redux";
const Steps = ({ step, setStep, data, units }) => {
  const items = [
    {
      key: "guest",
      icon: "address book outline",
      description: "Choose a guest",
      title: "Guest",
      active: step === "guest",
      onClick: () => setStep("guest"),
      completed: validateGuest(data)
    },
    // {
    //   key: "owner",
    //   icon: "id card outline",
    //   description: "Assign an owner",
    //   title: "Owner",
    //   active: step === "owner",
    //   onClick: () => setStep("owner"),
    //   completed: () => validateOwner(data)
    // },
    {
      key: "unit",
      icon: "hotel",
      description: "Select the unit",
      title: "Unit",
      active: step === "unit",
      onClick: () => setStep("unit"),
      completed: validateUnit(data, units)
    },
    {
      key: "details",
      icon: "book",
      title: "Details",
      description: "Enter booking info",
      active: step === "details",
      onClick: () => setStep("details"),
      completed: validateDetails(data)
    }
  ];

  return (
    <div style={{ minWidth: "220px" }}>
      <Step.Group vertical items={items} />
    </div>
  );
};
export default connect(state => ({
  units: state.BookingWizard.units
}))(Steps);
