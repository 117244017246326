import InitialState from "./initial.js";
export default (state = InitialState, action) => {
  switch (action.type) {
    case "USER_SET_CONNECTED_USER":
      return {
        ...state,
        ...action.data,
        timepunching: false
      };
    case "USER_TIMEPUNCH_REQUEST":
      return {
        ...state,
        timepunching: true
      };
    case "USER_TIMEPUNCH_SET_STATUS":
      return {
        ...state,
        clocked_in: action.data
      };
    case "USER_TIMEPUNCH_REQUEST_DONE":
      return {
        ...state,
        timepunching: false
      };
    case "USER_LOAD_PUNCHES":
      return {
        ...state,
        timepunches_for_week: {
          loading: true,
          loaded: true,
          data: [],
          calculated: "Loading"
        }
      };
    case "USER_SET_PUNCHES":
      return {
        ...state,
        timepunches_for_week: {
          loading: false,
          loaded: true,
          data: action.data,
          calculated: "Loading"
        }
      };
    case "USER_SET_CALCULATED":
      return {
        ...state,
        timepunches_for_week: {
          ...state.timepunches_for_week,
          calculated: action.data
        }
      };
    case "USER_UPDATE_TUTORIAL":
      const exists = state.tutorials.find(
        tutorial => tutorial.index === action.data.index
      );
      return {
        ...state,
        tutorials: exists
          ? // if the tutorial already exists, map over and update just that
            state.tutorials.map(tutorial => {
              if (tutorial.index === action.data.index) {
                return action.data;
              }
              return tutorial;
            })
          : // otherwise just concat it in
            state.tutorials.concat(action.data)
      };
    default:
      return state;
  }
};
