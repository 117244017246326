/**
 * This file takes the list of portal accounts that was loaded and
 * calculates the total amount of time each one has been punched in.
 * It also categorizes timepunches within thsoe accounts for an easier
 * to read output.
 */

/**
 *	@param {Array} data
 *  @param {Object} accounts
 *  @return {Array}
 */
export default (data, accounts) => {
  const accountsByID = getAccountsByID(accounts);
  const withAccounts = replaceIDsWithReference(data, accountsByID);
  const organized = organize(withAccounts, accountsByID);
  const withTotals = total(organized);
  return withTotals;
};
/**
 *	Categorize accounts by ID
 *	@param {Array} accounts
 *  @return {Object}
 */
const getAccountsByID = accounts => {
  const accountsByID = {};
  accounts.forEach(account => (accountsByID[account.id] = account));
  return accountsByID;
};
/**
 *	Replaces account ID integers with references to actual account objects
 *	@param {Array} data
 *  @param {Object} accountsByID
 *  @return {Array}
 */
const replaceIDsWithReference = (data, accountsByID) =>
  data.map(timepunch => {
    timepunch.account = accountsByID[timepunch.account.id];
    timepunch.edits = timepunch.edits.map(edit => {
      edit.editedBy = accountsByID[edit.editedBy.id];
      return edit;
    });
    return timepunch;
  });

/**
 *	Categorizes the timepunches by the account they are attached onto
 *	@param {Array} data
 *  @param {Object} accountsByID
 *  @return {Array}
 */
const organize = (data, accountsByID) => {
  const byAccount = {};
  data.map(timepunch => {
    // categorize the timepunches by the account ID they belong to
    if (typeof byAccount[timepunch.account.id] === "undefined") {
      byAccount[timepunch.account.id] = {
        account: timepunch.account,
        timepunches: []
      };
    }
    // store the individual timepunch within that categorized set
    byAccount[timepunch.account.id].timepunches.push(timepunch);
    return null;
  });
  return Object.values(byAccount);
};

/**
 *	Sums the timepunched hours for each entry
 *	@param {Array} data
 *  @return {Array}
 */
const total = data => {
  const now = new Date();
  return data.map(row => {
    row.total = 0;
    row.modified = false;
    row.timepunches
      .filter(row => !row.deleted)
      .forEach(punch => {
        let end_time = punch.out ? new Date(punch.out) : now;
        let start_time = new Date(punch.in);
        row.total += end_time.getTime() - start_time.getTime();
        if (punch.edits.length) {
          row.modified = true;
        }
      });
    return row;
  });
};
