import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { setStartingCheckNumber, setBank } from "redux/EOQReport/actions";
import { Form, Input } from "semantic-ui-react";
import naturalSort from "utils/naturalSort";

export class CheckInfo extends Component {
  static propTypes = {
    setStartingCheckNumber: PropTypes.func.isRequired,
    setBank: PropTypes.func.isRequired,
    banks: PropTypes.array.isRequired,
    bank: PropTypes.oneOfType([
      // number if chosen
      PropTypes.number,
      // string "" if empty
      PropTypes.string
    ]).isRequired,
    startingCheckNumber: PropTypes.oneOfType([
      // number if chosen automatically
      PropTypes.number,
      // string "" if empty or manually entered
      PropTypes.string
    ]).isRequired
  };

  render() {
    const {
      bank,
      banks,
      setBank,
      startingCheckNumber,
      setStartingCheckNumber
    } = this.props;

    return (
      <Form>
        <Form.Dropdown
          label="Bank"
          placeholder="Select one..."
          clearable
          selection
          search
          options={banks
            .filter(row => row.active)
            .sort((a, b) => naturalSort(a.portal_name, b.portal_name))
            .map(row => ({
              value: row.id,
              text: row.portal_name
            }))}
          value={bank}
          onChange={(e, { value }) => setBank(value)}
        />
        <Form.Field>
          <label>Starting Check Number</label>
          <Input
            type="number"
            label="#"
            placeholder="000000"
            value={startingCheckNumber}
            onChange={(e, { value }) => setStartingCheckNumber(value)}
          />
        </Form.Field>
      </Form>
    );
  }
}

const mapStateToProps = state => ({
  banks: state.EOQReport.banks.data,
  bank: state.EOQReport.bank,
  startingCheckNumber: state.EOQReport.startingCheckNumber
});

const mapDispatchToProps = {
  setStartingCheckNumber,
  setBank
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CheckInfo);
