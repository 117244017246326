import moment from "moment";

export default {
  loading: false,
  error: "",
  accounts: [],
  timepunches: [],
  processed: [],
  filters: {
    companies: [],
    disabled: "false",
    modified: "any"
  },
  selected_account: false,
  selected_timepunch: false,
  show_deleted: false,
  date: moment()
};
