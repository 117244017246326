import React from "react";
import styled from "styled-components";

import Counter from "./Counter";
import Puncher from "./Puncher";

import { secondBackgroundColor } from "theme";

const Timepunch = () => {
  return (
    <TimepunchWrapper>
      <Counter />
      <Puncher />
    </TimepunchWrapper>
  );
};

const TimepunchWrapper = styled.div`
  display: inline-grid;
  grid-template-columns: repeat(2, auto);
  grid-gap: 0rem;
  align-items: center;

  padding: 0 0.5rem 0 1rem;
  height: 2rem;

  background-color: ${secondBackgroundColor};
  border-radius: 25px;

  font-weight: bold;
  font-size: 1rem;

  @media screen and (max-width: 884px) {
    grid-template-columns: auto;
    background-color: transparent;
    padding: 0;
    height: auto;
  }
`;

export default Timepunch;
