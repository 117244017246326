import React from "react";
import AjaxDropdown from "components/AjaxDropdown";
export default ({ data, set }) => (
  <div>
    <p>
      Choose the type of account you want to create. You can create new roles or
      manage the permissions for that role from the "Manage Account Roles" page.
      They will show up in this list when you refresh the page.
    </p>
    <p>
      You will be able to change a users role once the account has been created.
    </p>
    <AjaxDropdown
      url={"/apis/portal/accountroles"}
      data={{ action: "read_for_dropdowns" }}
      placeholder={"Account Role"}
      value={data.account_role_id}
      onChange={(e, { value }) => set("account_role_id", value)}
      dataRetreiver={({ message: m }) =>
        m.map(({ id, name }) => ({
          value: id,
          text: name
        }))
      }
    />
  </div>
);
