import React from "react";
import { Breadcrumb } from "semantic-ui-react";
import { Link } from "react-router-dom";
import ContextBar from "components/ContextBar";

export const InitialBar = () => (
  <ContextBar>
    <Breadcrumb>
      <Breadcrumb.Section>
        <Link to={"/"}>Portal</Link>
      </Breadcrumb.Section>
      <Breadcrumb.Divider>/</Breadcrumb.Divider>
      <Breadcrumb.Section>
        <Link to={"/browse/units"}>Units</Link>
      </Breadcrumb.Section>
      <Breadcrumb.Divider>/</Breadcrumb.Divider>
      <Breadcrumb.Section active>Loading...</Breadcrumb.Section>
    </Breadcrumb>
  </ContextBar>
);

export const UnitBar = ({ unit }) => (
  <ContextBar>
    <Breadcrumb>
      <Breadcrumb.Section>
        <Link to={"/"}>Portal</Link>
      </Breadcrumb.Section>
      <Breadcrumb.Divider>/</Breadcrumb.Divider>
      <Breadcrumb.Section>
        <Link to={"/browse/units"}>Units</Link>
      </Breadcrumb.Section>
      <Breadcrumb.Divider>/</Breadcrumb.Divider>
      <Breadcrumb.Section active>
        {unit.resort.resort_company.name +
          " " +
          unit.resort.name +
          " " +
          unit.name}
      </Breadcrumb.Section>
    </Breadcrumb>
  </ContextBar>
);

export const ListingBar = ({ unit, listing }) => (
  <ContextBar>
    <Breadcrumb>
      <Breadcrumb.Section>
        <Link to={"/"}>Portal</Link>
      </Breadcrumb.Section>
      <Breadcrumb.Divider>/</Breadcrumb.Divider>
      <Breadcrumb.Section>
        <Link to={"/browse/units"}>Units</Link>
      </Breadcrumb.Section>
      <Breadcrumb.Divider>/</Breadcrumb.Divider>
      <Breadcrumb.Section>
        <Link to={"/browse/units/" + unit.id}>
          {unit.resort.resort_company.name +
            " " +
            unit.resort.name +
            " " +
            unit.name}
        </Link>
      </Breadcrumb.Section>
      <Breadcrumb.Divider>/</Breadcrumb.Divider>
      <Breadcrumb.Section>Listings</Breadcrumb.Section>
      <Breadcrumb.Divider>/</Breadcrumb.Divider>
      <Breadcrumb.Section active>{listing.name}</Breadcrumb.Section>
    </Breadcrumb>
  </ContextBar>
);
