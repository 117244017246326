import React from "react";
import { connect } from "react-redux";
import { Container, Header } from "semantic-ui-react";
import styled from "styled-components";

import Layout from "components/Layout";
import Breadcrumb from "./components/Breadcrumb";
import Controls from "./components/Controls";
import { SearchBar } from "components/PortalTable/parts";
import FiltersModal from "./components/FiltersModal";
import BookingTable from "./components/BookingTable";
import { instantiate } from "redux/BookingList/actions";
import WithTour from "components/WithTour";
import { bookingListTour } from "fixtures/tutorials";
import config from "./config";

class BookingList extends React.Component {
  componentDidMount() {
    this.props.instantiate(config);
  }
  render() {
    return (
      <Layout noBar>
        <Breadcrumb />
        <br />
        <Container>
          <Header as={"h1"} dividing>
            <Header.Content>
              Bookings
              <Header.Subheader>
                Below are all of the bookings registered in the database.
              </Header.Subheader>
            </Header.Content>
            <div className={"sub header"} />
          </Header>
          {this.props.instantiated === true && (
            <WithTour {...bookingListTour}>
              <Controls />
              <SearchWrapper className="tutorial-booklist-filters">
                <SearchBar namespace={"BookingList"} semantic />
                <FiltersModal />
              </SearchWrapper>
              <BookingTable />
            </WithTour>
          )}
        </Container>
      </Layout>
    );
  }
}

const SearchWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  grid-column-gap: 1rem;
  grid-auto-flow: column;
  width: 100%;
`;

const mapStateToProps = state => ({
  instantiated: typeof state.PortalTables["BookingList"] === "object"
  // ...
});
const mapDispatchToProps = dispatch => ({
  instantiate: config => dispatch(instantiate(config))
  // ...
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BookingList);
