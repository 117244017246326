import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";

import { memberPageTour } from "fixtures/tutorials";
import WithTour from "components/WithTour";
import WithState from "components/WithState";

import { LoadingBreadcrumb } from "../Breadcrumb";
import Sidebar from "../Sidebar";
import MemberBookings from "../MemberBookings";
import MemberComments from "../MemberComments";
import MemberIssues from "../MemberIssues";
import MemberSummary from "../MemberSummary";
import MembershipSummary from "../MembershipSummary";

export default class Renderer extends Component {
  static propTypes = {
    memberID: PropTypes.string.isRequired,
    membershipID: PropTypes.string,
    reloadMember: PropTypes.func.isRequired,
    reloadMembership: PropTypes.func.isRequired,
    memberTab: PropTypes.string,
    membershipTab: PropTypes.string,
    memberData: PropTypes.shape({
      data: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]).isRequired,
      loading: PropTypes.bool.isRequired,
      error: PropTypes.any.isRequired
    }).isRequired,
    membershipData: PropTypes.shape({
      data: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]).isRequired,
      loading: PropTypes.bool.isRequired,
      error: PropTypes.any.isRequired
    }).isRequired
  };

  /**
   * the page's router
   * tries to match a membership route first
   * if it can't find one, it matches a member route
   */
  router = () => {
    const {
      memberData,
      memberTab,
      membershipData,
      membershipTab,
      membershipID,
      reloadMember,
      reloadMembership
    } = this.props;

    const childProps = {
      member: memberData.data,
      membership: membershipData.data,
      reloadMember: reloadMember,
      reloadMembership: reloadMembership
    };

    if (membershipID) {
      // this shouldnt be happneing but it does rarely
      // it doesn't show for more than a frame or two
      // if (!membershipData.data.id) {
      //   return <p>Loading...</p>;
      // }

      switch (membershipTab) {
        default:
          return <MembershipSummary {...childProps} />;
      }
    } else {
      switch (memberTab) {
        case "bookings":
          return <MemberBookings {...childProps} />;
        case "issues":
          return <MemberIssues {...childProps} />;
        case "comments":
          return <MemberComments {...childProps} />;
        default:
          return <MemberSummary {...childProps} />;
      }
    }
  };

  render() {
    const {
      memberData,
      memberTab,
      membershipTab,
      membershipData,
      memberID,
      membershipID
    } = this.props;

    const tourEnabled = !memberData.loading && !membershipData.loading;

    return (
      <WithTour enabled={tourEnabled} {...memberPageTour}>
        <Fragment>
          {memberData.loading ||
            (membershipData.loading && <LoadingBreadcrumb />)}
          <WithState {...memberData}>
            <Sidebar
              memberID={memberID}
              membershipID={membershipID}
              memberData={memberData}
              memberTab={memberTab}
              membershipTab={membershipTab}
              membershipData={membershipData}
            />
            <WithState {...membershipData}>{this.router()}</WithState>
          </WithState>
        </Fragment>
      </WithTour>
    );
  }
}
