import React from "react";
import { Button, Modal, Form, Input, Message } from "semantic-ui-react";
import styled from "styled-components";

import NullableDropdown from "components/NullableDropdown";

export default (ContentManager, Editor) => {
  return (
    <Modal open={ContentManager.state.editorActive} size={"small"}>
      <Modal.Header>Update Bank</Modal.Header>
      <Modal.Content>
        <Form>
          <Form.Group widths={"equal"}>
            <Form.Field>
              <label htmlFor="portalName">Portal Name</label>
              {Editor.functions.createInput(
                <Input id="portalName" fluid />,
                "portal_name"
              )}
            </Form.Field>
            <Form.Field>
              <label htmlFor="active">Active</label>
              {Editor.functions.createInput(
                <NullableDropdown id="active" />,
                "active",
                {
                  fluid: true,
                  selection: true,
                  options: [
                    { value: false, text: "Not Active" },
                    { value: true, text: "Active" }
                  ]
                }
              )}
            </Form.Field>
          </Form.Group>
          <br />

          <Form.Group widths={"equal"}>
            <Form.Field>
              <label htmlFor="bankName">Bank Name</label>
              {Editor.functions.createInput(
                <Input id="bankName" fluid />,
                "bank_name"
              )}
            </Form.Field>
            <Form.Field>
              <label htmlFor="company">Company</label>
              {Editor.functions.createInput(
                <Input id="company" fluid />,
                "company_name"
              )}
            </Form.Field>
          </Form.Group>
          <Form.Group widths={"equal"}>
            <Form.Field>
              <label htmlFor="bankAddress">Bank Address</label>
              {Editor.functions.createInput(
                <Input id="bankAddress" fluid />,
                "bank_address"
              )}
            </Form.Field>
            <Form.Field>
              <label htmlFor="companyAddress">Company Address</label>
              {Editor.functions.createInput(
                <Input id="companyAddress" fluid />,
                "company_address"
              )}
            </Form.Field>
          </Form.Group>
          <Form.Group widths={"equal"}>
            <Form.Field>
              <label htmlFor="bankAddress2">Bank Address 2</label>
              {Editor.functions.createInput(
                <Input id="bankAddress2" fluid />,
                "bank_address_2"
              )}
            </Form.Field>
            <Form.Field>
              <label htmlFor="companyAddress2">Company Address 2</label>
              {Editor.functions.createInput(
                <Input id="companyAddress2" fluid />,
                "company_address_2"
              )}
            </Form.Field>
          </Form.Group>
          <br />

          <Form.Group>
            <Form.Field width={4}>
              <label htmlFor="account">Account</label>
              {Editor.functions.createInput(
                <Input id="account" fluid />,
                "account_number",
                {
                  label: "#",
                  type: "number"
                }
              )}
            </Form.Field>
            <Form.Field width={4}>
              <label htmlFor="routing_number">Routing</label>
              {Editor.functions.createInput(
                <Input id="routing_number" fluid />,
                "routing_number",
                {
                  label: "#",
                  type: "number"
                }
              )}
            </Form.Field>
            <Form.Field width={8}>
              <label htmlFor="signature">Signature</label>
              {Editor.functions.createInput(
                <Input id="signature" fluid />,
                "signature"
              )}
            </Form.Field>
          </Form.Group>
        </Form>
      </Modal.Content>
      <Modal.Content>
        <Message info>
          <Message.Header>Info</Message.Header>
          <p>
            The "Portal Name" is what the check appears as in dropdowns. It will
            not show on the check.
          </p>
          <p>
            Leave the signature field blank to display a "Signature not
            required" message on the check.
          </p>
        </Message>
      </Modal.Content>
      <ModalActions>
        <div>
          <Button
            content="Close"
            onClick={ContentManager.functions.closeEditor}
            basic
          />
          {ContentManager.functions.getDeleteButton()}
        </div>

        {Object.keys(Editor.data).length ? (
          <div>
            <Button
              type={"button"}
              content={"Discard"}
              icon="undo"
              onClick={Editor.functions.reset}
              basic
            />
            <Button
              type={"button"}
              onClick={Editor.functions.save}
              loading={ContentManager.state.editorLoading}
              icon={"save"}
              content={"Save"}
              color={"blue"}
            />
          </div>
        ) : null}
      </ModalActions>
    </Modal>
  );
};

const ModalActions = styled(Modal.Actions)`
  display: flex;
  justify-content: space-between;
  align-items: stretch;

  &&& {
    div {
      &:first-of-type {
        justify-self: flex-start;
      }
    }
    button {
      height: 100%;
      margin: 0 0.25em 0 0.75em;
    }
  }
`;
