import React from "react";
import PropTypes from "prop-types";
import moment from "moment";

import { Popup, Header } from "semantic-ui-react";

import FocusBar from "components/FocusBar";

const getStep = data => {
  if (data.deal_sheet.onboarding_agent.id) return 4;
  else if (data.processed_on) return 3;
  else if (data.received_on) return 2;
  else if (data.sent_on) return 1;
  else return 0;
};

const Steps = props => {
  return null;

  // const { data } = props;
  // const active = getStep(data);
  // const format = "MMMM Do YYYY, h:mm a";

  // const Element = props => {
  //   const isActiveStep = active === props.step
  //   const isDone = active >= props.step
  //   const description = props.content + " on " + moment(props.description).format(format);

  //   if (isDone && !isActiveStep) {
  //     return (
  //       <Popup
  //         content=""
  //       />
  //     )
  //   }

  //   if (active > props.step) {
  //     return <FocusBar.Item content={description} icon={props.icon} />;
  //   } else {
  //     return (
  //       <FocusBar.Item
  //         content={active >= props.step ? description : props.content}
  //         icon={props.icon}
  //         color={active === props.step ? "blue" : undefined}
  //         disabled={active < props.step}
  //       />
  //     );
  //   }
  // };

  // return (
  //   <>
  //     <FocusBar.Generic>
  //       <Header inverted as="h3" content="Progress Tracker" />
  //     </FocusBar.Generic>
  //     <Element
  //       content="Started"
  //       icon="fas fa-calendar-day"
  //       description={data.created_on}
  //       step={0}
  //     />
  //     <Element
  //       content="Sent"
  //       icon="fas fa-envelope"
  //       description={data.sent_on}
  //       step={1}
  //     />
  //     <Element
  //       content="Submitted"
  //       icon="fas fa-inbox"
  //       description={data.received_on}
  //       step={2}
  //     />
  //     <Element
  //       content="Processed"
  //       icon="fas fa-fax"
  //       description={data.processed_on}
  //       step={3}
  //     />
  //     <Element
  //       content="Onboarded"
  //       icon="fas fa-sign-out-alt"
  //       description=""
  //       step={4}
  //     />
  //   </>
  // );
};

Steps.propTypes = {
  data: PropTypes.object.isRequired
};

export default Steps;
