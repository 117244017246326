import React from "react";
import { Header } from "semantic-ui-react";
import { connect } from "react-redux";
const BreadcrumbComponent = ({ unit }) => (
  <Header as={"h1"} dividing>
    {"Unit Availability"}
    <Header.Subheader>
      {unit.resort.resort_company.name + " "}
      {unit.resort.name + " "}
      {unit.name}
    </Header.Subheader>
  </Header>
);
export default connect(state => ({
  unit: state.Calendar.unit
}))(BreadcrumbComponent);
