import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import ListFn from "./components/List";
import Breadcrumb from "./components/Breadcrumb";

import Layout from "components/Layout";
import PortalTable from "components/PortalTable";
import WithTour from "components/WithTour";
import { memberListTour } from "fixtures/tutorials";

import { Container, Header } from "semantic-ui-react";

export class MemberList extends Component {
  static propTypes = {
    tables: PropTypes.object.isRequired
  };

  state = {
    firstLoadComplete: false
  };

  /**
   * We can't initialize the tutorial until the table loads, otherwise the
   * dimmer looks really weird.
   *
   * First check if the PortalTable is initialized, and when it is, check that it
   * is done loading.
   */
  componentDidUpdate() {
    const { firstLoadComplete } = this.state;
    const { members } = this.props.tables;

    if (typeof members === "object" && !firstLoadComplete) {
      if (!members.loading && !members.error) {
        // we need to give the dom a sec to render
        setTimeout(() => {
          this.setState({ firstLoadComplete: true });
        }, 200);
      }
    }
  }

  render() {
    return (
      <Layout noBar>
        <Breadcrumb />
        <Container style={{ paddingBottom: "4rem" }}>
          <WithTour {...memberListTour} enabled={this.state.firstLoadComplete}>
            <Header as={"h1"} dividing style={{ marginTop: "2rem" }}>
              Members
              <div className={"sub header"}>
                Below are all of the members registered in the database.
              </div>
            </Header>
            <div className="tutorial-memberlist-main">
              <PortalTable {...ListFn("members")} />
            </div>
          </WithTour>
        </Container>
      </Layout>
    );
  }
}

const mapStateToProps = state => ({
  tables: state.PortalTables
});

export default connect(mapStateToProps)(MemberList);
