import React from "react";
import { Dropdown, Icon } from "semantic-ui-react";
import AjaxFn from "services/AjaxFn";
/**
 *	@prop userID
 *
 */
export default class LogMeIn extends React.Component {
  state = {
    loading: false
  };
  handleClick = e => {
    e.stopPropagation();
    if (window.confirm("Are you sure? This will log you in to the account.")) {
      this.setState({ loading: true });
      AjaxFn({
        url: "/apis/portal/sessions",
        data: { action: "log_me_in", data: { ID: this.props.userID } },
        success: () => window.location.replace("/"),
        failure: e => {
          this.setState({ loading: false });
          window.toastr.error(e);
        }
      });
    }
  };
  render() {
    const { loading } = this.state;
    return (
      <Dropdown.Item disabled={loading} onClick={this.handleClick}>
        <Icon
          name={loading ? "notched circle" : "arrow right"}
          loading={loading}
        />
        Log Me In
      </Dropdown.Item>
    );
  }
}
