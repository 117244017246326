import React from "react";
import { Segment, Header, Grid, Icon, Form, Message } from "semantic-ui-react";
import { Wrapper } from "../../../../context";
import levels from "fixtures/membershipLevels";

const MembershipForm = ({ context }) => (
  <Segment vertical>
    <Grid stackable>
      <Grid.Column width={5}>
        <Header as={"h3"}>
          <Icon name={"id badge"} />
          Membership
        </Header>
        <p>
          Your membership info is required so that we may use your points for
          you.
        </p>
        <p>
          The "Points Allocated" fields are both required. If you do not want to
          allocate any points for the shown year, enter a zero.
        </p>
      </Grid.Column>
      <Grid.Column width={11}>
        <Form.Group widths={"equal"}>
          {context.functions.createInput(<Form.Input />, "account_number", {
            name: "account_number",
            label: "Account Number",
            placeholder: "Account Number",
            required: true,
            autoComplete: "off"
          })}
          {context.functions.createInput(<Form.Input />, "annual_earned", {
            name: "annual_earned",
            label: "Annual Points Earned",
            placeholder: "Annual Points Earned",
            required: true,
            autoComplete: "off"
          })}
          {context.functions.createInput(<Form.Dropdown />, "vip_status", {
            name: "vip_status",
            label: "VIP Status/Level",
            placeholder: "VIP Status/Level",
            required: true,
            autoComplete: "off",
            options: levels.filter(r => r.value !== "Unspecified"),
            selection: true
          })}
        </Form.Group>
        <Form.Group widths={"equal"}>
          {context.functions.createInput(<Form.Input />, "points_alloc_curr", {
            name: "points_alloc_curr",
            label: `${context.agreement.year} points`,
            placeholder: `${context.agreement.year} points`,
            required: true,
            autoComplete: "off",
            type: "number"
          })}
          {context.functions.createInput(<Form.Input />, "points_alloc_next", {
            name: "points_alloc_next",
            label: `${Number(context.agreement.year) + 1} points`,
            placeholder: `${Number(context.agreement.year) + 1} points`,
            required: true,
            autoComplete: "off",
            type: "number"
          })}
          {context.functions.createInput(<Form.Input />, "points_expiration", {
            name: "points_expiration",
            label: "Points Expiration",
            placeholder: "Points Expiration",
            required: true,
            autoComplete: "off",
            type: "date",
            icon: "calendar outline"
          })}
        </Form.Group>
        <Form.Group widths={"equal"}>
          {context.functions.createInput(<Form.Input />, "username", {
            name: "username",
            label: "Username",
            placeholder: "Username",
            required: true,
            autoComplete: "off"
          })}
          {context.functions.createInput(<Form.Input />, "password", {
            name: "password",
            label: "Password",
            placeholder: "Password",
            required: true,
            autoComplete: "off",
            icon: "lock"
          })}
        </Form.Group>
        <Message attached={"top"}>
          <Message.Header>
            <Icon name={"exclamation triangle"} />
            IMPORTANT
          </Message.Header>
          <p>
            Do any other companies or individuals use your account or have
            access to it?
          </p>
        </Message>
        <Segment attached={"bottom"}>
          <p>
            Failure to notify us of any outside activity in your account may
            cause:
          </p>
          <ul>
            <li>Reservation cancellations</li>
            <li>Not enough points being available</li>
            <li>Confusion and/or undue stress for guests</li>
          </ul>
          {context.functions.createInput(<Form.Dropdown />, "shared", {
            name: "shared",
            label: "Is your account shared?",
            placeholder: "Select one...",
            required: true,
            autoComplete: "off",
            options: [
              { value: "no", text: "No" },
              { value: "yes", text: "Yes" },
              { value: "unsure", text: "I don't know" }
            ],
            selection: true,
            fluid: false,
            style: {
              maxWidth: "200px"
            }
          })}
        </Segment>
      </Grid.Column>
    </Grid>
  </Segment>
);
export default Wrapper(MembershipForm);
