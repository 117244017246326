import React, { Component } from "react";
import { Header, Icon, Label } from "semantic-ui-react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

export default class Heading extends Component {
  static propTypes = {
    unit: PropTypes.object.isRequired
  };

  render() {
    const { unit } = this.props;
    return (
      <div>
        <Header as="h1">
          <Header.Content>
            {unit.resort.resort_company.name +
              " " +
              unit.resort.name +
              " " +
              unit.name}
            <Header.Subheader>
              {"* Name generated from: "}
              <Link
                to={"/browse/resortcompanies/" + unit.resort.resort_company.id}
                children={unit.resort.resort_company.name}
              />
              {", "}
              <Link
                to={"/browse/resorts/" + unit.resort.id}
                children={unit.resort.name}
              />
            </Header.Subheader>
          </Header.Content>
        </Header>
        <div>
          {unit.active ? (
            <Label size={"large"}>
              <Icon color={"green"} name={"checkmark"} />
              Active
            </Label>
          ) : (
            <Label size={"large"}>
              <Icon color={"red"} name={"cancel"} />
              Not Active
            </Label>
          )}
          <Label size={"large"}>
            <Icon name={"book"} />
            {unit.bookings.length} Total Booking(s)
          </Label>
        </div>
        <br />
      </div>
    );
  }
}
