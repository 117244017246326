import React from "react";
import styled from "styled-components";

import AjaxFn from "services/AjaxFn";

import { ThemeNavAction, ThemePopup, ThemeButton } from "theme/components";

import {
  backgroundColor,
  backgroundColorHover,
  borderColor,
  fontColor,
  defaultButtonBackground,
  defaultButtonFontColor,
  defaultButtonBorderColor,
  defaultButtonBackgroundHover
} from "theme";

const handleUserLogout = () => {
  AjaxFn({
    url: "/apis/portal/sessions",
    data: {
      action: "logout"
    },
    success: () => window.location.reload(),
    failure: e => window.toastr.error(e)
  });
};

const UserProfile = () => {
  return (
    <ThemePopup
      trigger={
        <ThemeNavAction large>
          <i className="fas fa-user-circle" />
        </ThemeNavAction>
      }
      content={
        <div style={{ width: "150px" }}>
          <Header>Account</Header>
          <ThemeButton
            fluid
            basic
            content="Logout"
            icon="sign-out alternate"
            onClick={handleUserLogout}
          />
        </div>
      }
      on="click"
      position="bottom center"
      basic
    />
  );
};

const Header = styled.div`
  font-size: 1rem;
  font-weight: 600;
  padding-bottom: 0.5rem;
  margin-bottom: 1rem;
  border-bottom: 1px solid ${borderColor};
`;

const StyledOptions = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;

  font-size: 1.5rem;
  padding: 0;

  height: 24px;
  width: 24px;
  border-radius: 50%;

  border: none;
  background-color: transparent;
  cursor: pointer;

  transition: background-color ease-in-out 150ms;

  &:hover,
  &:focus {
    background-color: ${backgroundColorHover};
  }
`;

export default UserProfile;
