import React from "react";
import { Button, Icon } from "semantic-ui-react";
export default ContentManager => ({
  tableName: "accountRoles",
  url: "/apis/portal/accountroles",
  data: {
    action: "read"
  },
  tableData: {
    show: 250,
    order: [[1, "asc"]],
    paginationPosition: "bottom",
    showFooter: false,
    menu: () => [
      "[search]",
      "[show]",
      "[refresh]",
      <Button
        icon
        color={"blue"}
        title={"New"}
        onClick={ContentManager.functions.openWizard}
        children={<Icon name={"plus"} />}
      />
    ],
    cols: [
      {
        name: "",
        src: "id",
        render: (src, row) =>
          !row.mutable ? null : (
            <a onClick={() => ContentManager.functions.openEditor(row)}>
              <Icon name={"external"} />
            </a>
          ),
        orderable: false,
        searchable: false,
        props: row => {
          const props = {
            textAlign: "center",
            width: 1
          };
          if (row.mutable) {
            props.selectable = true;
          }
          return props;
        }
      },
      {
        name: "Name",
        src: "name"
      },
      {
        name: "Restricted",
        src: "restricted",
        render: src =>
          src ? (
            <React.Fragment>
              <Icon name={"checkmark"} color={"green"} />
              Yes
            </React.Fragment>
          ) : (
            <React.Fragment>
              <Icon name={"cancel"} color={"red"} />
              No
            </React.Fragment>
          ),
        props: row => {
          const props = {};
          if (!row.restricted) {
            props.negative = true;
          }
          return props;
        }
      },
      {
        name: "Editable",
        src: "mutable",
        render: src =>
          src ? (
            <React.Fragment>
              <Icon name={"checkmark"} color={"green"} />
              Yes
            </React.Fragment>
          ) : (
            <React.Fragment>
              <Icon name={"cancel"} color={"red"} />
              No
            </React.Fragment>
          ),
        props: row => {
          const props = {};
          if (!row.mutable) {
            props.negative = true;
          }
          return props;
        }
      }
    ]
  }
});
