import React from "react";
import { connect } from "react-redux";
import { Container } from "semantic-ui-react";
import Layout from "components/Layout";
import WithState from "components/WithState";
import CalendarApp from "./CalendarApp";
import { getUnit, resetSynced } from "redux/Calendar/actions";
import Breadcrumb from "./components/Breadcrumb";
class CalendarPage extends React.Component {
  componentDidMount() {
    const unitID = this.props.match.params.id;
    this.props.dispatch(resetSynced());
    this.props.dispatch(getUnit(unitID, "reload"));
  }
  render() {
    const { loading, error } = this.props;
    if (loading || error) {
      return (
        <Layout noBar>
          <Breadcrumb unitID={this.props.match.params.id} />
          <Container>
            <WithState loading={loading} error={error} />
          </Container>
        </Layout>
      );
    }
    return (
      <Layout noBar>
        <Breadcrumb unitID={this.props.match.params.id} />
        <br />
        <Container>
          <CalendarApp />
        </Container>
      </Layout>
    );
  }
}
export default connect(state => ({
  loading: state.Calendar.unitLoading,
  error: state.Calendar.unitError
}))(CalendarPage);
