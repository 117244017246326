import React, { useState, useEffect } from "react";
import styled from "styled-components";
import theme from "styled-theming";

import { portalBlack, offWhite } from "theme/colors";

import {
  Admin,
  Accounting,
  Booking,
  CustomerService,
  Developer,
  Reception
} from "./components/Departments";

import { user_can } from "utils/user";

const AvailableResources = ({ user }) => {
  if (user_can("EM_RES_ADMIN", user)) return <Admin />;
  if (user_can("EM_RES_ACCOUNTING", user)) return <Accounting />;
  if (user_can("EM_RES_BOOKDEP", user)) return <Booking />;
  if (user_can("EM_RES_CS", user)) return <CustomerService />;
  if (user_can("EM_RES_DEVELOPER", user)) return <Developer />;
  if (user_can("EM_RES_RECEPTION", user)) return <Reception />;
  return (
    <NoResources>
      <div className="content">
        <i className="fas fa-globe" />
        <p>
          <strong>There are no resources available at this time.</strong>
        </p>
      </div>
    </NoResources>
  );
};

const Resources = ({ user }) => {
  const [resourcesEmpty, setResourcesEmpty] = useState(true);

  useEffect(() => {
    user_can("EM_RES_ADMIN", user) ||
    user_can("EM_RES_ACCOUNTING", user) ||
    user_can("EM_RES_BOOKDEP", user) ||
    user_can("EM_RES_CS", user) ||
    user_can("EM_RES_DEVELOPER", user) ||
    user_can("EM_RES_RECEPTION", user)
      ? setResourcesEmpty(false)
      : setResourcesEmpty(true);
  });

  return (
    <Wrapper empty={resourcesEmpty}>
      <AvailableResources user={user} />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: grid;
  grid-gap: 0.5rem;
  justify-content: ${({ empty }) => (empty ? "center" : "stretch")};
  align-items: ${({ empty }) => (empty ? "center" : "flex-start")};
  height: ${({ empty }) => (empty ? "100%" : null)};

  &&& {
    a {
      text-align: left;
    }
  }
`;

const iconColor = theme("mode", {
  light: portalBlack,
  dark: offWhite
});

const NoResources = styled.div`
  display: flex;
  align-items: center;
  text-align: center;

  i {
    font-size: 3rem;
    margin-bottom: 1rem;
    color: ${iconColor};
  }
`;

export default Resources;
