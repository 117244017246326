import React from "react";
import { Header } from "semantic-ui-react";
import Layout from "components/Layout";
import ContentManager from "components/ContentManager";
import { Ajax } from "services/Ajax";
import ListFn from "./components/List";
import Wizard from "./components/Wizard";
import Editor from "./components/Editor";
import { connect } from "react-redux";
import Delete from "./components/Delete";
import Breadcrumb from "./components/Breadcrumb";
class BanksList extends React.Component {
  getConfig = ContentManager => {
    return {
      List: ListFn({ ContentManager }),
      Wizard: {
        JSX: (CM, W) => <Wizard ContentManager={CM} Wizard={W} />,
        AJAX: {
          instance: new Ajax({ url: "/apis/portal/banks" }),
          onSuccess: ({ message }) => {
            window.toastr.success();
          },
          data: data => {
            data.active = 1;
            if (!data.signature) {
              data.signature = null;
            }
            return { action: "create", data };
          }
        }
      },
      Editor: {
        JSX: Editor,
        AJAX: {
          instance: new Ajax({ url: "/apis/portal/banks" }),
          onSuccess: ({ message }) => {
            window.toastr.success();
          },
          data: ({ id: ID }, data) => {
            if (typeof data.active !== "undefined") {
              data.active = data.active ? 1 : 0;
            }
            return { action: "update", data: { ID, ...data } };
          }
        }
      },
      Delete: {
        JSX: Delete,
        AJAX: {
          instance: new Ajax({ url: "/apis/portal/banks" }),
          onSuccess: ({ message }) => {
            window.toastr.success();
          },
          data: ({ id: ID }) => ({ action: "delete", data: { ID } })
        }
      }
    };
  };
  /**
   * 	Render fn
   */
  render() {
    return (
      <Layout noBar>
        <Breadcrumb />
        <div
          style={{
            margin: "0 auto",
            maxWidth: "1200px",
            paddingBottom: "4rem"
          }}
        >
          {/* <Container style={{ paddingBottom: "4rem" }}> */}
          <Header as={"h1"} dividing style={{ marginTop: "2rem" }}>
            Banks
            <div className={"sub header"}>
              Below are all of the banks registered in the database.
            </div>
          </Header>
          <ContentManager config={this.getConfig}>
            {({ components: { List, Editor, Wizard } }) => (
              <React.Fragment>
                {List}
                {Editor}
                {Wizard}
              </React.Fragment>
            )}
          </ContentManager>
          <br />
          {/* </Container> */}
        </div>
      </Layout>
    );
  }
}
export default connect(undefined)(BanksList);
