import React from "react";
import { Modal, Icon, Header } from "semantic-ui-react";
import { Wrapper } from "../../context";
import styled from "styled-components";

class CompletionModal extends React.Component {
  render() {
    const { context } = this.props;
    return (
      <Modal open={context.completionModalOpen} size="mini">
        <Modal.Content>
          <Header as="h2" icon textAlign="center">
            <Icon name="checkmark" color={"green"} circular />
            <Header.Content>Success</Header.Content>
          </Header>
          <p>
            Our server said we received your agreement. You can view it at any
            time with this secure link:
          </p>
          <AgreementLink>
            <span>
              <a href={window.location.href}>{window.location.href}</a>
            </span>
          </AgreementLink>
          <p>
            If you were required to enter it, any payment information will
            automatically be removed within three days. If you wish to preserve
            a copy with payment information intact, save the page to your
            computer or print a copy of it.
          </p>
        </Modal.Content>
      </Modal>
    );
  }
}

const AgreementLink = styled.div`
  margin-bottom: 1rem;
  padding: 0.5rem;
  background: #eee;
  border: 1px solid #ddd;

  span {
    display: block;
    word-wrap: break-word !important;
  }
`;

const Wrapped = Wrapper(CompletionModal);
export default Wrapped;
