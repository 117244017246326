/**
 * Returns company info, may change based on the date of the agreement's creation
 * @param {String} name
 * @param {String} dateCreated
 * @returns {Object}
 */
export const specificCompany = (name, dateCreated) => {
  const address = {
    ballwin: {
      full: "15933 Clayton Road #139\n Ballwin, MO 63011",
      city: "Ballwin",
      state: {
        full: "Missouri",
        short: "MO",
      },
      zipcode: "63011",
      street: "15933 Clayton Road #139",
    },
    orlando: {
      full: "7208 Sand Lake Rd, Suite #303, Orlando, FL 32819",
      city: "Orlando",
      state: {
        full: "Florida",
        short: "FL",
      },
      zipcode: "32819",
      street: "7208 Sand Lake Rd, Suite #303",
    },
  };

  // our address changed this day
  const today = dateCreated ? new Date(dateCreated) : new Date();
  if (today >= new Date("2020-12-09 00:00:00")) {
    address.ballwin = {
      full: "174 Plaza Drive, Wildwood MO 63040",
      city: "Ballwin",
      state: {
        full: "Missouri",
        short: "MO",
      },
      zipcode: "63040",
      street: "174 Plaza Drive",
    };
  }

  switch (name.toLowerCase()) {
    case "elite rewards":
      return address.orlando;
    case "timeshare selling team":
    case "member perks":
    case "default company":
    case "premier rewards":
    case "VMG Resorts":
    default:
      return address.ballwin;
  }
};
