import React from "react";
import { Segment, Grid } from "semantic-ui-react";
import injectSheet from "react-jss";
import Details from "./components/Details";
import Guest from "./components/Guest";
import Unit from "./components/Unit";
import Agents from "./components/Agents";
import Owner from "./components/Owner";
const styles = {
  headerContent: {
    paddingTop: "1rem",
    paddingBottom: "1.25rem",
    minHeight: "75px"
  }
};
class Summary extends React.Component {
  createInput = (el, src, props) => {
    return React.cloneElement(el, {
      value: this.props.data[src],
      ...props
    });
  };
  render() {
    return (
      <div>
        <Details {...this.props} />
        <br />
        <Grid columns={2}>
          <Grid.Column>
            <Guest {...this.props} />
            <Agents {...this.props} />
          </Grid.Column>
          <Grid.Column>
            <Unit {...this.props} />
            <Owner {...this.props} />
          </Grid.Column>
        </Grid>
      </div>
    );
  }
}
export default injectSheet(styles)(Summary);
