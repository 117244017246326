import React from "react";
import {
  Button,
  Modal,
  Form,
  Input,
  Checkbox,
  TextArea,
  Message
} from "semantic-ui-react";
import styled from "styled-components";

export default (ContentManager, Wizard) => {
  return (
    <Modal open={ContentManager.state.wizardActive} size={"tiny"}>
      <Modal.Header>Create</Modal.Header>
      <Modal.Content>
        <Form>
          <Form.Field>
            <label>Name</label>
            {Wizard.functions.createInput(<Input />, "name")}
          </Form.Field>
          <Form.Field>
            <label>Description</label>
            {Wizard.functions.createInput(<TextArea />, "description")}
          </Form.Field>
          <Form.Group widths={"equal"}>
            <Form.Field>
              <label>Restricted</label>
              {Wizard.functions.createCheckbox(<Checkbox />, "restricted", {
                toggle: true
              })}
            </Form.Field>
          </Form.Group>
          <Form.Field>
            <Message warning visible>
              <Message.Header>Warning</Message.Header>
              <p>
                Default permissions and page settings can be altered once the
                role has been created (if the role is not restricted).
              </p>
            </Message>
          </Form.Field>
        </Form>
      </Modal.Content>
      <ModalActions>
        <Button
          content="Close"
          basic
          onClick={ContentManager.functions.closeWizard}
        />
        {Object.keys(Wizard.data).length ? (
          <div>
            <Button
              type="button"
              content="Discard"
              icon="undo"
              basic
              onClick={Wizard.functions.reset}
            />
            <Button
              type="button"
              onClick={Wizard.functions.save}
              loading={ContentManager.state.wizardLoading}
              icon="save"
              content="Save"
              color="blue"
            />
          </div>
        ) : null}
      </ModalActions>
    </Modal>
  );
};

const ModalActions = styled(Modal.Actions)`
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  button {
    height: 100%;
    &:first-of-type {
      justify-self: flex-start;
    }
  }
  &&& {
    div {
      button {
        margin: 0 0.25em 0 0.75em;
      }
    }
  }
`;
