import React from "react";
import { connect } from "react-redux";
import injectSheet from "react-jss";
import { Table } from "semantic-ui-react";
import HeaderCell from "./components/HeaderCell";
/**
 *	@prop namespace
 */
class TableHeader extends React.Component {
  render() {
    const { namespace, tables } = this.props;
    const { cols } = tables[namespace];
    return (
      <Table.Header>
        <Table.Row>
          {cols
            .filter(col => col.visible !== false)
            .map((col, index) => (
              <HeaderCell
                col={col}
                key={index}
                index={index}
                namespace={namespace}
              />
            ))}
        </Table.Row>
      </Table.Header>
    );
  }
}

const styles = {
  // ...
};

const mapStateToProps = state => ({
  tables: state.PortalTables
  // ...
});
const mapDispatchToProps = dispatch => ({
  // ...
});

const connected = connect(
  mapStateToProps,
  mapDispatchToProps
)(TableHeader);
export default injectSheet(styles)(connected);
