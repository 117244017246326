import React from "react";
import { Table } from "semantic-ui-react";
import { addCommas } from "utils/numbers";
import { formatMJY } from "utils/dates";
import IDsCheckBox from "../../../IDsCheckBox";
import injectSheet from "react-jss";
const styles = {
  table: {
    margin: "0px !important",
    borderTop: "none !important",
    borderBottom: "none !important"
  }
};
class ModalContent extends React.Component {
  link = (e, url) => {
    e.preventDefault();
    window.open(url);
  };
  render() {
    const { row_bookings, row, classes } = this.props;
    return (
      <Table striped className={classes.table}>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell textAlign={"center"} width={1}>
              <IDsCheckBox IDs={row[2]} />
            </Table.HeaderCell>
            <Table.HeaderCell>Guest</Table.HeaderCell>
            <Table.HeaderCell>Check-In</Table.HeaderCell>
            <Table.HeaderCell>Payout</Table.HeaderCell>
            <Table.HeaderCell>View</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {row_bookings
            .sort((a, b) => new Date(b.check_in) - new Date(a.check_in))
            .map((booking, key) => {
              const bURL = "/browse/bookings/" + booking.id;
              const gURL = "/browse/guests/" + booking.guest.id;
              return (
                <Table.Row key={key}>
                  <Table.Cell textAlign={"center"}>
                    <IDsCheckBox IDs={[booking.id]} />
                  </Table.Cell>
                  <Table.Cell>
                    <a href={gURL} onClick={e => this.link(e, gURL)}>
                      {booking.guest.firstname + " " + booking.guest.lastname}
                    </a>
                  </Table.Cell>
                  <Table.Cell>{formatMJY(booking.check_in)}</Table.Cell>
                  <Table.Cell>
                    ${addCommas(booking.calculated.owner_pay, 2)}
                  </Table.Cell>
                  <Table.Cell>
                    <a href={bURL} onClick={e => this.link(e, bURL)}>
                      Click to view
                    </a>
                  </Table.Cell>
                </Table.Row>
              );
            })}
        </Table.Body>
      </Table>
    );
  }
}
export default injectSheet(styles)(ModalContent);
