import InitialState from "./initial.js";

export default (state = InitialState, action) => {
  switch (action.type) {
    case "SIDEBAR_SET_OPEN":
      return {
        ...state,
        open: true
      };
    case "SIDEBAR_SET_CLOSED":
      return {
        ...state,
        open: false
      };
    case "SIDEBAR_TOGGLE":
      return {
        ...state,
        open: !this.state.open
      };
    case "SIDEBAR_SET_PROPS":
      return {
        ...state,
        props: action.data
      };
    case "SIDEBAR_SET_CONTENT":
      return {
        ...state,
        content: action.data
      };
    case "SIDEBAR_SET_AS":
      return {
        ...state,
        as: action.data
      };
    case "SIDEBAR_LOCK":
      return {
        ...state,
        locked: true
      };
    case "SIDEBAR_UNLOCK":
      return {
        ...state,
        locked: false
      };
    default:
      return state;
  }
};
