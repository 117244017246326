import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { Modal, Button, Icon, Header } from "semantic-ui-react";
// import AniModal from "components/AniModal";
import AjaxFn from "services/AjaxFn";

export default class DeleteButton extends Component {
  static propTypes = {
    unit: PropTypes.object.isRequired,
    reload: PropTypes.func.isRequired
  };

  state = {
    open: false,
    loading: false
  };

  openModal = () => {
    this.setState({
      open: true
    });
  };

  closeModal = () => {
    if (!this.state.loading) {
      this.setState({
        open: false
      });
    }
  };

  /**
   * Sends the ajax request
   * @param {Event}
   */
  sendRequest = () => {
    this.setState({ loading: true });
    AjaxFn({
      url: "/apis/portal/units",
      data: {
        action: "delete",
        data: {
          ID: this.props.unit.id
        }
      },
      success: () => {
        window.toastr.success();
        setTimeout(() => {
          window.location.href = "/browse/units";
        }, 1000);
      },
      failure: error => {
        window.toastr.error(error);
        this.setState({ loading: false });
      }
    });
  };

  render() {
    return (
      <Fragment>
        <Button
          basic
          color="red"
          onClick={this.openModal}
          content="Delete unit"
        />
        <Modal onClose={this.closeModal} open={this.state.open} size="mini">
          <Modal.Header>
            <Header icon="exclamation triangle" content="Delete Unit" />
          </Modal.Header>
          <Modal.Content>
            <p>
              Are you sure you want to delete this unit? This can not be undone.
            </p>
          </Modal.Content>
          <Modal.Actions>
            <Button
              basic
              onClick={this.closeModal}
              disabled={this.state.loading}
              content="No"
            />
            <Button
              color="red"
              onClick={this.sendRequest}
              disabled={this.state.loading}
              loading={this.state.loading}
              content="Yes"
            />
          </Modal.Actions>
        </Modal>
      </Fragment>
    );
  }
}
