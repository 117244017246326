import React, { useContext } from "react";
import { Divider } from "semantic-ui-react";
import { Context } from "../../";
import ButtonElement from "../ButtonElement";
import Content from "../Content";

const ToggleComponent = () => {
  const context = useContext(Context);

  const { expanded, expandable } = context.state;
  const { toggleExpanded } = context.functions;

  if (expandable) {
    return (
      <div>
        <Divider />
        <Content>
          {context.state.expanded ? (
            <ButtonElement
              icon="fas fa-chevron-left"
              content="Collapse"
              onClick={toggleExpanded}
            />
          ) : (
            <ButtonElement
              icon="fas fa-chevron-right"
              content="Expand"
              onClick={toggleExpanded}
            />
          )}
        </Content>
      </div>
    );
  }

  return null;
};

export default ToggleComponent;
