import React from "react";
import { Table } from "semantic-ui-react";
import injectSheet from "react-jss";
import styles from "../../../../../../styles";
import { handleMouseUp, handleMouseEnter } from "redux/Calendar/actions";
import { connect } from "react-redux";
// import { dateToYMD } from "utils/dates";
class OldDay extends React.Component {
  handleMouseUp = () => {
    const { today, dispatch } = this.props;
    dispatch(handleMouseUp(today));
  };
  handleMouseEnter = () => {
    const { today, dispatch, rangeStart } = this.props;
    if (rangeStart) {
      dispatch(handleMouseEnter(today));
    }
  };
  render() {
    const { classes, day } = this.props;
    return (
      <Table.Cell
        className={classes.day + " " + classes.oldDay}
        onMouseUp={this.handleMouseUp}
        onMouseEnter={this.handleMouseEnter}
        children={day.day}
      />
    );
  }
}

const styled = injectSheet(styles)(OldDay);
export default connect((state) => ({
  rangeStart: state.Calendar.rangeStart
}))(styled);
