import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { reload } from "redux/TimepunchManager/actions";

import { Button } from "semantic-ui-react";

import AjaxFn from "services/AjaxFn";

export class DeleteButton extends Component {
  static propTypes = {
    timepunch: PropTypes.object.isRequired,
    locked: PropTypes.bool.isRequired,
    setLocked: PropTypes.func.isRequired
  };

  state = {
    loading: false,
    cancel: false
  };

  componentWillUnmount() {
    this.cancel();
  }

  cancel = () => {
    if (typeof this.state.cancel === "function") {
      this.state.cancel();
    }
  };

  handleClick = () => {
    if (window.confirm("Are you sure? This can not be undone.")) {
      const { timepunch, reload, setLocked } = this.props;
      setLocked(true);
      this.setState({
        loading: true,
        cancel: AjaxFn({
          url: "/apis/portal/timepunches",
          data: {
            action: "delete",
            data: {
              ID: timepunch.id
            }
          },
          success: () => {
            window.toastr.success();
            reload(false);
          },
          failure: error => {
            this.setState({ loading: false });
            setLocked(false);
            window.toastr.error(error);
          }
        })
      });
    }
  };

  render() {
    const { locked, timepunch } = this.props;
    if (timepunch.deleted) {
      return null;
    }
    return (
      <Button
        color="red"
        content="Delete Punch"
        loading={this.state.loading}
        disabled={locked}
        onClick={this.handleClick}
      />
    );
  }
}

const mapStateToProps = state => ({
  // ...
});

const mapDispatchToProps = {
  reload
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DeleteButton);
