import React, { Component } from "react";
import PropTypes from "prop-types";
import Bookings from "./components/Bookings";
import Availability from "./components/Availability";
import Summary from "./components/Summary";
import Comments from "./components/Comments";
import Actions from "./components/Actions";

export default class UnitRouter extends Component {
  static propTypes = {
    unit: PropTypes.object.isRequired,
    unitTab: PropTypes.string,
    reload: PropTypes.func.isRequired
  };

  render() {
    switch (this.props.unitTab) {
      case "availability":
        return (
          <Availability unit={this.props.unit} reload={this.props.reload} />
        );
      case "bookings":
        return <Bookings unit={this.props.unit} />;
      case "comments":
        return <Comments unit={this.props.unit} />;
      case "actions":
        return <Actions unit={this.props.unit} reload={this.props.reload} />;
      default:
        return <Summary unit={this.props.unit} reload={this.props.reload} />;
    }
  }
}
