import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import styled from "styled-components";

import { danger } from "theme/colors";
import { ThemeNavAction } from "theme/components";

// import redux action
import { timepunch } from "redux/User/actions.js";

const Puncher = ({ User, timepunch }) => {
  const { clocked_in, timepunching } = User;

  const status = clocked_in;

  return (
    <StyledPuncher
      type="button"
      name="timepunch"
      onClick={timepunch}
      disabled={timepunching}
      status={status}
      timepunching={timepunching}
      puncher
    >
      <i className="fas fa-stopwatch" />
    </StyledPuncher>
  );
};

const StyledPuncher = styled(ThemeNavAction)`
  height: 2rem;
  width: 2rem;

  color: ${({ status }) => (status ? danger : null)} !important;

  @media screen and (max-width: 868px) {
    height: 2.5rem;
    width: 2.5rem;
  }
`;

Puncher.propTypes = {
  User: PropTypes.object.isRequired,
  timepunch: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  User: state.User
});

const mapDispatchToProps = { timepunch };

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Puncher);
