import React from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import theme from "styled-theming";

import Layout from "components/Layout";

import ContextBar from "components/ContextBar";

import Header from "./components/Header";
import Bookmarks from "./components/Bookmarks";
import Resources from "./components/Resources";
import TimepunchTracker from "./components/TimepunchTracker";
import Feed from "./components/Feed";

import { offWhite } from "theme/colors";
import { portalBlackLighter } from "../../theme/colors";

const Dashboard = ({ User }) => {
  return (
    <Layout noBar>
      <ContextBar />

      <Header name={User.firstname} />

      <DashboardWrapper>
        <DashboardItem gridArea="timepunch">
          <TimepunchTracker />
        </DashboardItem>
        <DashboardItem gridArea="resources">
          <Resources user={User} />
        </DashboardItem>
        <DashboardItem gridArea="bookmarks">
          <Bookmarks />
        </DashboardItem>
        <DashboardItem gridArea="feed">
          <Feed />
        </DashboardItem>
      </DashboardWrapper>
    </Layout>
  );
};

const DashboardWrapper = styled.section`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-areas:
    "timepunch bookmarks feed"
    "resources bookmarks feed";
  grid-auto-rows: 240px;
  grid-gap: 1.5rem;
  align-self: flex-start;

  width: 100%;
  height: auto;

  @media screen and (max-width: 1264px) {
    grid-template-columns: repeat(2, 1fr);
    grid-template-areas:
      "timepunch timepunch"
      "bookmarks resources"
      "feed feed"
      "feed feed";
  }

  @media screen and (max-width: 810px) {
    grid-template-columns: 1fr;
    grid-template-areas:
      "timepunch"
      "bookmarks"
      "resources"
      "feed"
      "feed";
  }

  @media screen and (max-width: 592px) {
    grid-template-areas: "timepunch" "bookmarks" "resources";
  }
`;

const backgroundColor = theme("mode", {
  light: offWhite,
  dark: portalBlackLighter
});

const DashboardItem = styled.article`
  position: relative;
  background-color: ${backgroundColor};
  padding: 1rem;
  border-radius: 5px;
  overflow-y: auto;

  ${({ double }) => (double ? "grid-row: span 2" : null)}
  ${({ extended }) => (extended ? "grid-column: span 2" : null)};
  ${({ fluid }) => (fluid ? "grid-column: span 3" : null)};
  grid-area: ${({ gridArea }) => (gridArea ? gridArea : null)};

  @media screen and (max-width: 592px) {
    ${({ gridArea }) => {
      if (gridArea === "feed") return "display: none";
    }}
  }
`;

const mapStateToProps = state => ({
  User: state.User
});
export default connect(mapStateToProps)(Dashboard);
