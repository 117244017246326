import moment from "moment";

function getCompanyLegalName(value) {
  switch (value) {
    case "Premier":
      return "Premier Rewards LLC";
    case "EPC":
      return "Exchange Points Club";
    case "MPerks":
      return "Member Elite Perks";
    default:
      window.alert(
        "There was an error loading the contract companys legal name. " +
          "The info is not lost, but must be fixed to properly display"
      );
      return "(ERR)";
  }
}

function getExpiration(value) {
  const year = "0001";
  switch (value) {
    case "january 31st":
      return year + "-01-31";
    case "february 28th":
      return year + "-02-28";
    case "march 31st":
      return year + "-03-31";
    case "april 30th":
      return year + "-04-30";
    case "may 31st":
      return year + "-05-31";
    case "june 30th":
      return year + "-06-30";
    case "july 31st":
      return year + "-07-31";
    case "august 31st":
      return year + "-08-31";
    case "september 30th":
      return year + "09-30";
    case "october 31st":
      return year + "-10-31";
    case "november 30th":
      return year + "-11-30";
    case "december 31st":
      return year + "-12-31";
  }
}

function getShared(value) {
  switch (value) {
    case "1":
      return "yes";
    case "0":
      return "no";
    case "2":
      return "unsure";
  }
}

function getCardExpDate(value) {
  switch (value) {
    case "January":
      return "1";
    case "February":
      return "2";
    case "March":
      return "3";
    case "April":
      return "4";
    case "May":
      return "5";
    case "June":
      return "6";
    case "July":
      return "7";
    case "August":
      return "8";
    case "September":
      return "9";
    case "October":
      return "10";
    case "November":
      return "11";
    case "December":
      return "12";
  }
}

export default function(data) {
  const json = JSON.parse(data.cachedForm);
  const memberData = JSON.parse(data.memberData);
  const membershipData = JSON.parse(data.membershipData);
  const paymentData = JSON.parse(data.paymentData);

  return {
    id: data.id,
    email: data.email,
    recipient: data.name,
    sent_on: data.sent,
    received_on: data.received,
    created_on: data.created,
    IP: data.IP,
    deal_sheet: data.deal_sheet,
    data: JSON.stringify({
      firstname: memberData.firstname,
      lastname: memberData.lastname,
      pay_to: memberData.checksPayableTo,
      email: memberData.email,
      phone: memberData.phone,
      address: memberData.address,
      city: memberData.city,
      address_2: memberData.address2,
      state: memberData.state,
      zip: memberData.zipcode,
      // membership
      account_number: membershipData.accountNumber,
      annual_earned: membershipData.annualPoints.replace(",", ""),
      vip_status:
        membershipData.memberLevel.charAt(0).toUpperCase() +
        membershipData.memberLevel.slice(1),
      points_alloc_curr: membershipData.curAlloc.replace(",", ""),
      points_alloc_next: membershipData.nextAlloc.replace(",", ""),
      points_expiration: getExpiration(membershipData.ptsExpiration),
      username: membershipData.username,
      password: membershipData.password,
      shared: getShared(membershipData.sharedAccount),
      // billing
      full_name_on_account: paymentData["bill-name"],
      authorize_payment: paymentData["bill-authed-payment"],
      payment_method: paymentData["bill-method"],
      billing_address: paymentData["bill-address"],
      billing_city: paymentData["bill-city"],
      billing_address_2: paymentData["bill-address2"],
      billing_state: paymentData["bill-state"],
      billing_zip: paymentData["bill-zipcode"],
      // card
      card_number: paymentData["bill-card-number"],
      card_cvc: paymentData["bill-card-cvc"],
      card_exp_date: getCardExpDate(paymentData["bill-card-exp-month"]),
      card_exp_year: paymentData["bill-card-exp-year"],
      // check
      check_bank_addr: paymentData["bill-check-bank-addr"],
      check_account_num: paymentData["bill-check-account-num"],
      check_routing_num: paymentData["bill-check-route-num"],
      check_num: paymentData["bill-check-check-num"],
      // signature
      signature: data.signature
      // ...
    }),
    agent: data.agent,
    template: {
      id: null,
      name: json["contract-name"],
      type: "pointsrelease",
      active: false,
      versions: [
        {
          id: null,
          color: "blue",
          payment_required: json.requirePayment === "1" ? true : false,
          year: json.year || "2018",
          title: json["contract-name"],
          html: json.duties,
          legal: json.legal,
          account_company_logo: "",
          account_company_legal_name: getCompanyLegalName(json.company),
          account_company_phone: "-",
          account_company_email: "-",
          account_company_web: "-"
        }
      ],
      account_company: {
        id: null,
        name: json.company
      }
    }
  };
}
