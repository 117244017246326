const initialState = {
  alerts: []
};

export default (state = initialState, action) => {
  switch (action.type) {
    case "ALERTBAR_CREATE":
      return {
        ...state,
        alerts: state.alerts.concat(action.data).sort(byPriority)
      };
    case "ALERTBAR_REMOVE":
      return {
        ...state,
        alerts: state.alerts.filter((alert, index) => index !== 0)
      };
    case "ALERTBAR_REMOVE_BY_IDENTIFIER":
      return {
        ...state,
        alerts: state.alerts.filter(alert => alert.identifier !== action.data)
      };
    default:
      return state;
  }
};

const byPriority = (a, b) => {
  // the priorities are organized into groups. the higher the index, the higher the priority.
  // each group is an array of strings representing the alert identifier. each identifier in an array contains the same
  // priority.
  const priorities = [["clockedOut"], ["disconnected", "disconnected-fake"]];
  // a helper function to determine what the priority group of a given alert is
  const getGroup = alert => {
    const group = priorities.findIndex(group => {
      return group.indexOf(alert.identifier) !== -1;
    });

    return group || 0;
  };

  const aPriority = getGroup(a);
  const bPriority = getGroup(b);

  return aPriority < bPriority ? 1 : aPriority > bPriority ? -1 : 0;
};
