import React from "react";
import Loader from "./components/Loader";
import ErrorMessage from "./components/ErrorMessage";
/**
 *  If loading prop = true, shows loading segment
 *  If error prop is not falsy, shows error ErrorMessage
 *  Else shows children
 *	@prop error optional - error message to show
 *  @prop errorTitle optional - title of error message to show
 *  @prop loading optional - if it is loading
 *  @prop children optional
 */
export default class WithState extends React.PureComponent {
  render() {
    const {
      loading,
      error,
      errorTitle,
      children,
      loaderStyles = {}
    } = this.props;
    if (loading) {
      return <Loader styles={loaderStyles} />;
    } else if (error) {
      return <ErrorMessage message={error} title={errorTitle} />;
    } else {
      return <React.Fragment>{children || null}</React.Fragment>;
    }
  }
}
