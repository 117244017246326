import React from "react";
import { Header, Table, Message } from "semantic-ui-react";
import { Link } from "react-router-dom";
import { addCommas } from "utils/numbers";
import { formatMJY } from "utils/dates";

export default ({ data }) => {
  if (!data.bookings.length) {
    return <p>This check was not printed for any bookings.</p>;
  }

  try {
    const { member } = data.bookings[0].point_usages[0].point_set.membership;
    return (
      <div>
        <Header dividing as="h4">
          <Header.Content>
            <Link to={"/browse/members/" + member.id}>
              {member.firstname} {member.lastname}
            </Link>
            <Header.Subheader>The owner for these bookings</Header.Subheader>
          </Header.Content>
        </Header>
        <Table compact={"very"} basic={"very"}>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Booking</Table.HeaderCell>
              <Table.HeaderCell>Guest</Table.HeaderCell>
              <Table.HeaderCell>Check In</Table.HeaderCell>
              <Table.HeaderCell>Points Used</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {data.bookings.map((booking, key) => {
              return (
                <Table.Row key={key}>
                  <Table.Cell>
                    <Link to={"/browse/bookings/" + booking.id}>
                      #{booking.id.toString().padStart(7, "0")}
                    </Link>
                  </Table.Cell>
                  <Table.Cell>
                    <Link to={"/browse/guests/" + booking.guest.id}>
                      {booking.guest.firstname} {booking.guest.lastname}
                    </Link>
                  </Table.Cell>
                  <Table.Cell>{formatMJY(booking.check_in)}</Table.Cell>
                  <Table.Cell>
                    {addCommas(
                      booking.point_usages.reduce((a, c) => a + c.amount, 0)
                    )}
                  </Table.Cell>
                </Table.Row>
              );
            })}
          </Table.Body>
        </Table>
      </div>
    );
  } catch (e) {
    return (
      <div>
        <Message
          error
          content={`Something's wrong. This check was assigned to an owner for bookings, 
            but no bookings have point usages for this check. To fix this issue, you should save a screenshot of the check, 
            find and note all bookings that *should* be under this check, delete the check, re-allocate the points for 
            those bookings, and then re-create the check. All bookings assigned to the check are listed below.`}
        />
        <ul>
          {data.bookings.map((booking, index) => (
            <li key={index}>
              <Link to={`/browse/bookings/${booking.id}`}>#{booking.id}</Link>
            </li>
          ))}
        </ul>
      </div>
    );
  }
};
