import React from "react";
import { Button, Icon } from "semantic-ui-react";
export default ContentManager => ({
  tableName: "accountCompanies",
  url: "/apis/portal/accountcompanies",
  data: {
    action: "read"
  },
  tableData: {
    show: 250,
    order: [[0, "asc"]],
    paginationPosition: "bottom",
    showFooter: false,
    menu: () => [
      "[search]",
      "[show]",
      "[refresh]",
      <Button
        icon
        color={"blue"}
        title={"New"}
        onClick={ContentManager.functions.openWizard}
        children={<Icon name={"plus"} />}
      />
    ],
    cols: [
      {
        name: "Name",
        src: "name",
        props: {
          selectable: true
        },
        render: (src, row) => (
          <a onClick={() => ContentManager.functions.openEditor(row)}>{src}</a>
        )
      }
    ]
  }
});
