import React, { Component } from "react";
import PropTypes from "prop-types";

import { Button } from "semantic-ui-react";

import AjaxFn from "services/AjaxFn";

export default class Delete extends Component {
  static propTypes = {
    membership: PropTypes.object.isRequired,
    reloadMembership: PropTypes.func.isRequired
  };

  state = {
    loading: false,
    cancel: false
  };

  componentWillUnmount() {
    this.cancel();
  }

  cancel = () => {
    if (typeof this.state.cancel === "function") {
      this.state.cancel();
    }
  };

  handleClick = () => {
    const { membership, reloadMembership } = this.props;
    this.cancel();
    this.setState({
      loading: true,
      cancel: AjaxFn({
        url: "/apis/portal/membershiplogins",
        data: {
          action: "create",
          data: {
            membershipID: membership.id,
            username: "",
            password: "",
            login_works: 0
          }
        },
        success: reloadMembership,
        failure: error => {
          this.setState({ loading: false, cancel: false });
          window.toastr.error(error);
        }
      })
    });
  };

  render() {
    const { loading } = this.state;

    return (
      <Button
        primary
        basic
        icon="plus"
        content="Add another"
        loading={loading}
        disabled={loading}
        onClick={this.handleClick}
      />
    );
  }
}
