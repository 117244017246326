import React from "react";
import { TextArea, Form, Button, Divider } from "semantic-ui-react";
import AjaxFn from "services/AjaxFn";
const defaultState = {
  message: "Type a comment",
  loading: false,
  // error true becuase nothing exists to save
  error: true,
  text: ""
};
export default class Reply extends React.Component {
  state = {
    ...defaultState
  };
  handleChange = (e, { value }) => {
    this.setState({ text: value }, this.validate);
  };
  discard = () => {
    this.setState(defaultState, () => {
      this.props.setReplying(false);
    });
  };
  validate = () => {
    const trimmed = this.state.text.trim();
    if (trimmed.length < 10) {
      this.setState({
        error: true,
        message: `Type ${10 - trimmed.length} more character(s)`
      });
    } else if (trimmed.length > 512) {
      this.setState({ error: true, message: "Comment is too long (512 max)" });
    } else {
      this.setState({
        error: false,
        message: `${512 - trimmed.length} character(s) remaining`
      });
    }
  };
  submit = () => {
    this.setState({ loading: true });
    AjaxFn({
      url: "/apis/portal/comments",
      data: {
        action: "reply",
        data: {
          thread: this.props.thread,
          comment: this.props.comment.id,
          text: this.state.text
        }
      },
      success: message => {
        window.toastr.success();
        this.props.reload();
        this.props.setReplying(false);
      },
      failure: error => {
        window.toastr.error(error);
        this.setState({ loading: false });
      }
    });
  };
  render() {
    const { divider = true } = this.props;
    const { text, loading } = this.state;
    return (
      <div style={{ marginTop: "1rem" }}>
        <Form>
          <Form.Field>
            <TextArea
              placeholder={"Type a comment..."}
              value={text}
              onChange={this.handleChange}
              disabled={loading}
            />
          </Form.Field>
        </Form>
        {this.state.message && (
          // Display an error message (like incorrect length)
          <p style={{ marginTop: ".5rem", marginBottom: ".5rem" }}>
            {this.state.message}
          </p>
        )}
        <div style={{ textAlign: "right", marginTop: ".5rem" }}>
          <Button
            onClick={this.discard}
            size={"small"}
            disabled={loading}
            content={"Discard"}
          />
          <Button
            primary
            size={"small"}
            onClick={this.submit}
            disabled={loading || this.state.error}
            loading={loading}
            content={"Post"}
          />
        </div>
        {divider && <Divider />}
      </div>
    );
  }
}
