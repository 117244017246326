import React from "react";
import { Link } from "react-router-dom";

import { Breadcrumb } from "semantic-ui-react";

import ContextBar from "components/ContextBar";

export default () => (
  <ContextBar>
    <Breadcrumb>
      <Breadcrumb.Section>
        <Link to={"/"}>Portal</Link>
      </Breadcrumb.Section>
      <Breadcrumb.Divider>/</Breadcrumb.Divider>
      <Breadcrumb.Section active>Import Finances</Breadcrumb.Section>
    </Breadcrumb>
  </ContextBar>
);
