import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { Form, Input, Select } from "semantic-ui-react";

import membershipLevels from "fixtures/membershipLevels";
import resorts from "fixtures/resorts";

class Membership extends Component {
	constructor(props) {
		super(props);
		this.state = { resortOption: "" };
	}

	componentWillReceiveProps(nextProps) {
		if (
			nextProps.resort.toLowerCase() !== "wyndham" &&
			nextProps.resort.toLowerCase() !== "diamond" &&
			nextProps.resort !== ""
		) {
			this.setState({ resortOption: "other" });
		}
	}

	handleResortSelect = (e, { name, value, nested }) => {
		const { handleChange } = this.props;
		this.setState(
			{ resortOption: value },
			handleChange(e, { name, value, nested })
		);
	};

	render() {
		const {
			handleChange,
			member_number,
			points,
			resort,
			vip_level,
			years_owner
		} = this.props;
		const { resortOption } = this.state;
		return (
			<Fragment>
				<Form.Group widths="equal">
					{resortOption === "other" ? (
						<Form.Field /*required*/>
							<label htmlFor="otherResort">Resort</label>
							<Input
								type="text"
								id="resort"
								name="resort"
								nested="membership"
								onChange={handleChange}
								// required
								value={resort}
								placeholder="Resort name"
								action
							>
								<input />
								<Select
									compact
									options={resorts}
									id="resort"
									name="resort"
									nested="membership"
									onChange={this.handleResortSelect}
									value={resortOption}
									placeholder="Select one..."
									// required
								/>
							</Input>
						</Form.Field>
					) : (
						<Form.Select
							label="Resort"
							id="resort"
							name="resort"
							nested="membership"
							options={resorts}
							onChange={this.handleResortSelect}
							value={resort}
							placeholder="Select one..."
							// required
						/>
					)}
					<Form.Select
						label="VIP Level"
						id="vip_level"
						name="vip_level"
						nested="membership"
						options={membershipLevels}
						onChange={handleChange}
						value={vip_level || ""}
						placeholder="Select one..."
						// required
					/>
					<Form.Input
						label="Member Number"
						id="member_number"
						name="member_number"
						nested="membership"
						onChange={handleChange}
						value={member_number || ""}
						autoComplete="off"
						placeholder="Member number"
						// required
					/>
				</Form.Group>
				<Form.Group widths="equal">
					<Form.Input
						label="Points"
						id="points"
						name="points"
						nested="membership"
						onChange={handleChange}
						value={points || ""}
						autoComplete="off"
						placeholder="Points"
						// required
					/>
					<Form.Input
						label="Years as Owner"
						id="years_owner"
						name="years_owner"
						nested="membership"
						onChange={handleChange}
						value={years_owner || ""}
						autoComplete="off"
						placeholder="Years as owner"
						// required
					/>
				</Form.Group>
			</Fragment>
		);
	}
}

Membership.propTypes = {
	handleChange: PropTypes.func.isRequired,
	member_number: PropTypes.string,
	points: PropTypes.string,
	resort: PropTypes.string,
	vip_level: PropTypes.string,
	years_owner: PropTypes.string
};

Membership.defaultProps = {
	member_number: "",
	points: "",
	resort: "",
	vip_level: "",
	years_owner: ""
};

export default Membership;
