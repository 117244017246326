import React from "react";
import { Button, Form, Header, Input, Grid } from "semantic-ui-react";
import AjaxDropdown from "components/AjaxDropdown";
/**
 *	@prop unitOnly {Bool}
 *	@prop newResortAndUnit {Function}
 *	@prop setData {Function}
 *	@prop data {Object}
 */
export default class UnitForm extends React.PureComponent {
  render() {
    const { data, setData, newResortAndUnit, unitOnly } = this.props;
    return (
      <React.Fragment>
        {unitOnly && (
          <React.Fragment>
            <Header as={"h3"} dividing>
              Unit Resort
            </Header>
            <Grid columns={2}>
              <Grid.Column>
                <p>Choose the resort that the unit will belong to.</p>
              </Grid.Column>
              <Grid.Column>
                <Form>
                  <div style={{ display: "flex" }}>
                    <div style={{ flexGrow: "1" }}>
                      <Form.Field>
                        <label>Resort</label>
                        <AjaxDropdown
                          url={"/apis/portal/resorts"}
                          data={{
                            action: "read_for_dropdown"
                          }}
                          placeholder={"Select a Resort"}
                          dataRetreiver={({ message: m }) =>
                            m.map(r => ({
                              value: r.id,
                              text: r.resort_company.name + " " + r.name
                            }))
                          }
                          value={data.resort}
                          onChange={(e, { value }) =>
                            setData("resort_id", value)
                          }
                        />
                      </Form.Field>
                    </div>
                    <div style={{ marginLeft: ".25rem" }}>
                      <Button
                        onClick={newResortAndUnit}
                        style={{ display: "inline-block", marginTop: "1.7rem" }}
                        children={"New"}
                      />
                    </div>
                  </div>
                </Form>
              </Grid.Column>
            </Grid>
          </React.Fragment>
        )}
        <Header as={"h3"} dividing>
          Unit Info
        </Header>
        <Grid columns={2}>
          <Grid.Column>
            <p>
              Add some information for the unit. More will be available after it
              has been created, such as features and listing info.
            </p>
          </Grid.Column>
          <Grid.Column>
            <Form>
              <Form.Group widths={"equal"}>
                <Form.Field>
                  <label>Name</label>
                  <Input
                    placeholder={"1 Bedroom Deluxe"}
                    value={data.name}
                    onChange={e => setData("name", e.target.value)}
                  />
                </Form.Field>
              </Form.Group>
            </Form>
          </Grid.Column>
        </Grid>
      </React.Fragment>
    );
  }
}
