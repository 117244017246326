import React, { Component } from "react";
import PropTypes from "prop-types";

import { Button } from "semantic-ui-react";

import AjaxFn from "services/AjaxFn";

export default class MakeDealsheet extends Component {
  static propTypes = {
    reload: PropTypes.func.isRequired,
    data: PropTypes.object.isRequired
  };

  state = {
    loading: false,
    cancel: false
  };

  componentWillUnmount() {
    this.cancel();
  }

  cancel = () => {
    if (typeof this.state.cancel === "function") {
      this.state.cancel();
    }
  };

  handleClick = () => {
    this.cancel();
    this.setState({
      loading: true,
      cancel: AjaxFn({
        url: "/apis/portal/legacyagreementdealsheets",
        data: {
          action: "create",
          data: {
            ID: this.props.data.id
          }
        },
        success: this.props.reload,
        failure: error => {
          this.setState({ loading: false, cancel: false });
          window.toastr.error(error);
        }
      })
    });
  };

  render() {
    const { loading } = this.state;
    return (
      <Button
        loading={loading}
        disabled={loading}
        onClick={this.handleClick}
        content="Create Dealsheet"
        basic
        secondary
      />
    );
  }
}
