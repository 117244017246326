import theme from "styled-theming";

import {
  breadcrumbDivider,
  offWhite,
  offWhiteDark,
  portalBlue,
  portalBlueDark,
  portalBlack,
  portalBlackLighter,
  portalBlackLightest,
  portalGrey,
  portalGreyDark
} from "./colors";

// base level
export const backgroundColor = theme("mode", {
  light: offWhite,
  dark: portalBlackLightest
});

export const backgroundColorHover = theme("mode", {
  light: portalGrey,
  dark: portalBlackLighter
});

export const fontColor = theme("mode", {
  light: portalBlack,
  dark: offWhite
});

export const borderColor = theme("mode", {
  light: portalGrey,
  dark: portalBlackLighter
});

// second level
export const secondBackgroundColor = theme("mode", {
  light: portalGrey,
  dark: portalBlackLighter
});

// Element Specific
// Button default
export const defaultButtonBackground = theme("mode", {
  light: offWhite,
  dark: portalBlackLighter
});

export const defaultButtonFontColor = theme("mode", {
  light: portalBlack,
  dark: offWhite
});

export const defaultButtonBorderColor = theme("mode", {
  light: portalGrey,
  dark: portalBlack
});

export const defaultButtonBackgroundHover = theme("mode", {
  light: offWhiteDark,
  dark: portalBlack
});

// Button Primary
export const primaryButtonBackground = theme("mode", {
  light: portalBlue,
  dark: portalBlackLightest
});

export const primaryButtonFontColor = theme("mode", {
  light: offWhite,
  dark: offWhite
});

export const primaryButtonBorderColor = theme("mode", {
  light: portalBlueDark,
  dark: portalBlack
});

export const primaryButtonBackgroundHover = theme("mode", {
  light: portalBlueDark,
  dark: portalBlack
});

// Button Secondary
export const secondaryButtonBackground = theme("mode", {
  light: portalGrey,
  dark: portalBlackLightest
});

export const secondaryButtonBackgroundHover = theme("mode", {
  light: portalGreyDark,
  dark: portalBlackLighter
});

export const secondaryButtonBorderColor = theme("mode", {
  light: portalGreyDark,
  dark: portalBlackLightest
});

// Component Specific
export const footerBackgroundColor = theme("mode", {
  light: offWhite,
  dark: portalBlackLighter
});

// Input
export const inputBackgroundColor = theme("mode", {
  light: "#fff",
  dark: portalBlackLightest
});

export const inputBorderColor = theme("mode", {
  light: offWhiteDark,
  dark: portalBlack
});

export const inputFontColor = theme("mode", {
  light: portalBlack,
  dark: offWhite
});
