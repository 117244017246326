import React, { Component } from "react";
import { connect } from "react-redux";

import { Container } from "semantic-ui-react";

import Layout from "components/Layout";
import ContextBar from "components/ContextBar";
import SendAlert from "components/Alertbar/components/SendAlert";

export class AlertsTest extends Component {
  render() {
    return (
      <Layout noBar>
        <ContextBar />
        <Container>
          <br />
          <SendAlert />
        </Container>
      </Layout>
    );
  }
}

const mapStateToProps = state => ({
  // ...
});

const mapDispatchToProps = {
  // ....
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AlertsTest);
