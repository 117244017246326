import React from "react";
import { FiltersModal } from "components/PortalTable/parts";
import { Form, Dropdown, Grid } from "semantic-ui-react";
import sources from "fixtures/booking_sources";
import { BookingIssues } from "fixtures/issues";

const issueFilter = (applied, row) => {
  switch (applied.issues) {
    case "any":
      return true;
    case "none":
      return row.issues.filter(i => i.closed_on === null).length === 0;
    case "only":
      return row.issues.filter(i => i.closed_on === null).length > 0;
    default:
      return !!row.issues.find(
        i => i.closed_on === null && i.category === applied.issues
      );
  }
};

const checkFilter = (applied, row) => {
  switch (applied.check) {
    case "none":
      return !row.check.id;
    case "only":
      return !!row.check.id;
    default:
      return true;
  }
};

const findActive = r => r.completed_on !== null && r.invalidated_on === null;

const auditFilter = (applied, row) => {
  switch (applied.booking_audit) {
    case "only":
      return !!row.audits.find(findActive);
    case "none":
      return !row.audits.find(findActive);
    default:
      return true;
  }
};

const balanceDueFilter = (applied, row) => {
  if (applied.balance_due !== "any") {
    return row.balance_due === applied.balance_due;
  }

  return true;
};

export default () => (
  <FiltersModal
    namespace={"BookingList"}
    filters={{
      cancelled: false,
      booking_audit: "none",
      finance_audit: false,
      issues: "any",
      check: "none",
      channel: "any",
      balance_due: "any"
    }}
    buildFilters={applied => {
      const fns = [];
      if (applied.cancelled !== "any") {
        fns.push(b => b.cancelled === applied.cancelled);
      }
      if (applied.finance_audit !== "any") {
        fns.push(b => b.financialAuditDone === applied.finance_audit);
      }
      if (applied.channel !== "any") {
        fns.push(b => b.channel === applied.channel);
      }
      fns.push(row => checkFilter(applied, row));
      fns.push(row => issueFilter(applied, row));
      fns.push(row => auditFilter(applied, row));
      fns.push(row => balanceDueFilter(applied, row));
      return fns;
    }}
    modalProps={{
      size: "small"
    }}
    children={(applied, update) => (
      <Form>
        <Grid columns={2}>
          <Grid.Column>
            <Form.Dropdown
              label="Cancelled Bookings"
              selection
              onChange={(e, { value }) => update("cancelled", value)}
              value={applied.cancelled}
              options={[
                { value: true, text: "Cancelled Only" },
                { value: false, text: "Hide Cancelled" },
                { value: "any", text: "Any" }
              ]}
            />
            <Form.Dropdown
              label="Booking Audit"
              selection
              onChange={(e, { value }) => update("booking_audit", value)}
              value={applied.booking_audit}
              options={[
                { value: "any", text: "Any (not filtered)" },
                { value: "only", text: "Only audited bookings" },
                { value: "none", text: "No audited bookings" }
              ]}
            />
            <Form.Dropdown
              label="Financial Audit"
              selection
              onChange={(e, { value }) => update("finance_audit", value)}
              value={applied.finance_audit}
              options={[
                { value: true, text: "Only audited bookings" },
                { value: false, text: "No audited bookings" },
                { value: "any", text: "Any" }
              ]}
            />
            <Form.Dropdown
              label="Balance Due"
              selection
              onChange={(e, { value }) => update("balance_due", value)}
              value={applied.balance_due}
              options={[
                { value: true, text: "Yes" },
                { value: false, text: "No" },
                { value: "any", text: "Any" }
              ]}
            />
          </Grid.Column>
          <Grid.Column>
            <Form.Dropdown
              label="Issues"
              selection
              search
              onChange={(e, { value }) => update("issues", value)}
              value={applied.issues}
              options={[
                { value: "any", text: "Any (not filtered)" },
                { value: "only", text: "Only bookings with open issues" },
                { value: "none", text: "Only bookings without open issues" }
              ].concat(
                BookingIssues.map(i => ({
                  ...i,
                  text: "If has issue: " + i.text
                }))
              )}
            />
            <Form.Dropdown
              label="Owner Paid/Check Printed"
              selection
              onChange={(e, { value }) => update("check", value)}
              value={applied.check}
              options={[
                { value: "any", text: "Any (not filtered)" },
                { value: "only", text: "Only bookings with checks printed" },
                {
                  value: "none",
                  text: "Only bookings without checks printed"
                }
              ]}
            />
            <Form.Dropdown
              label="Channel"
              selection
              search
              onChange={(e, { value }) => update("channel", value)}
              value={applied.channel}
              options={sources
                .map(src => ({ ...src, disabled: false }))
                .concat([{ value: "any", text: "Any " }])}
            />
          </Grid.Column>
        </Grid>
      </Form>
    )}
  />
);
