import React, { Component, Fragment } from "react";
import { MemberBreadcrumb } from "../Breadcrumb";

import { Segment, Header, Grid, Form, Message } from "semantic-ui-react";

import AsyncForm from "components/AsyncForm";
import props from "../propTypes.js";
import Activate from "./components/Activate";

export default class MemberSummary extends Component {
  static propTypes = { ...props };

  render() {
    const { member, reloadMember } = this.props;

    const config = {
      reload: reloadMember,
      failure: window.toastr.error,
      dataFn: (source, value) => ({
        url: "/apis/portal/members",
        data: {
          action: "update",
          data: {
            ID: member.id,
            [source]: typeof value === "boolean" ? (value ? 1 : 0) : value
          }
        }
      })
    };

    return (
      <Fragment>
        <MemberBreadcrumb member={member} />
        <Header
          as="h1"
          dividing
          icon="folder outline"
          content={member.firstname + " " + member.lastname}
          subheader="View or edit general member info"
        />
        {member.deleted && (
          <Message error>
            <Message.Header>Member Inactive</Message.Header>
            This member is marked as inactive. They will not be available for
            use for bookings. If this is a mistake, you can set them to active
            in the "actions" section below.
          </Message>
        )}
        <p>
          You can use the form below to update information for this member.
          Changes you make will be saved automatically.
        </p>
        <Segment attached="top">
          <AsyncForm {...config}>
            {({ functions }) => {
              return (
                <Grid columns="3" stackable>
                  <Grid.Row>
                    <Grid.Column>
                      <Header icon="user" content="Personal" />
                      {functions.make({
                        element: <Form.Input />,
                        field: "firstname",
                        value: member.firstname,
                        debounce: 500,
                        props: {
                          label: "First name",
                          placeholder: "First name"
                        }
                      })}
                      {functions.make({
                        element: <Form.Input />,
                        field: "lastname",
                        value: member.lastname,
                        debounce: 500,
                        props: {
                          label: "Last name",
                          placeholder: "Last name"
                        }
                      })}
                      {functions.make({
                        element: <Form.Input />,
                        field: "pay_checks_to",
                        value: member.pay_checks_to,
                        debounce: 500,
                        props: {
                          label: "Pay checks to",
                          placeholder: "Nobody"
                        }
                      })}
                    </Grid.Column>
                    <Grid.Column>
                      <Header icon="map" content="Address" />
                      {functions.make({
                        element: <Form.Input />,
                        field: "address",
                        value: member.address,
                        debounce: 500,
                        props: {
                          label: "Street Address",
                          placeholder: "1234 Example St."
                        }
                      })}
                      {functions.make({
                        element: <Form.Input />,
                        field: "city",
                        value: member.city,
                        debounce: 500,
                        props: {
                          label: "City",
                          placeholder: "City"
                        }
                      })}
                      {functions.make({
                        element: <Form.Input />,
                        field: "state",
                        value: member.state,
                        debounce: 500,
                        props: {
                          label: "State",
                          placeholder: "State"
                        }
                      })}
                      {functions.make({
                        element: <Form.Input />,
                        field: "zipcode",
                        value: member.zipcode,
                        debounce: 500,
                        props: {
                          label: "Zipcode",
                          placeholder: "Zipcode"
                        }
                      })}
                    </Grid.Column>
                    <Grid.Column>
                      <Header icon="mail" content="Contact" />
                      {functions.make({
                        element: <Form.Input />,
                        field: "email",
                        value: member.email,
                        debounce: 500,
                        props: {
                          label: "Email Address",
                          placeholder: "email@address.com"
                        }
                      })}
                      {functions.make({
                        element: <Form.Input />,
                        field: "phone",
                        value: member.phone,
                        debounce: 500,
                        props: {
                          label: "Primary Phone",
                          placeholder: "(xxx) xxx-xxxx"
                        }
                      })}
                      {functions.make({
                        element: <Form.Input />,
                        field: "secondary_phone",
                        value: member.secondary_phone,
                        debounce: 500,
                        props: {
                          label: "Backup Phone",
                          placeholder: "(xxx) xxx-xxxx"
                        }
                      })}
                      {functions.make({
                        element: <Form.Input />,
                        field: "tertiary_phone",
                        value: member.tertiary_phone,
                        debounce: 500,
                        props: {
                          label: "Backup Phone 2",
                          placeholder: "(xxx) xxx-xxxx"
                        }
                      })}
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Column width={16}>{functions.message()}</Grid.Column>
                  </Grid.Row>
                </Grid>
              );
            }}
          </AsyncForm>
        </Segment>
        <Segment attached="bottom">
          <Header
            icon="settings"
            content="Actions"
            subheader="Perform actions for this member"
            as="h3"
          />
          <p>Use the button below to activate or deactivate this member.</p>
          <Activate member={member} reloadMember={reloadMember} />
        </Segment>
      </Fragment>
    );
  }
}
