import React from "react";
import { connect } from "react-redux";
import injectSheet from "react-jss";
import { Table } from "semantic-ui-react";
import TableCell from "../TableCell";
/**
 * @prop namespace
 * @prop row
 */
class TableRow extends React.Component {
  /**
   *  TODO: this makes no sense, reword it
   *	Checks to see if a class name as a string or function
   *  to return a class name exists and then returns that
   *  @param {Object} rows
   *  @param {Object} row
   *  @return {String}
   */
  getClassName(row, rows) {
    if (rows && rows.addClass) {
      return typeof rows.addClass === "function"
        ? rows.addClass(row)
        : rows.addClass;
    }
    return null;
  }
  render() {
    const { tables, namespace, row } = this.props;
    const { rows, cols } = tables[namespace];
    return (
      <Table.Row className={this.getClassName(row, rows)}>
        {cols.map((col, index) => {
          return <TableCell col={col} key={index} row={row} />;
        })}
      </Table.Row>
    );
  }
}

const styles = {
  // ...
};

const mapStateToProps = state => ({
  tables: state.PortalTables
  // ...
});
const mapDispatchToProps = dispatch => ({
  // ...
});

const connected = connect(
  mapStateToProps,
  mapDispatchToProps
)(TableRow);
export default injectSheet(styles)(connected);
