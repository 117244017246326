import React from "react";
import { Button, Icon, Modal, Table } from "semantic-ui-react";
import LinkConf from "components/LinkWithConfirmation";
/**
 *	@prop viewingResorts object/false
 *	@prop viewResorts function
 */
export default class ViewingResortsModal extends React.PureComponent {
  render() {
    const { viewingResorts, viewResorts } = this.props;
    if (!viewingResorts) {
      return null;
    }
    return (
      <Modal
        open={!!viewingResorts}
        onClose={() => viewResorts()}
        size={"small"}
      >
        <Modal.Header>Resorts for {viewingResorts.name}</Modal.Header>
        <Modal.Content>
          <div style={{ maxHeight: "500px", overflow: "auto" }}>
            <Table striped celled>
              <Table.Body>
                {viewingResorts.resorts.map(({ id, name }, key) => (
                  <Table.Row key={key}>
                    <Table.Cell selectable>
                      <LinkConf to={"/browse/resorts/" + id}>{name}</LinkConf>
                    </Table.Cell>
                  </Table.Row>
                ))}
              </Table.Body>
            </Table>
          </div>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={() => viewResorts()}>
            <Icon name={"close"} />
            Close
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}
