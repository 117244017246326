import React from "react";
import SearchBox from "./components/SearchBox";
import StartSelect from "./components/StartSelect";
import EndSelect from "./components/EndSelect";
import PerPage from "./components/PerPage";
import Refresh from "./components/Refresh";
import AuditedOnly from "./components/AuditedOnly";

import { Form } from "semantic-ui-react";
export default () => (
  <div>
    <div style={{ display: "flex" }}>
      <div style={{ flexGrow: 1 }} />
      <Form>
        <Form.Group widths="equal" style={{ margin: 0 }}>
          <Form.Field>
            <label>Start</label>
            <StartSelect />
          </Form.Field>
          <Form.Field>
            <label>End</label>
            <EndSelect />
          </Form.Field>
          <Form.Field>
            <label>Audited</label>
            <AuditedOnly />
          </Form.Field>
          <Form.Field>
            <label>{"\u00A0"}</label>
            <Refresh />
          </Form.Field>
        </Form.Group>
      </Form>
    </div>
    <div style={{ display: "flex", marginTop: ".5rem" }}>
      <div style={{ flexGrow: "1" }}>
        <SearchBox />
      </div>
      <div style={{ marginLeft: "1rem" }}>
        <PerPage />
      </div>
    </div>
  </div>
);
