import React from "react";
import Comments from "components/Comments";
import { Header } from "semantic-ui-react";
import props from "../props.js";

const GuestComments = props => {
  const { data } = props.guestData;

  return (
    <>
      <Header
        as="h1"
        dividing
        icon="comment outline"
        content={data.firstname + " " + data.lastname + " / Comments"}
        subheader="Leave comments for the guest"
      />
      <Comments thread={data.thread} />
    </>
  );
};

GuestComments.propTypes = { ...props };

export default GuestComments;
