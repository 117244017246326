import React, { useContext } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { Context } from "../../index.js";
import { Button } from "semantic-ui-react";

const Refresh = props => {
  const context = useContext(Context);
  const { loading } = props;

  return (
    <Button
      primary
      content="Reload"
      disabled={loading}
      onClick={context.reload}
    />
  );
};

Refresh.propTypes = {
  loading: PropTypes.bool.isRequired
};

const mapStateToProps = state => ({
  loading: state.PortalTables.AgreementsList.loading
});

const mapDispatchToProps = {
  // ...
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Refresh);
