import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import styled from "styled-components";
import { Button, Icon, Modal, Table, Dropdown } from "semantic-ui-react";

import AjaxFn from "services/AjaxFn";
import AjaxComponent from "components/AjaxComponent";
import { user_can } from "utils/user";

export class Privileges extends Component {
  static propTypes = {
    user: PropTypes.object.isRequired
  };

  state = {
    open: false,
    privileges: {},
    loading: false
  };

  handleAjax = data => {
    this.setState({ loading: true });
    return AjaxFn({
      url: "/apis/portal/accountroles",
      data: {
        action: "set_rights",
        data
      },
      success: () => {
        window.toastr.success();
        this.handleClose();
        this.props.ContentManager.functions.closeEditor();
        this.props.ContentManager.functions.refresh();
      },
      failure: e => {
        window.toastr.error(e);
        this.setState({ loading: false });
      }
    });
  };

  handleOpen = () => this.setState({ open: true });

  handleClose = () => {
    this.setState({
      open: false,
      loading: false,
      privileges: {}
    });
  };

  /**
   *	Filters all changes for those not set to 'default' and saves them
   */
  submitChanges = () => {
    const { ContentManager } = this.props;
    // establish the user's current rights
    const existingPrivileges = {};

    ContentManager.state.editorSource.rights.map(({ right, value }) => {
      existingPrivileges[right] = value;
      return null;
    });

    const combinedRights = {
      ...existingPrivileges,
      ...this.state.privileges
    };

    const filtered = [];
    for (var right in combinedRights) {
      const value = combinedRights[right];
      if (value) {
        filtered.push({ right, value });
      }
    }

    this.handleAjax({
      ID: this.props.ContentManager.state.editorSource.id,
      data: filtered
    });
  };

  /**
   *	Update a privilege
   *	@param {String} id
   *	@param {Mixed} value
   */
  updatePrivilege = (id, value) => {
    this.setState({
      privileges: {
        ...this.state.privileges,
        [id]: value
      }
    });
  };

  /**
   *  Get modal table/content
   *	@param {Array} allPrivileges - all existing privileges
   */
  renderTable = allPrivileges => {
    const { ContentManager } = this.props;
    // the user's rights
    const rolePrivileges = ContentManager.state.editorSource.rights;
    return allPrivileges.map(right => {
      const fn = p => p.right === right;
      const { value } = rolePrivileges.find(fn) || { value: 0 };
      const canEdit = user_can(right, this.props.user);
      return (
        <Table.Row key={right}>
          <Table.Cell width={10}>{right}</Table.Cell>
          <Table.Cell width={6}>
            {canEdit ? (
              <Dropdown
                inline
                fluid
                options={options}
                defaultValue={value ? 1 : 0}
                onChange={(e, { value }) => this.updatePrivilege(right, value)}
              />
            ) : (
              <Icon name="lock" />
            )}
          </Table.Cell>
        </Table.Row>
      );
    });
  };

  render() {
    const { ContentManager } = this.props;
    const { restricted } = ContentManager.state.editorSource;

    const button = (
      <Button
        content="Privileges"
        icon="flag"
        fluid
        onClick={this.handleOpen}
        disabled={!restricted}
      />
    );

    const modalConfig = {
      trigger: button,
      open: this.state.open,
      size: "tiny",
      onClose: this.handleClose
    };

    const ajaxConfig = {
      url: "/apis/portal/sessions",
      data: { action: "rights" }
    };

    return (
      <Modal {...modalConfig}>
        <Modal.Header content="Account Privileges" />
        <ModalContentHeader>
          <Table basic="very">
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell width={10} content="Privilege" />
                <Table.HeaderCell width={6} content="Setting" />
              </Table.Row>
            </Table.Header>
          </Table>
        </ModalContentHeader>
        <Modal.Content scrolling>
          <AjaxComponent ajaxConfig={ajaxConfig}>
            {({ data: rights }) => (
              <Table basic={"very"} compact={"very"}>
                <Table.Body>{this.renderTable(rights)}</Table.Body>
              </Table>
            )}
          </AjaxComponent>
        </Modal.Content>
        <Modal.Actions>
          <Button
            onClick={this.handleClose}
            disabled={this.state.loading}
            content="Cancel"
            basic
          />
          <Button
            primary
            onClick={this.submitChanges}
            loading={this.state.loading}
            content="Save"
            icon="save"
          />
        </Modal.Actions>
      </Modal>
    );
  }
}

const options = [
  {
    text: (
      <React.Fragment>
        <Icon name={"checkmark"} color={"green"} />
        Allowed
      </React.Fragment>
    ),
    value: 1
  },
  {
    text: (
      <React.Fragment>
        <Icon name={"cancel"} color={"red"} />
        Disallowed
      </React.Fragment>
    ),
    value: 0
  }
];

const ModalContentHeader = styled(Modal.Content)`
  &&& {
    font-size: 1rem;
    padding: 1rem 1.5rem;
    border-bottom: 1px solid rgba(34, 36, 38, 0.15);
    table {
      thead {
        tr {
          th {
            border-bottom: none;
            padding-bottom: 0;
            padding-left: 0;
          }
        }
      }
    }
  }
`;

const mapStateToProps = state => ({
  user: state.User
});

const mapDispatchToProps = {
  // ...
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Privileges);
