import React, { useRef, useState } from "react";
import { useOutsideClick } from "react-use";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { Input } from "semantic-ui-react";
import { sortableHandle } from "react-sortable-hoc";

import { ThemeButton, ThemeNavAction } from "theme/components";
import DeleteButton from "./DeleteButton";

const Bookmark = props => {
  const { bookmark, editing, setEditing, stopEditing, setName, remove } = props;
  const ref = useRef(null);
  const [name, setNameState] = useState(bookmark.name);
  const handleStopEditing = () => {
    if (editing) {
      setNameState(bookmark.name);
      stopEditing();
    }
  };
  const handleChange = (undefined, { value }) => setNameState(value);

  useOutsideClick(ref, handleStopEditing);

  return (
    <Item editing={editing ? "true" : undefined} ref={ref}>
      <DragHandle />
      {editing ? (
        <>
          <Input
            label="Name"
            value={name}
            placeholder="Name"
            fluid
            onChange={handleChange}
          />
          <ThemeNavAction title="Save" onClick={() => setName(name)}>
            <i className="fas fa-check" />
          </ThemeNavAction>
        </>
      ) : (
        <>
          <Link to={bookmark.url}>
            <ThemeButton
              content={bookmark.name}
              align="left"
              icon="linkify"
              secondary
              fluid
            />
          </Link>
          <ThemeNavAction title="Edit" onClick={setEditing}>
            <i className="far fa-edit" />
          </ThemeNavAction>
          <DeleteButton remove={remove} />
        </>
      )}
    </Item>
  );
};

Bookmark.propTypes = {
  editing: PropTypes.bool.isRequired,
  bookmark: PropTypes.object.isRequired,
  setName: PropTypes.func.isRequired,
  setEditing: PropTypes.func.isRequired,
  stopEditing: PropTypes.func.isRequired,
  remove: PropTypes.func.isRequired
};

export default Bookmark;

const Item = styled.div`
  display: grid;
  grid-template-columns: ${props =>
    props.editing === "true" ? "auto 1fr auto auto" : "auto 1fr auto auto"};
  grid-gap: 0.5rem;
  width: 100%;

  > a {
    width: 100%;
  }
`;

const Grip = styled.i`
  align-self: center;
  cursor: pointer;
  padding: 0.5rem;
  cursor: grab !important;
  &:active {
    cursor: grabbing !important;
  }
`;

const DragHandle = sortableHandle(() => <Grip className="fas fa-grip-lines" />);
