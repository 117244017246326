import React, { useContext } from "react";
import styled from "styled-components";
import { Segment } from "semantic-ui-react";
import { Context } from "../../";

const ItemComponent = props => {
  const context = useContext(Context);
  const { expanded } = context.state;

  const {
    content = "",
    icon = "",
    description = "",
    inverted = true,
    ...rest
  } = props;

  const segmentprops = {
    expanded: expanded ? "true" : undefined,
    inverted,
    title: props.content,
    ...rest
  };

  return (
    <StyledSegment {...segmentprops}>
      {icon && <i className={icon} />}
      {expanded && content}
      {description && expanded && (
        <p style={{ marginTop: ".5rem" }}>{description}</p>
      )}
    </StyledSegment>
  );
};

export default ItemComponent;

const StyledSegment = styled(Segment)`
  ${props =>
    !props.expanded &&
    `
      border-radius: 0px !important;
      margin: 0px !important;
      padding: 1rem 0 !important;
      text-align: center;
    `}
  i {
    margin-right: ${props => (props.expanded ? "1rem" : "0px !important")};
  }
`;
