import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Tour from "reactour";
import AjaxFn from "services/AjaxFn";
import Skip from "./components/Skip";
import { updateTutorial } from "redux/User/actions";
import moment from "moment";

/**
 */
export class WithTour extends Component {
  static propTypes = {
    index: PropTypes.string.isRequired,
    config: PropTypes.object.isRequired,
    tutorials: PropTypes.array.isRequired,
    children: PropTypes.node.isRequired,
    enabled: PropTypes.bool
  };

  state = {
    tutorial: {
      index: this.props.index,
      step: 0,
      skip: false
    },
    isOpen: false,
    cancel: false,
    skipModalOpen: false
  };

  componentDidMount() {
    const match = this.props.tutorials.find(tutorial => {
      return tutorial.index === this.props.index;
    });

    this.setState({
      tutorial: match || this.state.tutorial,
      isOpen: match ? !this.isTutorialCompleted(match) : true
    });
  }

  componentWillUnmount() {
    this.cancel();
  }

  /**
   * Cancel any pending ajax requests
   */
  cancel = () => {
    if (typeof this.state.cancel === "function") {
      this.state.cancel();
    }
  };

  /**
   * Determine if the tutorial has been completed or not
   * @param {Object} match
   * @return {Bool}
   */
  isTutorialCompleted = match => {
    if (match.skip) {
      return true;
    } else if (match.completed_on) {
      return true;
    } else {
      return false;
    }
  };

  /**
   * Callback when the current step changes (the function name is misleading as per reactour)
   * Checks if the user just navigated to the final step or not
   * If so, it completes the tutorial
   * If not, it just progresses the tutorial
   * @param {Number} step
   */
  getCurrentStep = step => {
    // We don't want to send requests if we just completed it
    if (!this.state.tutorial.completed_on) {
      if (step + 1 === this.props.config.steps.length) {
        this.sendCompletionRequest(step);
      } else {
        this.sendProgressRequest(step);
      }
    }

    this.setState({
      tutorial: {
        ...this.state.tutorial,
        step
      }
    });
  };

  sendProgressRequest = step => {
    this.props.updateTutorial({
      ...this.state.tutorial,
      step,
      completed_on: null,
      skip: false
    });

    this.cancel();
    this.setState({
      cancel: AjaxFn({
        url: "/apis/portal/accounttutorials",
        data: {
          action: "progress",
          data: {
            index: this.state.tutorial.index,
            step
          }
        }
      })
    });
  };

  sendCompletionRequest = () => {
    this.props.updateTutorial({
      ...this.state.tutorial,
      completed_on: moment().format("YYYY-MM-DD HH:mm:ss")
    });

    this.cancel();
    this.setState({
      cancel: AjaxFn({
        url: "/apis/portal/accounttutorials",
        data: {
          action: "complete",
          data: {
            index: this.state.tutorial.index
          }
        },
        success: () => {
          this.setState({
            tutorial: {
              ...this.state.tutorial,
              // We have to do this to keep track of the fact that we just completed it. If we don't,
              // the user is able to use the navigation to "uncancel" it.
              completed_on: true
            }
          });
        }
      })
    });
  };

  sendSkipTutorialRequiest = () => {
    this.props.updateTutorial({
      ...this.state.tutorial,
      skip: true
    });

    this.cancel();
    this.setState({
      skipModalOpen: false,
      cancel: AjaxFn({
        url: "/apis/portal/accounttutorials",
        data: {
          action: "skip",
          data: {
            index: this.state.tutorial.index
          }
        },
        failure: () => {
          window.toastr.error("There was a problem skipping the tutorial.");
        }
      })
    });
  };

  closeSkipModal = () => this.setState({ skipModalOpen: false, isOpen: true });

  onRequestClose = () => {
    if (!this.state.tutorial.completed_on) {
      this.setState({ skipModalOpen: true, isOpen: false });
    } else {
      this.setState({ isOpen: false });
    }
  };

  render() {
    return this.props.children;
  }

  // render() {
  //   return (
  //     <Fragment>
  //       {this.props.enabled !== false && this.state.tutorial && (
  //         <Fragment>
  //           <Tour
  //             {...this.props.config}
  //             isOpen={this.state.isOpen}
  //             getCurrentStep={this.getCurrentStep}
  //             goToStep={this.state.tutorial.step}
  //             startAt={this.state.tutorial.step}
  //             onRequestClose={this.onRequestClose}
  //           />
  //           <Skip
  //             open={this.state.skipModalOpen}
  //             skipTutorial={this.sendSkipTutorialRequiest}
  //             closeSkipModal={this.closeSkipModal}
  //           />
  //         </Fragment>
  //       )}
  //       {this.props.children}
  //     </Fragment>
  //   );
  // }
}

const mapStateToProps = state => ({
  tutorials: state.User.tutorials
});

const mapDispatchToProps = {
  updateTutorial
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WithTour);
