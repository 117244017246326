import { getUnique } from "utils/arrays";

export const countBookingsInMembership = (membership) =>
  membership.points_sets.reduce((a, c) => a + countBookingsInPointsSet(c), 0);
export const countBookingsInPointsSet = (set) => {
  const bookings = [];
  set.point_usages.forEach((usage) => bookings.push(usage.booking.id));
  return getUnique(bookings).length;
};
export const countPointsUsedInMembership = (membership) =>
  membership.points_sets.reduce((a, c) => a + countPointsUsedInPointsSet(c), 0);
export const countPointsUsedInPointsSet = (set) =>
  set.point_usages.reduce((a, c) => a + c.amount, 0);
export const countPointsLeftInMembership = (membership) =>
  membership.points_sets.reduce((a, c) => a + countPointsLeftInPointsSet(c), 0);
export const countPointsLeftInPointsSet = (set) => set.allocated - countPointsUsedInPointsSet(set);
