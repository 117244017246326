import React from "react";
import { Link } from "react-router-dom";
import moment from "moment";

import { Divider, Icon, Popup } from "semantic-ui-react";

export default connectedUser => {
  const format = date => moment(date).format("MMM Do YYYY");
  const Calendar = () => <Icon name="calendar outline" size="large" />;
  const Avatar = () => (
    <Icon name="user circle" size="large" style={{ marginBottom: ".25rem" }} />
  );
  const Divided = () => (
    <Divider style={{ marginTop: "0.25rem", marginBottom: "0.5rem" }} />
  );
  const indicator = (completed, icon, agent = false) => {
    const sameAgent = agent && agent.id && agent.id === connectedUser.id;

    return (
      <Icon
        name={icon}
        color={completed ? (sameAgent ? "blue" : "black") : "black"}
        inverted={completed ? true : false}
        disabled={!completed}
        // bordered
        circular
        size="large"
      />
    );
  };

  return {
    show: 10,
    order: [[1, "asc"]],
    cols: [
      {
        name: "Agreement",
        src: row => row.template.name,
        render: (undefined, row) => (
          <>
            <b>{row.template.name}</b>
            <br />
            {format(row.created_on)}
            {" - "}
            <Link
              title={"View Agreement"}
              to={`/browse/agreements/${row.id}`}
              style={{ width: "50px", marginLeft: "5px" }}
              children={"click to view"}
            />
          </>
        )
      },
      {
        name: "Client",
        src: row => row.recipient + " " + row.email,
        render: (undefined, row) => (
          <>
            <b>{row.recipient}</b>
            <br />
            {row.email}
          </>
        )
      },
      {
        name: "Progress",
        props: {
          width: 4
        },
        src: row => {
          if (row.deal_sheet.onboarding_agent.id) {
            return 100;
          } else if (row.processed_on) {
            return 80;
          } else if (row.submitted_on) {
            return 60;
          } else if (row.sent_on) {
            return 40;
          } else {
            return 20;
          }
        },
        render: (undefined, row) => {
          const { deal_sheet } = row;

          const created_on = format(row.created_on);
          const appointment_setter =
            deal_sheet.appointment_setter.id &&
            deal_sheet.appointment_setter.firstname +
              " " +
              deal_sheet.appointment_setter.lastname;

          const sent_on = row.sent_on && format(row.sent_on);
          const closing_agent =
            deal_sheet.closing_agent.id &&
            deal_sheet.closing_agent.firstname +
              " " +
              deal_sheet.closing_agent.lastname;

          const received_on = row.received_on && format(row.received_on);

          const processed_on = row.processed_on && format(row.processed_on);
          const processing_agent =
            deal_sheet.processing_agent.id &&
            deal_sheet.processing_agent.firstname +
              " " +
              deal_sheet.processing_agent.lastname;

          const onboarding_agent =
            deal_sheet.onboarding_agent.id &&
            deal_sheet.onboarding_agent.firstname +
              " " +
              deal_sheet.onboarding_agent.lastname;

          return (
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Popup
                inverted
                trigger={indicator(
                  created_on,
                  "calendar outline alternate",
                  deal_sheet.appointment_setter
                )}
                content={
                  <div>
                    <span>Appointment Setter</span>
                    <Divided />
                    <Avatar />
                    <b>{appointment_setter}</b>
                    <br />
                    <Calendar />
                    <b>{created_on}</b>
                  </div>
                }
              />

              <Popup
                inverted
                trigger={indicator(
                  closing_agent,
                  "phone",
                  deal_sheet.closing_agent
                )}
                content={
                  closing_agent ? (
                    <div>
                      <span>Closing Agent</span>
                      <Divided />
                      <Avatar />
                      <b>{closing_agent}</b>
                      <br />
                      <Calendar />
                      <b>{sent_on}</b>
                    </div>
                  ) : (
                    "Awaiting sending..."
                  )
                }
              />

              <Popup
                inverted
                trigger={indicator(received_on, "inbox")}
                content={
                  received_on ? (
                    <div>
                      <span>Submitted</span>
                      <Divided />
                      <Calendar />
                      <b>{received_on}</b>
                    </div>
                  ) : (
                    "Awaiting submission..."
                  )
                }
              />

              <Popup
                inverted
                trigger={indicator(
                  processed_on,
                  "fax",
                  deal_sheet.processing_agent
                )}
                content={
                  processed_on ? (
                    <div>
                      <span>Processing Agent</span>
                      <Divided />
                      <Avatar />
                      <b>{processing_agent}</b>
                      <br />
                      <Calendar />
                      <b>{processed_on}</b>
                    </div>
                  ) : (
                    "Awaiting processing..."
                  )
                }
              />

              <Popup
                inverted
                trigger={indicator(
                  onboarding_agent,
                  "sign out",
                  deal_sheet.onboarding_agent
                )}
                content={
                  onboarding_agent ? (
                    <div>
                      <span>Onboarding Agent</span>
                      <Divided />
                      <Avatar />
                      <b>{onboarding_agent}</b>
                    </div>
                  ) : (
                    "Awaiting onboarding..."
                  )
                }
              />
            </div>
          );
        }
      },
      // searchable only columns
      {
        visible: false,
        src: row => moment(row.created_on).format("MMM Do, YYYY")
      },
      {
        visible: false,
        src: row => {
          if (row.deal_sheet.appointment_setter.id) {
            return (
              row.deal_sheet.appointment_setter.firstname +
              " " +
              row.deal_sheet.appointment_setter.lastname
            );
          }
        }
      },
      {
        visible: false,
        src: row => {
          if (row.deal_sheet.closing_agent.id) {
            return (
              row.deal_sheet.closing_agent.firstname +
              " " +
              row.deal_sheet.closing_agent.lastname
            );
          }
        }
      },
      {
        visible: false,
        src: row => {
          if (row.deal_sheet.processing_agent.id) {
            return (
              row.deal_sheet.processing_agent.firstname +
              " " +
              row.deal_sheet.processing_agent.lastname
            );
          }
        }
      },
      {
        visible: false,
        src: row => {
          if (row.deal_sheet.onboarding_agent.id) {
            return (
              row.deal_sheet.onboarding_agent.firstname +
              " " +
              row.deal_sheet.onboarding_agent.lastname
            );
          }
        }
      },
      {
        visible: false,
        src: row => (row.deal_sheet.client ? row.deal_sheet.client.name : null)
      },
      {
        visible: false,
        src: row => (row.deal_sheet.client ? row.deal_sheet.client.email : null)
      }
    ]
  };
};
