import React from "react";
import { Header, Message, Container } from "semantic-ui-react";
import Layout from "components/Layout";
import PortalTable from "components/PortalTable";
import ListFn from "./ListFn";
import Breadcrumb from "./components/Breadcrumb";
export default class ChecksList extends React.Component {
  render() {
    return (
      <Layout noBar>
        <Breadcrumb />
        <Container>
          <Header as={"h1"} dividing style={{ marginTop: "2rem" }}>
            Checks
            <div className={"sub header"}>
              Below are all of the checks registered in the database.
            </div>
          </Header>
          <PortalTable {...ListFn("checks")} />
          <Message>
            <Message.Header>Info</Message.Header>
            <p>
              Processed shows whether or not the check has been "confirmed" to
              have been valid. Any checks not marked as processed are
              automatically deleted at midnight.
            </p>
          </Message>
          <br />
        </Container>
      </Layout>
    );
  }
}
