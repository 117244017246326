import React, { Component } from "react";
import { Form, Table } from "semantic-ui-react";
import styled from "styled-components";

import MoneyInput from "components/MoneyInput";

const visibilityOptions = [
  { key: "def", text: "Default", value: "null" },
  { key: "acc", text: "Accounting Only", value: "accounting" },
  { key: "adm", text: "Admin Only", value: "admin" }
];

class CheckForm extends Component {
  createInput = (element, key, properties) => {
    const { check, update } = this.props;
    return React.cloneElement(element, {
      value: check[key] || "",
      onChange: (e, { value }) => {
        update({
          ...check,
          [key]: value
        });
      },
      ...properties
    });
  };

  createCheckbox = (element, key, properties) => {
    const { check, update } = this.props;

    return React.cloneElement(element, {
      checked: check[key],
      onChange: (e, { checked }) => {
        update({
          ...check,
          [key]: checked
        });
      },
      ...properties
    });
  };

  render() {
    const { check, update, remove } = this.props;
    return (
      <Table.Row>
        <Table.Cell>
          {this.createInput(<Form.Input />, "PayTo", {
            placeholder: "Pay To"
          })}
        </Table.Cell>
        <Table.Cell>
          <Form.Field>
            <MoneyInput
              {...{
                label: "$",
                value: check.Amount,
                onChange: value => {
                  update({
                    ...check,
                    Amount: value
                  });
                }
              }}
            />
          </Form.Field>
        </Table.Cell>
        <Table.Cell>
          {this.createInput(<Form.Input />, "Memo", {
            placeholder: "Memo"
          })}
        </Table.Cell>
        <Table.Cell>
          {this.createInput(<Form.Dropdown />, "visibility", {
            options: visibilityOptions,
            button: true,
            basic: true,
            selection: true
          })}
        </Table.Cell>
        <Table.Cell>
          <Form.Button
            onClick={remove}
            icon={this.props.length <= 1 ? "undo" : "trash alternate outline"}
            color="red"
          />
        </Table.Cell>
      </Table.Row>
    );
  }
}

export default CheckForm;
