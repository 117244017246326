import React from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { Breadcrumb } from "semantic-ui-react";

import ContextBar from "components/ContextBar";

const Element = props => {
  const { unit, unitID } = props;

  return (
    <ContextBar>
      <Breadcrumb>
        <Breadcrumb.Section>
          <Link to={"/"}>Portal</Link>
        </Breadcrumb.Section>
        <Breadcrumb.Divider>/</Breadcrumb.Divider>
        <Breadcrumb.Section>
          <Link to={"/browse/units"}>Units</Link>
        </Breadcrumb.Section>
        <Breadcrumb.Divider>/</Breadcrumb.Divider>
        <Breadcrumb.Section>
          <Link to={"/browse/units/" + unitID}>
            {unit
              ? unit.resort.resort_company.name +
                " " +
                unit.resort.name +
                " " +
                unit.name
              : "Unit #" + unitID.toString().padStart(7, "0")}
          </Link>
        </Breadcrumb.Section>
        <Breadcrumb.Divider>/</Breadcrumb.Divider>
        <Breadcrumb.Section active>Availability Calendar</Breadcrumb.Section>
      </Breadcrumb>
    </ContextBar>
  );
};

Element.propTypes = {
  unit: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]).isRequired,
  unitID: PropTypes.string.isRequired
};

const mapStateToProps = state => ({
  unit: state.Calendar.unit
});

export default connect(mapStateToProps)(Element);
