import React, { useContext } from "react";
import styled from "styled-components";

import { Context } from "../../";

const BackgroundComponent = props => {
  const context = useContext(Context);
  const { expanded } = context.state;
  const { children, drawer = false } = props;

  const segmentProps = {
    inverted: true,
    expanded: expanded ? "true" : undefined,
    drawer: drawer ? "true" : undefined
  };

  return (
    <StyledSegment {...segmentProps}>
      <Container>{children}</Container>
    </StyledSegment>
  );
};

export default BackgroundComponent;

const StyledSegment = styled.div`
  background-color: var(--portalBlack) !important;
  ${({ drawer }) => drawer === "true" && `background: #2a2b2b !important`};
  border-radius: 0px !important;
  height: 100%;
  max-height: 100%;
  position: relative;
  margin: 0 !important;
  padding: 0 !important;
  width: ${({ expanded }) => {
    return expanded === "true" ? "288px !important" : "50px !important";
  }};
  z-index: 999;

  i {
    font-size: 1.25rem;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  max-height: 100vh;
  justify-content: space-between;
`;
