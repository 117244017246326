import React from "react";
import { Container, Header } from "semantic-ui-react";
import Layout from "components/Layout";
import PortalTable from "components/PortalTable";
import CompletionModal from "./components/CompletionModal";
import List from "./components/List";
import injectSheet from "react-jss";
import { connect } from "react-redux";
import Breadcrumb from "./components/Breadcrumb";
const styles = {};
class Resorts extends React.Component {
  state = {
    viewingCompletionHelp: false
  };
  viewCompletionHelp = state => this.setState({ viewingCompletionHelp: state });
  config = () => {
    return List({
      functions: {
        viewCompletion: () => this.viewCompletionHelp(true)
      }
    });
  };

  /**
   * 	Render fn
   */
  render() {
    return (
      <Layout noBar>
        <Breadcrumb />
        <Container style={{ paddingBottom: "4rem" }}>
          <Header as={"h1"} dividing style={{ marginTop: "2rem" }}>
            Resorts
            <div className={"sub header"}>
              Below are all of the resorts registered in the database.
            </div>
          </Header>
          <PortalTable {...this.config()} />
          <CompletionModal
            open={this.state.viewingCompletionHelp}
            close={() => this.viewCompletionHelp(false)}
          />
        </Container>
      </Layout>
    );
  }
}
const styled = injectSheet(styles)(Resorts);
const mapStateToProps = () => ({});
export default connect(mapStateToProps)(styled);
