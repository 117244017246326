import React, { Component } from "react";
import PropTypes from "prop-types";
import { Button, Modal, Dropdown, Form } from "semantic-ui-react";
import AjaxFn from "services/AjaxFn";
import ScheduleControls from "components/ScheduleControls";

export default class Schedule extends Component {
  state = {
    open: false,
    loading: false,
    cancel: false,
    data: initialTimes
  };

  componentWillUnmount() {
    this.cancel();
  }

  cancel = () => {
    if (typeof this.state.cancel === "function") {
      this.state.cancel();
    }
  };

  /**
   * opens modal, attempts to read schedule from props and
   * set data to resemble it
   */
  handleOpen = () => {
    const { ContentManager } = this.props;
    const { account_schedule } = ContentManager.state.editorSource;

    const newState = {
      open: true,
      data: initialTimes
    };

    for (var k in initialTimes) {
      const day = account_schedule[k];

      newState.data[k] = day && account_schedule[k].split(" ")[1];
    }

    this.setState(newState);
  };

  /**
   * closes modal, if not loading
   */
  handleClose = () => {
    if (!this.state.loading) {
      this.setState({ open: false });
    }
  };

  /**
   * onChange handler for createInput()
   * @param {String} key
   * @param {Mixed} value
   */
  handleChange = (key, value) => {
    this.setState({
      data: {
        ...this.state.data,
        [key]: value
      }
    });
  };

  submit = () => {
    const { data } = this.state;
    const { ContentManager } = this.props;

    this.setState({
      loading: true,
      cancel: AjaxFn({
        url: "/apis/portal/accountschedules",
        data: {
          action: "update",
          data: {
            ID: ContentManager.state.editorSource.account_schedule.id,
            ...data
          }
        },
        success: () => {
          window.toastr.success();
          this.handleClose();
          ContentManager.functions.closeEditor();
          ContentManager.functions.refresh();
        },
        failure: error => {
          window.toastr.error(error);
          this.setState({ loading: false, cancel: false });
        }
      })
    });
  };

  render() {
    const { open, loading, data } = this.state;
    const { ContentManager } = this.props;
    const { handleOpen, handleClose, submit } = this;
    const { restricted } = ContentManager.state.editorSource.account_role;

    const modalProps = {
      trigger: (
        <Dropdown.Item
          disabled={!restricted}
          onClick={handleOpen}
          icon="calendar outline"
          text="Schedule"
        />
      ),
      size: "tiny",
      open,
      onClose: handleClose
    };

    return (
      <Modal {...modalProps}>
        <Modal.Header>Account Schedule</Modal.Header>
        <Modal.Content>
          <p>
            Specify what the users schedule will look like. If the user does not
            have the permission to clock in outside of hours, they will need
            manager permission to clock in before the times below.
          </p>
          <Form>
            {days.map((day, index) => (
              <Form.Field key={index}>
                <label>{day[1]}</label>
                <ScheduleControls
                  value={data[day[0]]}
                  handleChange={value => this.handleChange(day[0], value)}
                />
              </Form.Field>
            ))}
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button primary content="Save" onClick={submit} loading={loading} />
          <Button content="Cancel" onClick={handleClose} disabled={loading} />
        </Modal.Actions>
      </Modal>
    );
  }
}

Schedule.propTypes = {
  ContentManager: PropTypes.shape({
    state: PropTypes.shape({
      editorSource: PropTypes.shape({
        account_schedule: PropTypes.shape({
          id: PropTypes.number.isRequired,
          mon: PropTypes.string.isRequired,
          tue: PropTypes.string.isRequired,
          wed: PropTypes.string.isRequired,
          thu: PropTypes.string.isRequired,
          fri: PropTypes.string.isRequired,
          sat: PropTypes.string.isRequired,
          sun: PropTypes.string.isRequired
        }).isRequired,
        account_role: PropTypes.shape({
          restricted: PropTypes.bool.isRequired
        }).isRequired
      }).isRequired
    }).isRequired
  }).isRequired
};

const initialTimes = {
  mon: "10:00:00",
  tue: "10:00:00",
  wed: "10:00:00",
  thu: "10:00:00",
  fri: "10:00:00",
  sat: null,
  sun: null
};

const days = [
  ["mon", "Monday"],
  ["tue", "Tuesday"],
  ["wed", "Wednesday"],
  ["thu", "Thursday"],
  ["fri", "Friday"],
  ["sat", "Saturday"],
  ["sun", "Sunday"]
];
