import InitialState from "./initial.js";
export default (state = InitialState, action) => {
  switch (action.type) {
    case "TIMEPUNCH_MANAGER_RELOAD":
      return {
        ...state,
        loading: true,
        error: "",
        accounts: [],
        timepunches: [],
        processed: [],
        selected_account: action.unselect ? false : state.selected_account,
        selected_timepunch: action.unselect ? false : state.selected_timepunch
      };
    case "TIMEPUNCH_MANAGER_SET_DATA":
      return {
        ...state,
        loading: false,
        ...action.data
      };
    case "TIMEPUNCH_MANAGER_ERROR":
      return {
        ...state,
        loading: false,
        error: action.data
      };
    case "TIMEPUNCH_MANAGER_SET_ACCOUNTS":
      return {
        ...state,
        accounts: action.data
      };
    case "TIMEPUNCH_MANAGER_SET_TIMEPUNCHES":
      return {
        ...state,
        timepunches: action.data
      };
    case "TIMEPUNCH_MANAGER_SET_PROCESSED":
      return {
        ...state,
        loading: false,
        processed: action.data
      };
    case "TIMEPUNCH_MANAGER_SET_DATE":
      return {
        ...state,
        date: action.data
      };
    case "TIMEPUNCH_MANAGER_SET_ACCOUNT":
      return {
        ...state,
        selected_account: action.data,
        selected_timepunch: false
      };
    case "TIMEPUNCH_MANAGER_SET_PUNCH":
      return {
        ...state,
        selected_timepunch: action.data
      };
    case "TIMEPUNCH_MANAGER_SET_FILTERS":
      return {
        ...state,
        filters: action.data,
        selected_account: false,
        selected_timepunch: false
      };
    case "TIMEPUNCH_MANAGER_TOGGLE_DELETED":
      return {
        ...state,
        show_deleted: !state.show_deleted
      };
    default:
      return state;
  }
};
