import React, { Component, Fragment } from "react";
import { Redirect } from "react-router-dom";
import {
  Button,
  Divider,
  Form,
  Message,
  Modal,
  Header
} from "semantic-ui-react";
import AjaxFn from "services/AjaxFn";
import FocusBar from "components/FocusBar";

const infoMessages = [
  "The name and message will be written in the email, so make them professional.",
  "The email will contain a link that the client can use to fill out and submit the contract. Please verify that the email address is correct."
];

class SendButton extends Component {
  state = {
    open: false,
    loading: false,
    name: "",
    email: "",
    message: "",
    isPreviewOpen: false
    // redirect: false
  };

  // handler for the initial opening
  handleOpen = () => this.setState({ open: true });

  // handler for closing modal
  handleClose = () => {
    const { loading } = this.state;
    if (!loading) {
      this.setState({ open: false });
    }
  };

  // handler for the preview modal
  // does not control parent modal
  handlePreview = () => {
    const { isPreviewOpen } = this.state;
    this.setState({ isPreviewOpen: !isPreviewOpen });
  };

  // validation
  canSave = () => {
    const { loading, name, email, message } = this.state;
    return !loading && name && email && message;
  };

  // function to send agreement
  send = () => {
    const { email, message, name } = this.state;
    const { data } = this.props;

    this.setState({ loading: true });
    AjaxFn({
      url: "/apis/portal/agreements",
      data: {
        action: "send",
        data: {
          ID: data.id,
          email: email,
          message: message,
          recipient: name
        }
      },
      success: () => {
        window.toastr.success("", "Agreement sent");
        this.props.reload();
        // this.setState({
        //   redirect: true
        // });
      },
      failure: error => {
        window.toastr.error(error);
        this.setState({ loading: false });
      }
    });
  };

  render() {
    const {
      email,
      name,
      message,
      open,
      loading,
      isPreviewOpen
      // redirect
    } = this.state;

    const modalProps = {
      open,
      size: "small",
      trigger: (
        <FocusBar.Button
          icon="fas fa-paper-plane"
          content="Send Agreement"
          onClick={this.handleOpen}
        />
      )
    };

    // if (redirect) return <Redirect to="/browse/agreements" />;

    return (
      <Fragment>
        <Modal {...modalProps}>
          <Modal.Header content="Send Agreement" />
          <Modal.Content scrolling>
            <Form>
              <Form.Input
                label="Email Address"
                placeholder="Email Address"
                value={email}
                onChange={(e, { value }) => this.setState({ email: value })}
                required
              />
              <Form.Field required>
                <label>Name</label>
                <Form.Input
                  placeholder="Name"
                  value={name}
                  onChange={(e, { value }) => this.setState({ name: value })}
                />
              </Form.Field>
              <Form.Field required>
                <label>Message</label>
                <Form.TextArea
                  placeholder="Message"
                  value={message}
                  onChange={(e, { value }) => this.setState({ message: value })}
                />
              </Form.Field>
            </Form>
          </Modal.Content>
          <Modal.Content style={{ paddingTop: "0" }}>
            <Message
              icon="info circle"
              list={infoMessages}
              warning
              style={{ marginTop: 1 }}
            />
          </Modal.Content>
          <Modal.Actions>
            <Button
              content="Cancel"
              onClick={this.handleClose}
              disabled={loading}
              basic
            />
            <Button
              content="Preview"
              icon="eye"
              primary
              onClick={this.handlePreview}
              disabled={!this.canSave()}
            />
          </Modal.Actions>
        </Modal>

        <PreviewAgreement
          name={name}
          email={email}
          message={message}
          isOpen={isPreviewOpen}
          submit={this.send}
          toggle={this.handlePreview}
          loading={loading}
        />
      </Fragment>
    );
  }
}

const PreviewAgreement = ({
  name,
  email,
  message,
  isOpen,
  submit,
  toggle,
  loading
}) => {
  return (
    <Modal size="tiny" open={isOpen} onClose={() => toggle()}>
      <Modal.Header content="Send Agreement Preview" />
      <Modal.Content scrolling>
        <p>to: {email}</p>
        <Divider />
        <Header as="h4" content={`Dear ${name},`} />
        <p style={{ whiteSpace: "pre-wrap" }}>{message}</p>
      </Modal.Content>
      <Modal.Actions>
        <Button
          content="Cancel"
          onClick={() => toggle()}
          disabled={loading}
          basic
        />
        <Button
          content="Send Agreement"
          icon="mail"
          primary
          onClick={() => submit()}
          loading={loading}
        />
      </Modal.Actions>
    </Modal>
  );
};

export default SendButton;
