import React, { useContext } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import { Button } from "semantic-ui-react";

import { Context } from "../../";

const DropdownComponent = props => {
  const context = useContext(Context);

  const { open, toggle, content = "", ...restOfProps } = props;

  const buttonProps = {
    onClick: toggle,
    expanded: context.state.expanded ? "true" : undefined,
    title: content,
    ...restOfProps
  };

  const Icon = () => (
    <i className={open ? "fas fa-minus" : "fas fa-chevron-down"} />
  );

  return (
    <StyledButton {...buttonProps}>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        {context.state.expanded ? (
          <>
            <span children={content} />
            <Icon />
          </>
        ) : (
          <>
            <div />
            <Icon />
            <div />
          </>
        )}
      </div>
    </StyledButton>
  );
};

DropdownComponent.propTypes = {
  toggle: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired
};

const StyledButton = styled(Button)`
  display: block !important;
  border-radius: 0px !important;
  margin: none !important;
  padding: ${({ expanded }) =>
    expanded === "true" ? "1rem 1.5rem !important" : "1rem 0rem !important"};
  &&& {
    &&& {
      border: 1px solid transparent !important;
      box-shadow: none !important;
      &:hover {
        background: rgba(255, 255, 255, 0.05) !important;
        border: 1px solid rgba(255, 255, 255, 0.055) !important;
      }
      &:focus {
        background: rgba(255, 255, 255, 0.075) !important;
        border: 1px solid #2185d0 !important;
      }
    }
  }

  span {
    /* TODO: use the correct shade of white */
    color: #fff !important;
  }
`;

export default DropdownComponent;
