import React from "react";
import { Dropdown, Form } from "semantic-ui-react";
import { connect } from "react-redux";
import { setFilters } from "redux/PortalTables/actions.js";
import AjaxDropdown from "components/AjaxDropdown";
/**
 *  @prop namespace
 */
class Filters extends React.Component {
  state = {
    open: false,
    applied: {
      account_company_id: "any",
      active: true
    }
  };
  componentDidMount() {
    this.updateFilters();
  }
  updateFilterState = (key, value) => {
    let newState = {
      applied: {
        ...this.state.applied,
        [key]: value
      }
    };
    this.setState(newState, this.updateFilters);
  };
  buildFilters = () => {
    const { applied } = this.state;
    const functions = [];
    if (applied.active !== "any") {
      functions.push(row => row.active === applied.active);
    }
    if (applied.account_company_id !== "any") {
      functions.push(
        row => row.account_company.id === applied.account_company_id
      );
    }
    return functions;
  };
  updateFilters = () => {
    const action = setFilters(this.props.namespace, this.buildFilters());
    this.props.dispatch(action);
  };
  render() {
    return (
      <Form>
        <Form.Group>
          <Form.Field width={10} />
          <Form.Field width={3}>
            <label>Company</label>
            <AjaxDropdown
              url={"/apis/portal/accountcompanies"}
              data={{ action: "read_for_dropdown" }}
              dataRetreiver={({ message }) =>
                message
                  .map(({ id, name }) => ({
                    value: id,
                    text: name
                  }))
                  .concat({
                    value: "any",
                    text: "Any"
                  })
              }
              onChange={(e, { value }) =>
                this.updateFilterState("account_company_id", value)
              }
              value={this.state.applied.account_company_id}
            />
          </Form.Field>
          <Form.Field width={3}>
            <label>Active</label>
            <Dropdown
              button
              fluid
              selection
              basic
              onChange={(e, { value }) =>
                this.updateFilterState("active", value)
              }
              value={this.state.applied.active}
              options={[
                { key: true, text: "Yes", value: true },
                { key: false, text: "No", value: false },
                { key: "any", text: "Any", value: "any" }
              ]}
            />
          </Form.Field>
        </Form.Group>
      </Form>
    );
  }
}
const mapStateToProps = () => ({});
export default connect(mapStateToProps)(Filters);
