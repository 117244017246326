import React from "react";
import styled from "styled-components";

import Filters from "./components/Filters";

const Header = () => (
  <MenuBar>
    <Filters />
  </MenuBar>
);

export default Header;

const MenuBar = styled.div`
  position: relative;
  .form {
    display: grid;
    grid-template-columns: repeat(16, auto);
    grid-template-rows: auto;
    grid-gap: 1rem;

    .field {
      display: flex;
      flex-wrap: wrap;
      grid-column: span 3;
      margin: 0;
      align-self: start;
      &:nth-of-type(5),
      &:nth-of-type(6) {
        grid-column: span 2;
        width: 100%;
      }
      label {
        min-width: 50%;
      }
      .dropdown {
        min-width: 0;
        width: 100%;
      }
      .button {
        margin: 0 !important;
        width: 100%;
      }
      /* MyCalWrapper */
      div {
        width: 100%;
        .input input {
          width: 100% !important;
        }
      }
    }

    @media screen and (max-width: 1200px) {
      .field {
        grid-column: span 4;
        &:nth-of-type(5),
        &:nth-of-type(6) {
          grid-row: 2;
          label {
            display: none;
          }
        }

        &:nth-of-type(5) {
          grid-column: 15;
        }
        &:nth-of-type(6) {
          grid-column: 16;
        }
      }
    }

    @media screen and (max-width: 992px) {
      .field {
        grid-column: span 8;
        &:nth-of-type(5),
        &:nth-of-type(6) {
          grid-row: 3;
        }
      }
    }

    @media screen and (max-width: 568px) {
      .field {
        grid-column: span 16;
        &:nth-of-type(5),
        &:nth-of-type(6) {
          grid-row: auto;
          grid-column: span 8;
        }
      }
    }
  }
`;
