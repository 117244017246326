import React, { Component } from "react";
import styled from "styled-components";

import { Button, Icon, Modal, Table, Dropdown } from "semantic-ui-react";

import AjaxComponent from "components/AjaxComponent";
import naturalSort from "utils/naturalSort";
import AjaxFn from "services/AjaxFn";

export default class Pages extends Component {
  state = {
    open: false,
    settings: {},
    loading: false
  };

  handleAjax = data => {
    this.setState({ loading: true });
    return AjaxFn({
      url: "/apis/portal/accountroles",
      data: {
        action: "set_pages",
        data
      },
      success: () => {
        window.toastr.success();
        this.handleClose();
        this.props.ContentManager.functions.closeEditor();
        this.props.ContentManager.functions.refresh();
      },
      failure: e => {
        window.toastr.error(e);
        this.setState({ loading: false });
      }
    });
  };

  handleOpen = () => this.setState({ open: true });

  handleClose = () => {
    this.setState({
      open: false,
      loading: false,
      settings: {}
    });
  };

  /**
   *	Filters all changes for those not set to 'default' and saves them
   */
  submitChanges = () => {
    const { ContentManager } = this.props;
    // establish the user's current settings
    const existingPages = {};
    ContentManager.state.editorSource.page_settings.map(({ page, value }) => {
      existingPages[page.id] = value;
      return null;
    });
    const combinedSettings = {
      ...existingPages,
      ...this.state.settings
    };
    const filtered = [];
    for (var page_id in combinedSettings) {
      const value = combinedSettings[page_id];
      if (value) {
        filtered.push({ page_id, value });
      }
    }
    this.handleAjax({
      ID: this.props.ContentManager.state.editorSource.id,
      data: filtered
    });
  };

  /**
   *	Update a page's setting for a user
   *	@param {Integer} page_id
   *	@param {Mixed} value
   */
  updateSetting = (page_id, value) => {
    this.setState({
      settings: {
        ...this.state.settings,
        [page_id]: value
      }
    });
  };

  /**
   *	Get the table to display
   *	@param {Array} pages
   */
  getTable = pages => {
    const { ContentManager } = this.props;
    const pageSettings = [...ContentManager.state.editorSource.page_settings];
    const sorted = (pages || []).sort((a, b) => naturalSort(a.name, b.name));
    return sorted.map(page => {
      // Find the user override, use default if none exists
      const fn = ({ page: { id } }) => id === page.id;
      const setting = pageSettings.find(fn) || { value: 0 };
      return (
        <Table.Row key={page.id}>
          <Table.Cell width={10}>
            <Icon name={page.icon} style={{ marginRight: "1rem" }} />
            {page.name}
          </Table.Cell>
          <Table.Cell width={6}>
            <Dropdown
              fluid
              inline
              options={options}
              defaultValue={setting.value}
              onChange={(e, { value }) => this.updateSetting(page.id, value)}
            />
          </Table.Cell>
        </Table.Row>
      );
    });
  };

  render() {
    const { ContentManager } = this.props;
    const { open } = this.state;

    const { restricted } = ContentManager.state.editorSource;

    const modalConfig = {
      size: "tiny",
      open,
      onClose: this.handleClose,
      trigger: (
        <Button
          content="Pages"
          icon="copy"
          fluid
          onClick={this.handleOpen}
          disabled={!restricted}
        />
      )
    };

    return (
      <Modal {...modalConfig}>
        <Modal.Header content="Account Pages" />
        <ModalContentHeader>
          <Table basic="very">
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell width={10} content="Page" />
                <Table.HeaderCell width={6} content="Setting" />
              </Table.Row>
            </Table.Header>
          </Table>
        </ModalContentHeader>
        <Modal.Content scrolling>
          <AjaxComponent ajaxConfig={{ url: "/apis/portal/pages?action=read" }}>
            {({ data }) => (
              <Table basic={"very"}>
                <Table.Body>{this.getTable(data)}</Table.Body>
              </Table>
            )}
          </AjaxComponent>
        </Modal.Content>
        <Modal.Actions>
          <Button
            onClick={this.handleClose}
            disabled={this.state.loading}
            content="Cancel"
            basic
          />
          <Button
            primary
            onClick={this.submitChanges}
            loading={this.state.loading}
            content="Save"
            icon="save"
          />
        </Modal.Actions>
      </Modal>
    );
  }
}

const options = [
  {
    text: (
      <React.Fragment>
        <Icon name={"checkmark"} color={"green"} />
        Shown
      </React.Fragment>
    ),
    value: 1
  },
  {
    text: (
      <React.Fragment>
        <Icon name={"cancel"} color={"red"} />
        Hidden
      </React.Fragment>
    ),
    value: 0
  }
];

const ModalContentHeader = styled(Modal.Content)`
  &&& {
    font-size: 1rem;
    padding: 1rem 1.5rem;
    border-bottom: 1px solid rgba(34, 36, 38, 0.15);
    table {
      thead {
        tr {
          th {
            border-bottom: none;
            padding-bottom: 0;
            padding-left: 0;
          }
        }
      }
    }
  }
`;
