import React from "react";
import { Container, Divider } from "semantic-ui-react";
import Agreement from "components/Agreement";
import AjaxComponent from "components/AjaxComponent";
import WithState from "components/WithState";
import Breadcrumb from "./components/Breadcrumb";

export default class AgreementExternal extends React.Component {
  render() {
    const ajaxConfig = {
      ajaxConfig: {
        url: "/apis/portal/agreements",
        data: {
          action: "read_one_external",
          data: {
            ID: this.props.match.params.id,
            key: this.props.match.params.key
          }
        }
      },
      stateful: false
    };
    return (
      <AjaxComponent {...ajaxConfig}>
        {({ data, loading, error }) => {
          return (
            <div>
              <Breadcrumb />
              <br />
              <Container>
                <WithState loading={loading} error={error}>
                  <Agreement
                    data={data}
                    type={"external"}
                    token={this.props.match.params.key}
                  />
                </WithState>
              </Container>
            </div>
          );
        }}
      </AjaxComponent>
    );
  }
}
