import React, { Component } from "react";
import { Container, Divider, Form } from "semantic-ui-react";
import moment from "moment";
import styled from "styled-components";

import { Wrapper } from "components/Agreement/context";
import { isObjectEmpty } from "utils/objects";
import { addCommas } from "utils/numbers";

import { specificCompany } from "../../helpers";
import Header from "../Header";
import Highlight from "../Highlight";
import Info, { Item } from "./components/Info";

import { PageBreak } from "components/Printing";

class Legal extends Component {
  static propTypes = {};

  render() {
    const { context } = this.props;
    const { data } = context;
    const {
      functions: { createInput },
    } = context;
    const { account_company } = context.agreement.original_data.template;
    const company = context.agreement.account_company_legal_name;
    const address = specificCompany(
      account_company.name,
      context.agreement.original_data.created_on
    );
    const agreementDate = moment(data.agreement_date).format("MMMM DD, YYYY");
    const paymentStartDate = moment(data.loan_start_date).format("MM/DD/YYYY");
    const annualRate = `${Number(data.interest_rate).toFixed(2)}%`;
    const financeCharge = `$${addCommas(data.finance_charge, 2)}`;
    const amountFinanced = `$${addCommas(data.financed_amount, 2)}`;
    const totalOfPayments = `$${addCommas(data.total_payment, 2)}`;
    const totalSalePrice = `$${addCommas(data.total_sale, 2)}`;
    const monthlyPayment = `$${addCommas(data.monthly_payment, 2)}`;
    const downPayment = `$${addCommas(data.down_payment, 2)}`;
    const agreementTerm = data.agreement_term;

    const traveler = data.traveler_name || "N/A";
    const cotraveler = data.cotraveler_name || "N/A";

    const highlight = (text) => <Highlight content={text} />;

    if (isObjectEmpty(context.data)) return null;
    return (
      <Container text>
        <Header hideBranding>
          <p>Exhibit “a” to</p>
          <p>Deed transfer agreement and consumer note</p>
          <p>Truth in lending act disclosure statement</p>
        </Header>

        <Info>
          <Item header="Creditor" text={company} icon="fa-user-tie" />
          <Item header="Holder" text={address.full} icon="fa-address-card" />
          <Item header="Date" text={agreementDate} icon="fa-calendar-alt" />
          <Item header="Traveler" text={traveler} icon="fa-user" />
          <Item header="Co-Traveler" text={cotraveler} icon="fa-user-friends" />
        </Info>

        <Divider />

        <Info>
          <Item
            header="Annual % rate"
            text="The cost of your credit is a yearly rate"
            data={annualRate}
          />
          <Item
            header="Amount Financed"
            text="The amount of credit provided to you or on your behalf"
            data={amountFinanced}
          />
          <Item
            header="Total Sale Price"
            text="The total cost of your purchase on credit, including your down payment"
            data={totalSalePrice}
          />
          <Item
            header="Finance Charge"
            text="The dollar amount the credit will cost you"
            data={financeCharge}
          />
          <Item
            header="Total of Payments"
            text="The amount you will have paid after you have made all payments as scheduled"
            data={totalOfPayments}
          />
        </Info>

        <p>
          You have a right to receive an itemization of the amount financed.
        </p>
        <p>Your payment Schedule will be:</p>

        <Info autoFlow="row" columns="3" rows="auto">
          <Item header={"Number of\n Payments"} data={agreementTerm} />
          <Item header={"Amount of\n Payments"} data={monthlyPayment} />
          <Item
            header="Payments are due monthly beginning"
            data={paymentStartDate}
          />
        </Info>

        <PageBreak />

        <LateCharge>
          <h3>Early Payoffs:</h3>
          <p>No penalties for early payoff</p>
        </LateCharge>

        <LateCharge>
          <h3>Late Charge:</h3>
          <p>
            If a payment is not paid within ten (10) days after its due date you
            will be charged a late fee of 5% or $25, whichever is greater.
          </p>
        </LateCharge>

        <p>
          See your agreement documents for any additional information about
          nonpayment, default, any required repayment in full before the
          scheduled date and prepayment refunds and penalties.
        </p>
        <p>The Letter 'e' means estimate</p>

        <p>
          Itemization of Amount Financed of
          <Highlight floatRight content={amountFinanced} />
        </p>
        <p>
          Amount Credited to Your Account
          <Highlight floatRight content={downPayment} />
        </p>

        <StyledNote>
          <h3>Promissory Note</h3>
          <p>
            For value received, each of the undersigned (hereafter collectively
            called Applicant), jointly and severally promise to pay to the order
            of {highlight(company)}, or holder, the sum of{" "}
            {highlight(totalOfPayments)} United States dollars payable to{" "}
            {highlight(company)} or such places as the note holder may
            designate, in {highlight(agreementTerm)} equal consecutive monthly
            installments of {highlight(monthlyPayment)} beginning{" "}
            {highlight(paymentStartDate)} and one like installment on the same
            day of each of the following months until paid in full.
          </p>
          <p>
            In the event Applicant fails to pay an installment due hereunder,
            holder may, at its option, upon thirty (30) days written notice to
            Applicant, declare immediately due and payable all amounts and
            become due hereunder. The waiver by holder of any default shall not
            constitute a waiver of that or any subsequent default. Each
            applicant and endorser hereof severally waives presentment demand,
            protests, and notice of dishonor. In the event of default hereunder,
            where permitted by law, the Applicant also agrees to pay all costs
            of collection, including reasonable attorney fees.
          </p>
          <h4>WITNESS THE FOLLOWING SIGNATURES AND SEALS</h4>
          <p>
            By signing below, Client and/or Co-Client hereby acknowledges that a
            completed copy of this Truth in Lending Act Disclosure Statement has
            been received and note executed, if applicable.
          </p>
          {context.agreement.original_data.created_on >=
          "2019-03-06 14:00:00" ? (
            <>
              <p>
                The undersigned hereby consent(s) to {highlight(company)}’s use
                of a non-business consumer credit report on the undersigned in
                order to further evaluate the credit worthiness of the
                undersigned as principal(s), member(s), partner(s),
                proprietor(s) and/or guarantor(s) in connection with the
                extension of business credit as contemplated by this credit
                application. The undersigned hereby authorize(s){" "}
                {highlight(company)} to utilize a consumer credit report on the
                undersigned from time to time in connection with the extension
                or continuation of the business credit represented by this
                credit application. The undersigned as [an] individual(s) hereby
                knowingly consent to the use of such credit report consistent
                with the Federal Fair Credit Reporting Act as contained in 15
                U.S.C. @ 1681 et seq.
              </p>
              <p>
                The Federal Equal Credit Opportunity Act prohibits creditors
                from discriminating against credit applicants on the basis of
                race, color, religion, national origin, sex, marital status, age
                (provided the applicant has the capacity to enter into a binding
                contract); because all or part of the applicants income derives
                from any public assistance program; or because the applicant has
                in good faith exercised any right under the Consumer Credit
                Protection Act. The federal agency that administers compliance
                with this law concerning this creditor is the Federal Trade
                Commission, Equal Credit Opportunity Washington, D.C. 20580.
              </p>
            </>
          ) : null}
        </StyledNote>

        <StyledSignature widths="equal">
          {createInput(<Form.Input />, "signature", {
            label: "Client's Signature",
            autoComplete: "off",
            required: true,
          })}
          <Form.Input label="Company's Signature" value={company} readOnly />
        </StyledSignature>
      </Container>
    );
  }
}

export default Wrapper(Legal);

const LateCharge = styled.div`
  display: flex;
  flex: 0 1 auto;

  @media print {
    margin-top: 0.25in;
  }

  h3 {
    margin-right: 1rem;
    white-space: nowrap;
    font-size: 1.25rem;
    text-transform: uppercase;
  }
`;

const StyledNote = styled.div`
  h3 {
    text-align: center;
    text-transform: uppercase;
  }
  h4 {
    text-transform: uppercase;
  }
`;

const StyledSignature = styled(Form.Group)``;
