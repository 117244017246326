import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { set } from "redux/Storage/actions";
import { Checkbox } from "semantic-ui-react";

const LocalStorageToggle = props => {
  const { storage, index, set, active, onChange, ...rest } = props;
  const value = props.storage[index];

  return (
    <Checkbox
      toggle
      {...rest}
      defaultChecked={active(value)}
      onChange={() => set(index, onChange(value))}
    />
  );
};

LocalStorageToggle.propTypes = {
  set: PropTypes.func.isRequired,
  storage: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  active: PropTypes.func.isRequired,
  index: PropTypes.string.isRequired
};

const mapStateToProps = state => ({
  storage: state.Storage
});

const mapDispatchToProps = {
  set
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LocalStorageToggle);
