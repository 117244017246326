import React from "react";

export const Admin = () => (
  <>
    <h1>Admin Resources</h1>
    <a
      href="https://teamup.com/kshzc88oxihc2ftzgs"
      target="_blank"
      rel="noopener noreferrer"
    >
      <i className="fas fa-external-link-alt" />
      Administrator Calendars
    </a>
    <a
      href="https://teamup.com/ks6fq99v3quf58z8h7"
      target="_blank"
      rel="noopener noreferrer"
    >
      <i className="fas fa-external-link-alt" />
      Team 1
    </a>
    <a
      href="https://teamup.com/ksmwrditwktwxij5gt"
      target="_blank"
      rel="noopener noreferrer"
    >
      <i className="fas fa-external-link-alt" />
      Team 2
    </a>
  </>
);
export const Accounting = () => (
  <>
    <h1>Accounting Resources</h1>
    <a
      href="https://teamup.com/kse4bzic113o6twvii"
      target="_blank"
      rel="noopener noreferrer"
    >
      <i className="fas fa-external-link-alt" />
      VMG Calendars
    </a>
    <a
      href="https://teamup.com/ksoz87pizunbk525x2"
      target="_blank"
      rel="noopener noreferrer"
    >
      <i className="fas fa-external-link-alt" />
      Team 1 Deal Processing Calendar
    </a>
    <a
      href="https://teamup.com/ks2ut8q5mvuhdrgwf9"
      target="_blank"
      rel="noopener noreferrer"
    >
      <i className="fas fa-external-link-alt" />
      Team 2 Deal Processing Calendar
    </a>
  </>
);
export const Booking = () => (
  <>
    <h1>Booking Resources</h1>
    <a
      href="https://teamup.com/ksk38nfsfqgiht9atv"
      target="_blank"
      rel="noopener noreferrer"
    >
      <i className="fas fa-external-link-alt" />
      Booking Department Calendars
    </a>
  </>
);
export const CustomerService = () => (
  <>
    <h1>Customer Service Resources</h1>
    <a
      href="https://teamup.com/ks37btr8g1937kw59w"
      target="_blank"
      rel="noopener noreferrer"
    >
      <i className="fas fa-external-link-alt" />
      Customer Service Calendars
    </a>
  </>
);
export const Developer = () => (
  <>
    <h1>Developer Resources</h1>
    <a
      href="https://teamup.com/ksm4prb4n3pi158mfd"
      target="_blank"
      rel="noopener noreferrer"
    >
      <i className="fas fa-external-link-alt" />
      Developer Calendars
    </a>
  </>
);
export const Reception = () => (
  <>
    <h1>Admin Resources</h1>
    <a
      href="https://teamup.com/ksv9yq9n88c7q3px3r"
      target="_blank"
      rel="noopener noreferrer"
    >
      <i className="fas fa-external-link-alt" />
      Reception Calendars
    </a>
  </>
);
