import React, { useState } from "react";
import PropTypes from "prop-types";

import { Button } from "semantic-ui-react";

import AjaxFn from "services/AjaxFn";

const Activate = props => {
  const [loading, setLoading] = useState(false);
  const { resort, reloadResort } = props;

  const handleClick = () => {
    setLoading(true);
    AjaxFn({
      url: "/apis/portal/resorts",
      data: {
        action: "update",
        data: {
          ID: resort.id,
          active: resort.active ? 0 : 1
        }
      },
      success: () => {
        window.toastr.success();
        reloadResort();
      },
      failure: error => {
        window.toastr.error(error);
        setLoading(false);
      }
    });
  };

  if (resort.active) {
    return (
      <Button
        basic
        color="red"
        content="Deactivate"
        onClick={handleClick}
        loading={loading}
        disabled={loading}
      />
    );
  } else {
    return (
      <Button
        basic
        primary
        content="Activate"
        onClick={handleClick}
        loading={loading}
        disabled={loading}
      />
    );
  }
};

Activate.propTypes = {
  resort: PropTypes.object.isRequired,
  reloadResort: PropTypes.func.isRequired
};

export default Activate;
