import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { reload } from "redux/TimepunchManager/actions";

import { Form, Button } from "semantic-ui-react";
import { MyCalWrapper } from "components/MyCal";
import moment from "moment";

import AjaxFn from "services/AjaxFn";

export class EditForm extends Component {
  static propTypes = {
    timepunch: PropTypes.object.isRequired,
    locked: PropTypes.bool.isRequired,
    setLocked: PropTypes.func.isRequired
  };

  state = {
    in: moment(this.props.timepunch.in),
    out: this.props.timepunch.out ? moment(this.props.timepunch.out) : false,
    reason: "",
    cancel: false,
    loading: false
  };

  componentWillUnmount() {
    this.cancel();
  }

  cancel = () => {
    if (typeof this.state.cancel === "function") {
      this.state.cancel();
    }
  };

  reset = () => {
    this.setState({
      in: moment(this.props.timepunch.in),
      out: this.props.timepunch.out ? moment(this.props.timepunch.out) : false,
      reason: ""
    });
  };

  handleSubmit = e => {
    const { timepunch, reload, setLocked } = this.props;
    setLocked(true);
    e.preventDefault();
    this.setState({
      loading: true,
      cancel: AjaxFn({
        url: "/apis/portal/timepunches",
        data: {
          action: "update",
          data: {
            ID: timepunch.id,
            in: this.state.in.format("YYYY-MM-DD HH:mm:ss"),
            out: this.state.out
              ? this.state.out.format("YYYY-MM-DD HH:mm:ss")
              : undefined,
            reason: this.state.reason
          }
        },
        success: () => {
          window.toastr.success();
          reload(false);
        },
        failure: error => {
          setLocked(false);
          this.setState({ loading: false });
          window.toastr.error(error);
        }
      })
    });
  };

  hasChanged = () => {
    if (this.state.reason !== "") {
      return true;
    }

    const inFormatted = this.state.in.format("YYYY-MM-DD HH:mm:ss");
    if (inFormatted !== this.props.timepunch.in) {
      return true;
    }

    if (this.props.timepunch.out) {
      const outFormatted = this.state.out.format("YYYY-MM-DD HH:mm:ss");
      if (outFormatted !== this.props.timepunch.out) {
        return true;
      }
    }

    return false;
  };

  render() {
    const { timepunch, locked } = this.props;
    const changed = this.hasChanged();
    return (
      <Form onSubmit={this.handleSubmit}>
        <Form.Group>
          <Form.Field width={8} required>
            <label>Punch In</label>
            <MyCalWrapper
              type="datetime"
              value={this.state.in}
              onChange={(e, { value }) => this.setState({ in: value })}
              format={false}
              outputMask={"MMM Do YYYY, h:mm:ss a"}
              disabled={locked}
            />
          </Form.Field>
          <Form.Field width={8} required={!!timepunch.out}>
            <label>Punch Out</label>
            {this.state.out ? (
              <MyCalWrapper
                type="datetime"
                value={this.state.out}
                onChange={(e, { value }) => this.setState({ out: value })}
                format={false}
                outputMask={"MMM Do YYYY, h:mm:ss a"}
                disabled={locked}
              />
            ) : (
              <p>User must be clocked out to edit punch-out.</p>
            )}
          </Form.Field>
        </Form.Group>
        <Form.TextArea
          width={16}
          label="Reason for Change"
          value={this.state.reason}
          onChange={(e, { value }) => this.setState({ reason: value })}
          placeholder="Reason for change..."
          disabled={locked}
          required
        />
        {changed && (
          <div style={{ textAlign: "right" }}>
            <Button
              type="button"
              content="Discard"
              onClick={this.reset}
              disabled={locked}
            />
            <Button
              type="submit"
              content="Save"
              color="blue"
              loading={this.state.loading}
              disabled={locked}
            />
          </div>
        )}
      </Form>
    );
  }
}

const mapStateToProps = state => ({});

const mapDispatchToProps = { reload };

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditForm);
