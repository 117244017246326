export default (data) =>
  data.map((row) => {
    row.completion = getCompletion(row);
    return row;
  });
const getCompletion = (u) => {
  let completion = 0;
  if (u.active) {
    completion += 25;
  }
  if (u.details && u.details !== "N/A") {
    completion += 25;
  }
  if (u.features && u.features !== "N/A") {
    completion += 25;
  }
  if (u.completed) {
    completion += 25;
  }
  return completion;
};
