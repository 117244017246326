import React from "react";
import { Container, Header } from "semantic-ui-react";
import Layout from "components/Layout";
import ContentManager from "components/ContentManager";
import { Ajax } from "services/Ajax";
import ListFn from "./components/List";
import Wizard from "./components/Wizard";
import Breadcrumb from "./components/Breadcrumb";
import { connect } from "react-redux";
class Guests extends React.Component {
  getConfig = ContentManager => {
    return {
      List: ListFn({ ContentManager }),
      Wizard: {
        JSX: (CM, W) => <Wizard ContentManager={CM} Wizard={W} />,
        AJAX: {
          instance: new Ajax({ url: "/apis/portal/guests" }),
          onSuccess: ({ message }) => {
            window.toastr.success();
          },
          data: data => {
            data.active = 1;
            if (data.country === "none") {
              data.country = "";
            }
            return {
              action: "create",
              data
            };
          }
        }
      }
    };
  };
  /**
   * 	Render fn
   */
  render() {
    return (
      <Layout noBar>
        <Breadcrumb />
        <Container style={{ paddingBottom: "4rem" }}>
          <Header as={"h1"} dividing style={{ marginTop: "2rem" }}>
            Guests
            <div className={"sub header"}>
              Below are all of the guests registered in the database.
            </div>
          </Header>
          <ContentManager config={this.getConfig}>
            {({ components: { List, Editor, Wizard } }) => (
              <React.Fragment>
                {List}
                {Wizard}
              </React.Fragment>
            )}
          </ContentManager>
          <br />
        </Container>
      </Layout>
    );
  }
}
const mapStateToProps = () => ({});
export default connect(mapStateToProps)(Guests);
