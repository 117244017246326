import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Button, Form } from "semantic-ui-react";
import moment from "moment";

import { setDate, setFilters, reload } from "redux/TimepunchManager/actions";
import NewPunchButton from "../NewPunchButton";
import { MyCalWrapper } from "components/MyCal";

export class Filters extends Component {
  static propTypes = {
    setFilters: PropTypes.func.isRequired,
    filters: PropTypes.object.isRequired,
    loading: PropTypes.bool.isRequired,
    reload: PropTypes.func.isRequired,
    date: PropTypes.instanceOf(moment),
    setDate: PropTypes.func.isRequired
  };

  changeModified = (e, { value }) => {
    const { setFilters, filters } = this.props;
    setFilters({ ...filters, modified: value });
  };

  changeDisabled = (e, { value }) => {
    const { setFilters, filters } = this.props;
    setFilters({ ...filters, disabled: value });
  };

  changeCompanies = (e, { value }) => {
    const { setFilters, filters } = this.props;
    this.props.setFilters({
      ...filters,
      companies: value
    });
  };

  getCompanies = () => {
    const { accounts } = this.props;
    const uniqueCompanies = {};
    accounts.forEach(({ account_company }) => {
      if (typeof uniqueCompanies[account_company.id] == "undefined") {
        uniqueCompanies[account_company.id] = account_company;
      }
    });
    return Object.values(uniqueCompanies).map((company, key) => ({
      value: company.id,
      text: company.name,
      key
    }));
  };

  render() {
    const { filters, date, loading, reload, setDate } = this.props;
    return (
      <Form>
        <Form.Dropdown
          label="Modified Timepunches"
          selection
          selectOnBlur={false}
          value={filters.modified}
          onChange={this.changeModified}
          options={[
            { value: "true", text: "Show only" },
            { value: "false", text: "Do not show" },
            { value: "any", text: "Unfiltered" }
          ]}
        />
        <Form.Dropdown
          label="Disabled Accounts"
          selection
          selectOnBlur={false}
          value={filters.disabled}
          onChange={this.changeDisabled}
          options={[
            { value: "true", text: "Show only" },
            { value: "false", text: "Do not show" },
            { value: "any", text: "Unfiltered" }
          ]}
        />
        <Form.Dropdown
          label="Companies"
          selection
          multiple={true}
          selectOnBlur={false}
          value={filters.companies}
          onChange={this.changeCompanies}
          options={[...this.getCompanies()]}
          placeholder="Filter by company..."
        />
        <Form.Field>
          <label>Week</label>
          <MyCalWrapper
            type="date"
            value={date}
            onChange={(e, { value }) => setDate(value)}
            format={false}
          />
        </Form.Field>
        <Form.Field>
          <label>&nbsp;</label>
          <Button
            onClick={reload}
            icon="refresh"
            disabled={loading}
            loading={loading}
            basic
            primary
          />
        </Form.Field>
        <Form.Field>
          <label>&nbsp;</label>
          <NewPunchButton />
        </Form.Field>
      </Form>
    );
  }
}

const mapStateToProps = state => ({
  accounts: state.TimepunchManager.accounts,
  date: state.TimepunchManager.date,
  filters: state.TimepunchManager.filters,
  loading: state.TimepunchManager.loading
});

const mapDispatchToProps = {
  setDate,
  setFilters,
  reload
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Filters);
