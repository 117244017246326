import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { reloadBooking } from "redux/BookingPage/actions";
import AjaxComponent from "components/AjaxComponent";
import { Button, Modal, Form } from "semantic-ui-react";
import AjaxFn from "services/AjaxFn";
import naturalSort from "utils/naturalSort";

export class ChangeUnit extends Component {
  static propTypes = {
    data: PropTypes.object.isRequired,
    reload: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired
  };
  /**
   * Calls cancel() on unmount
   */
  componentWillUnmount() {
    this.cancel();
  }
  /**
   * Cancels a pending ajax request, if one exists
   */
  cancel = () => {
    if (typeof this.state.cancel === "function") {
      this.state.cancel();
    }
  };
  // ...
  state = {
    loading: false,
    unit: false,
    unit_type_ciirus: false,
    cancel: false
  };
  /**
   * Render the unit selection field
   * @return {ReactElement}
   */
  renderUnitField = units => {
    return (
      <Form.Dropdown
        {...{
          label: "Unit",
          placeholder: "Select one...",
          clearable: true,
          selection: true,
          selectOnBlur: false,
          value: this.state.unit ? this.state.unit.id : "",
          options: units
            .filter(row => row.active)
            .map(unit => ({
              value: unit.id,
              text: unit.resort.name + " " + unit.name,
              unit
            }))
            .sort((a, b) => naturalSort(a.text, b.text)),
          onChange: (undefined, { value }) => {
            this.setState({
              unit: units.find(unit => unit.id === value),
              unit_type_ciirus: ""
            });
          }
        }}
      />
    );
  };
  /**
   * Determine which listing selection field to render
   * @return {ReactElement}
   */
  renderListingField = () => {
    switch (this.state.unit.unit_type) {
      case "ciirus":
        return this.renderCiirusListingField();
      default:
        throw new Error("unknown unit type");
    }
  };
  /**
   * Render the ciirus listing selection field
   * @return {ReactElement}
   */
  renderCiirusListingField = () => {
    const { ciirus_units } = this.state.unit;

    if (!ciirus_units.length) {
      return <p>This unit does not have any available ciirus listings.</p>;
    }

    return (
      <Form.Dropdown
        {...{
          label: "Listing",
          placeholder: "Select one...",
          clearable: true,
          selection: true,
          selectOnBlur: false,
          value: this.state.unit_type_ciirus
            ? this.state.unit_type_ciirus.id
            : "",
          options: ciirus_units
            .map(ciirus_unit => ({
              value: ciirus_unit.id,
              text: ciirus_unit.name
            }))
            .sort((a, b) => naturalSort(a.text, b.text)),
          onChange: (undefined, { value }) => {
            this.setState({
              unit_type_ciirus: ciirus_units.find(
                listing => listing.id === value
              )
            });
          }
        }}
      />
    );
  };
  /**
   * Generate the data parameters to send
   * @return {Object}
   */
  getSaveData = () => {
    switch (this.state.unit.unit_type) {
      case "ciirus":
        return {
          ID: this.props.data.id,
          unit: this.state.unit.id,
          unit_type_ciirus: this.state.unit_type_ciirus.id
        };
      default:
        throw new Error("unknown unit type");
    }
  };
  /**
   * Handle the save button click
   */
  handleSave = () => {
    this.setState({
      loading: true,
      cancel: AjaxFn({
        url: "/apis/portal/bookings",
        data: {
          action: "update_unit",
          data: this.getSaveData()
        },
        success: () => {
          window.toastr.success();
          this.props.reload();
        },
        failure: error => {
          this.setState({ loading: false });
          window.toastr.error(error);
        }
      })
    });
  };
  /**
   * Return whether or not we can curently click save
   * @return {Bool}
   */
  canSave = () => {
    const { loading, unit, unit_type_ciirus } = this.state;
    if (loading) {
      return false;
    }
    if (!unit) {
      return false;
    }
    switch (unit.unit_type) {
      case "ciirus":
        return !!unit_type_ciirus;
      default:
        throw new Error("unknown unit type");
    }
  };
  /**
   * Render
   */
  render() {
    const { open, handleClose } = this.props;
    const config = {
      url: "/apis/portal/units?action=read_for_dropdown"
    };
    return (
      <Modal open={open} onClose={handleClose} size="mini">
        <Modal.Header>Edit Unit</Modal.Header>
        <Modal.Content>
          <AjaxComponent ajaxConfig={config}>
            {({ data }) => (
              <Form>
                {this.renderUnitField(data)}
                {this.state.unit && this.renderListingField()}
              </Form>
            )}
          </AjaxComponent>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={handleClose} content="Close" />
          <Button
            primary
            onClick={this.handleSave}
            content="Save"
            disabled={!this.canSave()}
            loading={this.state.loading}
          />
        </Modal.Actions>
      </Modal>
    );
  }
}

const mapDispatchToProps = {
  reload: reloadBooking
};

export default connect(
  undefined,
  mapDispatchToProps
)(ChangeUnit);
