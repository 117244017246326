import React, { Component, Fragment } from "react";
import { Divider, Header } from "semantic-ui-react";
import CancelButton from "./components/CancelButton";
import DeleteButton from "./components/DeleteButton";
import UncancelButton from "../UncancelButton";
class Actions extends Component {
  render() {
    const fn = row => row.active;
    const cancellations = this.props.data.cancellations.filter(fn);
    return (
      <div>
        <Header as={"h3"}>Cancel Booking</Header>
        <p>Use the button below to cancel or uncancel a booking.</p>
        {cancellations.length === 0 ? (
          this.props.data._actions.actions.cancel ? (
            <CancelButton />
          ) : (
            <p>You are not able to cancel this right now.</p>
          )
        ) : this.props.data._actions.actions.uncancel ? (
          <UncancelButton />
        ) : (
          <p>You are not able to uncancel this right now.</p>
        )}
        <Divider />
        <br />
        <Header as={"h3"}>Delete Booking</Header>
        {cancellations.length > 0 ? (
          <div>
            <p>Use the button below to permanently remove the booking.</p>
            {this.props.data._actions.actions.delete ? (
              <DeleteButton />
            ) : (
              <p>You are not able to delete this right now.</p>
            )}
          </div>
        ) : (
          <p>The booking must first be cancelled before it can be deleted.</p>
        )}
        <br />
      </div>
    );
  }
}
export default Actions;
