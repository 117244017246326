import moment from "moment";

const cardDefaults = {
  card_number: "",
  card_cvc: "",
  card_exp_date: moment().format("M"),
  card_exp_year: moment().format("YYYY")
};
const checkDefaults = {
  check_bank_addr: "",
  check_account_num: "",
  check_routing_num: "",
  check_num: ""
};
const paymentDefaults = {
  full_name_on_account: "",
  authorize_payment: "0.00",
  payment_method: "card",
  billing_address: "",
  billing_city: "",
  billing_address_2: "",
  billing_state: "",
  billing_zip: "",
  ...cardDefaults,
  ...checkDefaults
};

export const pointsrelease = {
  // member
  firstname: "",
  lastname: "",
  pay_to: "",
  email: "",
  phone: "",
  address: "",
  city: "",
  address_2: "",
  state: "",
  zip: "",
  // membership
  account_number: "",
  annual_earned: "",
  vip_status: "Plus",
  points_alloc_curr: "",
  points_alloc_next: "",
  points_expiration: moment().format("YYYY") + "-12-31",
  username: "",
  password: "",
  shared: "unsure",
  // ...
  ...paymentDefaults,
  signature: ""
};

export const deedtransfer = {
  // owner section
  name_on_deed: "",
  owner_names: [{ fullname: "", married: "", status: "" }],
  email: "",
  primary_phone: "",
  work_phone: "",
  cell_phone: "",
  address: "",
  address_2: "",
  city: "",
  state: "",
  zip: "",
  mortgage: "",
  // resort section
  resort_name: "",
  resort_city: "",
  resort_state: "",
  week_type: "",
  unit_size: "",
  usage_rights: "",
  unit_number: "",
  owner_number: "",
  week_number: "",
  earned_points_annualy: "",
  owned_contracts: "",
  ownership: [],
  // week info
  curr_week_status: "",
  next_week_status: "",
  // fees
  fees_paid_through_month: "",
  fees_paid_through_year: "",
  taxes_paid_through_month: "",
  taxes_paid_through_year: "",
  annual_taxes: "",
  fees_up_to_date: "",
  // ...
  notes: "",
  // payment
  ...paymentDefaults
};

export const generic = {
  // personal info
  name_on_deed: "",
  owner_names: [{ fullname: "", married: "", status: "" }],
  fees_up_to_date: "",
  mortgage: "",
  // contact info
  email: "",
  primary_phone: "",
  work_phone: "",
  cell_phone: "",
  address: "",
  address_2: "",
  city: "",
  state: "",
  zip: "",
  // payment
  ...paymentDefaults
};

export const finance = {
  // traveler info
  traveler_name: "",
  traveler_ssn: "",
  cotraveler_name: "",
  cotraveler_ssn: "",
  address: "",
  city: "",
  state: "",
  zip: "",
  work_phone: "",
  home_phone: "",
  account_number: "",

  // agreement info
  agreement_date: moment().format(),
  loan_start_date: moment()
    .add(1, "M")
    .format(),
  base_amount: "",
  down_payment: "",
  financed_amount: "", // amount of credit provided to client
  interest_rate: "20.99", // the percentage of interest added to credit
  agreement_term: "", // in months => number of payments - i.e 30 months/payments = 2.5 years
  monthly_payment: "", // how much client will pay each month
  finance_charge: "", // amount the credit will cost the client
  total_payment: "", // the amount the client will have paid after all scheduled payments
  total_sale: "", // the total cost of purchase on credit; this includes the down payment
  payments: [
    // {
    //   number: 1, // position in the payment cycle
    //   payment_date: moment().format(), // date the payment is due
    //   starting_balance: "", // balance before payment
    //   monthly_payment: "", // the monthly payment
    //   principle: "", // amount toward the principle
    //   interest: "", // amount of interest
    //   ending_balance: "" // balance after payment
    // }
  ],

  // client signatures
  initial: "",
  signature: ""
};
