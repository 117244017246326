import React, { Component } from "react";
import { Table } from "semantic-ui-react";
import propTypes from "prop-types";
import moment from "moment";
import { InstanceTypes } from "../../../../../../types";
import injectSheet from "react-jss";
/**
 * @prop day
 * @prop days
 * @prop setDate
 * @prop config
 * @prop month
 * @prop year
 * @prop setMonth
 * @prop setYear
 * @prop classes
 */
class Days extends Component {
	static propTypes = {
		day: propTypes.string.isRequired,
		days: propTypes.array.isRequired,
		setDate: propTypes.func.isRequired,
		config: InstanceTypes.config,
		month: propTypes.string.isRequired,
		year: propTypes.string.isRequired,
		setMonth: propTypes.func.isRequired,
		setYear: propTypes.func.isRequired,
		classes: propTypes.object.isRequired
	};
	setDay = dayMoment => {
		const { value, setDate } = this.props;
		const newValue = moment(dayMoment)
			.hour(value.hour())
			.minute(value.minute())
			.second(value.second());
		setDate(newValue);
	};
	/**
	 * Detect a scroll on an element, and then set the month/year accordingly to how the user scrolls
	 */
	handleScroll = e => {
		const { month, year, setMonth, setYear } = this.props;
		const modifier = e.deltaY <= 0 ? 1 : -1;
		let newMonth = Number(month) + modifier;
		if (newMonth === 0) {
			newMonth = 12;
			const lastyear = Number(year) - 1;
			setYear(lastyear.toString());
		}
		if (newMonth === 13) {
			newMonth = 1;
			const nextyear = Number(year) + 1;
			setYear(nextyear.toString());
		}
		const padded = newMonth.toString().padStart(2, "0");
		setMonth(padded);
	};
	/**
	 * Determine if a date is clickable or not based on minDate and maxDate in config
	 * @param {Moment} moment (current day's moment)
	 * @return bool
	 */
	isClickable = moment => {
		const { config } = this.props;
		const { minDate, maxDate } = config;
		if (minDate) {
			if (moment < minDate) {
				return false;
			}
		}
		if (maxDate) {
			if (moment > maxDate) {
				return false;
			}
		}
		return true;
	};
	/**
	 * render
	 */
	render() {
		const { day: selected, days, setDate, classes } = this.props;
		const thisMoment = moment().startOf("day");
		return (
			<Table celled columns={7} compact onWheel={this.handleScroll} unstackable>
				<Table.Header>
					<Table.Row>
						<Table.HeaderCell className={classes.generic}>Sun</Table.HeaderCell>
						<Table.HeaderCell className={classes.generic}>Mon</Table.HeaderCell>
						<Table.HeaderCell className={classes.generic}>Tue</Table.HeaderCell>
						<Table.HeaderCell className={classes.generic}>Wed</Table.HeaderCell>
						<Table.HeaderCell className={classes.generic}>Thu</Table.HeaderCell>
						<Table.HeaderCell className={classes.generic}>Fri</Table.HeaderCell>
						<Table.HeaderCell className={classes.generic}>Sat</Table.HeaderCell>
					</Table.Row>
				</Table.Header>
				<Table.Body>
					{days.map((week, wk) => (
						<Table.Row key={wk}>
							{week.map((day, dk) => {
								if (day) {
									const dayMoment = moment(day.ymd).startOf("day");
									if (this.isClickable(dayMoment)) {
										// Date cell, selectable
										return (
											<Table.Cell
												className={
													selected === day.ymd
														? classes.selected
														: thisMoment.isSame(dayMoment)
															? classes.today
															: classes.generic
												}
												selectable
												onClick={() => this.setDay(dayMoment)}
												key={dk}
												children={<a href={null}>{day.day}</a>}
											/>
										);
									} else {
										// Date cell, unselectable
										return (
											<Table.Cell
												key={dk}
												className={classes.disabled}
												children={day.day}
											/>
										);
									}
								} else {
									// Blank cell
									return <Table.Cell key={dk} className={classes.disabled} />;
								}
							})}
						</Table.Row>
					))}
				</Table.Body>
			</Table>
		);
	}
}
const shared = {
	padding: ".35rem .5rem 1rem .5rem !important",
	"& a": {
		padding: "0px !important"
	}
};
const styles = {
	selected: {
		...shared,
		background: "#e2f9ff",
		cursor: "pointer"
	},
	disabled: {
		...shared,
		background: "#eee"
	},
	generic: {
		...shared,
		cursor: "pointer"
	},
	today: {
		...shared,
		cursor: "pointer"
		// ...
	}
};
export default injectSheet(styles)(Days);
