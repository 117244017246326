import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { setPage } from "redux/EOQReport/actions";
import { Button } from "semantic-ui-react";

export class Pages extends Component {
  static propTypes = {
    rows: PropTypes.array.isRequired,
    setPage: PropTypes.func.isRequired,
    per_page: PropTypes.number.isRequired,
    page: PropTypes.number.isRequired
  };

  render() {
    const { rows, setPage, per_page } = this.props;
    let page = this.props.page - 1;
    let div = Math.floor(rows.length / per_page);
    let pages = rows.length % per_page ? div + 1 : div;
    const buttons = [];
    let start_offset = 2;
    let end_offset = 3;
    let start = page - start_offset > 0 ? page - start_offset : 0;
    let end = page + end_offset <= pages ? page + end_offset : pages;

    for (let i = start; i < end; i++) {
      buttons.push(i);
    }

    return (
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div>
          {page > start_offset && (
            <Button content={"First"} onClick={() => setPage(1)} basic />
          )}
          <Button
            disabled={page === 0}
            content={"Back"}
            onClick={() => setPage(page)}
            basic
          />
        </div>
        <div>
          {buttons.map((el, i) => (
            <Button
              key={i}
              content={el + 1}
              primary={el === page}
              onClick={() => setPage(el + 1)}
              basic
            />
          ))}
        </div>
        <div>
          <Button
            disabled={page >= pages - 1}
            content={"Next"}
            onClick={() => setPage(page + 2)}
            basic
          />
          {page < pages - end_offset && (
            <Button
              onClick={() => setPage(pages)}
              content={`Last (${pages})`}
              basic
            />
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  per_page: state.EOQReport.per_page,
  page: state.EOQReport.page,
  rows: state.EOQReport.bookings.data.rows_ordered
  // ...
});
const mapDispatchToProps = {
  setPage
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Pages);
