// stub all socket behavior since they're no longer supported

export default () =>
  class {
    static socket = undefined;

    static connect = function () {
      return this;
    };

    static off = function () {
      return this;
    };

    static joinRoom = function () {
      return this;
    };

    static ignore = function () {
      return this;
    };

    static emit = function (...args) {
      return this;
    };

    static on = function () {
      return this;
    };
  };
