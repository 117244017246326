import React, { Component, Fragment } from "react";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import AjaxFn from "services/AjaxFn";
import {
  Container,
  Grid,
  Header,
  Form,
  Segment,
  Button,
  Message
} from "semantic-ui-react";
import injectSheet from "react-jss";
const styles = {
  background: {
    position: "fixed",
    top: "0",
    left: "0",
    right: "0",
    bottom: "0",
    width: "100%",
    height: "100%",
    backgroundImage: 'url("/img/backgrounds/dark-background.png")',
    backgroundColor: "#2a2b2b",
    backgroundRepeat: "no-repeat",
    backgroundAttachment: "fixed",
    backgroundPosition: "center",
    backgroundSize: "cover",
    zIndex: "1 !important"
    // opacity: ".7"
  },
  wrapper: {
    position: "fixed",
    top: "0",
    left: "0",
    right: "0",
    bottom: "0",
    width: "100%",
    height: "100%",
    zIndex: "2 !important"
  },
  grid: {
    height: "100%"
  },
  column: {
    maxWidth: "450px"
  },
  segment: {
    padding: "3rem",
    background: "#fff",
    border: "1px solid gray"
  }
};
class Login extends Component {
  state = {
    username: "",
    password: "",
    loading: false,
    error: false
  };
  handleSubmit = e => {
    e.preventDefault();
    this.setState({ loading: true });
    AjaxFn({
      url: "/apis/portal/sessions",
      data: {
        action: "login",
        data: {
          username: this.state.username,
          password: this.state.password
        }
      },
      success: () => window.location.reload(),
      failure: error => {
        console.log(error);
        this.setState({ loading: false, error });
      }
    });
  };
  render() {
    const { classes, User } = this.props;
    if (typeof User.id !== "undefined") {
      return <Redirect to={{ pathname: "/" }} />;
    }
    return (
      <Fragment>
        <div className={classes.background} />
        <Container>
          <div className={classes.wrapper}>
            <Grid verticalAlign={"middle"} centered className={classes.grid}>
              <Grid.Column className={classes.column}>
                <Segment stacked className={classes.segment}>
                  <Form onSubmit={this.handleSubmit}>
                    <Header as={"h2"}>VMG Portal</Header>
                    <br />
                    <Form.Input
                      name="username"
                      value={this.state.username}
                      onChange={(e, { value }) =>
                        this.setState({ username: value })
                      }
                      icon="user"
                      placeholder="Username"
                      required
                    />
                    <Form.Input
                      name="password"
                      type="password"
                      value={this.state.password}
                      onChange={(e, { value }) =>
                        this.setState({ password: value })
                      }
                      icon="lock"
                      placeholder="Password"
                      required
                    />
                    <Message
                      error
                      header="Error logging in"
                      list={[this.state.error]}
                      visible={!!this.state.error}
                    />
                    <br />
                    <Button
                      fluid
                      primary
                      loading={this.state.loading}
                      disabled={this.state.loading}
                      content="Login"
                    />
                  </Form>
                </Segment>
              </Grid.Column>
            </Grid>
          </div>
        </Container>
      </Fragment>
    );
  }
}
const styled = injectSheet(styles)(Login);
const mapStateToProps = ({ User }) => ({ User });
export default connect(mapStateToProps)(styled);
