import React from "react";
import { Modal, Button, Icon } from "semantic-ui-react";
/**
 *	@prop open
 *	@prop close
 */
export default class CompletionModal extends React.Component {
  render() {
    const { open, close } = this.props;
    return (
      <Modal open={open} onClose={close}>
        <Modal.Header>Unit Completion</Modal.Header>
        <Modal.Content>
          The number displayed in a resort's completion progress bar is
          determined by the following:
          <ul>
            <li>Unit is active</li>
            <li>Unit has a Ciirus ID</li>
            <li>Unit has features</li>
            <li>Unit has a unique title</li>
          </ul>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={close}>
            <Icon name={"close"} />
            Close
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}
