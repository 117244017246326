import React, { Component } from "react";
import PropTypes from "prop-types";
import { Button, Modal, Form } from "semantic-ui-react";
import AjaxDropdown from "components/AjaxDropdown";
import AjaxFn from "services/AjaxFn";
import naturalSort from "utils/naturalSort";
import { connect } from "react-redux";
import { reloadBooking } from "redux/BookingPage/actions";

class ChangeModal extends Component {
  static propTypes = {
    booking: PropTypes.object.isRequired,
    handleClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    reload: PropTypes.func.isRequired
  };

  componentWillUnmount() {
    this.cancel();
  }

  cancel = () => {
    if (typeof this.state.cancel === "function") {
      this.state.cancel();
    }
  };

  state = {
    loading: false,
    guest: this.props.booking.guest.id,
    cancel: false
  };

  handleChange = (e, { value }) => this.setState({ guest: value });

  save = () => {
    this.cancel();
    this.setState({
      loading: true,
      cancel: AjaxFn({
        url: "/apis/portal/bookings",
        data: {
          action: "update_guest",
          data: {
            ID: this.props.booking.id,
            guestID: this.state.guest
          }
        },
        success: () => {
          window.toastr.success();
          this.props.reload();
        },
        failure: error => {
          window.toastr.error(error);
          this.setState({ loading: false, cancel: false });
        }
      })
    });
  };

  render() {
    const { open, handleClose } = this.props;
    return (
      <Modal open={open} size={"mini"}>
        <Modal.Header>Change Guest</Modal.Header>
        <Modal.Content>
          <Form>
            <Form.Field>
              <label>Choose a Guest</label>
              <AjaxDropdown
                lazyLoad
                placeholder={"Guest"}
                value={this.state.guest}
                onChange={this.handleChange}
                dataRetreiver={processGuests}
                disabled={this.state.loading}
                url={"/apis/portal/guests"}
                data={{ action: "read_for_booking_wizard" }}
              />
            </Form.Field>
          </Form>
          <br />
          <p>Only guests set to "active" will appear in the dropdown above.</p>
        </Modal.Content>
        <Modal.Actions>
          <Button
            icon={"close"}
            content={"Cancel"}
            onClick={handleClose}
            disabled={this.state.loading}
          />
          <Button
            primary
            icon={"save"}
            content={"Save"}
            onClick={this.save}
            loading={this.state.loading}
            disabled={this.state.loading}
          />
        </Modal.Actions>
      </Modal>
    );
  }
}

const mapDispatchToProps = {
  reload: reloadBooking
};

export default connect(
  undefined,
  mapDispatchToProps
)(ChangeModal);

const processGuests = ({ message }) =>
  message
    .filter(row => row.active)
    .map(row => ({
      value: row.id,
      text: row.firstname + " " + row.lastname
    }))
    .sort((a, b) => naturalSort(a.text, b.text));
