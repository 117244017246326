import React from "react";
import Element from "../Element";
import ItemComponent from "../ItemComponent";

const ItemElement = props => {
  const { active = false, hideOnCollapse = false, ...rest } = props;

  const elementprops = {
    indicator: active ? true : undefined,
    hideOnCollapse
  };

  return (
    <Element {...elementprops}>
      <ItemComponent {...rest} />
    </Element>
  );
};

export default ItemElement;
