import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { setFilters } from "redux/PortalTables/actions";
import { Modal, Icon, Button } from "semantic-ui-react";

export class FiltersModal extends Component {
  static propTypes = {
    namespace: PropTypes.string.isRequired,
    modalProps: PropTypes.object,
    filters: PropTypes.object,
    buildFilters: PropTypes.func.isRequired
  };

  state = {
    open: false,
    applied: {
      ...(this.props.filters || {})
    }
  };

  componentDidMount() {
    this.updateFilters();
  }

  handleOpen = () => this.setState({ open: true });
  handleClose = () => this.setState({ open: false });

  /**
   *	Call the dispatch to update the portaltables filters
   */
  updateFilters = () =>
    this.props.setFilters(this.props.namespace, this.buildFilters());

  /**
   *	Update a key/value pair in state
   *  @param {String} key
   *  @param {Mixed} value
   */
  updateFilterState = (key, value) => {
    let newState = {
      applied: {
        ...this.state.applied,
        [key]: value
      }
    };
    this.setState(newState, this.updateFilters);
  };

  /**
   *	Build and return an array of filters
   *  @return {Array}
   */
  buildFilters = () => {
    if (typeof this.props.buildFilters === "function") {
      return this.props.buildFilters(this.state.applied);
    } else {
      return [];
    }
  };

  /**
   *	Build and return an object of modal props
   *  @return {Object}
   */
  getModalConf = () => {
    if (typeof this.props.modalProps === "function") {
      return this.props.modalProps({
        state: this.state,
        functions: {
          handleOpen: this.handleOpen,
          handleClose: this.handleClose
        }
      });
    } else {
      return {
        open: this.state.open,
        onClose: this.handleClose,
        trigger: (
          <Button
            icon="filter"
            onClick={this.handleOpen}
            content="Filter Results"
          />
        ),
        ...this.props.modalProps
      };
    }
  };

  render() {
    return (
      <Modal {...this.getModalConf()}>
        <Modal.Header>Filters</Modal.Header>
        <Modal.Content>
          {typeof this.props.children === "function"
            ? this.props.children(this.state.applied, this.updateFilterState)
            : this.props.children}
        </Modal.Content>
        <Modal.Actions>
          <Button content="Close" onClick={this.handleClose} basic />
        </Modal.Actions>
      </Modal>
    );
  }
}

const mapDispatchToProps = {
  setFilters
};

export default connect(
  undefined,
  mapDispatchToProps
)(FiltersModal);
