import React from "react";
import { findInObject, setInObject, trimObject } from "utils/objects";

export default class Editor extends React.Component {
  state = {
    data: {}
  };
  /**
   *	Returns relevant functions/data for rendering
   *	@return {Object}
   */
  _this = () => ({
    ...this.state,
    functions: {
      createInput: this.createInput,
      createCheckbox: this.createCheckbox,
      set: this.set,
      reset: this.reset,
      save: this.save
    }
  });
  /**
   *  Determines what to show for the user
   *  @param {Object} touched (the touched data)
   *  @param {Object} pure (the pure data)
   *  @return {Mixed}
   */
  _getValueToDisplay = (touched, pure) => {
    if (typeof touched === "undefined") {
      return pure;
    } else {
      return touched;
    }
  };
  /**
   *  Returns visual feedback styles to apply if the input has edited data
   *  @param {Object} touchedData (the touched data)
   *  @return {Object}
   */
  _getStyleToDisplay = touchedData =>
    typeof touchedData === "undefined"
      ? {
          borderRadius: "5px"
        }
      : {
          borderRadius: "5px",
          boxShadow: "0px 0px 3px 0px #ff9741",
          border: "1px solid #ff9741"
        };

  _ajax = () => {
    const { Editor: { AJAX } = {} } = this.props.config;
    if (!AJAX) {
      throw new Error("No .AJAX property was defined");
    }
    if (!AJAX.instance) {
      throw new Error("No .AJAX.instance property was defined");
    }
    return {
      instance: AJAX.instance,
      validator: AJAX.validator || this._defaultAjaxValidator,
      onSuccess: AJAX.onSuccess || this._defaultAjaxSuccess,
      onError: AJAX.onError || this._defaultAjaxError,
      data: AJAX.data || this._defaultAjaxData
    };
  };
  _defaultAjaxValidator = ({ status: s }) => s;
  _defaultAjaxSuccess = () => console.log("success");
  _defaultAjaxError = ({ message: e = "Unknown error" }) => {
    window.toastr.error(e);
  };
  _defaultAjaxData = ({ id: ID }, data) => ({ ID, data });

  /**
   *	Update a key/value pair in this.state.data
   *  @param {String/Array} path
   *  @param {Mixed} value
   *	@return {Object}
   */
  set = (path, value) => {
    const paths = Array.isArray(path) ? path : [path];
    // get the original value
    const originalValue = findInObject(
      this.props.manager.state.editorSource,
      paths
    );
    this.setState(({ data }) => {
      // if our new value is different than the original value,
      if (originalValue !== value) {
        // push the changes
        setInObject(data, paths, value);
      } else {
        // otherwise, set it to undefined and remove any undefined changes
        // to recursively trim the data object
        setInObject(data, paths, undefined);
        trimObject(data);
      }
      return { data };
    });
  };
  /**
   *  Clear out this.state.data
   */
  reset = () => this.setState({ data: {} });
  /**
   *	A helper to generate a controlled editor component with less code
   *	@param {ReactElement} reactElement (any valid react element, like <Input /> or <Select /> from semanticUI)
   *  @param {String} name (the name that corresponds to the entry in the editor's redux store)
   *  @param {Object} props (optional, any props you want to add on extra)
   *	@return {ReactElement}
   */
  createInput = (reactElement, name, props = {}) => {
    const path = Array.isArray(name) ? name : [name];
    const touchedData = findInObject(this.state.data, path);
    const pureData = findInObject(this.props.manager.state.editorSource, path);
    const valueToShow = this._getValueToDisplay(touchedData, pureData);
    return React.cloneElement(reactElement, {
      value: typeof valueToShow === "undefined" ? "" : valueToShow,
      placeholder: path[0],
      style: this._getStyleToDisplay(touchedData),
      onChange: (e, { value }) => this.set(path, value),
      ...props
    });
  };
  createCheckbox = (reactElement, name, props = {}) => {
    const path = Array.isArray(name) ? name : [name];
    const touchedData = findInObject(this.state.data, path);
    const pureData = findInObject(this.props.manager.state.editorSource, path);
    const valueToShow = this._getValueToDisplay(touchedData, pureData) || false;
    return React.cloneElement(reactElement, {
      checked: valueToShow ? true : false,
      style: this._getStyleToDisplay(touchedData),
      onChange: (e, { checked: value }) => this.set(path, value ? 1 : 0),
      ...props
    });
  };
  /**
   *	Handler for clicking the save button
   */
  save = () => {
    const { manager, config } = this.props;
    const AJAX = this._ajax();
    const onSuccess = response => {
      manager.functions.editorLoading(false);
      manager.functions.closeEditor(true);
      if (typeof config.List !== "undefined") {
        manager.functions.refresh();
      }
      this.setState({ data: {} });
      AJAX.onSuccess(response);
    };
    const onError = response => {
      manager.functions.editorLoading(false);
      AJAX.onError(response);
    };
    const payload =
      typeof AJAX.data === "function"
        ? AJAX.data(manager.state.editorSource, { ...this.state.data })
        : AJAX.data;
    manager.functions.editorLoading(true);
    AJAX.instance.send(AJAX.validator, onSuccess, onError, payload);
  };

  render() {
    const { config, manager } = this.props;
    const { Editor: { JSX } = {} } = config;
    if (typeof JSX === "function") {
      return JSX(manager, this._this());
    } else if (typeof JSX !== "undefined") {
      return JSX;
    } else {
      return null;
    }
  }
}
