import React from "react";
import Clickable from "./components/Clickable";
import Unclickable from "./components/Unclickable";

export default ({ clickable, ...rest }) => {
  if (clickable) {
    return <Clickable {...rest} />;
  } else {
    return <Unclickable {...rest} />;
  }
};
