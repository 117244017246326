import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { Form, Input } from "semantic-ui-react";
import bookingSources from "fixtures/booking_sources";
import paymentSources from "fixtures/payment_sources";
import AsyncForm from "components/AsyncForm";
import { MyCalWrapper } from "components/MyCal";
import moment from "moment";

export default class Details extends Component {
  static propTypes = {
    data: PropTypes.object.isRequired
  };

  render() {
    const { data } = this.props;
    const readOnly = !data._actions.actions.edit_summary;
    const config = {
      reload: () => {
        console.log("async form reload triggered");
      },
      success: () => {
        console.log("async form saved");
      },
      failure: e => {
        window.toastr.error(e);
      },
      dataFn: (source, value) => ({
        url: "/apis/portal/bookings",
        data: {
          action: "update",
          data: {
            ID: data.id,
            [source]: typeof value === "boolean" ? (value ? 1 : 0) : value
          }
        }
      })
    };
    return (
      <div>
        <AsyncForm {...config}>
          {({ functions }) => {
            return (
              <Fragment>
                <Form.Group widths="equal">
                  <Form.Field>
                    <label>Check in</label>
                    {functions.make({
                      element: <MyCalWrapper />,
                      field: "check_in",
                      value: data.check_in,
                      debounce: 0,
                      props: {
                        type: "date",
                        maxDate: moment(data.check_out),
                        readOnly
                      }
                    })}
                  </Form.Field>
                  {functions.make({
                    element: <Form.Dropdown />,
                    field: "booking_source",
                    value: data.booking_source,
                    debounce: 0,
                    props: {
                      label: "Booking Source",
                      options: bookingSources,
                      selection: true,
                      selectOnBlur: false,
                      disabled: readOnly
                    }
                  })}
                  <Form.Field>
                    <label>Resort Confirmation Number</label>
                    {functions.make({
                      element: <Input />,
                      field: "rco",
                      value: data.rco,
                      debounce: 500,
                      props: {
                        label: "#",
                        placeholder: "000000",
                        readOnly,
                        icon: readOnly ? "lock" : undefined
                      }
                    })}
                  </Form.Field>
                </Form.Group>
                <Form.Group widths="equal">
                  <Form.Field>
                    <label>Check out</label>
                    {functions.make({
                      element: <MyCalWrapper />,
                      field: "check_out",
                      value: data.check_out,
                      debounce: 0,
                      props: {
                        type: "date",
                        minDate: moment(data.check_in),
                        readOnly
                      }
                    })}
                  </Form.Field>
                  {functions.make({
                    element: <Form.Dropdown />,
                    field: "payment_source",
                    value: data.payment_source,
                    debounce: 0,
                    props: {
                      label: "Payment Source",
                      options: paymentSources,
                      selection: true,
                      selectOnBlur: false,
                      disabled: readOnly
                    }
                  })}
                  <Form.Field>
                    <label>CiiRUS ID</label>
                    {functions.make({
                      element: <Input />,
                      field: "ciirus_id",
                      value: data.ciirus_id,
                      debounce: 500,
                      props: {
                        label: "#",
                        placeholder: "000000",
                        readOnly,
                        icon: readOnly ? "lock" : undefined
                      }
                    })}
                  </Form.Field>
                </Form.Group>
                <Form.Group widths="equal">
                  <Form.Field>
                    <label>Date booked</label>
                    {functions.make({
                      element: <MyCalWrapper />,
                      field: "date_booked",
                      value: data.date_booked,
                      debounce: 0,
                      props: {
                        type: "date",
                        readOnly
                      }
                    })}
                  </Form.Field>
                  <Form.Field />
                  <Form.Field />
                </Form.Group>
                {functions.message()}
              </Fragment>
            );
          }}
        </AsyncForm>
      </div>
    );
  }
}
