import React from "react";
import injectSheet from "react-jss";
const styles = {
  div: {
    padding: ".5rem",
    marginBottom: ".5rem",
    border: "1px solid #eee"
  },
  clickable: {
    "&:hover": {
      background: "#eee !important",
      border: "1px solid #ddd",
      cursor: "pointer !important",
      "& .field": {
        cursor: "pointer !important",
        "& label": {
          cursor: "pointer !important"
        }
      }
    }
  }
};

const Clickable = ({ classes, children }) => (
  <div className={classes.div + " " + classes.clickable}>{children}</div>
);

export default injectSheet(styles)(Clickable);
