import React, { Component, Fragment } from "react";
import { Container, Grid } from "semantic-ui-react";
import Layout from "components/Layout";
import UnitLoader from "./components/UnitLoader";
import UnitListingLoader from "./components/UnitListingLoader";
import Sidebar from "./components/Sidebar";
import UnitRouter from "./components/UnitRouter";
import ListingRouter from "./components/ListingRouter";
import { InitialBar, UnitBar, ListingBar } from "./components/Breadcrumbs";
import Heading from "./components/Heading";
import PropTypes from "prop-types";

export default class UnitPage extends Component {
  static propTypes = {
    match: PropTypes.object.isRequired
  };

  render() {
    const { match } = this.props;
    const { listingID, unitID, listingTab, unitTab } = match.params;
    return (
      <Layout noBar>
        <InitialBar />
        <Container>
          <UnitLoader unitID={unitID} ref={r => (this.unitLoader = r)}>
            {unit => (
              <Grid>
                <Grid.Column width={16}>
                  <br />
                  <Heading unit={unit.data} />
                </Grid.Column>
                <Grid.Column width={4}>
                  <Sidebar
                    unit={unit.data}
                    match={match}
                    reload={this.unitLoader.reload}
                  />
                </Grid.Column>
                <Grid.Column stretched width={12}>
                  {listingID ? (
                    <UnitListingLoader unit={unit.data} listingID={listingID}>
                      {listing => (
                        <Fragment>
                          <ListingBar
                            render={false}
                            listing={listing.data}
                            unit={unit.data}
                          />
                          <ListingRouter
                            listing={listing.data}
                            listingTab={listingTab}
                            unit={unit.data}
                            reload={this.unitLoader.reload}
                          />
                        </Fragment>
                      )}
                    </UnitListingLoader>
                  ) : (
                    <Fragment>
                      <UnitBar render={false} unit={unit.data} />
                      <UnitRouter
                        unit={unit.data}
                        unitTab={unitTab}
                        reload={this.unitLoader.reload}
                      />
                    </Fragment>
                  )}
                </Grid.Column>
              </Grid>
            )}
          </UnitLoader>
        </Container>
      </Layout>
    );
  }
}
