import React from "react";
import { Button, Modal, Form, Input, Checkbox } from "semantic-ui-react";
import styled from "styled-components";

import AjaxDropdown from "components/AjaxDropdown";

export default (ContentManager, Wizard) => {
  return (
    <Modal open={ContentManager.state.wizardActive} size={"small"}>
      <Modal.Header>Create</Modal.Header>
      <Modal.Content>
        <Form>
          <Form.Field>
            <label>Group</label>
            {Wizard.functions.createInput(
              <AjaxDropdown
                url={"/apis/portal/pagegroups"}
                data={{ action: "read" }}
                dataRetreiver={({ message: m }) =>
                  m.map(({ id, name }) => ({
                    value: id,
                    text: name
                  }))
                }
              />,
              ["page_group", "id"],
              {
                placeholder: "Page Group"
              }
            )}
          </Form.Field>
          <Form.Field>
            <label htmlFor="icon">Icon</label>
            {Wizard.functions.createInput(<Input id="icon" />, "icon")}
          </Form.Field>
          <Form.Field>
            <label htmlFor="name">Name</label>
            {Wizard.functions.createInput(<Input id="name" />, "name")}
          </Form.Field>
          <Form.Field>
            <label htmlFor="url">URL</label>
            {Wizard.functions.createInput(<Input id="url" />, "uri")}
          </Form.Field>
          <Form.Field>
            <label htmlFor="enabled">Enabled</label>
            {Wizard.functions.createCheckbox(
              <Checkbox id="enabled" />,
              "enabled",
              {
                toggle: true
              }
            )}
          </Form.Field>
        </Form>
      </Modal.Content>
      <ModalActions>
        <Button
          content="Close"
          onClick={ContentManager.functions.closeWizard}
          basic
        />
        {Object.keys(Wizard.data).length ? (
          <div>
            <Button
              type={"button"}
              content={"Discard"}
              icon="undo"
              onClick={Wizard.functions.reset}
              basic
            />
            <Button
              type={"button"}
              onClick={Wizard.functions.save}
              loading={ContentManager.state.wizardLoading}
              icon={"save"}
              content={"Save"}
              color={"blue"}
            />
          </div>
        ) : null}
      </ModalActions>
    </Modal>
  );
};

const ModalActions = styled(Modal.Actions)`
  display: flex;
  justify-content: space-between;
  align-items: stretch;

  &&& {
    button {
      height: 100%;
      margin: 0 0.25em 0 0.75em;
      &:first-of-type {
        justify-self: flex-start;
      }
    }
  }
`;
