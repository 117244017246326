import React from "react";
import { Modal, Button, Icon, List } from "semantic-ui-react";
import AjaxFn from "services/AjaxFn";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { timerestrict_booking } from "utils/user";
class DeleteButton extends React.Component {
  state = {
    open: false,
    loading: false,
    redirecting: false
  };
  handleOpen = () => this.setState({ open: true });
  handleClose = () => this.setState({ open: false });
  handleConfirm = () => {
    this.setState({ loading: true });
    AjaxFn({
      url: "/apis/portal/bookings",
      data: {
        action: "delete",
        data: {
          ID: this.props.booking.id
        }
      },
      success: () => {
        window.toastr.success();
        this.setState({ redirecting: true });
      },
      failure: error => {
        window.toastr.error(error);
        this.setState({ loading: false });
      }
    });
  };
  render() {
    const { user, booking } = this.props;
    const can_cancel = timerestrict_booking(
      booking,
      user,
      ["BOOKINGS_DELETE"],
      ["BOOKINGS_WIZARD"]
    );
    if (!can_cancel) {
      return <p>You do not have access to delete bookings.</p>;
    }

    if (this.state.redirecting) {
      return <Redirect push to={"/browse/bookings"} />;
    }
    const trigger = (
      <Button color={"red"} onClick={this.handleOpen}>
        Delete Booking
      </Button>
    );
    return (
      <Modal
        open={this.state.open}
        trigger={trigger}
        onClose={this.handleClose}
        size={"mini"}
      >
        <Modal.Header icon={"exclamation triangle"}>Are you sure?</Modal.Header>
        <Modal.Content>
          <List>
            <List.Item>
              <List.Icon name="info" />
              <List.Content>
                <List.Description>
                  All financial info will be lost.
                </List.Description>
              </List.Content>
            </List.Item>
            <List.Item>
              <List.Icon name="info" />
              <List.Content>
                <List.Description>
                  All audit info will be lost.
                </List.Description>
              </List.Content>
            </List.Item>
            <List.Item>
              <List.Icon name="info" />
              <List.Content>
                <List.Description>
                  Any points usage data will be lost.
                </List.Description>
              </List.Content>
            </List.Item>
            <List.Item>
              <List.Icon name="info" />
              <List.Content>
                <List.Description>
                  Any records of sent notices will be lost.
                </List.Description>
              </List.Content>
            </List.Item>
            <List.Item>
              <List.Icon name="info" />
              <List.Content>
                <List.Description>
                  A booking that has had a check printed for it can not be
                  deleted.
                </List.Description>
              </List.Content>
            </List.Item>
          </List>
        </Modal.Content>
        <Modal.Actions>
          <Button
            color={"red"}
            onClick={this.handleConfirm}
            loading={this.state.loading}
            disabled={this.state.loading}
          >
            <Icon name={"checkmark"} />
            Yes, delete it
          </Button>
          <Button onClick={this.handleClose} disabled={this.state.loading}>
            <Icon name={"close"} />
            Nevermind
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}
const mapStateToProps = state => ({
  booking: state.BookingPage.booking.data,
  user: state.User
  // ...
});
export default connect(mapStateToProps)(DeleteButton);
