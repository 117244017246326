import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import theme from "styled-theming";
import moment from "moment";

import { fontColor } from "theme";
import { ThemePopup } from "theme/components";
import {
  offWhite,
  offWhiteDark,
  portalBlack,
  portalBlackLightest,
  portalBlue
} from "theme/colors";

const ViewPost = ({ goBack, posts, viewing }) => {
  const post = posts.filter(post => post.id === viewing);
  const back = () => (
    <Back onClick={goBack}>
      <i className="fas fa-long-arrow-alt-left" /> back
    </Back>
  );
  const renderPost = () => {
    return post.map(p => {
      const { header, plain_text, created_by, created_on } = p;

      const date = () => {
        const postDate = moment(created_on);

        if (!postDate.isSame(moment(), "year")) {
          return postDate.format("MM-DD-YYYY");
        }
        if (!!(moment().diff(postDate, "hours") > 24)) {
          return postDate.format("MMM D");
        }
        return postDate.fromNow();
      };

      const createdBy = `${created_by.firstname} ${created_by.lastname}`;

      return (
        <StyledPost key={p.id}>
          {back()}
          <div className="post">
            <div className="post-header">
              <strong>{header}</strong>
              <span>&bull;</span>
              <span>{date()}</span>
            </div>
            <div className="post-content">
              <p>{plain_text}</p>
            </div>
            <div className="post-footer">
              <p>Posted By: {createdBy}</p>
            </div>
          </div>
        </StyledPost>
      );
    });
  };

  return renderPost();
};

const userBackgroundColor = theme("mode", {
  light: portalBlue,
  dark: portalBlackLightest
});

const userFontColor = theme("mode", {
  light: offWhite,
  dark: offWhite
});

const borderColor = theme("mode", {
  light: offWhiteDark,
  dark: portalBlack
});

const Back = styled.button`
  background-color: transparent;
  border: none;
  color: ${fontColor};
  cursor: pointer;
  margin-bottom: 1.5rem;
`;

const StyledPost = styled.div`
  padding: 1.5rem;
  color: ${fontColor};

  .post {
    display: grid;
    grid-template-columns: auto;
    grid-column-gap: 1rem;
  }

  .post-header {
    display: flex;
    justify-content: flex-start;
    margin-bottom: 0.5rem;
    text-align: left;

    strong {
      font-weight: 700;
    }
    span {
      margin-left: 0.5rem;
      opacity: 0.5;
    }
  }

  .post-content {
    text-align: left;
    white-space: pre-wrap;
    width: 100%;
  }

  .post-footer {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    margin-top: 1.5rem;
    padding-top: 1.5rem;

    border-top: 1px solid ${borderColor};
  }
`;

export default ViewPost;
