import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Route, Switch, Redirect } from "react-router-dom";
import { withRouter } from "react-router";

// Wizards
import PropertyWizard from "./PropertyWizard";
import AccountWizard from "./AccountWizard";

// Content Management
import GuestList from "./GuestList";
import GuestPage from "./GuestPage";
import ResortCompanyList from "./ResortCompanyList";
import UnitList from "./UnitList";
import UnitPage from "./UnitPage";
import CalendarPage from "./CalendarPage";
import BookingList from "./BookingList";
import BookingPage from "./BookingPage";
import ResortList from "./ResortList";
import ResortPage from "./ResortPage";
import MemberList from "./MemberList";
import MemberPage from "./MemberPage";
import MembershipList from "./MembershipList";
import OwnerWizard from "./OwnerWizard";
import BookingWizard from "./BookingWizard";

// Financial Content Management
import BanksList from "./BanksList";
import ChecksList from "./ChecksList";
import CheckPage from "./CheckPage";
import CheckInvoicePage from "./CheckInvoicePage";
import CheckPrintPage from "./CheckPrintPage";
import ChecksWizard from "./CheckWizard";
import EndOfQuarterPage from "./EndOfQuarterPage";

// Admin Content Management
import PageManager from "./PageManager";
import PageGroupManager from "./PageGroupManager";
import AccountManager from "./AccountManager";
import AccountManagerSpreadsheet from "./AccountManagerSpreadsheet";
import AccountRoleManager from "./AccountRoleManager";
import AccountCompanyManager from "./AccountCompanyManager";
import TimepunchManager from "./TimepunchManager";

// Agreements
import AgreementTemplateWizard from "./AgreementTemplateWizard";
import AgreementTemplateList from "./AgreementTemplateList";
import AgreementTemplatePage from "./AgreementTemplatePage";
import AgreementsList from "./AgreementsList";
import AgreementInternal from "./AgreementInternal";
import AgreementWizard from "./AgreementWizard";
import AgreementsLegacy from "./AgreementsLegacy";
import AgreementsLegacyPage from "./AgreementsLegacyPage";

// Uncategorized
import Dashboard from "./Dashboard";
import NotFound from "./NotFound";
import FinancesImportPage from "./FinancesImportPage";
import AvailabilityImportPage from "./AvailabilityImportPage";
import PayoutReport from "./PayoutReport";

export class AuthedScenes extends Component {
  static propTypes = {
    user: PropTypes.object.isRequired
  };

  render() {
    const { user } = this.props;

    if (typeof user.id === "undefined") {
      return <Redirect to={{ pathname: "/login" }} />;
    } else {
      return (
        <Switch>
          {/* wizards */}
          <Route exact path="/wizards/properties" component={PropertyWizard} />
          <Route exact path="/wizards/accounts" component={AccountWizard} />
          {/* content management */}
          <Route exact path="/import/finances" component={FinancesImportPage} />
          <Route
            exact
            path="/import/availability"
            component={AvailabilityImportPage}
          />

          <Route exact path="/browse/guests" component={GuestList} />
          <Route
            exact
            path="/browse/guests/:guestID?/:guestTab?"
            component={GuestPage}
          />
          <Route
            exact
            path="/browse/resortCompanies"
            component={ResortCompanyList}
          />

          <Route exact path="/browse/units" component={UnitList} />
          <Route exact path="/browse/units/:unitID" component={UnitPage} />
          <Route
            exact
            path="/browse/units/:id/calendar"
            component={CalendarPage}
          />
          <Route
            exact
            path="/browse/units/:unitID/:unitTab"
            component={UnitPage}
          />
          <Route
            exact
            path="/browse/units/:unitID/listing/:listingID"
            component={UnitPage}
          />

          <Route
            exact
            path="/browse/units/:unitID/listing/:listingID/:listingTab"
            component={UnitPage}
          />

          <Route exact path="/browse/resorts" component={ResortList} />
          <Route
            exact
            path="/browse/resorts/:resortID/:resortTab?"
            component={ResortPage}
          />
          <Route exact path="/browse/bookings" component={BookingList} />
          <Route
            exact
            path="/browse/bookings/:bookingID"
            component={BookingPage}
          />
          <Route
            exact
            path="/browse/bookings/:bookingID/:bookingTab"
            component={BookingPage}
          />

          <Route exact path="/browse/members" component={MemberList} />
          <Route exact path="/browse/memberships" component={MembershipList} />
          <Route
            exact
            path={"/browse/members/:memberID/:memberTab?"}
            component={MemberPage}
          />

          <Route
            exact
            path="/browse/members/:memberID/membership/:membershipID/:membershipTab?"
            component={MemberPage}
          />

          <Route exact path="/wizards/members" component={OwnerWizard} />
          <Route exact path="/wizards/members/:id" component={OwnerWizard} />
          <Route exact path="/wizards/memberships" component={OwnerWizard} />
          <Route exact path="/wizards/bookings" component={BookingWizard} />
          {/* financials */}
          <Route exact path="/browse/banks" component={BanksList} />
          <Route exact path="/browse/checks" component={ChecksList} />
          <Route exact path="/browse/checks/:id" component={CheckPage} />
          <Route
            exact
            path="/browse/checks/:id/invoice"
            component={CheckInvoicePage}
          />

          <Route
            exact
            path="/browse/checks/:id/print"
            component={CheckPrintPage}
          />
          <Route exact path="/wizards/checks" component={ChecksWizard} />
          <Route exact path="/reports/eoq" component={EndOfQuarterPage} />
          {/* admin panel */}
          <Route exact path="/browse/pages" component={PageManager} />
          <Route exact path="/browse/pageGroups" component={PageGroupManager} />
          <Route exact path="/browse/accounts" component={AccountManager} />
          <Route
            exact
            path="/browse/accounts/spreadsheet"
            component={AccountManagerSpreadsheet}
          />

          <Route
            exact
            path="/browse/accountRoles"
            component={AccountRoleManager}
          />
          <Route
            exact
            path="/browse/accountCompanies"
            component={AccountCompanyManager}
          />

          <Route
            exact
            path="/browse/timepunches"
            component={TimepunchManager}
          />
          {/* agreements */}
          <Route
            exact
            path="/wizards/agreementtemplates"
            component={AgreementTemplateWizard}
          />

          <Route
            exact
            path="/browse/agreementtemplates"
            component={AgreementTemplateList}
          />

          <Route
            exact
            path="/browse/agreementtemplates/:id"
            component={AgreementTemplatePage}
          />

          <Route exact path="/browse/agreements" component={AgreementsList} />
          <Route
            exact
            path="/browse/agreements/:id/:viewing?"
            component={AgreementInternal}
          />
          <Route exact path="/wizards/agreements" component={AgreementWizard} />
          <Route
            exact
            path="/browse/legacyagreements"
            component={AgreementsLegacy}
          />

          <Route
            exact
            path="/browse/legacyagreements/:id"
            component={AgreementsLegacyPage}
          />
          {/* miscellaneous */}
          <Route exact path="/reports/payout" component={PayoutReport} />
          <Route exact path="/" component={Dashboard} />
          <Route component={NotFound} />
        </Switch>
      );
    }
  }
}

const mapStateToProps = state => ({
  user: state.User
});

const mapDispatchToProps = {
  // ...
};

const connected = connect(
  mapStateToProps,
  mapDispatchToProps
)(AuthedScenes);

export default withRouter(connected);
