import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Container, Form } from "semantic-ui-react";
import styled from "styled-components";

import { Wrapper } from "components/Agreement/context";

import { user_can } from "utils/user";
import AjaxFn from "services/AjaxFn";

import CalculateFinanceAgreement from "./components/CalculateFinanceAgreement";
import calculate from "./components/Calculate";

class Config extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      hasAdminPriv: false,
      formIsValid: false,
      step: "calculate"
    };
  }

  static propTypes = {
    context: PropTypes.object.isRequired
  };

  componentDidMount = () => {
    const { context, User } = this.props;
    const {
      functions: {}
    } = context;
    if (user_can("ACCOUNTS_ADMIN_LOGIN", User)) {
      this.setState({ hasAdminPriv: true });
    } else {
      this.setState({ hasAdminPriv: false });
    }

    this.validate(context.data);
  };

  componentDidUpdate = (prevProps, prevState) => {
    if (prevProps.context.data !== this.props.context.data) {
      this.validate();
    }
  };

  componentWillUnmount = () => {
    clearTimeout(this.loadingTimeout);
  };

  validate = () => {
    const { context } = this.props;
    const {
      base_amount,
      down_payment,
      interest_rate,
      agreement_term
    } = context.data;

    const data = !!(
      base_amount &&
      down_payment &&
      interest_rate &&
      agreement_term
    );

    this.setState({ formIsValid: data });
  };

  handleCalculation = async () => {
    const {
      base_amount,
      down_payment,
      interest_rate,
      agreement_term,
      loan_start_date
    } = this.props.context.data;
    const { setData } = this.props.context.functions;

    await this.setState({ loading: true });

    // Store our calc function in a constant
    const calc = calculate({
      base_amount,
      down_payment,
      interest_rate,
      agreement_term,
      loan_start_date
    });

    // Set our data in the context data object
    await setData("financed_amount", calc.financed_amount);
    await setData("monthly_payment", calc.monthly_payment);
    await setData("total_payment", calc.total_payment);
    await setData("finance_charge", calc.finance_charge);
    await setData("total_sale", calc.total_sale);
    await setData("payments", calc.payments);

    const duration = 500;

    this.loadingTimeout = setTimeout(() => {
      this.setState({ loading: false, step: "control" });
    }, duration);
  };

  setStepCalc = () => this.setState({ step: "calculate" });

  save = () => {
    AjaxFn({
      url: "/apis/portal/agreements",
      data: {
        action: "prefill",
        data: {
          ID: this.props.context.agreement.id,
          data: {
            ...this.props.context.data
          }
        }
      },
      success: res => window.toastr["success"](res),
      failure: err => window.toastr["error"](err)
    });
  };

  render() {
    const { step } = this.state;
    const { context } = this.props;

    return (
      <StyledConfig id="config">
        <Container>
          {step === "calculate" && (
            <CalculateFinanceAgreement
              context={context}
              handleCalculation={this.handleCalculation}
              {...this.state}
            />
          )}
          {step === "control" && (
            <Form.Group>
              <Form.Button
                type="button"
                content="Adjust Finance Calculations"
                onClick={this.setStepCalc}
              />
              <Form.Button
                type="button"
                content="Save"
                onClick={this.save}
                primary
              />
            </Form.Group>
          )}
        </Container>
      </StyledConfig>
    );
  }
}

const mapStateToProps = state => ({ User: state.User });

const StyledConfig = styled.div`
  position: sticky;
  top: 0;
  width: 100%;
  background: #eee;
  padding: 1rem 1.5rem;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 5px;
  z-index: 1;

  .fields {
    margin-bottom: 0 !important;
    justify-content: flex-end;
  }

  .disabled {
    cursor: not-allowed;
    pointer-events: all !important;
  }
`;

export default Wrapper(connect(mapStateToProps)(Config));
