import React, { Fragment } from "react";
import TimepunchStatus from "./components/TimepunchStatus";
import CheckOffline from "./components/CheckOffline";
import Announcements from "./components/Announcements";
import SubscribeToStorage from "./components/SubscribeToStorage";
export default () => (
  <Fragment>
    <TimepunchStatus />
    <CheckOffline />
    <Announcements />
    <SubscribeToStorage />
  </Fragment>
);
