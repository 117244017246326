import React from "react";
import { Dimmer, Loader, Header, Icon } from "semantic-ui-react";
import FocusBar from "components/FocusBar";
import props from "../props.js";

const GuestFocusBar = props => {
  const {
    guestData: { data },
    guestID,
    guestTab
  } = props;

  const url = "/browse/guests/" + guestID + "/";

  return (
    <FocusBar>
      {data ? (
        <FocusBar.Content>
          <br />
          <FocusBar.Generic>
            <Header as="h3" inverted>
              {data.firstname} {data.lastname}
              <Header.Subheader>
                {data.active ? (
                  <>
                    <Icon name="checkmark" color="green" />
                    Guest is active
                  </>
                ) : (
                  <>
                    <Icon name="cancel" color="red" />
                    Guest is inactive
                  </>
                )}
              </Header.Subheader>
            </Header>
          </FocusBar.Generic>

          <FocusBar.Nav
            content="Summary"
            icon="far fa-folder"
            active={!guestTab}
            href={url}
          />
          <FocusBar.Nav
            content="Bookings"
            icon="far fa-address-book"
            active={guestTab === "bookings"}
            href={url + "bookings"}
          />
          <FocusBar.Nav
            content="Comments"
            icon="far fa-comments"
            active={guestTab === "comments"}
            href={url + "comments"}
          />
        </FocusBar.Content>
      ) : (
        <FocusBar.Content>
          <Dimmer active style={{ background: "none" }}>
            <Loader />
          </Dimmer>
        </FocusBar.Content>
      )}
    </FocusBar>
  );
};

GuestFocusBar.propTypes = { ...props };
export default GuestFocusBar;
