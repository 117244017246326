export const OwnerIssues = [
  { value: "MISCHI", text: "Misc. high priority" },
  { value: "MISCLOW", text: "Misc. low priority" },
  { value: "CANCBOOK", text: "Cancelled Booking(s)" },
  { value: "CBACK", text: "Charged Back" },
  { value: "IIOD", text: "Information Out Of Date" },
  { value: "NOLOGIN", text: "Login Broken" },
  { value: "PAYISSUE", text: "Payout Issue" },
  { value: "NEWBOOK", text: "New Booking", disabled: true },
  { value: "FIRSTBOOK", text: "First Booking", disabled: true }
];

export const BookingIssues = [
  { value: "MISCHI", text: "Misc. high priority" },
  { value: "MISCLOW", text: "Misc. low priority" },
  { value: "NEEDADDR", text: "Needs address" },
  { value: "NOCHECK", text: "Guest can't check in" },
  { value: "NOPAY", text: "Not paid" },
  { value: "OWNCANC", text: "Owner cancelled" },
  { value: "WOS", text: "Wall of Shame" },
  { value: "AUDIT", text: "Booking Audit", disabled: true }
];

export const getIssueName = (name, issues) => {
  const issue = issues.find(row => row.value === name);
  if (issue) {
    return issue.text;
  } else {
    return name;
  }
};
