const InitialState = {
  posts: [],
  loading: false
};

export default (state = InitialState, action) => {
  switch (action.type) {
    case "NEWSFEED_PUSH_NEWS":
      return {
        ...state,
        posts: [{ ...action.data }].concat(state.posts)
      };
    case "NEWSFEED_REQUEST":
      return {
        posts: [],
        loading: true
      };
    case "NEWSFEED_REQUEST_SUCCESS":
      return {
        posts: action.data,
        loading: false
      };
    case "NEWSFEED_REQUEST_ERROR":
      return {
        ...state,
        loading: false
      };
    default:
      return state;
  }
};
