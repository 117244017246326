import axios, { CancelToken } from "axios";
import qs from "qs";
import { getAPIURL } from "../../utils/url";

const _ctokens = {};
const resolve = (variable, fallback) =>
  typeof variable === "function"
    ? variable()
    : typeof variable === "undefined"
    ? fallback
    : variable;
const func = (fn) => (typeof fn === "function" ? fn : () => true);
export default (settings = {}) => {
  if (!settings.url) {
    throw new Error("Missing .url");
  }
  let _cancelFn = null;
  const __index = resolve(settings.index, false);
  const payload = resolve(settings.data, {});
  const success = func(settings.success);
  const failure = func(settings.failure);
  const finalFn = func(settings.finally);
  const message = resolve(settings.message, "An unknown error occurred");
  const _wcreds = resolve(settings.withCredentials, true);
  const _ctoken = new CancelToken((c) => (_cancelFn = c));

  if (__index) {
    if (typeof _ctokens[__index] === "function") {
      _ctokens[__index]();
    }
    _ctokens[__index] = _cancelFn;
  }

  axios
    .post(getAPIURL(settings.url), qs.stringify(payload), {
      cancelToken: _ctoken,
      withCredentials: _wcreds,
    })
    .then(({ data }) => {
      if (!data.status) {
        throw data.message;
      }
      success(data.message);
    })
    .catch((error) => {
      if (typeof error === "object") {
        if (error.__CANCEL__) {
          console.log("Cancelled request to", settings.url);
          return;
        }
      }
      failure(error || message);
    })
    .finally(finalFn);

  return _cancelFn;
};
