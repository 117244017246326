export const uniqueFilter = (value, index, self) =>
  self.indexOf(value) === index;
export const getUnique = (array) => array.filter(uniqueFilter);
export const chunk = (arr, len) => {
  let chunks = [],
    i = 0,
    n = arr.length;
  while (i < n) {
    chunks.push(arr.slice(i, (i += len)));
  }
  return chunks;
};
