import React from "react";
import { Container, Button } from "semantic-ui-react";
import Layout from "components/Layout";
import Breadcrumb from "./components/Breadcrumb";
import UploadStep from "./components/UploadStep";
import MapStep from "./components/MapStep";
import AjaxFn from "services/AjaxFn";
export default class FinancesImportPage extends React.Component {
  state = {
    data: false,
    loading: false
  };
  verifyFirstRow = data => {
    const firstRow = data[0];
    const expected = [
      "Date",
      "Type",
      "Confirmation Code",
      "Start Date",
      "Nights",
      "Guest",
      "Listing",
      "Details",
      "Reference",
      "Currency",
      "Amount",
      "Paid Out",
      "Host Fee",
      "Cleaning Fee"
    ];
    try {
      expected.forEach((el, index) => {
        if (firstRow[index] !== el) {
          throw new Error();
        }
      });
      return true;
    } catch (e) {
      const expectedStr = expected.join(", ");
      const receivedStr = firstRow.join(", ");
      const msg = `The header row was missing or in the wrong format\n\n Expected: ${expectedStr}\n\nReceived: ${receivedStr}`;
      window.toastr["error"](msg);
      return false;
    }
  };
  handleUpload = data => {
    let valid = this.verifyFirstRow(data);
    if (valid) {
      this.setState({
        data: data.splice(1).filter(row => row[1] !== "Payout")
      });
    } else {
      this.setState({ data: false });
    }
  };
  confirm = () => {
    this.setState({ loading: true });
    AjaxFn({
      url: "/apis/portal/csvimport/finances",
      data: {
        data: JSON.stringify(this.state.data)
      },
      success: message => {
        window.toastr.success();
        this.setState({ loading: false, data: false });
      },
      failure: error => {
        window.toastr.error(error);
        this.setState({ loading: false });
      }
    });
  };
  render() {
    return (
      <Layout noBar>
        <Breadcrumb />
        <br />
        <Container>
          <UploadStep handleUpload={this.handleUpload} />
          {this.state.data && (
            <div>
              <MapStep data={this.state.data} />
              <div style={{ textAlign: "right" }}>
                <Button
                  primary
                  onClick={this.confirm}
                  disabled={this.state.loading}
                  loading={this.state.loading}
                >
                  Confirm
                </Button>
              </div>
            </div>
          )}
        </Container>
      </Layout>
    );
  }
}
