import axios from "axios";
import qs from "qs";
import { getAPIURL } from "../../utils/url";

// @depreciated USE "services/AjaxFn" INSTEAD

// tokens used to cancel ajax requests
const CancelToken = axios.CancelToken;

class Ajax {
  // -----------------------------------------------------
  // ----------- USER CONFIGURABLE VARIABLES -------------
  // the ajax URL (string or function returning string)
  url = null;
  // the ajax Method (string, "get" or "post")
  method = "post";
  // the ajax data (obj or function returning obj)
  data = {};
  // constructor, used to set all of the above properties from an object
  constructor({ url, data, method }) {
    if (url) {
      this.url = getAPIURL(url);
    } else {
      throw new Error("Missing .url");
    }
    if (typeof method === "string") {
      let lc = method.toLowerCase();
      if (lc === "get" || lc === "post") {
        this.method = lc;
      } else {
        throw new Error('Invalid .method ("get" or "post" only)');
      }
    }
    if (data) {
      this.data = data;
    }
  }
  // -----------------------------------------------------
  _cancelRequest = null;
  _index = 0;
  // -----------------------------------------------------
  /**
   *  Check if value is a function, if so, execute and return ,if not, just return
   *	@param {Mixed} value
   *  @param {Argument} args
   *  @return {Mixed}
   */
  _funcReturn = (value, ...args) =>
    typeof value === "function" ? value(...args) : value;
  /**
   *	Handler that sends ajax requests and chooses to ignore them if necessary
   *	@param {String} url
   *	@param {Object} data
   *	@param {String} method
   *  @param {Function} validator
   *  @param {Function} onSuccess
   *  @param {Function} onError
   */
  _send = (url, data, method, validator, onSuccess, onError) => {
    if (this.requestPending()) {
      this.cancelPendingRequest();
    }
    // get/post requests have to have cancel tokens in different function args
    const CTD = this._getOptions();
    // figure out the order to pass arguments with the cancel token
    let [arg1, arg2, arg3] =
      method === "post" ? [url, data, CTD] : [url, CTD, null];
    // update the current index, save the one to listen for
    this._index++;
    const newIndex = this._index;
    // fire the request
    axios[method](arg1, arg2, arg3)
      .then(({ data }) => {
        // check if we're on the correct _index
        if (newIndex === this._index) {
          // validate the response
          if (validator(data)) {
            onSuccess(data);
          } else {
            onError(data);
          }
        }
      })
      .catch((err) => {
        console.log("ERROR IN AJAX CLASS -", err);
        // verify we're on the correct _index
        if (newIndex === this._index) {
          onError("Could not connect");
        }
      });
  };
  /**
   *	Create and return a cancel token
   */
  _getOptions = () => {
    return {
      cancelToken: new CancelToken((c) => (this._cancelRequest = c)),
      withCredentials: true,
    };
  };
  /**
   *	Checks if a request is in progress
   */
  requestPending = () => {
    return !!this._cancelRequest;
  };
  /**
   *	Cancels a request if one is in progress
   */
  cancelPendingRequest = () => {
    this._index++;
    this._cancelRequest();
  };
  send = (validator, onSuccess, onError, data) => {
    this.reload(validator, onSuccess, onError, data);
  };
  /**
   *	Triggers a reload
   */
  reload = (validator, onSuccess, onError, data) => {
    let fn = () => true;
    if (!validator) validator = fn;
    if (!onSuccess) onSuccess = fn;
    if (!onError) onError = fn;
    // if no over-ridden data object was passed, use this.data
    if (!data) {
      data = this._funcReturn(this.data);
    } else {
      data = this._funcReturn(data);
    }
    let url = this._funcReturn(this.url);
    let method = this._funcReturn(this.method);
    if (method === "get") {
      url += "?" + qs.stringify(data);
      data = {};
    } else {
      data = qs.stringify(data);
    }
    this._send(url, data, method, validator, onSuccess, onError);
  };
}

export { Ajax };
