import InitialState from "./initial";
export default (state = InitialState, action) => {
  switch (action.type) {
    case "BOOKPAGE_SET_START":
      return {
        ...state,
        start: action.data
      };
    case "BOOKPAGE_SET_END":
      return {
        ...state,
        end: action.data
      };
    case "BOOKPAGE_SWITCH_START_END":
      return {
        ...state,
        start: state.end,
        end: state.start
      };
    case "BOOKPAGE_SET_TYPE":
      return {
        ...state,
        type: action.data
      };
    default:
      return state;
  }
};
