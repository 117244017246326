import React from "react";
import {
  Button,
  Icon,
  Modal,
  Form,
  Input,
  Dropdown,
  Flag
} from "semantic-ui-react";
import { countries } from "fixtures/countries";

export default class WizardClass extends React.PureComponent {
  render() {
    const { ContentManager, Wizard } = this.props;
    return (
      <Modal open={ContentManager.state.wizardActive} size={"small"}>
        <Modal.Header>Create Guest</Modal.Header>
        <Modal.Content>
          <Form>
            <Form.Group widths={"equal"}>
              <Form.Field>
                <label>First Name</label>
                {Wizard.functions.createInput(<Input />, "firstname")}
              </Form.Field>
              <Form.Field>
                <label>Last Name</label>
                {Wizard.functions.createInput(<Input />, "lastname")}
              </Form.Field>
            </Form.Group>
            <Form.Group widths={"equal"}>
              <Form.Field>
                <label>Phone Number</label>
                {Wizard.functions.createInput(<Input />, "phone")}
              </Form.Field>
              <Form.Field>
                <label>Email Address</label>
                {Wizard.functions.createInput(<Input />, "email")}
              </Form.Field>
            </Form.Group>
            <Form.Group>
              <Form.Field width={10}>
                <label>Street Address</label>
                {Wizard.functions.createInput(<Input />, "address")}
              </Form.Field>
              <Form.Field width={6}>
                <label>City</label>
                {Wizard.functions.createInput(<Input />, "city")}
              </Form.Field>
            </Form.Group>
            <Form.Group widths={"equal"}>
              <Form.Field>
                <label>Country</label>
                {Wizard.functions.createInput(<Dropdown />, "country", {
                  search: true,
                  selection: true,
                  lazyLoad: true,
                  options: [
                    {
                      value: "none",
                      text: (
                        <React.Fragment>
                          <Icon name={"cancel"} color={"red"} />
                          None
                        </React.Fragment>
                      )
                    }
                  ].concat(
                    countries.map((c) => ({
                      value: c[0],
                      text: (
                        <React.Fragment>
                          <Flag name={c[1]} />
                          {c[2]}
                        </React.Fragment>
                      )
                    }))
                  )
                })}
              </Form.Field>
              <Form.Field>
                <label>State</label>
                {Wizard.functions.createInput(<Input />, "state")}
              </Form.Field>
              <Form.Field>
                <label>Zipcode</label>
                {Wizard.functions.createInput(<Input />, "zipcode")}
              </Form.Field>
            </Form.Group>
          </Form>
        </Modal.Content>
        <Modal.Actions>
          {Object.keys(Wizard.data).length ? (
            <React.Fragment>
              <Button
                type={"button"}
                onClick={Wizard.functions.save}
                loading={ContentManager.state.wizardLoading}
                icon={"save"}
                content={"Save"}
                color={"blue"}
              />
              <Button
                type={"button"}
                content={"Discard"}
                onClick={Wizard.functions.reset}
              />
            </React.Fragment>
          ) : null}
          <Button onClick={ContentManager.functions.closeWizard}>
            <Icon name={"close"} />
            Close
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}
