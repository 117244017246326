import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { reload } from "redux/TimepunchManager/actions";

import { Button, Container, Header, Message } from "semantic-ui-react";
import Loader from "components/WithState/components/Loader";
import { InitialBar } from "./components/Breadcrumbs";
import Controls from "./components/Header";
import TimepunchTable from "./components/TimepunchTable";
import TimepunchRow from "./components/TimepunchRow";

import Layout from "components/Layout";

export class TimepunchManager extends Component {
  static propTypes = {
    loading: PropTypes.bool.isRequired,
    error: PropTypes.any.isRequired,
    reload: PropTypes.func.isRequired,
    processed: PropTypes.array.isRequired
  };

  componentDidMount() {
    this.props.reload();
  }

  renderContent = () => {
    const { loading, error, reload } = this.props;
    if (loading) {
      return <Loader />;
    } else if (error) {
      return (
        <Message error>
          <Message.Header>Something went wrong</Message.Header>
          <p>{error}</p>
          <Button basic content="Reload" onClick={reload} />
        </Message>
      );
    } else {
      return (
        <Fragment>
          <TimepunchTable />
          <TimepunchRow />
        </Fragment>
      );
    }
  };

  render() {
    return (
      <Layout noBar>
        <InitialBar />
        <Container style={{ paddingBottom: "4rem" }}>
          <Header as={"h1"} dividing style={{ marginTop: "2rem" }}>
            Timepunches
            <div className={"sub header"}>
              Below are all of the timepunches in the database.
            </div>
          </Header>
          <Controls />
          {this.renderContent()}
        </Container>
      </Layout>
    );
  }
}

const mapStateToProps = state => ({
  loading: state.TimepunchManager.loading,
  error: state.TimepunchManager.error,
  processed: state.TimepunchManager.processed
});

const mapDispatchToProps = {
  reload
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TimepunchManager);
