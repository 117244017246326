import React from "react";
import {
  Container,
  Header,
  Segment,
  Button,
  Icon,
  Divider,
  Form,
  Input,
  Dropdown,
  Label,
  Accordion
} from "semantic-ui-react";
import AjaxFn from "services/AjaxFn";
import Agreement, { getCurrentData } from "components/Agreement";
import Loader from "components/WithState/components/Loader";
import brace from "brace";
import AceEditor from "react-ace";
import NullableDropdown from "components/NullableDropdown";
import "brace/mode/html";
import "brace/snippets/html";
import "brace/theme/monokai";
import "brace/ext/language_tools";
import "brace/ext/searchbox";
const ace_config = {
  mode: "html",
  theme: "monokai",
  name: "blah2",
  fontSize: 16,
  showPrintMargin: true,
  showGutter: true,
  highlightActiveLine: true,
  setOptions: {
    enableBasicAutocompletion: false,
    enableLiveAutocompletion: true,
    enableSnippets: true,
    showLineNumbers: true,
    tabSize: 2
  },
  style: { width: "100%" }
};
export default class Editor extends React.Component {
  state = {
    loading: false,
    data: null
  };
  componentDidMount() {
    this.setState({ data: this.getOriginal() });
  }
  getOriginal = () => {
    const { data } = this.props;
    const current = getCurrentData({ id: 1, template: data });
    const versions = [{ ...current }];
    versions[0].created_on = versions[0].template_version_date;
    delete versions[0].template;
    delete versions[0].template_version;
    delete versions[0].template_version_date;
    delete versions[0].id;
    return versions[0];
  };
  createInput = (element, source, props) => {
    return (
      <Form.Field>
        <label>{source}</label>
        {React.cloneElement(element, {
          value: this.state.data[source] || "",
          onChange: (e, { value }) =>
            this.setState({
              data: {
                ...this.state.data,
                [source]: value
              }
            }),
          fluid: true,
          ...props
        })}
      </Form.Field>
    );
  };
  getDifferences = () => {
    const changes = {};
    const state = this.state.data;
    const original = this.getOriginal();
    for (var k in state) {
      if (state[k] !== original[k]) {
        changes[k] = state[k];
      }
    }
    if (typeof changes.payment_required !== "undefined") {
      changes.payment_required = changes.payment_required ? 1 : 0;
    }
    return changes;
  };
  save = () => {
    const data = { ...this.getDifferences() };
    delete data.original_data;
    if (Object.keys(data).length === 0) {
      window.toastr["info"]("You have to make changes before saving");
      return false;
    }
    this.setState({ loading: true });
    AjaxFn({
      url: "/apis/portal/agreementtemplates",
      data: {
        action: "add_version",
        data: {
          ID: this.props.data.id,
          data
        }
      },
      success: message => {
        window.toastr.success();
        this.props.reload();
      },
      failure: error => {
        window.toastr.error(error);
        this.setState({ loading: false });
      }
    });
  };
  deactivate = () => {
    if (window.confirm("Are you sure?")) {
      this.activateDeactivateAjax(false);
    }
  };
  activate = () => this.activateDeactivateAjax(true);
  activateDeactivateAjax = status => {
    this.setState({ loading: true });
    AjaxFn({
      url: "/apis/portal/agreementtemplates/",
      data: {
        action: "activate_deactivate",
        data: {
          ID: this.props.data.id,
          active: status ? 1 : 0
        }
      },
      success: message => {
        window.toastr.success();
        this.props.reload();
      },
      failure: error => {
        window.toastr.error(error);
        this.setState({ loading: false });
      }
    });
  };
  render() {
    if (!this.state.data) {
      return null;
    }
    return (
      <div>
        <Container>
          <Segment>
            <Header as={"h1"} dividing>
              <Icon name={"settings"} />
              <Header.Content>
                Template Information
                <Header.Subheader>{this.props.data.name}</Header.Subheader>
              </Header.Content>
            </Header>
            {this.state.loading ? (
              <Loader />
            ) : (
              <div>
                <Form>
                  <Accordion
                    panels={[
                      {
                        key: 1,
                        title: {
                          content: (
                            <Label
                              // color="blue"
                              style={{ width: "200px" }}
                              content={"General Info"}
                            />
                          )
                        },
                        content: {
                          content: (
                            <div>
                              <Form.Group widths={"equal"}>
                                {this.createInput(<Dropdown />, "color", {
                                  options: [
                                    { value: "black", text: "Black" },
                                    { value: "red", text: "Red" },
                                    { value: "orange", text: "Orange" },
                                    { value: "yellow", text: "Yellow" },
                                    { value: "olive", text: "Olive" },
                                    { value: "green", text: "Green" },
                                    { value: "teal", text: "Teal" },
                                    { value: "blue", text: "Blue" },
                                    { value: "purple", text: "Purple" },
                                    { value: "violet", text: "Violet" },
                                    { value: "pink", text: "Pink" },
                                    { value: "brown", text: "Brown" },
                                    { value: "grey", text: "Grey" }
                                  ],
                                  selection: true
                                })}
                                {this.createInput(
                                  <NullableDropdown />,
                                  "payment_required",
                                  {
                                    options: [
                                      { value: true, text: "Yes" },
                                      { value: false, text: "No" }
                                    ],
                                    selection: true
                                  }
                                )}
                                {this.createInput(<Input />, "year", {
                                  type: "number"
                                })}
                              </Form.Group>
                              <Form.Group widths={"equal"}>
                                {this.createInput(
                                  <Input />,
                                  "account_company_logo",
                                  {}
                                )}
                                {this.createInput(
                                  <Input />,
                                  "account_company_legal_name",
                                  {}
                                )}
                                {this.createInput(<Input />, "title", {})}
                              </Form.Group>
                              <Form.Group widths={"equal"}>
                                {this.createInput(
                                  <Input />,
                                  "account_company_phone",
                                  {}
                                )}
                                {this.createInput(
                                  <Input />,
                                  "account_company_email",
                                  {}
                                )}
                                {this.createInput(
                                  <Input />,
                                  "account_company_web",
                                  {}
                                )}
                              </Form.Group>
                            </div>
                          )
                        }
                      },
                      {
                        key: 2,
                        title: {
                          content: (
                            <Label
                              // color="blue"
                              style={{ width: "200px" }}
                              content={"Flavor Text"}
                            />
                          )
                        },
                        content: {
                          content: (
                            <Form.Field>
                              <label>Flavor Text</label>
                              <AceEditor
                                {...ace_config}
                                onChange={value =>
                                  this.setState({
                                    data: { ...this.state.data, html: value }
                                  })
                                }
                                value={this.state.data.html}
                              />
                            </Form.Field>
                          )
                        }
                      },
                      {
                        key: 3,
                        title: {
                          content: (
                            <Label
                              // color="blue"
                              style={{ width: "200px" }}
                              content={"Legal Text"}
                            />
                          )
                        },
                        content: {
                          content: (
                            <Form.Field>
                              <label>Legal Text</label>
                              <AceEditor
                                {...ace_config}
                                onChange={value =>
                                  this.setState({
                                    data: { ...this.state.data, legal: value }
                                  })
                                }
                                value={this.state.data.legal}
                              />
                            </Form.Field>
                          )
                        }
                      }
                    ]}
                  />
                </Form>
                <Divider />
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  {this.props.data.active ? (
                    <Button
                      onClick={this.deactivate}
                      loading={this.state.loading}
                      disabled={this.state.loading}
                      color={"red"}
                    >
                      Deactivate
                    </Button>
                  ) : (
                    <Button
                      onClick={this.activate}
                      loading={this.state.loading}
                      disabled={this.state.loading}
                    >
                      Activate
                    </Button>
                  )}
                  <Button
                    primary
                    onClick={this.save}
                    loading={this.state.loading}
                    disabled={this.state.loading}
                  >
                    Save Changes
                  </Button>
                </div>
              </div>
            )}
          </Segment>
        </Container>
        <br />
        <br />
        <Agreement
          // internal={false}
          type={"preview"}
          data={{
            id: null,
            template: {
              ...this.props.data,
              versions: [{ ...this.state.data }]
            },
            data: JSON.stringify({
              // ...
            })
          }}
        />
      </div>
    );
  }
}
