import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getUnit } from "redux/Calendar/actions";
import { getLastPull } from "utils/units";
import { Button, Icon } from "semantic-ui-react";
import moment from "moment";

export class Resync extends Component {
  static propTypes = {
    getUnit: PropTypes.func.isRequired,
    processing: PropTypes.bool.isRequired,
    changes: PropTypes.array.isRequired,
    year: PropTypes.number.isRequired,
    unit: PropTypes.object.isRequired
  };

  state = {
    now: new moment()
  };

  interval = null;

  componentDidMount() {
    this.interval = setInterval(() => {
      this.setState({
        now: new moment()
      });
    }, 1000);
  }

  /**
   * Return the diff in seconds between the most recent pull and now
   * @return {Integer}
   */
  getDiff = () => {
    const last_pull = getLastPull(this.props.unit);
    if (last_pull) {
      return this.state.now.diff(last_pull, "s");
    } else {
      return 61;
    }
  };

  /**
   * Handle click
   */
  handleClick = () => {
    const { unit, getUnit } = this.props;
    getUnit(unit.id, "resync");
  };

  /**
   * Render
   */
  render() {
    const { processing, margin } = this.props;
    const diff = this.getDiff();
    if (diff >= 60) {
      clearInterval(this.interval);
    }
    return (
      <Button
        basic
        disabled={processing || diff < 60}
        {...margin}
        onClick={this.handleClick}
        content={
          diff < 60 ? (
            <span>{60 - diff}s</span>
          ) : (
            <Fragment>
              <Icon name={"bolt"} />
              Resync
            </Fragment>
          )
        }
      />
    );
  }
}

const mapStateToProps = state => ({
  processing: state.Calendar.processing,
  changes: state.Calendar.changes,
  year: state.Calendar.year,
  unit: state.Calendar.unit
});

const mapDispatchToProps = {
  getUnit
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Resync);
