import React, { Component } from "react";
import PropTypes from "prop-types";
import WithState from "components/WithState";
import AjaxFn from "services/AjaxFn";
import { Redirect } from "react-router-dom";

export default class UnitListingLoader extends Component {
  static propTypes = {
    listingID: PropTypes.string,
    children: PropTypes.func.isRequired,
    unit: PropTypes.object.isRequired
  };

  componentDidMount() {
    this.get();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.listingID !== this.props.listingID) {
      this.get();
    }
  }

  componentWillUnmount() {
    this.cancel();
  }

  state = {
    loading: false,
    error: false,
    cancel: false,
    redirecting: false,
    data: {}
  };

  cancel = () => {
    if (typeof this.state.cancel === "function") {
      this.state.cancel();
      this.setState({ cancel: false });
    }
  };

  get = () => {
    this.verifyID();
    this.cancel();
    this.setState({
      loading: true,
      cancel: AjaxFn({
        url: this.getURL(),
        success: data => {
          this.setState({
            data,
            error: false,
            cancel: false,
            loading: false
          });
        },
        failure: error => {
          this.setState({
            data: {},
            error,
            cancel: false,
            loading: false
          });
        }
      })
    });
  };

  /**
   * Determine which type of config function to call and return
   * @return {Object}
   */
  getURL = () => {
    const { unit, listingID: id } = this.props;
    switch (unit.unit_type) {
      case "ciirus":
        return `/apis/portal/unittypeciirus?action=read_one&data[ID]=${id}`;
      default:
        throw new Error("invalid unit type");
    }
  };

  /**
   * Verify the id exists within the units listings
   * @param {Integer} id
   */
  verifyID = () => {
    const { unit, listingID: id } = this.props;
    let match = false;
    switch (unit.unit_type) {
      case "ciirus":
        match = unit.ciirus_units.filter(r => r.id === Number(id));
        break;
      default:
        throw new Error("invalid unit type");
    }
    if (match.length !== 1) {
      this.setState({ redirecting: unit.id });
    }
  };

  /**
   * Render
   */
  render() {
    if (this.state.redirecting) {
      return <Redirect push to={`/browse/units/${this.state.redirecting}`} />;
    }
    return (
      <WithState loading={this.state.loading} error={this.state.error}>
        {this.state.data.id && this.props.children({ ...this.state })}
      </WithState>
    );
  }
}
