import React from "react";
import { Button, Card } from "semantic-ui-react";

import { ThemeButton } from "theme/components";

export const Admin = () => (
  <>
    <ThemeButton
      href="https://teamup.com/kshzc88oxihc2ftzgs"
      content="Administrator Calendars"
      icon="external"
      target="_blank"
      rel="noreferrer noopener"
      secondary
      fluid
    />
    <ThemeButton
      href="https://teamup.com/ks6fq99v3quf58z8h7"
      content="Team 1"
      icon="external"
      target="_blank"
      rel="noreferrer noopener"
      secondary
      fluid
    />
    <ThemeButton
      href="https://teamup.com/ksmwrditwktwxij5gt"
      content="Team 2"
      icon="external"
      target="_blank"
      rel="noreferrer noopener"
      secondary
      fluid
    />
  </>
);

export const Accounting = () => (
  <>
    <ThemeButton
      href="https://teamup.com/kse4bzic113o6twvii"
      content="VMG Calendars"
      icon="external"
      target="_blank"
      rel="noreferrer noopener"
      secondary
      fluid
    />
    <ThemeButton
      href="https://teamup.com/ksoz87pizunbk525x2"
      content="Team 1 Deal Processing Calendar"
      icon="external"
      target="_blank"
      rel="noreferrer noopener"
      secondary
      fluid
    />
    <ThemeButton
      href="https://teamup.com/ks2ut8q5mvuhdrgwf9"
      content="Team 2 Deal Processing Calendar"
      icon="external"
      target="_blank"
      rel="noreferrer noopener"
      secondary
      fluid
    />
  </>
);

export const Booking = () => (
  <>
    <ThemeButton
      href="https://teamup.com/ksk38nfsfqgiht9atv"
      content="Booking Department Calendars"
      icon="external"
      target="_blank"
      rel="noreferrer noopener"
      secondary
      fluid
    />
  </>
);

export const CustomerService = () => (
  <>
    <ThemeButton
      href="https://teamup.com/ks37btr8g1937kw59w"
      content="Customer Service Calendars"
      icon="external"
      target="_blank"
      rel="noreferrer noopener"
      secondary
      fluid
    />
  </>
);

export const Developer = () => (
  <>
    <ThemeButton
      href="https://teamup.com/ksm4prb4n3pi158mfd"
      content="Developer Calendars"
      icon="external"
      target="_blank"
      rel="noreferrer noopener"
      secondary
      fluid
    />
  </>
);

export const Reception = () => (
  <>
    <ThemeButton
      href="https://teamup.com/ksv9yq9n88c7q3px3r"
      content="Reception Calendars"
      icon="external"
      target="_blank"
      rel="noreferrer noopener"
      secondary
      fluid
    />
  </>
);
