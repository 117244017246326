import React from "react";
import { Dropdown } from "semantic-ui-react";
/**
 *	@prop remapPrefix string - defaults "__FALSY_VALUE__"
 *	@prop remapFalsyTo mixed - defaults false
 *  @prop shallowRemap boolean - defaults true
 *  Any <Dropdown /> compatible prop
 */
export default class NullableDropdown extends React.Component {
  deepRemap = (remapPrefix, options, onChange, value) => ({
    // Look through options for any falsy values, and if any are found,
    // append our remapPrefix + the JSON encoded value
    // This ensures 0, false, and '', or anything else, is kept unchanged
    // We COULD just use an object here and skip the regex/json crap, but
    // semantic dropdowns do not support object values.
    options: options.map((option, index) => ({
      ...option,
      value: option.value || remapPrefix + JSON.stringify(option.value),
      key: index
    })),
    // If an onChange function exists, check if the value for the selected
    // Dropdown.Item is a string, then check if it has the remapPrefix
    // If so, get the JSON decoded value, and replace the value in the
    // onChange event with that instead.
    onChange: (e, data) => {
      if (typeof onChange === "function") {
        if (typeof data.value === "string") {
          const regex = new RegExp(`^${remapPrefix}(.+)`);
          const match = data.value.match(regex);
          if (match) {
            data.value = JSON.parse(match[1]);
          }
        }
        onChange(e, data);
      }
    },
    // If a value prop was specified, it has to be formatted accordingly
    value:
      typeof value !== "undefined" && !value
        ? remapPrefix + JSON.stringify(value)
        : value
  });
  shallowRemap = (remapPrefix, remapFalsyTo, options, onChange, value) => {
    return {
      // Remap any falsy option values to the remapPrefix so we can tell
      // that they are falsy
      options: options.map((option, index) => ({
        ...option,
        value: option.value || remapPrefix,
        key: index
      })),
      // Same concept as deepRemap, except no regex/json trickery. Just use remapFalsyTo
      onChange: (e, data) => {
        if (typeof onChange === "function") {
          onChange(e, {
            ...data,
            value: data.value === remapPrefix ? remapFalsyTo : data.value
          });
        }
      },
      // again, need to reformat the default value prop
      value: typeof value !== "undefined" && !value ? remapPrefix : value
    };
  };
  render() {
    const {
      remapPrefix = "__FALSY_VALUE__",
      remapFalsyTo = false,
      shallowRemap = true,
      options = [],
      onChange,
      value,
      ...rest
    } = this.props;
    let newProps = shallowRemap
      ? this.shallowRemap(remapPrefix, remapFalsyTo, options, onChange, value)
      : this.deepRemap(remapPrefix, options, onChange, value);
    return <Dropdown {...newProps} {...rest} />;
  }
}
