import AjaxFn from "services/AjaxFn";
import { getHighestCheckNumber } from "../../selector";

export const setBanksLoading = () => ({ type: "EOQ_BANKS_LOADING" });
export const setBanksError = data => ({ type: "EOQ_BANKS_ERROR", data });
export const setBanksData = data => ({ type: "EOQ_BANKS_DATA", data });

export const loadBanks = () => dispatch => {
  dispatch(setBanksLoading());
  AjaxFn({
    index: "eoq_banks",
    url: "/apis/portal/banks",
    data: {
      action: "read_for_checks"
    },
    success: message => dispatch(setBanksData(message)),
    failure: message => dispatch(setBanksError(message))
  });
};

export const setBank = bank => (dispatch, getState) => {
  const banks = getState().EOQReport.banks.data;
  const cnum = getHighestCheckNumber(bank, banks);
  dispatch(setStartingCheckNumber(cnum));
  dispatch({
    type: "EOQ_SET_BANK",
    data: bank
  });
};

export const setStartingCheckNumber = number => ({
  type: "EOQ_SET_CNUM",
  data: number
});
