import React, { Component } from "react";
import PropTypes from "prop-types";
import { BookingIssues } from "fixtures/issues";
import AjaxFn from "services/AjaxFn";
import {
  Modal,
  Button,
  Icon,
  Form,
  Input,
  Dropdown,
  TextArea
} from "semantic-ui-react";

export default class OpenIssue extends Component {
  static propTypes = {
    data: PropTypes.object.isRequired
  };

  state = {
    open: false,
    loading: false,
    data: {
      text: "",
      name: "",
      category: "MISCLOW"
    }
  };

  handleOpen = () => this.setState({ open: true });

  handleClose = () => {
    if (!this.state.loading) {
      this.setState({ open: false });
    }
  };

  canSave = () => {
    return (
      this.state.data.text &&
      this.state.data.name &&
      this.state.data.category &&
      !this.state.loading
    );
  };

  submit = () => {
    this.setState({ loading: true });
    AjaxFn({
      url: "/apis/portal/bookingissues",
      data: {
        action: "create",
        data: {
          booking_id: this.props.data.id,
          opened_reason: this.state.data.text,
          category: this.state.data.category,
          name: this.state.data.name
        }
      },
      success: () => {
        window.toastr.success();
        this.props.reload();
      },
      failure: error => {
        window.toastr.error(error);
        this.setState({ loading: false });
      }
    });
  };

  createInput = (el, src, props) => {
    return React.cloneElement(el, {
      value: this.state.data[src] || "",
      onChange: (e, { value }) =>
        this.setState({
          data: {
            ...this.state.data,
            [src]: value
          }
        }),
      disabled: this.state.loading,
      ...props
    });
  };

  render() {
    return (
      <Modal
        {...{
          open: this.state.open,
          onClose: this.handleClose,
          size: "mini",
          trigger: (
            <Button primary basic content="Open" onClick={this.handleOpen} />
          )
        }}
      >
        <Modal.Header>Open Issue</Modal.Header>
        <Modal.Content>
          <Form>
            <Form.Field>
              <label>Category</label>
              {this.createInput(<Dropdown />, "category", {
                placeholder: "Select a category",
                selection: true,
                options: BookingIssues
              })}
            </Form.Field>
            <Form.Field>
              <label>Issue Name</label>
              {this.createInput(<Input />, "name", {
                placeholder: "Name",
                maxLength: 64
              })}
            </Form.Field>
            <Form.Field>
              <label>Description</label>
              {this.createInput(<TextArea />, "text", {
                placeholder: "Description",
                maxLength: 1024
              })}
            </Form.Field>
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button disabled={this.state.loading} onClick={this.handleClose}>
            <Icon name={"cancel"} />
            Back
          </Button>
          <Button
            primary
            loading={this.state.loading}
            disabled={!this.canSave()}
            onClick={this.submit}
          >
            Submit
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}
