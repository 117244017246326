import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import injectSheet from "react-jss";

import { Container, Header, Grid } from "semantic-ui-react";

import { setFilters } from "redux/PortalTables/actions.js";
import { Ajax } from "services/Ajax";
import ContentManager from "components/ContentManager";
import Layout from "components/Layout";
import Breadcrumb from "./components/Breadcrumb";
import Editor from "./components/Editor";
import Filters from "./components/Filters";
import List from "./components/List";

const styles = {
  disabled: {
    "& td": {
      background: "#e6e6e6 !important"
    }
  }
};

export class AccountManager extends Component {
  static propTypes = {
    setFilters: PropTypes.func.isRequired,
    classes: PropTypes.object.isRequired
  };

  state = {
    filters: {
      disabled: false,
      company: "any",
      unrestricted: "any"
    }
  };

  updateFilters = (key, value) => {
    const { setFilters } = this.props;
    const filters = {
      ...this.state.filters,
      [key]: value
    };

    this.setState({ filters }, () => {
      setFilters("accounts", this.buildFilters());
    });
  };

  buildFilters = () => {
    const { filters } = this.state;
    const fns = [];

    if (filters.disabled !== "any") {
      fns.push(row => row.disabled === filters.disabled);
    }

    if (filters.company !== "any") {
      fns.push(row => row.account_company.id === filters.company);
    }

    if (filters.unrestricted !== "any") {
      fns.push(row => row.account_role.restricted !== filters.unrestricted);
    }

    return fns;
  };

  getConfig = ContentManager => {
    const { classes } = this.props;
    const filters = this.buildFilters();

    return {
      List: List(ContentManager, classes, filters),
      Editor: {
        JSX: Editor,
        AJAX: {
          instance: new Ajax({ url: "/apis/portal/accounts" }),
          onSuccess: () => window.toastr.success(),
          data: ({ id: ID }, data) => {
            if (data.account_role) {
              data.account_role_id = data.account_role.id;
              delete data.account_role;
            }
            if (data.account_company) {
              data.account_company_id = data.account_company.id;
              delete data.account_company;
            }
            return { action: "update", data: { ID, ...data } };
          }
        }
      }
    };
  };

  render() {
    const { filters } = this.state;
    const { getConfig, updateFilters } = this;
    return (
      <Layout noBar>
        <Breadcrumb />
        <Container style={{ paddingBottom: "4rem" }}>
          <Header as={"h1"} dividing style={{ marginTop: "2rem" }}>
            Accounts
            <div className={"sub header"}>
              Below are all of the pages registered in the database.
            </div>
          </Header>
          <ContentManager config={getConfig}>
            {({ components: { List, Editor, Wizard } }) => (
              <Fragment>
                <Grid>
                  <Grid.Column width={10}>
                    <Filters filters={filters} updateFilters={updateFilters} />
                    {List}
                  </Grid.Column>
                  <Grid.Column width={6}>{Editor}</Grid.Column>
                </Grid>
                {Wizard}
              </Fragment>
            )}
          </ContentManager>
          <br />
        </Container>
      </Layout>
    );
  }
}

const mapStateToProps = state => ({
  // ...
});

const mapDispatchToProps = {
  setFilters
};

export const connected = connect(
  mapStateToProps,
  mapDispatchToProps
)(AccountManager);

export default injectSheet(styles)(connected);
