import AjaxFn from "services/AjaxFn";
import { clearQueue } from "../";
import { processData } from "../Table";
import calculateBooking from "utils/bookings";
export const setBookingsLoading = () => ({ type: "EOQ_BOOK_LOADING" });
export const setBookingsError = data => ({ type: "EOQ_BOOK_ERROR", data });
export const setBookingsData = data => dispatch => {
  dispatch({
    type: "EOQ_BOOK_DATA",
    data: {
      rows: data.rows,
      bookings: data.bookings.map(booking => ({
        ...booking,
        // we know the booking is not cancelled, so we add in a blank cancellations array
        calculated: calculateBooking({ ...booking, cancellations: [] })
      }))
    }
  });
  dispatch(processData());
};
export const loadBookings = () => (dispatch, getState) => {
  const { start, end, auditedOnly } = getState().EOQReport;
  dispatch(setBookingsLoading());
  dispatch(clearQueue());
  AjaxFn({
    index: "eoq_books",
    url: "/apis/portal/reports",
    data: { action: "eoq", data: { start, end, audited: auditedOnly } },
    success: message => dispatch(setBookingsData(message)),
    failure: message => dispatch(setBookingsError(message))
  });
};
