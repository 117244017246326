import React, { Fragment } from "react";
import MemberForm from "./components/MemberForm";
import MembershipForm from "./components/MembershipForm";
import PaymentForm from "../PaymentForm";
import { Wrapper } from "../../context";

const Form = ({ context }) => {
  return (
    <Fragment>
      <MemberForm />
      <MembershipForm />
      {context.agreement.payment_required && <PaymentForm />}
    </Fragment>
  );
};

export default Wrapper(Form);
