export default [
  {
    value: "pointsrelease",
    text: "Points Release"
  },
  {
    value: "deedtransfer",
    text: "Deed Transfer"
  },
  {
    value: "finance",
    text: "Finance Agreement"
  },
  {
    value: "generic",
    text: "Generic Agreement"
  }
];
