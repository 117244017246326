import React, { Component, Fragment } from "react";
import { Form, Header, Message, Segment, Grid } from "semantic-ui-react";
import months from "fixtures/months";

const ownershipData = {
  resort_name: "",
  resort_city: "",
  resort_state: "",
  owner_number: "",
  earned_points_annually: "",
  curr_week_status: "",
  next_week_status: "",
  fees_paid_through_month: "",
  fees_paid_through_year: "",
  fees_up_to_date: "",
  taxes_paid_through_month: "",
  taxes_paid_through_year: "",
  annual_taxes: ""
};

class Ownership extends Component {
  state = { disableRemoveButton: false };

  componentDidUpdate = (prevProps, prevState) => {
    const { context } = this.props;
    const { data, functions } = context;
    const { setData } = functions;

    if (data.ownership.length === 0) {
      const newData = [{ ...ownershipData }];
      setData("ownership", newData);
    }

    if (prevProps.context.data.ownership !== data.ownership) {
      this.handleDisableRemoveButton();
    }
  };

  createFormInput = (index, el, source, props = {}) => {
    const {
      context: {
        data,
        functions: { setData }
      }
    } = this.props;
    return React.cloneElement(el, {
      value: data.ownership[index][source] || "",
      onChange: (e, { value }) => {
        if (!props.readOnly) {
          const newData = data.ownership.map((r, i) => {
            return i === index ? { ...r, [source]: value } : r;
          });
          setData("ownership", newData);
        }
      },
      ...props
    });
  };

  handleOwnershipAddition = () => {
    const { context } = this.props;
    const {
      data,
      functions: { setData }
    } = context;
    const newData = data.ownership.concat({ ...ownershipData });
    setData("ownership", newData);
  };

  handleOwnershipRemoval = () => {
    const { context } = this.props;
    const {
      data,
      functions: { setData }
    } = context;
    const newData = data.ownership.slice(0, -1);
    setData("ownership", newData);
  };

  handleDisableRemoveButton = () => {
    const {
      context: { data }
    } = this.props;

    if (data.ownership.length > 1) {
      return this.setState({ disableRemoveButton: false });
    }
    return this.setState({ disableRemoveButton: true });
  };

  render() {
    const { disableRemoveButton } = this.state;
    const {
      context: {
        agreement,
        data,
        config,
        functions: { createInput, shouldFieldBeDisabled, shouldFieldBeReadOnly }
      }
    } = this.props;
    const readOnly = shouldFieldBeReadOnly("ownership");
    const disabled = shouldFieldBeDisabled("ownership", readOnly);

    const defaultOption = { value: "", text: "Select one..." };
    let monthOptions = months.map((m, index) => ({
      key: index,
      value: m.month,
      text: m.text
    }));
    monthOptions.unshift(defaultOption);

    return (
      <Fragment>
        {data.ownership &&
          data.ownership.map((item, i) => (
            <Segment.Group key={i}>
              <Segment color={config.color}>
                <Header as="h3" content="Miscellaneous" />
                {this.createFormInput(i, <Form.Input />, "resort_name", {
                  name: "resort_name",
                  label: "Name of resort",
                  placeholder: "Enter the name of the owners resort",
                  required: true,
                  autoComplete: "off",
                  disabled,
                  readOnly,
                  fluid: true
                })}
                <Form.Group widths="equal">
                  {this.createFormInput(i, <Form.Input />, "resort_city", {
                    name: "resort_city",
                    label: "Resort city",
                    placeholder: "Enter the resorts city",
                    required: false,
                    autoComplete: "off",
                    disabled,
                    readOnly,
                    fluid: true
                  })}
                  {this.createFormInput(i, <Form.Input />, "resort_state", {
                    name: "resort_state",
                    label: "Resort state",
                    placeholder: "Enter the resorts state",
                    required: false,
                    autoComplete: "off",
                    disabled,
                    readOnly,
                    fluid: true
                  })}
                </Form.Group>
                <Form.Group widths="equal">
                  {this.createFormInput(i, <Form.Input />, "owner_number", {
                    name: "owner_number",
                    label: "Owner number",
                    placeholder: "Owner number",
                    autoComplete: "off",
                    required: false
                  })}
                  {this.createFormInput(
                    i,
                    <Form.Input />,
                    "earned_points_annually",
                    {
                      type: "number",
                      name: "earned_points_annually",
                      label: "Earned points per year",
                      placeholder: "0",
                      autoComplete: "off",
                      required: false
                    }
                  )}
                </Form.Group>
              </Segment>
              <Segment>
                <Header as="h3" content="Week Status" />
                <Form.Group widths="equal">
                  {this.createFormInput(
                    i,
                    <Form.Dropdown />,
                    "curr_week_status",
                    {
                      name: "curr_week_status",
                      placeholder: "Select one...",
                      label: `${agreement.year} week status`,
                      autoComplete: "off",
                      required: false,
                      selection: true,
                      options: weekOptions
                    }
                  )}
                  {this.createFormInput(
                    i,
                    <Form.Dropdown />,
                    "next_week_status",
                    {
                      name: "next_week_status",
                      placeholder: "Select one...",
                      label: `${Number(agreement.year) + 1} week status`,
                      autoComplete: "off",
                      required: false,
                      selection: true,
                      options: weekOptions
                    }
                  )}
                </Form.Group>
              </Segment>
              <Segment>
                <Header as="h3" content="Tax/Fee Status" />
                <Grid columns={2} stackable>
                  <Grid.Column>
                    <Form.Group widths="equal">
                      {this.createFormInput(
                        i,
                        <Form.Dropdown />,
                        "fees_paid_through_month",
                        {
                          name: "fees_paid_through_month",
                          placeholder: "Select one...",
                          label: "Maintenance paid until..",
                          autoComplete: "off",
                          required: false,
                          fluid: true,
                          selection: true,
                          options: monthOptions
                        }
                      )}
                      {this.createFormInput(
                        i,
                        <Form.Input />,
                        "fees_paid_through_year",
                        {
                          name: "fees_paid_through_year",
                          placeholder: "Year",
                          label: <label>&nbsp;</label>,
                          autoComplete: "off",
                          required: false,
                          fluid: true,
                          type: "number"
                        }
                      )}
                    </Form.Group>
                    {this.createFormInput(
                      i,
                      <Form.Dropdown />,
                      "fees_up_to_date",
                      {
                        name: "fees_up_to_date",
                        placeholder: "Select one...",
                        label: "Fees up to date",
                        autoComplete: "off",
                        required: true,
                        selection: true,
                        options: [
                          { value: "", text: "Select one..." },
                          { value: "yes", text: "Yes" },
                          { value: "no", text: "No" }
                        ]
                      }
                    )}
                  </Grid.Column>
                  <Grid.Column>
                    <Form.Group widths="equal">
                      {this.createFormInput(
                        i,
                        <Form.Dropdown />,
                        "taxes_paid_through_month",
                        {
                          name: "taxes_paid_through_month",
                          placeholder: "Select one...",
                          label: "Taxes paid until..",
                          autoComplete: "off",
                          required: false,
                          fluid: true,
                          selection: true,
                          options: months.map(m => ({
                            text: m.text,
                            value: m.month
                          }))
                        }
                      )}
                      {this.createFormInput(
                        i,
                        <Form.Input />,
                        "taxes_paid_through_year",
                        {
                          name: "taxes_paid_through_year",
                          placeholder: "Year",
                          label: <label>&nbsp;</label>,
                          autoComplete: "off",
                          required: false,
                          fluid: true,
                          type: "number"
                        }
                      )}
                    </Form.Group>
                    {this.createFormInput(i, <Form.Input />, "annual_taxes", {
                      name: "annual_taxes",
                      placeholder: "Annual Taxes",
                      label: "Annual Taxes (if billed separate)",
                      autoComplete: "off",
                      required: false
                    })}
                  </Grid.Column>
                </Grid>
              </Segment>
            </Segment.Group>
          ))}

        <Segment basic>
          <Form.Group>
            <Form.Button
              type="button"
              content="Add another"
              color={config.color}
              onClick={this.handleOwnershipAddition}
              disabled={readOnly || disabled}
            />
            <Form.Button
              type="button"
              content="Remove one"
              onClick={this.handleOwnershipRemoval}
              disabled={readOnly || disabled || disableRemoveButton}
            />
          </Form.Group>
        </Segment>

        <Header dividing as="h3" content="Special Instructions / Addendum" />
        {createInput(<Form.TextArea />, "notes", {
          name: "notes",
          placeholder: "Notes...",
          autoComplete: "off",
          required: false,
          fluid: "true"
        })}

        <Message
          header="Important Note"
          content="If the owner chooses to keep or use any weeks, points or credits for current year, they will be responsible for all fees associated with them, unless specifically noted in the Special Instructions and Addendum."
        />
      </Fragment>
    );
  }
}

export default Ownership;

const weekOptions = [
  { value: "", text: "Select one..." },
  { value: "lakeside", text: "Lakeside Closing LLC" },
  { value: "banked", text: "Banked (RCI or II)" },
  { value: "owner", text: "Owner Reservation" }
];
