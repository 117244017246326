import React, { Component } from "react";
import PropTypes from "prop-types";
import { Segment, Header, List, Button } from "semantic-ui-react";
import { connect } from "react-redux";
import { reloadBooking } from "redux/BookingPage/actions";
import AjaxFn from "services/AjaxFn";

class Agents extends Component {
  static propTypes = {
    reload: PropTypes.func.isRequired,
    data: PropTypes.object.isRequired
  };

  state = {
    loading: false,
    cancel: null
  };

  componentWillUnmount() {
    if (typeof this.state.cancel === "function") {
      this.state.cancel();
    }
  }

  handleClick = () => {
    const msg =
      "Please confirm to mark this as rebooked. This can not be undone";

    if (window.confirm(msg)) {
      this.setState({
        loading: true,
        cancel: AjaxFn({
          url: "/apis/portal/bookings",
          data: {
            action: "rebook",
            data: {
              ID: this.props.data.id
            }
          },
          success: () => {
            window.toastr.success();
            // this.setState({ loading: false, cancel: false });
            this.props.reload();
          },
          failure: error => {
            this.setState({ loading: false, cancel: false });
            window.toastr.error(error);
          }
        })
      });
    }
  };

  render() {
    const { data, classes } = this.props;
    const { booking_agent, rebooking_agent } = data;
    return (
      <Segment>
        <Header as="h2">
          <Header.Content className={classes.headerContent}>
            Booking Agents
          </Header.Content>
        </Header>
        <List className={classes.list}>
          <List.Item>
            <List.Icon name="book" />
            <List.Content>
              <List.Header>Booking Agent</List.Header>
              <List.Description>
                {booking_agent.id
                  ? booking_agent.firstname + " " + booking_agent.lastname
                  : "Unknown"}
              </List.Description>
            </List.Content>
          </List.Item>
          <List.Item>
            <List.Icon name="redo" />
            <List.Content>
              <List.Header>Rebooking Agent</List.Header>
              <List.Description>
                {rebooking_agent.id
                  ? rebooking_agent.firstname + " " + rebooking_agent.lastname
                  : "Not Rebooked"}
              </List.Description>
            </List.Content>
          </List.Item>
        </List>
        <br />
        {data._actions.actions.mark_rebooked ? (
          <div>
            {!rebooking_agent.id && (
              <Button
                onClick={this.handleClick}
                loading={this.state.loading}
                disabled={this.state.loading}
                content="Rebook"
                basic
                primary
              />
            )}
          </div>
        ) : (
          <p>You are not able to rebook this right now.</p>
        )}
      </Segment>
    );
  }
}

const mapDispatchToProps = {
  reload: reloadBooking
};

export default connect(
  undefined,
  mapDispatchToProps
)(Agents);
