import React from "react";
import { Table } from "semantic-ui-react";
import { connect } from "react-redux";
import { loadBookings } from "redux/EOQReport/actions";
import injectSheet from "react-jss";
import WithState from "components/WithState";
import Header from "./components/Header";
import Pages from "./components/Pages";
import Summary from "./components/Summary";
import IDsCheckBox from "./components/IDsCheckBox";
import Member from "./components/Member";
import Membership from "./components/Membership";
import Bookings from "./components/Bookings";
import Total from "./components/Total";
class EOQTable extends React.Component {
  componentDidMount() {
    this.props.load();
  }
  render() {
    const { loading, error, rows, bookings } = this.props;
    return (
      <WithState loading={loading} error={error}>
        <Table striped celled basic="very">
          <Header />
          <Table.Body>
            {rows.map((row, key) => {
              const row_bookings = row[2].map(id => {
                return bookings.find(booking => booking.id === id);
              });
              const props = { row, row_bookings };
              return (
                <Table.Row key={key}>
                  <Table.Cell textAlign={"center"} width={1}>
                    <IDsCheckBox IDs={row[2]} />
                  </Table.Cell>
                  <Member {...props} />
                  <Membership {...props} />
                  <Bookings {...props} />
                  <Total {...props} />
                </Table.Row>
              );
            })}
          </Table.Body>
        </Table>
        <Pages />
        <br />
        <Summary />
      </WithState>
    );
  }
}

const styles = {
  // ...
};

const mapStateToProps = state => ({
  loading: state.EOQReport.bookings.loading,
  error: state.EOQReport.bookings.error,
  rows: state.EOQReport.bookings.data.rows_paginated,
  bookings: state.EOQReport.bookings.data.bookings
  // ...
});
const mapDispatchToProps = dispatch => ({
  load: () => dispatch(loadBookings())
  // ...
});

const connected = connect(
  mapStateToProps,
  mapDispatchToProps
)(EOQTable);
export default injectSheet(styles)(connected);
