import React from "react";
import { Button, Icon } from "semantic-ui-react";
export default ContentManager => ({
  tableName: "pages",
  url: "/apis/portal/pages",
  data: {
    action: "read"
  },
  tableData: {
    show: 25,
    order: [[1, "asc"], [2, "asc"]],
    paginationPosition: "bottom",
    showFooter: false,
    menu: () => [
      "[search]",
      "[show]",
      "[refresh]",
      <Button
        icon
        color={"blue"}
        title={"New"}
        onClick={ContentManager.functions.openWizard}
        children={<Icon name={"plus"} />}
      />
    ],
    props: {
      color: "blue"
    },
    cols: [
      {
        name: "",
        src: "id",
        render: (src, row) => (
          <a onClick={() => ContentManager.functions.openEditor(row)}>
            <Icon name={"external"} />
          </a>
        ),
        orderable: false,
        searchable: false,
        props: {
          textAlign: "center",
          selectable: true,
          width: 1
        }
      },
      {
        name: "Group",
        src: ({ page_group: { name = "None" } }) => name
      },
      {
        name: "Name",
        src: "name"
      },
      {
        name: "URL",
        src: "uri"
      },
      {
        name: "Icon",
        src: "icon",
        render: src =>
          src ? (
            <React.Fragment>
              <Icon name={src} style={{ marginRight: ".75rem" }} />
              <span style={{ textTransform: "uppercase" }}>{src}</span>
            </React.Fragment>
          ) : (
            "No icon"
          )
      }
    ]
  }
});
