import React from "react";
import { FiltersModal } from "components/PortalTable/parts";
import { Form, Dropdown } from "semantic-ui-react";

export default () => (
  <FiltersModal
    namespace={"banks"}
    filters={{
      active: true
    }}
    buildFilters={applied => {
      const functions = [];
      if (applied.active !== "any") {
        functions.push(row => row.active === applied.active);
      }
      return functions;
    }}
    children={(applied, update) => (
      <Form>
        <Form.Field>
          <label>Active</label>
          <Dropdown
            selection
            options={[
              { value: false, text: "Do not show" },
              { value: true, text: "Show Only " },
              { value: "any", text: "Any " }
            ]}
            value={applied.active}
            onChange={(e, { value }) => update("active", value)}
          />
        </Form.Field>
      </Form>
    )}
  />
);
