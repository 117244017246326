import "core-js";

import React from "react";
import ReactDOM from "react-dom";
import App from "App.js";
import store from "redux/store.js";
import { Provider } from "react-redux";

import "styles.css";
import "services/sentry";
import "services/toastify";
import "react-toastify/dist/ReactToastify.min.css";

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById("portal")
);
