import React from "react";
import BookingTable from "../BookingTable";
import { chunk } from "utils/arrays";
import Container from "../Container";
/**
 *	@prop classes
 *	@prop check
 *  @prop bookings
 */
export default class FirstPage extends React.Component {
  render() {
    const { bookings, classes, check } = this.props;
    const chunks = chunk(bookings, 4);

    return chunks.map((bookings, key) => (
      <Container header={false} key={key} classes={classes} check={check}>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <p>
            Bookings Summary (page {key + 1}/{chunks.length})
          </p>
          <p>Check #{check.number.toString().padStart(7, "0")}</p>
        </div>
        <BookingTable bookings={bookings} />
      </Container>
    ));
  }
}
