import React from "react";
import ContextBar from "components/ContextBar";
import { Breadcrumb } from "semantic-ui-react";
import { Link } from "react-router-dom";
import { formatMJY } from "utils/dates";

export const LoadedBreadcrumb = ({ data }) => {
  const guest = `${data.guest.firstname} ${data.guest.lastname}`;
  const resort = `${data.unit.resort.resort_company.name} ${
    data.unit.resort.name
  } ${data.unit.name}`;
  const checkin = formatMJY(data.check_in);
  return (
    <ContextBar>
      <Breadcrumb>
        <Breadcrumb.Section>
          <Link to={"/"}>Portal</Link>
        </Breadcrumb.Section>
        <Breadcrumb.Divider>/</Breadcrumb.Divider>
        <Breadcrumb.Section>
          <Link to={"/browse/bookings"}>Bookings</Link>
        </Breadcrumb.Section>
        <Breadcrumb.Divider>/</Breadcrumb.Divider>
        <Breadcrumb.Section active>
          {`${checkin} - ${guest} at ${resort}`}
        </Breadcrumb.Section>
      </Breadcrumb>
    </ContextBar>
  );
};

export default () => (
  <ContextBar>
    <Breadcrumb>
      <Breadcrumb.Section>
        <Link to={"/"}>Portal</Link>
      </Breadcrumb.Section>
      <Breadcrumb.Divider>/</Breadcrumb.Divider>
      <Breadcrumb.Section>
        <Link to={"/browse/bookings"}>Bookings</Link>
      </Breadcrumb.Section>
      <Breadcrumb.Divider>/</Breadcrumb.Divider>
      <Breadcrumb.Section active>Loading...</Breadcrumb.Section>
    </Breadcrumb>
  </ContextBar>
);
