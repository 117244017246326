import React from "react";
import styled from "styled-components";

import Navbar from "./components/Navbar";
import Breadcrumb from "./components/Breadcrumb";
import Content from "./components/Content";
import FocusBar from "../FocusBar";

import { fontColor } from "theme";

/**
 * Global layout component
 * @todo rename to Layout once complete and approved
 */
const NewLayout = ({ children, noBar = false }) => {
  return (
    <Wrapper>
      <Navbar />
      <Breadcrumb />
      <div id="focus-bar" />
      {noBar && <FocusBar expandable={false} expanded={false} />}
      <Content children={children} />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: grid;
  grid-template-areas:
    "focusbar navbar"
    "focusbar breadcrumb"
    "focusbar content";
  grid-template-columns: auto 1fr;
  grid-template-rows: 50px 34px auto;
  height: 100%;

  #focus-bar {
    grid-area: focusbar;
  }

  /* setting font-family for elements in Layout */
  h1,
  h2,
  h3,
  h4,
  h5,
  p,
  button,
  ul,
  ol,
  li,
  nav,
  span {
    font-family: "Open Sans", Arial, Helvetica, sans-serif;
    color: ${fontColor};
  }

  a {
    font-family: "Open Sans", Arial, Helvetica, sans-serif;
    color: var(--portalBlue);
    font-weight: 600;
  }
`;

export default NewLayout;
