import React from "react";
import { connect } from "react-redux";
import injectSheet from "react-jss";
import { addCommas } from "utils/numbers";
/**
 *	@prop namespace
 */
class Info extends React.Component {
  render() {
    const { namespace, tables } = this.props;
    const { finalData, orderedData, requestData } = tables[namespace];
    const page = finalData.length;
    const pages = orderedData.length;
    const request = requestData.length;
    return request > 0 ? (
      <p>
        {"Showing "}
        <b>{addCommas(page)}</b>
        {" out of "}
        <b>{addCommas(pages)}</b>
        {` visible ${pages > 1 ? "results" : "result"}, `}
        <b>{addCommas(request)}</b>
        {` total ${request > 1 ? "results" : "result"} found`}
      </p>
    ) : (
      <p>No results found</p>
    );
  }
}

const styles = {
  // ...
};

const mapStateToProps = state => ({
  tables: state.PortalTables
  // ...
});
const mapDispatchToProps = dispatch => ({
  // ...
});

const connected = connect(
  mapStateToProps,
  mapDispatchToProps
)(Info);
export default injectSheet(styles)(connected);
