import React from "react";
import { connect } from "react-redux";
import injectSheet from "react-jss";
import { Table } from "semantic-ui-react";
import { TableCell } from "components/PortalTable/parts";
/**
 * @prop row
 */
class TableRow extends React.Component {
  render() {
    return (
      <Table.Row
        onClick={() => {
          window.location.href = `/browse/bookings/${this.props.row.id}`;
        }}
        onContextMenu={() => {
          window.open(`/browse/bookings/${this.props.row.id}`);
        }}
        className={this.props.classes.row}
        children={this.props.cols
          .filter(col => col.visible !== false)
          .map((col, index) => (
            <TableCell col={col} key={index} row={this.props.row} />
          ))}
      />
    );
  }
}

const styles = {
  row: {
    cursor: "pointer"
  }
};

const mapStateToProps = state => ({
  cols: state.PortalTables["BookingList"].cols
  // ...
});
const mapDispatchToProps = dispatch => ({
  // ...
});

const connected = connect(
  mapStateToProps,
  mapDispatchToProps
)(TableRow);
export default injectSheet(styles)(connected);
