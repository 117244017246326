import React from "react";
import { connect } from "react-redux";
import injectSheet from "react-jss";
import WithState from "components/WithState";
import { Table } from "semantic-ui-react";
import { TableHeader, Pagination, Info } from "components/PortalTable/parts";
import TableBody from "./components/TableBody";
class BookingTable extends React.Component {
  render() {
    return (
      <WithState loading={this.props.loading} error={this.props.error}>
        <Table {...this.props.tableProps}>
          <TableHeader namespace={"BookingList"} />
          <Table.Body>
            <TableBody namespace={"BookingList"} />
          </Table.Body>
        </Table>
        <Pagination namespace={"BookingList"} />
        <br />
        <Info namespace={"BookingList"} />
      </WithState>
    );
  }
}

const styles = {
  // ...
};

const mapStateToProps = state => ({
  loading: state.PortalTables["BookingList"].loading,
  error: state.PortalTables["BookingList"].error,
  tableProps: state.PortalTables["BookingList"].tableProps
  // ...
});
const mapDispatchToProps = dispatch => ({
  // ...
});

const connected = connect(
  mapStateToProps,
  mapDispatchToProps
)(BookingTable);
export default injectSheet(styles)(connected);
