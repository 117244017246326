import React, { Fragment, Component } from "react";
import ReactDOM from "react-dom";
import PropTypes from "prop-types";
import enhanceWithClickOutside from "react-click-outside";
import styled from "styled-components";

import BackgroundComponent from "../BackgroundComponent";
import FocusBar from "../../";

class DrawerPortalComponent extends Component {
  static propTypes = {
    autoclose: PropTypes.bool.isRequired,
    backbutton: PropTypes.bool.isRequired,
    children: PropTypes.any.isRequired,
    expanded: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    node: PropTypes.any.isRequired,
    open: PropTypes.bool.isRequired,
    overlay: PropTypes.bool.isRequired
  };

  handleClickOutside() {
    if (this.props.autoclose) {
      this.props.handleClose();
    }
  }

  render() {
    const {
      backbutton,
      children,
      expanded,
      handleClose,
      node,
      open,
      overlay
    } = this.props;

    const containerProps = {
      overlay: overlay ? "true" : undefined,
      expanded: expanded ? "true" : undefined
    };

    if (open && node) {
      return ReactDOM.createPortal(
        <DrawerContainer {...containerProps}>
          <BackgroundComponent drawer>
            {backbutton && (
              <FocusBar.Actions>
                <br />
                <FocusBar.Button
                  icon="fas fa-chevron-left"
                  content="Go Back"
                  onClick={handleClose}
                />
                <FocusBar.Divider />
              </FocusBar.Actions>
            )}
            {React.Children.map(children, (child, key) => (
              <Fragment key={key}>
                {key !== 0 && <FocusBar.Divider />}
                {React.cloneElement(child)}
              </Fragment>
            ))}
          </BackgroundComponent>
        </DrawerContainer>,
        node
      );
    }

    return null;
  }
}

export default enhanceWithClickOutside(DrawerPortalComponent);

const DrawerContainer = styled.div`
  bottom: 0;
  left: ${props => {
    if (props.overlay) {
      return "0";
    } else {
      if (props.expanded) {
        return "288px";
      } else {
        return "42px";
      }
    }
  }};
  /* left: ${props => (props.expanded ? "288px" : "42px")}; */
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1000;
`;
