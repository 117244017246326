export const portalBlue = "#2185D0";
export const portalBlueDark = "#1A68A3";

export const portalBlack = "#1B1C1D";
export const portalBlackLighter = "#28292A";
export const portalBlackLightest = "#343637";

export const portalGrey = "#E0E1E2";
export const portalGreyDark = "#D2D4D5";

export const offWhite = "#F2F2F3";
export const offWhiteDark = "#E5E5E6";

export const breadcrumbDivider = "#B2B5B8";

export const danger = "#EE4545";
