import React, { Component, Fragment } from "react";
import { InstanceTypes } from "../../types";
import { Button, Modal, Icon } from "semantic-ui-react";
import moment from "moment";
import DateForm from "./components/DateForm";
import TimeForm from "./components/TimeForm";
import Input from "./components/Input";
/**
 *	@prop config (see MyCal.propTypes.config.calendars)
 */
export default class Instance extends Component {
  static propTypes = InstanceTypes;
  state = {
    open: this.props.config.open || false,
    // the "real" value
    // if no value is supplied, default to now
    truevalue: this.props.config.value || moment(),
    // the value that's used until the user hits apply
    // if no value is supplied, default to now
    value: this.props.config.value || moment(),
    displayMonth: "",
    displayYear: ""
  };
  componentWillReceiveProps(props) {
    if (props.config.value !== this.state.value) {
      this.setState({
        value: props.config.value,
        truevalue: props.config.value
      });
    }
  }
  /**
   *	Call a function with an argument of the current parent's state of calendars
   *  @param {Function} fn
   *  @param {Mixed} rest
   *  @return mixed
   */
  _callWithArguments = (fn, ...rest) => fn(this.props.getCalendars(), ...rest);
  /**
   * Determine the input to render
   * @return {ReactElement}
   */
  _renderInput = () => {
    const { config } = this.props;
    const { truevalue } = this.state;
    if (config.getInput) {
      return config.getInput(this);
    } else {
      return (
        <Input
          config={config}
          value={truevalue}
          setOpen={this.setOpen}
          setDate={
            config.type === "datetime"
              ? this.setDateTime
              : config.type === "time"
                ? this.setTime
                : this.setDate
          }
        />
      );
    }
  };
  /**
   * This gets and then applies the "real" value to aply, and then triggers the onchange
   * function with that value, then closes the picker.
   */
  _applyValue = () => {
    const { value, truevalue } = this.state;
    const { config } = this.props;
    if (value !== truevalue) {
      const actualvalue = this._getActualValueToApply();
      const callback = () => {
        if (config.onChange) {
          this._callWithArguments(config.onChange, actualvalue);
        }
        this.setClosed();
      };
      const newstate = {
        truevalue: actualvalue,
        value: actualvalue
      };
      this.setState(newstate, callback);
    }
  };
  /**
   * This returns the "real" value to apply in applyValue().
   * This automatically corrects for time periods too far past min/max date.
   * @return {Moment}
   */
  _getActualValueToApply = () => {
    const { value } = this.state;
    const { minDate, maxDate } = this.props.config;
    if (minDate && value < minDate) {
      return minDate;
    }
    if (maxDate && value > maxDate) {
      return maxDate;
    }
    return value;
  };

  /**
   *	Open this calendar instance
   */
  setOpen = () => {
    if (!this.state.open) {
      const newState = {
        open: true
      };
      const callback = () => {
        if (this.props.config.onOpen) {
          this._callWithArguments(this.props.config.onOpen);
        }
      };
      this.setState(newState, callback);
    }
  };
  /**
   *	Close this calendar instance
   */
  setClosed = () => {
    if (this.state.open) {
      const newState = {
        open: false,
        // any unsaved changes must be reset
        value: this.state.truevalue
      };
      const callback = () => {
        if (this.props.config.onClose) {
          this._callWithArguments(this.props.config.onClose);
        }
      };
      this.setState(newState, callback);
    }
  };
  /**
   * @param {Moment} newvalue  Sets time
   * @param {Bool} apply if true, applyValue() will be called
   */
  setTime = (newvalue, apply) => {
    const newtime = moment(this.state.value)
      .hour(newvalue.hour())
      .minute(newvalue.minute())
      .second(newvalue.second());
    this.setDateTime(newtime, apply);
  };
  /**
   * @param {Moment} newvalue Sets date
   * @param {Bool} apply if true, applyValue() will be called
   */
  setDate = (newvalue, apply) => {
    const newtime = moment(this.state.value)
      .year(newvalue.year())
      .month(newvalue.month())
      .date(newvalue.date());
    this.setDateTime(newtime, apply);
  };
  /**
   * @param {Moment} newvalue Sets datetime
   * @param {Bool} apply if true, applyValue() will be called
   */
  setDateTime = (newvalue, apply) => {
    const newState = {
      value: newvalue,
      displayMonth: null,
      displayYear: null
    };
    this.setState(newState, () => {
      if (apply) {
        this._applyValue();
      }
    });
  };
  /**
   * determine if a moment is valid given the minDate and maxDate
   * @param {Moment} someMoment
   * @return {Bool}
   */
  isValid = someMoment => {
    const { minDate, maxDate } = this.props.config;
    if (minDate && minDate > someMoment) {
      return false;
    }
    if (maxDate && maxDate < someMoment) {
      return false;
    }
    return true;
  };

  /**
   * 	Render
   */
  render() {
    const { value, truevalue, open } = this.state;
    const trigger = this._renderInput();
    const { type } = this.props.config;
    const DateSection = (
      <DateForm
        config={this.props.config}
        value={this.state.value}
        displayMonth={this.state.displayMonth}
        displayYear={this.state.displayYear}
        setMonth={month => this.setState({ displayMonth: month })}
        setYear={year => this.setState({ displayYear: year })}
        setDate={this.setDate}
      />
    );
    const TimeSection = (
      <TimeForm
        config={this.props.config}
        value={this.state.value}
        setTime={this.setTime}
      />
    );

    return (
      <Modal trigger={trigger} open={open} size="mini">
        {this.props.config.hint && (
          <Modal.Header>{this.props.config.hint}</Modal.Header>
        )}
        {open && (
          <Fragment>
            <Modal.Content>
              {(() => {
                switch (type) {
                  case "date":
                    return DateSection;
                  case "time":
                    return TimeSection;
                  default:
                    return (
                      <Fragment>
                        {DateSection}
                        <br />
                        {TimeSection}
                        <br />
                      </Fragment>
                    );
                }
              })()}
            </Modal.Content>
            <Modal.Actions>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <Button
                  onClick={() => this.setDateTime(moment())}
                  disabled={!this.isValid(moment())}
                  content="Use Now"
                />
                <div style={{ textAlign: "right" }}>
                  <Button onClick={this.setClosed} content="Close" />
                  <Button
                    primary
                    onClick={this._applyValue}
                    disabled={value === truevalue}
                    content="Apply"
                  />
                </div>
              </div>
            </Modal.Actions>
          </Fragment>
        )}
      </Modal>
    );
  }
}
