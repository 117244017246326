import AjaxFn from "services/AjaxFn";
export const setGuestsLoading = () => ({ type: "BOOKWIZ_GUESTS_LOADING" });
export const setGuestsError = data => ({
  type: "BOOKWIZ_GUESTS_ERROR",
  data
});
export const setGuestsData = data => ({ type: "BOOKWIZ_GUESTS_DATA", data });
export const loadGuests = () => dispatch => {
  dispatch(setGuestsLoading());
  AjaxFn({
    url: "/apis/portal/guests/index",
    data: {
      action: "read_for_booking_wizard"
    },
    success(message) {
      dispatch(setGuestsData(message));
    },
    failure(error) {
      dispatch(setGuestsError(error));
    }
  });
};

export const setUnitsLoading = () => ({ type: "BOOKWIZ_UNITS_LOADING" });
export const setUnitsError = data => ({
  type: "BOOKWIZ_UNITS_ERROR",
  data
});
export const setUnitsData = data => ({ type: "BOOKWIZ_UNITS_DATA", data });
export const loadUnits = () => dispatch => {
  dispatch(setUnitsLoading());
  AjaxFn({
    url: "/apis/portal/units",
    data: {
      action: "read_for_dropdown"
    },
    success(message) {
      dispatch(setUnitsData(message));
    },
    failure(error) {
      dispatch(setUnitsError(error));
    }
  });
};
