import React from "react";
import { Container, Header } from "semantic-ui-react";
import Layout from "components/Layout";
import ContentManager from "components/ContentManager";
import { Ajax } from "services/Ajax";
import List from "./components/List";
import Editor from "./components/Editor";
import Wizard from "./components/Wizard";
import ViewingResortsModal from "./components/ViewingResortsModal";
import injectSheet from "react-jss";
import { connect } from "react-redux";
import Breadcrumb from "./components/Breadcrumb";
const styles = {};
class ResortCompanies extends React.Component {
  state = {
    viewingResorts: false
  };
  viewResorts = (viewingResorts = false) => this.setState({ viewingResorts });
  getConfig = ContentManager => {
    return {
      List: List({ ContentManager, viewResorts: this.viewResorts }),
      Editor: {
        JSX: (CM, E) => <Editor ContentManager={CM} Editor={E} />,
        AJAX: {
          instance: new Ajax({ url: "/apis/portal/resortcompanies" }),
          onSuccess: () => window.toastr.success(),
          data: ({ id }, data) => {
            return {
              action: "update",
              data: {
                ID: id,
                ...data
              }
            };
          }
        }
      },
      Wizard: {
        JSX: (CM, W) => <Wizard ContentManager={CM} Wizard={W} />,
        AJAX: {
          instance: new Ajax({ url: "/apis/portal/resortcompanies" }),
          onSuccess: ({ message }) => {
            window.toastr.success();
          },
          data: data => {
            return {
              action: "create",
              data
            };
          }
        }
      }
    };
  };
  /**
   * 	Render fn
   */
  render() {
    return (
      <Layout noBar>
        <Breadcrumb />
        <Container style={{ paddingBottom: "4rem" }}>
          <Header as={"h1"} dividing style={{ marginTop: "2rem" }}>
            Resort Companies
            <div className={"sub header"}>
              Below are all of the resort companies registered in the database.
            </div>
          </Header>
          <ContentManager config={this.getConfig}>
            {({ components: { List, Editor, Wizard } }) => (
              <React.Fragment>
                {List}
                {Editor}
                {Wizard}
              </React.Fragment>
            )}
          </ContentManager>
        </Container>
        <ViewingResortsModal
          viewingResorts={this.state.viewingResorts}
          viewResorts={this.viewResorts}
        />
      </Layout>
    );
  }
}
const styled = injectSheet(styles)(ResortCompanies);
const mapStateToProps = () => ({});
export default connect(mapStateToProps)(styled);
