import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { ToastContainer } from "react-toastify";
import { ThemeProvider, createGlobalStyle } from "styled-components";
import theme from "styled-theming";

import { Container, Message, Button } from "semantic-ui-react";

import Alertbar from "components/Alertbar";
import BackgroundTasks from "components/BackgroundTasks";
import { setConnectedUser, loadPunches } from "redux/User/actions.js";
import Scenes from "scenes";
import LoadingPage from "scenes/Loading";
import AjaxFn from "services/AjaxFn";

const App = props => {
  const [state, setState] = useState({
    loading: true,
    error: false,
    intialized: false
  });

  /**
   * Loads session data on mount
   */
  useEffect(() => {
    if (!state.initialized) {
      setState({
        loading: true,
        error: false,
        initialized: true
      });
      AjaxFn({
        url: "/apis/portal/sessions?action=get",
        success: message => {
          props.setConnectedUser(message);
          if (message) props.loadPunches();
          setState({
            loading: false,
            error: false,
            initialized: true
          });
        },
        failure: error => {
          setState({
            loading: false,
            initialized: true,
            error: typeof error === "object" ? JSON.stringify(error) : error
          });
        }
      });
    }
  });

  const backgroundColor = theme("mode", {
    light: "#FFF",
    dark: "var(--portalBlackLightest)"
  });

  const GlobalThemeStyle = createGlobalStyle`
    :root {
      --portalBlue: #2185D0;
      --portalBlack: #1B1C1D;
      --portalBlackLighter: #28292A;
      --portalBlackLightest: #343637;
      --portalGrey: #E0E1E2;
      --offWhite: #F2F2F3;

      --breadcrumbDivider: #B2B5B8;
      --danger: #EE4545;
    }

    body {
      background-color: ${backgroundColor};
    }

    @media print {
      #navbar,
      #breadcrumb,
      #focus-bar,
      #footer {
        display: none !important;
      }

      #content {
        overflow-y: visible;
      }
    }
  `;

  const {
    setConnectedUser,
    Alertbar: { alerts },
    themeMode
  } = props;

  if (state.loading) {
    return <LoadingPage />;
  }

  if (state.error) {
    return (
      <Container>
        <br />
        <br />
        <Message error>
          <Message.Header>Something went wrong</Message.Header>
          <p>
            <b>
              Something went wrong loading the application. Try refreshing the
              page.
            </b>
          </p>
          <p>
            <b>Error:</b> {state.error}
          </p>
          <Button onClick={() => window.location.reload()} content="Reload" />
        </Message>
      </Container>
    );
  }

  return (
    <ThemeProvider theme={{ mode: themeMode }}>
      <>
        <GlobalThemeStyle />
        <ToastContainer />
        <BackgroundTasks />
        {alerts.length > 0 && <Alertbar />}
        <Scenes />
      </>
    </ThemeProvider>
  );
};

App.propTypes = {
  setConnectedUser: PropTypes.func.isRequired,
  loadPunches: PropTypes.func.isRequired,
  Alertbar: PropTypes.object.isRequired,
  themeMode: PropTypes.string.isRequired
};

const mapStateToProps = state => ({
  Alertbar: state.Alertbar,
  themeMode: state.Storage.theme
});

const mapDispatchToProps = {
  setConnectedUser,
  loadPunches
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(App);
