import React from "react";
import { Button, Icon } from "semantic-ui-react";
import LinkConf from "components/LinkWithConfirmation";

export default (ContentManager, classes, filters) => ({
  tableName: "accounts",
  url: "/apis/portal/accounts",
  data: {
    action: "read"
  },
  tableData: {
    filters,
    show: 10,
    order: [[1, "asc"]],
    paginationPosition: "bottom",
    showFooter: false,
    menu: () => [
      "[search]",
      "[show]",
      "[refresh]",
      <LinkConf
        to={"/wizards/accounts"}
        as={<Button />}
        icon
        color={"blue"}
        title={"New"}
        children={<Icon name={"plus"} />}
      />
    ],
    cols: [
      {
        name: "",
        src: "id",
        render: (src, row) => (
          <a onClick={() => ContentManager.functions.openEditor(row)}>
            <Icon name={"external"} />
          </a>
        ),
        orderable: false,
        searchable: false,
        props: {
          textAlign: "center",
          selectable: true,
          width: 1
        }
      },
      {
        name: "Name",
        src: row => row.firstname + " " + row.lastname
      },
      {
        name: "Role",
        src: row => row.account_role.name
      }
    ],
    rows: {
      addClass: row => {
        if (row.disabled) {
          return classes.disabled;
        }
      }
    }
  }
});
