import React from "react";
export const { Provider, Consumer } = React.createContext();

export const Wrapper = ReactElement => props => {
  return (
    <Consumer>
      {context => <ReactElement {...props} context={context} />}
    </Consumer>
  );
};
