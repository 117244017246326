import React from "react";
import {
  Container,
  Header,
  Icon,
  Button,
  Grid,
  Divider,
  Dimmer,
  Loader
} from "semantic-ui-react";
import Layout from "components/Layout";
import ContextBar from "components/ContextBar";
import MemberForm from "./components/MemberForm";
import MembershipForm from "./components/MembershipForm";
import MemberSelect from "./components/MemberSelect";
import AjaxFn from "services/AjaxFn";
import { Redirect } from "react-router-dom";

export default class OwnerWizard extends React.Component {
  state = {
    loading: false,
    mode: "member",
    member: member,
    memberData: { ...memberData },
    membershipData: { ...membershipData },
    created: false
  };
  componentDidMount() {
    const { match } = this.props;
    switch (match.path) {
      case "/wizards/members":
        this.setMode("member");
        break;
      case "/wizards/members/:id":
        this.setMode("membership", "member", Number(match.params.id));
        break;
      case "/wizards/memberships":
        this.setMode("membership");
        break;
      default:
        this.setMode("member");
        break;
    }
  }
  setMode = (mode, extraField, extraValue) =>
    this.setState({ mode, [extraField]: extraValue });
  reset = () =>
    this.setState({
      member: member,
      memberData: { ...memberData },
      membershipData: { ...membershipData }
    });
  save = () => {
    this.setState({ loading: true });
    const data = this.getData();
    AjaxFn({
      url: "/apis/portal/owners",
      data: {
        action: "build",
        data: this.getData()
      },
      success: message => {
        if (window.confirm("Successfully saved. Go there now?")) {
          this.setState({ created: message });
        } else {
          this.setState({ loading: false });
          this.reset();
        }
      },
      failure: error => {
        this.setState({ loading: false });
        window.toastr.error(error);
      }
    });
  };
  getData = () => {
    const { mode, memberData, membershipData, member } = this.state;
    switch (mode) {
      case "member":
        return {
          mode: "member",
          data: {
            memberData,
            membershipData
          }
        };
      case "membership":
        return {
          mode: "membership",
          data: {
            ...membershipData,
            member: member
          }
        };
      default:
        return false;
    }
  };
  canSave = () => {
    const { mode } = this.state;
    switch (mode) {
      case "member":
        return this.memberIsValid() && this.membershipIsValid();
      case "membership":
        return this.memberSelectedIsValid() && this.membershipIsValid();
      default:
        return false;
    }
  };
  memberIsValid = () => {
    let valid = true;
    const strings = ["firstname", "lastname", "email", "phone"];
    strings.forEach(field => {
      const data = this.state.memberData[field] || "";
      if (!data.trim()) {
        valid = false;
      }
    });
    return valid;
  };
  membershipIsValid = () => {
    let valid = true;
    const strings = [
      "home_resort_name",
      "account_number",
      "username",
      "password",
      "issuer_company"
    ];
    strings.forEach(field => {
      const data = this.state.membershipData[field] || "";
      if (!data.trim()) {
        valid = false;
      }
    });
    const { level, payrate, resort_company } = this.state.membershipData;
    if (!level) valid = false;
    if (payrate === null) valid = false;
    if (resort_company === null) valid = false;
    return valid;
  };
  memberSelectedIsValid = () => !!this.state.member;
  render() {
    if (this.state.created) {
      switch (this.state.created.type) {
        case "member":
          const mid = this.state.created.created.member.id;
          return <Redirect push to={`/browse/members/${mid}`} />;
        default:
          const msid = this.state.created.created.membership.id;
          const memid = this.state.member;
          return (
            <Redirect push to={`/browse/members/${memid}/membership/${msid}`} />
          );
      }
    }
    return (
      <Layout noBar>
        <ContextBar />
        <Container>
          <Header
            as="h1"
            dividing
            style={{ marginTop: "2rem", marginBottom: "3rem" }}
          >
            <Icon name="wizard" />
            <Header.Content>
              Owner Wizard
              <Header.Subheader>Create Members & Memberships</Header.Subheader>
            </Header.Content>
          </Header>
          <div style={{ position: "relative" }}>
            <Dimmer inverted active={this.state.loading}>
              <Loader>Working...</Loader>
            </Dimmer>
            <Grid columns={2}>
              <Grid.Column width={6}>
                <Header style={{ marginBottom: "2rem" }}>
                  <Icon name="id badge outline" />
                  <Header.Content>
                    Member
                    <Header.Subheader>
                      Personal information about the member
                    </Header.Subheader>
                  </Header.Content>
                </Header>
                {this.state.mode === "member" ? (
                  <MemberForm
                    data={this.state.memberData}
                    setData={(field, data) =>
                      this.setState({
                        memberData: {
                          ...this.state.memberData,
                          [field]: data
                        }
                      })
                    }
                    switchMode={() => this.setMode("membership")}
                  />
                ) : (
                  <MemberSelect
                    data={this.state.member}
                    setData={member => this.setState({ member })}
                    switchMode={() => this.setMode("member")}
                  />
                )}
              </Grid.Column>
              <Grid.Column width={10}>
                <Header style={{ marginBottom: "2rem" }}>
                  <Icon name="cubes" />
                  <Header.Content>
                    Membership
                    <Header.Subheader>
                      Information about the member's ownership
                    </Header.Subheader>
                  </Header.Content>
                </Header>
                <MembershipForm
                  data={this.state.membershipData}
                  setData={(field, data) =>
                    this.setState({
                      membershipData: {
                        ...this.state.membershipData,
                        [field]: data
                      }
                    })
                  }
                />
              </Grid.Column>
            </Grid>
            <Divider />
            <div style={{ textAlign: "right" }}>
              <Button
                content={"Reset"}
                onClick={this.reset}
                disabled={this.state.loading}
              />
              <Button
                color={"blue"}
                icon={"save"}
                content={"Save"}
                onClick={this.save}
                disabled={!this.canSave() || this.state.loading}
                loading={this.state.loading}
              />
            </div>
          </div>
        </Container>
        <br />
        <br />
      </Layout>
    );
  }
}

const memberData = {
  firstname: "",
  lastname: "",
  email: "",
  phone: ""
};
const membershipData = {
  home_resort_name: "",
  account_number: "",
  username: "",
  password: "",
  guest_passes: 0,
  reservation_passes: 0,
  level: null,
  payrate: null,
  is_active: 1,
  shared: 2,
  resort_company: null,
  issuer_company: "Unknown"
};
const member = null;
// test values
// const memberData = {
//   firstname: "John",
//   lastname: "Doe",
//   email: "jd@gmail.com",
//   phone: "444-444-4444"
// };
// const membershipData = {
//   home_resort_name: "asdf",
//   account_number: "123",
//   username: "asdf",
//   password: "asdf",
//   guest_passes: 1,
//   reservation_passes: 0,
//   level: "Gold",
//   payrate: "5.00",
//   is_active: false,
//   shared: false,
//   resort_company: 65,
//   issuer_company: "Premier"
// };
// const member = 1;
