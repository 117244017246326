import React from "react";
import { connect } from "react-redux";

import { Table } from "semantic-ui-react";

import TableRow from "../TableRow";
import NoResults from "../NoResults";

class TableBody extends React.Component {
  render() {
    const { tables, namespace } = this.props;
    const { finalData } = tables[namespace];
    return (
      <Table.Body>
        {finalData.length ? (
          finalData.map((row, ix) => (
            <TableRow row={row} key={ix} namespace={namespace} />
          ))
        ) : (
          <NoResults namespace={namespace} />
        )}
      </Table.Body>
    );
  }
}

const mapStateToProps = state => ({
  tables: state.PortalTables
  // ...
});

const mapDispatchToProps = dispatch => ({
  // ...
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TableBody);
