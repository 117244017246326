import moment from "moment";

/**
 * Reduce Timepunches
 * @param {*} timepunches Array of User timepunches
 * @param {*} format The returned formatted value - Default hh:mm:ss
 * @param {*} trim Trims the time - Default false
 */
export const reduce = (timepunches = [], format = "hh:mm:ss", trim = false) => {
  const mapped = timepunches.map(punch => ({
    in: moment(punch.in),
    out: punch.out ? moment(punch.out) : moment()
  }));

  const reduced = mapped.reduce((a, c) => a + c.out - c.in, 0);
  const duration = moment.duration(reduced);

  return duration.format(format, { trim });
};
