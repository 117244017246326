import React from "react";
import { Table } from "semantic-ui-react";

export default ({ row_bookings, row }) => {
  const { membership } = row_bookings[0].point_usages[0].point_set;
  const { member } = membership;
  const url = `/browse/members/${member.id}/membership/${membership.id}`;
  // const url = "/browse/memberships/" + membership.id;
  const handleClick = e => {
    e.preventDefault();
    if (window.confirm("Open membership in new tab?")) {
      window.open(url);
    }
  };
  return (
    <Table.Cell selectable>
      <a href={url} onClick={handleClick}>
        {row[1]}
      </a>
    </Table.Cell>
  );
};
