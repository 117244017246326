import React, { Fragment } from "react";
import { Form } from "semantic-ui-react";
import { Wrapper } from "../../../../context";

const Check = ({ context: { functions } }) => (
  <Fragment>
    <p>Your checking information is required to process the payment.</p>
    <Form.Group widths={"equal"}>
      {functions.createInput(<Form.Input />, "check_bank_addr", {
        name: "check_bank_addr",
        label: "Bank address",
        placeholder: "Bank address",
        required: true,
        autoComplete: "off"
      })}
      {functions.createInput(<Form.Input />, "check_account_num", {
        name: "check_account_num",
        label: "Account number",
        placeholder: "Account number",
        required: true,
        autoComplete: "off",
        type: "number"
      })}
    </Form.Group>
    <Form.Group widths={"equal"}>
      {functions.createInput(<Form.Input />, "check_routing_num", {
        name: "check_routing_num",
        label: "Routing number",
        placeholder: "Routing number",
        required: true,
        autoComplete: "off",
        type: "number"
      })}
      {functions.createInput(<Form.Input />, "check_num", {
        name: "check_num",
        label: "Check number",
        placeholder: "Check number",
        required: true,
        autoComplete: "off",
        type: "number"
      })}
    </Form.Group>
  </Fragment>
);

export default Wrapper(Check);
