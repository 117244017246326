const timezones = [
  { key: "hst", text: "Hawaii–Aleutian Time Zone", description: "HST", value: "HST" },
  { key: "akst", text: "Alaska Time Zone", description: "AKST", value: "AKST" },
  { key: "pst", text: "Pacific Time Zone", description: "PST", value: 'PST' },
  { key: "mst", text: "Mountain Time Zone", description: "MST", value: "MST" },
  { key: "cst", text: "Central Time Zone", description: "CST", value: "CST" },
  { key: "est", text: "Eastern Time Zone", description: "EST", value: "EST" }
];

export default timezones;
