import React from "react";
import {
  Header,
  Icon,
  Button,
  Divider,
  Segment,
  Step
} from "semantic-ui-react";
import Layout from "components/Layout";
import AjaxFn from "services/AjaxFn";
import Step1 from "./components/Step1";
import Step2 from "./components/Step2";
import Step3 from "./components/Step3";
import Breadcrumb from "./components/Breadcrumb";

const defaultData = {
  firstname: "",
  lastname: "",
  username: "",
  email: "",
  account_company_id: "",
  account_role_id: "",
  mon: "10:00:00",
  tue: "10:00:00",
  wed: "10:00:00",
  thu: "10:00:00",
  fri: "10:00:00",
  sat: null,
  sun: null
};

export default class AccountWizard extends React.Component {
  state = {
    loading: false,
    step: 1,
    data: {
      ...defaultData
    }
  };

  /**
   *	Update a key/value pair in data
   *  @param {String}
   */
  set = (index, value) =>
    this.setState(({ data }) => ({
      data: {
        ...data,
        [index]: value
      }
    }));

  /**
   *	Check if the current step is completed
   */
  isStepDone = step => {
    switch (step) {
      case 1:
        const value = this.state.data["account_role_id"];
        if (value === undefined || value === "") {
          return false;
        }
        return true;
      case 2:
        try {
          [
            "firstname",
            "lastname",
            "username",
            "email",
            "account_company_id"
          ].forEach(field => {
            const value = this.state.data[field];
            if (value === undefined || value === "") {
              throw new Error();
            }
          });
        } catch (e) {
          return false;
        }
        return true;
      case 3:
        // this one has acceptible defaults
        return true;
      default:
        // shouldn't ever default
        return false;
    }
  };

  /**
   *	Return properties for the steps
   *  @param {Integer} step
   */
  getStepProps = step => {
    const current = this.state.step;
    return {
      completed: current > step,
      active: current === step,
      disabled: current < step,
      onClick: () => this.setState({ step })
    };
  };

  /**
   *	Increment/Decrement step
   */
  modifyStep = val => this.setState(({ step }) => ({ step: step + val }));

  /**
   *	@return {ReactElement}
   */
  getCurrentStepContent = () => {
    const { step, data } = this.state;
    switch (step) {
      case 1:
        return <Step1 data={data} set={this.set} />;
      case 2:
        return <Step2 data={data} set={this.set} />;
      case 3:
        return <Step3 data={data} set={this.set} />;
      default:
        // shouldn't ever default
        return false;
    }
  };

  canSave = () => {
    return (
      this.state.step === 3 &&
      this.isStepDone(1) &&
      this.isStepDone(2) &&
      this.isStepDone(3)
    );
  };

  save = () => {
    this.setState({ loading: true });
    AjaxFn({
      url: "/apis/portal/accounts",
      data: {
        action: "create",
        data: this.state.data
      },
      success: message => {
        this.setState({ loading: false, step: 1, data: { ...defaultData } });
        window.toastr.success(
          "The account has been created. Its password is: " + message.password
        );
      },
      failure: e => {
        this.setState({ loading: false });
        window.toastr.error(e);
      }
    });
  };

  render() {
    const { step, loading } = this.state;
    return (
      <Layout noBar>
        <Breadcrumb />
        <div style={{ maxWidth: "800px", margin: "0 auto" }}>
          <br />
          <Header dividing as="h2">
            <Icon name="wizard" />
            <Header.Content>
              Accounts Wizard
              <Header.Subheader>Accounts Wizard</Header.Subheader>
            </Header.Content>
          </Header>
          {loading ? (
            <Segment loading raised style={{ padding: "16rem 8rem" }} />
          ) : (
            <React.Fragment>
              <Step.Group fluid ordered>
                <Step {...this.getStepProps(1)}>
                  <Step.Content>
                    <Step.Title>Role</Step.Title>
                    <Step.Description>Choose a role</Step.Description>
                  </Step.Content>
                </Step>
                <Step {...this.getStepProps(2)}>
                  <Step.Content>
                    <Step.Title>Details</Step.Title>
                    <Step.Description>Enter account details</Step.Description>
                  </Step.Content>
                </Step>
                <Step {...this.getStepProps(3)}>
                  <Step.Content>
                    <Step.Title>Schedule</Step.Title>
                    <Step.Description>Enter schedule info</Step.Description>
                  </Step.Content>
                </Step>
              </Step.Group>
              {this.getCurrentStepContent()}
              <Divider />
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div>
                  {step !== 1 && (
                    <Button
                      onClick={() => this.modifyStep(-1)}
                      content={"Previous step"}
                    />
                  )}
                </div>
                <div style={{ textAlign: "right" }}>
                  {step !== 3 && (
                    <Button
                      onClick={() => this.modifyStep(1)}
                      content={"Next step"}
                      disabled={!this.isStepDone(step)}
                    />
                  )}
                  {this.canSave() && (
                    <Button
                      color={"blue"}
                      onClick={this.save}
                      icon={"save"}
                      content={"Save"}
                    />
                  )}
                </div>
              </div>
            </React.Fragment>
          )}
        </div>
        <br />
      </Layout>
    );
  }
}
