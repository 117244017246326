import moment from "moment";

/**
 * Return the most recent availability pull for the unit
 * @return {null|moment}
 */
export const getLastPull = unit => {
  if (!unit.ciirus_units) {
    throw new Error("missing unit.ciirus_units");
  }
  let last_pull = null;
  unit.ciirus_units.forEach(sub_unit => {
    if (sub_unit.availability_pulled) {
      if (last_pull) {
        if (sub_unit.availability_pulled > last_pull) {
          last_pull = sub_unit.availability_pulled;
        }
      } else {
        last_pull = sub_unit.availability_pulled;
      }
    }
  });
  return last_pull ? moment(last_pull) : null;
};

/**
 * Determine if the date of the last push should be considered updated or outdated
 * in regards to the given availability interval
 * @param {String} last_push the datetime string of the last push
 * @param {String} interval the unit's availability interval
 * @return {Bool}
 */
export const isLastPushUpToDate = (last_push, interval) => {
  if (!last_push) {
    return false;
  } else if (interval === "never") {
    return true;
  } else {
    const lastMidnight = getLastMidnight();
    const even = moment().dayOfYear() % 2;
    const push = moment(last_push);
    switch (interval) {
      case "every":
        return moment(push) > getLastMidnight();
      case "even":
        if (even) {
          return moment(push) > lastMidnight.subtract(1, "day");
        } else {
          return moment(push) > lastMidnight;
        }
      case "odd":
        if (even) {
          return moment(push) > lastMidnight;
        } else {
          return moment(push) > lastMidnight.subtract(1, "day");
        }
      case "fri":
        return moment(push) > getLastFridayMidnight();
      default:
        return false;
    }
  }
};

/**
 * Return whether or not availability should be considered outdated or not for a unit
 * @param {Object} unit
 * @return {String} representing availability status
 */
export const getAvailabilityUpdated = unit => {
  const { unit_type, availability_interval } = unit;

  switch (unit_type) {
    case "ciirus":
      return getCiirusAvailabilityUpdated(unit);
    default:
      throw new Error("unknown unit type");
  }
};

/**
 * Returns whether or not unit ciirus_units have invalid availability
 * @param {Object} unit
 * @return {String} representing availability status
 */
export const getCiirusAvailabilityUpdated = unit => {
  const { ciirus_units, availability_interval } = unit;

  if (!ciirus_units.length) {
    return "unlisted";
  }

  const unfrozenListings = ciirus_units.filter(
    listing => !listing.freeze_availability
  );

  if (!unfrozenListings.length) {
    return "frozen";
  }

  const outdatedListings = unfrozenListings.find(({ availability_pushed }) => {
    return !isLastPushUpToDate(availability_pushed, availability_interval);
  });

  return typeof outdatedListings === "undefined" ? "updated" : "outdated";
};

export const toMidnight = date =>
  date
    .hour("23")
    .minute("59")
    .second("59");
export const getLastMidnight = () => toMidnight(moment().subtract(1, "day"));
export const getLastFridayMidnight = () =>
  toMidnight(
    moment()
      .day(0)
      .subtract(2, "day")
  );
