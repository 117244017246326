import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { toggleDeleted } from "redux/TimepunchManager/actions";
import { Checkbox } from "semantic-ui-react";

export class ShowDeletedToggle extends Component {
  static propTypes = {
    showing: PropTypes.bool.isRequired
  };

  render() {
    const { showing, toggleDeleted } = this.props;

    return (
      <Checkbox
        label={"Show Deleted Punches"}
        toggle
        checked={showing}
        onChange={toggleDeleted}
      />
    );
  }
}

const mapStateToProps = state => ({
  showing: state.TimepunchManager.show_deleted
});

const mapDispatchToProps = {
  toggleDeleted
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ShowDeletedToggle);
