import InitialState from "./initial.js";

console.log("InitialState", InitialState);

export default (state = InitialState, action) => {
  switch (action.type) {
    case "STORAGE_SET":
      return {
        ...state,
        [action.key]: action.value
      };
    case "STORAGE_RESYNC":
      return {
        ...action.data
      };
    default:
      return state;
  }
};
