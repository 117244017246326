import React from "react";
import { Icon, Header } from "semantic-ui-react";
import styled from "styled-components";

import { Wrapper } from "components/Agreement/context";

const SubmittedMessage = ({ context }) => (
  <Wrap>
    <Header as="h2" icon textAlign="center">
      <Icon name="checkmark" color={"green"} circular />
      <Header.Content>Agreement completed</Header.Content>
    </Header>
    <p>
      We have received your agreement. It can no longer be altered, but a copy
      of it can be viewed below.
      <br />
      <br />
      <b>Do not give your secure link to anyone!</b>
    </p>
  </Wrap>
);

const Wrap = styled.div`
  padding: 8rem 0;

  p {
    text-align: center !important;
  }
`;

export default Wrapper(SubmittedMessage);
