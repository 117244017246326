import React from "react";
import { connect } from "react-redux";
import { loadBanks } from "redux/EOQReport/actions";
import injectSheet from "react-jss";
import { Segment, Divider, Header } from "semantic-ui-react";
import WithState from "components/WithState";
import Statistics from "./components/Statistics";
import CheckInfo from "./components/CheckInfo";
import Controls from "./components/Controls";

class EOQSummary extends React.Component {
  componentDidMount() {
    this.props.loadBanks();
  }
  render() {
    const { loading, error } = this.props;
    return (
      <Segment>
        <Header as={"h2"}>Summary</Header>
        <Statistics />
        <Divider />
        <WithState loading={loading} error={error} loaderStyles={loaderStyles}>
          <CheckInfo />
          <br />
          <Controls />
        </WithState>
      </Segment>
    );
  }
}

const loaderStyles = {
  paddingTop: "4rem",
  paddingBottom: "4rem"
};

const styles = {
  // ...
};

const mapStateToProps = state => ({
  loading: state.EOQReport.banks.loading || state.EOQReport.saving,
  error: state.EOQReport.banks.error
  // ...
});
const mapDispatchToProps = dispatch => ({
  loadBanks: () => dispatch(loadBanks())
  // ...
});

const connected = connect(
  mapStateToProps,
  mapDispatchToProps
)(EOQSummary);
export default injectSheet(styles)(connected);
