import { combineReducers } from "redux";
import Alertbar from "./Alertbar/reducer";
import BookingList from "./BookingList/reducer";
import BookingPage from "./BookingPage/reducer";
import BookingWizard from "./BookingWizard/reducer";
import Calendar from "./Calendar/reducer";
import CheckWizard from "./CheckWizard/reducer";
import ContextBar from "./ContextBar/reducer";
import EOQReport from "./EOQReport/reducer";
import NewsFeed from "./NewsFeed/reducer";
import PortalTables from "./PortalTables/reducer";
import Sidebar from "./Sidebar/reducer";
import Sockets from "./Sockets/reducer";
import Storage from "./Storage/reducer";
import TimepunchManager from "./TimepunchManager/reducer";
import User from "./User/reducer";

export default combineReducers({
  Alertbar,
  BookingList,
  BookingPage,
  BookingWizard,
  Calendar,
  CheckWizard,
  ContextBar,
  EOQReport,
  NewsFeed,
  PortalTables,
  Sidebar,
  Sockets,
  Storage,
  TimepunchManager,
  User
});
