import React from "react";

import { Form, Grid, Header, Input, Message, Segment } from "semantic-ui-react";

import AsyncForm from "components/AsyncForm";
import { countries } from "fixtures/countries";
import Activate from "./components/Activate";
import Assign from "./components/Assign";
import props from "../props.js";

const Summary = props => {
  const {
    resortData: { data },
    reloadResort
  } = props;

  const config = {
    reload: reloadResort,
    failure: window.toastr.error,
    dataFn: (source, value) => {
      return {
        url: "/apis/portal/resorts",
        data: {
          action: "update",
          data: {
            ID: data.id,
            [source]: typeof value === "boolean" ? (value ? 1 : 0) : value
          }
        }
      };
    }
  };

  if (data.country === "") {
    data.country = "none";
  }

  if (data.country === "USA") {
    data.country = "us";
  }

  return (
    <>
      <Header
        as="h1"
        dividing
        icon="folder outline"
        content={data.resort_company.name + " " + data.name}
        subheader="View or edit general resort info"
      />
      {!data.active && (
        <Message error>
          <Message.Header>Resort Inactive</Message.Header>
          This resort is marked as inactive. It will not be available for use
          for bookings. If this is a mistake, you can set it to active in the
          "actions" section below.
        </Message>
      )}
      <Segment attached="top">
        <AsyncForm {...config}>
          {({ functions }) => {
            return (
              <Grid columns="3" stackable>
                <Grid.Row>
                  <Grid.Column>
                    <Header icon="building outline" content="General" />
                    <Form.Field>
                      <label>Name</label>
                      {functions.make({
                        element: <Input />,
                        field: "name",
                        value: data.name,
                        debounce: 500,
                        props: {
                          label: data.resort_company.name,
                          placeholder: "Name"
                        }
                      })}
                    </Form.Field>
                    <Form.Field>
                      <label>Assigned To</label>
                      <Input
                        value={
                          data.assigned_account.id
                            ? data.assigned_account.firstname +
                              " " +
                              data.assigned_account.lastname
                            : "Nobody"
                        }
                        action={<Assign {...props} />}
                        readOnly
                      />
                    </Form.Field>
                    {functions.make({
                      element: <Form.Input />,
                      field: "phone",
                      value: data.phone,
                      debounce: 500,
                      props: {
                        label: "Primary Phone",
                        placeholder: "(xxx) xxx-xxxx"
                      }
                    })}
                  </Grid.Column>
                  <Grid.Column>
                    <Header icon="map" content="Address" />
                    {functions.make({
                      element: <Form.Input />,
                      field: "address",
                      value: data.address,
                      debounce: 500,
                      props: {
                        label: "Street Address",
                        placeholder: "1234 Example St."
                      }
                    })}
                    {functions.make({
                      element: <Form.Input />,
                      field: "city",
                      value: data.city,
                      debounce: 500,
                      props: {
                        label: "City",
                        placeholder: "City"
                      }
                    })}
                    {functions.make({
                      element: <Form.Input />,
                      field: "state",
                      value: data.state,
                      debounce: 500,
                      props: {
                        label: "State",
                        placeholder: "State"
                      }
                    })}
                    {functions.make({
                      element: <Form.Dropdown />,
                      field: "country",
                      value: data.country,
                      props: {
                        label: "Country",
                        name: "not-a-country-chrome",
                        search: true,
                        selection: true,
                        lazyLoad: true,
                        selectOnBlur: false,
                        options: allCountries
                      }
                    })}
                  </Grid.Column>
                  <Grid.Column>
                    <Header icon="credit card outline" content="Fees" />
                    <Form.Field>
                      <label>Security Deposit</label>
                      {functions.make({
                        element: <Input />,
                        field: "security_deposit",
                        value: data.security_deposit,
                        debounce: 500,
                        props: {
                          label: "$",
                          placeholder: "0.00"
                        }
                      })}
                    </Form.Field>
                    <Form.Field>
                      <label>Resort Fee</label>
                      {functions.make({
                        element: <Input />,
                        field: "resort_fee",
                        value: data.resort_fee,
                        debounce: 500,
                        props: {
                          label: "$",
                          placeholder: "0.00"
                        }
                      })}
                    </Form.Field>
                    <Form.Field>
                      <label>Parking Fee</label>
                      {functions.make({
                        element: <Input />,
                        field: "parking_fee",
                        value: data.parking_fee,
                        debounce: 500,
                        props: {
                          label: "$",
                          placeholder: "0.00"
                        }
                      })}
                    </Form.Field>
                    <Form.Group widths="equal">
                      <Form.Field>
                        <label>State Tax</label>
                        {functions.make({
                          element: <Input />,
                          field: "state_tax",
                          value: data.state_tax,
                          debounce: 500,
                          props: {
                            label: "%",
                            labelPosition: "right",
                            placeholder: "0.00",
                            fluid: true
                          }
                        })}
                      </Form.Field>
                      <Form.Field>
                        <label>County Tax</label>
                        {functions.make({
                          element: <Input />,
                          field: "county_tax",
                          value: data.county_tax,
                          debounce: 500,
                          props: {
                            label: "%",
                            labelPosition: "right",
                            placeholder: "0.00",
                            fluid: true
                          }
                        })}
                      </Form.Field>
                    </Form.Group>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column width={16}>
                    <Header icon="edit outline" content="Descriptions" />
                    {functions.make({
                      element: <Form.TextArea />,
                      field: "features",
                      value: data.features,
                      debounce: 500,
                      props: {
                        label: "Features / Description",
                        placeholder: "Features / Description",
                        loading: "false"
                      }
                    })}
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column width={16}>{functions.message()}</Grid.Column>
                </Grid.Row>
              </Grid>
            );
          }}
        </AsyncForm>
      </Segment>
      <Segment attached="bottom">
        <Header
          icon="settings"
          content="Actions"
          subheader="Perform actions for this resort"
          as="h3"
        />
        <p>Use the button below to activate or deactivate this resort.</p>
        <Activate resort={data} reloadResort={reloadResort} />
      </Segment>
    </>
  );
};

Summary.propTypes = { ...props };

export default Summary;

const none = {
  key: "none",
  value: "none",
  text: "None"
};

const allCountries = [none].concat(
  countries.map(c => ({
    key: c[0],
    value: c[0],
    flag: c[1],
    text: c[2]
  }))
);
