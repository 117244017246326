import React from "react";
import { Form, Input, Dropdown } from "semantic-ui-react";
import AjaxDropdown from "components/AjaxDropdown";
import NullableDropdown from "components/NullableDropdown";
import payrates from "fixtures/payrates";
import membershipLevels from "fixtures/membershipLevels";
import issuerCompanies from "fixtures/issuerCompanies";
/**
 *	@prop data
 *	@prop setData
 */
export default class MembershipForm extends React.Component {
  createInput = (element, field, props) => {
    return React.cloneElement(element, {
      onChange: (e, { value }) => this.props.setData(field, value),
      value: this.props.data[field],
      ...props
    });
  };
  render() {
    return (
      <React.Fragment>
        <Form>
          <Form.Group widths={"equal"}>
            <Form.Field>
              <label>Resort Company</label>
              {this.createInput(
                <AjaxDropdown
                  url={"/apis/portal/resortcompanies"}
                  data={{ action: "read_for_dropdown" }}
                  dataRetreiver={({ message: m }) =>
                    m.map(({ id, name }) => ({
                      value: id,
                      text: name
                    }))
                  }
                />,
                "resort_company",
                {
                  placeholder: "Resort Company"
                }
              )}
            </Form.Field>
            <Form.Field>
              <label>Membership Level</label>
              {this.createInput(<Dropdown />, "level", {
                placeholder: "Membership Level",
                selection: true,
                options: membershipLevels
              })}
            </Form.Field>
          </Form.Group>
          <Form.Field>
            <label>Home Resort Name</label>
            {this.createInput(<Input fluid />, "home_resort_name", {
              placeholder: "Home Resort Name",
              icon: "building outline"
            })}
          </Form.Field>
          <Form.Group widths={"equal"}>
            <Form.Field>
              <label>Guest Passes</label>
              {this.createInput(<Input fluid />, "guest_passes", {
                placeholder: "Guest Passes",
                type: "number",
                label: "#"
              })}
            </Form.Field>
            <Form.Field>
              <label>Reservation Passes</label>
              {this.createInput(<Input fluid />, "reservation_passes", {
                placeholder: "Reservation Passes",
                type: "number",
                label: "#"
              })}
            </Form.Field>
          </Form.Group>
          <Form.Group widths={"equal"}>
            <Form.Field>
              <label>Account Number</label>
              {this.createInput(<Input fluid />, "account_number", {
                placeholder: "Account Number",
                label: "#"
              })}
            </Form.Field>
            <Form.Field>
              <label>Shared Account</label>
              {this.createInput(<Dropdown />, "shared", {
                placeholder: "Shared Account",
                selection: true,
                options: [
                  { value: 1, text: "Yes" },
                  { value: 0, text: "No" },
                  { value: 2, text: "Unknown" }
                ],
                fluid: true
              })}
            </Form.Field>
            <Form.Field>
              <label>Issued To Us By</label>
              {this.createInput(<Dropdown />, "issuer_company", {
                options: issuerCompanies,
                selection: true,
                placeholder: "Issuing Company",
                fluid: true
              })}
            </Form.Field>
          </Form.Group>
          <Form.Group widths={"equal"}>
            <Form.Field>
              <label>Username</label>
              {this.createInput(<Input fluid />, "username", {
                placeholder: "Username"
              })}
            </Form.Field>
            <Form.Field>
              <label>Password</label>
              {this.createInput(<Input fluid />, "password", {
                placeholder: "Password"
              })}
            </Form.Field>
          </Form.Group>
          <Form.Group widths={"equal"}>
            <Form.Field>
              <label>Payrate</label>
              {this.createInput(<Dropdown />, "payrate", {
                placeholder: "Select a Payrate",
                options: [...payrates].splice(1),
                selection: true
              })}
            </Form.Field>
            <Form.Field>
              <label>Active</label>
              {this.createInput(<Dropdown />, "is_active", {
                placeholder: "Active",
                options: [
                  { value: 0, text: "Inactive" },
                  { value: 1, text: "Active" }
                ],
                selection: true
              })}
            </Form.Field>
          </Form.Group>
        </Form>
      </React.Fragment>
    );
  }
}
