import React from "react";
import { findInObject, setInObject, trimObject } from "utils/objects";

export default class Wizard extends React.Component {
  state = {
    data: {}
  };

  componentWillMount() {
    const { Wizard = {} } = this.props.config;
    if (typeof Wizard.Defaults === "object") {
      this.setState({
        data: Wizard.Defaults
      });
    }
  }

  /**
   *	Returns relevant functions/data for rendering
   *	@return {Object}
   */
  _this = () => ({
    ...this.state,
    functions: {
      createInput: this.createInput,
      createCheckbox: this.createCheckbox,
      set: this.set,
      reset: this.reset,
      save: this.save
    }
  });
  _ajax = () => {
    const { Wizard: { AJAX } = {} } = this.props.config;
    if (!AJAX) {
      throw new Error("No .AJAX property was defined");
    }
    if (!AJAX.instance) {
      throw new Error("No .AJAX.instance property was defined");
    }
    return {
      instance: AJAX.instance,
      validator: AJAX.validator || this._defaultAjaxValidator,
      onSuccess: AJAX.onSuccess || this._defaultAjaxSuccess,
      onError: AJAX.onError || this._defaultAjaxError,
      data: AJAX.data || this._defaultAjaxData
    };
  };
  _defaultAjaxValidator = ({ status: s }) => s;
  _defaultAjaxSuccess = () => console.log("success");
  _defaultAjaxError = ({ message: e = "Unknown error" }) => {
    window.toastr.error(e);
  };
  _defaultAjaxData = data => ({ data });
  /**
   *	Update a key/value pair in this.state.data
   *  @param {String/Array} path
   *  @param {Mixed} value
   *	@return {Object}
   */
  set = (path, value) => {
    this.setState(({ data }) => {
      setInObject(data, path, value);
      trimObject(data);
      return { data };
    });
  };
  /**
   *  Clear out this.state.data
   */
  reset = () => this.setState({ data: {} });
  /**
   *	A helper to generate a controlled editor component with less code
   *	@param {ReactElement} reactElement (any valid react element, like <Input /> from semanticUI)
   *  @param {String} name (the name that corresponds to the entry in the editor's redux store)
   *  @param {Object} props (optional, any props you want to add on extra)
   *	@return {ReactElement}
   */
  createInput = (reactElement, name, props = {}) => {
    const path = Array.isArray(name) ? name : [name];
    const foundValue = findInObject(this.state.data, path);
    return React.cloneElement(reactElement, {
      value: typeof foundValue === "undefined" ? "" : foundValue,
      placeholder: path[0],
      onChange: (e, { value }) => this.set(path, value),
      ...props
    });
  };
  createCheckbox = (reactElement, name, props = {}) => {
    const path = Array.isArray(name) ? name : [name];
    const value = findInObject(this.state.data, path) || false;
    return React.cloneElement(reactElement, {
      checked: value ? true : false,
      onChange: (e, { checked: value }) => this.set(path, value ? 1 : 0),
      ...props
    });
  };
  /**
   *	Handler for clicking the save button
   */
  save = () => {
    const { manager } = this.props;
    const AJAX = this._ajax();
    const onSuccess = response => {
      manager.functions.wizardLoading(false);
      manager.functions.closeWizard(true);
      manager.functions.refresh();
      this.reset();
      AJAX.onSuccess(response);
    };
    const onError = response => {
      manager.functions.wizardLoading(false);
      AJAX.onError(response);
    };
    const payload =
      typeof AJAX.data === "function"
        ? AJAX.data({ ...this.state.data })
        : AJAX.data;
    manager.functions.wizardLoading(true);
    AJAX.instance.send(AJAX.validator, onSuccess, onError, payload);
  };

  render() {
    const { config, manager } = this.props;
    const { Wizard: { JSX } = {} } = config;
    if (typeof JSX === "function") {
      return JSX(manager, this._this());
    } else if (typeof JSX !== "undefined") {
      return JSX;
    } else {
      return null;
    }
  }
}
