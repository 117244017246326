import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";

import { Table, Checkbox } from "semantic-ui-react";

import { countPointsLeftInMembership } from "utils/memberships";
import { addCommas } from "utils/numbers";

export default class FoundOwners extends Component {
  static propTypes = {
    data: PropTypes.array.isRequired,
    use: PropTypes.func.isRequired,
    check_in: PropTypes.string.isRequired
  };

  state = {
    showingMore: false,
    restrictQuarter: true
  };

  /**
   * turn a datetime string like 1970-01-01 00:00:00 into
   * the month and day 01-01
   * @param {String} datetime
   * @return {String}
   */
  formatDate = datetime => {
    const dates = datetime.split(" ")[0].split("-");
    return dates[1] + "-" + dates[2];
  };

  categorizeByQuarter = bookings => {
    const quarters = [
      {
        start: "01-01",
        end: "03-31",
        bookings: []
      },
      {
        start: "04-01",
        end: "06-30",
        bookings: []
      },
      {
        start: "07-01",
        end: "09-30",
        bookings: []
      },
      {
        start: "10-01",
        end: "12-31",
        bookings: []
      }
    ];

    bookings.forEach(booking => {
      const date = this.formatDate(booking.check_in);

      quarters.forEach(quarter => {
        if (date >= quarter.start && date <= quarter.end) {
          quarter.bookings.push(booking);
        }
      });
    });

    return quarters;
  };

  filterByQuarter = row => {
    const bookings = {};
    // first we need to get every booking in the set
    // store in an object indexed by id for uniqueness
    row.points_sets.forEach(point_set => {
      point_set.point_usages.forEach(point_usage => {
        const booking = point_usage.booking;
        if (typeof bookings[booking.id] === "undefined") {
          bookings[booking.id] = booking;
        }
      });
    });

    // turn that object of bookings by id's into an array
    const bookingsArray = Object.values(bookings);

    // first, group up all bookings into quarters
    const bookingsInQuarter = this.categorizeByQuarter(bookingsArray);

    // next, order them in terms of who has the most bookings first
    const orderedQuarters = bookingsInQuarter.sort((a, b) => {
      return a.bookings.length > b.bookings.length ? -1 : 1;
    });

    // next, figure out which quarter has the most bookings
    const maximum = orderedQuarters[0].bookings.length;

    // remove any quarters that have less then that
    // this makes sure if two quarters have = num of bookings,
    // both are viable options
    const filteredQuarters = orderedQuarters.filter(quarter => {
      return quarter.bookings.length >= maximum;
    });

    let isViable = false;
    const check_in = this.formatDate(this.props.check_in);
    filteredQuarters.forEach(quarter => {
      if (check_in >= quarter.start && check_in <= quarter.end) {
        isViable = true;
      }
    });

    return isViable;
  };

  getVisibleData = () => {
    const { data } = this.props;
    const { showingMore, restrictQuarter } = this.state;

    return data
      .filter(row => {
        if (restrictQuarter) {
          return this.filterByQuarter(row);
        }

        return true;
      })
      .map(row => ({
        ...row,
        remaining: countPointsLeftInMembership(row)
      }))
      .sort((a, b) => (a.remaining > b.remaining ? 1 : -1))
      .slice(0, showingMore ? undefined : 10);
  };

  render() {
    const { use } = this.props;
    const { showingMore, restrictQuarter } = this.state;

    const data = this.getVisibleData();

    return (
      <Fragment>
        <Table compact={"very"} selectable>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Total Points</Table.HeaderCell>
              <Table.HeaderCell>Name</Table.HeaderCell>
              <Table.HeaderCell>Membership</Table.HeaderCell>
              <Table.HeaderCell />
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {data.length ? (
              data.map((row, key) => (
                <Table.Row key={key}>
                  <Table.Cell>{addCommas(row.remaining)}</Table.Cell>
                  <Table.Cell>
                    {row.member.firstname + " " + row.member.lastname}
                  </Table.Cell>
                  <Table.Cell>
                    {row.resort_company.name + " " + row.level}
                  </Table.Cell>
                  <Table.Cell>
                    <a href={null} onClick={() => use(row)}>
                      Select
                    </a>
                  </Table.Cell>
                </Table.Row>
              ))
            ) : (
              <Table.Row>
                <Table.Cell colSpan={4}>
                  <br />
                  <p>There are no results to show.</p>
                  <br />
                </Table.Cell>
              </Table.Row>
            )}
          </Table.Body>
        </Table>
        <Checkbox
          label="Restrict to 10 results"
          checked={!showingMore}
          toggle
          onChange={() => this.setState({ showingMore: !showingMore })}
        />
        <br />
        <br />
        <Checkbox
          label="Filter by quarter (check-in)"
          checked={restrictQuarter}
          toggle
          onChange={() => this.setState({ restrictQuarter: !restrictQuarter })}
        />
      </Fragment>
    );
  }
}
