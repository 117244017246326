import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { reload } from "redux/TimepunchManager/actions";
import { Button } from "semantic-ui-react";
import AjaxFn from "services/AjaxFn";

export class ClockOutButton extends Component {
  static propTypes = {
    locked: PropTypes.bool.isRequired,
    setLocked: PropTypes.func.isRequired,
    reload: PropTypes.func.isRequired,
    account: PropTypes.object.isRequired
  };

  state = {
    loading: false,
    cancel: false
  };

  componentWillUnmount() {
    this.cancel();
  }

  cancel = () => {
    if (typeof this.state.cancel === "function") {
      this.state.cancel();
    }
  };

  handleClick = () => {
    if (!window.confirm("Are you sure?")) {
      return false;
    }

    const { setLocked, reload } = this.props;
    this.cancel();
    setLocked(true);
    this.setState({
      loading: true,
      cancel: AjaxFn({
        url: "/apis/portal/timepunches/",
        data: {
          action: "clockUserOut",
          data: {
            ID: this.props.account.id
          }
        },
        success: () => {
          window.toastr.success();
          reload(false);
        },
        failure: error => {
          window.toastr.error(error);
          setLocked(false);
          this.setState({ loading: false });
        }
      })
    });
  };

  render() {
    return (
      <Button
        content="Clock Out"
        color="red"
        onClick={this.handleClick}
        loading={this.state.loading}
        disabled={this.props.locked}
      />
    );
  }
}

const mapStateToProps = state => ({
  // ...
});

const mapDispatchToProps = {
  reload
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ClockOutButton);
