import { getAvailabilityUpdated } from "utils/units";

export default data => {
  return data.map(row => ({
    ...row,
    availability_updated: getAvailabilityUpdated(row),
    completion: getCompletion(row)
  }));
};
/**
 * Return an integer representing unit completion %
 * @param {Object} u
 * @return {Integer}
 */
const getCompletion = u => {
  let completion = 0;
  if (u.active) {
    completion += 25;
  }
  if (u.features && u.features !== "N/A") {
    completion += 25;
  }
  if (u.unique_title && u.unique_title !== "N/A") {
    completion += 25;
  }
  if (u.ciirus_id && u.ciirus_id !== "N/A") {
    completion += 25;
  }
  return completion;
};
