import React from "react";
export default class DeleteButton extends React.PureComponent {
  state = {
    isOpen: false,
    isLoading: false
  };
  _this = () => ({
    state: { ...this.state },
    functions: {
      openModal: this.openModal,
      cancel: this.cancel,
      confirm: this.confirm
    }
  });
  _ajax = () => {
    const { Delete: { AJAX } = {} } = this.props.config;
    if (!AJAX) {
      throw new Error("No .AJAX property was defined");
    }
    if (!AJAX.instance) {
      throw new Error("No .AJAX.instance property was defined");
    }
    return {
      instance: AJAX.instance,
      validator: AJAX.validator || this._defaultAjaxValidator,
      onSuccess: AJAX.onSuccess || this._defaultAjaxSuccess,
      onError: AJAX.onError || this._defaultAjaxError,
      data: AJAX.data || this._defaultAjaxData
    };
  };
  _defaultAjaxValidator = ({ status: s }) => s;
  _defaultAjaxSuccess = () => console.log("success");
  _defaultAjaxError = ({ message: e = "Unknown error" }) => {
    window.toastr.error(e);
  };
  _defaultAjaxData = ({ id: ID }) => ({ ID });
  confirm = () => {
    const { manager } = this.props;
    const AJAX = this._ajax();
    const onSuccess = response => {
      this.cancel();
      this.setState({ isLoading: false });
      manager.functions.closeEditor(true);
      try {
        manager.functions.refresh();
      } catch (e) {
        console.warn("could not refresh table");
      }
      AJAX.onSuccess(response);
    };
    const onError = response => {
      this.setState({ isLoading: false });
      AJAX.onError(response);
    };
    const payload =
      typeof AJAX.data === "function"
        ? AJAX.data(manager.state.editorSource)
        : AJAX.data;
    this.setState({ isLoading: true });
    AJAX.instance.send(AJAX.validator, onSuccess, onError, payload);
  };

  openModal = () => this.setState({ isOpen: true });
  cancel = () => this.setState({ isOpen: false });

  render() {
    const { config, manager } = this.props;
    const { Delete: { JSX } = {} } = config;
    if (typeof JSX === "function") {
      return JSX(manager, this._this());
    } else if (typeof JSX !== "undefined") {
      return JSX;
    } else {
      return null;
    }
  }
}
