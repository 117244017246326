import InitialState from "./initial.js";
export default (state = {}, action) => {
  switch (action.type) {
    case "PORTAL_TABLES_CREATE_TABLE":
      return {
        ...state,
        [action.tableName]: {
          ...InitialState,
          ...action.tableData,
          AJAX: action.AJAX
        }
      };
    case "PORTAL_TABLES_SET_LOADING":
      return {
        ...state,
        [action.tableName]: {
          ...state[action.tableName],
          loading: true,
          error: false
        }
      };
    case "PORTAL_TABLES_SET_ERROR":
      return {
        ...state,
        [action.tableName]: {
          ...state[action.tableName],
          loading: false,
          error: action.value || "An unknown error has occurred"
        }
      };
    case "PORTAL_TABLES_SET_DATA":
      const tableState = {
        ...state[action.tableName],
        [action.dataType]: action.data
      };
      if (action.dataType === "requestData") {
        tableState.loading = false;
      }
      return {
        ...state,
        [action.tableName]: tableState
      };
    case "PORTAL_TABLES_SET_ORDER":
      return {
        ...state,
        [action.tableName]: {
          ...state[action.tableName],
          order: action.order
        }
      };
    case "PORTAL_TABLES_SET_SEARCH":
      return {
        ...state,
        [action.tableName]: {
          ...state[action.tableName],
          search: action.search
        }
      };
    case "PORTAL_TABLES_SET_FILTERS":
      return {
        ...state,
        [action.tableName]: {
          ...state[action.tableName],
          filters: action.filters
        }
      };
    case "PORTAL_TABLES_SET_SHOWN":
      return {
        ...state,
        [action.tableName]: {
          ...state[action.tableName],
          show: action.amount
        }
      };
    case "PORTAL_TABLES_SET_PAGE":
      return {
        ...state,
        [action.tableName]: {
          ...state[action.tableName],
          page: action.page
        }
      };
    default:
      return state;
  }
};
