import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import styled from "styled-components";
import theme from "styled-theming";

import {
  portalBlue,
  portalBlackLightest,
  offWhite,
  offWhiteDark,
  portalBlack
} from "theme/colors";

import { user_can } from "utils/user";
import { getNews } from "redux/NewsFeed/actions";

import Posts from "./Posts";
import ViewPost from "./ViewPost";
import CreatePost from "./CreatePost";

const Feed = ({ feed, getNews, user }) => {
  const [view, setView] = useState("posts");

  const viewPost = postId => {
    return setView(postId);
  };

  const goBack = () => setView("posts");

  useEffect(() => {
    getNews();
  }, []);

  const renderFeed = () => {
    if (view === "create") return <CreatePost goBack={goBack} />;
    if (view === "posts")
      return <Posts posts={feed.posts} viewPost={viewPost} />;
    return <ViewPost posts={feed.posts} viewing={view} goBack={goBack} />;
  };

  if (feed.loading) return <p>Loading...</p>;

  return (
    <Wrapper>
      <div className="posts">{renderFeed()}</div>
      <div className="extra">
        {user_can("ANNOUNCEMENTS_POST", user) && (
          <p>Posting is currently disabled.</p>
        )}
      </div>
    </Wrapper>
  );
};

const mapStateToProps = state => ({
  feed: state.NewsFeed,
  user: state.User
});

Feed.propTypes = {
  feed: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired
};

const mapDispatchToProps = {
  getNews
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Feed);

const extraBackgroundColor = theme("mode", {
  light: offWhiteDark,
  dark: portalBlack
});

const Wrapper = styled.div`
  display: grid;
  grid-template-rows: 1fr auto;

  height: calc(100% + 2rem);
  width: calc(100% + 2rem);
  margin: -1rem;

  .posts {
    overflow-y: auto;
  }

  .extra {
    background-color: ${extraBackgroundColor};
    padding: 0.5rem 1.5rem;
  }
`;

const PostButton = styled.button``;

//<PostButton onClick={() => setView("create")}>
//            <i className="fas fa-edit" />
//          </PostButton>
