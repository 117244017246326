import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";

import { Modal, Button, Form, Input } from "semantic-ui-react";

import AmountSelector from "./components/AmountSelector";
import FoundOwners from "./components/FoundOwners";
import ValidPointSets from "./components/ValidPointSets";
import AjaxFn from "services/AjaxFn";
import Loader from "components/WithState/components/Loader";

export default class PointsWizard extends Component {
  static propTypes = {
    open: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    reload: PropTypes.func.isRequired,
    membership: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
    booking: PropTypes.object.isRequired
  };

  state = {
    loadingMemberships: false,
    loadingMembershipsCancelToken: false,
    saving: false,
    memberships: [],
    membership: false,
    point_set: false,
    amount: ""
  };

  componentWillReceiveProps(props) {
    this.cancelLoadingMemberships();
    if (props.membership) {
      this.setState({
        loadingMemberships: true,
        loadingMembershipsCancelToken: AjaxFn({
          url: "/apis/portal/memberships",
          data: {
            action: "read_points_for_booking_page",
            data: {
              ID: props.membership.id
            }
          },
          success: data => {
            this.setState({
              loadingMemberships: false,
              loadingMembershipsCancelToken: false,
              membership: {
                ...props.membership,
                // remove all sets that the booking falls outside of
                points_sets: data.points_sets.filter(row => {
                  const checkIn = new Date(this.props.booking.check_in);
                  const start = new Date(row.start);
                  const end = new Date(row.end);
                  return checkIn >= start && checkIn <= end;
                })
              }
            });
          },
          failure: window.toastr.error
        })
      });
    }
  }

  /**
   * Calls cancelLoadingMemberships()
   */
  componentWillUnmount() {
    this.cancelLoadingMemberships();
  }

  /**
   * If a cancel token exists, execute it
   */
  cancelLoadingMemberships = () => {
    if (typeof this.state.loadingMembershipsCancelToken === "function") {
      this.state.loadingMembershipsCancelToken();
    }
  };

  /**
   * Keeps modal open if saving, otherwise closes it
   */
  handleClose = () => {
    if (!this.state.saving) {
      this.props.handleClose();
    }
  };

  /**
   * Updates the amount
   */
  setAmount = amount => this.setState({ amount });

  /**
   * Updates the amount and queries for memberships
   */
  setAmountAndSearch = amount => {
    if (amount !== this.state.amount) {
      this.cancelLoadingMemberships();
      this.setState({
        amount,
        memberships: [],
        membership: false,
        point_set: false,
        loadingMemberships: true,
        loadingMembershipsCancelToken: AjaxFn({
          url: "/apis/portal/bookings",
          data: {
            action: "find_owner",
            data: { amount, booking: this.props.booking.id }
          },
          success: memberships => {
            this.setState({
              loadingMemberships: false,
              loadingMembershipsCancelToken: false,
              memberships
            });
          },
          failure: error => {
            this.setState({
              loadingMemberships: false,
              loadingMembershipsCancelToken: false
            });
            window.toastr.error(error);
          }
        })
      });
    }
  };

  /**
   * Set the membership to use
   */
  setMembership = membership => {
    const { points_sets } = membership;

    // if there's only one point set, we don't have to have them choose, we just use it
    if (points_sets.length === 1) {
      this.usePointSet(points_sets[0]);
    } else {
      const newState = {
        membership,
        point_set: false,
        loadingMemberships: true
      };
      // it looks really weird if this is instant so we show a loader
      const callback = () => {
        setTimeout(() => {
          this.setState({
            loadingMemberships: false
          });
        }, 300);
      };
      this.setState(newState, callback);
    }
  };

  /**
   * Clear the membership being used
   */
  unsetMembership = () => {
    const newState = {
      membership: false,
      point_set: false,
      loadingMemberships: true
    };
    // it looks really weird if this is instant so we show a loader
    const callback = () => {
      setTimeout(() => {
        this.setState({
          loadingMemberships: false
        });
      }, 300);
    };
    this.setState(newState, callback);
  };

  /**
   * Fire the request to allocate points using the given point set
   */
  usePointSet = point_set => {
    const { handleClose, reload, booking } = this.props;
    const { amount } = this.state;

    if (!amount || amount < 0) {
      window.toastr.info("You must enter a valid amount of points.");
      return false;
    }

    this.setState({ saving: true });
    AjaxFn({
      url: "/apis/portal/bookingpointusages",
      data: {
        action: "allocate",
        data: {
          booking_id: booking.id,
          point_set_id: point_set.id,
          amount
        }
      },
      success: () => {
        window.toastr.success();
        handleClose();
        reload();
      },
      failure: error => {
        this.setState({ saving: false });
        window.toastr.error(error);
      }
    });
  };

  /**
   * Render the view in the case that the booking has an owner assigned
   */
  renderHasOwnerView = () => {
    const { open } = this.props;
    const { amount, membership, loadingMemberships, saving } = this.state;

    return (
      <Modal open={open} onClose={this.handleClose} size="small">
        <Modal.Header>
          {this.props.membership ? "Allocate Points" : "Assign Owner"}
        </Modal.Header>
        <Modal.Content>
          <p>Enter the amount of points that you want to allocate. </p>
          <AmountSelector
            amount={amount}
            disabled={saving}
            setAmount={this.setAmount}
          />
          <br />
          {saving || loadingMemberships ? (
            <Loader styles={{ height: "300px" }} />
          ) : (
            <div style={{ marginTop: "2rem" }}>
              <ValidPointSets
                membership={membership}
                usePointSet={this.usePointSet}
              />
            </div>
          )}
        </Modal.Content>
        <Modal.Actions>
          <Button
            onClick={this.handleClose}
            content="Close"
            disabled={saving}
          />
        </Modal.Actions>
      </Modal>
    );
  };

  /**
   * Render the view in the case that the booking has no owner assigned
   */
  renderHasNoOwnerView = () => {
    const { booking, open } = this.props;
    const {
      memberships,
      membership,
      amount,
      loadingMemberships,
      saving
    } = this.state;

    return (
      <Modal open={open} onClose={this.handleClose} size="small">
        <Modal.Header>Assign Owner</Modal.Header>
        <Modal.Content>
          <AmountSelector
            amount={amount}
            disabled={saving}
            setAmount={this.setAmountAndSearch}
            message={true}
          />
          <br />
          {saving || loadingMemberships ? (
            <Loader styles={{ height: "300px" }} />
          ) : !membership ? (
            <FoundOwners
              data={memberships}
              use={this.setMembership}
              check_in={booking.check_in}
            />
          ) : (
            <div style={{ marginTop: "2rem" }}>
              <ValidPointSets
                membership={membership}
                unsetMembership={this.unsetMembership}
                usePointSet={this.usePointSet}
              />
            </div>
          )}
        </Modal.Content>
        <Modal.Actions>
          <Button
            onClick={this.handleClose}
            content="Close"
            disabled={saving}
          />
        </Modal.Actions>
      </Modal>
    );
  };

  render() {
    const { membership } = this.props;

    if (membership) {
      return this.renderHasOwnerView();
    } else {
      return this.renderHasNoOwnerView();
    }
  }
}
