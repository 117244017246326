import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import styled from "styled-components";
import theme from "styled-theming";
import moment from "moment";

import { timepunch } from "redux/User/actions";
import { ThemeButton } from "theme/components";
import { danger, portalBlackLightest, portalGrey } from "theme/colors";

import { reduce } from "utils/timepunch";

const thisMorning = moment().startOf("day");

const TimepunchTracker = ({
  clocked_in,
  timepunching,
  timepunches,
  timepunch,
  loading,
  loaded
}) => {
  const buttonText = clocked_in ? "Punch out" : "Punch in";
  const buttonIcon = "fas fa-stopwatch";

  const [today, setToday] = useState("...");
  const [week, setWeek] = useState("...");

  const calculateToday = () => {
    if (loaded && !loading) {
      const todaysPunches = timepunches.filter(punch => {
        return !punch.out || moment(punch.in) >= thisMorning;
      });

      setToday(reduce(todaysPunches));
    }
  };

  const calculateWeek = () => {
    if (loaded && !loading) {
      setWeek(reduce(timepunches));
    }
  };

  useEffect(() => {
    if (clocked_in) {
      const todayInt = setInterval(calculateToday, 1000);
      const weekInt = setInterval(calculateWeek(), 1000);
      return () => {
        clearInterval(todayInt);
        clearInterval(weekInt);
      };
    } else {
      calculateToday();
      calculateWeek();
    }
  });

  return (
    <Wrapper>
      <div className="today">
        <h1>Today</h1>
        <span>{today}</span>
      </div>
      <Divider />
      <div className="week">
        <h1>Week</h1>
        <span>{week}</span>
      </div>
      <ThemeButton
        onClick={timepunch}
        loading={timepunching || loading}
        disabled={timepunching || loading}
        fluid
        secondary
      >
        <i
          className={buttonIcon}
          style={{ color: clocked_in ? danger : null, marginRight: "0.5rem" }}
        />
        {buttonText}
      </ThemeButton>
    </Wrapper>
  );
};

TimepunchTracker.propTypes = {
  timepunching: PropTypes.bool.isRequired,
  clocked_in: PropTypes.bool.isRequired,
  timepunches: PropTypes.array.isRequired,
  timepunch: PropTypes.func.isRequired,
  loaded: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired
};

const mapStateToProps = state => ({
  timepunching: state.User.timepunching,
  clocked_in: state.User.clocked_in,
  timepunches: state.User.timepunches_for_week.data,
  loaded: state.User.timepunches_for_week.loaded,
  loading: state.User.timepunches_for_week.loading
});

const mapDispatchToProps = {
  timepunch
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TimepunchTracker);

const Wrapper = styled.div`
  height: 100%;

  display: grid;
  grid-template-columns: 1fr 1px 1fr;
  grid-template-rows: 1fr auto;
  grid-gap: 1rem;

  align-items: center;
  justify-content: center;
  text-align: center;

  h1 {
    font-size: 1rem;
    font-weight: 600;
  }

  span {
    font-size: 2rem;
    font-weight: 600;
  }

  button {
    grid-column: 1 / span 3;
    align-self: flex-end;
  }

  @media screen and (max-width: 420px) {
    width: 100%;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr auto 1fr;

    button {
      grid-column: auto;
    }
  }
`;

const dividerColor = theme("mode", {
  light: portalGrey,
  dark: portalBlackLightest
});

const Divider = styled.div`
  width: 1px;
  height: 50%;
  border-left: 1px solid ${dividerColor};

  @media screen and (max-width: 420px) {
    border-left: none;
    border-top: 1px solid ${dividerColor};
    width: 100%;
    height: 1px;
  }
`;
