import React from "react";
import { Icon, Button } from "semantic-ui-react";
import { Link } from "react-router-dom";
export default () => ({
  tableName: "AgreementTemplatesList",
  url: "/apis/portal/agreementtemplates",
  data: {
    action: "read"
  },
  tableData: {
    show: 10,
    order: [[1, "asc"]],
    paginationPosition: "bottom",
    showFooter: false,
    menu: () => [
      "[search]",
      "[show]",
      "[refresh]",
      <Link to={"/wizards/agreementtemplates"}>
        <Button primary content={"New"} />
      </Link>
    ],
    cols: [
      {
        name: "",
        src: "id",
        render: (undefined, row) => (
          <a
            title={"View Agreement"}
            href={"/browse/agreementtemplates/" + row.id}
            style={{ width: "50px", marginLeft: "5px" }}
            children={<Icon name={"external"} />}
          />
        ),
        orderable: false,
        searchable: false,
        props: {
          textAlign: "center",
          selectable: true,
          collapsing: true
        }
      },
      {
        name: "Name",
        src: "name"
      },
      {
        name: "Company",
        src: row => row.account_company.name
      },
      {
        name: "Active",
        src: "active",
        render: src => (src ? "Yes" : "No")
      }
    ]
  }
});
