import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { Container, Header, Table } from "semantic-ui-react";

import { useOnMountEffect } from "effects";
import AjaxFn from "services/AjaxFn";

import AgreementListFocusBar from "components/AgreementListFocusBar";
import Layout from "components/Layout";
import WithState from "components/WithState";
import {
  TableHeader,
  TableBody,
  SearchBar,
  ShowSelector,
  Pagination,
  Info
} from "components/PortalTable/parts";

import Breadcrumb from "./components/Breadcrumb";
import Refresh from "./components/Refresh";
import Filters from "./components/Filters";
import config from "./ListFn";

import { createTable } from "redux/PortalTables/actions";
import {
  setLoading,
  setError,
  setRequestData
} from "redux/PortalTables/actions";

const AgreementsList = props => {
  const {
    instantiated,
    createTable,
    setLoading,
    setError,
    setRequestData,
    user,
    table
  } = props;

  const context = {
    reload: () => {
      setLoading("AgreementsList");
      AjaxFn({
        url: "/apis/portal/agreements?action=read",
        success: data => setRequestData("AgreementsList", data),
        failure: err => setError("AgreementsList", err)
      });
    }
  };

  useOnMountEffect(() => {
    createTable("AgreementsList", config(user));
    context.reload();
  });

  return (
    <Context.Provider value={context}>
      <Layout>
        <AgreementListFocusBar page="agreements" />
        <Breadcrumb />
        <br />
        <Container>
          <Header as={"h1"} dividing>
            <Header.Content>
              Agreements
              <Header.Subheader>
                All of the agreements visible to you will be in the list below.
              </Header.Subheader>
            </Header.Content>
          </Header>
          {instantiated && (
            <>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <div style={{ flexGrow: 1, marginRight: ".25rem" }}>
                  <SearchBar namespace="AgreementsList" />
                </div>
                <div style={{ marginRight: ".25rem" }}>
                  <ShowSelector namespace="AgreementsList" />
                </div>
                <div style={{ marginRight: "0" }}>
                  <Filters />
                </div>
                <div style={{ marginRight: ".25rem" }}>
                  <Refresh />
                </div>
              </div>
              <WithState loading={table.loading} error={table.error}>
                <Table>
                  <TableHeader namespace={"AgreementsList"} />
                  <TableBody namespace="AgreementsList" />
                </Table>
                <Pagination namespace={"AgreementsList"} />
                <br />
                <Info namespace={"AgreementsList"} />
              </WithState>
            </>
          )}
        </Container>
      </Layout>
    </Context.Provider>
  );
};

AgreementsList.propTypes = {
  user: PropTypes.object.isRequired,
  createTable: PropTypes.func.isRequired,
  instantiated: PropTypes.bool.isRequired,
  setLoading: PropTypes.func.isRequired,
  setError: PropTypes.func.isRequired,
  setRequestData: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  user: state.User,
  table: state.PortalTables.AgreementsList,
  instantiated: typeof state.PortalTables["AgreementsList"] === "object"
});

const mapDispatchToProps = {
  createTable,
  setLoading,
  setError,
  setRequestData
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AgreementsList);

export const Context = React.createContext();
