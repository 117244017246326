import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import theme from "styled-theming";
import moment from "moment";

import { ThemePopup } from "theme/components";

import {
  offWhite,
  offWhiteDark,
  portalBlack,
  portalBlackLightest,
  portalBlue
} from "theme/colors";

const Posts = ({ posts, viewPost }) => {
  return posts
    .filter(post => post.deleted_on === null)
    .map(post => {
      return <Post post={post} viewPost={viewPost} key={post.id} />;
    });
};

Posts.propTypes = {
  posts: PropTypes.array.isRequired
};

export default Posts;

export const Post = ({ post, viewPost }) => {
  const date = () => {
    const postDate = moment(post.created_on);

    if (!postDate.isSame(moment(), "year")) {
      return postDate.format("MM-DD-YYYY");
    }
    if (!!(moment().diff(postDate, "hours") > 24)) {
      return postDate.format("MMM D");
    }
    return postDate.fromNow();
  };

  const previewText = () => {
    return post.plain_text.length > 280
      ? post.plain_text.substr(0, 280).concat("...")
      : post.plain_text;
  };

  const createdByInitials = () => {
    const first = post.created_by.firstname[0];
    const last = post.created_by.lastname[0];
    return first + last;
  };

  const createdBy = `${post.created_by.firstname} ${post.created_by.lastname}`;

  return (
    <StyledPost onClick={() => viewPost(post.id)}>
      <div className="post">
        <ThemePopup
          trigger={<UserIcon>{createdByInitials()}</UserIcon>}
          content={createdBy}
          size="tiny"
        />
        <div className="post-header">
          <strong>{post.header}</strong>
          <span>&bull;</span>
          <span>{date()}</span>
        </div>
        <div className="post-content">
          <p>{previewText()}</p>
        </div>
      </div>
    </StyledPost>
  );
};

const backgroundColorHover = theme("mode", {
  light: offWhiteDark,
  dark: portalBlack
});

const borderColor = theme("mode", {
  light: offWhiteDark,
  dark: portalBlack
});

const userBackgroundColor = theme("mode", {
  light: portalBlue,
  dark: portalBlackLightest
});

const userFontColor = theme("mode", {
  light: offWhite,
  dark: offWhite
});

const UserIcon = styled.div`
  grid-area: user;

  display: flex;
  align-items: center;
  justify-content: center;

  height: 2.5rem;
  width: 2.5rem;

  background-color: ${userBackgroundColor};
  border-radius: 50%;
  color: ${userFontColor};
  font-weight: 600;

  @media screen and (max-width: 580px) {
    display: none;
  }
`;

const StyledPost = styled.button`
  background-color: transparent;
  border: none;
  border-bottom: 1px solid ${borderColor};
  width: 100%;
  padding: 1.5rem;
  cursor: pointer;

  .post {
    display: grid;
    grid-template-areas:
      "user title"
      "user post";
    grid-template-columns: auto 1fr;
    grid-column-gap: 1rem;

    .user {
      grid-area: user;
    }
    .post-header {
      grid-area: title;
    }
    .post-content {
      grid-area: post;
    }
  }

  &:hover,
  &:focus {
    background-color: ${backgroundColorHover};
  }

  .post-header {
    display: flex;
    justify-content: flex-start;
    margin-bottom: 0.5rem;

    strong {
      font-weight: 700;
    }
    span {
      margin-left: 0.5rem;
      opacity: 0.5;
    }
  }

  .post-content {
    text-align: left;
    white-space: pre-wrap;
    width: 100%;
  }
`;
