import React from "react";
import { Message } from "semantic-ui-react";

const Processed = ({ data }) => {
  if (data.processed) return null;
  return (
    <Message
      header="This check hasn't been Processed"
      content="This check has not been marked as Processed. Checks that are not processed will be automatically deleted at midnight."
      icon="warning"
      warning
    />
  );
};

export default Processed;
