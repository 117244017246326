import React from "react";
import { Table } from "semantic-ui-react";
import { connect } from "react-redux";
import { addToQueue, clearQueue } from "redux/EOQReport/actions";
import injectSheet from "react-jss";
import IDsCheckBox from "../IDsCheckBox";

const Header = ({ rows }) => {
  let allIDs = [];
  rows.forEach(row => {
    allIDs = allIDs.concat(row[2]);
    return;
  });
  return (
    <Table.Header>
      <Table.Row>
        <Table.HeaderCell textAlign={"center"} width={1}>
          <IDsCheckBox IDs={allIDs} />
        </Table.HeaderCell>
        <Table.HeaderCell>Name</Table.HeaderCell>
        <Table.HeaderCell>Membership</Table.HeaderCell>
        <Table.HeaderCell>Bookings</Table.HeaderCell>
        <Table.HeaderCell>Total</Table.HeaderCell>
      </Table.Row>
    </Table.Header>
  );
};

const styles = {
  // ...
};

const mapStateToProps = state => ({
  rows: state.EOQReport.bookings.data.rows_original,
  queue: state.EOQReport.queue
  // ...
});
const mapDispatchToProps = dispatch => ({
  clear: () => dispatch(clearQueue()),
  add: ids => dispatch(addToQueue(ids))
  // ...
});

const connected = connect(
  mapStateToProps,
  mapDispatchToProps
)(Header);
export default injectSheet(styles)(connected);
