import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { Dropdown, Form } from "semantic-ui-react";
import axios from "axios";

import timezones from "fixtures/timezone";

const ClientInfo = ({ email, handleChange, name, phone, time_zone }) => {
	return (
		<Fragment>
			<Form.Group widths="equal">
				<Form.Input
					label="Name"
					id="name"
					name="name"
					nested="client"
					onChange={handleChange}
					value={name || ""}
					autoComplete="off"
					placeholder="Client name"
					// required
				/>
				<Form.Input
					label="Email"
					id="email"
					name="email"
					nested="client"
					onChange={handleChange}
					value={email || ""}
					autoComplete="off"
					placeholder="Client email"
					// required
				/>
			</Form.Group>
			<Form.Group widths="equal">
				<Form.Input
					label="Phone Number"
					id="phone"
					name="phone"
					nested="client"
					onChange={handleChange}
					value={phone || ""}
					autoComplete="off"
					placeholder="Client phone number"
					// required
				/>
				<Form.Field /*required*/>
					<label htmlFor="">Time Zone</label>
					<Dropdown
						fluid
						search
						selection
						options={timezones}
						id="time_zone"
						name="time_zone"
						nested="client"
						onChange={handleChange}
						value={time_zone}
						autoComplete="off"
						placeholder="Select a timezone"
						// required
					/>
				</Form.Field>
			</Form.Group>
		</Fragment>
	);
};

ClientInfo.propTypes = {
	email: PropTypes.string,
	handleChange: PropTypes.func.isRequired,
	name: PropTypes.string,
	phone: PropTypes.string,
	time_zone: PropTypes.string
};

ClientInfo.defaultProps = {
	email: "",
	name: "",
	phone: "",
	time_zone: ""
};

export default ClientInfo;
