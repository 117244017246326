import React from "react";
import { Header, Grid, Form, Segment, Icon } from "semantic-ui-react";
import moment from "moment";

import { FiltersModal } from "components/PortalTable/parts";
import { ThemePopup, ThemePopupContent } from "theme/components";
import Steps from "./Steps";
import Date from "./Date";

// get todays date
const today = moment();

const checkIfStale = row => {
  // check if the date is >= 7 days
  const compare = date => today.diff(date, "days") >= 7;
  // if agreement hasn't been sent within 7 days of it being started, it's stale
  if (row.sent_on === null) return compare(moment(row.created_on));
  // if agreement hasn't been submitted within 7 days of it being sent, it's stale
  if (row.received_on === null) return compare(moment(row.sent_on));
  // agreement isn't stale
  return false;
};

const handleStale = (row, applied) => {
  if (applied.stale === "any") return true;

  return applied.stale === "true" ? checkIfStale(row) : !checkIfStale(row);
};

const countCompletedSteps = row => {
  if (row.deal_sheet.onboarding_agent.id) {
    return 5;
  } else if (row.processed_on) {
    return 4;
  } else if (row.received_on) {
    return 3;
  } else if (row.sent_on) {
    return 2;
  } else {
    return 1;
  }
};

const handleSteps = (row, applied) => {
  const completedSteps = countCompletedSteps(row);

  if (completedSteps < applied.stepsCompleted) {
    return false;
  } else if (completedSteps === applied.stepsCompleted) {
    return true;
  } else {
    return applied.stepsExclusive !== "true";
  }
};

const handleDate = (row, applied) => {
  if (applied.dateType === "any") return true;

  const date = row[applied.dateType];

  if (!date) {
    return false;
  }

  const start = applied.dateStart.format("YYYY-MM-DD") + " 00:00:00";
  const end = applied.dateEnd.format("YYYY-MM-DD") + " 23:59:59";

  return date >= start && date <= end;
};

const handlePaid = (row, applied) => {
  if (applied.paid === "any") return true;

  return applied.paid === "true" ? !!row.paid_on : !row.paid_on;
};

const genericOptions = [
  { value: "any", text: "Any" },
  { value: "true", text: "Show only" },
  { value: "false", text: "Hide all" }
];

const Filters = () => (
  <FiltersModal
    namespace="AgreementsList"
    filters={{
      stale: "false",
      paid: "any",
      stepsCompleted: 1,
      stepsExclusive: "false",
      dateType: "any",
      dateStart: moment().subtract(7, "days"),
      dateEnd: moment()
    }}
    buildFilters={applied => {
      const functions = [];

      functions.push(row => handleStale(row, applied));
      functions.push(row => handleSteps(row, applied));
      functions.push(row => handlePaid(row, applied));
      functions.push(row => handleDate(row, applied));

      return functions;
    }}
    modalProps={{}}
    children={(applied, update) => (
      <Form>
        <Grid columns="2">
          <Grid.Row>
            <Grid.Column>
              <Header as="h4" content="Generic Filters" />
              <Segment secondary>
                <Form.Field>
                  <label>
                    {"Steps Completed "}
                    <ThemePopup
                      trigger={<Icon name="question circle" />}
                      content={
                        <ThemePopupContent>
                          <h1>Minumum Steps Completed</h1>
                          <p>
                            This filter will hide any agreements that don't have
                            the required steps completed. For example, if you
                            select <b>processed</b>, agreements that haven't
                            been processed will not be visible.
                          </p>
                          <p>
                            The <b>hide more steps</b> toggle will hide any
                            agreements that have <b>more</b> steps completed.
                          </p>
                        </ThemePopupContent>
                      }
                      wide
                      basic
                    />
                  </label>
                  <p style={{ marginBottom: ".5rem" }}>
                    Use the buttons below to set the required number of
                    completed steps.
                  </p>
                  <Steps update={update} applied={applied} />
                </Form.Field>
                <Form.Checkbox
                  defaultChecked={applied.stepsExclusive === "true"}
                  onChange={() =>
                    update(
                      "stepsExclusive",
                      applied.stepsExclusive === "true" ? "false" : "true"
                    )
                  }
                  label="Hide if more steps completed"
                  toggle
                />
              </Segment>
              <Form.Dropdown
                label={
                  <label>
                    {"Stale Agreements "}
                    <ThemePopup
                      trigger={<Icon name="question circle" />}
                      content={
                        <ThemePopupContent>
                          <h1>Stale Agreements</h1>
                          <p>
                            Agreements are marked stale if they have been
                            created but not sent within 7 days, or sent but not
                            received within 7 days.
                          </p>
                          <p>
                            If the date difference is more than 21 days the
                            agreement is considered dead and will not be loaded.
                          </p>
                        </ThemePopupContent>
                      }
                      wide
                      basic
                    />
                  </label>
                }
                options={genericOptions}
                value={applied.stale}
                onChange={(undefined, { value }) => update("stale", value)}
                selection
              />
              <Form.Dropdown
                label="Paid Agreements"
                options={genericOptions}
                value={applied.paid}
                onChange={(undefined, { value }) => update("paid", value)}
                selection
              />
            </Grid.Column>
            <Grid.Column>
              <Header as="h4" content="Filter by Date" />
              <Date applied={applied} update={update} />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Form>
    )}
  />
);

export default Filters;
