import React from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import { Input } from "semantic-ui-react";

import LocalStorageToggle from "components/LocalStorageToggle";
import { borderColor } from "theme";
import { ThemeNavAction, ThemePopup } from "theme/components";
import { set } from "redux/Storage/actions";

const AdditionalOptions = props => {
  const { betaCode, setBetaCode } = props;

  return (
    <ThemePopup
      trigger={
        <ThemeNavAction>
          <i className="fas fa-ellipsis-v" />
        </ThemeNavAction>
      }
      content={
        <div>
          <Header>Preferences</Header>
          <p>There are no preferences yet.</p>
          <Header>Beta Preferences</Header>
          {betaCode === "🅱eta=true" ? (
            <>
              <ToggleContainer>
                <Text>
                  Dark mode
                  <ThemePopup
                    trigger={
                      <i
                        className="fas fa-question-circle"
                        style={{ marginLeft: "0.5rem" }}
                      />
                    }
                    content={<p>Dark mode is a beta feature.</p>}
                    on="hover"
                    size="small"
                    position="top center"
                    basic
                  />
                </Text>
                <LocalStorageToggle
                  index="theme"
                  active={value => value === "dark"}
                  onChange={value => (value === "dark" ? "light" : "dark")}
                />
              </ToggleContainer>
              <ToggleContainer>
                <Text>🦀 Crabs 🦀</Text>
                <LocalStorageToggle
                  index="crabmode"
                  active={value => value}
                  onChange={value => !value}
                />
              </ToggleContainer>
            </>
          ) : (
            <Input
              label="code"
              placeholder="Beta Code"
              value={betaCode}
              onChange={(undefined, { value }) => setBetaCode(value)}
              fluid
            />
          )}
        </div>
      }
      on="click"
      position="bottom center"
      basic
    />
  );
};

AdditionalOptions.propTypes = {
  setBetaCode: PropTypes.func.isRequired,
  betaCode: PropTypes.string.isRequired
};

const mapStateToProps = state => ({
  betaCode: state.Storage.betaCode
});

const mapDispatchToProps = dispatch => ({
  setBetaCode: code => dispatch(set("betaCode", code))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AdditionalOptions);

const ToggleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.5rem;
`;

const Header = styled.div`
  font-size: 1rem;
  font-weight: 600;
  padding-bottom: 0.5rem;
  margin-bottom: 1rem;
  border-bottom: 1px solid ${borderColor};
`;

const Text = styled.span`
  font-size: 0.9rem;
  font-weight: 600;
`;
