import React from "react";
import PropTypes from "prop-types";

import { Icon, Table, Header } from "semantic-ui-react";

import { formatMJY } from "utils/dates";
import { OwnerIssues, getIssueName } from "fixtures/issues";

const IssueTableRow = props => {
  const { data, setOpen } = props;

  return (
    <Table.Row style={{ cursor: "pointer" }} onClick={setOpen}>
      {data.closed_on === null ? (
        <Table.Cell singleLine negative>
          <Header as="h3" textAlign="center">
            Open
          </Header>
        </Table.Cell>
      ) : (
        <Table.Cell singleLine>
          <Header as="h3" textAlign="center">
            Closed
          </Header>
        </Table.Cell>
      )}
      <Table.Cell>
        <b>{getIssueName(data.category, OwnerIssues)}</b>
        <br />
        {data.name}
      </Table.Cell>
      <Table.Cell singleLine>
        {!data.opened_by.id ? (
          <b>
            <Icon name="shield" /> System
          </b>
        ) : (
          <b>
            {data.opened_by.firstname} {data.opened_by.lastname}
          </b>
        )}
        <br />
        {formatMJY(data.opened_on)}
      </Table.Cell>
      <Table.Cell>
        {data.closed_on ? (
          <>
            {!data.closed_by.id ? (
              <b>
                <Icon name="shield" /> System
              </b>
            ) : (
              <b>
                {data.closed_by.firstname} {data.closed_by.lastname}
              </b>
            )}
            <br />
            {formatMJY(data.closed_on)}
          </>
        ) : null}
      </Table.Cell>
    </Table.Row>
  );
};

IssueTableRow.propTypes = {
  data: PropTypes.object.isRequired,
  setOpen: PropTypes.func.isRequired
};

export default IssueTableRow;
