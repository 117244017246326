import React, { Component } from "react";
import { MyCalTypes } from "./types";
import Instance from "./components/Instance";
import moment from "moment";

export default class MyCal extends Component {
  static propTypes = MyCalTypes;
  // When calendars are registered, their refs live here
  calendar_refs = {};
  state = {
    calendars: {}
  };
  /**
   *	Initial setup
   */
  componentDidMount() {
    this.registerCalendarsFromConfig(this.props.config);
  }
  componentWillReceiveProps(props) {
    this.registerCalendarsFromConfig(props.config);
  }
  /**
   *	Map this.state from configuration values to calendar instances
   */
  registerCalendarsFromConfig = config => {
    this.calendars_refs = {};
    const calendars = {};
    // Iterate over config.calendars
    config.calendars.forEach(calendar => {
      // Each one will be indexed by its unique .name
      if (typeof calendars.name !== "undefined") {
        throw new Error(".name propery must be unique for each calendar");
      }
      calendars[calendar.name] = (
        <Instance
          // pass down its config
          config={calendar}
          // give it a function to get all instances
          getCalendars={() => this.calendar_refs}
          // assign it to this.calendar_refs so it can be accessed
          ref={ref => (this.calendar_refs[calendar.name] = ref)}
        />
      );
    });

    this.setState({ calendars });
  };

  /**
   *	Render
   */
  render() {
    // If there is 1 calendar, just render it, otherwise, render them through a function
    const keys = Object.keys(this.state.calendars);
    switch (keys.length) {
      case 1:
        return this.state.calendars[keys[0]];
      case 0:
        return null;
      default:
        return this.props.children(this.state.calendars);
    }
  }
}

// a wrapper for ContentManager
export const MyCalWrapper = ({
  value, // will be handled by ContentManager
  onChange, // will be handled by ContentManager
  field, // corresponds to the second .createInput() argument
  style, // ContentManager will supply style
  format = "YYYY-MM-DD HH:mm:ss", // Field should be specified depending on the page's needs
  ...rest // The rest of the third createInput() ".props" argument ends up here
}) => (
  // div allows for edited state styles to display
  <div style={style}>
    <MyCal
      config={{
        calendars: [
          {
            name: "calendar",
            value: moment(value),
            // trigger onChange in a format ContentManager expects (semanticUI's format)
            onChange: (_, value) => {
              onChange(undefined, {
                value: format ? value.format(format) : value
              });
            },
            ...rest
          }
        ]
      }}
    />
  </div>
);
