import React from "react";
import { Modal, Button } from "semantic-ui-react";
import { connect } from "react-redux";
import injectSheet from "react-jss";
import Cell from "./components/Cell";
import ModalContent from "./components/ModalContent";
class Bookings extends React.Component {
  state = {
    open: false
  };
  handleClose = () => this.setState({ open: false });
  handleOpen = () => this.setState({ open: true });
  render() {
    const { queue, row_bookings, row } = this.props;
    const modalProps = {
      open: this.state.open,
      onClose: this.handleClose,
      trigger: <Cell row={row} queue={queue} onClick={this.handleOpen} />,
      size: "small"
    };
    return (
      <Modal {...modalProps}>
        <Modal.Header>Bookings</Modal.Header>
        {/* <Modal.Content> */}
        <ModalContent row={row} row_bookings={row_bookings} />
        {/* </Modal.Content> */}
        <Modal.Actions>
          <Button onClick={this.handleClose}>Close</Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

const styles = {
  // ...
};

const mapStateToProps = state => ({
  queue: state.EOQReport.queue
  // ...
});
const mapDispatchToProps = dispatch => ({
  // ...
});

const connected = connect(
  mapStateToProps,
  mapDispatchToProps
)(Bookings);
export default injectSheet(styles)(connected);
