import React, { useState } from "react";
import moment from "moment";
import { useMount } from "react-use";

import { Button, Divider, Form, Message, Segment } from "semantic-ui-react";

import Layout from "components/Layout";
import WithState from "components/WithState";
import AjaxFn from "services/AjaxFn";
import calculate from "utils/bookings";
import { addCommas } from "utils/numbers";

export default () => {
  const [state, setState] = useState({
    loading: false,
    data: [],
    start: moment()
      .subtract(1, "month")
      .format("YYYY-MM-DD"),
    end: moment().format("YYYY-MM-DD"),
    company: "Any",
    companies: []
  });

  /**
   * Filters data and returns desired calculated values
   * @param {Array} data
   * @returns {Object}
   */
  const processData = () => {
    return (
      state.data
        .filter(row => {
          if (state.company === "Any") return true;
          const {
            name
          } = row.point_usages[0].point_set.membership.resort_company;
          return name === state.company;
        })
        // calcluate all of the bookings
        .map(calculate)
        .reduce(
          // our reducer
          (a, c) => ({
            count: a.count + 1,
            income: Number(a.income) + Number(c.gross_rental_income),
            payout: Number(a.payout) + Number(c.owner_pay),
            profit: Number(a.profit) + Number(c.profit)
          }),
          { count: 0, income: 0, payout: 0, profit: 0 }
        )
    );
  };

  const reload = () => {
    setState({
      ...state,
      loading: true,
      data: [],
      company: "Any",
      companies: []
    });

    AjaxFn({
      url: "/apis/portal/bookings",
      data: {
        action: "read",
        data: {
          start: state.start,
          end: state.end
        }
      },
      success: data =>
        setState({
          ...state,
          loading: false,
          data: data
            // remove rows with cancellations that are active
            .filter(
              row =>
                !row.cancellations.some(cancellation => cancellation.active)
            )
            // remove rows with no point usages
            .filter(row => row.point_usages.length > 0),
          companies: getCompanies(data)
        }),
      failure: error => {
        setState({ ...state, loading: false });
        window.toastr.error(error);
      }
    });
  };

  useMount(() => reload());

  const { count, income, profit, payout } = processData();

  return (
    <Layout noBar>
      <div style={{ width: "100%" }}>
        <WithState loading={state.loading}>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Form>
              <Form.Group widths="equal">
                <Form.Input
                  placeholder="Start"
                  label="Start"
                  type="date"
                  value={state.start}
                  max={state.end}
                  onChange={(e, { value }) =>
                    setState({ ...state, start: value })
                  }
                  disabled={state.loading}
                />
                <Form.Input
                  placeholder="End"
                  label="End"
                  type="date"
                  value={state.end}
                  min={state.start}
                  onChange={(e, { value }) =>
                    setState({ ...state, end: value })
                  }
                  disabled={state.loading}
                />
                <Form.Dropdown
                  selection
                  search
                  placeholder="Select one..."
                  label="Company"
                  value={state.company}
                  options={state.companies}
                  onChange={(e, { value }) =>
                    setState({ ...state, company: value })
                  }
                  disabled={state.loading}
                />
              </Form.Group>
            </Form>
            <Button
              disabled={state.loading}
              primary
              basic
              content="Reload"
              onClick={reload}
            />
          </div>
          <Divider />
          <Segment>
            <p>
              <b>Bookings: </b> {addCommas(count)}
            </p>
            <p>
              <b>Total Gross Rental Income: </b> ${addCommas(income.toFixed(2))}
            </p>
            <p>
              <b>Total Owner Payout: </b> ${addCommas(payout.toFixed(2))}
            </p>
            <p>
              <b>Total Profit: </b> ${addCommas(profit.toFixed(2))}
            </p>
          </Segment>
          <Message
            header="Filtering by Company"
            content="Company-based filtering happens after results are loaded."
          />
          <Message
            header="Excluded Bookings"
            content="Bookings with no point usages or cancellations are not included."
          />
          <Message
            info
            icon="exclamation triangle"
            header="Important"
            content="Bookings without fixed payrates are using *current* settings for owners. Owner payout and profit may not be completely accurate."
          />
        </WithState>
      </div>
    </Layout>
  );
};

function getCompanies(data) {
  const results = [];

  results.push("Any");
  data.forEach(row => {
    if (!row.point_usages.length) return;
    const { name } = row.point_usages[0].point_set.membership.resort_company;
    if (!results.includes(name)) results.push(name);
  });

  return results.map(text => ({ value: text, text }));
}
