import React, { Component } from "react";
import PropTypes from "prop-types";

import { Button } from "semantic-ui-react";

import AjaxFn from "services/AjaxFn";

export default class Activate extends Component {
  static propTypes = {
    member: PropTypes.object.isRequired,
    reloadMember: PropTypes.func.isRequired
  };

  state = {
    loading: false,
    cancel: false
  };

  componentWillUnmount() {
    this.cancel();
  }

  cancel = () => {
    if (typeof this.state.cancel === "function") {
      this.state.cancel();
    }
  };

  handleClick = () => {
    const data = {
      ID: this.props.member.id,
      deleted: this.props.member.deleted ? 0 : 1
    };

    this.setState({
      loading: true,
      cancel: AjaxFn({
        url: "/apis/portal/members",
        data: {
          action: "update",
          data: data
        },
        success: this.props.reloadMember,
        failure: error => {
          this.setState({ loading: false, cancel: false });
          window.toastr.error(error);
        }
      })
    });
  };

  render() {
    return (
      <Button
        basic
        onClick={this.handleClick}
        loading={this.state.loading}
        disabled={this.state.disabled}
        color={this.props.member.deleted ? "blue" : "red"}
        content={this.props.member.deleted ? "Activate" : "Deactivate"}
      />
    );
  }
}
