import React from "react";
import { Header, Icon, Divider, Form } from "semantic-ui-react";
import CSVUploadComponent from "react-csv-reader";
export default ({ handleUpload }) => (
  <div>
    <Header as={"h1"} dividing>
      <Header.Content>
        <Icon name={"cloud upload"} />
        Step 1
        <Header.Subheader>Upload the finances file</Header.Subheader>
      </Header.Content>
    </Header>
    <Form>
      <Form.Field required width={4}>
        <CSVUploadComponent onFileLoaded={handleUpload} />
      </Form.Field>
    </Form>
    <br />
    <p>
      <Icon name={"info"} />
      Files uploaded must be in a .csv format.
    </p>
    <Divider />
  </div>
);
