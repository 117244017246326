import PropTypes from "prop-types";

export default {
  guestData: PropTypes.shape({
    data: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]).isRequired,
    loading: PropTypes.bool.isRequired,
    error: PropTypes.any.isRequired
  }).isRequired,
  reloadGuest: PropTypes.func.isRequired,
  guestTab: PropTypes.string,
  guestID: PropTypes.string.isRequired
};
