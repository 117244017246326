import React, { useContext } from "react";
import { NavLink } from "react-router-dom";
import styled from "styled-components";

import { Context } from "../../";

const HeaderComponent = () => {
  const context = useContext(Context);

  return (
    <StyledNavLink to="/" title="Go to dashboard">
      <svg
        width="16"
        height="21"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M2.17 21H0C1.75 16.893 5.39 8.316 5.95 6.86 4.13 1.54 3.08 1.54 1.68 1.54V.56C1.703.373 2.17 0 3.85 0c3.01 0 4.392 4.13 4.76 5.32 1.283 4.153 3.948 12.656 4.34 13.44.42.84 1.75.747 2.38.63v1.05c0 .21-1.05.56-2.24.56-2.24 0-3.313-2.03-3.71-3.22L6.72 9.52 2.17 21z" />
      </svg>
    </StyledNavLink>
  );
};

export default HeaderComponent;

const StyledNavLink = styled(NavLink)`
  display: flex;
  align-items: center;
  justify-content: center;

  height: 50px;
  min-height: 50px;
  max-height: 50px;
  width: 100%;

  background-color: #2185d0;
  color: var(--offWhite);

  margin-bottom: 1rem;

  &:hover {
    svg {
      transform: scale(1.25);
    }
  }
  &:active,
  &:active&:focus {
    svg {
      transform: scale(0.75);
    }
  }
  &:focus {
    svg {
      transform: scale(1);
    }
  }

  svg {
    fill: var(--offWhite);
    transform: scale(1);
    transition: transform cubic-bezier(0.785, 0.135, 0.15, 0.86) 200ms;
  }
`;
