import React, { Fragment } from "react";
import { Button, Icon } from "semantic-ui-react";
import preprocessor from "./Preprocessor.js";
import ProgressBar from "components/ProgressBar";
import { Link } from "react-router-dom";
import Filters from "./components/Filters";
import moment from "moment";
export default ({ functions, namespace }) => ({
  tableName: namespace,
  url: "/apis/portal/units",
  data: {
    action: "read"
  },
  preprocessor,
  tableData: {
    show: 10,
    order: [[1, "asc"]],
    paginationPosition: "bottom",
    showFooter: false,
    menu: () => [
      "[search]",
      "[show]",
      <Filters />,
      "[refresh]",
      <Link to={"/wizards/properties"}>
        <Button icon color={"blue"} title={"New"}>
          <Icon name={"plus"} />
        </Button>
      </Link>
    ],
    cols: [
      {
        name: "",
        src: "id",
        render: (src, row) => (
          <Link to={"/browse/units/" + row.id}>
            <Icon name={"external"} />
          </Link>
        ),
        orderable: false,
        searchable: false,
        props: {
          textAlign: "center",
          selectable: true,
          width: 1
        }
      },
      {
        name: "Name",
        src: row =>
          row.resort.resort_company.name +
          " " +
          row.resort.name +
          " " +
          row.name
      },
      {
        name: "Availability",
        src: "availability_updated",
        render: (status, row) => {
          return (
            <Link to={`/browse/units/${row.id}/calendar`}>
              <AvailabilityRenderer
                status={status}
                date={row.availability_pushed}
              />
            </Link>
          );
        },
        props: row => {
          const props = {
            width: 2,
            selectable: true
          };
          if (!row.availability_updated) {
            props.negative = true;
          }
          return props;
        }
      },
      {
        name: "Completion",
        src: "completion",
        render: src => (
          <a onClick={functions.viewCompletion}>
            <ProgressBar value={src} />
          </a>
        ),
        props: {
          width: 4,
          selectable: true
        }
      }
    ]
  }
});

const AvailabilityRenderer = ({ status, date }) => {
  switch (status) {
    case "updated":
      return (
        <React.Fragment>
          <Icon name={"checkmark"} color={"green"} />
          {moment(date).fromNow()}
        </React.Fragment>
      );
    case "outdated":
      return (
        <React.Fragment>
          <Icon name={"cancel"} color={"red"} />
          {date ? moment(date).fromNow() : "Not done"}
        </React.Fragment>
      );
    case "frozen":
      return (
        <Fragment>
          <Icon name={"snowflake"} color={"blue"} />
          Frozen
        </Fragment>
      );
    case "unlisted":
      return (
        <Fragment>
          <Icon name={"exclamation triangle"} color={"yellow"} />
          No Listings
        </Fragment>
      );
    default:
      throw new Error("Invalid availability status");
  }
};
