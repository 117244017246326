import React from "react";
import PropTypes from "prop-types";
import { Step, Icon } from "semantic-ui-react";

const getStepProps = props => {
	if (props.onboarding_agent.id) {
		return [
			{ completed: true },
			{ completed: true },
			{ completed: true },
			{ completed: true },
			{ completed: true }
		];
	} else if (props.processing_agent.id) {
		return [
			{ completed: true },
			{ completed: true },
			{ completed: true },
			{ completed: true },
			{ active: true }
		];
	} else if (props.received_on) {
		return [
			{ completed: true },
			{ completed: true },
			{ completed: true },
			{ active: true },
			{ disabled: true }
		];
	} else if (props.closing_agent.id) {
		return [
			{ completed: true },
			{ active: true },
			{},
			{ disabled: true },
			{ disabled: true }
		];
	} else {
		return [
			{ active: true },
			{},
			{ disabled: true },
			{ disabled: true },
			{ disabled: true }
		];
	}
};
const EmployeeInfo = props => {
	const {
		appointment_setter,
		closing_agent,
		processing_agent,
		onboarding_agent,
		received_on
	} = props;
	const stepProps = getStepProps(props);
	return (
		<Step.Group widths="5" fluid size="small">
			<Step {...stepProps[0]}>
				<Icon name="calendar outline alternate" />
				<Step.Content>
					<Step.Title>Appointment</Step.Title>
					<Step.Description>
						{`${appointment_setter.firstname} ${appointment_setter.lastname}`}
					</Step.Description>
				</Step.Content>
			</Step>
			<Step {...stepProps[1]}>
				<Icon name="phone" />
				<Step.Content>
					<Step.Title>Closer</Step.Title>
					<Step.Description>
						{closing_agent.id ? (
							`${closing_agent.firstname} ${closing_agent.lastname}`
						) : (
							<p>Send the agreement...</p>
						)}
					</Step.Description>
				</Step.Content>
			</Step>
			<Step {...stepProps[2]}>
				<Icon name="inbox" />
				<Step.Content>
					<Step.Title>Submission</Step.Title>
					<Step.Description>
						{received_on ? <p>Submitted</p> : <p>Waiting...</p>}
					</Step.Description>
				</Step.Content>
			</Step>
			<Step {...stepProps[3]}>
				<Icon name="fax" />
				<Step.Content>
					<Step.Title>Processor</Step.Title>
					<Step.Description>
						{processing_agent.id ? (
							`${processing_agent.firstname} ${processing_agent.lastname}`
						) : (
							<p>Waiting...</p>
						)}
					</Step.Description>
				</Step.Content>
			</Step>
			<Step {...stepProps[4]}>
				<Icon name="sign in" />
				<Step.Content>
					<Step.Title>Onboarding</Step.Title>
					<Step.Description>
						{onboarding_agent.id ? (
							`${onboarding_agent.firstname} ${onboarding_agent.lastname}`
						) : (
							<p>Waiting...</p>
						)}
					</Step.Description>
				</Step.Content>
			</Step>
		</Step.Group>
	);
};

EmployeeInfo.propTypes = {
	appointment_setter: PropTypes.object,
	closing_agent: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
	onboarding_agent: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
	processing_agent: PropTypes.oneOfType([PropTypes.array, PropTypes.object])
};

EmployeeInfo.defaultProps = {
	appointment_setter: { firstname: "", id: 0, lastname: "" },
	closing_agent: [],
	onboarding_agent: [],
	processing_agent: []
};

export default EmployeeInfo;
