import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { Form } from "semantic-ui-react";

import trueFalse from "fixtures/trueFalse";
import { isValidFinance } from "utils/numbers";

const draftType = [
	{ keys: "mo", text: "Monthly", value: "monthly" },
	{ keys: "yr", text: "Yearly", value: "yearly" }
];

class Maintenance extends Component {
	constructor(props) {
		super(props);
		this.state = { fees: props.fees };
	}

	componentWillReceiveProps(nextProps) {
		if (nextProps.fees !== this.state.fees) {
			this.setState({ fees: nextProps.fees });
		}
	}

	handleChange = (e, { name, value, nested }) => {
		const { handleChange } = this.props;
		if (isValidFinance(value)) {
			this.setState({ fees: value }, handleChange(e, { name, value, nested }));
		}
	};

	triggerChange = (e, { name }) => {
		const { onChange } = this.props;
		if (typeof onChange === "function") {
			onChange(this.state[name]);
		}
	};

	handleBlur = e => {
		const { name, value } = e.target;
		const float = parseFloat(value);
		const floatValue = isNaN(float) ? "0.00" : float.toFixed(2);

		this.setState({ [name]: floatValue }, this.triggerChange(e, { name }));
	};

	render() {
		const {
			auto_draft,
			fee_withdrawl_occurrence,
			fees_up_to_date,
			handleChange
		} = this.props;

		const { fees } = this.state;
		return (
			<Fragment>
				<Form.Group widths="equal">
					<Form.Input
						label="Fees"
						id="fees"
						name="fees"
						nested="maintenance"
						value={fees}
						onChange={this.handleChange}
						onBlur={this.handleBlur}
						autoComplete="off"
						placeholder="Maintenence fees"
						// required
					/>
					<Form.Select
						label="mo/yr"
						id="fee_withdrawl_occurrence"
						name="fee_withdrawl_occurrence"
						nested="maintenance"
						options={draftType}
						value={fee_withdrawl_occurrence}
						onChange={handleChange}
						placeholder="Select one..."
						// required
					/>
					<Form.Select
						label="auto draft?"
						id="auto_draft"
						name="auto_draft"
						nested="maintenance"
						options={trueFalse}
						value={auto_draft}
						onChange={handleChange}
						placeholder="Select one..."
						// required
					/>
					<Form.Select
						label="Fees up-to-date?"
						id="fees_up_to_date"
						name="fees_up_to_date"
						nested="maintenance"
						options={trueFalse}
						value={fees_up_to_date}
						onChange={handleChange}
						placeholder="Select one..."
						// required
					/>
				</Form.Group>
			</Fragment>
		);
	}
}

Maintenance.propTypes = {
	auto_draft: PropTypes.bool,
	fee_withdrawl_occurrence: PropTypes.string,
	fees: PropTypes.string,
	fees_up_to_date: PropTypes.bool,
	handleChange: PropTypes.func.isRequired
};

Maintenance.defaultProps = {
	auto_draft: false,
	fee_withdrawl_occurrence: "",
	fees: "",
	fees_up_to_date: true
};

export default Maintenance;
