import React, { Component } from "react";
import propTypes from "prop-types";
import { Button } from "semantic-ui-react";
import AjaxFn from "services/AjaxFn";

export default class StartAudit extends Component {
  static propTypes = {
    reload: propTypes.func,
    data: propTypes.object
  };

  state = {
    loading: false
  };

  handleClick = () => {
    this.setState({ loading: true });
    AjaxFn({
      url: "/apis/portal/bookingaudits",
      data: {
        action: "start",
        data: {
          bookingID: this.props.data.id
        }
      },
      success: () => {
        window.toastr.success();
        this.props.reload();
      },
      failure: error => {
        window.toastr.error(error);
        this.setState({ loading: false });
      }
    });
  };

  render() {
    return (
      <div>
        <p>
          There is currently not an audit in progress. Click the button below to
          begin one.
        </p>
        <Button
          primary
          loading={this.state.loading}
          disabled={this.state.loading}
          onClick={this.handleClick}
          content="Start Audit"
          basic
        />
        <br />
        <br />
        <p>
          Starting an audit will automatically open common booking-related
          issues. You will need to verify that each one of them are resolved.
          After you've closed all of the issues on the booking, you will be able
          to mark the audit as complete.
        </p>
      </div>
    );
  }
}
