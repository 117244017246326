import React, { Component } from "react";
import { Input, Icon } from "semantic-ui-react";
import { InstanceTypes } from "../../../../types";
import propTypes from "prop-types";
import moment from "moment";
export default class InputComponent extends Component {
  static propTypes = {
    value: propTypes.instanceOf(moment).isRequired,
    setOpen: propTypes.func.isRequired,
    setDate: propTypes.func.isRequired,
    config: InstanceTypes.config
  };
  state = {
    inputting: false,
    input: ""
  };
  /**
   *  Get the displayed formatted value
   *	@return {String}
   */
  getDisplayedValue = () => {
    const { config, value } = this.props;
    const { inputting } = this.state;
    const { outputMask, type } = config;
    if (inputting) {
      return this.state.input;
    } else {
      if (outputMask) {
        return value.format(outputMask);
      } else {
        switch (type) {
          case "date":
            return value.format("MMM Do, YYYY");
          case "time":
            return value.format("h:mm a");
          default:
            return value.format("MMM D, YYYY, h:mm a");
        }
      }
    }
  };
  /**
   * Determine which icon button should be visible
   * @return {ReactElement}
   */
  getDisplayedIcon = () => {
    const { setOpen } = this.props;
    const { inputting } = this.state;
    if (this.props.config.disabled || this.props.config.readOnly) {
      return <Icon name="lock" />;
    } else if (inputting) {
      return (
        // cancel changes button
        <Icon
          name="cancel"
          color="red"
          inverted
          circular
          link
          onMouseDown={this.cancelChanges}
        />
      );
    } else {
      return (
        // open calendar button
        <Icon
          name="calendar alternate outline"
          inverted
          circular
          link
          onMouseDown={setOpen}
        />
      );
    }
  };
  /**
   * handle the click on the cancel changes icon
   * @param {Event} e
   */
  cancelChanges = e => this.setState({ inputting: false, input: "" });
  /**
   * handle blurring the input field
   * @param {Event} e
   */
  handleBlur = e => {
    if (this.state.inputting) {
      // only want to proces the input if something was entered
      if (this.state.input) {
        this.processValue();
        this.setState({ input: "" });
      }
      this.setState({ inputting: false });
    }
  };
  /**
   * handle click on the input field
   * @param {Event} e
   */
  handleClick = e => {
    this.props.setOpen();
    // if (!this.props.config.readOnly) {
    //   this.setState({ inputting: true });
    // }
  };
  /**
   * try to make sense of the user's input
   * @param {String} enteredValue
   */
  processValue = () => {
    const { input } = this.state;
    const { setDate } = this.props;
    // try to make sense of what the user typed
    const parsed = moment(input);
    if (!parsed.isValid()) {
      window.toastr.error("Please enter a valid date");
      return false;
    }
    setDate(parsed, true);
  };

  render() {
    return (
      <Input
        icon={this.getDisplayedIcon()}
        value={this.getDisplayedValue()}
        // allow the user to type into the field
        onClick={this.handleClick}
        // register changes as the user types
        onChange={(e, { value }) => this.setState({ input: value })}
        // process the change when user is done typing
        onBlur={this.handleBlur}
        // to help the user enter a date
        placeholder={"Enter a date/time"}
        disabled={this.props.config.disabled}
        readOnly
        fluid={this.props.config.fluid || false}
      />
    );
  }
}
