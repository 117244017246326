import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import {
  Grid,
  Segment,
  Form,
  Dropdown,
  Button,
  Header,
  Icon
} from "semantic-ui-react";

import AdvanceGuestMessage from "./components/AdvanceGuestMessage";
import BookingNoticeMessage from "./components/BookingNoticeMessage";
import FinalGuestMessage from "./components/FinalGuestMessage";
import History from "./components/History";
import AjaxFn from "services/AjaxFn";
import options from "fixtures/bookingNotices";

import { reloadBooking } from "redux/BookingPage/actions";

export class Mail extends Component {
  static propTypes = {
    reload: PropTypes.func.isRequired,
    data: PropTypes.object.isRequired
  };

  state = {
    value: "",
    loading: false,
    previewing: false,
    cancel: false
  };

  componentWillUnmount() {
    this.cancel();
  }

  cancel = () => {
    if (typeof this.state.cancel === "function") {
      this.state.cancel();
    }
  };
  /**
   * Toggle previewing state
   */
  togglePreviewing = () =>
    this.setState({ previewing: !this.state.previewing });
  /**
   * Get the message that shows below the selected item
   * @return {ReactElement}
   */
  getMessage = () => {
    switch (this.state.value) {
      case "advance":
        return <AdvanceGuestMessage data={this.props.data} />;
      case "final":
        return <FinalGuestMessage data={this.props.data} />;
      case "booking":
        return <BookingNoticeMessage data={this.props.data} />;
      default:
        return null;
    }
  };
  /**
   * Return all valid send mail options
   * @return {Array}
   */
  getOptions = () => {
    const { data } = this.props;
    const options = [];
    if (data._actions.actions.send_mail_guest) {
      options.push({ value: "advance", text: "Guest Advance Confirmation" });
      options.push({ value: "final", text: "Guest Final Confirmation" });
    }
    if (data._actions.actions.send_mail_owner) {
      /**
       * this is being prevented by the actions always returning send_mail_owner = false
       * this is intentional
       * @see https://github.com/vmgresorts/portal-react/issues/348
       */
      options.push({ value: "booking", text: "Owner Booking Notice" });
    }
    return options;
  };
  /**
   * Render the preview segment
   */
  renderPreview = () => {
    return (
      <Segment>
        <Button onClick={this.togglePreviewing}>Close Preview</Button>
        <br />
        <br />
        <div>
          <iframe
            title={"Preview Mail"}
            style={{ width: "100%", height: "800px" }}
            src={
              window.location.protocol +
              "//" +
              window.location.hostname +
              `/apis/portal/bookingsentnotices/?action=preview&booking_id=${
                this.props.data.id
              }&type=${this.state.value}`
            }
          />
        </div>
      </Segment>
    );
  };
  /**
   * Send mail
   */
  send = () => {
    this.setState({
      loading: true,
      cancel: AjaxFn({
        url: "/apis/portal/bookingsentnotices",
        data: {
          action: "send",
          data: {
            booking_id: this.props.data.id,
            type: this.state.value
          }
        },
        success: () => {
          window.toastr.success();
          this.props.reload();
        },
        failure: error => {
          this.setState({ loading: false, cancel: false });
          window.toastr.error(error);
        }
      })
    });
  };
  /**
   * Render
   */
  render() {
    const { value, loading, previewing } = this.state;
    if (previewing) {
      return this.renderPreview();
    } else {
      return (
        <Fragment>
          <div style={{ maxWidth: "500px" }}>
            <Form>
              <Form.Field>
                <label>Email Type</label>
                <div style={{ display: "flex" }}>
                  <div style={{ flexGrow: "1" }}>
                    <Dropdown
                      {...{
                        fluid: true,
                        selection: true,
                        selectOnBlur: false,
                        placeholder: "Select one...",
                        value,
                        onChange: (e, { value }) => this.setState({ value }),
                        options: this.getOptions()
                      }}
                    />
                  </div>
                  <div style={{ marginLeft: ".25rem" }}>
                    <Button
                      disabled={loading || !value}
                      content={"Preview"}
                      onClick={this.togglePreviewing}
                    />
                    <Button
                      primary
                      loading={loading}
                      disabled={loading || !value}
                      onClick={this.send}
                      content={"Send"}
                    />
                  </div>
                </div>
              </Form.Field>
            </Form>
            {this.getMessage()}
          </div>
          <History data={this.props.data} />
        </Fragment>
      );
    }
  }
}

const mapDispatchToProps = {
  reload: reloadBooking
};

export default connect(
  undefined,
  mapDispatchToProps
)(Mail);
