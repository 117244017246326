import React from "react";
import { fetch } from "redux/PortalTables/actions";
import List from "./components/List";
import Editor from "./components/Editor";
import Wizard from "./components/Wizard";
import DeleteButton from "./components/DeleteButton";
import { connect } from "react-redux";

class ContentManager extends React.PureComponent {
  state = {
    wizardLoading: false,
    wizardActive: false,
    editorLoading: false,
    editorActive: false,
    editorSource: {}
  };
  /**
   *	Returns both the state and various methods in an object
   *	@return {Object}
   */
  _this = () => ({
    state: { ...this.state },
    functions: {
      openWizard: this.openWizard,
      closeWizard: this.closeWizard,
      wizardLoading: this.wizardLoading,
      openEditor: this.openEditor,
      closeEditor: this.closeEditor,
      editorLoading: this.editorLoading,
      refresh: this.refresh,
      getDeleteButton: this.getDeleteButton
    }
  });
  _config = () => {
    let { config } = this.props;
    if (typeof config === "function") {
      let _this = this._this();
      config = config(_this);
    }
    return config;
  };
  /**
   *	Set wizard state to active
   */
  openWizard = () =>
    this.setState({
      wizardActive: true
    });
  /**
   *	Set wizard state to inactive
   */
  closeWizard = () =>
    this.setState({
      wizardActive: false
    });
  /**
   *	Set wizard loading state
   *  @param {Bool} loading
   */
  wizardLoading = (loading = false) =>
    this.setState({
      wizardLoading: loading
    });

  /**
   *	Set editor state to active, and set the original editor data source
   *  to the row passed as an argument
   *  @param {Object} row
   */
  openEditor = (row) =>
    this.setState({
      editorActive: true,
      editorSource: { ...row }
    });
  /**
   *	Set wizard state to inactive
   */
  closeEditor = () =>
    this.setState({
      editorActive: false
    });
  /**
   *	Set editor loading state
   *  @param {Bool} loading
   */
  editorLoading = (loading = false) =>
    this.setState({
      editorLoading: loading
    });

  refresh = () => {
    let {
      List: { tableName }
    } = this._config();
    this.props.dispatch(fetch(tableName));
  };
  /**
   *	Returns data to inject into the children of the <ContentManager />
   *	@return {Object}
   */
  getChildData = () => {
    let _this = this._this();
    let config = this._config();
    return {
      components: {
        List: <List config={config} manager={_this} />,
        Editor: <Editor config={config} manager={_this} />,
        Wizard: <Wizard config={config} manager={_this} />,
        Delete: this.getDeleteButton(config, _this)
      },
      instance: _this,
      config: config
    };
  };

  getDeleteButton = (config, _this) => {
    if (!config) {
      config = this._config();
    }
    if (!_this) {
      _this = this._this();
    }
    return <DeleteButton config={config} manager={_this} />;
  };

  render() {
    const data = this.getChildData();
    return this.props.children(data);
  }
}
const mapStateToProps = () => ({});
export default connect(mapStateToProps)(ContentManager);
