import InitialState from "./initial";
export default (state = InitialState, action) => {
  switch (action.type) {
    case "BOOKPAGE_LOADING":
      return {
        ...state,
        booking: {
          data: false,
          loading: true,
          error: false
        }
      };
    case "BOOKPAGE_ERROR":
      return {
        ...state,
        booking: {
          data: false,
          loading: false,
          error: action.data
        }
      };
    case "BOOKPAGE_DATA":
      return {
        ...state,
        booking: {
          data: action.data,
          loading: false,
          error: false
        }
      };
    case "BOOKPAGE_STORE_CHANGE":
      return {
        ...state,
        changes: {
          ...state.changes,
          [action.source]: action.value
        }
      };
    case "BOOKPAGE_REMOVE_CHANGE":
      const newChanges = { ...state.changes };
      delete newChanges[action.source];
      return {
        ...state,
        changes: newChanges
      };
    case "BOOKPAGE_DISCARD_CHANGES":
      return {
        ...state,
        changes: {}
      };
    case "BOOKPAGE_SAVING_CHANGES":
      return {
        ...state,
        savingChanges: true
      };
    case "BOOKPAGE_SAVED_CHANGES":
      return {
        ...state,
        savingChanges: false
      };
    default:
      return state;
  }
};
