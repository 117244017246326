import React from "react";
import { Table, Icon } from "semantic-ui-react";

export default ({ row, queue, onClick }) => {
  const row_queued = row[2]
    .map(id => queue.indexOf(id) !== -1)
    .filter(row => row);
  const getIcon = () => {
    if (!row_queued.length) {
      return <Icon name={"cancel"} color={"red"} />;
    } else if (row_queued.length === row[2].length) {
      return <Icon name={"checkmark"} color={"green"} />;
    } else {
      return <Icon name={"exclamation triangle"} color={"orange"} />;
    }
  };
  const getCellProps = () => {
    if (!row_queued.length) {
      return { negative: true };
    } else if (row_queued.length === row[2].length) {
      return {};
    } else {
      return { warning: true };
    }
  };
  return (
    <Table.Cell selectable {...getCellProps()}>
      <a href={null} onClick={onClick}>
        {getIcon()}
        {row_queued.length}
        {"/"}
        {row[2].length}
      </a>
    </Table.Cell>
  );
};
